define("portal-talentrh/pods/application/shortcuts/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ShortcutsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ShortcutsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "permission", _descriptor6, this);
      _initializerDefineProperty(this, "showModal", _descriptor7, this);
      _initializerDefineProperty(this, "sectionExpanded", _descriptor8, this);
      _initializerDefineProperty(this, "shortcutsUser", _descriptor9, this);
      _initializerDefineProperty(this, "shortcutsOptions", _descriptor10, this);
      _initializerDefineProperty(this, "shortcutsList", _descriptor11, this);
    }
    get dataLeft() {
      return this.args.isManager ? 'manager' : 'me';
    }
    *initShortcuts() {
      try {
        const shortcuts = yield this.fetch.request('shortcuts');
        this.shortcutsList = shortcuts;
        const userShortcuts = yield this.fetch.request('/shortcuts/findByUser');
        this.shortcutsUser = userShortcuts;
        this.shortcutsList = this.shortcutsList.map(obj => {
          obj.selected = this.shortcutsUser.find(shortcut => shortcut.name === obj.name) ? true : false;
          return obj;
        });
      } catch (e) {
        return Ember.debug(e);
      }
    }
    *saveShortcuts() {
      try {
        this.shortcutsUser = this.shortcutsList.filter(shortcut => shortcut.selected);
        yield this.fetch.post('/shortcuts/save', {
          data: this.shortcutsUser
        });
        this.toast.success('Atalhos salvo com sucesso!');
        this.showModal = false;
        this.sectionExpanded = '';
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *deleteShortcut(shortcut) {
      this.shortcutsUser = this.shortcutsUser.filter(shortcutUser => shortcutUser.name !== shortcut.name);
      this.shortcutsList = this.shortcutsList.map(obj => {
        if (obj.name === shortcut.name) {
          return {
            ...obj,
            selected: false
          };
        }
        return obj;
      });
      yield this.fetch.post('/shortcuts/save', {
        data: this.shortcutsUser
      });
    }
    deleteConfirm(shortcut) {
      this.swal.confirm(`Tem certeza que deseja remover o atalho ${shortcut.description}?`).then(result => {
        if (result.value) {
          this.deleteShortcut.perform(shortcut);
        }
      });
    }
    editShortcut() {
      const shortcutsSelected = this.shortcutsList.map(obj => {
        obj.selected = this.shortcutsUser.find(shortcut => shortcut.name === obj.name) ? true : false;
        return obj;
      });
      this.shortcutsList = shortcutsSelected;
      const shortcutsOptions = this.shortcutsList.reduce((r, _ref) => {
        let {
          section,
          sectionDescription,
          ...rest
        } = _ref;
        rest.selected = this.shortcutsUser.find(shortcut => shortcut.name === rest.name) ? true : false;
        const key = section;
        r[key] = r[key] || {
          section,
          sectionDescription,
          items: []
        };
        r[key]['items'].push(rest);
        return r;
      }, {});
      this.shortcutsOptions = Object.values(shortcutsOptions);
      this.sectionExpanded = '';
      this.showModal = true;
    }
    onSelectShortcut(name) {
      const shortcutsSelected = this.shortcutsList.map(obj => {
        if (obj.name === name) {
          return {
            ...obj,
            selected: !obj.selected
          };
        }
        return obj;
      });
      const filterShortcutsSelected = shortcutsSelected.filter(shortcut => shortcut.selected);
      if (filterShortcutsSelected.length >= 5) {
        this.swal.warning('É possível adicionar até 5 atalhos!');
        return;
      }
      this.shortcutsList = shortcutsSelected;
      const shortcutsOptions = shortcutsSelected.reduce((r, _ref2) => {
        let {
          section,
          sectionDescription,
          ...rest
        } = _ref2;
        const key = section;
        r[key] = r[key] || {
          section,
          sectionDescription,
          items: []
        };
        r[key]['items'].push(rest);
        return r;
      }, {});
      this.shortcutsOptions = Object.values(shortcutsOptions);
    }
    setSectionExpanded(section) {
      if (this.sectionExpanded === section) {
        this.sectionExpanded = '';
      } else {
        this.sectionExpanded = section;
      }
    }
    onChangeShortcut(action) {
      const currentRoute = this.router.currentRouteName;
      if (currentRoute === 'feed.index.index' && action === 'feed') {
        window.location.reload();
      } else {
        this.router.transitionTo(action);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "sectionExpanded", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "shortcutsUser", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "shortcutsOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "shortcutsList", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initShortcuts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initShortcuts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveShortcuts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveShortcuts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteShortcut", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "deleteShortcut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteConfirm", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "deleteConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editShortcut", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "editShortcut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectShortcut", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectShortcut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSectionExpanded", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setSectionExpanded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeShortcut", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeShortcut"), _class.prototype)), _class));
  _exports.default = ShortcutsComponent;
});