define("portal-talentrh/models/pdi", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    date: (0, _model.attr)('date'),
    objective: (0, _model.attr)('string'),
    status: (0, _model.attr)('number'),
    description: (0, _model.attr)('string'),
    expirationDate: (0, _model.attr)('date'),
    company: (0, _model.belongsTo)('company'),
    leaderUser: (0, _model.belongsTo)('user'),
    user: (0, _model.belongsTo)('user'),
    createdBy: (0, _model.belongsTo)('user'),
    pdiActions: (0, _model.hasMany)('pdiAction'),
    statusBadge: Ember.computed('status', 'isExpired', function () {
      let status = this.status;
      let badges = {
        0: {
          text: 'Aberto',
          color: 'info'
        },
        1: {
          text: 'Finalizado',
          color: 'success'
        },
        2: {
          text: 'Expirado',
          color: 'warning'
        }
      };
      if (this.isExpired && this.status !== 1) {
        status = 2;
      }
      return badges[status] || {};
    })
  });
  _exports.default = _default;
});