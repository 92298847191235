define("portal-talentrh/pods/components/user-preview-popup-insight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZQCd0HZM",
    "block": "{\"symbols\":[\"userLoaded\",\"isLoaded\"],\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"user-attribute\",[],[[\"@userId\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[12],[2,\"TESTE\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"preloader-animation\",[],[[\"@type\"],[4]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"h1\"],[12],[2,\"TESTE\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"user\",\"if\",\"isUserId\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-preview-popup-insight/template.hbs"
    }
  });
  _exports.default = _default;
});