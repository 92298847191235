define("portal-talentrh/pods/group/form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    groupsService: Ember.inject.service('groups'),
    router: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    permission: Ember.inject.service(),
    allowSendMessageGroupChat: Ember.computed.or('permission.accessProfile.allowSendMessageGroupChat', 'permission.admin'),
    // Multiline computed properties
    isNew: Ember.computed('group.isNew', function () {
      if (!this.group) {
        return;
      }
      return this.group.get('isNew');
    }),
    save: (0, _emberConcurrency.task)(function* () {
      if (!this.group.get('validations.isValid')) {
        return this.swal.validation(this.group.get('validations'));
      }
      try {
        const isNew = this.isNew;
        this.swal.loading('Salvando grupo', {
          allowOutsideClick: false
        });
        yield this.group.save();
        if (isNew) {
          this.swal.success('Agora você pode adicionar uma imagem para o grupo');
          this.router.transitionTo('group.edit', this.group.id);
        } else {
          if (this.onCloseModal) {
            this.onCloseModal();
          }
          this.toast.success('Alterações salvas');
          this.router.transitionTo('group.index');
        }
        this.groupsService.loadList();
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      changeGroupImage() {
        const groupId = this.group.id;
        let element = document.getElementById('group-image-file-field');
        let file = element.files[0];
        if (!file) {
          return false;
        }
        let form = new FormData();
        form.append('attachment', file);
        this.swal.loading('A imagem está sendo atualizada');
        this.set('isUpdatingImage', true);
        this.ajax.request(`usergroups/${groupId}/image`, {
          contentType: false,
          processData: false,
          type: 'POST',
          data: form
        }).then(response => {
          this.toast.success('Imagem atualizada');
          this.groupsService.reloadGroup(groupId);
          this.set('isUpdatingImage', false);
          this.set('group.imageUrl', response.userGroup.imageUrl);
        }).catch(e => {
          this.swal.catch(e);
        });
      },
      click(item) {
        document.getElementById(item).click();
      }
    }
  });
  _exports.default = _default;
});