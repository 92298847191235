define("portal-talentrh/pods/portal-manager/feeling/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let Item = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('args.feeling'), _dec7 = Ember.computed.alias('permission.companyConfig.hasAVDModule'), _dec8 = Ember.computed.alias('permission.companyConfig.showJobFunction'), _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember.computed('feeling.type'), _dec19 = Ember.computed('feeling.leader', 'leaderAction.createdBy', 'permission.user'), _dec20 = Ember.computed('feeling.type'), _dec21 = Ember._action, _dec22 = Ember._action, (_class = class Item extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "feeling", _descriptor6, this);
      _initializerDefineProperty(this, "hasAVDModule", _descriptor7, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor8, this);
      _initializerDefineProperty(this, "openViewModal", _descriptor9, this);
      _initializerDefineProperty(this, "openActionModal", _descriptor10, this);
      _initializerDefineProperty(this, "linkToFeedback", _descriptor11, this);
      _initializerDefineProperty(this, "disableFeedback", _descriptor12, this);
      _initializerDefineProperty(this, "textFeelings", _descriptor13, this);
      _initializerDefineProperty(this, "leaderMessage", _descriptor14, this);
      _initializerDefineProperty(this, "managerMessage", _descriptor15, this);
      _initializerDefineProperty(this, "managerAction", _descriptor16, this);
      _initializerDefineProperty(this, "leaderAction", _descriptor17, this);
    }
    get feelingIsNegative() {
      const negativeFeelings = ['Irritado', 'Cansado', 'Entediado', 'Ansioso', 'Triste', 'Desanimado'];
      return negativeFeelings.includes(this.feeling.type) ? true : false;
    }
    get canEditAction() {
      const userLoggedId = this.permission.user.get('id');
      const leaderOnFeeling = this.feeling.leader.get('id');
      let createdBy;
      if (this.leaderAction) {
        createdBy = this.leaderAction.createdBy.get('id');
      }
      return createdBy && createdBy === userLoggedId || leaderOnFeeling === userLoggedId;
    }
    get feelingsSource() {
      const text = {
        Feliz: 'happy.svg',
        Apaixonado: 'in-love.svg',
        Irritado: 'angry.svg',
        Cansado: 'tired.svg',
        Inteligente: 'smart.svg',
        Entediado: 'bored.svg',
        Ansioso: 'eager.svg',
        Legal: 'cool.svg',
        Triste: 'sad.svg',
        Desanimado: 'dispirited.svg',
        Divertido: 'funny.svg',
        Entusiasmado: 'excited.svg'
      };
      return text[this.feeling.type];
    }
    showModal(canEdit) {
      this.openActionModal = canEdit;
      this.openViewModal = true;
    }
    closeModal() {
      this.openViewModal = false;
    }
    *save() {
      let userLogged = yield this.store.peekRecord('user', this.session.user.id);
      if (this.leaderAction) {
        this.leaderAction.message = this.leaderMessage;
        this.leaderAction.createdBy = userLogged, yield this.leaderAction.save();
      } else {
        yield this.leaderAction = yield this.store.createRecord('feeling-action', {
          origin: 'myTeam',
          message: this.leaderMessage,
          createdBy: userLogged,
          feelingType: this.feeling.get('type'),
          feeling: this.feeling,
          insightLed: this.insightLed || null
        });
        if (this.leaderAction.validations.isInvalid) {
          return this.swal.validation(this.leaderAction.validations);
        }
        yield this.leaderAction.save();
      }
      if (this.linkToFeedback && !this.disableFeedback) {
        const date = new Date();
        let userLogged = yield this.store.peekRecord('user', this.session.user.id);
        let company = yield this.store.peekRecord('company', this.session.user.company);
        const feedback = yield this.store.createRecord('feedback', {
          finalFeedback: this.leaderMessage,
          company: company,
          draft: true,
          date: date,
          leaderUser: userLogged,
          objective: 'Conversa sobre sentimento negativo',
          status: 1,
          startTime: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
          endTime: '00:00:00',
          type: 0,
          user: this.feeling.user
        });
        yield feedback.save();
        this.leaderAction.feedback = feedback;
        this.leaderAction.linkedToFeedback = true;
        yield this.leaderAction.save();
        this.linkToFeedback = true;
        this.disableFeedback = true;
      } else if (this.linkToFeedback && this.disableFeedback) {
        const feedbackId = yield this.leaderAction.get('feedback');
        let feedback = yield this.store.findRecord('feedback', feedbackId.id);
        feedback.activityDescription = this.leaderMessage;
        feedback.save();
      }
      this.toast.success('Ação salva com sucesso!');
      this.openViewModal = false;
    }
    *findInsightLed() {
      try {
        let insightLed = yield this.store.query('insight-led', {
          feeling: this.feeling.id,
          led: this.feeling.user.get('id')
        });
        insightLed = insightLed.toArray();
        insightLed = insightLed.length ? insightLed[0] : null;
        this.insightLed = insightLed;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *prepareItems() {
      this.leaderAction = yield this.store.queryRecord('feeling-action', {
        feeling: this.feeling.id,
        origin: ['myTeam', 'habitus']
      });
      this.managerAction = yield this.store.queryRecord('feeling-action', {
        feeling: this.feeling.id,
        origin: 'humanResourses'
      });
      if (this.leaderAction) {
        const feedback = yield this.leaderAction.get('feedback');
        this.linkToFeedback = feedback ? true : false;
        this.disableFeedback = feedback ? true : false;
      }
      this.leaderMessage = this.leaderAction ? this.leaderAction.message : '';
      this.managerMessage = this.managerAction ? this.managerAction.message : '';
      const textFemale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonada',
        Irritado: 'Irritada',
        Cansado: 'Cansada',
        Inteligente: 'Inteligente',
        Entediado: 'Entediada',
        Ansioso: 'Ansiosa',
        Legal: 'Legal',
        Desanimado: 'Desanimada',
        Triste: 'Triste',
        Divertido: 'Divertida',
        Entusiasmado: 'Entusiasmada'
      };
      const textMale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonado',
        Irritado: 'Irritado',
        Cansado: 'Cansado',
        Inteligente: 'Inteligente',
        Entediado: 'Entediado',
        Ansioso: 'Ansioso',
        Legal: 'Legal',
        Desanimado: 'Desanimado',
        Triste: 'Triste',
        Divertido: 'Divertido',
        Entusiasmado: 'Entusiasmado'
      };
      this.textFeelings = this.feeling.userGender === 'F' ? textFemale[this.feeling.type] : textMale[this.feeling.type];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "feeling", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasAVDModule", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "openViewModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "openActionModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "linkToFeedback", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "disableFeedback", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "textFeelings", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "leaderMessage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "managerMessage", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "managerAction", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "leaderAction", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "feelingIsNegative", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "feelingIsNegative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "canEditAction", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "canEditAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "feelingsSource", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "feelingsSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "showModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findInsightLed", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findInsightLed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepareItems", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "prepareItems"), _class.prototype)), _class));
  _exports.default = Item;
});