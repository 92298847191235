define("portal-talentrh/pods/application/navbar/notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Vw0X5BVJ",
    "block": "{\"symbols\":[\"notification\",\"@unreadCount\"],\"statements\":[[10,\"div\"],[14,0,\"navbar__dropdown__header border-bottom\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"navbar__dropdown__header__title ml-3\"],[12],[2,\"Notificações\"],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"navbar__dropdown__header__btn float-right mr-3\"],[15,\"onclick\",[30,[36,0],[[32,0],\"readAllNotifications\"],null]],[12],[2,\"\\n    Marcar todas como lidas\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"application/navbar/notification/item\",[[24,0,\"px-3\"]],[[\"@notification\",\"@isRead\",\"@unreadCount\"],[[32,1],[32,1,[\"read\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[8,\"link-to\",[[24,0,\"navbar__dropdown__btn bg-white rounded-bottom\"]],[[\"@route\"],[\"notification\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  Ver todas as notificações\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"notifications\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/template.hbs"
    }
  });
  _exports.default = _default;
});