define("portal-talentrh/pods/components/dashboard/photo-album/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vk9l2xdy",
    "block": "{\"symbols\":[\"photo\",\"@dashboardSlideItem\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"h-100 ui-block board-block board-block--album board-height-large color-block--\",[32,2,[\"color\"]],\" text-white\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12 ui-block-title text-center font-weight-bold\"],[12],[2,\"\\n    \"],[1,[32,2,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12 content-album pb-5\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,0,[\"album\"]],[32,0,[\"album\",\"photos\",\"isFulfilled\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"autoplay\",\"arrows\",\"slidesToScroll\",\"slidesToShow\",\"autoplaySpeed\",\"dots\"],[true,false,1,1,8000,false]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"album\",\"photos\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"h-100\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"display-flex-dashboard\"],[12],[2,\"\\n              \"],[10,\"img\"],[15,\"src\",[32,1,[\"path\"]]],[15,5,[32,0,[\"maxHeight\"]]],[12],[13],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"font-weight-bold d-block mt-2 text-center\"],[12],[1,[32,0,[\"album\",\"description\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[32,2,[\"refreshTimeInMilliseconds\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dashboard-action-timer\",[],[[\"@tickAction\",\"@timer\"],[[30,[36,0],[[32,0,[\"refreshDashboard\"]]],null],[32,2,[\"refreshTimeInMilliseconds\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"-track-array\",\"each\",\"slick-slider\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/dashboard/photo-album/template.hbs"
    }
  });
  _exports.default = _default;
});