define("portal-talentrh/pods/components/meeting-attachments-itens/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['col-md-6', 'mb-3'],
    // Services
    ajax: Ember.inject.service(),
    fileDownload: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Tasks
    download: (0, _emberConcurrency.task)(function* () {
      const id = Ember.get(this, 'value.id');
      const name = Ember.get(this, 'value.name');
      let data = yield this.ajax.request(`/meetingAttachments/${id}/signedLink`);
      this.fileDownload.downloadFile(data, name);
    }),
    // Actions
    actions: {
      delete(item) {
        this.swal.confirm('Tem certeza que deseja remover este anexo?').then(result => {
          if (!result.value) {
            return;
          }
          item.destroyRecord().catch(() => {
            this.swal.error('Não foi possível remover estiver anexo');
          });
        });
      }
    }
  });
  _exports.default = _default;
});