define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTeamMemberPerformanceManagementEmployeeEvaluationsRoute extends Ember.Route {
    setupController(controller, model) {
      controller.filterSelected = null;
      if (controller.newPerformanceModule) {
        controller.loadFromGraphQl.perform(model.id);
        return;
      }
      controller.loadAllEmployeeEvaluations.perform(model.id);
    }
    deactivate() {
      console.log('deactivate');
      this.controller.set('evaluations', []);
      this.controller.set('evaluationSelected', null);
      this.controller.set('employeeEvaluation', null);
      this.controller.set('feedback', null);
    }
  }
  _exports.default = ViewTeamMemberPerformanceManagementEmployeeEvaluationsRoute;
});