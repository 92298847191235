define("portal-talentrh/pods/components/user-preview-insight/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserPreviewInsightComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('session.showUserPreviewInsight'), _dec3 = Ember.computed.alias('session.userPreviewInsight'), _dec4 = Ember.computed.alias('session.previewInsight'), _dec5 = Ember.computed.alias('session.previewInsightLed'), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class UserPreviewInsightComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "showUserPreviewInsight", _descriptor2, this);
      _initializerDefineProperty(this, "userPreviewInsight", _descriptor3, this);
      _initializerDefineProperty(this, "previewInsight", _descriptor4, this);
      _initializerDefineProperty(this, "previewInsightLed", _descriptor5, this);
      this.position = this.position || 'left';
      this.possiblePositions = ['bot', 'right', 'top'];
    }
    insertElement(element) {
      this._enablePopup(element);
    }
    destroyElement() {
      this._disablePopup();
    }
    _enablePopup(element) {
      element.addEventListener('mouseover', () => this._onMouseover(element), false);
      element.addEventListener('mouseleave', () => this._onMouseleave(element), false);
    }
    _disablePopup() {
      this.showUserPreviewInsight = false;
      document.removeEventListener('keydown', this.keydowEvent, false);
    }
    _onMouseover(element) {
      Ember.run.cancel(this.openPopupLater);
      const {
        user
      } = this.args;
      let openPopupLater = Ember.run.later(() => {
        if (this.showUserPreviewInsight || !user) {
          return;
        }
        this._openPopup(element);
        this._hideOnKeydown();
      }, 500);
      this.openPopupLater = openPopupLater;
    }
    _onMouseleave() {
      if (this.showUserPreviewInsight) {
        this._disablePopup();
      }
      Ember.run.cancel(this.openPopupLater);
    }
    _openPopup(element) {
      const {
        user,
        insight,
        insightLed
      } = this.args;
      let position = this.position;
      let userPreviewInsightPopup = document.querySelector('.user-preview-resize');
      this.currentPositions = [...this.possiblePositions];
      let {
        left,
        top
      } = this._getPositions(position, element);
      top = top - 30;
      userPreviewInsightPopup.style.top = `${top}px`;
      userPreviewInsightPopup.style.left = `${left}px`;
      this.userPreviewInsight = user;
      this.previewInsight = insight;
      this.previewInsightLed = insightLed;
      this.showUserPreviewInsight = true;
    }
    _hideOnKeydown() {
      let keydowEvent = () => this._disablePopup();
      document.addEventListener('keydown', keydowEvent, false);
      this.keydowEvent = keydowEvent;
    }

    // Calcula a posição em relação ao elemento em que o popup será posicionado.
    _getPositions(position, element) {
      let popupWidth = -80;
      let popupHeight = 0;
      let elementRect = element.getBoundingClientRect();
      let top = elementRect.top;
      let left = elementRect.left;
      if (position === 'left') {
        left -= popupWidth;
        top += elementRect.height / 2 - popupHeight / 2;
      }
      if (position === 'right') {
        left += elementRect.width;
        top += elementRect.height / 2 - popupHeight / 2;
      }
      if (position === 'top') {
        left += elementRect.width / 2 - popupWidth / 2;
        top -= popupHeight;
      }
      if (position === 'bot') {
        left += elementRect.width / 2 - popupWidth / 2;
        top += elementRect.height;
      }

      // Executa de forma recursiva até encontrar uma posição possível para o popup.
      let newPosition = this._validatePositions(position, left, top, popupWidth, popupHeight);

      // Se retornar uma newPosition, é por que a position atual não é possível;
      if (newPosition && !this.staticPosition) {
        return this._getPositions(newPosition, element);
      } else {
        return {
          left,
          top
        };
      }
    }

    // Esse método verifica se a posição atual do popup está saindo da tela, caso esteja, calcula uma nova posição.
    _validatePositions(position, left, top, popupWidth, popupHeight) {
      let newPosition;
      let bot = top + popupHeight;
      let right = left + popupWidth;
      let cWidth = document.body.clientWidth;
      let cHeight = document.body.clientHeight - document.documentElement.scrollTop;
      if (left < 0 || top < 0 || right > cWidth || bot > cHeight) {
        // Caso esteja saindo da tela, tenta uma nova posição.
        newPosition = this.currentPositions.pop();
      }
      return newPosition;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showUserPreviewInsight", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userPreviewInsight", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "previewInsight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "previewInsightLed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "insertElement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "insertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyElement", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "destroyElement"), _class.prototype)), _class));
  _exports.default = UserPreviewInsightComponent;
});