define("portal-talentrh/pods/habitus/team-insights/total-insight-by-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SnCJXJpr",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"Header\"]],\"expected `Card.Header` to be a contextual component but found a string. Did you mean `(component Card.Header)`? ('portal-talentrh/pods/habitus/team-insights/total-insight-by-type/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"b\"],[12],[2,\"Total de insights por tipo\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"Body\"]],\"expected `Card.Body` to be a contextual component but found a string. Did you mean `(component Card.Body)`? ('portal-talentrh/pods/habitus/team-insights/total-insight-by-type/template.hbs' @ L6:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[4,[38,0],[[32,0,[\"init\"]]],null],[4,[38,1],[[32,0,[\"init\"]]],null],[12],[13],[2,\"\\n    \"],[10,\"canvas\"],[14,1,\"amount-of-feelings-by-type\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"-assert-implicit-component-helper-argument\",\"component\",\"ui-card\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/team-insights/total-insight-by-type/template.hbs"
    }
  });
  _exports.default = _default;
});