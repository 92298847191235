define("portal-talentrh/pods/application/navbar/feeling/icon/component", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    showTooltip: true,
    createPopover() {
      let currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let closeFeelingPopover = this.session.data.closeFeelingPopover;

      // Quando fechar o popover, o mesmo só abrirá novamente no dia seguinte.
      if (currentDate === closeFeelingPopover) {
        return;
      }
      Ember.run.later(() => {
        if (this.showTooltip) {
          (0, _jquery.default)('#feeling-popover').popover({
            content: 'Como você está se sentindo hoje?',
            placement: 'bottom',
            container: '.ui-navbar',
            template: '<div class="d-lg-block popover bg-primary felling-popover" role="tooltip"><div class="arrow"></div><strong class="popover-body d-block text-white"></strong></div>'
          }).popover('show');
        }
      }, 1000);

      // Fecha o Popover ao clicar na tela.
      let eventListener = () => {
        (0, _jquery.default)('#feeling-popover').removeClass('d-lg-block').addClass('d-none');
        (0, _jquery.default)('#feeling-popover').popover('dispose');
        this.session.set('data.closeFeelingPopover', (0, _moment.default)().format('YYYY-MM-DD'));
        document.getElementById('page-application').removeEventListener('click', eventListener);
      };
      document.getElementById('page-application').addEventListener('click', eventListener);
    },
    actions: {
      onIconClick() {
        Ember.set(this, 'inSecondStep', false);
        Ember.set(this, 'selectedFeeling.factor', null);
        Ember.set(this, 'selectedFeeling.motive', "");
        Ember.set(this, 'selectedFeeling', {});
        Ember.set(this, 'showTooltip', false);
      }
    }
  });
  _exports.default = _default;
});