define("portal-talentrh/pods/feed/me/index/activities/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JbY0P3sk",
    "block": "{\"symbols\":[\"@value\",\"@icon\",\"@link\"],\"statements\":[[10,\"li\"],[15,0,[31,[\"list-group-item align-items-center border-0 px-0 py-2 bg-light \",[30,[36,0],[[32,1],\"d-flex\",\"d-none\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"avatar-3 border text-primary position-relative\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,2],\" position-absolute centralize\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"a\"],[15,6,[32,3]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,0,\"ml-2 text-truncate\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 text-wrap\"],[12],[10,\"strong\"],[12],[1,[32,1]],[13],[2,\" \"],[1,[32,0,[\"computedLabel\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/activities/item/template.hbs"
    }
  });
  _exports.default = _default;
});