define("portal-talentrh/pods/components/album-images-select/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'picture-uploader',
    maxImages: 50,
    createSubtitle: false,
    renderPreview: function (element) {
      /*jshint loopfunc: true */
      var files = element[0].files;
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let oFReader = new FileReader();
        oFReader.readAsDataURL(files[i]);
        oFReader.onload = oFREvent => {
          var img = new Image();
          img.src = oFREvent.target.result;
          img.onload = () => {
            this.postImages.pushObject({
              preview: oFREvent.target.result,
              form: file
            });
          };
        };
      }
    },
    actions: {
      click(item) {
        document.getElementById(item).click();
      },
      selectImage() {
        this.renderPreview((0, _jquery.default)(`#${this.elementId} > input`));
      },
      deleteImage(index) {
        this.postImages.removeAt(index);
      },
      chooseImage: function () {
        if (this.urlPreview) {
          this.set('urlPreview', null);
          this.set('promiseUpload', null);
        } else {
          document.querySelector('.upload-image').click();
        }
      }
    }
  });
  _exports.default = _default;
});