define("portal-talentrh/pods/components/post-preview/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const POST_TYPES = {
    ad: 'um classificado',
    announcement: 'um comunicado',
    event: 'um evento',
    message: 'um post',
    news: 'uma notícia',
    survey: 'uma enquete'
  };
  let PostPreviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, (_class = class PostPreviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "postPreview", _descriptor6, this);
      _initializerDefineProperty(this, "postUser", _descriptor7, this);
      _initializerDefineProperty(this, "postTypeText", _descriptor8, this);
      _initializerDefineProperty(this, "postTag", _descriptor9, this);
      _initializerDefineProperty(this, "showPostPreview", _descriptor10, this);
      _initializerDefineProperty(this, "postImages", _descriptor11, this);
      _initializerDefineProperty(this, "postVideos", _descriptor12, this);
    }
    setPostTypeText() {
      const typeText = POST_TYPES[this.postPreview.type];
      const groupCurrentUser = Ember.get(this, 'session.groups.groups');
      const isAdmin = this.permission.admin;
      const groupUser = groupCurrentUser.map(group => group.id);
      if (groupUser?.length || !isAdmin) {
        const groupUserIsOnGroupPost = groupUser.includes(this.postPreview.userGroup);
        this.groupUserIsOnGroupPost = groupUserIsOnGroupPost;
      }
      if (this.postPreview.branches && this.postPreview.branches.length) {
        const firstBranchName = this.postPreview.branches.firstObject.tradingName || this.postPreview.branches.firstObject.name;
        let branchText = "";
        if (firstBranchName) {
          branchText += ` na filial ${firstBranchName}`;
          if (this.postPreview.branches.length > 1) {
            branchText += ` e em mais ${this.postPreview.branches.length - 1} filial(is)`;
          }
        }
        this.postTypeText = `${typeText} ${branchText}`;
      }
      this.postTypeText = typeText;
    }
    *getSharedPost() {
      try {
        const {
          post
        } = this.args;
        const isSharedPost = post.isSharedPost ? post.isSharedPost : null;
        let userActive;
        if (isSharedPost) {
          const id = post.id;
          const response = yield this.fetch.request(`/sharedPosts/post/${id}`, {
            host: 'core'
          });
          this.postPreview = response.post;
          const user = yield this.store.loadRecord('user', `/users/${this.postPreview.user}`);
          this.postUser = user;
          userActive = user.active;
          if (this.postPreview.postTag) {
            this.postTag = yield this.store.peekRecord('post-tag', this.postPreview.postTag);
          }
        } else {
          this.postPreview = post;
          this.postUser = post.user;
          userActive = this.postUser.get('active');
          if (this.postPreview.postTag) {
            this.postTag = this.postPreview.get('postTag');
          }
        }
        if (this.postPreview.complained || !userActive) {
          this.showPostPreview = false;
        }
        if (this.postPreview.images) {
          this.postImages = this.postPreview.images.filter(postImage => {
            return !postImage.summernote;
          });
        }
        if (this.postPreview.videos) {
          this.postVideos = this.postPreview.videos.filter(postVideo => {
            return !postVideo.summerNote;
          });
        }
        this.setPostTypeText();
      } catch (e) {
        console.error(e);
        this.showPostPreview = false;
        this.postPreview = null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "postPreview", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "postUser", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "postTypeText", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "postTag", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showPostPreview", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "postImages", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "postVideos", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSharedPost", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getSharedPost"), _class.prototype)), _class));
  _exports.default = PostPreviewComponent;
});