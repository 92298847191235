define("portal-talentrh/pods/components/post-filter-dropdown/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['dropdown-item', 'px-2'],
    classNameBindings: ['visibleClass'],
    permission: Ember.inject.service(),
    visibleClass: Ember.computed('permission.{accessProfile.admin,companyConfig.permitCreateVacancyAnnouncement,user.id}', 'tag.{param,profileUser}', function () {
      let visibleClass = 'd-block';
      if (this.tag && this.tag.param === 'vacancy' && !this.permission.companyConfig.permitCreateVacancyAnnouncement) {
        visibleClass = 'd-none';
      }
      if (Boolean(this.tag && this.tag.param === 'archived' && this.tag.profileUser) && this.tag.profileUser.get('id') !== this.permission.user.id && !this.permission.accessProfile.admin) {
        visibleClass = 'd-none';
      }
      return visibleClass;
    })
  });
  _exports.default = _default;
});