define("portal-talentrh/pods/search/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    setupController(controller, model) {
      this._super(controller, model);
      return this.controller.search.perform();
    }
  });
  _exports.default = _default;
});