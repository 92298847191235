define("portal-talentrh/pods/profile/show/information/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfileShowIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model.user'), _dec5 = Ember.computed.alias('model.preference'), _dec6 = Ember.computed.or('user.{facebookUrl,linkedinUrl,instagramUrl,twitterUrl,googleplusUrl,skype}'), _dec7 = Ember.computed.or('user.{favoriteDishes,favoriteTeam,favoriteSport,otherSport,hobbies,favoriteAuthor}'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('user.branches.length'), _dec11 = Ember.computed('user.branches.length', 'user.company.name'), _dec12 = Ember.computed('preference.{hideAddress,hideContact,hideEmergencyContact}', 'showBirthDate', 'user.id', 'userPermission.accessProfile'), _dec13 = Ember.computed('userSubordinatesIds', 'user.id', 'permission.userPermission.accessProfile.{admin,allowRegistrationDataManagement,allowHistoricalDataMyTeam}'), (_class = class ProfileShowIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "preference", _descriptor5, this);
      _initializerDefineProperty(this, "showSocialNetworks", _descriptor6, this);
      _initializerDefineProperty(this, "showAdditionalInfo", _descriptor7, this);
      _initializerDefineProperty(this, "showBirthDate", _descriptor8, this);
      _initializerDefineProperty(this, "userSubordinatesIds", _descriptor9, this);
    }
    get showRegistration() {
      let registration = this.user.registration;
      return registration && registration !== '0';
    }
    get branchName() {
      let name = "";
      if (this.user.branches?.length) {
        this.user.branches.forEach((branch, index) => {
          name += (index != 0 ? " / " : "") + (branch.get('tradingName') ? branch.get('tradingName') : branch.get('name'));
        });
      } else {
        name = "Não informado";
      }
      return name;
    }
    get companyName() {
      let name = "";
      if (this.user.branches?.length) {
        this.user.branches.forEach((branch, index) => {
          name += (index != 0 ? " / " : "") + branch.get('name');
        });
      } else {
        name = this.user.company.get('name');
      }
      return name;
    }
    *getShowBirthDate() {
      if (!this.preference) {
        this.showBirthDate = true;
        return;
      }
      const accessProfile = this.permission.accessProfile;
      if (accessProfile.admin || accessProfile.allowRegistrationDataManagement) {
        this.showBirthDate = true;
        return;
      }
      if (this.user.id == this.permission.user.id) {
        this.showBirthDate = true;
        return;
      }
      if (!accessProfile.admin && !accessProfile.allowRegistrationDataManagement && accessProfile.allowHistoricalDataMyTeam) {
        const userSubordinates = yield this.fetch.request('users/userSubordinateAjax');
        if (userSubordinates.length) {
          const userSubordinatesIds = userSubordinates.map(u => Number(u.id));
          this.userSubordinatesIds = userSubordinatesIds;
          this.showBirthDate = userSubordinatesIds.includes(Number(this.user.id));
          return;
        }
      }
      this.showBirthDate = !this.preference.hideBirthDate;
    }
    *getExternalLine() {
      const getExternalLine = yield this.fetch.request(`/users/externalLine`, {
        data: {
          id: this.user.id
        }
      });
      Ember.set(this.user, 'externalLine', getExternalLine.users[0].externalLine);
    }
    get showPersonalData() {
      let userPermission = this.permission;
      let me = this.user.id;
      let thisIsMe = false;
      if (me == userPermission.user.id) {
        thisIsMe = true;
      }
      let {
        admin
      } = userPermission.accessProfile;
      if (thisIsMe || admin || userPermission && userPermission.accessProfile && userPermission.accessProfile.allowRegistrationDataManagement) {
        if (this.preference) {
          Ember.set(this, 'preference.hideAddress', false);
          if (this.preference.hideEmergencyContact != null) {
            Ember.set(this, 'preference.hideEmergencyContact', false);
          }
          if (this.preference.hideContact != null) {
            Ember.set(this, 'preference.hideContact', false);
          }
        }
      }
      return !this.preference.hideAddress || this.showBirthDate;
    }
    get birthDayFormat() {
      let userPermission = this.permission;
      let {
        admin,
        allowRegistrationDataManagement,
        allowHistoricalDataMyTeam
      } = userPermission.accessProfile;
      if (admin || allowRegistrationDataManagement) {
        return 'DD/MM/YYYY';
      }
      if (allowHistoricalDataMyTeam && this.userSubordinatesIds) {
        const isSubordinate = this.userSubordinatesIds.includes(Number(this.user.id));
        if (isSubordinate) return 'DD/MM/YYYY';
      }
      return 'DD/MM';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "preference", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showSocialNetworks", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showAdditionalInfo", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showBirthDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userSubordinatesIds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "branchName", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "branchName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "companyName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getShowBirthDate", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getShowBirthDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getExternalLine", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getExternalLine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showPersonalData", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showPersonalData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "birthDayFormat", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "birthDayFormat"), _class.prototype)), _class));
  _exports.default = ProfileShowIndexController;
});