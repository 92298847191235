define("portal-talentrh/services/user-data-loader", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    errorUser: null,
    get(userId) {
      return this.getTask.perform(userId);
    },
    getTask: (0, _emberConcurrency.task)(function* (userId) {
      try {
        let user = yield this.store.peekRecord('user', userId);
        if (Ember.isNone(user)) {
          Ember.debug(`[user-data-loader] Carrega usuário ${userId} da API`);
          user = yield this.store.findRecord('user', userId);
        } else {
          Ember.debug(`Usuario ${userId} presente no store`);
        }
        return user;
      } catch (error) {
        Ember.debug(`error: `, error);
        return this.createErrorUser();
      }
    }).drop(),
    createErrorUser() {
      let errorUser = this.errorUser;
      if (!errorUser) {
        errorUser = this.store.createRecord('user', {
          firstName: 'Usuário',
          lastName: 'Indisponível'
        });
        this.set('errorUser', errorUser);
      }
      return errorUser;
    }
  });
  _exports.default = _default;
});