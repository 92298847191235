define("portal-talentrh/pods/components/indicator-loader/renderer/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RendererComponent extends _component.default {
    get showFilter() {
      let {
        indicator
      } = this.args;
      return indicator.filters && !indicator.hideBtnFilter;
    }
  }
  _exports.default = RendererComponent;
});