define("portal-talentrh/services/user-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserCookieService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class UserCookieService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _defineProperty(this, "handled", false);
    }
    setInitialSessionCount() {
      this.session.set('data.logged', 1);
      this.session.set('data.sessionCount', 0);
    }
    invalidateAuthCookie() {
      window.dispatchEvent(new Event('CHAT_WIDGET_LOGOUT'));
      setTimeout(() => {
        this.session.invalidate();
      }, 200);
    }
    getAuthTokenFromCookie() {
      const emberSimpleAuthRegex = /ember_simple_auth-session=([^;]+)/;
      const cookie = document.cookie;
      const matches = cookie.match(emberSimpleAuthRegex);
      if (!matches) {
        return null;
      }
      const decoded = decodeURIComponent(matches[1]);
      const parsed = JSON.parse(decoded);
      return parsed.authenticated.token;
    }
    async deleteUserDbToken() {
      try {
        await this.store.query('user-token', {
          user: this.session.user.id
        }).then(records => {
          records.forEach(record => {
            record.destroyRecord();
          });
        }).catch(e => {
          throw new Error(e);
        });
      } catch (e) {
        throw 'Error on delete user db token', e;
      } finally {
        this.session.set('data.logged', false);
        this.handled = true;
      }
    }
    async handleDeleteSession() {
      await this.deleteUserDbToken();
      await this.swal.fire({
        title: 'Sua sessão anterior foi encerrada!',
        text: 'Informe suas credenciais de acesso novamente.'
      }).then(() => {
        this.invalidateAuthCookie();
      });
    }
    async handleSessionCount() {
      const sessionCount = this.session.data.sessionCount;
      const logged = this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!sessionCount && !logged && !isReload) {
        return this.handleDeleteSession();
      }
      this.session.set('data.sessionCount', sessionCount + 1);
    }
    handleBeforeUnload() {
      const that = this;
      window.addEventListener('beforeunload', function () {
        const sessionCount = that.session.data.sessionCount;
        if (sessionCount > 0) {
          that.session.set('data.sessionCount', sessionCount - 1);
          that.session.set('data.logged', false);
        }
      });
    }
    async handle() {
      if (this.handled) {
        return;
      }
      this.handleBeforeUnload();
      const isLogged = this.session.data.sessionCount || this.session.data.logged;
      const isReload = window.performance.navigation.type === 1;
      if (!isLogged && !isReload) {
        return this.handleDeleteSession();
      }
      try {
        const token = this.getAuthTokenFromCookie();
        const userToken = await this.store.queryRecord('user-token', {
          user: this.session.user.id,
          token
        });
        if (!userToken) {
          return this.handleDeleteSession();
        }
        this.handled = true;
        return this.handleSessionCount();
      } catch (e) {
        console.error(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserCookieService;
});