define("portal-talentrh/pods/forum/topic/show/comment-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xZKPDClW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-end\"],[12],[2,\"\\n  \"],[8,\"textarea\",[[24,0,\"form-control flex-fill\"],[24,\"placeholder\",\"Escreva um comentário...\"]],[[\"@value\",\"@keyDown\"],[[32,0,[\"comment\",\"text\"]],[30,[36,0],[[32,0],\"keyDown\"],null]]],null],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"editComment\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"btn btn-link w-100 text-body px-1\"],[14,\"title\",\"Cancelar\"],[15,\"onclick\",[30,[36,0],[[32,0],\"close\"],null]],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-times fa-lg\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-link w-100 px-1\"],[14,\"title\",\"Publicar comentário\"],[15,\"onclick\",[30,[36,2],[[32,0,[\"createComment\"]]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-paper-plane fa-lg\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/forum/topic/show/comment-form/template.hbs"
    }
  });
  _exports.default = _default;
});