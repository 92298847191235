define("portal-talentrh/helpers/truncate-text", ["exports", "portal-talentrh/utils/strip-html"], function (_exports, _stripHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncateText = truncateText;
  function truncateText(params, hash) {
    let value = params[0] || '';
    let len = hash.limit;
    let breakLines = hash.breakLines || false;
    let out = '';
    if (value) {
      value = (0, _stripHtml.default)(value.string || value, breakLines);
      out = value.substr(0, len);
      if (value.length > len) {
        out += '...';
      }
    }
    return out;
  }
  var _default = Ember.Helper.helper(truncateText);
  _exports.default = _default;
});