define("portal-talentrh/pods/human-resources/chat-history/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dTtCQt/g",
    "block": "{\"symbols\":[\"@message\"],\"statements\":[[10,\"div\"],[14,0,\"row no-gutters align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-3 font-weight-bold text-truncate\"],[15,\"title\",[32,1,[\"fromName\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"fromName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-3 text-center text-truncate\"],[15,\"title\",[30,[36,1],[[32,1,[\"groupName\"]],[32,1,[\"toName\"]]],null]],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"groupName\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"h6\"],[14,0,\"text-truncate mb-0\"],[12],[1,[32,1,[\"groupName\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"message\",\"receivers\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"small\"],[12],[1,[32,0,[\"receivers\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1,[\"toName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-2 text-center text-truncate\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n    \"],[1,[32,1,[\"body\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"or\",\"moment-format\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/human-resources/chat-history/item/template.hbs"
    }
  });
  _exports.default = _default;
});