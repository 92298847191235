define("portal-talentrh/pods/components/arrow-back-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "L6dWDjRq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"w-100\"],[12],[2,\"\\n  \"],[11,\"span\"],[24,0,\"text-primary cursor-pointer d-flex\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"back\"]]],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-chevron-left font-weight-bold d-flex align-items-center mr-2\"],[12],[13],[2,\" Voltar\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/arrow-back-route/template.hbs"
    }
  });
  _exports.default = _default;
});