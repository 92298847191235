define("portal-talentrh/models/comment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _attr.default)('date'),
    isResponse: (0, _attr.default)('number'),
    likesCount: (0, _attr.default)('number'),
    mentions: (0, _attr.default)('raw'),
    responsesCount: (0, _attr.default)('number'),
    responseTo: (0, _attr.default)('number'),
    text: (0, _attr.default)('string'),
    user: (0, _attr.default)('number'),
    usersLikedId: (0, _attr.default)('raw'),
    // Associations
    post: (0, _relationships.belongsTo)('post')
  });
  _exports.default = _default;
});