define("portal-talentrh/pods/components/indicator-block/img-feeling/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ImgFeelingComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ImgFeelingComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "imgNames", [{
        type: 'Feliz',
        src: 'happy',
        text: 'Feliz'
      }, {
        type: 'Apaixonado',
        src: 'in-love',
        text: this.permission.user.gender === 'F' ? 'Apaixonada' : 'Apaixonado'
      }, {
        type: 'Irritado',
        src: 'angry',
        text: this.permission.user.gender === 'F' ? 'Irritada' : 'Irritado'
      }, {
        type: 'Cansado',
        src: 'tired',
        text: this.permission.user.gender === 'F' ? 'Cansada' : 'Cansado'
      }, {
        type: 'Inteligente',
        src: 'smart',
        text: 'Inteligente'
      }, {
        type: 'Entediado',
        src: 'bored',
        text: this.permission.user.gender === 'F' ? 'Entediada' : 'Entediado'
      }, {
        type: 'Ansioso',
        src: 'eager',
        text: this.permission.user.gender === 'F' ? 'Ansiosa' : 'Ansioso'
      }, {
        type: 'Legal',
        src: 'cool',
        text: 'Legal'
      }, {
        type: 'Desanimado',
        src: 'dispirited',
        text: this.permission.user.gender === 'F' ? 'Desanimada' : 'Desanimado'
      }, {
        type: 'Triste',
        src: 'sad',
        text: 'Triste'
      }, {
        type: 'Divertido',
        src: 'funny',
        text: this.permission.user.gender === 'F' ? 'Divertida' : 'Divertido'
      }, {
        type: 'Entusiasmado',
        src: 'excited',
        text: this.permission.user.gender === 'F' ? 'Entusiasmada' : 'Entusiasmado'
      }]);
    }
    get src() {
      const cdn = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling';
      let found = this.imgNames.findBy('type', this.args.feelingName) || {
        type: 'default',
        src: 'default'
      };
      found = found.src;
      if (this.args.feelingName && this.args.typeSelected !== null && this.args.typeSelected !== undefined && this.args.typeSelected !== this.args.feelingName) {
        return `${cdn}/${found}-disabled.svg`;
      }
      return `${cdn}/${found}.svg`;
    }
    get title() {
      const textFemale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonada',
        Irritado: 'Irritada',
        Cansado: 'Cansada',
        Inteligente: 'Inteligente',
        Entediado: 'Entediada',
        Ansioso: 'Ansiosa',
        Legal: 'Legal',
        Desanimado: 'Desanimada',
        Triste: 'Triste',
        Divertido: 'Divertida',
        Entusiasmado: 'Entusiasmada'
      };
      const textMale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonado',
        Irritado: 'Irritado',
        Cansado: 'Cansado',
        Inteligente: 'Inteligente',
        Entediado: 'Entediado',
        Ansioso: 'Ansioso',
        Legal: 'Legal',
        Desanimado: 'Desanimado',
        Triste: 'Triste',
        Divertido: 'Divertido',
        Entusiasmado: 'Entusiasmado'
      };
      if (this.args.feelingUser) {
        const userLed = this.store.peekRecord('user', this.args.feelingUser);
        return userLed.get('gender') === 'F' ? textFemale[this.args.feelingName] : textMale[this.args.feelingName];
      }
      return this.args.feelingName;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ImgFeelingComponent;
});