define("portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/user-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "s0Z4uQBf",
    "block": "{\"symbols\":[\"Avatar\"],\"statements\":[[8,\"ui-avatar\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4 mb-2\"],[16,\"alt\",[30,[36,1],[[32,0,[\"user\",\"firstName\"]],[32,0,[\"user\",\"lastName\"]]],null]],[16,\"title\",[32,0,[\"popoverContent\"]]]],[[\"@src\"],[[30,[36,0],[[32,0,[\"user\",\"avatarUrl\"]],[32,0,[\"user\",\"avatarUrl\"]],\"/images/user-sem-imagem.png\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/user-avatar/template.hbs"
    }
  });
  _exports.default = _default;
});