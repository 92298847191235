define("portal-talentrh/pods/components/modal-manager-indicator-filter/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ModalManagerIndicatorFilterComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed.alias('permission.accessProfile.manageOnlyBranch'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class ModalManagerIndicatorFilterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "manageOnlyBranch", _descriptor5, this);
      _initializerDefineProperty(this, "saveFilter", _descriptor6, this);
      _initializerDefineProperty(this, "indicatorToSetFilter", _descriptor7, this);
      _initializerDefineProperty(this, "specificFilterTitle", _descriptor8, this);
      _initializerDefineProperty(this, "listJobRoles", _descriptor9, this);
      _initializerDefineProperty(this, "endDate", _descriptor10, this);
      _initializerDefineProperty(this, "startDate", _descriptor11, this);
      _initializerDefineProperty(this, "ledType", _descriptor12, this);
      _initializerDefineProperty(this, "haveIndirectLeds", _descriptor13, this);
    }
    init() {
      this.show = true;
      this.endDate = null;
      this.startDate = null;
      if (this.args.indicatorToSetFilter && this.args.indicatorToSetFilter.filterData) {
        let {
          endDate,
          startDate,
          ledType
        } = this.args.indicatorToSetFilter.filterData;
        if (endDate && startDate && (0, _moment.default)(endDate).isValid() && (0, _moment.default)(startDate).isValid()) {
          this.endDate = (0, _moment.default)(endDate).toDate();
          this.startDate = (0, _moment.default)(startDate).toDate();
        }
        if (ledType) {
          this.ledType = ledType;
        }
        this.specificFilterTitle = this.generateSpecificFilterTitle();
        this.generateJobFunction();
        this.getIndirectLedUsers.perform();
      }
    }
    *getIndirectLedUsers() {
      try {
        const response = yield this.ajax.request(`users/${this.session.user.id}/indirectLed`);
        if (response && response.length) {
          Ember.set(this, 'haveIndirectLeds', true);
        }
      } catch (e) {
        console.log(e);
        return this.swal.catch(e);
      }
    }
    generateSpecificFilterTitle() {
      //Página clima organizacional
      if (this.args.indicatorToSetFilter.name === 'generalClimate') {
        return 'de Clima Predominante';
      } else if (this.args.indicatorToSetFilter.name === 'negativeFeelingsForEmployee') {
        return 'de Sentimentos Negativos por Colaborador';
      } else if (this.args.indicatorToSetFilter.name === 'amountOfFeelingsByType') {
        return 'de Sentimentos por Tipo';
      }
      //Página perfil da empresa
      else if (this.args.indicatorToSetFilter.name === 'salaryGeneralInfo') {
        return 'de Salários';
      } else if (this.args.indicatorToSetFilter.name === 'schoolingByType') {
        return 'de Escolaridade';
      } else if (this.args.indicatorToSetFilter.name === 'jobfunctionsByType') {
        return 'de Distribuição de Funções';
      } else if (this.args.indicatorToSetFilter.name === 'ageRange') {
        return 'de Faixa Etária';
      } else if (this.args.indicatorToSetFilter.name === 'companyYears') {
        return 'de Usuários por Tempo de Empresa';
      } else if (this.args.indicatorToSetFilter.name === 'absencesByType') {
        return 'de Ausências por Tipo';
      } else if (this.args.indicatorToSetFilter.name === 'usersByGender') {
        return 'de Usuários por Sexo';
      } else if (this.args.indicatorToSetFilter.name === 'hiresPerYear') {
        return 'de Contratação';
      } else if (this.args.indicatorToSetFilter.name === 'generalIndicators') {
        return 'de Indicadores Gerais';
      } else if (this.args.indicatorToSetFilter.name === 'disapprovalAndApproval') {
        return 'de Avaliações de Experiência';
      } else if (this.args.indicatorToSetFilter.name === 'functionEvaluationStatistics') {
        return 'de Avaliações de Função';
      } else if (this.args.indicatorToSetFilter.name === 'functionEvaluationChart') {
        return 'de Notas por Função';
      } else if (this.args.indicatorToSetFilter.name === 'totalOfEvaluationsByType') {
        return 'de Avaliações Criadas por Tipo';
      } else if (this.args.indicatorToSetFilter.name === 'evaluationsOpenByType') {
        return 'de Avaliações Abertas por Tipo';
      } else if (this.args.indicatorToSetFilter.name === 'evaluationsDelayedOrExpired') {
        return 'de Avaliações Dentro do prazo x Atrasadas x Expiradas';
      } else if (this.args.indicatorToSetFilter.name === 'evaluationsDelayedByType') {
        return 'de Avaliações Atrasadas por Tipo';
      } else if (this.args.indicatorToSetFilter.name === 'expiredOrCanceledByAppraiser') {
        return 'de Finalizadas e Canceladas por Avaliador Responsável';
      } else if (this.args.indicatorToSetFilter.name === 'employeeEvolutionByFunction') {
        return 'de Avaliação de Desempenho por Empregado';
      }
    }
    generateIndicators() {
      let indicatorToSetFilter = this.processesFilters(this.args.indicatorToSetFilter);
      let filterData = Ember.get(indicatorToSetFilter, 'filterData');
      if ((0, _moment.default)(filterData.startDate).isAfter(filterData.endDate)) {
        this.swal.warning('A data final não pode ser anterior a data inicial');
      } else if ((filterData.startDate || !indicatorToSetFilter.filters.startDate) && (filterData.endDate || !indicatorToSetFilter.filters.endDate)) {
        if (this.saveFilter && indicatorToSetFilter && indicatorToSetFilter.isGeneralFilters) {
          let storageFilters = {
            startDate: filterData.startDate,
            endDate: filterData.endDate
          };
          if (filterData.branch) {
            storageFilters.branch = filterData.branch.map(o => {
              return {
                id: o.id,
                name: o.name
              };
            });
          }
          if (filterData.department) {
            storageFilters.department = filterData.department.map(o => {
              return {
                id: o.id,
                name: o.name
              };
            });
          }
          if (indicatorToSetFilter.filters.filterByLedUsers) {
            storageFilters.ledType = this.ledType;
          }
          if (filterData.branch) {
            storageFilters.branch = filterData.branch.map(o => {
              return {
                id: o.id,
                companyName: o.companyName
              };
            });
          }
          if (filterData.jobRole) {
            storageFilters.jobRole = filterData.jobRole.map(o => {
              return {
                id: o.id,
                title: o.title
              };
            });
          }
          if (filterData.jobFunction) {
            storageFilters.jobFunction = filterData.jobFunction.map(o => {
              return {
                id: o.id,
                title: o.title
              };
            });
          }
          localStorage.setItem('indicatorFilters', JSON.stringify(storageFilters));
        }
        this.show = false;
        if (this.args.loadIndicators) {
          this.args.loadIndicators(indicatorToSetFilter);
        }
      } else {
        this.swal.warning('Data inicial e final devem ser informadas');
      }
    }
    //Faço uma lista de JobRoles selecionados para listar os JobFunctions
    generateJobFunction() {
      let indicatorToSetFilter = this.processesFilters(this.args.indicatorToSetFilter);
      let filterData = Ember.get(indicatorToSetFilter, 'filterData');
      let listJobRoles = [];
      if (filterData.jobRole && filterData.jobRole.length > 0) {
        listJobRoles = filterData.jobRole.map(o => {
          return {
            id: o.id
          };
        });
      }
      this.listJobRoles = listJobRoles;
    }
    clearIndicatorFilters() {
      localStorage.removeItem('indicatorFilters');
      Ember.set(this.args.indicatorToSetFilter, 'filterData', {});
      Ember.set(this.args.generalFilters, 'filterData.jobRole', []);
      Ember.set(this.args.generalFilters, 'filterData.jobFunction', []);
      this.endDate = null;
      this.startDate = null;
      this.ledType = 'd';
      this.generalFilters = {};
    }

    // Processa os dados selecionado no filtro e adiciona em indicatorToSetFilter no formato adequado
    processesFilters(indicatorToSetFilter) {
      Ember.set(indicatorToSetFilter, 'filterData.startDate', this.startDate ? this.startDate : null);
      Ember.set(indicatorToSetFilter, 'filterData.endDate', this.endDate ? this.endDate : null);
      if (indicatorToSetFilter.filters.filterByLedUsers) {
        Ember.set(indicatorToSetFilter, 'filterData.ledType', this.ledType);
      }
      if (indicatorToSetFilter.filterData.branch) {
        Ember.set(indicatorToSetFilter, 'filterData.branch', indicatorToSetFilter.filterData.branch);
      }
      if (indicatorToSetFilter.filterData.department) {
        Ember.set(indicatorToSetFilter, 'filterData.department', indicatorToSetFilter.filterData.department);
      }
      if (indicatorToSetFilter.filterData.jobRole) {
        Ember.set(indicatorToSetFilter, 'filterData.jobRole', indicatorToSetFilter.filterData.jobRole);
      }
      if (indicatorToSetFilter.filterData.jobFunction) {
        Ember.set(indicatorToSetFilter, 'filterData.jobFunction', indicatorToSetFilter.filterData.jobFunction);
      }
      if (Ember.get(indicatorToSetFilter, 'isGeneralFilters')) {
        if (this.startDate) {
          Ember.set(this.args.generalFilters, 'filterData.startDate', this.startDate);
        }
        if (this.endDate) {
          Ember.set(this.args.generalFilters, 'filterData.endDate', this.endDate);
        }
      }
      return indicatorToSetFilter;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "manageOnlyBranch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saveFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "indicatorToSetFilter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "specificFilterTitle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "listJobRoles", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "ledType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'd';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "haveIndirectLeds", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getIndirectLedUsers", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getIndirectLedUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateIndicators", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "generateIndicators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateJobFunction", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "generateJobFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearIndicatorFilters", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "clearIndicatorFilters"), _class.prototype)), _class));
  _exports.default = ModalManagerIndicatorFilterComponent;
});