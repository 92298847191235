define("portal-talentrh/pods/components/insight-action-buttons/access-frequency/warning-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vcxG2Bu2",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"@openModal\",\"@leader\",\"@onSubmit\"],\"statements\":[[8,\"ui-modal\",[[24,0,\"w-100\"]],[[\"@show\",\"@size\",\"@toggle\"],[[32,3],\"lg\",[30,[36,1],[[30,[36,0],[[32,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Frequência de acesso\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"modal-warning-body\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[12],[1,[32,4]],[2,\", algumas dicas importantes antes de você registrar a ação:\"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"pt-4\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"Seu liderado possui outras ausências de acesso em insights anteriores a esse.\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"É recomendado tratar os insights diariamente, para poder entender melhor a causa da ausência de acesso.\"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"Caso seja registrada a ação neste insight, os anteriores serão concluídos.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,1],[[32,5]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Registrar ação\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/insight-action-buttons/access-frequency/warning-modal/template.hbs"
    }
  });
  _exports.default = _default;
});