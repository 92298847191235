define("portal-talentrh/pods/portal-manager/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    integration: Ember.computed.or('permission.companyConfig.communicationApi', 'permission.companyConfig.permitImports'),
    isAdmin: Ember.computed.alias('permission.admin'),
    showMenuConsultMyTeam: Ember.computed.or('permission.accessProfile.allowConsultMyTeam', 'permission.admin'),
    showMenuCompensatoryTime: Ember.computed('integration', 'permission.{accessProfile.allowCompensatoryTimeMyTeam,admin,companyConfig.communicationApi}', function () {
      return this.permission.companyConfig.communicationApi && this.permission.accessProfile.allowCompensatoryTimeMyTeam || this.permission.companyConfig.communicationApi && this.permission.admin;
    }),
    showMenuPayment: Ember.computed('integration', 'permission.{accessProfile.allowReceiptMyTeam,admin}', function () {
      return this.integration && this.permission.accessProfile.allowReceiptMyTeam || this.integration && this.permission.admin;
    }),
    showMenuFeelings: Ember.computed('permission.accessProfile.{allowFeelingsTodayMyTeam,allowReceiptMyTeam}', 'permission.companyConfig.{permitReceipt,permitUserFeeling}', 'permission.{admin,manager}', function () {
      return this.permission.accessProfile.allowFeelingsTodayMyTeam && this.permission.companyConfig.permitUserFeeling || this.permission.admin && this.permission.companyConfig.permitUserFeeling;
    }),
    showMenuTimeCard: Ember.computed('integration', 'permission.{accessProfile.allowTimeCardMyTeam,admin}', function () {
      return this.integration && this.permission.accessProfile.allowTimeCardMyTeam || this.integration && this.permission.admin;
    }),
    showMenuHistoricalData: Ember.computed('integration', 'isAdmin', 'permission.accessProfile.allowHistoricalDataMyTeam', function () {
      return this.integration && this.permission.accessProfile.allowHistoricalDataMyTeam || this.integration && this.isAdmin;
    }),
    showMenuIndicator: Ember.computed.or('permission.accessProfile.allowIndicatorTeamProfile', 'permission.accessProfile.allowIndicatorPerformanceMyTeam', 'permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam', 'permission.accessProfile.allowIndicatorEngagementMyTeam', 'permission.admin'),
    showMenuIndicatorEngagement: Ember.computed.or('permission.accessProfile.allowIndicatorEngagementMyTeam', 'permission.admin'),
    showMenuIndicatorOrganizationalClimate: Ember.computed.or('permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam', 'permission.admin'),
    showMenuIndicatorPerformance: Ember.computed.or('permission.accessProfile.allowIndicatorPerformanceMyTeam', 'permission.admin')
  });
  _exports.default = _default;
});