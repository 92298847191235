define("portal-talentrh/pods/components/indicators/company-engagement/access-frequency/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AccessFrequencyComponent = (_dec = Ember.computed.alias('args.accessFrequenciesData'), _dec2 = Ember.computed.alias('accessFrequenciesData.groupBy'), _dec3 = Ember._action, (_class = class AccessFrequencyComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "accessFrequenciesData", _descriptor, this);
      _initializerDefineProperty(this, "groupBy", _descriptor2, this);
    }
    initChart(canvas) {
      const that = this;
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'line',
        data: {
          labels: that.accessFrequenciesData.labels,
          datasets: [{
            type: 'bar',
            label: 'Web',
            data: that.accessFrequenciesData.accessWeb,
            backgroundColor: '#54548C'
          }, {
            type: 'bar',
            label: 'App',
            data: that.accessFrequenciesData.accessApp,
            backgroundColor: '#FF784D'
          }, {
            type: 'line',
            label: 'Total de acessos',
            data: that.accessFrequenciesData.accessTotal,
            borderColor: '#18A0FB',
            backgroundColor: ['#18A0FB'],
            tension: 0.5
          }]
        },
        plugins: [{
          beforeInit: chart => {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
              fitValue.bind(chart.legend)();
              return this.height += 10;
            };
          }
        }],
        options: {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          elements: {
            point: {
              radius: 4
            },
            line: {
              borderWidth: 1
            }
          },
          plugins: {
            legend: {
              labels: {
                fontColor: '#666666',
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            }
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accessFrequenciesData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupBy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = AccessFrequencyComponent;
});