define("portal-talentrh/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nnLDL14U",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-login\",[],[[\"@icon\",\"@appName\",\"@onAuthenticate\"],[\"social\",\"Social\",[30,[36,0],[[32,0,[\"authenticate\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/login/template.hbs"
    }
  });
  _exports.default = _default;
});