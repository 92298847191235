define("portal-talentrh/models/appointment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    archieved: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    end: (0, _attr.default)('date'),
    googleCalendar: (0, _attr.default)('boolean'),
    googleCalendarId: (0, _attr.default)('string'),
    googleCalendarLink: (0, _attr.default)('string'),
    importanceQuadrant: (0, _attr.default)('string', {
      defaultValue: 'none'
    }),
    location: (0, _attr.default)('string'),
    outlookCalendarId: (0, _attr.default)('string'),
    personalPlanning: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    public: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    start: (0, _attr.default)('date'),
    title: (0, _attr.default)('string'),
    canceled: (0, _attr.default)('boolean'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    trainingSchedule: (0, _relationships.belongsTo)('training-schedule'),
    createdBy: (0, _relationships.belongsTo)('user', {
      inverse: null
    }),
    room: (0, _relationships.belongsTo)('room'),
    user: (0, _relationships.belongsTo)('user', {
      inverse: 'appointments'
    }),
    birthdayPerson: (0, _relationships.belongsTo)('user', {
      inverse: null
    }),
    userMessage: (0, _relationships.belongsTo)('user-message'),
    confirmedUsers: (0, _relationships.hasMany)('user', {
      inverse: 'confirmedAppointments'
    }),
    invitedUsers: (0, _relationships.hasMany)('user', {
      inverse: 'invitedAppointments'
    }),
    // Computed Properties
    endDate: Ember.computed('end', function () {
      let vDate = (0, _moment.default)(this.end);
      if (!vDate.isValid()) {
        return '';
      }
      return vDate.format('DD/MM/YYYY');
    }),
    endTime: Ember.computed('end', function () {
      let vDate = (0, _moment.default)(this.end);
      if (!vDate.isValid()) {
        return '';
      }
      return vDate.format('HH:mm');
    }),
    startDate: Ember.computed('start', function () {
      let vDate = (0, _moment.default)(this.start);
      if (!vDate.isValid()) {
        return '';
      }
      return vDate.format('DD/MM/YYYY');
    }),
    startTime: Ember.computed('start', function () {
      let vDate = (0, _moment.default)(`${this.start}`);
      if (!vDate.isValid()) {
        return '';
      }
      return vDate.format('HH:mm');
    })
  });
  _exports.default = _default;
});