define("portal-talentrh/pods/dashboard/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    habitus: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    afterModel() {
      const companies = [59, 131, 433, 437, 460, 491];
      if (!companies.includes(this.session.company.id)) {
        return this.router.transitionTo('errors.forbidden');
      }
      this.habitus.show = false;
    },
    setDashboardRoute: Ember.on('activate', function () {
      Ember.debug('Activate dashboard route.');
      const applicationController = this.controllerFor('application');
      // Variável que indica que o usuário esta na página do dashboard
      applicationController.set('isAtDashboardRoute', true);
    }),
    unsetDashboardRoute: Ember.on('deactivate', function () {
      Ember.debug('Deactivate dashboard route.');
      const applicationController = this.controllerFor('application');
      applicationController.set('isAtDashboardRoute', false);
    })
  });
  _exports.default = _default;
});