define("portal-talentrh/pods/components/widgets/birthday-employees/birthday-user-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "71tTESVi",
    "block": "{\"symbols\":[\"user\",\"@birthdayUser\",\"@firstUser\"],\"statements\":[[8,\"user-attribute\",[],[[\"@userId\"],[[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"profile.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"account\",\"hideBirthDate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"anniversary-birthday-card-privacy-tag-feed\"],[12],[2,\"\\n        \"],[8,\"privacy-tag\",[],[[\"@value\"],[true]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-avatar mb-3\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[14,0,\"ui-avatar__img  avatar-7\"],[15,\"src\",[32,1,[\"avatar\"]]],[15,\"alt\",[32,1,[\"fullName\"]]],[15,\"title\",[32,1,[\"fullName\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"img\"],[14,0,\"ui-avatar__img  avatar-7\"],[15,\"data-lazy\",[32,1,[\"avatar\"]]],[15,\"alt\",[32,1,[\"fullName\"]]],[15,\"title\",[32,1,[\"fullName\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"mb-1\"],[12],[2,\"Parabéns pelo seu aniversário,\"],[13],[2,\"\\n    \"],[10,\"h6\"],[12],[1,[32,1,[\"fullName\"]]],[2,\"!\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"configuration\",\"showBirthdayMonth\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[[32,2,[\"birthDate\"]]],null],\"DD/MM\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"utc\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/widgets/birthday-employees/birthday-user-card/template.hbs"
    }
  });
  _exports.default = _default;
});