define("portal-talentrh/pods/company/professional-progression/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyProfessionalProgressionDetailsRoute extends Ember.Route {
    deactivate() {
      this.controller.data = null;
    }
  }
  _exports.default = CompanyProfessionalProgressionDetailsRoute;
});