define("portal-talentrh/pods/components/user-birthday/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userDataLoader: Ember.inject.service(),
    // TODO: Remover utilização de isVisible
    // https://deprecations.emberjs.com/v3.x/#toc_ember-component-is-visible
    isVisible: 'd-none',
    message: '',
    // Tasks
    loadBirthdayMessage: (0, _emberConcurrency.task)(function* () {
      let user = yield this.userDataLoader.get(this.session.user.id);
      if (user && user.isBirthday) {
        let result = yield this.store.queryRecord('birthday-message', this.session.user.company);
        if (result) {
          Ember.set(this, 'isVisible', '');
          Ember.set(this, 'message', result.get('message'));
        }
      }
    }).on('didInsertElement'),
    // Actions
    actions: {
      close() {
        Ember.set(this, 'isVisible', 'd-none');
        localStorage.setItem('isBirthdayMessageClosed', 'true');
      }
    }
  });
  _exports.default = _default;
});