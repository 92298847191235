define("portal-talentrh/pods/post/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    afterModel(post) {
      Ember.debug(`Post criado por: ${Ember.get(post, 'user.id')}`);
      Ember.debug(`Usuário acessando: ${this.session.user.id} - Admin: ${this.permission.admin}`);
      if (Number(Ember.get(post, 'user.id')) !== this.session.user.id) {
        Ember.debug('Este usuário não criou o post');
        if (!this.permission.admin) {
          Ember.debug('Este usuário não é Administrador desta rede, redirecionando para tela de erro.');
          this.transitionTo('errors.forbidden');
        }
      }
    },
    deactivate() {
      this.controller.model.rollbackAttributes();
    },
    actions: {
      refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});