define("portal-talentrh/pods/feed/me/request/show/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeedMeRequestShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model'), _dec4 = Ember.computed.equal('solicitation.solicitationType', 4), _dec5 = Ember.computed.equal('solicitation.solicitationType', 11), _dec6 = Ember.computed.equal('solicitation.solicitationType', 14), _dec7 = Ember.computed.equal('solicitation.solicitationType', 15), _dec8 = Ember.computed('solicitation.status'), _dec9 = Ember.computed('solicitation.attendant'), _dec10 = Ember.computed('solicitation.managerResponse'), _dec11 = Ember._action, (_class = class FeedMeRequestShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "solicitation", _descriptor3, this);
      _initializerDefineProperty(this, "isVacancyPeriod", _descriptor4, this);
      _initializerDefineProperty(this, "isAlterRegistrationData", _descriptor5, this);
      _initializerDefineProperty(this, "isJustification", _descriptor6, this);
      _initializerDefineProperty(this, "isCompensatoryTime", _descriptor7, this);
    }
    get statusLabel() {
      if (this.solicitation.status === "approved") {
        return "Aprovado " + (0, _moment.default)(this.solicitation.updatedAt).format('DD/MM/YYYY - HH:mm');
      } else if (this.solicitation.status === "pending-manager") {
        return "Aguardando resposta do Líder";
      } else if (this.solicitation.status === "pending-hr") {
        return "Aguardando resposta do Gestão de Pessoas";
      } else if (this.solicitation.status === "disapproved-manager") {
        return "Reprovado pelo Líder " + (0, _moment.default)(this.solicitation.updatedAt).format('DD/MM/YYYY - HH:mm');
      } else if (this.solicitation.status === "disapproved-hr") {
        return "Reprovado pelo Gestão de Pessoas " + (0, _moment.default)(this.solicitation.updatedAt).format('DD/MM/YYYY - HH:mm');
      } else {
        return "";
      }
    }
    get userId() {
      let attendant = this.solicitation.attendant;
      if (attendant && typeof attendant === 'object') {
        if (attendant.get) {
          return attendant.get('id');
        } else {
          return attendant.id;
        }
      }
      return null;
    }
    get managerResponse() {
      return this.solicitation.managerResponse;
      ;
    }
    *userApprover() {
      try {
        let user = yield this.store.findRecord('user', this.userId);
        Ember.set(this, 'userName', user.fullName);
      } catch (error) {
        console.error(error);
      }
    }
    closeModal() {
      this.router.transitionTo('feed.me.request');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "solicitation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isVacancyPeriod", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isAlterRegistrationData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isJustification", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isCompensatoryTime", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statusLabel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "statusLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userId", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "userId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "managerResponse", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "managerResponse"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userApprover", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "userApprover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = FeedMeRequestShowController;
});