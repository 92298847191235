define("portal-talentrh/models/training", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    conclusionDate: (0, _model.attr)('date'),
    expirationDate: (0, _model.attr)('date'),
    finished: (0, _model.attr)('boolean'),
    frequency: (0, _model.attr)('number'),
    hourQuantity: (0, _model.attr)('number'),
    initialDate: (0, _model.attr)('date'),
    note: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    // Associations
    budget: (0, _model.belongsTo)('budget'),
    company: (0, _model.belongsTo)('company'),
    institution: (0, _model.belongsTo)('institution'),
    room: (0, _model.belongsTo)('room'),
    trainingGeneric: (0, _model.belongsTo)('trainingGeneric'),
    trainingGroup: (0, _model.belongsTo)('trainingGroup'),
    items: (0, _model.hasMany)('item'),
    teams: (0, _model.hasMany)('team'),
    trainers: (0, _model.hasMany)('user'),
    users: (0, _model.hasMany)('user'),
    jobFunctions: (0, _model.hasMany)('job-function', {
      inverse: 'trainings'
    })
  });
  _exports.default = _default;
});