define("portal-talentrh/pods/components/dashboard/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R2DZCwUt",
    "block": "{\"symbols\":[\"@dashboardSlideItem\",\"@dashboardSlideItemLink\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"ui-block board-block board-block--album board-height-large color-block--\",[32,1,[\"color\"]]]]],[4,[38,1],[[30,[36,0],[[32,0,[\"metaTagValidate\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-block-title text-center font-weight-bold\"],[12],[2,\"\\n    \"],[1,[32,1,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"h-100 content-album pb-4 text-center\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"iframe\"],[14,0,\"w-100 h-100\"],[15,\"src\",[32,2,[\"url\"]]],[15,\"title\",[32,1,[\"title\"]]],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"refreshTimeInMilliseconds\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dashboard-action-timer\",[],[[\"@tickAction\",\"@timer\"],[[30,[36,0],[[32,0,[\"refreshDashboard\"]]],null],[32,1,[\"refreshTimeInMilliseconds\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/dashboard/link/template.hbs"
    }
  });
  _exports.default = _default;
});