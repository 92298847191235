define("portal-talentrh/utils/formatDate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatDate;
  function formatDate(date) {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone,
        timeZoneName: "short",
        hour12: false
      };
      date = new Date(date);
      return new Intl.DateTimeFormat('pt-BR', options).format(date);
    } catch (error) {
      console.error(error);
    }
  }
});