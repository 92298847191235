define("portal-talentrh/models/training-generic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    avatarUrl: (0, _model.attr)('string'),
    color: (0, _model.attr)('string'),
    content: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    resume: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    // Computed Properties
    avatar: Ember.computed('avatarUrl', function () {
      if (!this.avatarUrl) {
        return '/images/icons/jpg.png';
      }
      if (this.avatarUrl.includes('gravatar')) {
        return this.avatarUrl;
      }
      return this.avatarUrl;
    })
  });
  _exports.default = _default;
});