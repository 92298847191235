define("portal-talentrh/pods/components/user-list-professional-interests/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YBCsrCuH",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[11,\"li\"],[24,0,\"list-group-item border my-1\"],[24,\"data-toggle\",\"collapse\"],[16,6,[31,[\"#collapse-interests-\",[32,1,[\"id\"]]]]],[16,1,[31,[\"nela-3152-interests--\",[32,1,[\"id\"]]]]],[24,\"role\",\"button\"],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"onDidInsert\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fal fa-user-tie fa-lg text-primary\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"border-left pl-2 ml-3\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"mb-1\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[2,\"Área: \"],[13],[2,\" \"],[1,[32,1,[\"area\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"collapse\"],[15,1,[31,[\"collapse-interests-\",[32,1,[\"id\"]]]]],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"Descrição: \"],[13],[2,\" \"],[1,[32,1,[\"description\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fas fa-caret-down fa-sm ml-auto pl-3\"],[14,\"aria-hidden\",\"\"],[14,\"title\",\"Exibir descrição\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-list-professional-interests/item/template.hbs"
    }
  });
  _exports.default = _default;
});