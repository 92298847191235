define("portal-talentrh/pods/habitus/preferences/wizard/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let WizardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('habitus.habitusPreferences.finished'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember.computed('permission.toAccessAVD', 'step'), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = class WizardComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "habitus", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "swal", _descriptor5, this);
      _initializerDefineProperty(this, "finishedSteps", _descriptor6, this);
      _initializerDefineProperty(this, "step", _descriptor7, this);
      _initializerDefineProperty(this, "currentStep", _descriptor8, this);
      _initializerDefineProperty(this, "useCustomMessageBirthDate", _descriptor9, this);
      _initializerDefineProperty(this, "useCustomMessageAdmissionAnniversary", _descriptor10, this);
      _initializerDefineProperty(this, "characterBirthDateCount", _descriptor11, this);
      _initializerDefineProperty(this, "characterAdmissionAnniversaryCount", _descriptor12, this);
      _initializerDefineProperty(this, "anniversaryPhrases", _descriptor13, this);
      _initializerDefineProperty(this, "admissionAnniversaryPhrases", _descriptor14, this);
      _initializerDefineProperty(this, "daysAccessFrequencyWarning2", _descriptor15, this);
      _initializerDefineProperty(this, "daysAccessFrequencyWarning7", _descriptor16, this);
      _initializerDefineProperty(this, "daysAccessFrequencyWarning15", _descriptor17, this);
      _initializerDefineProperty(this, "daysAccessFrequencyWarning30", _descriptor18, this);
    }
    get lastStep() {
      return this.step === 2;
    }
    *start() {
      try {
        const {
          preferences
        } = this.args;
        const listAnniversaryPhrases = yield this.fetch.request('/habitusAnniversaryPhrase');
        if (listAnniversaryPhrases) {
          listAnniversaryPhrases.forEach(anniversary => {
            if (anniversary.type === 'admissionAnniversary') {
              this.admissionAnniversaryPhrases.push(anniversary);
            } else if (anniversary.type === 'birthday') {
              this.anniversaryPhrases.push(anniversary);
            }
          });
        }
        if (preferences) {
          let step = 1;
          if (preferences.finished) {
            step = 1;
          }
          this.useCustomMessageBirthDate = preferences.randomMessageBirthDate ? 'false' : 'true';
          this.useCustomMessageAdmissionAnniversary = preferences.randomMessageAdmissionAnniversary ? 'false' : 'true';
          this.currentStep = step;
          this.step = step;
        }
        this.checkDaysAccessFrequencyWarning(preferences.daysAccessFrequencyWarning);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    closeModal() {
      const {
        preferences
      } = this.args;
      if (!this.firstAccess && preferences) {
        preferences.save();
      }
      this.router.transitionTo('habitus');
    }
    nextStep() {
      const {
        preferences
      } = this.args;
      try {
        preferences.save();
        this.step = this.currentStep + 1;
        preferences.finished = true;
        preferences.step = this.currentStep;
        if (this.lastStep) {
          this.closeModal();
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    prevStep() {
      this.currentStep -= 1;
      this.step = this.currentStep;
    }
    updateMessageBirthDate() {
      const {
        preferences
      } = this.args;
      if (this.useCustomMessageBirthDate === 'true') {
        preferences.randomMessageBirthDate = false;
        preferences.customMessageBirthDate = preferences.customMessageBirthDate ? preferences.customMessageBirthDate : 'Parabéns! Que neste dia todas as alegrias do mundo sejam suas. Feliz aniversário! ';
      } else {
        preferences.randomMessageBirthDate = true;
      }
      if (this.useCustomMessageAdmissionAnniversary === 'true') {
        preferences.randomMessageAdmissionAnniversary = false;
        preferences.customMessageAdmissionAnniversary = preferences.customMessageAdmissionAnniversary ? preferences.customMessageAdmissionAnniversary : 'Parabéns! Hoje é uma grande data para celebrar. Você está completando mais um ano de trabalho.';
      } else {
        preferences.randomMessageAdmissionAnniversary = true;
      }
    }
    checkDaysAccessFrequencyWarning(checkbox) {
      const {
        preferences
      } = this.args;
      preferences.daysAccessFrequencyWarning = checkbox;
      if (checkbox === 2) {
        this.daysAccessFrequencyWarning2 = true;
        this.daysAccessFrequencyWarning7 = false;
        this.daysAccessFrequencyWarning15 = false;
        this.daysAccessFrequencyWarning30 = false;
      } else if (checkbox === 7) {
        this.daysAccessFrequencyWarning2 = false;
        this.daysAccessFrequencyWarning7 = true;
        this.daysAccessFrequencyWarning15 = false;
        this.daysAccessFrequencyWarning30 = false;
      } else if (checkbox === 15) {
        this.daysAccessFrequencyWarning2 = false;
        this.daysAccessFrequencyWarning7 = false;
        this.daysAccessFrequencyWarning15 = true;
        this.daysAccessFrequencyWarning30 = false;
      } else if (checkbox === 30) {
        this.daysAccessFrequencyWarning2 = false;
        this.daysAccessFrequencyWarning7 = false;
        this.daysAccessFrequencyWarning15 = false;
        this.daysAccessFrequencyWarning30 = true;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "finishedSteps", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "step", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentStep", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "useCustomMessageBirthDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'false';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "useCustomMessageAdmissionAnniversary", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'false';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "characterBirthDateCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "characterAdmissionAnniversaryCount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "anniversaryPhrases", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "admissionAnniversaryPhrases", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "daysAccessFrequencyWarning2", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "daysAccessFrequencyWarning7", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "daysAccessFrequencyWarning15", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "daysAccessFrequencyWarning30", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lastStep", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "lastStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "start", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextStep", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "nextStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prevStep", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "prevStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMessageBirthDate", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "updateMessageBirthDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkDaysAccessFrequencyWarning", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "checkDaysAccessFrequencyWarning"), _class.prototype)), _class));
  _exports.default = WizardComponent;
});