define("portal-talentrh/pods/components/view-survey-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'view-survey__item p-1 mt-2',
    votingDescription: Ember.computed('alternative.votes', function () {
      let votes = Ember.get(this, 'alternative.votes');
      let description = 'Nenhum voto';
      if (votes === 1) {
        description = 'Votado por uma pessoa';
      } else if (votes > 1) {
        description = `Votado por ${votes} pessoas`;
      }
      return description;
    }),
    users: Ember.computed('alternative.users', function () {
      const users = this.alternative.users;
      if (!users) return [];
      return users.map(user => {
        user.set('userDefaultImageWhenInactive', true);
        return user;
      });
    }),
    actions: {
      select(alternative) {
        this.select(alternative);
      }
    }
  });
  _exports.default = _default;
});