define("portal-talentrh/pods/company/benefits/controller", ["exports", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let CompanyBenefitsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.filter('model', ['termFilter', 'categoryFilter'], function (benefit) {
    let title = benefit.title || '';
    let category = benefit.category || '';
    let categoryFilter = this.categoryFilter ? this.categoryFilter.value : 'all';
    return (categoryFilter === 'all' || category === categoryFilter) && title.match(new RegExp(this.termFilter, 'i'));
  }), _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CompanyBenefitsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "categoryFilter", _descriptor3, this);
      _initializerDefineProperty(this, "termFilter", _descriptor4, this);
      _defineProperty(this, "categories", [{
        label: 'Todas',
        icon: 'fas fa-database',
        color: '#20c997',
        value: 'all'
      }, {
        label: 'Academia',
        icon: 'fas fa-dumbbell',
        color: '#495057',
        value: 'gym'
      }, {
        label: 'Saúde',
        icon: 'fas fa-medkit',
        color: '#dc3545',
        value: 'health'
      }, {
        label: 'Educação',
        icon: 'fas fa-graduation-cap',
        color: '#6f42c1',
        value: 'education'
      }, {
        label: 'Alimentação',
        icon: 'fas fa-utensils-alt',
        color: '#ffc107',
        value: 'food'
      }, {
        label: 'Transporte',
        icon: 'fas fa-bus',
        color: '#fd7e14',
        value: 'transport'
      }, {
        label: 'Convênio',
        icon: 'fas fa-hands-helping',
        color: '#e83e8c',
        value: 'agreement'
      }, {
        label: 'Auxílio',
        icon: 'fas fa-hand-holding-usd',
        color: '#28a745',
        value: 'support'
      }, {
        label: 'Lazer',
        icon: 'fas fa-bowling-pins',
        color: '#00134d',
        value: 'recreation'
      }, {
        label: 'Vestuário',
        icon: 'fas fa-tshirt',
        color: '#d0d1d6',
        value: 'clothing'
      }, {
        label: 'Beleza',
        icon: 'fas fa-spa',
        color: '#f8c29e',
        value: 'beauty'
      }, {
        label: 'Outros',
        icon: 'fas fa-ellipsis-h',
        color: '#007bff',
        value: 'others'
      }]);
      _initializerDefineProperty(this, "benefits", _descriptor5, this);
    }
    colorCategory(element, _ref) {
      let [category] = _ref;
      element.setAttribute('style', `height:16px;width:16px;background:${category.color}`);
    }
    bigColorCategory(element, _ref2) {
      let [color] = _ref2;
      element.setAttribute('style', `height:60px;width:60px;background:${color}`);
    }
    *updateFilters() {
      yield (0, _emberConcurrency.timeout)(300);
      this.termFilter = this.inputFilter;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "categoryFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "termFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "benefits", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "colorCategory", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "colorCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bigColorCategory", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "bigColorCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype)), _class));
  _exports.default = CompanyBenefitsController;
});