define("portal-talentrh/pods/components/dashboard/album-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X0EgFIxa",
    "block": "{\"symbols\":[\"album\",\"photo\"],\"statements\":[[10,\"div\"],[14,0,\"ui-block board-block board-block--album board-height-large\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-block-title text-center font-weight-bold\"],[12],[2,\"\\n    Álbum de Fotos\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"h-100 content-album\"],[12],[2,\"\\n\"],[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"autoplay\",\"arrows\",\"slidesToScroll\",\"slidesToShow\",\"autoplaySpeed\",\"dots\"],[true,false,1,1,8000,false]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"photos\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"h-100\"],[12],[2,\"\\n              \"],[10,\"img\"],[15,\"src\",[32,2,[\"url\"]]],[14,0,\"mx-auto\"],[14,\"alt\",\"Author\"],[12],[13],[2,\"\\n              \"],[10,\"h6\"],[14,0,\"font-weight-bold mt-2 text-center\"],[12],[1,[32,1,[\"description\"]]],[2,\" | \"],[1,[30,[36,0],[[32,1,[\"updatedAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"dashboard-action-timer\",[],[[\"@tickAction\",\"@timer\"],[[30,[36,6],[[32,0],\"refreshDashboard\"],null],60000]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"-track-array\",\"each\",\"albums\",\"slick-slider\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/dashboard/album-block/template.hbs"
    }
  });
  _exports.default = _default;
});