define("portal-talentrh/pods/profile/show/projects/project-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gEpjJNnS",
    "block": "{\"symbols\":[\"Skeleton\",\"@projectFilter\",\"@selectFilter\",\"@title\",\"@loading\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-skeleton\",[[24,0,\"col-md-3 d-flex justify-content-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-25\"]],[[\"@size\"],[\"lg\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"col-md-3 text-center text-\",[32,0,[\"currentColor\"]]]]],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,3],[32,2]],null]],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"font-weight-bold\"],[12],[1,[32,2,[\"count\"]]],[13],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"mb-0\"],[12],[1,[32,4]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/show/projects/project-filter/template.hbs"
    }
  });
  _exports.default = _default;
});