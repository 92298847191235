define("portal-talentrh/pods/components/user-preview/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-preview'],
    // Services
    session: Ember.inject.service(),
    // Computed
    showUserPreview: Ember.computed.alias('session.showUserPreview'),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.position = this.position || 'left';
      this.possiblePositions = ['bot', 'right', 'top'];
    },
    didInsertElement() {
      this._super(...arguments);
      this._enablePopup();
    },
    willDestroyElement() {
      this._super(...arguments);
      this._disablePopup();
    },
    // Methods
    _enablePopup() {
      this.element.addEventListener('mouseover', () => this._onMouseover(), false);
      this.element.addEventListener('mouseleave', () => this._onMouseleave(), false);
    },
    _disablePopup() {
      Ember.set(this, 'session.showUserPreview', false);
      document.removeEventListener('keydown', this.keydowEvent, false);
    },
    _onMouseover() {
      Ember.run.cancel(this.openPopupLater);
      let openPopupLater = Ember.run.later(() => {
        if (this.showUserPreview || !this.user) {
          return;
        }
        this._openPopup();
        this._hideOnKeydown();
      }, 500);
      Ember.set(this, 'openPopupLater', openPopupLater);
    },
    _onMouseleave() {
      if (this.showUserPreview) {
        this._disablePopup();
      }
      Ember.run.cancel(this.openPopupLater);
    },
    _openPopup() {
      let position = this.position;
      let userPreviewPopup = document.querySelector('.user-preview-popup');
      this.currentPositions = [...this.possiblePositions];
      let {
        left,
        top
      } = this._getPositions(position);
      userPreviewPopup.style.top = `${top}px`;
      userPreviewPopup.style.left = `${left}px`;
      Ember.set(this, 'session.userPreview', this.user);
      Ember.set(this, 'session.showUserPreview', position);
    },
    _hideOnKeydown() {
      let keydowEvent = () => this._disablePopup();
      document.addEventListener('keydown', keydowEvent, false);
      Ember.set(this, 'keydowEvent', keydowEvent);
    },
    // Calcula a posição em relação ao elemento em que o popup será posicionado.
    _getPositions(position) {
      let popupWidth = 275;
      let popupHeight = 225;
      let elementRect = this.element.getBoundingClientRect();
      let top = elementRect.top;
      let left = elementRect.left;
      if (position === 'left') {
        left -= popupWidth;
        top += elementRect.height / 2 - popupHeight / 2;
      }
      if (position === 'right') {
        left += elementRect.width;
        top += elementRect.height / 2 - popupHeight / 2;
      }
      if (position === 'top') {
        left += elementRect.width / 2 - popupWidth / 2;
        top -= popupHeight;
      }
      if (position === 'bot') {
        left += elementRect.width / 2 - popupWidth / 2;
        top += elementRect.height;
      }

      // Executa de forma recursiva até encontrar uma posição possível para o popup.
      let newPosition = this._validatePositions(position, left, top, popupWidth, popupHeight);

      // Se retornar uma newPosition, é por que a position atual não é possível;
      if (newPosition && !this.staticPosition) {
        this._getPositions(newPosition);
        return this._getPositions(newPosition);
      } else {
        return {
          left,
          top
        };
      }
    },
    // Esse método verifica se a posição atual do popup está saindo da tela, caso esteja, calcula uma nova posição.
    _validatePositions(position, left, top, popupWidth, popupHeight) {
      let newPosition;
      let bot = top + popupHeight;
      let right = left + popupWidth;
      let cWidth = document.body.clientWidth;
      let cHeight = document.body.clientHeight - document.documentElement.scrollTop;
      if (left < 0 || top < 0 || right > cWidth || bot > cHeight) {
        // Caso esteja saindo da tela, tenta uma nova posição.
        newPosition = this.currentPositions.pop();
      }
      return newPosition;
    }
  });
  _exports.default = _default;
});