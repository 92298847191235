define("portal-talentrh/pods/components/post-group-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    groupsService: Ember.inject.service('groups'),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    filter: '',
    selected: 0,
    groups: Ember.computed('filter', 'groupsService.isListLoaded', 'permission.admin', 'session.user.id', function () {
      let groups = this.groupsService.filter(this.filter);
      if (groups && !this.permission.admin) {
        groups = groups.filter(group => {
          if (!group.postOnlyForAdmin || (group.userAdminIds || []).includes(parseInt(this.session.user.id))) {
            return true;
          }
          return false;
        });
      }
      return groups;
    }),
    publishOnlyInGroup: Ember.computed('permission', function () {
      return this.permission.accessProfile.publishOnlyInGroup && !this.permission.admin;
    }),
    actions: {
      closeModal() {
        Ember.set(this, 'filter', '');
        Ember.set(this, 'selected', this.value);
        Ember.set(this, 'openModal', false);
      },
      save() {
        Ember.set(this, 'filter', '');
        Ember.set(this, 'value', this.selected);
        Ember.set(this, 'openModal', false);
        if (this.onSave) {
          this.onSave(this.selected);
        }
      }
    }
  });
  _exports.default = _default;
});