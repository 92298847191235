define("portal-talentrh/pods/notification/backup-solicitation/component", ["exports", "@glimmer/component", "portal-talentrh/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let NotificationBackupSolicitationComponent = (_dec = Ember.computed('args.notification.modelId'), (_class = class NotificationBackupSolicitationComponent extends _component.default {
    get linkTo() {
      return `${_environment.default.appUrl['conta']}lgpd/backup/company/${this.args.notification.modelId}`;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "linkTo", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "linkTo"), _class.prototype)), _class));
  _exports.default = NotificationBackupSolicitationComponent;
});