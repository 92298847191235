define("portal-talentrh/pods/components/toolbar-lunch/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    limit: null,
    diff: null,
    lunch: null,
    configuration: Ember.computed.alias('permission.companyConfig'),
    loadToolbarLunch: (0, _emberConcurrency.task)(function* () {
      yield this.loadLunch.perform();
      const limit = this.configuration ? this.configuration.timeLimitToOrderLunch : null;
      this.set('limit', (0, _moment.default)(limit, 'HH:mm').utcOffset(-3));
      const now = (0, _moment.default)().utcOffset(-3);
      const seconds = this.limit.diff(now, 'seconds');
      this.set('seconds', seconds);
      Ember.debug(`Limit: ${this.limit} - Now is: ${now} - Time remaining to order lunch: ${seconds}`);
      this.set('diff', _moment.default.utc(seconds * 1000).format('HH [hora(s)] mm [minuto(s)]'));
      let user = this.store.peekRecord('user', this.session.user.id);
      let lunch = this.store.createRecord('user-lunch-solicitation', {
        user: user,
        day: this.limit.format('DD'),
        month: this.limit.format('MM'),
        year: this.limit.format('YYYY')
      });
      this.set('lunch', lunch);
    }).on('didInsertElement'),
    loadLunch: (0, _emberConcurrency.task)(function* () {
      if (this.configuration && this.configuration.manageLunchList) {
        let userId = this.session.user.id;
        let verify = yield this.ajax.request(`userLunchSolicitations/verify/${userId}`);
        this.set('showOrderLunch', verify.userLunchSolicitation == null);
      }
    }),
    confirm: (0, _emberConcurrency.task)(function* () {
      let {
        value
      } = yield this.swal.confirm("Confirma a solicitação de refeição para hoje?");
      if (value) {
        this.set('lunch.confirmed', true);
        try {
          yield this.lunch.save();
          this.swal.success('Refeição solicitada com sucesso!');
        } catch (err) {
          this.swal.catch(err);
        } finally {
          this.toggleProperty('showOrderLunch');
        }
      }
    }),
    cancel: (0, _emberConcurrency.task)(function* () {
      let {
        value
      } = yield this.swal.confirm('Deseja mesmo cancelar a refeição para hoje?', null, true, true, 'Sim, cancelar refeição', 'Não cancelar');
      if (value) {
        this.set('lunch.confirmed', false);
        try {
          yield this.lunch.save();
          this.swal.success('Refeição cancelada com sucesso!');
        } catch (err) {
          this.swal.catch(err);
        } finally {
          this.toggleProperty('showOrderLunch');
        }
      }
    })
  });
  _exports.default = _default;
});