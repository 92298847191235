define("portal-talentrh/pods/profile/show/projects/project-filter/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProjectFilterComponent extends _component.default {
    get currentColor() {
      let {
        color,
        filterSelected,
        projectFilter
      } = this.args;
      if (!filterSelected) {
        return color;
      } else {
        if (filterSelected === projectFilter) {
          return color;
        } else {
          return 'muted';
        }
      }
    }
  }
  _exports.default = ProjectFilterComponent;
});