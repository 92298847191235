define("portal-talentrh/pods/components/post-edit-survey/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    type: 'radio',
    didInsertElement() {
      if (this.post.body && this.post.type === 'survey') {
        let body = this.post.body;
        if (typeof body !== 'object') {
          body = JSON.parse(body);
        }
        Ember.set(this, 'post.body', body);
      }
    },
    actions: {
      add() {
        this.post.body.alternatives.pushObject({
          value: `Opção ${this.post.body.alternatives.length + 1}`
        });
      },
      remove(option) {
        this.swal.confirm('Tem certeza que deseja remover este item?').then(result => {
          if (result.value) {
            this.post.body.alternatives.removeObject(option);
          }
        });
      },
      type(type) {
        (0, _jquery.default)(`.btn-group-sm.type button`).toggleClass('active');
        Ember.set(this, 'type', type);
      }
    }
  });
  _exports.default = _default;
});