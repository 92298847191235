define("portal-talentrh/pods/application/navbar/feeling/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['showFeelings:d-block:invisible'],
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    inSecondStep: false,
    selectedFeeling: {},
    showMotiveWarning: false,
    // Computed
    showFeelings: Ember.computed('permission.companyConfig.permitUserFeeling', 'answered', function () {
      return !this.answered && this.permission.companyConfig.permitUserFeeling;
    }),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.answered = true;
      this.feelings = [{
        type: 'Feliz',
        src: 'happy.svg',
        text: 'Feliz'
      }, {
        type: 'Apaixonado',
        src: 'in-love.svg',
        text: this.permission.user.gender === 'F' ? 'Apaixonada' : 'Apaixonado'
      }, {
        type: 'Irritado',
        src: 'angry.svg',
        text: this.permission.user.gender === 'F' ? 'Irritada' : 'Irritado'
      }, {
        type: 'Cansado',
        src: 'tired.svg',
        text: this.permission.user.gender === 'F' ? 'Cansada' : 'Cansado'
      }, {
        type: 'Inteligente',
        src: 'smart.svg',
        text: 'Inteligente'
      }, {
        type: 'Entediado',
        src: 'bored.svg',
        text: this.permission.user.gender === 'F' ? 'Entediada' : 'Entediado'
      }, {
        type: 'Ansioso',
        src: 'eager.svg',
        text: this.permission.user.gender === 'F' ? 'Ansiosa' : 'Ansioso'
      }, {
        type: 'Legal',
        src: 'cool.svg',
        text: 'Legal'
      }, {
        type: 'Desanimado',
        src: 'dispirited.svg',
        text: this.permission.user.gender === 'F' ? 'Desanimada' : 'Desanimado'
      }, {
        type: 'Triste',
        src: 'sad.svg',
        text: 'Triste'
      }, {
        type: 'Divertido',
        src: 'funny.svg',
        text: this.permission.user.gender === 'F' ? 'Divertida' : 'Divertido'
      }, {
        type: 'Entusiasmado',
        src: 'excited.svg',
        text: this.permission.user.gender === 'F' ? 'Entusiasmada' : 'Entusiasmado'
      }];
    },
    didInsertElement() {
      let currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastAnsweredFeeling = this.session.data.lastAnsweredFeeling;
      let permitUserFeeling = this.permission.companyConfig.permitUserFeeling;
      Ember.set(this, 'inSecondStep', false);
      if (lastAnsweredFeeling !== currentDate && permitUserFeeling) {
        this.checkIfAnswered.perform();
      }

      // Impede que o dropdown feche ao ser clicado dentro do mesmo.
      let el = this.element.querySelector('.ui-navbar__nav__dropdown') || {};
      el.onclick = e => e.stopPropagation();
    },
    // Tasks
    checkIfAnswered: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.ajax.request(`/users/${this.session.user.id}/feelingtoday`);
        if (response.answered) {
          this.session.set('data.lastAnsweredFeeling', response.currentDate);
        } else {
          Ember.set(this, 'answered', false);
          Ember.set(this, 'enablePopover', true);
        }
      } catch (e) {
        Ember.debug(e);
      }
    }),
    createFeeling: (0, _emberConcurrency.task)(function* (feeling) {
      if (this.inSecondStep) {
        if (feeling.factor) {
          try {
            yield this.ajax.post('feelings', {
              data: {
                feeling
              }
            });
            Ember.set(this, 'showAnimation', feeling);
            this.session.trigger('answerFeeling');
            Ember.run.later(() => {
              Ember.set(this, 'answered', true);
              this.session.set('data.lastAnsweredFeeling', (0, _moment.default)().format('YYYY-MM-DD'));
              $('.dropdown-feelings_nela-3556').dropdown('toggle');
            }, 2000);
          } catch (e) {
            this.swal.catch(e);
          }
        } else {
          Ember.set(this, 'showMotiveWarning', true);
        }
      } else {
        Ember.set(this, 'selectedFeeling', feeling);
        Ember.set(this, 'inSecondStep', true);
      }
    })
  });
  _exports.default = _default;
});