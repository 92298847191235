define("portal-talentrh/pods/group/show/attachment/show/comment/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'position-relative'],
    showMore: false,
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Multiline computed properties
    userCanRemove: Ember.computed('attachment.createdBy.id', 'attachment.userGroup.user.id', 'comment.user.id', 'session.user', function () {
      let user = this.session.user;
      let createdBy = parseInt(Ember.get(this, 'comment.user.id'));
      let attCreator = parseInt(Ember.get(this, 'attachment.createdBy.id'));
      let groupAdmin = parseInt(Ember.get(this, 'attachment.userGroup.user.id'));
      return user.admin || user.id === createdBy || user.id === attCreator || user.id === groupAdmin;
    }),
    buttonText: Ember.computed('showMore', function () {
      return this.showMore ? 'Ver menos' : 'Ver mais';
    }),
    commentText: Ember.computed('comment.text.length', 'showMore', 'showSeeMore', function () {
      const showSeeMore = this.comment.text.length > 300 ? true : false;
      if (!showSeeMore) {
        return this.comment.text;
      } else if (!this.showMore) {
        return this.comment.text.substr(0, 297) + "...";
      }
      return this.comment.text;
    }),
    // Tasks
    removeComment: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do comentário?');
        if (!value) {
          return;
        }
        yield this.comment.destroyRecord();
        this.toast.success('Comentário removido');
        this.attachment.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      toogleComment(comment, event) {
        event.preventDefault();
        Ember.set(this, 'showMore', !this.showMore);
      }
    }
  });
  _exports.default = _default;
});