define("portal-talentrh/pods/feed/me/compensatory-time/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeedMeCompensatoryTimeController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.filterBy('model', 'type', 'credit'), _dec7 = Ember.computed.filterBy('model', 'type', 'debit'), _dec8 = Ember.computed.alias('permission.admin'), _dec9 = Ember.computed.oneWay('session.user.id'), _dec10 = Ember.computed('model.{firstObject.totalBalance,length}', 'totalCredits', 'totalDebts'), _dec11 = Ember.computed('credits', 'model.{firstObject.totalCredits,length}'), _dec12 = Ember.computed('debts', 'model.{firstObject.totalDebts,length}'), (_class = class FeedMeCompensatoryTimeController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "credits", _descriptor6, this);
      _initializerDefineProperty(this, "debts", _descriptor7, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor8, this);
      _initializerDefineProperty(this, "user", _descriptor9, this);
      _defineProperty(this, "types", Ember.A([{
        value: 'all',
        label: 'Todos'
      }, {
        value: 'credit',
        label: 'Crédito'
      }, {
        value: 'debit',
        label: 'Débito'
      }]));
    }
    get totalBalance() {
      if (this.model.length && this.model.firstObject.totalBalance) {
        return this.model.firstObject.totalBalance;
      }
      let totalDebts = this.totalDebts;
      let totalCredits = this.totalCredits;
      return totalCredits - totalDebts;
    }
    get totalCredits() {
      if (this.model.length && this.model.firstObject.totalCredits) {
        return this.model.firstObject.totalCredits;
      }
      let total = 0;
      let credits = this.credits;
      credits.forEach(credit => {
        total += credit.get('balance');
      });
      return total;
    }
    get totalDebts() {
      if (this.model.length && this.model.firstObject.totalDebts) {
        return this.model.firstObject.totalDebts;
      }
      let total = 0;
      let debts = this.debts;
      debts.forEach(debt => {
        total += debt.get('balance');
      });
      return total;
    }

    // Tasks
    *refreshData() {
      try {
        let isAdmin = this.isAdmin;
        let cycle = this.cycle ? this.cycle.name : '';
        let type = this.type;
        if (!cycle) {
          return this.swal.warning('É necessário informar um ciclo');
        }
        let where = {
          cycle
        };
        if (type && type.value !== 'all') {
          where.type = type.value;
        }
        if (isAdmin) {
          let user = this.user;
          where.user = user;
        }
        let response = yield this.store.query('compensatory-time', {
          where: JSON.stringify(where),
          sort: 'date DESC'
        });
        if (!response.length) {
          this.toast.info('Nenhum registro encontrado');
        }
        Ember.set(this, 'model', response);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "credits", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "debts", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "totalBalance", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "totalBalance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalCredits", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "totalCredits"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalDebts", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "totalDebts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshData", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "refreshData"), _class.prototype)), _class));
  _exports.default = FeedMeCompensatoryTimeController;
});