define("portal-talentrh/pods/application/controller", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.alias('permission.accessProfile'), _dec11 = Ember.computed.alias('permission.routeHome'), _dec12 = Ember.computed.alias('permission.companyConfig.featureFlags'), _dec13 = Ember.computed('dashboardOptions', 'dash.route'), _dec14 = Ember.computed('permission.dashboardAvailableToView'), _dec15 = Ember.computed.alias('session.company'), _dec16 = Ember.computed.alias('permission.companyConfig.accountIsActive'), _dec17 = Ember.computed.and('isAtDashboardRoute', 'fullscreen.isEnabled'), _dec18 = Ember.computed.and('permission.companyConfig.permitChat', 'isActive'), _dec19 = Ember.computed.and('session.isAuthenticated', 'permission.isLoaded', 'session.user', 'permission.companyConfig'), _dec20 = Ember.computed.or('permission.accessProfile.allowSendMessageGroupChat', 'permission.accessProfile.allowSendMessageIndividualChat', 'permission.admin'), _dec21 = Ember.computed.or('permission.isLoading', 'currentlyLoading'), _dec22 = Ember.computed('allowSendMessageChat', 'permitChat'), _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "voorsChat", _descriptor6, this);
      _initializerDefineProperty(this, "shortcutSelected", _descriptor7, this);
      _initializerDefineProperty(this, "dashboardSelect", _descriptor8, this);
      _initializerDefineProperty(this, "dash", _descriptor9, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor10, this);
      _initializerDefineProperty(this, "routeHome", _descriptor11, this);
      _initializerDefineProperty(this, "featureFlags", _descriptor12, this);
      _initializerDefineProperty(this, "company", _descriptor13, this);
      _initializerDefineProperty(this, "isActive", _descriptor14, this);
      _initializerDefineProperty(this, "dashboardActive", _descriptor15, this);
      _initializerDefineProperty(this, "permitChat", _descriptor16, this);
      _initializerDefineProperty(this, "isReady", _descriptor17, this);
      _initializerDefineProperty(this, "allowSendMessageChat", _descriptor18, this);
      _initializerDefineProperty(this, "isLoading", _descriptor19, this);
    }
    get dashboardSelected() {
      if (this.dash) {
        return this.dashboardOptions.find(d => d.route === this.dash.route);
      }
      return this.dashboardOptions[0];
    }

    // Isso aqui é um workarroud (força ser um useEffect)
    set dashboardSelected(value) {
      Ember.set(this, 'dash', value);
    }
    get dashboardOptions() {
      const dashboardAvailableToView = this.permission.dashboardAvailableToView;
      const dashboardOptions = [];
      if (!dashboardAvailableToView.includes('leader') && !dashboardAvailableToView.includes('humanResources')) {
        return dashboardOptions;
      }
      dashboardAvailableToView.forEach(dashboardPermission => {
        if (dashboardPermission === 'leader') {
          dashboardOptions.push({
            label: 'Dashboard do time',
            icon: 'fi fi-rr-users-alt',
            route: 'home.my-team.index',
            dashboard: 'leader'
          });
        }
        if (dashboardPermission === 'humanResources') {
          dashboardOptions.push({
            label: 'Dashboard de gestão',
            icon: 'fi fi-rr-people-poll',
            route: 'home.human-resources.index',
            dashboard: 'humanResources'
          });
        }
      });
      dashboardOptions.push({
        label: 'Meu dashboard',
        icon: 'fi fi-rr-portrait',
        route: 'home.my-workspace',
        dashboard: 'myWorkspace'
      });
      return dashboardOptions;
    }
    get isProductionBuild() {
      return _environment.default.environment === 'production';
    }
    get showNelaChat() {
      return this.permitChat && this.allowSendMessageChat && ['production', 'staging'].includes(_environment.default.environment);
    }
    logout() {
      this.session.invalidate();
    }
    refresh() {
      this.send('reloadRoute');
    }
    willChangeRouteShortcut(route) {
      if (!route) {
        route = this.router.currentRoute.name;
      }
      let shortcut = route.split('.');
      this.dashboardSelect = shortcut[0] === 'home';
      shortcut = shortcut.filter(shortcut => shortcut !== 'index');
      shortcut = shortcut.join('-');
      this.shortcutSelected = shortcut;
    }
    onSelectDashboard(dashboard) {
      Ember.set(this, 'dashboardSelected', dashboard);
      this.router.transitionTo(dashboard.route, {
        queryParams: {
          from: 'selectDashboard'
        }
      });
    }
    onCloseDashboard() {
      this.router.transitionTo(this.dashboardSelected.route, {
        queryParams: {
          from: 'selectDashboard'
        }
      });
    }
    transitionToHome() {
      this.router.transitionTo('home.my-workspace', {
        queryParams: {
          from: 'selectDashboard'
        }
      });
    }
    validateChat() {
      if (this.permitChat) {
        return;
      }
      const chatElement = document.getElementById('chat-widget');
      if (chatElement) {
        chatElement.remove();
      }
      const chatScriptElement = document.getElementById('chat-script');
      if (chatScriptElement) {
        chatScriptElement.remove();
      }
      window.chatLogin = () => {};
    }
    registerActionOnChatHeaderClick() {
      this.voorsChat.registerActionOnChatHeaderClick();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "voorsChat", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "shortcutSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dashboardSelect", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dash", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "routeHome", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "featureFlags", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dashboardSelected", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "dashboardSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dashboardOptions", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "dashboardOptions"), _class.prototype), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dashboardActive", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "permitChat", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isReady", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "allowSendMessageChat", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showNelaChat", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "showNelaChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willChangeRouteShortcut", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "willChangeRouteShortcut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectDashboard", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectDashboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseDashboard", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDashboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToHome", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToHome"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateChat", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "validateChat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerActionOnChatHeaderClick", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "registerActionOnChatHeaderClick"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});