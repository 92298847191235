define("portal-talentrh/pods/components/social-page/menu/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9VbG7yUC",
    "block": "{\"symbols\":[\"Card\",\"@title\",\"&default\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"border-0 font-weight-bold text-secondary\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,2]]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"pt-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n      \"],[18,3,[[30,[36,1],null,[[\"Item\"],[[30,[36,0],[\"social-page/menu/list/item\"],null]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/social-page/menu/list/template.hbs"
    }
  });
  _exports.default = _default;
});