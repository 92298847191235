define("portal-talentrh/pods/components/insight-action-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const INSIGHT_TYPES = {
    birthday: 'Aniversariante do dia',
    admissionAnniversary: 'Aniversariante de empresa',
    accessFrequency: 'Frequência de acesso',
    feeling: 'Sentimento dos liderados',
    feedback: 'Feedback',
    post: 'Registrar ação',
    pdi: 'PDI'
  };
  let InsightActionModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.controller('habitus.index'), _dec10 = Ember.computed.alias('permission.companyConfig.hasAVDModule'), _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember.computed.alias('habitus.showInsightActionModal'), _dec25 = Ember.computed.alias('habitus.multipleUsersActionModal'), _dec26 = Ember.computed.alias('habitus.habitusPreferences'), _dec27 = Ember._action, _dec28 = Ember.computed('args.insightLed'), _dec29 = Ember.computed('args.insightLed'), _dec30 = Ember.computed('args.insightLed'), _dec31 = Ember.computed('args.insightLed'), _dec32 = Ember._action, _dec33 = Ember._action, _dec34 = Ember._action, (_class = class InsightActionModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "habitus", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "toast", _descriptor6, this);
      _initializerDefineProperty(this, "fetch", _descriptor7, this);
      _initializerDefineProperty(this, "utils", _descriptor8, this);
      _initializerDefineProperty(this, "habitusController", _descriptor9, this);
      _initializerDefineProperty(this, "hasAVDModule", _descriptor10, this);
      _initializerDefineProperty(this, "characterCount", _descriptor11, this);
      _initializerDefineProperty(this, "message", _descriptor12, this);
      _initializerDefineProperty(this, "showAll", _descriptor13, this);
      _initializerDefineProperty(this, "placeholder", _descriptor14, this);
      _initializerDefineProperty(this, "linkToFeedback", _descriptor15, this);
      _initializerDefineProperty(this, "anniversaryPhrases", _descriptor16, this);
      _initializerDefineProperty(this, "disableInput", _descriptor17, this);
      _initializerDefineProperty(this, "birthdayPhrases", _descriptor18, this);
      _initializerDefineProperty(this, "admissionAnniversaryPhrases", _descriptor19, this);
      _initializerDefineProperty(this, "modalTitle", _descriptor20, this);
      _initializerDefineProperty(this, "showLinkToFeedbackCheck", _descriptor21, this);
      _initializerDefineProperty(this, "user", _descriptor22, this);
      _initializerDefineProperty(this, "feelingAction", _descriptor23, this);
      _initializerDefineProperty(this, "showInsightActionModal", _descriptor24, this);
      _initializerDefineProperty(this, "multipleUsersActionModal", _descriptor25, this);
      _initializerDefineProperty(this, "habitusPreferences", _descriptor26, this);
    }
    *startModal() {
      let {
        insight,
        insightLed,
        multipleUsersActionModal,
        type,
        insightLedId,
        insightLedActionId,
        feelingActionId
      } = yield this.args;
      try {
        if (insightLedId) {
          insightLed = yield this.store.findRecord('insight-led', insightLedId);
          insight = yield insightLed.get('insight');
        }
        if (insight) {
          if (insight && insight.type) {
            type = insight.type;
          } else {
            type = insight.get('type');
          }
        }
        if (type === 'feeling' || type === 'accessFrequency') {
          this.showLinkToFeedbackCheck = true;
        }
        if (insightLedActionId) {
          this.insightLedAction = yield this.store.findRecord('insight-led-action', insightLedActionId);
          this.message = this.insightLedAction.get('message');
          this.linkToFeedback = this.insightLedAction.get('linkedToFeedback');
        }
        if (feelingActionId) {
          this.feelingAction = yield this.store.findRecord('feeling-action', feelingActionId);
          this.message = this.feelingAction.get('message');
          this.linkToFeedback = this.feelingAction.get('linkedToFeedback');
        }
        this.user = insightLed.get('led');
        this._buildModalTitle(type);
        this.disableInput = false;
        this.anniversaryPhrases = yield this.fetch.request('/habitusAnniversaryPhrase');
        this.birthdayPhrases = this.anniversaryPhrases.filter(o => o.type === 'birthday');
        this.admissionAnniversaryPhrases = this.anniversaryPhrases.filter(o => o.type === 'admissionAnniversary');
        if (insightLed.length <= 3 && multipleUsersActionModal) {
          this.changeShowAll();
          return;
        }
        if (insight.type === 'birthday' || insight.type === 'admissionAnniversary') {
          if (insight.type === 'birthday') {
            if (this.habitusPreferences.randomMessageBirthDate) {
              this.message = this.birthdayPhrases[Math.floor(Math.random() * this.birthdayPhrases.length)].phrase;
            } else {
              this.message = this.habitusPreferences.customMessageBirthDate;
            }
          } else if (insight.type === 'admissionAnniversary') {
            if (this.habitusPreferences.randomMessageAdmissionAnniversary) {
              this.message = this.admissionAnniversaryPhrases[Math.floor(Math.random() * this.admissionAnniversaryPhrases.length)].phrase;
            } else {
              this.message = this.habitusPreferences.customMessageAdmissionAnniversary;
            }
          }
        } else if (insight.type === 'feeling') {
          this.placeholder = 'Descreva o que foi conversado a respeito do sentimento reportado';
        } else if (insight.type === 'accessFrequency') {
          this.placeholder = 'Descreva o que foi conversado a respeito da frequência de acesso';
        }
      } catch (e) {
        console.error(e);
      }
    }
    changeShowAll() {
      const {
        insight,
        insightLed
      } = this.args;
      this.showAll = true;
      insightLed.forEach(insightLed => {
        if (insight.type === 'birthday') {
          if (this.habitusPreferences.randomMessageBirthDate) {
            insightLed.message = this.birthdayPhrases[Math.floor(Math.random() * this.birthdayPhrases.length)].phrase;
          } else {
            insightLed.message = this.habitusPreferences.customMessageBirthDate;
          }
        } else if (insight.type === 'admissionAnniversary') {
          if (this.habitusPreferences.randomMessageAdmissionAnniversary) {
            insightLed.message = this.admissionAnniversaryPhrases[Math.floor(Math.random() * this.admissionAnniversaryPhrases.length)].phrase;
          } else {
            insightLed.message = this.habitusPreferences.customMessageAdmissionAnniversary;
          }
        }
        insightLed.characterCount = insightLed.message ? insightLed.message.length : 0;
      });
    }
    get showMoreUserAvatar() {
      const {
        insightLed
      } = this.args;
      if (insightLed.length > 3) {
        return true;
      } else {
        return false;
      }
    }
    get singleUserAvatar() {
      const {
        insightLed
      } = this.args;
      return insightLed.length === 1;
    }
    get countUsers() {
      const {
        insightLed
      } = this.args;
      return insightLed.length - 3;
    }
    get feelingType() {
      const {
        insightLed
      } = this.args;
      const textFemale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonada',
        Irritado: 'Irritada',
        Cansado: 'Cansada',
        Inteligente: 'Inteligente',
        Entediado: 'Entediada',
        Ansioso: 'Ansiosa',
        Legal: 'Legal',
        Desanimado: 'Desanimada',
        Triste: 'Triste',
        Divertido: 'Divertida',
        Entusiasmado: 'Entusiasmada'
      };
      const textMale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonado',
        Irritado: 'Irritado',
        Cansado: 'Cansado',
        Inteligente: 'Inteligente',
        Entediado: 'Entediado',
        Ansioso: 'Ansioso',
        Legal: 'Legal',
        Desanimado: 'Desanimado',
        Triste: 'Triste',
        Divertido: 'Divertido',
        Entusiasmado: 'Entusiasmado'
      };
      return insightLed.led.get('gender') === 'F' ? textFemale[insightLed.feelingType] : textMale[insightLed.feelingType];
    }
    _buildModalTitle(type) {
      this.modalTitle = INSIGHT_TYPES[type];
    }
    changeLedStatus(status, led) {
      try {
        const {
          multipleUsersActionModal,
          insightLed,
          insight
        } = this.args;
        led.status = status;
        led.save();
        if (status === 'noSend') {
          this.toast.success('Mensagem não será enviada');
          if (insightLed.length === 1) {
            this.disableInput = true;
          }
        } else if (status === 'pending') {
          this.toast.success('Habilitado o envio da mensagem');
          this.disableInput = false;
          insight.status = 'pending';
          insight.save();
        }
        if (!multipleUsersActionModal) {
          this.closeModal();
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *registerAction() {
      try {
        const {
          insight,
          insightLed,
          multipleUsersActionModal
        } = this.args;
        this.insightLedAction = this.feelingAction ? this.feelingAction : this.insightLedAction;
        if (this.insightLedAction) {
          const wasLinkedToFeedback = this.insightLedAction.linkedToFeedback;
          this.insightLedAction.message = this.message;
          if (this.insightLedAction.validations.isInvalid) {
            return this.swal.validation(this.insightLedAction.validations);
          }
          if (this.message.length > 500) {
            return this.swal.warning('Número máximo de caracteres atingido, o máximo é de 500 caracteres.');
          }
          this.insightLedAction.linkedToFeedback = this.linkToFeedback;
          const insightLed = this.insightLedAction.get('insightLed');
          if (!wasLinkedToFeedback && this.linkToFeedback) {
            const date = new Date();
            let userLogged = yield this.store.peekRecord('user', this.session.user.id);
            let company = yield this.store.peekRecord('company', this.session.user.company);
            const objectiveFeedback = insight.type === 'feeling' ? 'Conversar sobre sentimento negativo' : 'Conversar sobre frequência de acesso';
            const feedback = yield this.store.createRecord('feedback', {
              finalFeedback: this.message,
              company: company,
              draft: true,
              date: date,
              leaderUser: userLogged,
              objective: objectiveFeedback,
              status: 1,
              startTime: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
              endTime: '00:00:00',
              type: 0,
              user: insightLed.get('led')
            });
            yield feedback.save();
            this.insightLedAction.feedback = feedback;
          } else if (wasLinkedToFeedback) {
            const feedback = yield this.insightLedAction.get('feedback');
            if (this.linkToFeedback) {
              feedback.finalFeedback = this.message;
              yield feedback.save();
            } else {
              yield feedback.destroyRecord();
            }
          }
          yield this.insightLedAction.save();
        } else {
          if (multipleUsersActionModal) {
            insightLed.forEach(insightItem => {
              const insightLedAction = this.store.createRecord('insight-led-action', {
                insightLed: insightItem,
                type: insight.type,
                message: this.message
              });
              insightLedAction.save();
              this.changeInsightLedConcluded(insightItem);
              this.sendMessageChat(insightItem, this.message, false);
            });
            this.swal.success('Mensagens enviadas com sucesso');
          } else {
            let insightLedAction = {};
            if (this.linkToFeedback) {
              const date = new Date();
              let userLogged = yield this.store.peekRecord('user', this.session.user.id);
              let company = yield this.store.peekRecord('company', this.session.user.company);
              const objectiveFeedback = insight.type === 'feeling' ? 'Conversar sobre sentimento negativo' : 'Conversar sobre frequência de acesso';
              const feedback = this.store.createRecord('feedback', {
                finalFeedback: this.message,
                company: company,
                draft: true,
                date: date,
                leaderUser: userLogged,
                objective: objectiveFeedback,
                status: 1,
                startTime: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
                endTime: '00:00:00',
                type: 0,
                user: insightLed.led
              });
              yield feedback.save();
              const bodyAction = {
                feedback: feedback,
                linkedToFeedback: true,
                insightLed: insightLed,
                type: insight.type,
                message: this.message
              };
              if (insight.type === 'feeling') {
                insightLedAction = this.store.createRecord('feeling-action', Object.assign(bodyAction, {
                  createdBy: this.permission.user,
                  feeling: insightLed.feeling,
                  feelingType: insightLed.get('feelingType'),
                  origin: 'habitus'
                }));
              } else {
                insightLedAction = this.store.createRecord('insight-led-action', bodyAction);
              }
            } else {
              const bodyAction = {
                insightLed: insightLed,
                type: insight.type,
                message: this.message
              };
              if (insight.type === 'feeling') {
                insightLedAction = this.store.createRecord('feeling-action', Object.assign(bodyAction, {
                  createdBy: this.permission.user,
                  feeling: insightLed.feeling,
                  feelingType: insightLed.get('feelingType'),
                  origin: 'habitus'
                }));
              } else {
                if (['admissionAnniversary', 'birthday'].includes(bodyAction.type)) {
                  bodyAction.chatToken = this.utils.getAuthTokenChat();
                }
                insightLedAction = this.store.createRecord('insight-led-action', bodyAction);
              }
            }
            if (insightLedAction.validations.isInvalid) {
              return this.swal.validation(insightLedAction.validations);
            }
            yield insightLedAction.save();
            this.changeInsightLedConcluded(insightLed);
            if (insight.type === 'birthday' || insight.type === 'admissionAnniversary') {
              this.sendMessageChat(insightLed, this.message, true);
            } else {
              this.swal.success('Ação registrada com sucesso');
            }
            if (this.habitusController.insightLedsToConclude) {
              this.habitusController.hasInsightsToUpdate = true;
              (0, _emberConcurrency.timeout)(2000).then(() => {
                this.habitusController.insightLedsToConclude = null;
                this.habitusController.hasInsightsToUpdate = false;
              });
            }
          }
        }
        this.closeModal();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    registerActionAll() {
      try {
        const {
          insight,
          insightLed
        } = this.args;
        insightLed.forEach(led => {
          if (led.status === 'pending') {
            const insightLedAction = this.store.createRecord('insight-led-action', {
              insightLed: led,
              type: insight.type,
              message: led.message
            });
            insightLedAction.save();
            this.sendMessageChat(led, led.message, false);
            delete led.message;
            delete led.characterCount;
            this.changeInsightLedConcluded(led);
          }
        });
        this.closeModal();
        this.swal.success('Mensagens enviadas com sucesso');
      } catch (erro) {
        console.log(erro);
        this.swal.catch(erro);
      }
    }
    *loadAnniversaryPhrases() {
      try {
        this.anniversaryPhrases = yield this.fetch.request('/habitusAnniversaryPhrase');
      } catch (e) {
        this.swal.catch(e);
      }
    }
    changeInsightLedConcluded(insightLed) {
      insightLed.status = 'concluded';
      insightLed.save();
    }
    closeModal() {
      this.showInsightActionModal = false;
      this.multipleUsersActionModal = false;
      this.message = null;
      this.characterCount = 0;
      this.showAll = false;
      (0, _emberConcurrency.timeout)(1000).then(() => {
        this.args.reloadRecords();
      });
    }
    sendMessageChat(insightLed, message, openChatWindow) {
      console.log('Chat::Depreciado::TODO: migrar novo chat', insightLed, message, openChatWindow);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "habitusController", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasAVDModule", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "characterCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showAll", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "linkToFeedback", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "anniversaryPhrases", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "disableInput", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "birthdayPhrases", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "admissionAnniversaryPhrases", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "showLinkToFeedbackCheck", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "feelingAction", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "showInsightActionModal", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "multipleUsersActionModal", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "habitusPreferences", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "startModal", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "startModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeShowAll", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "changeShowAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showMoreUserAvatar", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "showMoreUserAvatar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "singleUserAvatar", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "singleUserAvatar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countUsers", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "countUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "feelingType", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "feelingType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeLedStatus", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "changeLedStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerAction", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "registerAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerActionAll", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "registerActionAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAnniversaryPhrases", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAnniversaryPhrases"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = InsightActionModalComponent;
});