define("portal-talentrh/pods/application/navbar/notification/user-absence/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UbARcJcx",
    "block": "{\"symbols\":[\"Avatar\",\"@notification\"],\"statements\":[[10,\"li\"],[14,0,\"d-flex py-4 cursor-pointer\"],[15,\"onclick\",[30,[36,0],[[32,0],\"redirect\"],null]],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,2,[\"relatedUser\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,2,[\"relatedUser\",\"fullName\"]]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,2,[\"message\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,2,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"html\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/user-absence/template.hbs"
    }
  });
  _exports.default = _default;
});