define("portal-talentrh/transforms/timestamp", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      if (!serialized) {
        return null;
      }
      return _moment.default.unix(serialized).toDate();
    },
    serialize(deserialized) {
      if (!deserialized) {
        return null;
      }
      return (0, _moment.default)(deserialized).unix();
    }
  });
  _exports.default = _default;
});