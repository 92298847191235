define("portal-talentrh/pods/components/team-hierarchy/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement() {
      this._super(...arguments);
      this.loadHierarchy.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.loadHierarchy.perform();
    },
    hierarchy: Ember.computed.alias('loadHierarchy.last.value'),
    showLeader: Ember.computed.or('hierarchy.leader', 'isAllEmpty'),
    isAllEmpty: Ember.computed('hierarchy.leader', 'hierarchy.leds.length', function () {
      if (!this.hierarchy) {
        return;
      }
      return !this.hierarchy.leader && !this.hierarchy.leds.length;
    }),
    haveLeds: Ember.computed('hierarchy.leader', 'hierarchy.leds.length', function () {
      if (!this.hierarchy) {
        return false;
      }
      return !this.hierarchy.leds.length;
    }),
    permitAddToHierarchy: Ember.computed.or('permission.hr', 'permission.admin'),
    loadHierarchy: (0, _emberConcurrency.task)(function* () {
      const data = {
        ignoreCache: true
      };
      try {
        return yield this.ajax.request(`users/${this.profileUserId}/hierarchy`, {
          data
        });
      } catch (e) {
        Ember.debug(e);
      }
    })
  });
  _exports.default = _default;
});