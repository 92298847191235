define("portal-talentrh/pods/home/my-team/solicitations-or-compensatory-time/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeMyTeamSolicitationsOrCompensatoryTimeComponent = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember._tracked, _dec5 = Ember.computed.alias('permission.accessProfile'), _dec6 = Ember.computed.or('accessProfile.allowCompensatoryTimeMyTeam', 'accessProfile.admin'), _dec7 = Ember.computed.or('accessProfile.manager', 'accessProfile.admin'), _dec8 = Ember.computed.alias('permission.companyConfig'), _dec9 = Ember.computed.and('allowSolicitations', 'companyConfig.permitMessageRh'), _dec10 = Ember.computed.and('allowCompensatoryTimeMyTeam', 'companyConfig.usesCompensatoryTime'), _dec11 = Ember.computed('allowCompensatoryTimeMyTeam', 'allowSolicitations', 'companyConfig.{permitMessageRh,usesCompensatoryTime}'), _dec12 = Ember._action, (_class = class HomeMyTeamSolicitationsOrCompensatoryTimeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "type", _descriptor4, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor5, this);
      _initializerDefineProperty(this, "allowCompensatoryTimeMyTeam", _descriptor6, this);
      _initializerDefineProperty(this, "allowSolicitations", _descriptor7, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor8, this);
      _initializerDefineProperty(this, "showSolicitations", _descriptor9, this);
      _initializerDefineProperty(this, "showCompensatoryTimes", _descriptor10, this);
    }
    get showMenuDropdown() {
      return this.companyConfig.permitMessageRh && this.companyConfig.usesCompensatoryTime && this.allowSolicitations && this.allowCompensatoryTimeMyTeam;
    }
    setType(type) {
      this.type = type;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Solicitations';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "allowCompensatoryTimeMyTeam", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allowSolicitations", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showSolicitations", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showCompensatoryTimes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showMenuDropdown", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showMenuDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setType", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setType"), _class.prototype)), _class));
  _exports.default = HomeMyTeamSolicitationsOrCompensatoryTimeComponent;
});