define("portal-talentrh/pods/home/my-workspace/balance-vacation-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ceg/W7l6",
    "block": "{\"symbols\":[\"Modal\",\"openVacation\",\"@showModal\",\"@closeModal\",\"@openVacations\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\",\"@size\"],[[32,3],[30,[36,0],[[32,4]],null],\"md\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Saldo de Férias\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"max-h-vh-8 overflow-auto ui-scrollbar\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"container d-flex\"],[12],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[14,0,\"border-0 d-flex list-group-item\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"width\",\"60\"],[14,\"height\",\"60\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Fvacation-summer.svg\"],[14,\"alt\",\"Icone Férias de verão\"],[12],[13],[2,\"\\n            \"],[10,\"section\"],[14,0,\"ml-4\"],[12],[2,\"\\n              \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Período aquisitivo: \"],[13],[1,[32,2,[\"vestingPeriod\"]]],[13],[2,\"\\n              \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Saldo: \"],[13],[1,[32,2,[\"balance\"]]],[2,\" dias\"],[13],[2,\"\\n              \"],[10,\"p\"],[12],[10,\"strong\"],[12],[2,\"Data limite para início do gozo: \"],[13],[1,[32,2,[\"enjoymentLimit\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/home/my-workspace/balance-vacation-modal/template.hbs"
    }
  });
  _exports.default = _default;
});