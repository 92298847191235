define("portal-talentrh/pods/components/indicators/company-performance/nine-box/arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BMPx6Cu7",
    "block": "{\"symbols\":[\"@cycles\",\"@expand\",\"@upArrow\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"indicators/company-performance/nine-box/arrow/up-arrow\",[],[[\"@cycles\",\"@expand\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"indicators/company-performance/nine-box/arrow/right-arrow\",[],[[\"@cycles\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-performance/nine-box/arrow/template.hbs"
    }
  });
  _exports.default = _default;
});