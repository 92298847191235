define("portal-talentrh/pods/components/user-action-popup-insight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0An7sCgK",
    "block": "{\"symbols\":[\"userLoaded\",\"isLoaded\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"user-preview-popup user-preview-resize shadow-sm\\n\",[30,[36,0],[[32,0,[\"showUserPreviewInsight\"]],\"d-block\",\"d-none\"],null],\"\\n\",[30,[36,0],[[30,[36,2],[[30,[36,1],[[32,0,[\"insight\",\"type\"]],\"feeling\"],null],[30,[36,1],[[32,0,[\"insight\",\"type\"]],\"accessFrequency\"],null],[30,[36,1],[[32,0,[\"insight\",\"type\"]],\"feedback\"],null],[30,[36,1],[[32,0,[\"insight\",\"type\"]],\"pdi\"],null]],null],\"change-size\",\"user-preview-size\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isUserId\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"user-attribute\",[],[[\"@userId\"],[[32,0,[\"user\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"user-action-popup-insight/content\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,0,[\"insight\"]],[32,0,[\"insightLed\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"preloader-animation\",[],[[\"@type\"],[4]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"user-action-popup-insight/content\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,0,[\"user\"]],[32,0,[\"insight\"]],[32,0,[\"insightLed\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"or\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-action-popup-insight/template.hbs"
    }
  });
  _exports.default = _default;
});