define("portal-talentrh/pods/indicators/people-analytics/my-team/company-engagement/access-frequency-detail/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsMyTeamCompanyEngagementAccessFrequencyDetailController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('endDate', 'initialDate'), _dec6 = Ember.computed('endDate', 'initialDate', 'titleColumnPeriod'), _dec7 = Ember._action, (_class = class IndicatorsPeopleAnalyticsMyTeamCompanyEngagementAccessFrequencyDetailController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['branches', 'departments', 'jobRoles', 'jobFunctions', 'initialDate', 'endDate', 'groupBy', 'ledType']);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "accessFrequenciesData", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
    }
    get titleColumnPeriod() {
      const diffDiasGroup = (0, _moment.default)(this.endDate).diff(this.initialDate, 'days');
      if (diffDiasGroup >= 32 && diffDiasGroup <= 90) {
        return 'Semana';
      } else if (diffDiasGroup > 90) {
        return 'Mês/Ano';
      }
      return 'Data';
    }
    get exportFields() {
      return [{
        field: 'period',
        fieldName: `${this.titleColumnPeriod}`
      }, {
        field: 'totalWeb',
        fieldName: 'Acessos web'
      }, {
        field: 'totalApp',
        fieldName: 'Acessos app'
      }, {
        field: 'totalAccess',
        fieldName: 'Total de acessos'
      }, {
        field: 'ratingAccess',
        fieldName: '% de acessos'
      }];
    }
    *loadAccessLogs() {
      try {
        const filters = this.buildFilters();
        const accessFrequenciesData = yield this.fetch.request('/api/leader/getAccessLogs', {
          host: 'indicadores',
          data: filters
        });
        this.accessFrequenciesData = accessFrequenciesData;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.branches) {
        filters.branches = this.branches.split(',');
      }
      if (this.departments) {
        filters.departments = this.departments.split(',');
      }
      if (this.jobRoles) {
        filters.jobRoles = this.jobRoles.split(',');
      }
      if (this.jobFunctions) {
        filters.jobFunctions = this.jobFunctions.split(',');
      }
      if (this.initialDate) {
        filters.initialDate = this.initialDate;
      }
      if (this.endDate) {
        filters.endDate = this.endDate;
      }
      if (this.groupBy) {
        filters.groupBy = this.groupBy;
      }
      if (this.ledType) {
        filters.ledType = this.ledType;
      }
      const diffDiasGroup = (0, _moment.default)(this.endDate).diff(this.initialDate, 'days');
      if (diffDiasGroup >= 32 && diffDiasGroup <= 90) {
        filters.groupBy = 'week';
      } else if (diffDiasGroup > 90) {
        filters.groupBy = 'month';
      } else {
        filters.groupBy = 'day';
      }
      this.filters = filters;
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accessFrequenciesData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "titleColumnPeriod", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "titleColumnPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportFields", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "exportFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAccessLogs", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAccessLogs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsMyTeamCompanyEngagementAccessFrequencyDetailController;
});