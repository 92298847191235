define("portal-talentrh/mirage/factories/company-configuration", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    featureFlags: "{ ENABLE: false }",
    subscription: {
      availableModules: ["AGENDA", "NELA", "PROJECT-MANAGER", "PORTAL_COLAB", "PORTAL_GESTOR", "AVD", "PESQUISA", "TRAINING"],
      usersLimit: 1000,
      status: "paid",
      active: true,
      sellType: "convencional"
    },
    userSolicitationConfigs: {}
  });
  _exports.default = _default;
});