define("portal-talentrh/pods/habitus/team-insights/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HabitusTeamInsightsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class HabitusTeamInsightsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _defineProperty(this, "queryParams", ['startDate', 'endDate']);
      _initializerDefineProperty(this, "openFilterModal", _descriptor4, this);
      _initializerDefineProperty(this, "modalStartDate", _descriptor5, this);
      _initializerDefineProperty(this, "modalEndDate", _descriptor6, this);
      _initializerDefineProperty(this, "indicators", _descriptor7, this);
      _initializerDefineProperty(this, "indicatorsByType", _descriptor8, this);
      _initializerDefineProperty(this, "findIndicatorsByLeadersPerDay", _descriptor9, this);
      _initializerDefineProperty(this, "isgeneralIndicators", _descriptor10, this);
      _initializerDefineProperty(this, "isLoaded", _descriptor11, this);
      _initializerDefineProperty(this, "leaders", _descriptor12, this);
      _initializerDefineProperty(this, "filters", _descriptor13, this);
    }
    start() {
      this.modalStartDate = new Date(this.startDate);
      this.modalEndDate = new Date(this.endDate);
      this.indicators = this.model.indicators;
      this.indicatorsByType = this.model.indicatorsByType;
      this.findIndicatorsByLeadersPerDay = this.model.findIndicatorsByLeadersPerDay;
      this.isLoaded = true;
    }
    updateFilters(filterType) {
      if (filterType === 'm') {
        this.filters = {
          insights: true,
          date: true,
          branch: true,
          department: true,
          jobRole: true,
          jobFunction: true
        };
      } else if (filterType === 'e') {
        this.filters = {
          insights: true,
          date: true,
          branch: false,
          department: false,
          jobRole: false,
          jobFunction: false
        };
      }
    }
    changeLeaders() {
      this.isgeneralIndicators = !this.isgeneralIndicators;
      if (!this.isgeneralIndicators) {
        this.leaders = JSON.parse(JSON.stringify(this.model.hierarchy.leds));
        this.leaders.push(this.session.user.id);
        this.reloadIndicators.perform();
      } else {
        this.leaders = [];
        this.reloadIndicators.perform();
      }
    }
    filterByLead(id) {
      Array.from(document.getElementsByName('img-leaders')).map(function (button) {
        button.style.filter = 'grayscale(100%)';
      });
      document.getElementById('img-leaders' + id).style.filter = 'grayscale(0%)';
      this.leaders = [];
      this.leaders.push(id);
      this.reloadIndicators.perform();
    }
    *reloadIndicators(insight) {
      let end, start;
      if (insight) {
        end = insight.endDate;
        start = insight.startDate;
      }
      this.modalStartDate = start ? (0, _moment.default)(start, 'YYYY-MM-DD').toDate() : (0, _moment.default)(this.modalStartDate).toDate();
      this.modalEndDate = end ? (0, _moment.default)(end, 'YYYY-MM-DD').toDate() : (0, _moment.default)(this.modalEndDate).toDate();
      this.isLoaded = false;
      let leaders = this.leaders && this.leaders.length ? this.leaders : this.session.user.id;
      let startDate = start ? start : `${(0, _moment.default)(this.modalStartDate).format('YYYY-MM-DD')} 00:00:00`;
      let endDate = end ? end : `${(0, _moment.default)(this.modalEndDate).format('YYYY-MM-DD')} 23:59:59`;
      this.indicators = yield this.fetch.request(`findIndicatorsByLeaders`, {
        data: {
          leaders: leaders,
          endDate: endDate,
          startDate: startDate
        }
      });
      this.indicatorsByType = yield this.fetch.request(`findIndicatorsByType`, {
        data: {
          leaders: leaders,
          endDate: endDate,
          startDate: startDate
        }
      });
      this.findIndicatorsByLeadersPerDay = yield this.fetch.request(`findIndicatorsByLeadersPerDay`, {
        data: {
          leaders: leaders,
          endDate: endDate,
          startDate: startDate
        }
      });
      this.openFilterModal = false;
      this.isLoaded = true;
    }
    isHabitusTeamPage() {
      return this.router.currentRouteName === 'habitus.team-insights';
    }
    loadInsights(insight) {
      const teamPage = this.isHabitusTeamPage();
      if ((!insight.type || insight.type !== 'e') && teamPage) {
        this.router.transitionTo('habitus.index', {
          queryParams: insight
        });
        this.isInsightTeam = false;
      } else {
        this.reloadIndicators.perform(insight);
      }
      this.openFilterModal = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modalStartDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modalEndDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "indicators", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "indicatorsByType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "findIndicatorsByLeadersPerDay", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isgeneralIndicators", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isLoaded", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "leaders", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        insights: true,
        date: true,
        branch: true,
        department: true,
        jobRole: true,
        jobFunction: true
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeLeaders", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "changeLeaders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByLead", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "filterByLead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadIndicators", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "reloadIndicators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype)), _class));
  _exports.default = HabitusTeamInsightsController;
});