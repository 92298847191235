define("portal-talentrh/pods/components/feed/post-comments/comment/body/component", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['comment__body'],
    showSeeMore: false,
    showMore: false,
    buttonText: 'Ver mais',
    groups: Ember.computed('comment.post', function () {
      return Ember.get(this.comment.post, 'userGroup');
    }),
    // eslint-disable-next-line ember/require-computed-property-dependencies
    commentText: Ember.computed('@comment.text', 'showMore', function () {
      this.showSeeMore = this.comment.text.length > 300 ? true : false;
      if (!this.showSeeMore) {
        Ember.set(this, 'buttonText', 'Ver mais');
        return this.comment.text;
      } else if (!this.showMore) {
        Ember.set(this, 'buttonText', 'Ver mais');
        return this.comment.text.substr(0, 297) + "...";
      } else {
        Ember.set(this, 'buttonText', 'Ver menos');
        return this.comment.text;
      }
    }),
    publicationDate: Ember.computed('comment.createdAt', 'comment', function () {
      const timezone = _momentTimezone.default.tz.guess();
      if (this.comment.isResponse) {
        let responseCommentDate = _momentTimezone.default.tz(this.comment.createdAt, timezone);
        responseCommentDate = (0, _momentTimezone.default)(responseCommentDate).add(3, 'h');
        return responseCommentDate;
      } else {
        let commentDate = _momentTimezone.default.tz(this.comment.createdAt, timezone);
        return commentDate;
      }
    }),
    actions: {
      toogleComment(comment, event) {
        event.preventDefault();
        Ember.set(this, 'showMore', !this.showMore);
      }
    }
  });
  _exports.default = _default;
});