define("portal-talentrh/helpers/slice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;
  function slice(params /*, hash*/) {
    let array = params[0] || [];
    let begin = params[1] || 0;
    let end = params[2] || 0;
    return array.slice(begin, end);
  }
  var _default = Ember.Helper.helper(slice);
  _exports.default = _default;
});