define("portal-talentrh/pods/human-resources/user-solicitations/historic/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HumanResourcesUserSolicitationsHistoricRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.status = controller.statusOptions.firstObject;
      let filters = this.controllerFor('human-resources.user-solicitations').get('filters');
      if (!filters.createdAt) {
        controller.getFilters();
      } else {
        controller.getFilters(filters);
      }
    }
    deactivate() {
      this.controller.createdAt = null;
      this.controller.inputFilter = null;
      this.controller.status = this.controller.statusOptions.firstObject;
      this.controller.getFilters();
    }
  }
  _exports.default = HumanResourcesUserSolicitationsHistoricRoute;
});