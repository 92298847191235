define("portal-talentrh/pods/feed/me/professional-progression/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pU+JmMoD",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[2,\"\\n\"],[8,\"ui-card\",[[24,0,\"shadow-sm mb-3 h-100\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"d-flex align-items-center justify-content-between\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 font-weight-bold text-secondary\"],[12],[2,\"Meu quadro de Progressão Profissional\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,5,\"height: calc(100dvh - 14rem);\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[24,1,\"professional-progression-crud-container\"],[24,0,\"h-100\"],[4,[38,1],[[30,[36,0],[[32,0,[\"getProfessionalProgression\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"data\",\"professionalProgression\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"professional-progression-board\",[],[[\"@data\",\"@initialLevel\"],[[32,0,[\"data\",\"professionalProgression\",\"progressions\"]],[32,0,[\"data\",\"professionalProgression\",\"userLevel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"text-center font-italic\"],[12],[2,\"Nenhum dado encontrado\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/professional-progression/template.hbs"
    }
  });
  _exports.default = _default;
});