define("portal-talentrh/pods/company/userlist/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    permission: Ember.inject.service(),
    allowSendMessageIndividualChat: Ember.computed.alias('permission.accessProfile.allowSendMessageIndividualChat'),
    // Default values
    hasNewEmployeeCheck: false,
    cipeiro: false,
    brigade: false,
    homeOffice: false,
    // Life Cycle
    init() {
      this._super(...arguments);
      this.filtersSelected = {};
      this.filters = this.getFilters();
    },
    showJobTitle: Ember.computed('permission.{companyConfig.showJobFunctionCard,toAccessManagement}', function () {
      if (this.permission.companyConfig.showJobFunctionCard || this.permission.toAccessManagement) {
        return true;
      } else {
        return false;
      }
    }),
    updateFilter: (0, _emberConcurrency.task)(function* (model) {
      yield (0, _emberConcurrency.timeout)(300);
      let hasNewEmployees;
      if (model) {
        hasNewEmployees = model.hasNewEmployees;
      }
      if (!this.hasNewEmployeeCheck && hasNewEmployees && this.filtersSelected) {
        this.send('toggleAdvancedFilter');
        Ember.set(this, 'hasNewEmployeeCheck', true);
      }
      let filters = this.getFilters(hasNewEmployees);
      filters.term = this.search;
      Ember.set(this, 'filters', filters);
    }).restartable(),
    getFilters(hasNewEmployees) {
      let selected = this.filtersSelected;
      let filters = {
        fields: ['email', 'professionalPhone', 'cipeiro', 'brigade', 'homeOffice'],
        where: {}
      };
      if (selected.branch) {
        filters.where.branch = selected.branch.id;
      }
      if (selected.department) {
        filters.where.department = selected.department.id;
      }
      if (selected.jobRole) {
        filters.where.jobRole = selected.jobRole.id;
      }
      if (selected.jobFunction) {
        filters.where.jobFunction = selected.jobFunction.id;
      }
      if (hasNewEmployees || this.hasNewEmployeeCheck) {
        filters.where.admissionDate = true;
      }
      if (this.cipeiro) {
        filters.where.cipeiro = true;
      }
      if (this.brigade) {
        filters.where.brigade = true;
      }
      if (this.homeOffice) {
        filters.where.homeOffice = true;
      }
      if (selected.searchTerm) {
        filters.searchTerm = selected.searchTerm;
      }
      return filters;
    },
    resetFilters() {
      Ember.setProperties(this, {
        search: null,
        advancedFilter: null,
        filters: {},
        filtersSelected: {},
        hasNewEmployeeCheck: false,
        cipeiro: false,
        brigade: false,
        homeOffice: false
      });
    },
    // Actions
    actions: {
      toggleAdvancedFilter() {
        this.toggleProperty('advancedFilter');
        Ember.set(this, 'filtersSelected', {});

        // Reseta a lista de colaboradores ao fechar os filtros avançados
        if (!this.advancedFilter && Object.keys(this.filtersSelected).length) {
          this.updateFilter.perform();
        }
      },
      toggleCheckbox() {
        this.toggleProperty('hasNewEmployeeCheck');
        this.updateFilter.perform();
      },
      toggleCheckboxCipa() {
        this.toggleProperty('cipeiro');
        this.updateFilter.perform();
      },
      toggleCheckboxBrigade() {
        this.toggleProperty('brigade');
        this.updateFilter.perform();
      },
      toggleCheckboxHomeOffice() {
        this.toggleProperty('homeOffice');
        this.updateFilter.perform();
      }
    }
  });
  _exports.default = _default;
});