define("portal-talentrh/pods/portal-manager/request/hr/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QBUVna/k",
    "block": "{\"symbols\":[\"@solicitation\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[15,\"title\",[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[32,1,[\"userFullName\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[32,1,[\"subject\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-center w-25\"],[12],[1,[32,1,[\"statusLabel\"]]],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-right align-middle\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"w-100\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-sm btn-primary\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"cancelSolicitation\"]]],null]],[14,\"title\",\"Cancelar\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-ban\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn btn-sm btn-danger\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"removeSolicitation\"]]],null]],[14,\"title\",\"Remover\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/portal-manager/request/hr/item/template.hbs"
    }
  });
  _exports.default = _default;
});