define("portal-talentrh/pods/notification/pde/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Multi line computed properties
    link: Ember.computed('notification', 'notification.isLoaded', function () {
      let type = Ember.get(this, 'notification.type');
      let linksByType = {
        'pde': () => {
          let modelId = Ember.get(this, 'notification.modelId');
          return `pde/${modelId}`;
        },
        'pde-action': () => {
          let modelId = Ember.get(this, 'notification.modelId');
          let pde = Ember.get(this, 'notification.linkParams.pde');
          return `pde/${pde}/pdeAction/${modelId}`;
        },
        'pde-evidence': () => {
          let modelId = Ember.get(this, 'notification.modelId');
          let pde = Ember.get(this, 'notification.linkParams.pde');
          let pdeAction = Ember.get(this, 'notification.linkParams.pdeAction');
          return `pde/${pde}/pdeAction/${pdeAction}/evidence/${modelId}`;
        }
      };
      return linksByType[type]();
    })
  });
  _exports.default = _default;
});