define("portal-talentrh/pods/components/album-images-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uERBTicJ",
    "block": "{\"symbols\":[\"image\",\"index\",\"@postImages\"],\"statements\":[[10,\"div\"],[14,0,\"picture-uploader-preview position-relative\"],[12],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"album-images-select/item\",[],[[\"@image\",\"@index\",\"@postImages\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n    \"],[11,\"li\"],[16,0,[31,[\"width-3 no-image \",[30,[36,5],[[30,[36,4],[[35,3,[\"length\"]],[35,2]],null],\"d-none\"],null]]]],[4,[38,8],[[32,0],\"click\",[30,[36,7],[\"file-field-\",[35,6]],null]],null],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"picture-uploader-add\"],[14,\"title\",\"Adicionar Imagens\"],[12],[2,\"Adicionar Imagem\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"picture-remove-controls\"],[12],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"input\"],[14,0,\"upload-image d-none\"],[15,1,[30,[36,7],[\"file-field-\",[35,6]],null]],[14,\"multiple\",\"multiple\"],[15,\"onchange\",[30,[36,8],[[32,0],\"selectImage\"],null]],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"maxImages\",\"postImages\",\"lt\",\"unless\",\"elementId\",\"concatenate\",\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/album-images-select/template.hbs"
    }
  });
  _exports.default = _default;
});