define("portal-talentrh/pods/feed/me/index/goals/items/exclusive-goal/competences-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0SVj8qCh",
    "block": "{\"symbols\":[\"option\",\"@competenceSelected\",\"@competences\"],\"statements\":[[2,\" \"],[8,\"power-select\",[],[[\"@placeholder\",\"@renderInPlace\",\"@selected\",\"@options\",\"@onChange\",\"@noMatchesMessage\"],[\"Filtrar mês/ano \",true,[32,2],[32,3],[32,0,[\"onCompetenceSelected\"]],\"Sem registros\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/goals/items/exclusive-goal/competences-dropdown/template.hbs"
    }
  });
  _exports.default = _default;
});