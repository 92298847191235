define("portal-talentrh/pods/components/quick-tips/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vv85tkiO",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"videoId\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"quick-tip-container position-fixed r-9\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-link quick-tips-icon\"],[14,\"title\",\"Dica rápida\"],[15,\"onclick\",[30,[36,0],[[32,0],\"toggleVideo\"],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-lightbulb-on\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[11,\"div\"],[24,0,\"spinner-grow spinner-grow position-absolute t-0 l-2 text-primary\"],[24,\"role\",\"status\"],[4,[38,1],[[32,0,[\"hideEffect\"]]],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showVideo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"quick-tips__float-video\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-link position-absolute r-0 t-0 py-0 z-index-1\"],[15,\"onclick\",[30,[36,0],[[32,0],\"toggleVideo\"],null]],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fas fa-times\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"iframe\"],[15,\"src\",[31,[\"https://player.vimeo.com/video/\",[32,0,[\"videoId\"]],\"?autoplay=1\"]]],[14,\"frameborder\",\"0\"],[14,\"allow\",\"autoplay\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/quick-tips/template.hbs"
    }
  });
  _exports.default = _default;
});