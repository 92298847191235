define("portal-talentrh/pods/components/user-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['statusClass'],
    attributeBindings: ['title'],
    contactService: Ember.inject.service(),
    statusClass: Ember.computed('user.{chatStatus,online}', 'contactService.isListLoaded', function () {
      let status = Ember.get(this, 'user.chatStatus');
      const isOnline = Ember.get(this, 'user.online');
      if (isOnline) {
        if (status === 2) {
          return 'user-status user-status--busy';
        }
        return 'user-status user-status--online';
      }
      if (status === 3) {
        return 'user-status user-status--away';
      }
      if (this.showOffline) {
        return 'user-status user-status--offline';
      }
      return 'd-none';
    }),
    title: Ember.computed('user.chatStatus', function () {
      let status = Ember.get(this, 'user.chatStatus');
      if (status === 1) {
        return 'Disponível';
      }
      if (status === 2) {
        return 'Ocupado';
      }
      if (status === 3) {
        return 'Ausente';
      }
      if (this.showOffline) {
        return 'Desconectado';
      }
      return '';
    })
  });
  _exports.default = _default;
});