define("portal-talentrh/helpers/prev-message-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function prevMessageDate(_ref) {
    let [messages, index] = _ref;
    if (index > 0) {
      return messages[index - 1].createdAt;
    }
    new Date();
  });
  _exports.default = _default;
});