define("portal-talentrh/pods/components/feed/post-comments/comment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['comment', 'position-relative'],
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    store: Ember.inject.service(),
    replies: Ember.A(),
    isOwner: Ember.computed('comment.user.id', 'permission.admin', 'session.user.{admin,id}', function () {
      return this.permission.admin || this.session.user.id === Ember.get(this, 'comment.user');
    }),
    commentReplies: Ember.computed('showReplies', 'comment.id', 'comments.[]', 'replies.[]', function () {
      let commentId = parseInt(this.get('comment.id'));
      let showReplies = this.showReplies;
      if (!showReplies || !this.replies.length) {
        return Ember.A();
      }
      return this.replies.filterBy('responseTo', commentId);
    })
  });
  _exports.default = _default;
});