define("portal-talentrh/pods/components/viewer-vacancy-ad/component", ["exports", "ember-concurrency", "moment", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    permission: Ember.inject.service(),
    permitEditVacancy: Ember.computed.or('permission.accessProfile.allowManageVacancies', 'permission.admin'),
    isRh: Ember.computed.alias('permission.accessProfile.allowManageVacancies'),
    classNames: 'row',
    skip: 0,
    limit: 12,
    loadMore: true,
    openModal: false,
    vacancy: {},
    isAdmin: false,
    manageOnlyBranch: Ember.computed.alias('permission.accessProfile.manageOnlyBranch'),
    didInsertElement() {
      if (this.permission.admin) {
        this.isAdmin = true;
      }
      this.loadVacancies.perform();
    },
    // Taks
    loadVacancies: (0, _emberConcurrency.task)(function* () {
      const skip = this.skip;
      const limit = this.limit;
      const vacancies = this.vacancies;
      const displayType = this.displayType;
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      const filters = {
        all: {},
        open: {
          applicationdeadline: {
            '>=': currentDate
          }
        },
        expired: {
          applicationdeadline: {
            '<=': currentDate
          }
        }
      };
      Ember.set(this, 'loading', true);
      if (!this.isRh && !this.isAdmin) {
        this.manageOnlyBranch = true;
      } else if (this.isAdmin && this.manageOnlyBranch) {
        this.manageOnlyBranch = false;
      }
      let sort;
      if (displayType === 'all' || displayType === 'expired') {
        sort = 'applicationdeadline DESC';
      } else {
        sort = 'applicationdeadline ASC';
      }
      let {
        vacancy
      } = yield this.ajax.request('vacancy/find', {
        data: {
          limit: limit,
          skip: skip,
          manageOnlyBranch: this.manageOnlyBranch,
          where: JSON.stringify(filters[displayType]),
          sort: sort
        },
        host: _environment.default.apiUrl.conta
      });
      if (vacancy) {
        let vacanciesAux = [];
        let vacancyAux = [];
        vacancyAux = JSON.parse(JSON.stringify(vacancy));
        if (vacanciesAux.length) {
          vacancyAux.reverse();
          vacancy = vacancyAux.concat(vacanciesAux);
        }
        Ember.set(this, 'loading', false);
        if (vacancy.length < limit) {
          Ember.set(this, 'loadMore', false);
        } else {
          Ember.set(this, 'loadMore', true);
          this.incrementProperty('skip', limit);
        }
        if (vacancies) {
          vacancy = vacancies.concat(vacancy);
        }
        Ember.set(this, 'vacancies', vacancy);
      }
    }),
    actions: {
      loadMore() {
        this.loadVacancies.perform();
      },
      openModal(vacancy) {
        if (vacancy) {
          const currentDate = (0, _moment.default)().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          const vacancyDate = (0, _moment.default)(vacancy.applicationdeadline).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          Ember.set(this, 'openModal', true);
          Ember.set(this, 'vacancy', vacancy);
          if (vacancyDate >= currentDate) {
            Ember.set(this, 'vacancy.open', true);
          } else {
            Ember.set(this, 'vacancy.open', false);
          }
        }
      },
      editVacancy(id) {
        const url = `${_environment.default.appUrl.conta}registry/social/vacancy/${id}/edit/?to=toEditVacancy`;
        window.open(url, '_blank');
      }
    }
  });
  _exports.default = _default;
});