define("portal-talentrh/pods/application/navbar/search/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "P//C2fZH",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"@result\",\"@closeSearch\"],\"statements\":[[6,[37,3],[[32,3,[\"type\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"icon\"]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate ml-2\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],[[32,3,[\"title\"]],[30,[36,0],[[32,3,[\"body\"]]],null]],[[\"limit\"],[80]]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\",\"@click\"],[\"post.show\",[32,3,[\"id\"]],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"user-attribute\",[],[[\"@userId\"],[[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0 mr-3\"]],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"text-truncate mb-0 pr-3\"],[15,\"title\",[32,1,[\"fullName\"]]],[12],[2,\"\\n      \"],[1,[32,1,[\"fullName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"mb-0 ml-auto text-right text-nowrap\"],[14,\"title\",\"Ramal\"],[12],[2,\"\\n      \"],[1,[32,3,[\"externalLine\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\",\"@click\"],[\"profile.show\",[32,1,[\"id\"]],[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"html\",\"or\",\"truncate-text\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/search/item/template.hbs"
    }
  });
  _exports.default = _default;
});