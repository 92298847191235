define("portal-talentrh/pods/habitus/preferences/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9UW+Rw2j",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"habitus/preferences/wizard\",[],[[\"@preferences\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/preferences/template.hbs"
    }
  });
  _exports.default = _default;
});