define("portal-talentrh/pods/feed/me/index/goals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N5CofZiw",
    "block": "{\"symbols\":[\"Card\",\"@user\",\"@roundedOff\",\"@class\"],\"statements\":[[6,[37,0],[[32,0,[\"permission\",\"companyConfig\",\"showMyGoalsCard\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-card\",[[24,\"data-step\",\"feed-goal\"],[16,0,[31,[[32,4],\" shadow-sm \",[30,[36,0],[[32,3],\"rounded-0\",\"p-3\"],null]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"useExclusiveGoals\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"feed/me/index/goals/items/exclusive-goal\",[],[[\"@user\",\"@competenceSelected\"],[[32,2],[32,0,[\"competenceSelected\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"feed/me/index/goals/items/goal\",[],[[\"@user\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/goals/template.hbs"
    }
  });
  _exports.default = _default;
});