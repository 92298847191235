define("portal-talentrh/pods/dashboard/show/gridstack/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7oC+hafJ",
    "block": "{\"symbols\":[\"gridItem\",\"index\",\"@fullscreen\",\"@slideIndex\",\"@gridItems\"],\"statements\":[[10,\"div\"],[14,0,\"dashboard-template-body mt-3\"],[15,\"data-slide\",[32,4]],[12],[2,\"\\n  \"],[8,\"grid-stack\",[],[[\"@options\"],[[30,[36,0],null,[[\"column\",\"maxRow\",\"cellHeight\",\"float\"],[12,2,[32,0,[\"cellHeight\"]],true]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"dashboard/grid-item\",[],[[\"@dashboardSlideItem\",\"@fullscreen\"],[[32,1],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hash\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/dashboard/show/gridstack/template.hbs"
    }
  });
  _exports.default = _default;
});