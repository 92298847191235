define("portal-talentrh/pods/company/decision/index/vote/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyDecisionIndexVoteRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controller.voteSelected = null;
      this.controller.justification = null;
    }
  }
  _exports.default = CompanyDecisionIndexVoteRoute;
});