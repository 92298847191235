define("portal-talentrh/pods/indicators/people-analytics/my-team/company-performance/pdi-details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsMyTeamCompanyPerformancePdiDetailsRoute extends Ember.Route {
    deactivate() {
      this.controller.filters = {};
      this.controller.filtersSelected = {};
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsMyTeamCompanyPerformancePdiDetailsRoute;
});