define("portal-talentrh/pods/notification/backup-solicitation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "69HfHfnS",
    "block": "{\"symbols\":[\"@click\",\"@notification\"],\"statements\":[[10,\"div\"],[14,0,\"row w-100 m-auto h-vh-2 dad_open_chat\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-7 d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[32,0,[\"linkTo\"]]],[14,0,\"d-flex\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mx-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n          \"],[1,[32,2,[\"message\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-3 d-flex flex-column align-items-end justify-content-between\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"mt-5\"],[12],[1,[30,[36,1],[[32,2,[\"createdAt\"]]],null]],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"navbar__dropdown__item__unread navbar__dropdown__item__unread\"],[14,\"title\",\"Marcar como lida\"],[15,\"onclick\",[30,[36,0],[[32,1]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"moment-from-now\",\"isRead\",\"unless\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/notification/backup-solicitation/template.hbs"
    }
  });
  _exports.default = _default;
});