define("portal-talentrh/pods/home/my-team/team-climate-details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeMyTeamTeamClimateDetailsRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.interval = {
        label: 'Últimos 7 dias',
        value: 7
      };
      this.controller.factor = 'internal';
      this.controller.filterValue = '';
    }
  }
  _exports.default = HomeMyTeamTeamClimateDetailsRoute;
});