define("portal-talentrh/pods/habitus/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HabitusController = (_dec = Ember.inject.controller('habitus.index'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.alias('habitus.showVideo'), _dec11 = Ember.computed.alias('habitus.activeButton'), _dec12 = Ember.computed.alias('permission.insightFlags.aiAssistant'), _dec13 = Ember.computed.alias('permission.allowShowHabitusIA'), _dec14 = Ember.computed.alias('permission.allowHabitusIa'), _dec15 = Ember.computed.alias('permission.admin'), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class HabitusController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "habitusController", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "habitus", _descriptor3, this);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "hasTransitioned", _descriptor6, this);
      _initializerDefineProperty(this, "routerName", _descriptor7, this);
      _initializerDefineProperty(this, "openFilterModal", _descriptor8, this);
      _initializerDefineProperty(this, "filters", _descriptor9, this);
      _initializerDefineProperty(this, "showVideo", _descriptor10, this);
      _initializerDefineProperty(this, "isActiveButton", _descriptor11, this);
      _initializerDefineProperty(this, "aiAssistant", _descriptor12, this);
      _initializerDefineProperty(this, "allowShowHabitusIA", _descriptor13, this);
      _initializerDefineProperty(this, "allowHabitusIa", _descriptor14, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor15, this);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off('routeDidChange', this.handleRouteChange.bind(this));
    }
    updateFilters(filterType) {
      if (filterType === 'm') {
        this.filters = {
          insights: true,
          date: true,
          branch: true,
          department: true,
          jobRole: true,
          jobFunction: true
        };
      } else if (filterType === 'e') {
        this.filters = {
          insights: true,
          date: true,
          branch: false,
          department: false,
          jobRole: false,
          jobFunction: false
        };
      }
    }
    loadInsights() {
      this.IAScreen();
    }
    IAScreen() {
      if (this.allowShowHabitusIA && this.allowHabitusIa || this.isAdmin) {
        this.isActiveButton = 'ia';
        this.router.transitionTo('habitus.index');
      } else {
        this.toast.info('Token de integração desabilitado ou este perfil não possui acesso a funcionalidade');
        this.router.transitionTo('habitus.news');
        this.isActiveButton = 'news';
      }
    }
    newsScreen() {
      this.isActiveButton = 'news';
      this.router.transitionTo('habitus.news');
    }
    insightsSCreen() {
      this.isActiveButton = 'insights';
      this.router.transitionTo('habitus.index');
    }
    showVideoApresentation() {
      this.showVideo = true;
      this.router.transitionTo('habitus.index');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "habitusController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasTransitioned", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "routerName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        insights: true,
        date: true,
        branch: true,
        department: true,
        jobRole: true,
        jobFunction: true
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showVideo", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isActiveButton", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "aiAssistant", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "allowShowHabitusIA", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "allowHabitusIa", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFilters", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "IAScreen", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "IAScreen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newsScreen", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "newsScreen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insightsSCreen", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "insightsSCreen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showVideoApresentation", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "showVideoApresentation"), _class.prototype)), _class));
  _exports.default = HabitusController;
});