define("portal-talentrh/pods/profile/show/timeline/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ProfileShowTimelineRoute = (_dec = Ember._action, (_class = class ProfileShowTimelineRoute extends Ember.Route {
    model() {
      return this.modelFor('profile.show');
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.loadPosts.perform();
    }
    willTransition() {
      this.controller.set('branches', null);
      this.controller.set('reloadFilters', !this.controller.get('reloadFilters'));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = ProfileShowTimelineRoute;
});