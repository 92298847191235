define("portal-talentrh/pods/post/event/route", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    fetch: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.fetch.request('/feed/posts', {
        host: _environment.default.apiUrl.core,
        data: {
          limit: 5,
          sort: 'createdAt DESC',
          type: 'event'
        }
      });
    }
  });
  _exports.default = _default;
});