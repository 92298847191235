define("portal-talentrh/pods/errors/inactive-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ro9y4+Lt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"span\"],[14,0,\"text-xxxl text-nela\"],[12],[10,\"i\"],[14,0,\"fa fa-meh-o\"],[12],[13],[13],[13],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Opa! Parece que a conta desta empresa está inativa.\"],[13],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"Entre em contato com o RH da sua empresa para maiores detalhes.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-success\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"logout\"],null],[12],[2,\"Voltar\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/errors/inactive-account/template.hbs"
    }
  });
  _exports.default = _default;
});