define("portal-talentrh/pods/application/navbar/notification/pdi/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    link: Ember.computed('notification.linkParams.{pdi,pdiAction}', 'notification.{modelId,type}', function () {
      const type = this.notification.type;
      const linksByType = {
        'pdi': () => {
          let modelId = this.notification.modelId;
          return `pdi/${modelId}`;
        },
        'pdi-action': () => {
          const modelId = this.notification.modelId;
          const pdi = this.notification.linkParams ? this.notification.linkParams.pdi : null;
          return `pdi/${pdi}/pdiAction/${modelId}`;
        },
        'pdi-action-expired': () => {
          let modelId = this.notification.modelId;
          const pdi = this.notification.linkParams ? this.notification.linkParams.pdi : null;
          return `pdi/${pdi}/pdiAction/${modelId}`;
        },
        'pdi-action-expire-in': () => {
          let modelId = this.notification.modelId;
          const pdi = this.notification.linkParams ? this.notification.linkParams.pdi : null;
          return `pdi/${pdi}/pdiAction/${modelId}`;
        },
        'evidence': () => {
          const modelId = this.notification.modelId;
          const pdi = this.notification.linkParams ? this.notification.linkParams.pdi : null;
          const pdiAction = this.notification.linkParams ? this.notification.linkParams.pdiAction : null;
          return `pdi/${pdi}/pdiAction/${pdiAction}/evidence/${modelId}`;
        }
      };
      return linksByType[type]();
    })
  });
  _exports.default = _default;
});