define("portal-talentrh/pods/view-team-member/notes/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberNotesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.alias('permission.admin'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ViewTeamMemberNotesController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "initialDate", _descriptor5, this);
      _initializerDefineProperty(this, "endDate", _descriptor6, this);
      _initializerDefineProperty(this, "showMessageInformation", _descriptor7, this);
      _initializerDefineProperty(this, "userIsMember", _descriptor8, this);
      _initializerDefineProperty(this, "filters", _descriptor9, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor10, this);
    }
    setInitialDate(_ref) {
      let {
        date
      } = _ref;
      this.initialDate = (0, _moment.default)(date);
      this.filterDate();
    }
    setEndDate(_ref2) {
      let {
        date
      } = _ref2;
      this.endDate = (0, _moment.default)(date);
      this.filterDate();
    }
    filterDate() {
      if (this.initialDate && this.endDate && (0, _moment.default)(this.initialDate).isAfter(this.endDate)) {
        return this.swal.warning('A data final não pode ser anterior a data inicial');
      }
    }
    reloadFilters(user) {
      this.filters = {
        user: user ? user.id : this.user.id,
        initialDate: this.initialDate ? (0, _moment.default)(this.initialDate).format('YYYY-MM-DD 00:00:00') : null,
        endDate: this.endDate ? (0, _moment.default)(this.endDate).format('YYYY-MM-DD 23:59:59') : null
      };
    }
    async buildFilters() {
      const filters = {
        user: this.user.id,
        isLeader: false
      };
      if (this.initialDate) {
        filters.initialDate = this.initialDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.endDate) {
        filters.endDate = this.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      this.filters = filters;
    }
    async checkMember() {
      let myTeamMembers = await this.fetch.request(`departments/myTeamMembers?limit=9999`);
      let member = myTeamMembers.findIndex(u => Number(u.id) === Number(this.user.id));
      this.userIsMember = member >= 0;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showMessageInformation", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "userIsMember", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        user: this.user.id
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setInitialDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setEndDate", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterDate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "filterDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "reloadFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkMember", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "checkMember"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberNotesController;
});