define("portal-talentrh/pods/components/user-list-experiences/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);
      if (!this.record.activityDescription) {
        document.getElementById("nela-3152-experience--" + this.record.id).setAttribute("style", "cursor: auto !important;");
      }
      $('#nela-3152-experience--' + this.record.id).on('show.bs.collapse', {
        id: this.record.id
      }, function (event) {
        $('#nela-3152-experience--' + event.data.id + ' .fas').removeClass('fa-caret-down');
        $('#nela-3152-experience--' + event.data.id + ' .fas').addClass('fa-caret-up');
        $('#nela-3152-experience--' + event.data.id + ' .fas').attr('title', 'Ocultar descrição');
      });
      $('#nela-3152-experience--' + this.record.id).on('hide.bs.collapse', {
        id: this.record.id
      }, function (event) {
        $('#nela-3152-experience--' + event.data.id + ' .fas').removeClass('fa-caret-up');
        $('#nela-3152-experience--' + event.data.id + ' .fas').addClass('fa-caret-down');
        $('#nela-3152-experience--' + event.data.id + ' .fas').attr('title', 'Exibir descrição');
      });
    }
  });
  _exports.default = _default;
});