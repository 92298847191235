define("portal-talentrh/pods/absence-board/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qB6f49u5",
    "block": "{\"symbols\":[],\"statements\":[[8,\"absence-board/form\",[],[[\"@absence\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/absence-board/edit/template.hbs"
    }
  });
  _exports.default = _default;
});