define("portal-talentrh/components/nela-input-money", ["exports", "nela-mask/components/nela-input-money"], function (_exports, _nelaInputMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _nelaInputMoney.default;
    }
  });
});