define("portal-talentrh/pods/human-resources/user-solicitations/skeleton/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lrL8jC9t",
    "block": "{\"symbols\":[\"Skeleton\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[],[[\"@tagName\"],[\"\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/human-resources/user-solicitations/skeleton/template.hbs"
    }
  });
  _exports.default = _default;
});