define("portal-talentrh/pods/components/post-branches-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KOZzfsqk",
    "block": "{\"symbols\":[\"Modal\",\"Card\",\"branch\",\"@openModal\",\"@branches\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\"],[[32,4],[30,[36,1],[[32,0,[\"close\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Filiais\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-card\",[[24,0,\"mb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"px-2 py-3 border-bottom d-flex\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"text-truncate w-100\"],[15,\"title\",[30,[36,0],[[32,3,[\"tradingName\"]],[32,3,[\"name\"]]],null]],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-building text-primary mx-2\"],[12],[13],[2,\"\\n            \"],[1,[30,[36,0],[[32,3,[\"tradingName\"]],[32,3,[\"name\"]]],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-branches-view/template.hbs"
    }
  });
  _exports.default = _default;
});