define("portal-talentrh/pods/forum/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    actions: {
      reload() {
        this.controller.set('reloadList', new Date());
      },
      didTransition() {
        this.controller.set('reloadList', null);
        return true;
      }
    }
  });
  _exports.default = _default;
});