define("portal-talentrh/pods/notification/list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j9m8aSEE",
    "block": "{\"symbols\":[\"@icon\",\"@title\"],\"statements\":[[10,\"i\"],[15,0,[31,[\"fa \",[32,1],\" text-primary\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"],[10,\"h6\"],[14,0,\"mb-0 ml-3 font-weight-bold\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/notification/list-item/template.hbs"
    }
  });
  _exports.default = _default;
});