define("portal-talentrh/pods/components/user-list-formations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JtNE3yci",
    "block": "{\"symbols\":[\"record\",\"@records\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"user-list-formations/item\",[],[[\"@record\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-graduation-cap mb-1\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 text-center\"],[12],[2,\"Ainda não há informações\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-list-formations/template.hbs"
    }
  });
  _exports.default = _default;
});