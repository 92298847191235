define("portal-talentrh/pods/feed/me/index/pdis/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const to = {
    resultPdi(pdi) {
      let result = 0;
      let pdiActions = pdi.get('pdiActions');
      const partialScores = pdiActions.mapBy('partialScore').filter(p => p);
      if (partialScores.length) {
        result = partialScores.reduce((sum, current) => {
          return sum + current;
        });
      }
      return {
        percent: result,
        percentLabel: `${result}%`,
        pdiActions
      };
    }
  };
  let PdisComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember.computed.sort('pdis', 'pdisOrderedSorting'), _dec5 = Ember._action, (_class = class PdisComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "pdis", _descriptor3, this);
      _defineProperty(this, "pdisOrderedSorting", ['expirationDate:desc']);
      _initializerDefineProperty(this, "pdisOrdered", _descriptor4, this);
      this.loadPdisInfo.perform();
    }
    *loadPdisInfo() {
      try {
        const user = this.session.user.id;
        const data = yield this.store.query('pdi', {
          user
        });
        const pdis = data.map(pdi => {
          let {
            percent,
            percentLabel,
            pdiActions
          } = to.resultPdi(pdi);
          return {
            ...pdi.toJSON(),
            percent,
            percentLabel,
            pdiActions
          };
        });
        this.pdis.addObjects(pdis);
      } catch (err) {
        Ember.debug(err);
      }
    }
    setProgressBarWidth(el) {
      el?.setAttribute('style', 'width:0');
      setTimeout(() => {
        el?.setAttribute('style', `width:${el.getAttribute('aria-valuenow')}`);
      }, 10);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pdis", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pdisOrdered", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadPdisInfo", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadPdisInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProgressBarWidth", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setProgressBarWidth"), _class.prototype)), _class));
  _exports.default = PdisComponent;
});