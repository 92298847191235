define("portal-talentrh/pods/components/album-images-select/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['position-relative', 'group', 'w-25', 'mb-2'],
    // Default values
    createSubtitle: false,
    // Actions
    actions: {
      addSubtitle() {
        let photo = this.postImages.objectAt(this.index);
        photo.subtitle = this.subtitle;
        this.send('cancelSubtitle');
      },
      addSubtitleOnEnter(_, e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          this.send('addSubtitle');
        }
      },
      editSubtitle() {
        let photo = this.postImages.objectAt(this.index);
        this.subtitle = photo.subtitle;
        Ember.set(this, 'createSubtitle', true);
      },
      cancelSubtitle() {
        Ember.set(this, 'createSubtitle', false);
        Ember.set(this, 'subtitle', null);
      },
      deleteImage() {
        this.postImages.removeAt(this.index);
      }
    }
  });
  _exports.default = _default;
});