define("portal-talentrh/models/company-program", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _attr.default)('boolean'),
    attachment: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    order: (0, _attr.default)('number'),
    path: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    attachments: (0, _relationships.hasMany)('company-program-attachment', {
      inverse: 'companyProgram'
    }),
    branches: (0, _relationships.hasMany)('branch', {
      inverse: 'companyprograms'
    }),
    topics: (0, _relationships.hasMany)('company-program-topic', {
      inverse: 'companyProgram'
    })
  });
  _exports.default = _default;
});