define("portal-talentrh/models/post-tag", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _attr.default)('boolean'),
    color: (0, _attr.default)('string'),
    order: (0, _attr.default)('number'),
    title: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company')
  });
  _exports.default = _default;
});