define("portal-talentrh/pods/portal-manager/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    toAccessMyTeam: Ember.computed.alias('permission.toAccessMyTeam'),
    admin: Ember.computed.alias('permission.admin'),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      if (!this.toAccessMyTeam && !this.admin) {
        return this.router.transitionTo('errors.forbidden');
      }
    }
  });
  _exports.default = _default;
});