define("portal-talentrh/pods/portal-manager/feeling/controller", ["exports", "moment", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _moment, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PortalManagerFeelingController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed.or('permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam', 'permission.accessProfile.admin'), _dec13 = Ember.computed.alias('permission.led'), _dec14 = Ember.computed('filtersSelected.departments.@each.id'), _dec15 = Ember.computed('filtersSelected.branches.@each.id'), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class PortalManagerFeelingController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "swal", _descriptor5, this);
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor7, this);
      _initializerDefineProperty(this, "openFilterModal", _descriptor8, this);
      _initializerDefineProperty(this, "disableBranchAndDepartment", _descriptor9, this);
      _initializerDefineProperty(this, "disableBranch", _descriptor10, this);
      _initializerDefineProperty(this, "ledTypeSelected", _descriptor11, this);
      _defineProperty(this, "feelingOptions", ['Ansioso', 'Apaixonado', 'Cansado', 'Desanimado', 'Divertido', 'Entediado', 'Entusiasmado', 'Feliz', 'Inteligente', 'Irritado', 'Legal', 'Triste']);
      _defineProperty(this, "statusOptions", [{
        value: 'concludedAction',
        label: 'Ação realizada'
      }, {
        value: 'pendingAction',
        label: 'Ação pendente'
      }, {
        value: 'notRequiredAction',
        label: 'Não requer ação'
      }]);
      _initializerDefineProperty(this, "allowIndicatorOrganizationalClimateMyTeam", _descriptor12, this);
      _initializerDefineProperty(this, "led", _descriptor13, this);
    }
    get selectedDepartmentsIds() {
      if (this.filtersSelected && this.filtersSelected.departments && this.filtersSelected.departments.length) {
        return this.filtersSelected.departments.map(b => b.id);
      }
      return null;
    }
    get selectedBranchesIds() {
      if (this.filtersSelected && this.filtersSelected.branches && this.filtersSelected.branches.length) {
        return this.filtersSelected.branches.map(b => b.id);
      }
      return null;
    }
    *exportFeelingsToXlsx() {
      try {
        let url = `${_environment.default.apiUrl.social}/feelings/export`;
        const filters = this.filters;
        const search = new URLSearchParams(filters).toString();
        url += `?${search}`;
        this.swal.loading('Carregando...');
        let xlxs = yield fetch(url, {
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        });
        let filename = yield xlxs.headers.get('content-disposition').match(/filename="(.+)"/)[1];
        xlxs = yield xlxs.blob();
        let element = document.createElement('a');
        element.href = URL.createObjectURL(xlxs);
        element.download = filename;
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        this.swal.close();
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }
    buildFilters(closeModal) {
      const filters = {
        ledType: 'd',
        teamManagement: true
      };
      if (this.openFilterModal && !this.filtersSelected.initialDate) {
        return this.swal.warning('A data inicial não foi informada');
      }
      if (this.openFilterModal && !this.filtersSelected.endDate) {
        return this.swal.warning('A data final não foi informada');
      }
      if (this.openFilterModal && this.filtersSelected.initialDate) {
        if ((0, _moment.default)(this.filtersSelected.endDate).endOf('day').isBefore((0, _moment.default)(this.filtersSelected.initialDate).startOf('day'))) {
          return this.swal.warning('A data final não pode ser anterior a data inicial');
        }
      }
      if (this.filtersSelected.initialDate && this.filtersSelected.endDate) {
        filters.start = (0, _moment.default)(this.filtersSelected.initialDate).startOf('day').format('YYYY-MM-DD HH:mm');
        filters.end = (0, _moment.default)(this.filtersSelected.endDate).endOf('day').format('YYYY-MM-DD HH:mm');
      }
      if (this.filtersSelected.users && this.filtersSelected.users.length) {
        filters.users = this.filtersSelected.users.map(u => u.id);
      }
      if (this.filtersSelected?.branches && this.filtersSelected.branches.length) {
        filters.branches = this.filtersSelected.branches.map(b => b.id);
      }
      if (this.filtersSelected?.departments && this.filtersSelected.departments.length) {
        filters.departments = this.filtersSelected.departments.map(d => d.id);
      }
      if (this.filtersSelected?.feelings && this.filtersSelected.feelings.length) {
        filters.feelings = this.filtersSelected.feelings;
      }
      if (this.filtersSelected?.status) {
        filters.status = this.filtersSelected.status.value;
      }
      if (this.ledTypeSelected) {
        filters.ledType = this.ledTypeSelected;
      }
      this.filters = filters;
      if (closeModal) {
        this.openFilterModal = false;
      }
      return filters;
    }
    afterChangeUsers() {
      if ((!this.filtersSelected.branches || this.filtersSelected.branches && !this.filtersSelected.branches.length) && (!this.filtersSelected.departments || this.filtersSelected.departments && !this.filtersSelected.departments.length) && this.filtersSelected.users.length) {
        this.disableBranchAndDepartment = true;
      } else {
        this.disableBranchAndDepartment = false;
      }
    }
    afterChangeDepartment() {
      if ((!this.filtersSelected.branches || this.filtersSelected.branches && !this.filtersSelected.branches.length) && this.filtersSelected.departments.length) {
        this.disableBranch = true;
      } else {
        this.disableBranch = false;
      }
    }
    clearFilters() {
      this.filtersSelected = {
        branches: [],
        departments: [],
        users: [],
        initialDate: (0, _moment.default)((0, _moment.default)().subtract(30, 'days')).startOf('day').toDate(),
        endDate: (0, _moment.default)().endOf('day').toDate()
      };
      this.filters = {
        ledType: 'd',
        teamManagement: true
      };
      this.ledTypeSelected = 'd';
      this.afterChangeUsers();
      this.afterChangeDepartment();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        initialDate: (0, _moment.default)((0, _moment.default)().subtract(30, 'days')).startOf('day').toDate(),
        endDate: (0, _moment.default)().endOf('day').toDate()
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "disableBranchAndDepartment", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "disableBranch", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "ledTypeSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'd';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "allowIndicatorOrganizationalClimateMyTeam", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "led", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedDepartmentsIds", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "selectedDepartmentsIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedBranchesIds", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBranchesIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportFeelingsToXlsx", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportFeelingsToXlsx"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterChangeUsers", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "afterChangeUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterChangeDepartment", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "afterChangeDepartment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = PortalManagerFeelingController;
});