define("portal-talentrh/pods/group/show/attachment/show/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    fileDownload: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    text: '',
    // Single line computed properties
    attachment: Ember.computed.alias('model'),
    // Multiline computed properties
    userCanEdit: Ember.computed('attachment.createdBy.id', 'attachment.userGroup.user.id', 'session.user', function () {
      let user = this.session.user;
      let createdBy = parseInt(Ember.get(this, 'attachment.createdBy.id'));
      let groupAdmin = parseInt(Ember.get(this, 'attachment.userGroup.user.id'));
      return user.admin || user.id === createdBy || user.id === groupAdmin;
    }),
    // Tasks
    postComment: (0, _emberConcurrency.task)(function* () {
      if (!this.text) {
        return;
      }
      try {
        yield this.ajax.post(`groupAttachments/${this.attachment.id}/comment`, {
          data: {
            text: this.text
          }
        });
        Ember.set(this, 'text', '');
        this.attachment.reload();
        this.toast.success('Comentário publicado');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    removeAttachment: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do arquivo?');
        if (!value) {
          return;
        }
        yield this.attachment.destroyRecord();
        this.toast.success('Arquivo removido');
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      closeModal() {
        this.router.transitionTo('group.show.attachment');
      },
      download() {
        this.ajax.request(`groupAttachments/${this.attachment.id}/signedLink`).then(data => {
          this.fileDownload.downloadFile(data);
        });
      },
      autoGrow() {
        const element = document.getElementById('comment');
        element.style.height = `${element.scrollHeight}px`;
      }
    }
  });
  _exports.default = _default;
});