define("portal-talentrh/pods/forum/topic/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);
      this.controller.set('filters', {
        category: model.id
      });
    },
    actions: {
      reload() {
        this.controller.set('reload', new Date());
      }
    }
  });
  _exports.default = _default;
});