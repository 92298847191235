define("portal-talentrh/models/version", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    module: (0, _attr.default)('number'),
    number: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    notificationSent: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    // Associations
    changesLogs: (0, _relationships.hasMany)('changes-log'),
    companiesRead: (0, _attr.default)('raw'),
    usersRead: (0, _attr.default)('raw')
  });
  _exports.default = _default;
});