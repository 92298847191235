define("portal-talentrh/models/good-transfer-attachment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _attr.default)('date'),
    name: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    goodTransfer: (0, _relationships.belongsTo)('goodTransfer'),
    user: (0, _relationships.belongsTo)('user'),
    // Computed Properties
    formatedCreatedAt: Ember.computed('createdAt', function () {
      let mDate = (0, _moment.default)(this.createdAt);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    })
  });
  _exports.default = _default;
});