define("portal-talentrh/pods/profile/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfileEditController = (_dec = Ember.computed.alias('model'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed('user.validations.isInvalid', 'user.avatarUrl', 'user.accessProfile', 'permission.companyConfig.requireImageProfile'), _dec6 = Ember.computed('user', 'permission.{companyConfig}'), _dec7 = Ember.computed('user.validations.messages'), _dec8 = Ember._action, (_class = class ProfileEditController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "user", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
    }
    get showAlert() {
      return this.user.validations.isInvalid || this.permission.companyConfig.requireImageProfile && !this.user.avatarUrl && this.user.accessProfile.get('changeAvatar');
    }
    get insertImageProfileMessage() {
      let user = this.user;
      const admin = user.get('accessProfile.admin');
      return this.permission.companyConfig.requireImageProfile && !user.avatarUrl && (user.accessProfile.get('changeAvatar') || admin);
    }
    get validationsHaveImageProfileMessage() {
      let user = this.user;
      return user.validations.messages.includes('Insira uma foto ao seu perfil');
    }
    showRequirementModal() {
      if (this.router.currentRouteName == "profile.edit.index.index") {
        if (this.showAlert) {
          if (this.insertImageProfileMessage && !this.validationsHaveImageProfileMessage) {
            this.user.validations.messages.push("Insira uma foto ao seu perfil");
          } else if (!this.insertImageProfileMessage && this.validationsHaveImageProfileMessage) {
            this.user.validations.messages.splice(this.user.validations.messages.indexOf("Insira uma foto ao seu perfil"), 1);
          }
          let userValidations = this.user.validations.messages;
          let obj = {};
          obj.messages = userValidations.filter((item, i) => userValidations.indexOf(item) === i);
          this.swal.validation(obj);
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showAlert", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showAlert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertImageProfileMessage", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "insertImageProfileMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validationsHaveImageProfileMessage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "validationsHaveImageProfileMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showRequirementModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showRequirementModal"), _class.prototype)), _class));
  _exports.default = ProfileEditController;
});