define("portal-talentrh/pods/home/human-resources/goals/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "portal-talentrh/config/environment", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeHumanResourcesGoalsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class HomeHumanResourcesGoalsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "initialDate", _descriptor4, this);
      _initializerDefineProperty(this, "endDate", _descriptor5, this);
      _initializerDefineProperty(this, "periods", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "periodSelected", _descriptor8, this);
      _initializerDefineProperty(this, "closed", _descriptor9, this);
    }
    *loadGoalsPerEmployee() {
      try {
        const goalsPerEmployeePeriods = yield this.fetch.request(`/api/humanResources/getGoalsPerEmployee/periods`, {
          host: _environment.default.apiUrl.indicadores
        });
        this.periods = goalsPerEmployeePeriods;
        if (!this.periodSelected) {
          let goalsPerEmployeePeriod = yield this.fetch.request(`/api/humanResources/getGoalsPerEmployee/periods`, {
            host: _environment.default.apiUrl.indicadores,
            data: {
              ref: (0, _moment.default)().format('YYYY-MM-DD')
            }
          });
          goalsPerEmployeePeriod = goalsPerEmployeePeriod.find(period => period.existsValuesPopulated);
          if (goalsPerEmployeePeriod) {
            this.periodSelected = goalsPerEmployeePeriod;
          } else {
            this.periodSelected = goalsPerEmployeePeriods.firstObject;
          }
        }
        const endAt = (0, _moment.default)(this.periodSelected.endAt);
        const curAt = (0, _moment.default)();
        this.closed = curAt.isAfter(endAt);
        this.buildFilters();
      } catch (e) {
        Ember.debug(`Home :: HumanResources :: GoalsComponent :: loadGoalsPerEmployee`);
      }
    }
    *initGoals() {
      try {
        if (!this.initialDate) {
          this.initialDate = (0, _moment.default)().subtract(5, 'months').startOf('month');
        }
        if (!this.endDate) {
          this.endDate = (0, _moment.default)().endOf('month');
        }
        yield this.loadGoalsPerEmployee.perform();
      } catch (e) {
        Ember.debug(`Home :: HumanResources :: GoalsComponent :: initGoals`);
        return this.swal.catch(e);
      }
    }
    setProgressBarWidth(el) {
      el?.setAttribute('style', 'width: 0');
      if (el.getAttribute('aria-valuenow')) {
        setTimeout(() => {
          el?.setAttribute('style', `width: ${el.getAttribute('aria-valuenow')}%`);
        }, 10);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.periodSelected) {
        filters.initialDate = this.periodSelected.startAt;
        filters.endDate = this.periodSelected.endAt;
      }
      this.filters = filters;
    }
    transitionToGoalDetails() {
      this.router.transitionTo('home.human-resources.goal-details.index', {
        queryParams: {
          periodSelectedStart: this.periodSelected.startAt,
          periodSelectedEnd: this.periodSelected.endAt
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "periods", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "periodSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "closed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadGoalsPerEmployee", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadGoalsPerEmployee"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initGoals", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initGoals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProgressBarWidth", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setProgressBarWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToGoalDetails", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToGoalDetails"), _class.prototype)), _class));
  _exports.default = HomeHumanResourcesGoalsComponent;
});