define("portal-talentrh/pods/external-link/new/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    // Computed
    userLink: Ember.computed.alias('model'),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.shareType = 'users';
      this.usersSelected = [];
      this.groupsSelected = [];
    },
    // Methods
    setUsers(userLink) {
      let users = this.usersSelected;
      let groups = this.groupsSelected;
      userLink.users.clear();
      if (this.shareType === 'users') {
        userLink.users.pushObjects(users);
      } else {
        groups.forEach(group => {
          userLink.users.pushObjects(group.users);
        });
      }
      return userLink;
    },
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          validations
        } = this.userLink;
        if (validations.isInvalid) {
          return this.swal.validation(validations);
        }
        this.userLink.link = this.userLink.link.indexOf('http') != -1 ? this.userLink.link : `https://${this.userLink.link}`;
        let userLink = this.setUsers(this.userLink);
        let created = yield userLink.save();
        this.swal.success('Link criado com sucesso');
        this.transitionToRoute('external-link.edit', created.get('id'));
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      afterSelectGroup(selected) {
        // Popula os usuários dos grupos
        if (selected) {
          selected.forEach(group => group.reload());
        }
      },
      closeModal() {
        this.transitionToRoute('external-link');
      }
    }
  });
  _exports.default = _default;
});