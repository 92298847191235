define("portal-talentrh/pods/components/social-page/menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['col-lg-3 col-sm-4'],
    didInsertElement() {
      this._super(...arguments);
      let elementMenu = this.element.querySelector('.social-page__menu__list');
      this.fixSidebarOnScroll = () => {
        let {
          scrollTop
        } = document.documentElement;
        if (scrollTop > this.positionToFixMenu) {
          elementMenu.classList.add('sticky-navbar');
        } else {
          elementMenu.classList.remove('sticky-navbar');
        }
      };
      document.addEventListener('scroll', this.fixSidebarOnScroll);
    },
    didRender() {
      let lastElement = this.element.lastElementChild;
      this.positionToFixMenu = lastElement.offsetTop + lastElement.offsetHeight + 50;
    },
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener('scroll', this.fixSidebarOnScroll);
    }
  });
  _exports.default = _default;
});