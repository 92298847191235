define("portal-talentrh/pods/components/input-mention/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b+xLgCml",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"input-mention__stylist\"],[12],[1,[30,[36,7],[[35,6]],null]],[13],[2,\"\\n\\n\"],[18,3,null],[2,\"\\n\\n\"],[6,[37,0],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,2],[[32,0,[\"group\",\"id\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-center border\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-warning font-weight-bold\"],[12],[2,\"Listando somente usuários do grupo \"],[1,[32,0,[\"group\",\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"ul\"],[14,0,\"input-mention__list list-unstyled\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[15,0,[30,[36,0],[[32,1,[\"selected\"]],\"selected\"],null]],[15,\"onclick\",[30,[36,1],[[32,0],\"selectUser\",[32,1]],null]],[14,\"role\",\"button\"],[12],[2,\"\\n        \"],[8,\"ui-avatar\",[],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,2,[\"Img\"]],[[24,0,\"input-mention__list-avatar\"],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n        \"],[1,[32,1,[\"fullName\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"gte\",\"usersToSelect\",\"-track-array\",\"each\",\"mentionTextStylized\",\"html\",\"creatingMention\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/input-mention/template.hbs"
    }
  });
  _exports.default = _default;
});