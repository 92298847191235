define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-profile/average-salary-by-department/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsHumanResourcesCompanyProfileAverageSalaryByDepartmentRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {};
      this.controller.chartFilter = null;
      this.controller.colors = null;
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyProfileAverageSalaryByDepartmentRoute;
});