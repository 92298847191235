define("portal-talentrh/pods/components/indicator-viewer/team-profile/age-range/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u7BZyT2l",
    "block": "{\"symbols\":[\"Card\",\"@data\",\"@indicator\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm\"],[4,[38,1],[[30,[36,0],[[32,0],\"generate\"],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Faixa Etária\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"indicator-block-h-415\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"pb-3 mb-4 text-center border-bottom\"],[12],[2,\"\\n      \"],[10,\"h6\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fas fa-user-chart\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n        Faixa Etária Predominante\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"h5\"],[14,0,\"mb-2 text-primary\"],[12],[2,\"\\n        \"],[1,[32,2,[\"highOccurrence\",\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-lg-6 col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"indicator-pie-chart\"],[12],[2,\"\\n          \"],[10,\"canvas\"],[14,1,\"chart-age-range\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-lg-6 col-md-12\"],[12],[2,\"\\n        \"],[10,\"ul\"],[14,1,\"chart-age-range-legend\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,3,[\"loaded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner position-absolute centralize\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"unless\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/team-profile/age-range/template.hbs"
    }
  });
  _exports.default = _default;
});