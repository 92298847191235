define("portal-talentrh/pods/components/widgets/latest-announcements-home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tgpocKL6",
    "block": "{\"symbols\":[\"Card\",\"announcement\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3 overflow-hidden width-widget\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"text-center font-weight-bold text-secondary border-0 pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"post.announcement\"]],[[\"default\"],[{\"statements\":[[2,\"Últimos comunicados\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"p-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"skeleton/announcement\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[14,0,\"list-group-item text-truncate\"],[12],[2,\"\\n\\n            \"],[8,\"link-to\",[[24,0,\"font-weight-bold\"]],[[\"@route\",\"@model\"],[\"post.show\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n              \"],[1,[32,2,[\"title\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[10,\"small\"],[14,0,\"d-block\"],[12],[2,\"\\n              publicado \"],[1,[30,[36,0],[[32,2,[\"createdAt\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]},{\"statements\":[[2,\"          \"],[10,\"li\"],[14,0,\"list-group py-4 text-center\"],[12],[2,\"Não há comunicados\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-from-now\",\"announcements\",\"-track-array\",\"each\",\"loadWidget\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/widgets/latest-announcements-home/template.hbs"
    }
  });
  _exports.default = _default;
});