define("portal-talentrh/pods/application/navbar/feeling/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bV/etjRl",
    "block": "{\"symbols\":[\"@enablePopover\"],\"statements\":[[10,\"div\"],[14,1,\"feeling-icon-parent_nela-3556\"],[15,\"onclick\",[30,[36,0],[[32,0],\"onIconClick\"],null]],[12],[2,\"\\n  \"],[10,\"img\"],[14,1,\"feeling-icon_nela-3556\"],[14,0,\"avatar-3\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Ficons%2Femoji-feeling-question.svg\"],[14,\"alt\",\"Feeling\"],[12],[13],[2,\"\\n\"],[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,1,\"feeling-popover\"],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"createPopover\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0],[32,0,[\"createPopover\"]]],null]],null],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/feeling/icon/template.hbs"
    }
  });
  _exports.default = _default;
});