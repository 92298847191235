define("portal-talentrh/pods/application/navbar/search/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICONS = {
    ad: 'fa-bullhorn climate-color',
    admissionAnniversary: 'fa-birthday-cake nela-color',
    announcement: 'fa-file nela-color',
    birthday: 'fa-birthday-cake training-color',
    event: 'fa-ticket training-color',
    link: 'fa-link',
    message: 'fa-envelope schedule-color',
    news: 'fa-newspaper accounts-color',
    survey: 'fa-file schedule-color'
  };
  const COLORS = {
    ad: '#519691',
    admissionAnniversary: '#0078D7',
    announcement: '#0078D7',
    birthday: '#DC6478',
    event: '#DC6478',
    message: '#6F74A1',
    news: '#2CB07D',
    survey: '#6F74A1'
  };
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex', 'align-items-center', 'py-3'],
    icon: Ember.computed('result.type', function () {
      let icon = ICONS[this.result.type];
      let color = COLORS[this.result.type];
      return Ember.String.htmlSafe(`<i class="fa fa-2x ${icon} text-center" style="color:${color};min-width:40px;"></i>`);
    })
  });
  _exports.default = _default;
});