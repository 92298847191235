define("portal-talentrh/pods/components/post-comments/comment/like-bar/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['comment__options', 'list-unstyled'],
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed methods
    likesLabel: Ember.computed('comment.usersLikedId.length', function () {
      let count = Ember.get(this, 'comment.usersLikedId.length');
      return count + (count > 1 ? ' curtiram' : ' curtiu');
    }),
    commentsLabel: Ember.computed('comment.responsesCount', function () {
      let count = Ember.get(this, 'comment.responsesCount');
      return count + (count > 1 ? ' respostas' : ' resposta');
    }),
    isLiked: Ember.computed('comment.{isFulfilled,usersLikedId.length}', 'session.user.id', function () {
      const userId = this.session.user.id;
      if (Ember.get(this, 'comment.usersLikedId')) {
        Ember.debug('userId: ', userId, 'get(this, comment.usersLikedId)', Ember.get(this, 'comment.usersLikedId'), 'includes -> ', Ember.get(this, 'comment.usersLikedId').includes(userId));
      }
      return Ember.get(this, 'comment.usersLikedId') && Ember.get(this, 'comment.usersLikedId').includes(userId);
    }),
    // Concurrency methods
    likeComment: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.ajax.post(`comments/${this.comment.id}/like`);
        this.store.pushPayload(result);
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    // Actions
    actions: {
      showRepliesPanel() {
        this.set('showReplies', true);
        this.set('showReplyField', true);
      },
      openReplyField() {
        let commentId = Ember.get(this, 'comment.responseTo') || Ember.get(this, 'comment.id');
        let input = document.getElementById('input-reply' + commentId);
        this.set('showReplyField', true);
        if (input) {
          Ember.run.later(() => {
            input.focus();
          });
        }
      },
      viewCommentLikes(comment) {
        if (this.viewCommentLikes) {
          this.viewCommentLikes(comment);
        }
      }
    }
  });
  _exports.default = _default;
});