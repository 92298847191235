define("portal-talentrh/pods/components/input-summernote/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Pla5IiSD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,1,[31,[[34,0],\"-summernote\"]]],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"elementId\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/input-summernote/template.hbs"
    }
  });
  _exports.default = _default;
});