define("portal-talentrh/services/contact-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    isListLoaded: false,
    users: Ember.A([]),
    filterUsers() {
      let filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      let shouldReturnSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const regExp = new RegExp(filter.trim(), 'i');
      return this.users.filter(item => {
        return item.active && (item.name && item.name.match(regExp) || item.email && item.email.match(regExp) || item.jobFunction && item.jobFunction.match(regExp) || item.department && item.department.match(regExp) || item.professionalPhone && item.professionalPhone.match(regExp)) && (item.id !== this.get('session.user.id') || shouldReturnSelf);
      });
    },
    birthDayUsers() {
      let variant = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'today';
      return this.users.filter(user => {
        return (variant === 'month' ? user.birthdayThisMonth : user.birthdayToday) && user.active;
      });
    },
    getSelf() {
      return this.users.findBy('id', parseInt(this.get('session.user.id')));
    },
    _updateObject(current, updated) {
      var key,
        keys = Object.keys(updated);
      var n = keys.length;
      while (n--) {
        key = keys[n];
        Ember.set(current, key, updated[key]);
      }
    }
  });
  _exports.default = _default;
});