define("portal-talentrh/pods/company/album/index/new-album/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        let newAlbum = this.model;
        yield newAlbum.save();
        this.send('closeModal');
        this.send('refreshModel');
        this.swal.success('Álbum criado com Sucesso');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      closeModal() {
        this.transitionToRoute('company.album.index');
      }
    }
  });
  _exports.default = _default;
});