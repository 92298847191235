define("portal-talentrh/pods/components/user-attribute/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GdigdYlE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,2],[35,1],[35,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hasError\",\"isLoaded\",\"user\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-attribute/template.hbs"
    }
  });
  _exports.default = _default;
});