define("portal-talentrh/services/welcome", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let WelcomeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember.computed.alias('permission.admin'), _dec10 = Ember.computed.alias('permission.companyConfig'), _dec11 = Ember.computed.alias('permission.accessProfile'), (_class = class WelcomeService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intro", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "ajax", _descriptor4, this);
      _initializerDefineProperty(this, "habitus", _descriptor5, this);
      _initializerDefineProperty(this, "session", _descriptor6, this);
      _initializerDefineProperty(this, "swal", _descriptor7, this);
      _initializerDefineProperty(this, "showWelcomeVideo", _descriptor8, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor9, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor10, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor11, this);
    }
    /* Verifica se as etapas que o usuário tem que visualizar após entrar no sistema pela primeira vez
      foram abertas e caso necessário, abre as etapas em sequência. */
    steps() {
      try {
        let steps = ['checkEmailOrCellphoneValidated', 'userValidations', 'policiesAccepted', 'leaderModal'];
        steps.find(step => {
          let isComplete = null;
          isComplete = this[step]();
          return !isComplete;
        });
      } catch (error) {
        console.log(error);
      }
    }

    // Verifica se o usuário está com seus dados pessoais obrigatórios
    userValidations() {
      const {
        validations,
        cellphone,
        personalEmail,
        avatarUrl
      } = this.permission.user;
      const hasRecoveryPasswordInfo = cellphone || personalEmail;
      const requiredChangeAvatar = this.companyConfig.requireImageProfile && this.accessProfile.changeAvatar && !avatarUrl;
      const isValid = !validations.isInvalid && hasRecoveryPasswordInfo && !requiredChangeAvatar;
      if (isValid) {
        return isValid;
      }
      this.router.transitionTo('profile.edit.index');
    }

    // Verifica se vai abrir o modal de configuração de líder
    leaderModal() {
      let social = this.permission.userPreference.social || {};
      let dismissModal = social.dismissLeadershipConfig || this.permission.user.get('appraisedBy.id') || this.permission.admin;
      if (!dismissModal) {
        this.router.transitionTo('feed.index.setup-account.leader');
      }
      return dismissModal;
    }
    habitusIntro() {
      const isLeader = this.permission.user.isLeader;
      if (this.isAdmin || isLeader) {
        this.checkHabitusStart(true);
      }
    }

    // Verifica se aceitou todas as politicas
    policiesAccepted() {
      if (!this.permission.policiesAccepted) {
        this.router.transitionTo('company.policy', {
          queryParams: {
            notAcceptedPolicies: true
          }
        });
      }
      return this.permission.policiesAccepted;
    }

    // Verifica se vai iniciar o habitus para lideranças, para colaborador normal nem inicia o habitus.
    checkHabitusStart(start) {
      const isLeader = this.permission.user.isLeader;
      if (isLeader || this.isAdmin) {
        if (start) {
          this.startHabitus();
        }
        return true;
      } else {
        this.session.set('data.closeHabitusInsight', (0, _moment.default)().format('YYYY-MM-DD'));
      }
      return false;
    }
    startHabitus() {
      this.habitus.start.perform();
    }

    // Verifica se o usuario já confirmar o email e senha
    checkEmailOrCellphoneValidated() {
      const userAccount = this.permission.userAccount;
      const invalid = !userAccount || !userAccount.validatedEmail && !userAccount.validatedCellphone;
      if (invalid) {
        this.router.transitionTo('profile.edit.index.change-recover-password-info');
      }
      if (userAccount.isPasswordExpired) {
        this.swal.warning('Sua senha expirou, por favor, crie uma nova senha.').then(() => {
          this.router.transitionTo('profile.edit.index.change-password');
        });
      }
      return !invalid;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intro", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showWelcomeVideo", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WelcomeService;
});