define("portal-talentrh/pods/feed/me/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RBcYvjTU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n      \"],[8,\"feed/me/index/welcome\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"accessAVD\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/goals\",[[24,\"data-step\",\"feed-me-index-3\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/role-function\",[[24,\"data-step\",\"feed-me-index-4\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/pdis\",[[24,\"data-step\",\"feed-me-index-5\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"accessProjects\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/tasks\",[[24,\"data-step\",\"feed-me-index-6\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"accessAVD\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/activities\",[[24,\"data-step\",\"feed-me-index-7\"]],[[\"@title\",\"@developmentPlans\"],[\"Minhas avaliações e feedbacks\",false]],null],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n        \"],[8,\"feed/me/index/activities\",[[24,\"data-step\",\"feed-me-index-8\"]],[[\"@title\",\"@developmentPlans\"],[\"Meus PDE's\",true]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/template.hbs"
    }
  });
  _exports.default = _default;
});