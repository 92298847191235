define("portal-talentrh/pods/habitus/details/feedback/amount-of-feedbacks-by-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TgZMRLsH",
    "block": "{\"symbols\":[\"Card\",\"@feedbacks\"],\"statements\":[[8,\"ui-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n   \"],[10,\"b\"],[12],[2,\"Total de feedbacks por tipo\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"canvas\"],[24,1,\"amount-of-feedbacks-by-type\"],[4,[38,2],[[30,[36,1],[[32,0],[35,0]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"text-center py-5\"],[12],[2,\"\\n        \"],[10,\"i\"],[12],[2,\" Nenhum feedback encontrado\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"init\",\"action\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/details/feedback/amount-of-feedbacks-by-type/template.hbs"
    }
  });
  _exports.default = _default;
});