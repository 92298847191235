define("portal-talentrh/pods/dashboard/layout/complete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iPbRUZ4o",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[8,\"dashboard/message-board\",[],[[\"@class\"],[\"col-md-4\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12 dashboard-scene dashboard-scene-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row board-height-large\"],[12],[2,\"\\n        \"],[8,\"dashboard/recent-users\",[[24,0,\"col-md-6 board-height-short\"]],[[],[]],null],[2,\"\\n        \"],[8,\"dashboard/announcements-block\",[[24,0,\"col-md-6 board-height-short\"]],[[],[]],null],[2,\"\\n        \"],[8,\"dashboard/births-block\",[[24,0,\"col-md-6 board-block-bot board-height-short\"]],[[\"@configuration\"],[[34,0]]],null],[2,\"\\n        \"],[8,\"dashboard/admission-births\",[[24,0,\"col-md-6 board-block-bot board-height-short\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"dashboard/album-block\",[[24,0,\"col-md-12 dashboard-scene dashboard-scene-2 invisible\"]],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"companyConfiguration\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/dashboard/layout/complete/template.hbs"
    }
  });
  _exports.default = _default;
});