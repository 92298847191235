define("portal-talentrh/pods/portal-manager/compensatory-time/total-card/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let TotalCardComponent = (_dec = Ember.computed('args.totals'), (_class = class TotalCardComponent extends _component.default {
    get textColorClassName() {
      const {
        totals
      } = this.args;
      const positive = 'text-primary';
      const negative = 'text-danger';
      let total = '';
      if (totals.total.charAt(0) === '+') {
        total = positive;
      } else if (totals.total.charAt(0) === '-') {
        total = negative;
      }
      return {
        positive,
        negative,
        total
      };
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "textColorClassName", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "textColorClassName"), _class.prototype)), _class));
  _exports.default = TotalCardComponent;
});