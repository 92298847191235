define("portal-talentrh/pods/indicators/people-analytics/my-team/company-engagement/ranking-influencers-detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsMyTeamCompanyEngagementRankingInfluencersDetailRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.rankingInfluencerData = Ember.A();
      this.controller.rankingInfluencersPostDetailsData = Ember.A();
      this.controller.filters = null;
      this.controller.modalPostsDetails = false;
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsMyTeamCompanyEngagementRankingInfluencersDetailRoute;
});