define("portal-talentrh/models/meeting-attachment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    name: (0, _attr.default)('string'),
    path: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    meetingMinute: (0, _relationships.belongsTo)('meeting-minute'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});