define("portal-talentrh/pods/absence-board/board/item/component", ["exports", "ember-concurrency", "moment", "portal-talentrh/utils/formatDate"], function (_exports, _emberConcurrency, _moment, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    classNames: ['col-lg-3', 'col-md-4', 'position-relative'],
    // Services
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // Default values
    showDetails: false,
    // Multi line computed properties
    startDateComputed: Ember.computed('absence.startDate', function () {
      if (this.absence.startDate) {
        const timezone = new Date().getTimezoneOffset() / 60;
        let date = (0, _moment.default)(this.absence.startDate).add(3, 'h');
        date = date.subtract(timezone, 'h');
        return (0, _formatDate.default)(date);
      } else {
        return "Não informado";
      }
    }),
    userId: Ember.computed('absence.user.id', function () {
      return this.absence.user.id;
    }),
    endDateComputed: Ember.computed('absence.endDate', function () {
      if (this.absence.endDate) {
        const timezone = new Date().getTimezoneOffset() / 60;
        let date = (0, _moment.default)(this.absence.endDate).add(3, 'h');
        date = date.subtract(timezone, 'h');
        return (0, _formatDate.default)(date);
      } else {
        return "Não informado";
      }
    }),
    permitEdit: Ember.computed('absence.user.id', 'permission.{accessProfile.permitUserMessageCreate,admin}', 'session.user.id', function () {
      let user = parseInt(this.absence.user.id);
      return user === this.session.user.id || this.permission.accessProfile.permitUserMessageCreate || this.permission.admin;
    }),
    finish: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da ausência?', {
          text: 'Esta ação não pode ser desfeita'
        });
        if (!value) {
          return;
        }
        yield this.ajax.put(`/userMessages/${this.absence.id}/active/false`);
        this.toast.success('Ausência excluída');
        this.onChangeFilter();
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});