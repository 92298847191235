define("portal-talentrh/pods/profile/show/team/leds/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YTd7NvTB",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"record\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@show\",\"@size\",\"@onClose\"],[true,\"lg\",[30,[36,0],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Adicionar liderados\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Liderados\"],[13],[2,\"\\n      \"],[8,\"nela-select-multiple\",[],[[\"@placeholder\",\"@selected\",\"@modelName\",\"@endpoint\",\"@query\"],[\"Selecionar liderados\",[32,0,[\"leds\"]],\"user\",\"/users/usersWithoutTeam\",[30,[36,1],null,[[\"user\"],[[32,0,[\"leader\",\"id\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,3,[\"fullName\"]]],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,2],[[32,0,[\"save\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"hash\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/show/team/leds/template.hbs"
    }
  });
  _exports.default = _default;
});