define("portal-talentrh/pods/feed/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    intro: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    welcome: Ember.inject.service(),
    fetch: Ember.inject.service(),
    activate() {
      this._super(...arguments);
      window.scrollTo(0, 0);
    },
    actions: {
      refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});