define("portal-talentrh/pods/company/benefits/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aDp7VVgy",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@size\",\"@onClose\"],[true,\"lg\",[30,[36,1],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"benefit\",\"title\"]]]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"benefit\",\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,0,[\"benefit\",\"description\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[12],[2,\"Não informado\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/benefits/details/template.hbs"
    }
  });
  _exports.default = _default;
});