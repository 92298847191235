define("portal-talentrh/pods/view-team-member/historical-data/evolution-salary-chart/component", ["exports", "@glimmer/component", "chart-js", "moment"], function (_exports, _component, _chartJs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EvolutionSalaryChartComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.user.userSalary'), _dec3 = Ember.computed.alias('args.user.generic'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class EvolutionSalaryChartComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "userSalary", _descriptor2, this);
      _initializerDefineProperty(this, "generic", _descriptor3, this);
      _initializerDefineProperty(this, "chart", _descriptor4, this);
      _initializerDefineProperty(this, "createdAt", _descriptor5, this);
      _initializerDefineProperty(this, "dateNow", _descriptor6, this);
      _initializerDefineProperty(this, "endAt", _descriptor7, this);
      _initializerDefineProperty(this, "readjustPercentage", _descriptor8, this);
      _initializerDefineProperty(this, "readjustReason", _descriptor9, this);
      _initializerDefineProperty(this, "salaryAchieveDate", _descriptor10, this);
      _initializerDefineProperty(this, "salaryAchieveDateAux", _descriptor11, this);
      _initializerDefineProperty(this, "salaryAchieveValue", _descriptor12, this);
      _initializerDefineProperty(this, "salaryValue", _descriptor13, this);
      _initializerDefineProperty(this, "salaryValueAux", _descriptor14, this);
      _initializerDefineProperty(this, "salaryUnit", _descriptor15, this);
      _initializerDefineProperty(this, "userSalaryAux", _descriptor16, this);
    }
    _formatDate(date) {
      const dateToFormat = new Date(date);
      switch (dateToFormat.getMonth()) {
        case 0:
          return 'Jan/' + dateToFormat.getFullYear();
        case 1:
          return 'Fev/' + dateToFormat.getFullYear();
        case 2:
          return 'Mar/' + dateToFormat.getFullYear();
        case 3:
          return 'Abr/' + dateToFormat.getFullYear();
        case 4:
          return 'Mai/' + dateToFormat.getFullYear();
        case 5:
          return 'Jun/' + dateToFormat.getFullYear();
        case 6:
          return 'Jul/' + dateToFormat.getFullYear();
        case 7:
          return 'Ago/' + dateToFormat.getFullYear();
        case 8:
          return 'Set/' + dateToFormat.getFullYear();
        case 9:
          return 'Out/' + dateToFormat.getFullYear();
        case 10:
          return 'Nov/' + dateToFormat.getFullYear();
        case 11:
          return 'Dez/' + dateToFormat.getFullYear();
      }
    }
    _buildChart() {
      let previousValue = this.salaryValue;
      let firstTime = true;
      let readjustPercentage = this.readjustPercentage;
      let readjustReason = this.readjustReason;
      let salaryAchieveDateAux = this.salaryAchieveDateAux;
      const data = {
        labels: this.salaryAchieveDate,
        datasets: [{
          label: 'Evolução salarial',
          data: this.salaryAchieveValue,
          backgroundColor: 'rgba(203,231,251,0.86)',
          borderColor: 'rgb(32,129,206)',
          fill: true
        }]
      };
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = document.getElementById('chart').getContext('2d');
      this.chart = new _chartJs.default(ctx, {
        type: 'line',
        responsive: true,
        data: data,
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: this.salaryValue ? this.salaryValue : 10,
                callback: function (value) {
                  if (previousValue) {
                    if (value < previousValue && value <= previousValue - 10 || firstTime) {
                      let str = Intl.NumberFormat('pt-br', {
                        currency: 'BRL',
                        style: 'currency',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }).format(value);
                      firstTime = false;
                      previousValue = value;
                      return str;
                    }
                  } else {
                    return value;
                  }
                }
              }
            }]
          },
          tooltips: {
            custom: function (tooltip) {
              if (!tooltip) return;
              tooltip.displayColors = false;
            },
            callbacks: {
              title: function () {},
              label: function (tooltipItem) {
                let str = Intl.NumberFormat('pt-br', {
                  currency: 'BRL',
                  style: 'currency',
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                }).format(tooltipItem.yLabel);
                let valueStr = ['Data: ' + salaryAchieveDateAux[tooltipItem.index], 'Motivo: ' + readjustReason[tooltipItem.index], 'Valor: ' + str, '% de Reajuste: ' + readjustPercentage[tooltipItem.index]];
                return valueStr;
              }
            }
          }
        }
      });
    }
    _buildData(userSalaryParam) {
      this.salaryAchieveDate = [];
      this.salaryAchieveValue = [];
      this.readjustReason = [];
      this.readjustPercentage = [];
      this.salaryAchieveDateAux = [];
      let salaryMaxValue = 0;
      let formattedDateAux = null;
      if (this.userSalary && this.userSalary.length) {
        let userSalaryArrayOrder = Object.assign([], this.userSalary).reverse();
        if (this.generic) {
          userSalaryArrayOrder = [];
          userSalaryArrayOrder = Object.assign([], this.userSalary);
        }
        const userSalaryArr = userSalaryParam ? userSalaryParam : userSalaryArrayOrder;
        for (const salary of userSalaryArr) {
          if (this.salaryValueAux > 0) {
            let readjustment = (salary.salary / this.salaryValueAux - 1) * 100;
            if (readjustment < 0) {
              this.readjustPercentage.push(0.0);
            } else {
              this.readjustPercentage.push(readjustment.toFixed(2));
            }
          } else {
            this.readjustPercentage.push(0.0);
          }
          let formattedDate = this._formatDate(salary.date);
          this.salaryAchieveDateAux.push((0, _moment.default)(salary.date).format('DD/MM/YYYY'));
          if (formattedDateAux && formattedDate === formattedDateAux) {
            this.salaryAchieveDate.push('');
          } else {
            this.salaryAchieveDate.push(formattedDate);
          }
          formattedDateAux = formattedDate;
          this.salaryAchieveValue.push(salary.salary);
          this.readjustReason.push(salary.readjustReason && salary.readjustReason.description ? salary.readjustReason.description : 'Motivo não cadastrado');
          if (salary.salary > salaryMaxValue) {
            salaryMaxValue = salary.salary;
          }
          this.salaryValueAux = salary.salary;
        }
        this.salaryValue = salaryMaxValue;
        this._buildChart();
      } else {
        this._buildChart();
      }
    }
    getFilters() {
      if (this.createdAt && this.endAt) {
        if ((0, _moment.default)(this.createdAt).isAfter(this.endAt)) {
          return this.swal.warning('A data final não pode ser anterior a data inicial');
        }
        this.userSalaryAux = [];
        let userSalaryArrayOrder = Object.assign([], this.userSalary).reverse();
        for (const salary of userSalaryArrayOrder) {
          const salaryCreatedAt = new Date(salary.date);
          if (this.createdAt.getTime() <= salaryCreatedAt.getTime() && this.endAt.getTime() >= salaryCreatedAt.getTime()) {
            this.userSalaryAux.push(salary);
          }
        }
        if (this.userSalaryAux) {
          this._buildData(this.userSalaryAux);
        }
      } else {
        this.userSalaryAux = [];
        this._buildData();
      }
    }
    initEvolutionSalaryChart() {
      this.salaryAchieveValue = [];
      this.salaryAchieveDate = [];
      if (!this.userSalary && this.generic) {
        this.userSalary = [];
        for (const salary of this.generic) {
          this.userSalary.push({
            date: salary.DATA + ' 23:59:59',
            salary: salary.VLRSALARIO,
            readjustReason: {
              description: salary.DESCMOTREAJU
            }
          });
        }
      }
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userSalary", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "generic", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date(new Date().setFullYear(new Date().getFullYear() - 5));
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dateNow", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "endAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "readjustPercentage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "readjustReason", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "salaryAchieveDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "salaryAchieveDateAux", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "salaryAchieveValue", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "salaryValue", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "salaryValueAux", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "salaryUnit", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "userSalaryAux", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initEvolutionSalaryChart", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "initEvolutionSalaryChart"), _class.prototype)), _class));
  _exports.default = EvolutionSalaryChartComponent;
});