define("portal-talentrh/pods/profile/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfileShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.or('permission.accessProfile.allowSendMessageIndividualChat', 'permission.admin'), _dec7 = Ember.computed.or('user.accessProfile.allowSendMessageIndividualChat', 'user.accessProfile.admin'), _dec8 = Ember.computed.alias('model'), _dec9 = Ember.computed.alias('permission.toAccessProjectManager'), _dec10 = Ember.computed.alias('permission.accessProfile'), _dec11 = Ember.computed.alias('permission.companyConfig'), _dec12 = Ember.computed.alias('permission.user'), _dec13 = Ember.computed('companyConfig.showJobFunctionCard', 'permission.toAccessManagement', 'user.appraisedBy', 'userLogged.id'), _dec14 = Ember.computed('companyConfig.showJobFunction', 'permission.admin', 'user.jobFunction.title', 'user.jobRole.title'), _dec15 = Ember._action, (_class = class ProfileShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "voorsChat", _descriptor5, this);
      _initializerDefineProperty(this, "iCanSendMessageIndividualChat", _descriptor6, this);
      _initializerDefineProperty(this, "heSendMessageIndividualChat", _descriptor7, this);
      _initializerDefineProperty(this, "user", _descriptor8, this);
      _initializerDefineProperty(this, "accessProjects", _descriptor9, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor10, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor11, this);
      _initializerDefineProperty(this, "userLogged", _descriptor12, this);
    }
    get isMyProfile() {
      let userId = String(this.session.user.id);
      return this.user.id === userId;
    }
    get validateJobRoleOrFunction() {
      let isSubordinate = Number(this.user.appraisedBy) === Number(this.userLogged.id);
      return this.companyConfig.showJobFunctionCard || this.permission.toAccessManagement || isSubordinate;
    }
    get jobTitle() {
      if (this.companyConfig.showJobFunction) {
        if (!this.permission.admin) {
          if (this.user.jobFunction.get('visible')) {
            return this.user.jobFunction.get('title') ? this.user.jobFunction.get('title') : 'Não informado';
          }
          return 'Não informado';
        }
        return this.user.jobFunction.get('title') ? this.user.jobFunction.get('title') : 'Não informado';
      } else {
        if (!this.permission.admin) {
          if (this.user.jobRole.get('visible')) {
            return this.user.jobRole.get('title') ? this.user.jobRole.get('title') : 'Não informado';
          }
          return 'Não informado';
        }
        return this.user.jobRole.get('title') ? this.user.jobRole.get('title') : 'Não informado';
      }
    }
    openChat() {
      this.voorsChat.openNewChat({
        id: this.user.id,
        isGroup: false
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "voorsChat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "iCanSendMessageIndividualChat", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "heSendMessageIndividualChat", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "accessProjects", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "userLogged", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validateJobRoleOrFunction", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "validateJobRoleOrFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobTitle", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "jobTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openChat", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "openChat"), _class.prototype)), _class));
  _exports.default = ProfileShowController;
});