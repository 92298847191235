define("portal-talentrh/pods/components/post-images-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    //Default values
    maxImages: 150,
    // Life cycle hooks
    init() {
      this._super(...arguments);
      if (this.maxQuantity) {
        Ember.set(this, 'maxImages', this.maxQuantity);
      }
    },
    // Custom methods
    renderPreview(element) {
      const files = [...element.files];

      // feito isso pois se o valor do elemento for igual ao novo inserido
      // ele não deixa colocar a imagem.
      element.value = null;
      let postImagesNextIndex = this.postImages.length;
      if (postImagesNextIndex + files.length > this.maxQuantity) {
        this.swal.warning(`Apenas ${this.maxQuantity} imagens podem ser salvas`);
        return;
      }
      for (var i = 0; i < files.length; i++) {
        let file = files[i];
        let oFReader = new FileReader();
        let types = ['gif', 'jpg', 'jpeg', 'png'];
        let typeImg = file.name.split('.').pop().toLowerCase();
        if (types.includes(typeImg)) {
          oFReader.readAsDataURL(files[i]);
          oFReader.onload = oFREvent => {
            var img = new Image();
            img.src = oFREvent.target.result;
            img.onload = () => {
              let runtimeIndex = -1;
              // Procura em qual index estava o file no FileList files
              for (var j = 0; j < files.length; j++) {
                if (files[j] === file) {
                  runtimeIndex = j;
                }
              }
              this.postImages[runtimeIndex + postImagesNextIndex] = {
                preview: oFREvent.target.result,
                form: file
              };

              // Avisa ember que o array foi alterado
              this.postImages.arrayContentDidChange(runtimeIndex + postImagesNextIndex, 0, 1);
            };
          };
        } else {
          this.swal.warning('Apenas imagens PNG, JPG/JPEG e GIF podem ser salvas');
        }
      }
    },
    actions: {
      click(item) {
        document.getElementById(item).click();
      },
      selectImage() {
        const element = document.querySelector(`#${this.elementId} input`);
        this.renderPreview(element);
      },
      deleteImage(index) {
        const postImages = this.postImages;
        postImages.removeAt(index);
      }
    }
  });
  _exports.default = _default;
});