define("portal-talentrh/validators/cpf", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INVALID_CPF = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999'];
  const Cpf = _base.default.extend({
    validate() {
      let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let message = options.message || 'CPF inválido';
      if (typeof value === 'number') {
        value = Number(value).toString();
      }
      value = value ? value.replace(/[^\d]+/g, '') : '';
      if (value.length !== 11 || INVALID_CPF.includes(value)) {
        return message;
      }

      // Primeiro dígito de validação
      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(value.charAt(i)) * (10 - i);
      }
      let rev = 11 - add % 11;
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(value.charAt(9))) {
        return message;
      }

      // Segundo dígito de validação
      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(value.charAt(i)) * (11 - i);
      }
      rev = 11 - add % 11;
      if (rev === 10 || rev === 11) {
        rev = 0;
      }
      if (rev !== parseInt(value.charAt(10))) {
        return message;
      }
      return true;
    }
  });
  Cpf.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor( /* attribute, options */
    ) {
      return [];
    }
  });
  var _default = Cpf;
  _exports.default = _default;
});