define("portal-talentrh/pods/components/post-attachment/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['nela-post-attachment', 'card', 'bg-light', 'border', 'rounded', 'mb-3'],
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    delete: (0, _emberConcurrency.task)(function* () {
      let result = yield this.swal.confirm('Excluir anexo?', 'Esta ação não pode ser desfeita');
      if (!result.value) {
        return;
      }
      try {
        let file = this.attachment;
        this.attachments.removeObject(file);
        if (Ember.get(file, 'id')) {
          file.destroyRecord();
        }
        this.toast.success('Anexo excluído com sucesso.');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    download: (0, _emberConcurrency.task)(function* () {
      let id = Ember.get(this, 'attachment.id');
      if (!id) {
        return;
      }
      let result = yield this.ajax.request('s3/signedGetUrl', {
        data: {
          modelId: id,
          model: 'postAttachment'
        }
      });
      window.open(result.url, '_blank');
    })
  });
  _exports.default = _default;
});