define("portal-talentrh/pods/home/my-team/time-card-details/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeMyTeamTimeCardDetailsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class HomeMyTeamTimeCardDetailsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "filterValue", _descriptor5, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor6, this);
      _initializerDefineProperty(this, "ledType", _descriptor7, this);
      _initializerDefineProperty(this, "statusSelected", _descriptor8, this);
      _initializerDefineProperty(this, "ledTypeOptions", _descriptor9, this);
      _defineProperty(this, "statusOptions", [{
        value: ['PENDING_HR', 'PENDING_LEADER', 'APPROVED', 'REPROVED'],
        label: 'Todas'
      }, {
        value: ['PENDING_LEADER'],
        label: 'Ação pendente'
      }, {
        value: ['PENDING_HR'],
        label: 'Ação realizada'
      }, {
        value: ['APPROVED'],
        label: 'Ação aprovada'
      }, {
        value: ['REPROVED'],
        label: 'Ação reprovada'
      }]);
      _initializerDefineProperty(this, "competences", _descriptor10, this);
      _defineProperty(this, "exportFields", [{
        field: 'user.fullName',
        fieldName: 'Colaborador',
        type: 'object'
      }, {
        field: 'user.leader.fullName',
        fieldName: 'Líder imediato',
        type: 'object'
      }, {
        field: 'user.departmentName',
        fieldName: 'Departamento',
        type: 'object'
      }, {
        field: 'user.jobRoleName',
        fieldName: 'Cargo',
        type: 'object'
      }, {
        field: 'user.jobFunctionName',
        fieldName: 'Função',
        type: 'object'
      }, {
        field: 'date',
        fieldName: 'Data'
      }, {
        field: 'horary',
        fieldName: 'Horário'
      }, {
        field: 'observation',
        fieldName: 'Observação'
      }, {
        field: 'translatedStatus',
        fieldName: 'Status'
      }]);
    }
    *filterTerm(term) {
      this.filterValue = term;
      yield (0, _emberConcurrency.timeout)(1000);
      this.buildFilters();
    }
    *initIndicator() {
      try {
        yield this.getCompetences.perform();
        yield this.buildFilters();
      } catch (e) {
        console.log(e);
        return this.swal.catch(e);
      }
    }
    *getCompetences() {
      try {
        const competences = yield this.ajax.request('offSiteBeats/competences', {
          data: {
            ledType: 'd'
          }
        });
        this.competences = competences;
        this.setInitialCompetence();
      } catch (error) {
        console.log(error);
        return this.swal.catch(error);
      }
    }
    setInitialCompetence() {
      Ember.set(this.filtersSelected, 'competence', [this.competences[0]]);
    }
    clearFilters() {
      this.filtersSelected = {};
      Ember.set(this.filtersSelected, 'competence', [this.competences[0]]);
      this.filterValue = '';
      this.ledType = {
        label: 'Diretos',
        value: 'd'
      };
      this.statusSelected = {
        value: ['PENDING_HR', 'PENDING_LEADER', 'APPROVED', 'REPROVED'],
        label: 'Todas'
      };
      this.buildFilters();
    }
    onChangeLedType(type) {
      this.ledType = type;
      Ember.set(this.filtersSelected, 'ledType', type.value);
      this.buildFilters();
    }
    onChangeStatus(status) {
      this.statusSelected = status;
      Ember.set(this.filtersSelected, 'status', status);
      this.buildFilters();
    }
    buildFilters() {
      const filters = {
        status: ['PENDING_HR', 'PENDING_LEADER', 'APPROVED', 'REPROVED']
      };
      if (this.filtersSelected.status) {
        filters.status = this.filtersSelected.status.value.map(status => status);
      }
      if (this.filtersSelected.branches?.length) {
        filters.branches = this.filtersSelected.branches.map(department => department.id);
      }
      if (this.filtersSelected.departments?.length) {
        filters.departments = this.filtersSelected.departments.map(department => department.id);
      }
      if (this.filtersSelected.jobRoles?.length) {
        filters.jobRoles = this.filtersSelected.jobRoles.map(jobRole => jobRole.id);
      }
      if (this.filtersSelected.jobFunctions?.length) {
        filters.jobFunctions = this.filtersSelected.jobFunctions.map(jobFunction => jobFunction.id);
      }
      if (this.filtersSelected.competence) {
        filters.competence = this.filtersSelected.competence;
      }
      if (this.filtersSelected.ledType) {
        filters.ledType = this.filtersSelected.ledType;
      } else {
        filters.ledType = 'd';
      }
      if (this.filterValue) {
        filters.term = this.filterValue;
      }
      this.filters = filters;
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ledType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Diretos',
        value: 'd'
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "statusSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        value: ['PENDING_HR', 'PENDING_LEADER', 'APPROVED', 'REPROVED'],
        label: 'Todas'
      };
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ledTypeOptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        label: 'Todos',
        value: 'a'
      }, {
        label: 'Diretos',
        value: 'd'
      }, {
        label: 'Indiretos',
        value: 'i'
      }];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "competences", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filterTerm", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterTerm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initIndicator", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initIndicator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInitialCompetence", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setInitialCompetence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeLedType", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeLedType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStatus", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype)), _class));
  _exports.default = HomeMyTeamTimeCardDetailsController;
});