define("portal-talentrh/pods/components/image-cropper/component", ["exports", "@glimmer/component", "cropper-js"], function (_exports, _component, _cropperJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ImageCropperComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class ImageCropperComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
    }
    initCropper(img) {
      let {
        image,
        onCrop,
        onFail
      } = this.args;
      if (!image) {
        return;
      }
      img.src = URL.createObjectURL(image);
      img.setAttribute('style', 'max-width:100%; display:block');
      img.onload = () => {
        if (img.naturalWidth < 200 || img.naturalHeight < 200) {
          img.src = null;
          return this.swal.warning('As dimensões da imagem devem ser no mínimo 200x200', {
            title: 'A imagem é muito pequena'
          }).then(() => {
            if (onFail) {
              onFail();
            }
          });
        }
        new _cropperJs.default(img, {
          aspectRatio: 1,
          viewMode: 2,
          minCanvasHeight: 200,
          minContainerWidth: 466,
          minContainerHeight: 466,
          responsive: false,
          cropmove: e => {
            let cropped = e.target.cropper.getData();
            if (cropped.width < 200 || cropped.height < 200) {
              cropped.width = 200;
              cropped.height = 200;
              e.target.cropper.setData(cropped);
            }
          },
          crop: e => {
            e.target.cropper.getCroppedCanvas().toBlob(blob => {
              let blobFile = new File([blob], image.name, {
                type: image.type
              });
              if (onCrop) {
                onCrop(blobFile);
              }
            });
          }
        });
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initCropper", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initCropper"), _class.prototype)), _class));
  _exports.default = ImageCropperComponent;
});