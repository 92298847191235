define("portal-talentrh/pods/components/widgets/user-messages/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement() {
      let session = this.session;
      let currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenUserMessagesWidget = session.data.lastOpenUserMessagesWidget;
      if (lastOpenUserMessagesWidget && lastOpenUserMessagesWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        this.loadWidget.perform();
      }
    },
    loadWidget: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.ajax.request('userMessages/latest');
      } catch (e) {
        Ember.debug('[Widgets::UserMessages] Erro ao carregar as ausências');
        Ember.debug(e);
      }
    }).on('didInsertElement'),
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenUserMessagesWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});