define("portal-talentrh/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains(params /*, hash*/) {
    let string = params[0] || '';
    return string.includes(params[1]);
  }
  var _default = Ember.Helper.helper(contains);
  _exports.default = _default;
});