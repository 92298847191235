define("portal-talentrh/pods/forum/topic/index/edit/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ForumTopicIndexEditController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model'), _dec5 = Ember._action, (_class = class ForumTopicIndexEditController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "ajax", _descriptor3, this);
      _initializerDefineProperty(this, "topic", _descriptor4, this);
      _defineProperty(this, "summernoteImages", Ember.A());
    }
    closeModal() {
      this.transitionToRoute('forum.topic.index');
    }
    *save() {
      try {
        if (!this.topic.title) {
          return this.swal.warning('É necessário informar um título');
        }

        // Resolve o problema de quando a imagem é muito grande e o base64
        // Estora o limite do tamanho da request
        let {
          body
        } = this.topic;
        const base64Regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
        if (base64Regex.test(body)) {
          const bases64 = body.match(base64Regex);
          bases64.forEach(base64 => {
            const base64Encoded = btoa(base64);
            body = body.replace(bases64, base64Encoded.slice(0, 8));
          });
        }
        this.topic.body = body;
        yield this.topic.save();
        if (this.summernoteImages.length) {
          this.swal.loading();
        }
        for (const image of this.summernoteImages) {
          const form = new FormData();
          form.append('topic', this.topic.id);
          form.append('summernote', true);
          form.append('attachment', image);
          const response = yield this.ajax.post(`topicImages`, {
            contentType: false,
            processData: false,
            type: 'POST',
            data: form
          });
          const postImage = response.postImage;
          postImage.base64 = image.base64;
          const base64Encoded = btoa(image.base64);
          this.topic.body = this.topic.body.replace(base64Encoded.slice(0, 8), postImage.url);
        }
        yield this.topic.save();
        this.toast.success('Tópico atualizado');
        this.send('reload');
        this.closeModal();
      } catch (e) {
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "topic", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = ForumTopicIndexEditController;
});