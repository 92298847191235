define("portal-talentrh/pods/components/draw-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vmk/ggL9",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[10,\"div\"],[14,1,\"sketch\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex justify-content-between align-items-center\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[1,[30,[36,0],[[32,1],\"Assinatura\"],null]],[13],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-outline-primary btn-sm mb-2 text-primary float-right\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"clearCanvas\"]]],null]],[12],[2,\"Limpar\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"canvas\"],[4,[38,2],[[32,0,[\"resizeCanvas\"]]],null],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/draw-panel/template.hbs"
    }
  });
  _exports.default = _default;
});