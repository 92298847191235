define("portal-talentrh/pods/application/navbar/notification/badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6dNH7rwE",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unreadCount\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/badge/template.hbs"
    }
  });
  _exports.default = _default;
});