define("portal-talentrh/pods/forum/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    search: null,
    // Methods
    getFilters() {
      let filters = {};
      if (this.search) {
        filters.term = this.search;
      }
      Ember.set(this, 'filters', filters);
    },
    // Tasks
    delete: (0, _emberConcurrency.task)(function* (category) {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção da categoria?');
        if (!value) {
          return;
        }
        yield category.destroyRecord();
        this.getFilters();
        this.toast.success('A categoria foi removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    filterInput: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable()
  });
  _exports.default = _default;
});