define("portal-talentrh/pods/components/post-filter-dropdown/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['post-filter-dropdown', 'd-flex', 'mb-2', 'align-items-center'],
    store: Ember.inject.service(),
    permission: Ember.inject.service(),
    ajax: Ember.inject.service(),
    // Default value

    // Single line computed
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    userBranches: Ember.computed.alias('permission.branches'),
    // Multi line computed
    showBranchesFilter: Ember.computed.and('companyConfig.featureFlags.ENABLE', 'companyConfig.featureFlags.postPerBranch', 'permission.accessProfile.admin'),
    init() {
      this._super(...arguments);
      this.selectedTitle = 'Principais';
      this.defaultTags = [{
        title: 'Principais',
        action: 'loadPost',
        param: 'all'
      }, {
        title: 'Recentes',
        action: 'loadPost',
        param: 'last'
      }, {
        title: 'Favoritos',
        action: 'loadPost',
        param: 'favorites'
      }, {
        title: 'Posts',
        action: 'loadPost',
        param: 'message'
      }, {
        title: 'Comunicados',
        action: 'loadPost',
        param: 'announcement'
      }, {
        title: 'Eventos',
        action: 'loadPost',
        param: 'event'
      }, {
        title: 'Notícias',
        action: 'loadPost',
        param: 'news'
      }, {
        title: 'Classificados',
        action: 'loadPost',
        param: 'ad'
      }, {
        title: 'Vagas',
        action: 'loadPost',
        param: 'vacancy'
      }, {
        title: 'Enquetes',
        action: 'loadPost',
        param: 'survey'
      }, {
        title: 'Arquivados',
        action: 'loadPost',
        param: 'archived',
        profileUser: this.profileUser,
        feed: this.feed
      }].filter(tag => {
        return !!this[tag.action];
      });
    },
    didInsertElement() {
      this.send('selectDefaultTag', this.defaultTags[0]);
    },
    loadTags: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.postTags) {
          return;
        }
        const postTags = yield this.store.query('post-tag', {
          sort: 'title ASC'
        });
        Ember.set(this, 'postTags', postTags);
        return;
      } catch (err) {
        Ember.debug('Erro ao carregar as etiquetas: ', err);
        return;
      }
    }),
    resetScroll() {
      let dropdown = this.element.querySelector(`.dropdown-menu`) || {};
      dropdown.scrollTop = 0;
      Ember.set(this, 'isLoadingMore', false);
      Ember.set(this, 'haveMorePosts', true);
    },
    actions: {
      selectDefaultTag(tag) {
        let action = Ember.get(this, tag.action);
        if (!action) {
          return;
        }
        const [tagArchived] = this.defaultTags.filter(tag => tag.param === 'archived');
        Ember.set(tagArchived, 'profileUser', this.profileUser);
        Ember.set(this, 'selectedTitle', tag.title);
        this.resetScroll();
        action(tag.param);
      },
      selectTag(tag) {
        Ember.set(this, 'selectedTitle', tag.get('title'));
        this.resetScroll();
        this.loadPost(null, tag.id);
      },
      selectBranch(branch) {
        Ember.set(this, 'selectedTitle', branch.tradingName || branch.name);
        this.resetScroll();
        this.loadPost('last', null, [branch]);
      }
    }
  });
  _exports.default = _default;
});