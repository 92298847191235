define("portal-talentrh/utils/localISOTime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /*
   *  Uso:
   *  import localISOTime from 'portal-talentrh/utils/localISOTime';
   */
  function _default() {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString();
  }
});