define("portal-talentrh/pods/company/minutes-of-meeting/index/print/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyMinutesOfMeetingIndexPrintRoute extends Ember.Route {
    model(_ref) {
      let {
        id
      } = _ref;
      return id;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.loadPdf.perform();
    }
    deactivate() {
      this.controller.pdf = null;
    }
  }
  _exports.default = CompanyMinutesOfMeetingIndexPrintRoute;
});