define("portal-talentrh/pods/human-resources/user-solicitations/historic/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency", "moment"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HumanResourcesUserSolicitationsHistoricController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('filter.solicitationType'), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class HumanResourcesUserSolicitationsHistoricController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "createdAt", _descriptor, this);
      _initializerDefineProperty(this, "filter", _descriptor2, this);
      _initializerDefineProperty(this, "inputFilter", _descriptor3, this);
      _initializerDefineProperty(this, "status", _descriptor4, this);
      _initializerDefineProperty(this, "reload", _descriptor5, this);
      _defineProperty(this, "reportsSolicitationSalary", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'readjustDate',
        fieldName: 'Data da alteração',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'readjustValue',
        fieldName: 'Valor do salário'
      }, {
        field: 'readjustReasonDescription',
        fieldName: 'Motivo do reajuste'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationFunction", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'functionChangeDate',
        fieldName: 'Data da alteração',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'salary',
        fieldName: 'Salário atual'
      }, {
        field: 'userJobFunctionTitleField',
        fieldName: 'Função atual'
      }, {
        field: 'solicitationJobFunctionTitle',
        fieldName: 'Nova função'
      }, {
        field: 'departamentField',
        fieldName: 'Departamento atual'
      }, {
        field: 'solicitationDepartment',
        fieldName: 'Novo departamento'
      }, {
        field: 'currentLeader',
        fieldName: 'Líder atual'
      }, {
        field: 'solicitationAppraisedBy',
        fieldName: 'Novo líder'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationChangeHorary", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'horaryChangeDate',
        fieldName: 'Data da alteração',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'horaryTitle',
        fieldName: 'Horário'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationVacation", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'vacationInitial',
        fieldName: 'Data inicial de gozo',
        type: 'date',
        dateFormat: 'DD/MM/YYYY'
      }, {
        field: 'vacationDays',
        fieldName: 'Quantidade de dias de gozo'
      }, {
        field: 'vacationPaidTypeField',
        fieldName: 'Abonar férias'
      }, {
        field: 'vacationDaysPaid',
        fieldName: 'Dias abonados'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationWarningNotification", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'notificationTypeField',
        fieldName: 'Tipo'
      }, {
        field: 'notificationReason',
        fieldName: 'Motivo'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationTermination", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'motiveLabel',
        fieldName: 'Causa da rescisão'
      }, {
        field: 'terminationDate',
        fieldName: 'Data da rescisão',
        type: 'date',
        dateFormat: 'DD/MM/YYYY'
      }, {
        field: 'noticeDate',
        fieldName: 'Data do aviso',
        type: 'date',
        dateFormat: 'DD/MM/YYYY'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "reportsSolicitationCompensatoryTime", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'compensatoryTimeTypeField',
        fieldName: 'Tipo de banco de horas '
      }, {
        field: 'compensatoryTimeStart',
        fieldName: 'Inicio',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'compensatoryTimeEnd',
        fieldName: 'Término',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "standardReport", Ember.A([{
        field: 'createdAt',
        fieldName: 'Data',
        type: 'date',
        dateFormat: 'DD/MM/YYYY - HH:MM'
      }, {
        field: 'requesterFullName',
        fieldName: 'Solicitante'
      }, {
        field: 'userFullName',
        fieldName: 'Colaborador'
      }, {
        field: 'companyName',
        fieldName: 'Estabelecimento'
      }, {
        field: 'subject',
        fieldName: 'Assunto'
      }, {
        field: 'solicitationTypeField',
        fieldName: 'Tipo de solicitação'
      }, {
        field: 'message',
        fieldName: 'Descrição'
      }, {
        field: 'statusLabel',
        fieldName: 'Situação'
      }, {
        field: 'response',
        fieldName: 'Resposta'
      }]));
      _defineProperty(this, "statusOptions", [{
        label: 'Todas',
        value: ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed']
      }, {
        label: 'Aprovadas',
        value: 'approved'
      }, {
        label: 'Reprovadas pelo líder',
        value: 'disapproved-manager'
      }, {
        label: 'Reprovadas pelo Gestão de Pessoas',
        value: 'disapproved-hr'
      }, {
        label: 'Canceladas',
        value: 'canceled'
      }, {
        label: 'Ignoradas no software de folha de pagamento',
        value: 'ignored'
      }, {
        label: 'Processadas',
        value: 'processed'
      }]);
    }
    get selectedSolicitationTypeReport() {
      if (this.filter.solicitationType === 1) {
        return this.reportsSolicitationSalary;
      }
      if (this.filter.solicitationType === 2) {
        return this.reportsSolicitationFunction;
      }
      if (this.filter.solicitationType === 3) {
        return this.reportsSolicitationChangeHorary;
      }
      if (this.filter.solicitationType === 4) {
        return this.reportsSolicitationVacation;
      }
      if (this.filter.solicitationType === 5) {
        return this.reportsSolicitationWarningNotification;
      }
      if (this.filter.solicitationType === 7) {
        return this.reportsSolicitationTermination;
      }
      if (this.filter.solicitationType === 15) {
        return this.reportsSolicitationCompensatoryTime;
      }
      if ([8, 9, 10, 11, 12, 13, 14].includes(this.filter.solicitationType)) {
        return this.standardReport;
      }
    }
    reloadFilters(filters) {
      this.filter = filters;
      this.filter.status = ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed'];
      if (this.inputFilter) {
        this.filter.term = this.inputFilter;
      }
      this.filter.hr = 's';
      this.reload = true;
    }
    clearInput() {
      this.inputFilter = null;
      this.getFilters();
    }
    getFilters(filter) {
      if (!filter) {
        if (!this.filter || this.filter && !this.filter.status) {
          let filter = {
            status: ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed']
          };
          filter.createdAt = (0, _moment.default)((0, _moment.default)().startOf('month').toDate()).format('YYYY-MM-DD HH:mm');
          filter.finalDate = (0, _moment.default)((0, _moment.default)().toDate()).format('YYYY-MM-DD HH:mm');
          this.filter = filter;
        } else {
          if (this.inputFilter) {
            this.filter.term = this.inputFilter;
          } else {
            delete this.filter.term;
          }
        }
      } else {
        this.filter = filter;
        this.filter.status = ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed'];
      }
      this.filter.hr = 's';
      this.reload = true;
    }
    *filterInput() {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        pending: 'n'
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inputFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "reload", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectedSolicitationTypeReport", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectedSolicitationTypeReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadFilters", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "reloadFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInput", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterInput", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterInput"), _class.prototype)), _class));
  _exports.default = HumanResourcesUserSolicitationsHistoricController;
});