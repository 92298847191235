define("portal-talentrh/pods/components/indicators/company-profile/age-group/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsCompanyProfileAgeGroupComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class IndicatorsCompanyProfileAgeGroupComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "utils", _descriptor, this);
      _initializerDefineProperty(this, "label", _descriptor2, this);
      _initializerDefineProperty(this, "colors", _descriptor3, this);
    }
    loadChart() {
      let that = this;
      let chartAgeGroup = document.getElementById('age-group-pie-chart');
      new _chartJs.default(chartAgeGroup.getContext('2d'), {
        type: 'doughnut',
        data: {
          labels: that.args.ageGroup?.labels,
          datasets: [{
            backgroundColor: that.args.colors ? that.args.colors : that.colors,
            borderColor: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
            label: 'Faixa Etária',
            data: that.args.ageGroup?.data,
            borderWidth: 2
          }]
        },
        plugins: [{
          afterDraw: function (chart) {
            if (that.args.ageGroup) {
              that.utils.addTextInsideChart(chart);
            }
          }
        }],
        options: {
          elements: {
            center: {
              text: that.args.ageGroup?.predominant,
              valueText: that.args.ageGroup?.predominantValue,
              color: '#666666',
              fontStyle: 'SourceSansPro',
              sidePadding: that.args.sidePadding ? that.args.sidePadding : 30,
              minFontSize: that.args.minFontSize ? that.args.minFontSize : 14,
              lineHeight: that.args.lineHeight ? that.args.lineHeight : 20
            }
          },
          cutout: '80%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: that.args.legendPosition ? that.args.legendPosition : 'right',
              labels: {
                fontColor: '#666666',
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            },
            tooltip: {
              yAlign: 'bottom',
              callbacks: {
                label: function (context) {
                  return context.label;
                }
              }
            }
          },
          onClick: function (e) {
            that.utils.onChartClick(this, that, e);
          },
          onHover: function (e) {
            if (that.args.hover) {
              that.utils.onChartHover(this, e);
            }
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['#3B00ED', '#9C27B0', '#36A2EB', '#FF6384', '#F4BE37', '#4BC0C0', '#FF784D', '#CC0088', '#54548C', '#0263FF', '#FC9C20', '#E82626', '#465773'];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadChart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadChart"), _class.prototype)), _class));
  _exports.default = IndicatorsCompanyProfileAgeGroupComponent;
});