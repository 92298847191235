define("portal-talentrh/pods/components/post-new/component", ["exports", "xss", "portal-talentrh/config/environment", "ember-concurrency"], function (_exports, _xss, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    groupsService: Ember.inject.service('groups'),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    postImageUpload: Ember.inject.service(),
    postVideoUpload: Ember.inject.service(),
    habitus: Ember.inject.service(),
    // Default values
    openAttachmentModal: false,
    openGroupModal: false,
    openBranchModal: false,
    openImagesModal: false,
    openPostTagModal: false,
    showTaskAttachment: false,
    postAttachments: Ember.A(),
    postImages: Ember.A(),
    advancedEditorImages: Ember.A(),
    advancedEditorVideos: Ember.A(),
    branches: Ember.A(),
    postDescription: '',
    groupModel: false,
    // Single Line Computed Properties
    isPostMessage: Ember.computed.equal('type', 'message'),
    isPostNews: Ember.computed.equal('type', 'news'),
    isPostAd: Ember.computed.equal('type', 'ad'),
    isPostEvent: Ember.computed.equal('type', 'event'),
    admin: Ember.computed.alias('permission.admin'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    // Multiline Computed Properties
    isPostMessageOrNews: Ember.computed('type', function () {
      return this.type === 'message' || this.type === 'news';
    }),
    imageConfig: Ember.computed('type', function () {
      if (this.type === 'event') {
        return {
          maxImages: 1,
          label: 'Selecionar imagem'
        };
      }
      return {
        maxImages: 10,
        label: 'Selecionar imagens'
      };
    }),
    imagePreview: Ember.computed('postImages.[]', function () {
      return this.postImages.map(image => image.preview);
    }),
    selectedPostTag: Ember.computed('post.postTag.id', function () {
      let id = Ember.get(this, 'post.postTag.id');
      if (!id) {
        return {
          title: 'Etiqueta'
        };
      }
      return this.store.peekRecord('post-tag', id);
    }),
    selectedUserGroup: Ember.computed('post.userGroup', function () {
      let id = Ember.get(this, 'post.userGroup');
      if (!id) {
        return {
          name: 'Toda a empresa'
        };
      }
      return this.groupsService.getById(id);
    }),
    selectedBranches: Ember.computed('post.{branches,branches.length}', function () {
      if (this.post.branches && this.post.branches.length) {
        return this.post.branches;
      }
      return 'Toda a empresa';
    }),
    showBranches: Ember.computed('post.userGroup', 'accessProfile.{allowPublishPerBranch,admin}', 'companyConfig.featureFlags.{ENABLE,postPerBranch}', function () {
      return !this.post.userGroup && (this.accessProfile.allowPublishPerBranch || this.accessProfile.admin) && this.companyConfig.featureFlags.ENABLE && this.companyConfig.featureFlags.postPerBranch;
    }),
    showGroup: Ember.computed('post.{branches[],branches.length}', function () {
      return !this.post.branches || !this.post.branches.length;
    }),
    branchesLabel: Ember.computed('post.{branches[],branches.length}', function () {
      if (!this.post.branches || !this.post.branches.length) {
        return 'Filial';
      } else if (this.post.branches.length === 1) {
        return this.post.branches[0].tradingName || this.post.branches[0].name;
      } else {
        return `${this.post.branches.length} filiais selecionadas`;
      }
    }),
    groupLabel: Ember.computed('post.userGroup', function () {
      let id = Ember.get(this, 'post.userGroup');
      const grupo = this.groupsService.getById(id);
      if (!id || !grupo) {
        return 'Grupo';
      }
      return this.groupsService.getById(id).name;
    }),
    // Lifecycle Hooks
    didReceiveAttrs() {
      this._super(...arguments);
      this._initModel();
    },
    didInsertElement() {
      this._super(...arguments);
      // Apenas ativa o editor básico se o post for do tipo message.
      Ember.set(this, 'showBasicEditor', this.type === 'message');
    },
    willDestroyElement() {
      this._super(...arguments);
    },
    // Tasks
    createPost: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(800);
      if (this.permission.accessProfile.publishOnlyInGroup && !this.post.userGroup && this.type !== 'ad' && !this.admin) {
        return Ember.set(this, 'openGroupModal', true);
      }
      if ((!this.post.title || this.post.title.length < 2) && this.type !== 'message' && this.type !== 'news') {
        return this.swal.warning('A publicação deve ter um título');
      }
      if (!this.postDescription && !this.post.body && this.type !== 'news') {
        return this.swal.warning('A publicação deve ter uma descrição');
      }
      if (this.post.postTag) {
        this.post.postTag = Ember.get(this, 'post.postTag.id');
      }
      if (this.type === 'event' && (!this.post.initialDate || !this.post.finalDate)) {
        return this.swal.warning('O evento deve ter data de início e término');
      }
      if (this.type === 'news') {
        if (!this.post.link) {
          return this.swal.warning('A publicação deve ter um link');
        } else {
          const links = this.post.link.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
          if (!links) {
            return this.swal.warning('Formato inválido, utilize o http:// antes do link. Exemplo: http://nela.com.br');
          } else {
            const check = yield this.ajax.request(`/posts/checkNewsLink`, {
              data: {
                link: links
              }
            });
            if (!check.result) {
              return this.swal.warning('O link não está no formato esperado, tente outro link.');
            }
          }
        }
      }
      Ember.set(this, 'isCreatingPost', true);
      const postMessageTab = document.getElementById('post-message-tab');
      if (postMessageTab) {
        postMessageTab.click();
      }
      let {
        offsetTop
      } = document.getElementById('infinite-post-scroll') || {};
      window.scrollTo({
        behavior: 'smooth',
        top: offsetTop
      });
      try {
        let {
          body
        } = this.post;
        Ember.set(this, 'post.body', this.postDescription);
        Ember.set(this, 'postDescription', '');
        let {
          post
        } = yield this.ajax.post('posts', {
          data: {
            post: this.post
          }
        });
        this.store.pushPayload({
          post
        });
        post = this.store.peekRecord('post', post.id);
        Ember.set(this, 'post', post);
        if (this.postAttachments) {
          for (const file of this.postAttachments) {
            Ember.debug('Uploading File: ', file.name);
            yield this.uploadFile.perform(file);
          }
        }
        if (this.postImages.length) {
          this.swal.loading();
        }
        post.postStatus = 'pending';
        yield post.save();
        for (let image of this.postImages) {
          let form = new FormData();
          form.append('post', post.id);
          form.append('attachment', image.form);
          let postImage = yield this.ajax.request('postimages', {
            contentType: false,
            processData: false,
            type: 'POST',
            data: form
          });
          this.store.pushPayload(postImage);
        }
        post.body = body;
        yield this.postImageUpload.save.perform(post, this.advancedEditorImages);
        yield this.postVideoUpload.save.perform(post, this.advancedEditorVideos);
        post.postStatus = 'concluded';
        yield post.save();
        this.swal.close();
        this.postImages.clear();
        this.postAttachments.clear();
        this.advancedEditorImages.clear();
        this.advancedEditorVideos.clear();
        this._initModel();
        if (this.type === 'event' && this.reloadWidgetNextEvent) {
          this.reloadWidgetNextEvent();
        }
        Ember.set(this, 'postHaveImages', false);
        const findPostCreated = yield this.ajax.request('/feed/posts/' + post.id, {
          host: _environment.default.apiUrl.core
        });
        if (findPostCreated && findPostCreated.post) {
          this.posts.insertAt(0, findPostCreated.post);
        }
      } catch (e) {
        this.swal.catch(e);
        if (this.post.id) {
          this.post.destroyRecord();
        }
      } finally {
        Ember.set(this, 'isCreatingPost', false);
      }
    }).drop(),
    uploadFile: (0, _emberConcurrency.task)(function* (file) {
      let s3Signer = '/s3/signedUploadUrl';
      let model = 'postAttachment';
      let parentModel = 'post';
      let parentModelId = this.post.id;
      let newFile = {};
      let query = {
        model,
        parentModel,
        parentModelId,
        filename: file.name ? file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : file.name,
        type: file.type
      };
      try {
        let response = yield this.ajax.request(s3Signer, {
          data: query
        });
        this.swal.loading('Realizando o upload do arquivo ' + file.name);
        return fetch(response.url, {
          method: 'PUT',
          body: file
        });
      } catch (e) {
        Ember.debug('Erro no upload: ', e);
        Ember.set(newFile, 'status', 'failed');
      }
    }),
    getUserBranches: (0, _emberConcurrency.task)(function* () {
      try {
        const userBranches = yield this.permission.branches;
        Ember.set(this, 'branches', userBranches);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop().on('didInsertElement'),
    onPostLinkChange: (0, _emberConcurrency.task)(function* (event) {
      try {
        const value = event.target.value;
        const check = yield this.ajax.request(`/posts/checkNewsLink`, {
          data: {
            link: value
          }
        });
        if (!check.result) {
          return this.swal.warning('O link não está no formato esperado, tente outro link.');
        }
        Ember.set(this, 'post.link', value);
      } catch (e) {
        console.log(e);
        this.swal.warning('O link não está no formato esperado, tente outro link.');
      }
    }),
    // Custom methods
    _initModel() {
      let post = {
        body: '',
        finalDate: undefined,
        initialDate: undefined,
        link: undefined,
        title: '',
        type: this.type,
        user: this.session.user.id,
        userGroup: Ember.get(this, 'userGroup.id'),
        value: undefined
      };
      Ember.set(this, 'post', post);
    },
    // Actions
    actions: {
      afterUpload(newFile, response) {
        delete response.url;
        this.postAttachments.pushObject(response.postAttachments);
      },
      groupSelected(group) {
        Ember.set(this, 'post.userGroup', group);
      },
      openSelectAttachment() {
        document.getElementById('select-attachment').click();
      },
      removePostTag() {
        Ember.set(this, 'selectedTag', null);
        Ember.set(this, 'post.postTag', null);
      },
      selectAttachment() {
        let file = document.getElementById('select-attachment').files[0];
        this.postAttachments.pushObject(file);
      },
      branchesSelected(branches) {
        Ember.set(this, 'openBranchModal', false);
        Ember.set(this, 'post.branches', branches);
      },
      toggleEditor() {
        this.toggleProperty('showBasicEditor');
        if (this.post.body) {
          Ember.set(this, 'post.body', this.post.body.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ''));
        }
      },
      hidePopover(element) {
        const noteEditable = element.querySelector('.note-editable');
        noteEditable?.addEventListener('scroll', () => {
          element.querySelector('.note-popover').style.display = 'none';
        });
      },
      openModal() {
        Ember.set(this, 'openPostTagModal', true);
      },
      onInputPostBody(text) {
        const postDiv = document.getElementById('post-message');
        const contentTextarea = document.querySelector('.post-new__form');
        const lineHeight = parseFloat(getComputedStyle(contentTextarea).lineHeight);
        const scrollSmoothlyBy = amount => {
          window.scrollTo({
            top: window.scrollY + amount,
            behavior: 'smooth'
          });
        };
        if (postDiv.getBoundingClientRect().bottom > window.innerHeight) {
          scrollSmoothlyBy(lineHeight);
        }
        Ember.set(this, 'postDescription', text);
        Ember.set(this, 'post.body', text);
      },
      inputSanitize() {
        const allowedIframeDomains = ['youtube.com', 'vimeo.com'];
        let options = {
          whiteList: {
            p: ["class"],
            span: ["class", "style"],
            strong: ["class", "style"],
            em: ["class", "style"],
            ul: [],
            ol: [],
            li: [],
            u: ["class", "style"],
            pre: ["class", "spellcheck", "style"],
            iframe: ["class", "src", "frameborder", "allowfullscreen", "showinfo", "width", "height"],
            img: ["src", "width", "height"],
            video: ["controlslist", "src", "controls", "width", "height", "webkit-playsinline", "playsinline", "x5-playsinline", "controlslist"],
            br: [],
            a: ["href", "rel", "target", "style", "class"],
            b: ["style", "class"],
            i: ["style", "class", "aria-hidden"]
          },
          stripIgnoreTagBody: ["script"],
          // Remove a tag script
          onTag: (tag, html, options) => {
            if (tag === 'iframe') {
              const srcMatch = html.match(/src="([^"]+)"/);
              if (srcMatch) {
                const src = srcMatch[1];
                const url = new URL(src);
                if (allowedIframeDomains.includes(url.hostname)) {
                  return ''; // Remove a tag iframe se o domínio não for permitido
                }
              }
            }
          }
        };

        let bodySanitized = (0, _xss.default)(this.post.bodyRaw, options);
        Ember.set(this, 'post.body', bodySanitized);
      },
      inputSanitizeTitle() {
        const allowedIframeDomains = ['youtube.com', 'vimeo.com'];
        let options = {
          whiteList: {
            p: ["class"],
            span: ["class", "style"],
            strong: ["class", "style"],
            em: ["class", "style"],
            ul: [],
            ol: [],
            li: [],
            u: ["class", "style"],
            pre: ["class", "spellcheck", "style"],
            iframe: ["class", "src", "frameborder", "allowfullscreen", "showinfo", "width", "height"],
            img: ["src", "width", "height"],
            video: ["controlslist", "src", "controls", "width", "height", "webkit-playsinline", "playsinline", "x5-playsinline", "controlslist"],
            br: [],
            a: ["href", "rel", "target", "style", "class"],
            b: ["style", "class"],
            i: ["style", "class", "aria-hidden"]
          },
          stripIgnoreTagBody: ["script"],
          // Remove a tag script
          onTag: (tag, html, options) => {
            if (tag === 'iframe') {
              const srcMatch = html.match(/src="([^"]+)"/);
              if (srcMatch) {
                const src = srcMatch[1];
                const url = new URL(src);
                if (allowedIframeDomains.includes(url.hostname)) {
                  return ''; // Remove a tag iframe se o domínio não for permitido
                }
              }
            }
          }
        };

        let titleSanitized = (0, _xss.default)(this.post.titleRaw, options);
        Ember.set(this, 'post.title', titleSanitized);
      }
    }
  });
  _exports.default = _default;
});