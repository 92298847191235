define("portal-talentrh/helpers/file-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICONS = ['music', 'file-alt', 'image', 'file-code', 'film', 'file-pdf', 'file-word', 'file-excel', 'file-powerpoint', 'file-archive', 'file-image'];
  const TYPES = {
    aac: 0,
    mp3: 0,
    mpeg: 0,
    ogg: 0,
    wav: 0,
    weba: 0,
    txt: 1,
    gif: 2,
    jpeg: 2,
    jpg: 2,
    png: 2,
    css: 3,
    html: 3,
    java: 3,
    js: 3,
    pas: 3,
    m4v: 4,
    mp4: 4,
    ogv: 4,
    epub: 5,
    pdf: 5,
    doc: 6,
    docx: 6,
    xls: 7,
    xlsx: 7,
    ppt: 8,
    pptx: 8,
    rar: 9,
    zip: 9,
    '7z': 9,
    bmp: 10,
    ico: 10,
    svg: 10,
    tiff: 10,
    webp: 10
  };
  var _default = Ember.Helper.helper(function fileType(params /*, hash*/) {
    let ext = (params[0] || 'txt').toLowerCase();
    return 'fa-' + ICONS[TYPES[ext] || 1];
  });
  _exports.default = _default;
});