define("portal-talentrh/helpers/coalesce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.coalesce = coalesce;
  _exports.default = void 0;
  function coalesce(params /*, hash*/) {
    return params[0] || params[1];
  }
  var _default = Ember.Helper.helper(coalesce);
  _exports.default = _default;
});