define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-engagement/index/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('permission.accessProfile.manageOnlyBranch'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, (_class = class IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "manageOnlyBranch", _descriptor4, this);
      _initializerDefineProperty(this, "initialDate", _descriptor5, this);
      _initializerDefineProperty(this, "endDate", _descriptor6, this);
      _initializerDefineProperty(this, "disableBranch", _descriptor7, this);
      _initializerDefineProperty(this, "disableBranchAndDepartment", _descriptor8, this);
      _initializerDefineProperty(this, "disableBranchAndDepartmentAndJobRole", _descriptor9, this);
      _initializerDefineProperty(this, "filters", _descriptor10, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor11, this);
      _initializerDefineProperty(this, "graphFilterSelected", _descriptor12, this);
      _initializerDefineProperty(this, "totalInteractionData", _descriptor13, this);
      _initializerDefineProperty(this, "accessFrequenciesData", _descriptor14, this);
      _initializerDefineProperty(this, "rankingInfluencerData", _descriptor15, this);
      _initializerDefineProperty(this, "rankingInteractionData", _descriptor16, this);
      _initializerDefineProperty(this, "firstRankingLikes", _descriptor17, this);
      _initializerDefineProperty(this, "firstRankingComments", _descriptor18, this);
      _initializerDefineProperty(this, "firstRankingPosts", _descriptor19, this);
    }
    loadIndicators() {
      try {
        const filters = this.buildFilters();
        if (filters.invalidPeriod) {
          return;
        }
        if (this.graphFilterSelected === 'interactions') {
          this.loadTotalInteractions.perform(filters);
          this.loadAccessFrequencies.perform(filters);
        } else if (this.graphFilterSelected === 'rankInfluencers') {
          this.loadRankingInfluencer.perform(filters);
        } else if (this.graphFilterSelected === 'rankInteractions') {
          this.loadRankingInteractions.perform(filters);
        }
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: CompanyEngagement :: Index :: loadIndicators`);
        return this.swal.catch(e);
      }
    }
    *loadAccessFrequencies(filters) {
      try {
        let accessFrequenciesData = yield this.fetch.request('/api/humanResources/getAccessLogs', {
          host: 'indicadores',
          data: filters
        });
        accessFrequenciesData = {
          ...accessFrequenciesData,
          groupBy: filters.groupBy
        };
        this.accessFrequenciesData = accessFrequenciesData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: CompanyEngagement :: Index :: loadAccessFrequencies`);
        return this.swal.catch(e);
      }
    }
    *loadTotalInteractions(filters) {
      try {
        const totalInteractionData = yield this.fetch.request('/api/humanResources/getTotalInteractions', {
          host: 'indicadores',
          data: filters
        });
        this.totalInteractionData = totalInteractionData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: CompanyEngagement :: Index :: loadTotalInteractions`);
        return this.swal.catch(e);
      }
    }
    *loadRankingInfluencer(filters) {
      try {
        const rankingInfluencerData = yield this.fetch.request('/api/humanResources/getRankingInfluencers', {
          host: 'indicadores',
          data: filters
        });
        this.rankingInfluencerData = rankingInfluencerData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: CompanyEngagement :: Index :: loadRankingInfluencer`);
        return this.swal.catch(e);
      }
    }
    *loadRankingInteractions(filters) {
      try {
        this.firstRankingLikes = null;
        this.firstRankingComments = null;
        this.firstRankingPosts = null;
        const rankingInteractionData = yield this.fetch.request('/api/humanResources/getInteractionRanking', {
          host: 'indicadores',
          data: filters
        });
        this.rankingInteractionData = rankingInteractionData;
        if (rankingInteractionData.likes.firstObject) {
          this.firstRankingLikes = rankingInteractionData.likes.firstObject;
        }
        if (rankingInteractionData.comments.firstObject) {
          this.firstRankingComments = rankingInteractionData.comments.firstObject;
        }
        if (rankingInteractionData.posts.firstObject) {
          this.firstRankingPosts = rankingInteractionData.posts.firstObject;
        }
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: CompanyEngagement :: Index :: loadRankingInteractions`);
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.initialDate && this.endDate) {
        const diffDias = (0, _moment.default)(this.endDate).diff(this.initialDate, 'days');
        diffDias + 1;
        if (diffDias > 365) {
          this.swal.warning('Não é possível buscar um período maior que um ano.');
          filters.invalidPeriod = true;
          return filters;
        }
        if (this.graphFilterSelected == "rangePost") {
          filters.startDate = this.initialDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
          filters.endDate = this.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        } else {
          filters.initialDate = this.initialDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
          filters.endDate = this.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        if ((0, _moment.default)(this.initialDate).isAfter(this.endDate)) {
          this.swal.warning('A data inicial não pode ser posterior a data final');
          filters.invalidPeriod = true;
          return filters;
        }
        const diffDiasGroup = (0, _moment.default)(this.endDate).diff(this.initialDate, 'days');
        if (diffDiasGroup >= 32 && diffDiasGroup <= 90) {
          filters.groupBy = 'week';
        } else if (diffDiasGroup > 90) {
          filters.groupBy = 'month';
        } else {
          filters.groupBy = 'day';
        }
      }
      if (this.filtersSelected && this.filtersSelected.branches) {
        if (this.graphFilterSelected == "rangePost") {
          filters.branch = this.filtersSelected.branches.map(branch => branch.id);
        } else {
          filters.branches = this.filtersSelected.branches.map(branch => branch.id);
        }
      }
      if (this.filtersSelected && this.filtersSelected.departments) {
        if (this.graphFilterSelected == "rangePost") {
          filters.department = this.filtersSelected.departments.map(department => department.id);
        } else {
          filters.departments = this.filtersSelected.departments.map(department => department.id);
        }
      }
      if (this.filtersSelected && this.filtersSelected.jobRoles) {
        if (this.graphFilterSelected == "rangePost") {
          filters.jobRole = this.filtersSelected.jobRoles.map(jobRole => jobRole.id);
        } else {
          filters.jobRoles = this.filtersSelected.jobRoles.map(jobRole => jobRole.id);
        }
      }
      if (this.filtersSelected && this.filtersSelected.jobFunctions) {
        if (this.graphFilterSelected == "rangePost") {
          filters.jobFunction = this.filtersSelected.jobFunctions.map(jobFunction => jobFunction.id);
        } else {
          filters.jobFunctions = this.filtersSelected.jobFunctions.map(jobFunction => jobFunction.id);
        }
      }
      if (this.graphFilterSelected == "rangePost") {
        filters.type = 'indicators';
      }
      this.filters = filters;
      return filters;
    }
    clearFilters() {
      this.filtersSelected = {};
      this.initialDate = (0, _moment.default)().subtract(1, 'months').startOf('month');
      this.endDate = (0, _moment.default)().subtract(1, 'months').endOf('month');
      this.firstRankingLikes = null;
      this.firstRankingComments = null;
      this.firstRankingPosts = null;
      this.loadIndicators();
    }
    onSelectGraphics(graph) {
      this.graphFilterSelected = graph;
      this.loadIndicators();
    }
    onSelectInitialDate(_ref) {
      let {
        moment
      } = _ref;
      this.initialDate = moment;
      this.loadIndicators();
    }
    onSelectEndDate(_ref2) {
      let {
        moment
      } = _ref2;
      this.endDate = moment;
      this.loadIndicators();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "manageOnlyBranch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().subtract(1, 'months').startOf('month');
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().subtract(1, 'months').endOf('month');
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "disableBranch", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "disableBranchAndDepartment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "disableBranchAndDepartmentAndJobRole", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "graphFilterSelected", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'interactions';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "totalInteractionData", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "accessFrequenciesData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "rankingInfluencerData", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "rankingInteractionData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "firstRankingLikes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "firstRankingComments", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "firstRankingPosts", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadIndicators", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "loadIndicators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAccessFrequencies", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAccessFrequencies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTotalInteractions", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTotalInteractions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadRankingInfluencer", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadRankingInfluencer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadRankingInteractions", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadRankingInteractions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectGraphics", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectGraphics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectInitialDate", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectInitialDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectEndDate", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectEndDate"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementIndexController;
});