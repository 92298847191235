define("portal-talentrh/pods/components/modal-skill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hl6xB0/r",
    "block": "{\"symbols\":[\"Modal\",\"@value\",\"@show\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@show\",\"@toggle\",\"@onClose\"],[[32,3],[30,[36,1],[[30,[36,0],[[32,3]],null]],null],[30,[36,1],[[30,[36,0],[[32,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[[24,0,\"text-break\"]],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"overflow-auto ui-scrollbar max-h-vh-8\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-align-left text-primary h4 mr-2\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Descrição:\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,2,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mt-2 mr-1 ml-4 overflow-auto ui-scrollbar max-h-vh-3\"],[12],[2,\"\\n        \"],[1,[32,2,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mt-2 mr-1 ml-4 font-italic\"],[12],[2,\"\\n        Nenhuma descrição cadastrada\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/modal-skill/template.hbs"
    }
  });
  _exports.default = _default;
});