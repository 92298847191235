define("portal-talentrh/models/vacancy", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    registrationlink: (0, _model.attr)('string'),
    imageUrl: (0, _model.attr)('string'),
    applicationdeadline: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    onAllBranches: (0, _model.attr)('boolean'),
    jobFunctions: (0, _model.hasMany)('job-function', {
      inverse: 'vacancies'
    })
  });
  _exports.default = _default;
});