define("portal-talentrh/pods/components/indicator-viewer/performance/general-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RC5yak4F",
    "block": "{\"symbols\":[\"@indicator\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 mb-2\"],[12],[2,\"\\n    \"],[8,\"block-indicator-info\",[[24,0,\"indicator-blue block-performance\"]],[[\"@isLoaded\",\"@name\",\"@value\"],[[32,1,[\"loaded\"]],\"Avaliações Criadas\",[32,1,[\"data\",\"indicator1\",\"count\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 mb-2\"],[12],[2,\"\\n    \"],[8,\"block-indicator-info\",[[24,0,\"indicator-green block-performance\"]],[[\"@isLoaded\",\"@name\",\"@value\"],[[32,1,[\"loaded\"]],\"Avaliações Finalizadas\",[32,1,[\"data\",\"indicator2\",\"count\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 mb-2\"],[12],[2,\"\\n    \"],[8,\"block-indicator-info\",[[24,0,\"indicator-yellow block-performance\"]],[[\"@isLoaded\",\"@name\",\"@value\"],[[32,1,[\"loaded\"]],\"Aguardando Avaliador\",[32,1,[\"data\",\"indicator3\",\"count\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 mb-2 \"],[12],[2,\"\\n    \"],[8,\"block-indicator-info\",[[24,0,\"indicator-red block-performance\"]],[[\"@isLoaded\",\"@name\",\"@value\"],[[32,1,[\"loaded\"]],\"Aguardando Avaliado\",[32,1,[\"data\",\"indicator4\",\"count\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/performance/general-indicators/template.hbs"
    }
  });
  _exports.default = _default;
});