define("portal-talentrh/pods/habitus/details/access-frequency/publications/publication-item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const CDN_IMAGES_URL = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images';
  const IMAGES = {
    ad: 'ad.svg',
    announcement: 'announcements.svg',
    event: 'appointments.svg',
    message: 'post.svg',
    news: 'news.svg',
    survey: 'survey.svg'
  };
  const POST_TEXT_MAX_SIZE = 80;
  let PublicationItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('description.length'), _dec8 = Ember.computed('args.post.type'), (_class = class PublicationItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "preview", _descriptor4, this);
      _initializerDefineProperty(this, "description", _descriptor5, this);
      _initializerDefineProperty(this, "link", _descriptor6, this);
    }
    get showMoreIsNeeded() {
      return this.description && this.description.length > POST_TEXT_MAX_SIZE;
    }
    get svg() {
      const {
        post
      } = this.args;
      return `${CDN_IMAGES_URL}/${IMAGES[post.type]}`;
    }
    _getPostBodyWithoutTags(post) {
      const regX = /(<([^>]+)>)/ig;
      if (post.body && typeof post.body !== 'object') {
        return post.body.replace(regX, "");
      }
      return "";
    }
    *loadDescription() {
      this.preview = null;
      try {
        const {
          post
        } = this.args;
        this.description = null;
        let sharedPost;
        if (post.isSharedPost) {
          sharedPost = yield this.store.findRecord('post', post.get('sharedPost'));
        }
        if (post.type === 'message' || post.type === 'news' || post.type == 'event') {
          this.description = this._getPostBodyWithoutTags(post);
        } else {
          this.description = post.title;
        }
        if (post.type === 'news') {
          if (sharedPost) {
            this.link = sharedPost.link;
          } else {
            this.link = yield post.get('link');
          }
          const {
            data
          } = yield this.ajax.request(`/posts/preview`, {
            data: {
              link: this.link
            }
          });
          if (data) {
            this.preview = data;
            this.error = false;
          } else {
            this.error = true;
          }
        }
        if (!this.description && sharedPost) {
          this.description = sharedPost.title || this._getPostBodyWithoutTags(sharedPost);
        }
      } catch (e) {
        console.error(e);
        this.error = true;
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "preview", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "link", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showMoreIsNeeded", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showMoreIsNeeded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "svg", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "svg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadDescription", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadDescription"), _class.prototype)), _class));
  _exports.default = PublicationItemComponent;
});