define("portal-talentrh/pods/profile/edit/formation/index/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7fVhInsq",
    "block": "{\"symbols\":[\"Card\",\"@formation\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm h-100\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"pb-0 text-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[1,[32,2,[\"institutionName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"font-italic text-muted\"],[12],[2,\"\\n      \"],[1,[32,2,[\"courseTitle\"]]],[2,\" - \"],[1,[32,2,[\"type\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h6\"],[14,0,\"small\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,2,[\"startDate\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n      -\\n      \"],[1,[30,[36,2],[[30,[36,0],[[32,2,[\"endDate\"]],\"DD/MM/YYYY\"],null],[30,[36,1],[[32,2,[\"startDate\"]],\"Cursando\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[[24,0,\"text-center border-0 pt-0 pb-2\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-link\"],[24,\"title\",\"Editar\"]],[[\"@route\",\"@model\"],[\"profile.edit.formation.edit\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-edit\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-link text-danger\"],[15,\"onclick\",[30,[36,3],[[32,0,[\"removeFormation\"]]],null]],[14,\"title\",\"Remover\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"and\",\"or\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/edit/formation/index/item/template.hbs"
    }
  });
  _exports.default = _default;
});