define("portal-talentrh/pods/external-link/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    permission: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
      if (!this.permission.companyConfig.permitExternalLink) {
        return this.transitionTo('feed');
      }
    },
    deactivate() {
      this.controller.set('filters', {});
      this.controller.set('filterTerm', null);
      this.controller.getFilters();
    },
    actions: {
      refresh() {
        this.controller.getFilters();
      }
    }
  });
  _exports.default = _default;
});