define("portal-talentrh/pods/habitus/details/access-frequency/access-frequency-info/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AccessFrequencyInfoComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed('args.accessLogs', 'endDate', 'startDate', 'cantAccessDays'), _dec14 = Ember.computed('args.userMessages', 'endDate', 'startDate', 'cantAccessDays'), _dec15 = Ember.computed('accessLogsMonth.length'), _dec16 = Ember.computed('cantAccessDays', 'endDate', 'startDate'), _dec17 = Ember.computed('accessLogsMonth.length', 'amountOfDaysInPeriod', 'userMessagesMonth.length'), _dec18 = Ember.computed('amountOfDaysInPeriod', 'totalAccess'), _dec19 = Ember._action, (_class = class AccessFrequencyInfoComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "interactions", _descriptor4, this);
      _initializerDefineProperty(this, "endDate", _descriptor5, this);
      _initializerDefineProperty(this, "startDate", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "filter", _descriptor8, this);
      _initializerDefineProperty(this, "openFilterModal", _descriptor9, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor10, this);
      _initializerDefineProperty(this, "cantAccessDays", _descriptor11, this);
      _initializerDefineProperty(this, "showDateOnFilters", _descriptor12, this);
    }
    get accessLogsMonth() {
      let accessLogsMonth = this.args.accessLogs.filter(accessLog => {
        const accessLogDate = accessLog.get('createdAt');
        return accessLogDate.getTime() >= this.startDate.getTime() && accessLogDate.getTime() <= this.endDate.getTime();
      });
      if (accessLogsMonth.length > 1) {
        accessLogsMonth = accessLogsMonth.filter((accessLog, i, self) => {
          const accessLogDate = accessLog.get('createdAt');
          accessLogDate.setHours(0, 0, 0, 0);
          return self.findIndex(s => {
            const selfDate = s.get('createdAt');
            selfDate.setHours(0, 0, 0, 0);
            return selfDate.getTime() === accessLogDate.getTime();
          }) === i;
        });
      }
      return accessLogsMonth;
    }
    get userMessagesMonth() {
      let userMessagesMonth = this.args.userMessages.filter(userMessage => {
        const userMessageDate = new Date(userMessage.startDate);
        return userMessageDate.getTime() >= this.startDate.getTime() && userMessageDate.getTime() <= this.endDate.getTime();
      });
      if (userMessagesMonth.length > 1) {
        userMessagesMonth = userMessagesMonth.filter((userMessage, i, self) => {
          const userMessageDate = new Date(userMessage.startDate);
          userMessageDate.setHours(0, 0, 0, 0);
          return self.findIndex(s => {
            const selfDate = new Date(s.startDate);
            selfDate.setHours(0, 0, 0, 0);
            return selfDate.getTime() === userMessageDate.getTime();
          }) === i;
        });
      }
      return userMessagesMonth;
    }
    get totalAccess() {
      return this.accessLogsMonth.length;
    }
    get amountOfDaysInPeriod() {
      let count = 0;
      let curDate = new Date(this.startDate);
      while (curDate <= this.endDate) {
        const dayOfWeek = curDate.getDay();
        const canAcces = !this.cantAccessDays.includes(dayOfWeek);
        if (canAcces) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    }
    get totalAbsenceAccess() {
      return this.amountOfDaysInPeriod - this.accessLogsMonth.length - this.userMessagesMonth.length;
    }
    get accessRate() {
      const accessRate = this.totalAccess / this.amountOfDaysInPeriod * 100;
      return Math.round((accessRate + Number.EPSILON) * 100) / 100;
    }
    loadInsights(storageFilters) {
      if (storageFilters) {
        const filters = {
          ...this.filters,
          startDate: storageFilters.startDate,
          endDate: storageFilters.endDate
        };
        this.filters = filters;
        this.showDateOnFilters = true;
      }
      const today = new Date();
      this.startDate = new Date(storageFilters.startDate);
      this.endDate = new Date(storageFilters.endDate);
      if (this.endDate.getTime() > today.getTime()) {
        this.endDate = today;
      }
      this.openFilterModal = false;
    }
    *loadAccessProfile() {
      try {
        const accessProfileUser = yield this.args.user.accessProfile;
        const accessProfile = yield this.store.findRecord('access-profile', accessProfileUser.id);
        this.accessProfile = accessProfile;
        const cantAccessDays = [];
        if (this.accessProfile.closedOnSunday) {
          cantAccessDays.push(0);
        }
        if (this.accessProfile.closedOnMonday) {
          cantAccessDays.push(1);
        }
        if (this.accessProfile.closedOnTuesday) {
          cantAccessDays.push(2);
        }
        if (this.accessProfile.closedOnWednesday) {
          cantAccessDays.push(3);
        }
        if (this.accessProfile.closedOnThursday) {
          cantAccessDays.push(4);
        }
        if (this.accessProfile.closedOnFriday) {
          cantAccessDays.push(5);
        }
        if (this.accessProfile.closedOnSaturday) {
          cantAccessDays.push(6);
        }
        this.cantAccessDays = cantAccessDays;
      } catch (e) {
        Ember.debug(e);
      }
    }
    *userDetailsTask() {
      try {
        // O user que vem para o modal é um objeto proxy, ember-data: por isso yield
        let user = yield this.args.user;
        let details = yield this.fetch.request('/indicators/indicatorUserDetails', {
          host: _environment.default.apiUrl.conta,
          data: {
            userId: user.id
          }
        });
        this.interactions = details;
      } catch (e) {
        Ember.debug(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "interactions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date();
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1);
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        date: true,
        startDate: null,
        endDate: null
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "cantAccessDays", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showDateOnFilters", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "accessLogsMonth", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "accessLogsMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userMessagesMonth", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "userMessagesMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalAccess", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "totalAccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "amountOfDaysInPeriod", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "amountOfDaysInPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalAbsenceAccess", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "totalAbsenceAccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "accessRate", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "accessRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAccessProfile", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAccessProfile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userDetailsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "userDetailsTask"), _class.prototype)), _class));
  _exports.default = AccessFrequencyInfoComponent;
});