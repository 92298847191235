define("portal-talentrh/pods/view-team-member/timecard/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberTimecardController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model'), _dec5 = Ember.computed.alias('permission.companyConfig.permitImports'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class ViewTeamMemberTimecardController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "userLed", _descriptor4, this);
      _initializerDefineProperty(this, "permitImports", _descriptor5, this);
      _initializerDefineProperty(this, "competences", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "timeCard", _descriptor8, this);
      _initializerDefineProperty(this, "usersCompetences", _descriptor9, this);
      _initializerDefineProperty(this, "competenceSelectedUsers", _descriptor10, this);
      _initializerDefineProperty(this, "competenceSelected", _descriptor11, this);
      _initializerDefineProperty(this, "notFoundCompetence", _descriptor12, this);
      _initializerDefineProperty(this, "showTimeCardImported", _descriptor13, this);
      _initializerDefineProperty(this, "timeCardImports", _descriptor14, this);
      _initializerDefineProperty(this, "timeCardImportedSelected", _descriptor15, this);
      _initializerDefineProperty(this, "files", _descriptor16, this);
    }
    *getCompetences() {
      try {
        if (!this.permitImports) {
          this.clearPreview();
          const {
            competences,
            users
          } = yield this.fetch.request('timeCards/competences', {
            data: {
              users: [this.userLed.id]
            }
          });
          this.competences = Ember.A(competences);
          this.usersCompetences = Ember.A(users);
          if (!competences.length || !competences.length) {
            this.notFoundCompetence = 'Não foi encontrada nenhuma competência para o colaborador.';
          }
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *getUserReport(pdf) {
      try {
        let filters = this.getFilters(pdf);
        if (!filters.competence) {
          return this.swal.warning('Informe uma competência');
        }
        if (pdf) {
          this.swal.loading('Gerando o pdf....');
          let report = yield this.fetch.file('timeCards/timeCard', {
            data: filters
          });
          const link = document.createElement('a');
          link.href = report;
          link.download = 'cartao-ponto.pdf';
          document.body.append(link);
          link.click();
          link.remove();
          this.swal.close();
        } else {
          const report = yield this.fetch.request('timeCards/timeCard', {
            data: filters
          });
          this.timeCard = report;
          this.timeCard.reportUser = this.userLed.id;
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *loadTimeCardImports() {
      this.timeCardImports = null;
      this.timeCardImportedSelected = null;
      try {
        if (this.permitImports) {
          const timeCardImports = yield this.store.loadRecords('import-time-card', '/import-time-card/my-team', {
            data: {
              users: [this.userLed.id]
            }
          });
          if (!timeCardImports.length) {
            this.notFoundCompetence = 'Não foi encontrada nenhuma competência para o colaborador.';
          }
          this.timeCardImports = timeCardImports;
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *getFiles() {
      try {
        const files = yield this.store.loadRecords('import-time-card-file', `/import-time-card/${this.timeCardImportedSelected.id}/files/my-team`, {
          data: {
            users: [this.userLed.id]
          }
        });
        this.files = files.length ? files : [];
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *viewTimeCardImport() {
      try {
        if (!this.timeCardImportedSelected) {
          return this.swal.warning('Informe uma competência');
        }
        yield this.getFiles.perform();
        this.showTimeCardImported = true;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    getFilters() {
      let pdf = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let filter = {
        teamManagement: true
      };
      if (this.competenceSelected) {
        filter.competence = this.competenceSelected.name;
      }
      filter.user = this.userLed.id;
      filter.generatePdf = pdf;
      return filter;
    }
    clearPreview() {
      this.competences.clear();
      this.competenceSelectedUsers.clear();
      this.usersCompetences.clear();
      this.competenceSelected = null;
      this.timeCard = null;
    }
    onCompetenceSelected(competence) {
      this.competenceSelected = competence;
      this.competenceSelectedUsers.clear();
      this.timeCard = null;
    }
    onChangeCompetenceImported(competence) {
      try {
        this.showTimeCardImported = false;
        this.timeCardImportedSelected = competence;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    printUserReceipt() {
      this.getUserReport.perform(true);
    }
    previewUserReceipt() {
      this.getUserReport.perform(false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userLed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permitImports", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "competences", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "timeCard", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "usersCompetences", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "competenceSelectedUsers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "competenceSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "notFoundCompetence", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showTimeCardImported", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "timeCardImports", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "timeCardImportedSelected", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getUserReport", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getUserReport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTimeCardImports", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTimeCardImports"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFiles", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewTimeCardImport", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "viewTimeCardImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCompetenceSelected", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onCompetenceSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCompetenceImported", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCompetenceImported"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printUserReceipt", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "printUserReceipt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previewUserReceipt", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "previewUserReceipt"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberTimecardController;
});