define("portal-talentrh/pods/application/navbar/feeling/dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    actions: {
      cancel() {
        Ember.set(this, 'inSecondStep', false);
        Ember.set(this, 'selectedFeeling.factor', null);
        Ember.set(this, 'selectedFeeling.motive', "");
        Ember.set(this, 'selectedFeeling', {});
        Ember.set(this, 'showTooltip', false);
        $('.dropdown-feelings_nela-3556').dropdown('toggle');
      }
    }
  });
  _exports.default = _default;
});