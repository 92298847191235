define("portal-talentrh/pods/absence-board/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wvIb21wh",
    "block": "{\"symbols\":[],\"statements\":[[8,\"absence-board/board\",[],[[\"@filters\",\"@getFilters\",\"@from\"],[[32,0,[\"filters\"]],[30,[36,0],[[32,0,[\"getFilters\"]]],null],[32,0,[\"from\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/absence-board/index/template.hbs"
    }
  });
  _exports.default = _default;
});