define("portal-talentrh/pods/components/privacy-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8CiASLLz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"btn-sm\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"value\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"badge badge-secondary btn-sm\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-globe\"],[12],[13],[2,\"\\n    Exibido\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[12],[13],[2,\"\\n    Ocultado\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/privacy-tag/template.hbs"
    }
  });
  _exports.default = _default;
});