define("portal-talentrh/pods/components/infinite-post-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kMkqNJtW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isLoadingMore\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"skeleton/post\",[],[[\"@count\"],[4]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/infinite-post-scroll/template.hbs"
    }
  });
  _exports.default = _default;
});