define("portal-talentrh/helpers/group-image", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    groups: Ember.inject.service(),
    compute(params /*, hash*/) {
      const noGroupImage = `/images/grupo-sem-imagem.png`;
      if (!params.length) {
        Ember.debug('O componente {{group-image}} foi utilizado de maneira incorreta');
        return noGroupImage;
      }
      let group = this.groups.getById(params[0]);
      return group && group.imageUrl ? `${_environment.default.bucketUrl}${group.imageUrl}` : noGroupImage;
    }
  });
  _exports.default = _default;
});