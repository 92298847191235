define("portal-talentrh/pods/post/presences/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostPresencesRoute extends Ember.Route {
    afterModel(post) {
      if (post.type !== 'event') {
        this.transitionTo('errors.not-found');
      }
    }
  }
  _exports.default = PostPresencesRoute;
});