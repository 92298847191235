define("portal-talentrh/pods/components/indicator-viewer/performance/function-evaluation-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vdf5Z9SO",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,3],[[30,[36,2],[[32,1,[\"Header\"]],\"expected `Card.Header` to be a contextual component but found a string. Did you mean `(component Card.Header)`? ('portal-talentrh/pods/components/indicator-viewer/performance/function-evaluation-chart/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"Média notas por função\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"Body\"]],\"expected `Card.Body` to be a contextual component but found a string. Did you mean `(component Card.Body)`? ('portal-talentrh/pods/components/indicator-viewer/performance/function-evaluation-chart/template.hbs' @ L4:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"canvas\"],[24,1,\"functionEvaluationCharts\"],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"init\"]]],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"-assert-implicit-component-helper-argument\",\"component\",\"ui-card\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/performance/function-evaluation-chart/template.hbs"
    }
  });
  _exports.default = _default;
});