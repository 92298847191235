define("portal-talentrh/pods/company/minutes-of-meeting/index/print/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UHAXUmKy",
    "block": "{\"symbols\":[\"Modal\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@size\",\"@onClose\"],[true,\"lg\",[30,[36,0],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Imprimir Ata de Reunião\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"object\"],[15,\"data\",[32,0,[\"pdf\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"500\"],[14,\"title\",\"Ata de reunião\"],[14,4,\"application/pdf\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/minutes-of-meeting/index/print/template.hbs"
    }
  });
  _exports.default = _default;
});