define("portal-talentrh/pods/components/user-preview-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-preview-popup'],
    classNameBindings: ['session.showUserPreview:d-block:d-none'],
    // Services
    session: Ember.inject.service(),
    // Computed
    user: Ember.computed.alias('session.userPreview'),
    isUserId: Ember.computed('user', function () {
      return typeof this.user !== 'object';
    }),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('mouseover', () => {
        Ember.set(this, 'session.showUserPreview', true);
      }, false);
      this.element.addEventListener('mouseleave', () => {
        Ember.set(this, 'session.showUserPreview', false);
      }, false);
    }
  });
  _exports.default = _default;
});