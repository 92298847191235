define("portal-talentrh/pods/components/widgets/birthday-employees/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Eyn2VLog",
    "block": "{\"symbols\":[\"Widget\",\"Slide\",\"birthdayUser\"],\"statements\":[[8,\"widgets/item\",[],[[\"@closeWidget\",\"@idElement\",\"@itemsPerPage\",\"@hasMore\",\"@hasPaging\"],[[30,[36,0],[[32,0],\"closeWidget\"],null],\"slick-birthday\",10,[32,0,[\"hasMore\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"text-center font-weight-bold text-secondary border-0 pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"anniversary\",[30,[36,3],[[35,2,[\"showBirthdayMonth\"]],[30,[36,1],null,[[\"typeBirthday\",\"filterDefault\"],[\"personal\",\"showBirthdayMonth\"]]],[30,[36,1],null,[[\"typeBirthday\",\"filterDefault\"],[\"personal\",\"showBirthdayDay\"]]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Aniversariantes do \"],[1,[30,[36,3],[[35,2,[\"showBirthdayMonth\"]],\"mês\",\"dia\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Slide\"]],[],[[\"@task\"],[[32,0,[\"loadWidget\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Item\"]],[[24,0,\"text-center position-relative py-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"widgets/birthday-employees/birthday-user-card\",[],[[\"@birthdayUser\",\"@firstUser\"],[[32,3],[32,2,[\"First\"]]]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Empty\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Não há aniversariantes\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"Loading\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"skeleton/birthday\",[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"configuration\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/widgets/birthday-employees/template.hbs"
    }
  });
  _exports.default = _default;
});