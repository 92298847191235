define("portal-talentrh/pods/components/progressbar-indicators/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ProgressbarIndicatorsComponent = (_dec = Ember.computed('args.{backgroundColor,chartFilter,chartId,hover,percentage}'), _dec2 = Ember.computed('args.{title,total}'), (_class = class ProgressbarIndicatorsComponent extends _component.default {
    get getStyle() {
      let style = `width: ${this.args.percentage};`;
      if (this.args.chartFilter != null && this.args.chartFilter != undefined) {
        if (this.args.chartFilter?.toString() === this.args.chartId?.toString()) {
          style += `background-color: ${this.args.backgroundColor};`;
        } else {
          style += 'background-color: #CFCFCF;';
        }
      } else if (this.args.backgroundColor) {
        style += `background-color: ${this.args.backgroundColor};`;
      }
      if (this.args.hover) {
        style += 'cursor: pointer;';
      }
      return Ember.String.htmlSafe(style);
    }
    get getTitle() {
      return this.args.title ? `${this.args.title} (${this.args.total})` : '';
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "getStyle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "getStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getTitle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getTitle"), _class.prototype)), _class));
  _exports.default = ProgressbarIndicatorsComponent;
});