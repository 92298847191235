define("portal-talentrh/pods/habitus/team-insights/insights-by-period/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5LVthWq3",
    "block": "{\"symbols\":[\"Card\",\"groupBy\",\"&attrs\"],\"statements\":[[8,\"ui-card\",[[17,3],[4,[38,0],[[32,0,[\"generate\"]]],null],[4,[38,1],[[32,0,[\"generate\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[10,\"b\"],[12],[2,\"Interações por Período\"],[13]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row justify-content-between\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-6 d-inline-flex align-items-center\"],[12],[2,\"\\n        Agrupar dados por:\\n\\n        \"],[8,\"nela-select\",[],[[\"@class\",\"@searchEnabled\",\"@selected\",\"@options\",\"@onChange\"],[\"pl-2\",false,[32,0,[\"groupBy\"]],[32,0,[\"groupByOptions\"]],[30,[36,2],[[32,0,[\"changeGroupBy\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,2,[\"label\"]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"canvas\"],[14,1,\"interactionsTimelineChart\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/team-insights/insights-by-period/template.hbs"
    }
  });
  _exports.default = _default;
});