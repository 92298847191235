define("portal-talentrh/pods/human-resources/feeling/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed.alias('permission.user'), _dec13 = Ember.computed.alias('permission.companyConfig.hasAVDModule'), _dec14 = Ember.computed.alias('permission.accessProfile.allowFeelingsTodayManagement'), _dec15 = Ember.computed.alias('permission.accessProfile.admin'), _dec16 = Ember.computed('args', 'feeling.type'), _dec17 = Ember.computed('admin', 'allowFeelingsTodayManagement', 'managerAction'), _dec18 = Ember.computed('args', 'feeling.type'), _dec19 = Ember._action, _dec20 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "openActionModal", _descriptor5, this);
      _initializerDefineProperty(this, "openActionModalWithDetails", _descriptor6, this);
      _initializerDefineProperty(this, "leaderMessage", _descriptor7, this);
      _initializerDefineProperty(this, "managerMessage", _descriptor8, this);
      _initializerDefineProperty(this, "textFeelings", _descriptor9, this);
      _initializerDefineProperty(this, "leaderAction", _descriptor10, this);
      _initializerDefineProperty(this, "managerAction", _descriptor11, this);
      _initializerDefineProperty(this, "userLogged", _descriptor12, this);
      _initializerDefineProperty(this, "hasAVDModule", _descriptor13, this);
      _initializerDefineProperty(this, "allowFeelingsTodayManagement", _descriptor14, this);
      _initializerDefineProperty(this, "admin", _descriptor15, this);
    }
    get feelingIsNegative() {
      const {
        feeling
      } = this.args;
      return ['Irritado', 'Cansado', 'Entediado', 'Ansioso', 'Triste', 'Desanimado'].includes(feeling.type);
    }
    get editAction() {
      return (this.allowFeelingsTodayManagement || this.admin) && this.managerAction;
    }
    get feelingsSource() {
      const {
        feeling
      } = this.args;
      const options = {
        Feliz: 'happy.svg',
        Apaixonado: 'in-love.svg',
        Irritado: 'angry.svg',
        Cansado: 'tired.svg',
        Inteligente: 'smart.svg',
        Entediado: 'bored.svg',
        Ansioso: 'eager.svg',
        Legal: 'cool.svg',
        Triste: 'sad.svg',
        Desanimado: 'dispirited.svg',
        Divertido: 'funny.svg',
        Entusiasmado: 'excited.svg'
      };
      return options[feeling.type];
    }
    *save() {
      const {
        feeling
      } = this.args;
      try {
        if (this.managerAction) {
          this.managerAction.message = this.managerMessage;
          this.managerAction.createdBy = this.userLogged;
          if (this.managerAction.validations.isInvalid) {
            return this.swal.validation(this.managerAction.validations);
          }
          yield this.managerAction.save();
        } else {
          yield this.managerAction = yield this.store.createRecord('feeling-action', {
            createDate: new Date(),
            origin: 'humanResourses',
            message: this.managerMessage,
            feelingType: feeling.get('type'),
            createdBy: this.userLogged,
            feeling: feeling
          });
          if (this.managerAction.validations.isInvalid) {
            return this.swal.validation(this.managerAction.validations);
          }
          yield this.managerAction.save();
        }
        if (this.linkToFeedback && !this.disableFeedback) {
          const date = new Date();
          const feedback = yield this.store.createRecord('feedback', {
            finalFeedback: this.managerMessage,
            company: this.userLogged.company,
            draft: true,
            date: date,
            leaderUser: this.userLogged,
            objective: 'Conversar sobre sentimento negativo',
            status: 1,
            startTime: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
            endTime: '00:00:00',
            type: 0,
            user: feeling.user
          });
          yield feedback.save();
          this.managerAction.feedback = feedback;
          this.managerAction.linkedToFeedback = true;
          yield this.managerAction.save();
          this.linkToFeedback = true;
          this.disableFeedback = true;
        } else if (this.linkToFeedback && this.disableFeedback) {
          const feedbackId = yield this.managerAction.get('feedback');
          let feedback = yield this.store.findRecord('feedback', feedbackId.id);
          feedback.activityDescription = this.managerMessage;
          feedback.save();
        }
        this.toast.success('Ação salva com sucesso!');
        this.openActionModal = false;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *loadActionInfo() {
      try {
        const {
          feeling
        } = this.args;
        this.leaderAction = yield this.store.queryRecord('feeling-action', {
          feeling: feeling.id,
          origin: ['myTeam', 'habitus']
        });
        this.managerAction = yield this.store.queryRecord('feeling-action', {
          feeling: feeling.id,
          origin: 'humanResourses'
        });
        if (this.managerAction) {
          this.linkToFeedback = this.managerAction?.linkedToFeedback ?? null;
          this.disableFeedback = this.managerAction?.linkedToFeedback ?? null;
        }
        this.leaderMessage = this.leaderAction ? this.leaderAction.message : '';
        this.managerMessage = this.managerAction ? this.managerAction.message : '';
        const textFemale = {
          Feliz: 'Feliz',
          Apaixonado: 'Apaixonada',
          Irritado: 'Irritada',
          Cansado: 'Cansada',
          Inteligente: 'Inteligente',
          Entediado: 'Entediada',
          Ansioso: 'Ansiosa',
          Legal: 'Legal',
          Desanimado: 'Desanimada',
          Triste: 'Triste',
          Divertido: 'Divertida',
          Entusiasmado: 'Entusiasmada'
        };
        const textMale = {
          Feliz: 'Feliz',
          Apaixonado: 'Apaixonado',
          Irritado: 'Irritado',
          Cansado: 'Cansado',
          Inteligente: 'Inteligente',
          Entediado: 'Entediado',
          Ansioso: 'Ansioso',
          Legal: 'Legal',
          Desanimado: 'Desanimado',
          Triste: 'Triste',
          Divertido: 'Divertido',
          Entusiasmado: 'Entusiasmado'
        };
        this.textFeelings = feeling.userGender === 'F' ? textFemale[feeling.type] : textMale[feeling.type];
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    viewDetails() {
      this.openActionModal = true;
      this.openActionModalWithDetails = true;
    }
    closeModal() {
      this.openActionModal = false;
      this.openActionModalWithDetails = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "openActionModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openActionModalWithDetails", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "leaderMessage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "managerMessage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "textFeelings", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "leaderAction", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "managerAction", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "userLogged", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasAVDModule", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "allowFeelingsTodayManagement", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "feelingIsNegative", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "feelingIsNegative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editAction", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "editAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "feelingsSource", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "feelingsSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadActionInfo", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadActionInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewDetails", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "viewDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});