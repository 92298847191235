define("portal-talentrh/pods/components/indicators/company-profile/users-by-sex/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HJZPMbIc",
    "block": "{\"symbols\":[],\"statements\":[[11,\"canvas\"],[24,1,\"users-by-sex-pie-chart\"],[24,0,\"d-inline-block\"],[4,[38,1],[[30,[36,0],[[32,0,[\"loadChart\"]]],null]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-profile/users-by-sex/template.hbs"
    }
  });
  _exports.default = _default;
});