define("portal-talentrh/intro/feed/me/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    steps: [{
      element: '[data-step="feed-me-1"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Bem vindo ao seu espaço no Nela Social!</h6>
        <div>Aqui você poderá ver sua agenda, seus links compartilhados, histórico de registro de humor e atividades em aberto.</div>
      `
    }, {
      element: '[data-step="feed-me-2"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Links Compartilhados</h6>
        <div>Aqui você terá acesso à todos os links externos, criados por você ou compartilhados por outro usuário.</div>
      `
    }, {
      element: '[data-step="feed-me-3"]',
      position: 'left',
      intro: `
        <h6 class="font-weight-bold text-primary">Minha Agenda</h6>
        <div>Aqui será exibido um resumo dos seus compromissos, com opção para visualização da agenda completa, que te levará para o módulo Nela Agenda.</div>
      `
    }, {
      element: '[data-step="feed-me-4"]',
      position: 'left',
      intro: `
        <h6 class="font-weight-bold text-primary">Histórico de registro de humor</h6>
        <div>Aqui você poderá visualizar como está o seu humor na semana e também, navegar pelo calendário, para visualizar o seu histórico de humor.</div>
      `
    }, {
      element: '[data-step="feed-btn"]',
      position: 'left',
      intro: `
        <h6 class="font-weight-bold text-primary">Acesso ao tutorial</h6>
        <div>Surgiu mais alguma dúvida? Você pode acessar o tutorial novamente clicando aqui.</div>
      `
    }]
  };
  _exports.default = _default;
});