define("portal-talentrh/models/policy", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    order: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    pdfUrl: (0, _model.attr)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    branches: (0, _model.hasMany)('branch', {
      inverse: 'policies'
    })
  });
  _exports.default = _default;
});