define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-engagement/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementIndexRoute extends Ember.Route {
    deactivate() {
      this.controller.filters = {};
      this.controller.filtersSelected = {};
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementIndexRoute;
});