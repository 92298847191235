define("portal-talentrh/pods/external-link/edit/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    utils: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Computed
    userLink: Ember.computed.alias('model'),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      try {
        const {
          validations
        } = this.userLink;
        if (validations.isInvalid) {
          return this.swal.validation(validations);
        }
        this.userLink.link = this.userLink.link.indexOf('http') != -1 ? this.userLink.link : `https://${this.userLink.link}`;
        let userLink = this.userLink;
        yield userLink.save();
        this.swal.success('Link salvo com sucesso');
        this.send('closeModal');
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    deleteImage: (0, _emberConcurrency.task)(function* () {
      try {
        const modelId = Ember.get(this, 'userLink.id');
        const currentImageUrl = Ember.get(this, 'userLink.imageUrl');
        let result = yield this.swal.confirm('Deseja remover a imagem deste link?');
        if (!result.value) {
          return;
        }
        let userLink = yield this.ajax.delete(`userLinks/${modelId}/deleteImage`, {
          data: {
            path: currentImageUrl
          }
        });
        this.store.pushPayload(userLink);
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    uploadLinkImage: (0, _emberConcurrency.task)(function* (file) {
      try {
        let filename = file.blob.name;
        if (!file) {
          return;
        }
        const name = this.utils.generateS3Name(filename);
        const key = `user-link/${name}`;
        const ajax = this.ajax;
        const currentImageUrl = Ember.get(this, 'userLink.imageUrl');
        const modelId = Ember.get(this, 'userLink.id');
        this.swal.loading('Fazendo upload da imagem.');

        // Se a imagem já existe, remove antes.
        if (currentImageUrl) {
          yield ajax.delete(`userLinks/${modelId}/deleteImage`, {
            data: {
              path: currentImageUrl
            }
          });
        }
        let response = yield ajax.request('userLinks/putSignedLink', {
          data: {
            key
          }
        });
        yield file.uploadBinary(response.link, {
          method: 'PUT'
        });
        this.set('userLink.imageUrl', key);
        this.swal.close();
        this.userLink.save().then();
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    actions: {
      closeModal() {
        this.send('refresh');
        this.transitionToRoute('external-link');
      }
    }
  });
  _exports.default = _default;
});