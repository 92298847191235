define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-performance/pdi-details/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsHumanResourcesCompanyPerformancePdiDetailsController = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'), _dec3 = Ember._tracked, _dec4 = Ember.computed('newPerformanceModule'), _dec5 = Ember._action, (_class = class IndicatorsPeopleAnalyticsHumanResourcesCompanyPerformancePdiDetailsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "newPerformanceModule", _descriptor2, this);
      _defineProperty(this, "queryParams", ['branches', 'departments', 'jobRoles', 'jobFunctions', 'userIds', 'startAt', 'endAt']);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _defineProperty(this, "exportFields", [{
        field: 'userName',
        fieldName: 'Colaborador'
      }, {
        field: 'tradingName',
        fieldName: 'Filial'
      }, {
        field: 'department',
        fieldName: 'Departamento'
      }, {
        field: 'jobRole',
        fieldName: 'Cargo'
      }, {
        field: 'jobFunction',
        fieldName: 'Função'
      }, {
        field: 'period',
        fieldName: 'Período'
      }, {
        field: 'scoreFmt',
        fieldName: '% Atingido do PDI'
      }]);
    }
    get endpoint() {
      if (this.newPerformanceModule) {
        return {
          url: '/api/graphql/humanResources/peopleAnalyticsPdi',
          host: 'indicadores'
        };
      }
      return {
        url: '/pdiActions/findPdiProgress',
        host: 'avd'
      };
    }
    *initDetails() {
      yield (0, _emberConcurrency.timeout)(300);
      this.filters = this.buildFilters();
    }
    buildFilters() {
      const filters = {};
      if (this.startAt && this.endAt) {
        filters.startAt = this.startAt;
        filters.endAt = this.endAt;
      }
      if (this.branches) {
        filters.branchs = this.branches.split(',');
      }
      if (this.departments) {
        filters.departments = this.departments.split(',');
      }
      if (this.jobRoles) {
        filters.jobRoles = this.jobRoles.split(',');
      }
      if (this.jobFunctions) {
        filters.jobFunctions = this.jobFunctions.split(',');
      }
      if (this.userIds) {
        filters.userIds = this.userIds.split(',');
      }
      filters.type = 'indicators';
      this.filters = filters;
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "newPerformanceModule", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "endpoint", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "endpoint"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initDetails", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyPerformancePdiDetailsController;
});