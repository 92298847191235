define("portal-talentrh/pods/company/album/view-album/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['col-lg-3', 'col-md-6', 'position-relative'],
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    showModalSubtitle: false,
    admin: Ember.computed.alias('permission.admin'),
    setCoverPhoto: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, 'album.coverPhoto', this.photo.path);
        yield this.album.save();
        this.toast.success('Capa atualizada');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    removePhoto: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover foto?');
        if (value) {
          yield this.photo.destroyRecord();
          this.toast.success('Foto removida');
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.photo.save();
        this.toast.success('Legenda adicionada');
        Ember.set(this, 'showModalSubtitle', false);
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    actions: {
      closeModalSubtitle() {
        this.photo.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});