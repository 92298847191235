define("portal-talentrh/pods/components/feed/post-comments/reply-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xIikC4zl",
    "block": "{\"symbols\":[\"commentReply\",\"Content\",\"@post\",\"@onlyShow\",\"@viewCommentLikes\",\"@commentReplies\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"feed/post-comments/comment\",[],[[\"@post\",\"@comment\"],[[32,3],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"    \\n    \"],[8,[32,2,[\"body\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"likeBar\"]],[],[[\"@onlyShow\",\"@viewCommentLikes\"],[[32,4],[32,5]]],null],[2,\"\\n    \"],[8,[32,2,[\"moreOptions\"]],[],[[\"@onlyShow\"],[[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/feed/post-comments/reply-list/template.hbs"
    }
  });
  _exports.default = _default;
});