define("portal-talentrh/pods/forum/topic/show/comment-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    // Life Cycle
    didInsertElement() {
      this._super(...arguments);
      this.initModel();
    },
    // Methods
    initModel() {
      let comment = this.editComment || this.store.createRecord('comment-forum');
      Ember.set(this, 'comment', comment);
    },
    // Tasks
    createComment: (0, _emberConcurrency.task)(function* () {
      try {
        let isEdit = this.editComment;
        let comment = this.comment;
        if (!comment) {
          return;
        }
        if (!comment.text || !comment.text.length) {
          return this.toast.info('Não é possível criar um comentário em branco.');
        }
        if (!isEdit) {
          comment.set('topic', this.topic);
        }
        yield comment.save();
        if (isEdit) {
          this.send('close');
        } else {
          this.initModel();
          this.reload();
          this.comments.pushObject(comment);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      keyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
          this.createComment.perform();
          return event.preventDefault();
        }
      },
      close() {
        this.onClose();
      }
    }
  });
  _exports.default = _default;
});