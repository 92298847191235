define("portal-talentrh/pods/habitus/details/feeling/general-climate/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let GeneralClimateComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class GeneralClimateComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "openFilterModal", _descriptor, this);
      _initializerDefineProperty(this, "indicator", _descriptor2, this);
      _initializerDefineProperty(this, "led", _descriptor3, this);
      _initializerDefineProperty(this, "feelings", _descriptor4, this);
      _initializerDefineProperty(this, "filters", _descriptor5, this);
      _initializerDefineProperty(this, "showDateOnFilters", _descriptor6, this);
    }
    loadInsights(feelings, storageFilters) {
      if (storageFilters) {
        const filters = {
          ...this.filters,
          startDate: storageFilters.startDate,
          endDate: storageFilters.endDate
        };
        this.filters = filters;
        this.showDateOnFilters = true;
      }
      this.loadData(feelings);
      this.feelings = feelings;
      this.openFilterModal = false;
    }
    init() {
      const {
        feelings
      } = this.args;
      if (feelings && feelings.length) {
        this.feelings = feelings;
        this.led = feelings.firstObject.user;
        this.loadData(feelings);
      }
    }
    loadData(feelings) {
      this.indicator.data = {};
      this.indicator.data.pieChart = {};
      this.indicator.data.pieChart.data = [this.goodFeelings(feelings), this.badFeelings(feelings)];
      this.indicator.data.pieChart.labels = ['Sentimentos Positivos', 'Sentimentos Negativos'];
      this.indicator.data.feelings = feelings;
      if (this.indicator && this.indicator.data) {
        this.initChart(this.indicator);
      }
    }
    initChart(indicator) {
      let haveData = {
        labels: indicator.data.pieChart.labels,
        datasets: [{
          data: indicator.data.pieChart.data,
          backgroundColor: ['#3dbd90', '#e86a6a']
        }]
      };
      let noData = {
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#eee']
        }]
      };
      let data = indicator.data.feelings.length ? haveData : noData;
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'doughnut',
          responsive: true,
          data: data,
          options: {
            legend: {
              display: false
            }
          }
        });
      }
      this.chart = chart;
    }
    get badPercent() {
      return Math.round(this.badFeelings(this.feelings) / this.feelings.length * 100);
    }
    get goodPercent() {
      return Math.round(this.goodFeelings(this.feelings) / this.feelings.length * 100);
    }
    badFeelings(feelings) {
      let badFeelings = [];
      feelings.forEach(feeling => {
        if (feeling.type === 'Triste' || feeling.type === 'Desanimado' || feeling.type === 'Irritado' || feeling.type === 'Cansado' || feeling.type === 'Entediado' || feeling.type === 'Ansioso') {
          badFeelings.push(feeling);
        }
      });
      return badFeelings.length;
    }
    goodFeelings(feelings) {
      let goodFeelings = [];
      feelings.forEach(feeling => {
        if (feeling.type === 'Feliz' || feeling.type === 'Divertido' || feeling.type === 'Apaixonado' || feeling.type === 'Inteligente' || feeling.type === 'Entusiasmado' || feeling.type === 'Legal') {
          goodFeelings.push(feeling);
        }
      });
      return goodFeelings.length;
    }
    get firstFeeling() {
      let feelings = this.countFeelings;
      if (!feelings) return null;
      feelings = feelings ? feelings : Ember.A();
      return feelings.objectAt(0);
    }
    get secondFeeling() {
      let feelings = this.countFeelings;
      if (!feelings) return null;
      feelings = feelings ? feelings : Ember.A();
      return feelings.objectAt(1);
    }
    get thirdFeeling() {
      let feelings = this.countFeelings;
      if (!feelings) return null;
      feelings = feelings ? feelings : Ember.A();
      return feelings.objectAt(2);
    }
    get otherFeelings() {
      let feelings = this.countFeelings;
      if (!feelings) return null;
      feelings = feelings ? feelings : Ember.A();
      return feelings.slice(3);
    }
    get countFeelings() {
      let data = [];
      this.feelings.forEach(feeling => {
        if (data && data.length) {
          let i = data.findIndex(x => x.type === feeling.type);
          if (i >= 0) {
            data[i].count = data[i].count + 1;
          } else {
            data.push({
              type: feeling.type,
              count: 1,
              user: feeling.user
            });
          }
        } else {
          data.push({
            type: feeling.type,
            count: 1,
            user: feeling.user
          });
        }
      });
      data = data.sort((a, b) => b.count - a.count);
      return data;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "indicator", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "led", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "feelings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        date: true
      };
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showDateOnFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "init", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = GeneralClimateComponent;
});