define("portal-talentrh/pods/components/feed/post-comments/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showMorePostText: false,
    classNameBindings: ['commentsToView.length:comments-list:d-none'],
    commentsToView: Ember.computed('limit', 'post.comments.{isFulfilled,length}', 'post.showComments', 'redirectToPostPage', 'showMorePostText', function () {
      let showComments = this.post.showComments;
      if (!showComments) {
        return;
      }
      let comments = this.post.comments.filterBy('isResponse', null);
      return comments;
    }),
    likeCount: Ember.computed('commentToViewLikes.usersLikedId.[]', function () {
      if (!this.commentToViewLikes) {
        return '';
      }
      let likes = this.commentToViewLikes.usersLikedId.length;
      return likes > 1 ? `${likes} curtidas` : '1 curtida';
    }),
    redirectToPostPage: Ember.computed('post.type', function () {
      let type = this.post.type;
      if (type === 'vacancy' || type === 'admissionAnniversary' || type === 'birthday' || type === 'defaultPost') {
        return false;
      }
      return true;
    }),
    viewMore: Ember.computed('limit', 'post.{commentsCount,comments.[]}', function () {
      let limit = this.limit;
      let length = Math.max(this.post.commentsCount, this.post.comments.filterBy('isResponse', null).length);
      return limit && length > limit;
    }),
    actions: {
      viewCommentLikes(comment) {
        Ember.set(this, 'commentToViewLikes', comment);
      },
      toogleShowComments() {
        Ember.set(this, 'showMorePostText', !this.showMorePostText);
      }
    }
  });
  _exports.default = _default;
});