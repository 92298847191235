define("portal-talentrh/models/task-history", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    endDate: (0, _attr.default)('date'),
    startDate: (0, _attr.default)('date'),
    // Associations
    task: (0, _relationships.belongsTo)('task'),
    taskColumn: (0, _relationships.belongsTo)('taskColumn'),
    user: (0, _relationships.belongsTo)('user'),
    // Computed Properties
    time: Ember.computed('startDate', 'endDate', function () {
      let mStartDate = (0, _moment.default)(this.startDate);
      let mEndDate = (0, _moment.default)(this.endDate);
      if (!mEndDate.isValid() || !mStartDate) {
        return '';
      }
      return mEndDate.diff(mStartDate, 'minute');
    })
  });
  _exports.default = _default;
});