define("portal-talentrh/pods/components/emoji-picker/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    emojiData: Ember.A([{
      emojis: Ember.A([{
        title: 'afirmativo',
        emoji: '👍'
      }, {
        title: 'negativo',
        emoji: '👎'
      }, {
        title: 'chorando',
        emoji: '😭'
      }, {
        title: 'confuso',
        emoji: '😕'
      }, {
        title: 'rosto neutro',
        emoji: '😐'
      }, {
        title: 'rosto sorridente com olhos sorridentes',
        emoji: '😊'
      }, {
        title: 'amei',
        emoji: '😍'
      }]),
      category: ''
    }, {
      emojis: Ember.A([{
        title: 'sorriso',
        emoji: '😄'
      }, {
        title: 'risonho',
        emoji: '😃'
      }, {
        title: 'sorrindo',
        emoji: '😀'
      }, {
        title: 'rosto sorridente com olhos sorridentes',
        emoji: '😊'
      }, {
        title: 'piscadinha',
        emoji: '😉'
      }, {
        title: 'amei',
        emoji: '😍'
      }, {
        title: 'rosto jogando beijo',
        emoji: '😘'
      }, {
        title: 'rosto beijando com olhos fechados',
        emoji: '😚'
      }, {
        title: 'rosto beijando',
        emoji: '😗'
      }, {
        title: 'rosto beijando com olhos sorridentes',
        emoji: '😙'
      }, {
        title: 'rosto com língua de fora e piscando',
        emoji: '😜'
      }, {
        title: 'rosto com língua de fora e olhos bem fechados',
        emoji: '😝'
      }, {
        title: 'rosto com língua de fora',
        emoji: '😛'
      }, {
        title: 'rosto enrubescido',
        emoji: '😳'
      }, {
        title: 'rosto mostrando os dentes com olhos sorridentes',
        emoji: '😁'
      }, {
        title: 'rosto pensativo',
        emoji: '😔'
      }, {
        title: 'rosto aliviado',
        emoji: '😌'
      }, {
        title: 'rosto de desinteressado',
        emoji: '😒'
      }, {
        title: 'rosto de frustrado',
        emoji: '😞'
      }, {
        title: 'rosto de perseverante',
        emoji: '😣'
      }, {
        title: 'rosto chorando',
        emoji: '😢'
      }, {
        title: 'rosto com lágrimas de alegria',
        emoji: '😂'
      }, {
        title: 'rosto chorando alto',
        emoji: '😭'
      }, {
        title: 'rosto sonolento',
        emoji: '😪'
      }, {
        title: 'rosto com misto de decepção e alívio',
        emoji: '😥'
      }, {
        title: 'rosto com boca aberta e suando frio',
        emoji: '😰'
      }, {
        title: 'rosto sorridente com boca aberta e suando frio',
        emoji: '😅'
      }, {
        title: 'rosto suando frio',
        emoji: '😓'
      }, {
        title: 'rosto fatigado',
        emoji: '😩'
      }, {
        title: 'rosto cansado',
        emoji: '😫'
      }, {
        title: 'rosto temeroso',
        emoji: '😨'
      }, {
        title: 'rosto gritando de medo',
        emoji: '😱'
      }, {
        title: 'rosto nervoso',
        emoji: '😠'
      }, {
        title: 'rosto de desaprovação',
        emoji: '😡'
      }, {
        title: 'triunfo',
        emoji: '😤'
      }, {
        title: 'rosto desconcertado',
        emoji: '😖'
      }, {
        title: 'rosto sorridente com boca aberta e olhos bem fechados',
        emoji: '😆'
      }, {
        title: 'rosto saboreando comida deliciosa',
        emoji: '😋'
      }, {
        titleEn: 'mask',
        title: 'rosto com máscara hospitalar',
        emoji: '😷'
      }, {
        title: 'rosto sorridente com óculos de sol',
        emoji: '😎'
      }, {
        title: 'rosto dormindo',
        emoji: '😴'
      }, {
        title: 'rosto tonto',
        emoji: '😵'
      }, {
        title: 'rosto silenciado',
        emoji: '😲'
      }, {
        title: 'Rosto preocupado',
        emoji: '😟'
      }, {
        title: 'rosto franzido com boca aberta',
        emoji: '😦'
      }, {
        title: 'rosto angustiado',
        emoji: '😧'
      }, {
        title: 'duende',
        emoji: '👿'
      }, {
        title: 'rosto com boca aberta',
        emoji: '😮'
      }, {
        title: 'rosto mostrando os dentes',
        emoji: '😬'
      }, {
        title: 'rosto neutro',
        emoji: '😐'
      }, {
        title: 'rosto confuso',
        emoji: '😕'
      }, {
        title: 'rosto silenciado',
        emoji: '😯'
      }, {
        title: 'rosto com meio sorriso',
        emoji: '😏'
      }, {
        title: 'rosto sem expressão',
        emoji: '😑'
      }, {
        title: 'homem com gua pi mao',
        emoji: '👲'
      }, {
        title: 'homem com turbante',
        emoji: '👳'
      }, {
        title: 'policial',
        emoji: '👮'
      }, {
        title: 'operário de construção civil',
        emoji: '👷'
      }, {
        title: 'guarda',
        emoji: '💂'
      }, {
        title: 'bebê',
        emoji: '👶'
      }, {
        title: 'menino',
        emoji: '👦'
      }, {
        title: 'menina',
        emoji: '👧'
      }, {
        title: 'homem',
        emoji: '👨'
      }, {
        title: 'mulher',
        emoji: '👩'
      }, {
        title: 'idoso',
        emoji: '👴'
      }, {
        title: 'idosa',
        emoji: '👵'
      }, {
        title: 'pessoa, cabelos brancos',
        emoji: '👱'
      }, {
        title: 'anjo bebê',
        emoji: '👼'
      }, {
        title: 'princesa',
        emoji: '👸'
      }, {
        title: 'smiley de gato sorridente com boca aberta',
        emoji: '😺'
      }, {
        title: 'smiley de gato feliz com olhos sorridentes',
        emoji: '😸'
      }, {
        title: 'smiley de gato sorridente com olhos de coração',
        emoji: '😻'
      }, {
        title: 'smiley de gato beijando com olhos fechados',
        emoji: '😽'
      }, {
        title: 'smiley de gato com sorriso maroto',
        emoji: '😼'
      }, {
        title: 'smiley de gato fatigado',
        emoji: '🙀'
      }, {
        title: 'smiley de gato chorando',
        emoji: '😿'
      }, {
        title: 'smiley de gato com lágrimas de alegria',
        emoji: '😹'
      }, {
        title: 'smiley de gato de desaprovação',
        emoji: '😾'
      }, {
        title: 'ogro japonês',
        emoji: '👹'
      }, {
        title: 'gnomo japonês',
        emoji: '👺'
      }, {
        title: 'macaco cego',
        emoji: '🙈'
      }, {
        title: 'macaco surdo',
        emoji: '🙉'
      }, {
        title: 'macaco mudo',
        emoji: '🙊'
      }, {
        title: 'caveira',
        emoji: '💀'
      }, {
        title: 'alienígena',
        emoji: '👽'
      }, {
        title: 'merda',
        emoji: '💩'
      }, {
        title: 'fogo',
        emoji: '🔥'
      }, {
        title: 'brilhos',
        emoji: '✨'
      }, {
        title: 'estrela brilhante',
        emoji: '🌟'
      }, {
        title: 'símbolo de tontura',
        emoji: '💫'
      }, {
        title: 'símbolo de colisão',
        emoji: '💥'
      }, {
        title: 'raiva',
        emoji: '💢'
      }, {
        title: 'símbolo da gota de suor',
        emoji: '💦'
      }, {
        title: 'gota',
        emoji: '💧'
      }, {
        title: 'zzz',
        emoji: '💤'
      }, {
        title: 'símbolo de traço',
        emoji: '💨'
      }, {
        title: 'orelha',
        emoji: '👂'
      }, {
        title: 'olhos',
        emoji: '👀'
      }, {
        title: 'nariz',
        emoji: '👃'
      }, {
        title: 'língua',
        emoji: '👅'
      }, {
        title: 'boca',
        emoji: '👄'
      }, {
        title: 'afirmativo',
        emoji: '👍'
      }, {
        title: 'negativo',
        emoji: '👎'
      }, {
        title: 'sinal de mão indicando ok',
        emoji: '👌'
      }, {
        title: 'sinal de mão em punho',
        emoji: '👊'
      }, {
        title: 'punho levantado',
        emoji: '✊'
      }, {
        title: 'sinal de mão acenando',
        emoji: '👋'
      }, {
        title: 'mão levantada',
        emoji: '✋'
      }, {
        title: 'sinal de mãos abertas',
        emoji: '👐'
      }, {
        title: 'dedo indicador branco apontando para cima',
        emoji: '👆'
      }, {
        title: 'dedo indicador branco apontando para baixo',
        emoji: '👇'
      }, {
        title: 'dedo indicador branco apontando para a direita',
        emoji: '👉'
      }, {
        title: 'dedo indicador branco apontando para a esquerda',
        emoji: '👈'
      }, {
        title: 'pessoa levantando as mãos em comemoração',
        emoji: '🙌'
      }, {
        title: 'pessoa com mãos unidas em oração',
        emoji: '🙏'
      }, {
        title: 'sinal de bater palmas',
        emoji: '👏'
      }, {
        title: 'bíceps contraídos',
        emoji: '💪'
      }, {
        title: 'caminhando',
        emoji: '🚶'
      }, {
        title: 'corredor',
        emoji: '🏃'
      }, {
        title: 'dançarina',
        emoji: '💃'
      }, {
        title: 'casal',
        emoji: '👫'
      }, {
        title: 'familia',
        emoji: '👪'
      }, {
        title: 'Beijo (mulher, homem)',
        emoji: '💏'
      }, {
        title: 'casal com coração',
        emoji: '💑'
      }, {
        title: 'dançarinas',
        emoji: '👯'
      }, {
        title: 'smiley com gesto de ok',
        emoji: '🙆'
      }, {
        title: 'smiley com gesto de reprovação',
        emoji: '🙅'
      }, {
        title: 'recepcionista de balcão de informações',
        emoji: '💁'
      }, {
        title: 'pessoa feliz levantando uma mão',
        emoji: '🙋'
      }, {
        title: 'massagem facial',
        emoji: '💆'
      }, {
        title: 'corte de cabelo',
        emoji: '💇'
      }, {
        title: 'unhas',
        emoji: '💅'
      }, {
        title: 'noiva com vel',
        emoji: '👰'
      }, {
        title: 'pessoa com rosto de desaprovação',
        emoji: '🙎'
      }, {
        title: 'pessoa carrancuda',
        emoji: '🙍'
      }, {
        title: 'pessoa curvando-se bastante',
        emoji: '🙇'
      }, {
        title: 'cartola',
        emoji: '🎩'
      }, {
        title: 'coroa',
        emoji: '👑'
      }, {
        title: 'chapéu de mulher',
        emoji: '👒'
      }, {
        title: 'tênis',
        emoji: '👟'
      }, {
        title: 'sapato masculino',
        emoji: '👞'
      }, {
        title: 'sandália feminina',
        emoji: '👡'
      }, {
        title: 'sapato de salto alto',
        emoji: '👠'
      }, {
        title: 'botas femininas',
        emoji: '👢'
      }, {
        title: 'camiseta',
        emoji: '👕'
      }, {
        title: 'gravata',
        emoji: '👔'
      }, {
        title: 'roupas de mulher',
        emoji: '👚'
      }, {
        title: 'vestido',
        emoji: '👗'
      }, {
        title: 'camisa de corrida com faixa',
        emoji: '🎽'
      }, {
        title: 'calça jeans',
        emoji: '👖'
      }, {
        title: 'quimono',
        emoji: '👘'
      }, {
        title: 'biquíni',
        emoji: '👙'
      }, {
        title: 'maleta',
        emoji: '💼'
      }, {
        title: 'bolsa',
        emoji: '👜'
      }, {
        title: 'bolsa saco',
        emoji: '👝'
      }, {
        title: 'bolsinha',
        emoji: '👛'
      }, {
        title: 'oculos de grau',
        emoji: '👓'
      }, {
        title: 'laço',
        emoji: '🎀'
      }, {
        title: 'guarda-chuva fechado',
        emoji: '🌂'
      }, {
        title: 'batom',
        emoji: '💄'
      }, {
        title: 'coração amarelo',
        emoji: '💛'
      }, {
        title: 'coração azul',
        emoji: '💙'
      }, {
        title: 'coração roxo',
        emoji: '💜'
      }, {
        title: 'coração verde',
        emoji: '💚'
      }, {
        title: 'coração partido',
        emoji: '💔'
      }, {
        title: 'coração crescendo',
        emoji: '💗'
      }, {
        title: 'coração batendo',
        emoji: '💓'
      }, {
        title: 'dois corações',
        emoji: '💕'
      }, {
        title: 'coração brilhando',
        emoji: '💖'
      }, {
        title: 'coração em circulo',
        emoji: '💞'
      }, {
        title: 'coração com flecha',
        emoji: '💘'
      }, {
        title: 'carta de amor',
        emoji: '💌'
      }, {
        title: 'beijo',
        emoji: '💋'
      }, {
        title: 'anel',
        emoji: '💍'
      }, {
        title: 'diamante',
        emoji: '💎'
      }, {
        title: 'busto em silhueta',
        emoji: '👤'
      }, {
        title: 'balão de fala',
        emoji: '💬'
      }, {
        title: 'pegadas',
        emoji: '👣'
      }]),
      category: 'Pessoas'
    }, {
      emojis: Ember.A([{
        title: 'cachorro',
        emoji: '🐶'
      }, {
        title: 'lobo',
        emoji: '🐺'
      }, {
        title: 'gato',
        emoji: '🐱'
      }, {
        title: 'rato',
        emoji: '🐭'
      }, {
        title: 'hamster',
        emoji: '🐹'
      }, {
        title: 'coelho',
        emoji: '🐰'
      }, {
        title: 'sapo',
        emoji: '🐸'
      }, {
        title: 'tigre',
        emoji: '🐯'
      }, {
        title: 'coala',
        emoji: '🐨'
      }, {
        title: 'urso',
        emoji: '🐻'
      }, {
        title: 'porco',
        emoji: '🐷'
      }, {
        title: 'nariz de porco',
        emoji: '🐽'
      }, {
        title: 'vaca',
        emoji: '🐮'
      }, {
        title: 'javali',
        emoji: '🐗'
      }, {
        title: 'cara de macaco',
        emoji: '🐵'
      }, {
        title: 'macaco',
        emoji: '🐒'
      }, {
        title: 'cavalo',
        emoji: '🐴'
      }, {
        title: 'ovelha',
        emoji: '🐑'
      }, {
        title: 'Elefante',
        emoji: '🐘'
      }, {
        title: 'cara de panda',
        emoji: '🐼'
      }, {
        title: 'pinguim',
        emoji: '🐧'
      }, {
        title: 'passáro',
        emoji: '🐦'
      }, {
        title: 'pintinho',
        emoji: '🐤'
      }, {
        title: 'pintinho chocado',
        emoji: '🐥'
      }, {
        title: 'pintinho encubado',
        emoji: '🐣'
      }, {
        title: 'frango',
        emoji: '🐔'
      }, {
        title: 'cobra',
        emoji: '🐍'
      }, {
        title: 'tartaruga',
        emoji: '🐢'
      }, {
        title: 'inseto',
        emoji: '🐛'
      }, {
        title: 'abelha',
        emoji: '🐝'
      }, {
        title: 'formiga',
        emoji: '🐜'
      }, {
        title: 'besouro',
        emoji: '🐞'
      }, {
        title: 'caracol',
        emoji: '🐌'
      }, {
        title: 'polvo',
        emoji: '🐙'
      }, {
        title: 'concha',
        emoji: '🐚'
      }, {
        title: 'peixe tropical',
        emoji: '🐠'
      }, {
        title: 'peixe',
        emoji: '🐟'
      }, {
        title: 'golfinho',
        emoji: '🐬'
      }, {
        title: 'baleia',
        emoji: '🐳'
      }, {
        title: 'cavalo de corrida',
        emoji: '🐎'
      }, {
        title: 'cara de dragão',
        emoji: '🐲'
      }, {
        title: 'baiacu',
        emoji: '🐡'
      }, {
        title: 'camelo',
        emoji: '🐫'
      }, {
        title: 'poodle',
        emoji: '🐩'
      }, {
        title: 'patas',
        emoji: '🐾'
      }, {
        title: 'buquê',
        emoji: '💐'
      }, {
        title: 'flor de cerejeira',
        emoji: '🌸'
      }, {
        title: 'tulipa',
        emoji: '🌷'
      }, {
        title: 'trevo-de-quatro-folhas',
        emoji: '🍀'
      }, {
        title: 'rosa',
        emoji: '🌹'
      }, {
        title: 'girassol',
        emoji: '🌻'
      }, {
        title: 'hibisco',
        emoji: '🌺'
      }, {
        title: 'folhas secas',
        emoji: '🍁'
      }, {
        title: 'folhas',
        emoji: '🍃'
      }, {
        title: 'folhas caídas',
        emoji: '🍂'
      }, {
        title: 'erva',
        emoji: '🌿'
      }, {
        title: 'espiga de arroz',
        emoji: '🌾'
      }, {
        title: 'cogumelo',
        emoji: '🍄'
      }, {
        title: 'cacto',
        emoji: '🌵'
      }, {
        title: 'palmeira',
        emoji: '🌴'
      }, {
        titleEn: 'chestnut',
        title: 'castanha',
        emoji: '🌰'
      }, {
        title: 'plantinha',
        emoji: '🌱'
      }, {
        title: 'flor',
        emoji: '🌼'
      }, {
        title: 'lua nova',
        emoji: '🌑'
      }, {
        title: 'lua do primeiro trimestre',
        emoji: '🌓'
      }, {
        title: 'lua',
        emoji: '🌔'
      }, {
        title: 'lua cheia',
        emoji: '🌕'
      }, {
        title: 'lua do primeiro trimestre com o rosto',
        emoji: '🌛'
      }, {
        title: 'lua crescente',
        emoji: '🌙'
      }, {
        title: 'terra da ásia',
        emoji: '🌏'
      }, {
        title: 'vulcão',
        emoji: '🌋'
      }, {
        title: 'via láctea',
        emoji: '🌌'
      }, {
        title: 'Estrelas',
        emoji: '🌠'
      }, {
        title: 'parcialmente ensolarado',
        emoji: '⛅'
      }, {
        title: 'boneco de neve',
        emoji: '⛄'
      }, {
        title: 'ciclone',
        emoji: '🌀'
      }, {
        title: 'nebuloso',
        emoji: '🌁'
      }, {
        title: 'Arco iris',
        emoji: '🌈'
      }, {
        title: 'oceano',
        emoji: '🌊'
      }]),
      category: 'Animais e natureza'
    }, {
      emojis: Ember.A([{
        title: 'bambu',
        emoji: '🎍'
      }, {
        title: 'coração presente',
        emoji: '💝'
      }, {
        title: 'bonecas',
        emoji: '🎎'
      }, {
        title: 'mochila escolar',
        emoji: '🎒'
      }, {
        title: 'capelo',
        emoji: '🎓'
      }, {
        title: 'bandeiras',
        emoji: '🎏'
      }, {
        title: 'fogos de artificio',
        emoji: '🎆'
      }, {
        title: 'estrelinhas',
        emoji: '🎇'
      }, {
        title: 'carrilhão de vento',
        emoji: '🎐'
      }, {
        title: 'cerimonia de contemplação da lua otsukimi',
        emoji: '🎑'
      }, {
        title: 'jack o lanterna',
        emoji: '🎃'
      }, {
        title: 'fantasma',
        emoji: '👻'
      }, {
        title: 'papai noel',
        emoji: '🎅'
      }, {
        title: 'arvore de natal',
        emoji: '🎄'
      }, {
        title: 'presente',
        emoji: '🎁'
      }, {
        title: 'arvore tanabata',
        emoji: '🎋'
      }, {
        title: 'lança confetis',
        emoji: '🎉'
      }, {
        title: 'bolas de confetis',
        emoji: '🎊'
      }, {
        title: 'balão',
        emoji: '🎈'
      }, {
        title: 'bandeiras cruzadas',
        emoji: '🎌'
      }, {
        title: 'bola de cristal',
        emoji: '🔮'
      }, {
        title: 'câmera cinematográfica',
        emoji: '🎥'
      }, {
        title: 'camera',
        emoji: '📷'
      }, {
        title: 'filmadora',
        emoji: '📹'
      }, {
        title: 'videocassete',
        emoji: '📼'
      }, {
        title: 'cd',
        emoji: '💿'
      }, {
        title: 'dvd',
        emoji: '📀'
      }, {
        title: 'minidisco',
        emoji: '💽'
      }, {
        title: 'disquete',
        emoji: '💾'
      }, {
        title: 'notebook',
        emoji: '💻'
      }, {
        title: 'celular',
        emoji: '📱'
      }, {
        title: 'telefone',
        emoji: '📞'
      }, {
        title: 'pager',
        emoji: '📟'
      }, {
        title: 'fax',
        emoji: '📠'
      }, {
        title: 'satélite',
        emoji: '📡'
      }, {
        title: 'tv',
        emoji: '📺'
      }, {
        title: 'rádio',
        emoji: '📻'
      }, {
        title: 'som alto',
        emoji: '🔊'
      }, {
        title: 'sino',
        emoji: '🔔'
      }, {
        title: 'Alto-falante',
        emoji: '📢'
      }, {
        title: 'megafone',
        emoji: '📣'
      }, {
        title: 'Ampulheta areia fluindo',
        emoji: '⏳'
      }, {
        title: 'ampulheta',
        emoji: '⌛'
      }, {
        title: 'despertador',
        emoji: '⏰'
      }, {
        title: 'relógio digital',
        emoji: '⌚'
      }, {
        title: 'cadeado desbloqueado',
        emoji: '🔓'
      }, {
        title: 'cadeado bloqueado',
        emoji: '🔒'
      }, {
        title: 'cadeado com caneta-tinteiro',
        emoji: '🔏'
      }, {
        title: 'cadeado fechado com chave',
        emoji: '🔐'
      }, {
        title: 'chave',
        emoji: '🔑'
      }, {
        title: 'lente de aumento virada para direita',
        emoji: '🔎'
      }, {
        title: 'lâmpada',
        emoji: '💡'
      }, {
        title: 'lanterna',
        emoji: '🔦'
      }, {
        title: 'tomada elétrica',
        emoji: '🔌'
      }, {
        title: 'bateria',
        emoji: '🔋'
      }, {
        title: 'lente de aumento virado para esquerda',
        emoji: '🔍'
      }, {
        title: 'banho',
        emoji: '🛀'
      }, {
        title: 'vaso sanitário',
        emoji: '🚽'
      }, {
        title: 'chave inglesa',
        emoji: '🔧'
      }, {
        title: 'porca e parafuso',
        emoji: '🔩'
      }, {
        title: 'martelo',
        emoji: '🔨'
      }, {
        title: 'porta',
        emoji: '🚪'
      }, {
        title: 'simbolo de fumante',
        emoji: '🚬'
      }, {
        title: 'bomba',
        emoji: '💣'
      }, {
        title: 'pistola',
        emoji: '🔫'
      }, {
        title: 'facão',
        emoji: '🔪'
      }, {
        title: 'comprimido',
        emoji: '💊'
      }, {
        title: 'seringa',
        emoji: '💉'
      }, {
        title: 'saco de dinheiro',
        emoji: '💰'
      }, {
        title: 'nota bancária com sinal de iene',
        emoji: '💴'
      }, {
        title: 'nota bancária com sinal de dólar',
        emoji: '💵'
      }, {
        title: 'cartão de crédito',
        emoji: '💳'
      }, {
        title: 'dinheiro com asas',
        emoji: '💸'
      }, {
        title: 'celular com seta para direita',
        emoji: '📲'
      }, {
        title: 'email',
        emoji: '📧'
      }, {
        title: 'bandeja de caixa de entrada',
        emoji: '📥'
      }, {
        title: 'bandeja de caixa de saida',
        emoji: '📤'
      }, {
        title: 'Envelope com seta pra baixo',
        emoji: '📩'
      }, {
        title: 'Envelope recebido',
        emoji: '📨'
      }, {
        title: 'caixa do correio',
        emoji: '📫'
      }, {
        title: 'caixa do correio fechada',
        emoji: '📪'
      }, {
        title: 'caixa do correio',
        emoji: '📮'
      }, {
        title: 'pacote',
        emoji: '📦'
      }, {
        title: 'memorando',
        emoji: '📝'
      }, {
        title: 'página voltada para cima',
        emoji: '📄'
      }, {
        title: 'página com curva',
        emoji: '📃'
      }, {
        title: 'guias de favoritos',
        emoji: '📑'
      }, {
        title: 'gráfico de barras',
        emoji: '📊'
      }, {
        title: 'gráfico com tendência ascendente',
        emoji: '📈'
      }, {
        title: 'gráfico com tendência descendente',
        emoji: '📉'
      }, {
        title: 'pergaminho',
        emoji: '📜'
      }, {
        title: 'prancheta',
        emoji: '📋'
      }, {
        title: 'calendário destacável',
        emoji: '📅'
      }, {
        title: 'calendário',
        emoji: '📆'
      }, {
        title: 'Índice de cartões',
        emoji: '📇'
      }, {
        title: 'pasta de arquivos',
        emoji: '📁'
      }, {
        title: 'pasta de arquivos aberta',
        emoji: '📂'
      }, {
        title: 'Alfinete',
        emoji: '📌'
      }, {
        title: 'clipe de papel',
        emoji: '📎'
      }, {
        title: 'régua reta',
        emoji: '📏'
      }, {
        title: 'régua triangular',
        emoji: '📐'
      }, {
        title: 'livro fechado',
        emoji: '📕'
      }, {
        title: 'livro verde',
        emoji: '📗'
      }, {
        title: 'livro azul',
        emoji: '📘'
      }, {
        title: 'livro laranja',
        emoji: '📙'
      }, {
        title: 'bloco de anotações',
        emoji: '📓'
      }, {
        title: 'bloco de anotações com capa decorativa',
        emoji: '📔'
      }, {
        title: 'livro-razão',
        emoji: '📒'
      }, {
        title: 'livros',
        emoji: '📚'
      }, {
        title: 'livro',
        emoji: '📖'
      }, {
        title: 'marca páginas',
        emoji: '🔖'
      }, {
        title: 'etiqueta',
        emoji: '📛'
      }, {
        title: 'jornal',
        emoji: '📰'
      }, {
        title: 'aquarela',
        emoji: '🎨'
      }, {
        title: 'badalo',
        emoji: '🎬'
      }, {
        title: 'microfone',
        emoji: '🎤'
      }, {
        title: 'fone de ouvido',
        emoji: '🎧'
      }, {
        title: 'partitura',
        emoji: '🎼'
      }, {
        title: 'nota musical',
        emoji: '🎵'
      }, {
        title: 'notas musicais',
        emoji: '🎶'
      }, {
        title: 'teclado musical',
        emoji: '🎹'
      }, {
        title: 'violino',
        emoji: '🎻'
      }, {
        title: 'trompete',
        emoji: '🎺'
      }, {
        title: 'saxofone',
        emoji: '🎷'
      }, {
        title: 'guitarra',
        emoji: '🎸'
      }, {
        title: 'monstro alienigena',
        emoji: '👾'
      }, {
        title: 'video game',
        emoji: '🎮'
      }, {
        title: 'coringa preto',
        emoji: '🃏'
      }, {
        title: 'baralho de flores',
        emoji: '🎴'
      }, {
        title: 'mahjong (racha cuca)',
        emoji: '🀄'
      }, {
        title: 'dado',
        emoji: '🎲'
      }, {
        title: 'dardo',
        emoji: '🎯'
      }, {
        title: 'futebol americano',
        emoji: '🏈'
      }, {
        title: 'basquete',
        emoji: '🏀'
      }, {
        title: 'futebol',
        emoji: '⚽'
      }, {
        title: 'basebol',
        emoji: '⚾'
      }, {
        title: 'tenis',
        emoji: '🎾'
      }, {
        title: 'sinuca',
        emoji: '🎱'
      }, {
        title: 'boliche',
        emoji: '🎳'
      }, {
        title: 'golfe',
        emoji: '⛳'
      }, {
        title: 'bandeira de chegada',
        emoji: '🏁'
      }, {
        title: 'troféu',
        emoji: '🏆'
      }, {
        titleEn: 'ski',
        title: 'ski',
        emoji: '🎿'
      }, {
        title: 'snowboarder',
        emoji: '🏂'
      }, {
        title: 'nadador',
        emoji: '🏊'
      }, {
        title: 'surf',
        emoji: '🏄'
      }, {
        title: 'vara de pescar e peixe',
        emoji: '🎣'
      }, {
        title: 'chá',
        emoji: '🍵'
      }, {
        title: 'sake',
        emoji: '🍶'
      }, {
        title: 'cerveja',
        emoji: '🍺'
      }, {
        title: 'cervejas',
        emoji: '🍻'
      }, {
        title: 'coquetel',
        emoji: '🍸'
      }, {
        title: 'bebida tropical',
        emoji: '🍹'
      }, {
        title: 'taça de vinho',
        emoji: '🍷'
      }, {
        title: 'talheres',
        emoji: '🍴'
      }, {
        title: 'pizza',
        emoji: '🍕'
      }, {
        title: 'hámburger',
        emoji: '🍔'
      }, {
        title: 'batata frita',
        emoji: '🍟'
      }, {
        title: 'carne de ave',
        emoji: '🍗'
      }, {
        title: 'carne com osso',
        emoji: '🍖'
      }, {
        title: 'Espaguete',
        emoji: '🍝'
      }, {
        title: 'curry',
        emoji: '🍛'
      }, {
        titleEn: 'fried_srimp',
        title: 'camarão',
        emoji: '🍤'
      }, {
        title: 'bento',
        emoji: '🍱'
      }, {
        title: 'sushi',
        emoji: '🍣'
      }, {
        title: 'bolo de peixe',
        emoji: '🍥'
      }, {
        title: 'bolinho de arroz',
        emoji: '🍙'
      }, {
        title: 'bolacha de arroz japonês',
        emoji: '🍘'
      }, {
        title: 'arroz cozido',
        emoji: '🍚'
      }, {
        title: 'ramen',
        emoji: '🍜'
      }, {
        title: 'tigela de comida',
        emoji: '🍲'
      }, {
        title: 'oden',
        emoji: '🍢'
      }, {
        title: 'dango',
        emoji: '🍡'
      }, {
        title: 'ovos',
        emoji: '🍳'
      }, {
        title: 'pão',
        emoji: '🍞'
      }, {
        title: 'rosquinha',
        emoji: '🍩'
      }, {
        title: 'pudim',
        emoji: '🍮'
      }, {
        title: 'sorvete de massa',
        emoji: '🍦'
      }, {
        title: 'sorvete',
        emoji: '🍨'
      }, {
        title: 'raspadinha',
        emoji: '🍧'
      }, {
        title: 'bolo de aniversário',
        emoji: '🎂'
      }, {
        title: 'torta',
        emoji: '🍰'
      }, {
        title: 'cookie',
        emoji: '🍪'
      }, {
        title: 'barra de chocolate',
        emoji: '🍫'
      }, {
        title: 'doce',
        emoji: '🍬'
      }, {
        title: 'pirulito',
        emoji: '🍭'
      }, {
        title: 'pote de mel',
        emoji: '🍯'
      }, {
        title: 'maça',
        emoji: '🍎'
      }, {
        title: 'maça verde',
        emoji: '🍏'
      }, {
        title: 'tangerina',
        emoji: '🍊'
      }, {
        title: 'cerejas',
        emoji: '🍒'
      }, {
        title: 'uvas',
        emoji: '🍇'
      }, {
        title: 'melância',
        emoji: '🍉'
      }, {
        title: 'morango',
        emoji: '🍓'
      }, {
        title: 'pêssego',
        emoji: '🍑'
      }, {
        title: 'melão',
        emoji: '🍈'
      }, {
        title: 'banana',
        emoji: '🍌'
      }, {
        title: 'abacaxi',
        emoji: '🍍'
      }, {
        title: 'batata doce',
        emoji: '🍠'
      }, {
        title: 'berinjela',
        emoji: '🍆'
      }, {
        title: 'tomate',
        emoji: '🍅'
      }, {
        title: 'milho',
        emoji: '🌽'
      }]),
      category: 'Objetos'
    }, {
      emojis: Ember.A([{
        title: 'casa',
        emoji: '🏠'
      }, {
        title: 'casa com jardim',
        emoji: '🏡'
      }, {
        title: 'Escola',
        emoji: '🏫'
      }, {
        title: 'Escritório',
        emoji: '🏢'
      }, {
        title: 'Agência dos correios',
        emoji: '🏣'
      }, {
        title: 'hospital',
        emoji: '🏥'
      }, {
        title: 'banco',
        emoji: '🏦'
      }, {
        title: 'loja de conveniência',
        emoji: '🏪'
      }, {
        title: 'motel',
        emoji: '🏩'
      }, {
        title: 'hotel',
        emoji: '🏨'
      }, {
        title: 'casamento',
        emoji: '💒'
      }, {
        title: 'igreja',
        emoji: '⛪'
      }, {
        title: 'loja de departamento',
        emoji: '🏬'
      }, {
        title: 'pôr do sol nos prédios',
        emoji: '🌆'
      }, {
        title: 'castelo japonês',
        emoji: '🏯'
      }, {
        title: 'castelo europeu',
        emoji: '🏰'
      }, {
        title: 'barraca',
        emoji: '⛺'
      }, {
        title: 'fábrica',
        emoji: '🏭'
      }, {
        title: 'torre de tóquio',
        emoji: '🗼'
      }, {
        title: 'silhueta do japão',
        emoji: '🗾'
      }, {
        title: 'monte fuji',
        emoji: '🗻'
      }, {
        title: 'nascer do sol nas montanhas',
        emoji: '🌄'
      }, {
        title: 'nascer do sol',
        emoji: '🌅'
      }, {
        title: 'noite estrelada',
        emoji: '🌃'
      }, {
        title: 'Estátua da liberdade',
        emoji: '🗽'
      }, {
        title: 'ponte à noite',
        emoji: '🌉'
      }, {
        title: 'carrossel de cavalinho',
        emoji: '🎠'
      }, {
        title: 'roda-gigante',
        emoji: '🎡'
      }, {
        title: 'fonte de agua',
        emoji: '⛲'
      }, {
        title: 'montanha russa',
        emoji: '🎢'
      }, {
        title: 'navio',
        emoji: '🚢'
      }, {
        title: 'barco a vela',
        emoji: '⛵'
      }, {
        title: 'lancha',
        emoji: '🚤'
      }, {
        title: 'foguete',
        emoji: '🚀'
      }, {
        title: 'assento',
        emoji: '💺'
      }, {
        title: 'Estação',
        emoji: '🚉'
      }, {
        title: 'trem de alta velocidade',
        emoji: '🚄'
      }, {
        title: 'trem-bala de alta velocidade',
        emoji: '🚅'
      }, {
        title: 'metrô',
        emoji: '🚇'
      }, {
        title: 'trem de montanha',
        emoji: '🚃'
      }, {
        title: 'onibus',
        emoji: '🚌'
      }, {
        title: 'carro',
        emoji: '🚙'
      }, {
        title: 'carro vermelho',
        emoji: '🚗'
      }, {
        title: 'táxi',
        emoji: '🚕'
      }, {
        title: 'caminhão',
        emoji: '🚚'
      }, {
        title: 'giroflex de viaturas policiais',
        emoji: '🚨'
      }, {
        title: 'viatura policial',
        emoji: '🚓'
      }, {
        title: 'caminhão de bombeiros',
        emoji: '🚒'
      }, {
        title: 'ambulância',
        emoji: '🚑'
      }, {
        title: 'bicicleta',
        emoji: '🚲'
      }, {
        title: 'barbeiro',
        emoji: '💈'
      }, {
        title: 'ponto de ônibus',
        emoji: '🚏'
      }, {
        title: 'passagem',
        emoji: '🎫'
      }, {
        title: 'semaforo horizontal',
        emoji: '🚥'
      }, {
        title: 'placa de obras',
        emoji: '🚧'
      }, {
        title: 'principiante',
        emoji: '🔰'
      }, {
        title: 'bomba de combustivel',
        emoji: '⛽'
      }, {
        title: 'izakaya lanterna',
        emoji: '🏮'
      }, {
        title: 'caça-niques',
        emoji: '🎰'
      }, {
        title: 'moyai',
        emoji: '🗿'
      }, {
        title: 'circo',
        emoji: '🎪'
      }, {
        title: 'arte circense',
        emoji: '🎭'
      }, {
        title: 'alfinete redondo',
        emoji: '📍'
      }, {
        title: 'bandeira triangular',
        emoji: '🚩'
      }]),
      category: 'Viagem e locais'
    }, {
      emojis: Ember.A([{
        title: 'tecla do dez',
        emoji: '🔟'
      }, {
        title: '1234',
        emoji: '🔢'
      }, {
        title: 'símbolo de entrada de símbolos',
        emoji: '🔣'
      }, {
        title: 'símbolo de entrada de letras latinas maiúsculas',
        emoji: '🔠'
      }, {
        title: 'símbolo de entrada de letras latinas minúsculas',
        emoji: '🔡'
      }, {
        title: 'símbolo de entrada de letras latinas',
        emoji: '🔤'
      }, {
        title: 'triângulo pequeno vermelho apontando para cima',
        emoji: '🔼'
      }, {
        title: 'triângulo pequeno vermelho apontando para baixo',
        emoji: '🔽'
      }, {
        title: 'triângulo duplo preto para a esquerda',
        emoji: '⏪'
      }, {
        title: 'triângulo duplo preto para a direita',
        emoji: '⏩'
      }, {
        title: 'triângulo duplo preto para cima',
        emoji: '⏫'
      }, {
        title: 'triângulo duplo preto para baixo',
        emoji: '⏬'
      }, {
        title: '"ok" em letras de fôrma',
        emoji: '🆗'
      }, {
        title: '"new" em letras de fôrma',
        emoji: '🆕'
      }, {
        title: '"up!" em letras de fôrma',
        emoji: '🆙'
      }, {
        title: '"cool" em letras de fôrma',
        emoji: '🆒'
      }, {
        title: '"‘free" em letras de fôrma',
        emoji: '🆓'
      }, {
        title: '"ng" em letras de fôrma',
        emoji: '🆖'
      }, {
        title: 'antena com barras',
        emoji: '📶'
      }, {
        title: 'cinema',
        emoji: '🎦'
      }, {
        title: 'caracteres koko em katakana contidos em quadrado',
        emoji: '🈁'
      }, {
        title: 'ideograma "lugar reservado" contido em quadrado',
        emoji: '🈯'
      }, {
        title: 'ideograma "vaga" contido em quadrado',
        emoji: '🈳'
      }, {
        title: 'ideograma "segredo" contido em círculo',
        emoji: '🈵'
      }, {
        title: 'ideograma "aceitação" contido em quadrado',
        emoji: '🈴'
      }, {
        title: 'ideograma "proibição" contido em quadrado',
        emoji: '🈲'
      }, {
        title: 'ideograma "vantagem" contido em círculo',
        emoji: '🉐'
      }, {
        title: 'ideograma "desconto" contido em quadrado',
        emoji: '🈹'
      }, {
        title: 'ideograma "em atividade" contido em quadrado',
        emoji: '🈺'
      }, {
        title: 'ideograma "pago" contido em quadrado',
        emoji: '🈶'
      }, {
        title: 'ideograma "gratuito" contido em quadrado',
        emoji: '🈚'
      }, {
        title: 'banheiro',
        emoji: '🚻'
      }, {
        title: 'símbolo masculino',
        emoji: '🚹'
      }, {
        title: 'símbolo feminino',
        emoji: '🚺'
      }, {
        title: 'símbolo de bebê',
        emoji: '🚼'
      }, {
        title: 'wc',
        emoji: '🚾'
      }, {
        title: 'símbolo de proibido fumar',
        emoji: '🚭'
      }, {
        title: 'ideograma "aplicação" contido em quadrado',
        emoji: '🈸'
      }, {
        title: 'ideograma "aceitar" contido em círculo',
        emoji: '🉑'
      }, {
        title: '"cl" em letras de fôrma',
        emoji: '🆑'
      }, {
        title: '"sos" em letras de fôrma',
        emoji: '🆘'
      }, {
        title: '"id" em letras de fôrma',
        emoji: '🆔'
      }, {
        title: 'sinal de entrada proibida',
        emoji: '🚫'
      }, {
        title: 'símbolo de proibido para menores de 18',
        emoji: '🔞'
      }, {
        title: 'Entrada proíbida',
        emoji: '⛔'
      }, {
        title: 'negativo de sinal de cruzamento contido em quadrado',
        emoji: '❎'
      }, {
        title: 'marca de verificação grossa branca',
        emoji: '✅'
      }, {
        title: 'decoração de coração',
        emoji: '💟'
      }, {
        title: 'vs',
        emoji: '🆚'
      }, {
        title: 'modo vibração',
        emoji: '📳'
      }, {
        title: 'celular desligado',
        emoji: '📴'
      }, {
        title: 'tipo sanguíneo Ab',
        emoji: '🆎'
      }, {
        title: 'forma de losango com ponto interno',
        emoji: '💠'
      }, {
        title: 'serpentário',
        emoji: '⛎'
      }, {
        title: 'Estrela de davi',
        emoji: '🔯'
      }, {
        title: 'caixa eletrônico',
        emoji: '🏧'
      }, {
        title: 'gráfico com tendência ascendente e símbolo de iene',
        emoji: '💹'
      }, {
        title: 'cifrão dólar',
        emoji: '💲'
      }, {
        title: 'câmbio de moeda',
        emoji: '💱'
      }, {
        title: 'sinal de cruzamento',
        emoji: '❌'
      }, {
        title: 'símbolo de ponto de exclamação vermelho',
        emoji: '❗'
      }, {
        title: 'ornamento de ponto de interrogação vermelho',
        emoji: '❓'
      }, {
        title: 'símbolo de ponto de exclamação cinza',
        emoji: '❕'
      }, {
        title: 'ornamento de ponto de interrogação cinza',
        emoji: '❔'
      }, {
        title: 'círculo grande grosso',
        emoji: '⭕'
      }, {
        title: '"top" com seta para cima',
        emoji: '🔝'
      }, {
        title: '"End" com seta para a esquerda',
        emoji: '🔚'
      }, {
        title: '"back" com seta para esquerda',
        emoji: '🔙'
      }, {
        title: '"on!" com seta para a esquerda e para a direita',
        emoji: '🔛'
      }, {
        title: '"soon" com seta para a direita',
        emoji: '🔜'
      }, {
        title: 'setas para baixo e para cima em sentido horário',
        emoji: '🔃'
      }, {
        title: 'relógio mostrando 12h00',
        emoji: '🕛'
      }, {
        title: 'relógio mostrando 01h00',
        emoji: '🕐'
      }, {
        title: 'relógio mostrando 02h00',
        emoji: '🕑'
      }, {
        title: 'relógio mostrando 03h00',
        emoji: '🕒'
      }, {
        title: 'relógio mostrando 04h00',
        emoji: '🕓'
      }, {
        title: 'relógio mostrando 05h00',
        emoji: '🕔'
      }, {
        title: 'relógio mostrando 06h00',
        emoji: '🕕'
      }, {
        title: 'relógio mostrando 07h00',
        emoji: '🕖'
      }, {
        title: 'relógio mostrando 08h00',
        emoji: '🕗'
      }, {
        title: 'relógio mostrando 09h00',
        emoji: '🕘'
      }, {
        title: 'relógio mostrando 10h00',
        emoji: '🕙'
      }, {
        title: 'relógio mostrando 11h00',
        emoji: '🕚'
      }, {
        title: 'sinal de adição grosso',
        emoji: '➕'
      }, {
        title: 'sinal de subtração grosso',
        emoji: '➖'
      }, {
        title: 'sinal de divisão grosso',
        emoji: '➗'
      }, {
        title: 'flor branca',
        emoji: '💮'
      }, {
        title: 'símbolo de cem pontos',
        emoji: '💯'
      }, {
        title: 'botão de opção',
        emoji: '🔘'
      }, {
        title: 'link',
        emoji: '🔗'
      }, {
        title: 'laço encaracolado',
        emoji: '➰'
      }, {
        title: 'tridente',
        emoji: '🔱'
      }, {
        title: 'triângulo vermelho apontando para cima',
        emoji: '🔺'
      }, {
        title: 'botão quadrado preto',
        emoji: '🔲'
      }, {
        title: 'botão quadrado branco',
        emoji: '🔳'
      }, {
        title: 'círculo grande vermelho',
        emoji: '🔴'
      }, {
        title: 'círculo grande azul',
        emoji: '🔵'
      }, {
        title: 'triângulo vermelho apontando para baixo',
        emoji: '🔻'
      }, {
        title: 'quadrado grande branco',
        emoji: '⬜'
      }, {
        title: 'quadrado grande preto',
        emoji: '⬛'
      }, {
        title: 'losango grande laranja',
        emoji: '🔶'
      }, {
        title: 'losango grande azul',
        emoji: '🔷'
      }, {
        title: 'losango pequeno laranja',
        emoji: '🔸'
      }, {
        title: 'losango pequeno azul',
        emoji: '🔹'
      }]),
      category: 'Símbolos'
    }]),
    didInsertElement() {
      this._super(...arguments);
      this.initEvents();
    },
    willDestroyElement() {
      this._super(...arguments);
      let input = this.getInput();
      if (!input) {
        return;
      }
      input.removeEventListener('click', null, false);
    },
    initEvents() {
      let input = this.getInput();
      if (!input) {
        return;
      }
      input.addEventListener('click', e => {
        Ember.set(this, 'selectionStart', e.target.selectionStart);
      }, false);
      input.addEventListener('keyup', e => {
        Ember.set(this, 'selectionStart', e.target.selectionStart);
        this.send('closePicker');
      }, false);
    },
    getInput() {
      return document.getElementById(this.inputId);
    },
    actions: {
      openPicker() {
        event.stopPropagation();
        Ember.set(this, 'open', true);
      },
      closePicker() {
        Ember.set(this, 'open', false);
      },
      selectEmoji(emojiItem) {
        let input = this.getInput();
        let value = this.value || '';
        let selectionStart = this.selectionStart;
        let position = selectionStart || value.length;
        if (!input) {
          return;
        }

        // Insere o emoji na posição do cursor.
        value = value.slice(0, position) + emojiItem.emoji + value.slice(position);

        // Caso tenha a action onSelectEmoji, envia o emoji para a mesma
        if (this.onSelectEmoji) {
          this.onSelectEmoji(emojiItem);
        }
        if (this.maxLength) {
          if (value.length <= this.maxLength) {
            Ember.set(this, 'value', value);
          }
        } else {
          Ember.set(this, 'value', value);
        }

        // Atualiza a posição do próximo emoji para não inserir atrás do anterior.
        Ember.set(this, 'selectionStart', selectionStart + 2);

        // Retorna o foco para o input
        input.focus();
      },
      searchEmoji(value) {
        if (value !== '') {
          (0, _jquery.default)(".nela-emoji-picker-emoji:not([title*='" + value + "'])").hide();
        } else {
          (0, _jquery.default)('.nela-emoji-picker-emoji').show();
        }
      }
    }
  });
  _exports.default = _default;
});