define("portal-talentrh/pods/notification/share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dE3kQuRU",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"@click\",\"@notification\"],\"statements\":[[10,\"div\"],[14,0,\"row row w-100 m-auto h-vh-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-9 d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex cursor-pointer\"],[15,\"onclick\",[30,[36,1],[[32,0],\"viewAndSetAsRead\"],null]],[12],[2,\"\\n      \"],[8,\"user-attribute\",[],[[\"@userId\"],[[32,4,[\"relatedUser\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,[32,2,[\"Img\"]],[[24,0,\"avatar-6\"],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"px-3\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n            \"],[1,[32,1,[\"fullName\"]]],[2,\" compartilhou sua publicação:\\n          \"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-3 d-flex flex-column align-items-end justify-content-between\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"mt-5\"],[12],[1,[30,[36,2],[[32,4,[\"updatedAt\"]]],null]],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"navbar__dropdown__item__unread navbar__dropdown__item__unread\"],[14,\"title\",\"Marcar como lida\"],[15,\"onclick\",[30,[36,0],[[32,3]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"action\",\"moment-from-now\",\"isRead\",\"unless\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/notification/share/template.hbs"
    }
  });
  _exports.default = _default;
});