define("portal-talentrh/pods/human-resources/sent-signatures/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HumanResourcesSentSignaturesController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class HumanResourcesSentSignaturesController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "competences", _descriptor5, this);
      _initializerDefineProperty(this, "competence", _descriptor6, this);
      _initializerDefineProperty(this, "competencesToSentSignature", _descriptor7, this);
      _initializerDefineProperty(this, "competenceToSentSignature", _descriptor8, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor9, this);
      _initializerDefineProperty(this, "typeDocument", _descriptor10, this);
      _initializerDefineProperty(this, "status", _descriptor11, this);
      _initializerDefineProperty(this, "showSentDocumentModal", _descriptor12, this);
      _defineProperty(this, "typeDocumentOptions", [{
        label: 'Cartão Ponto',
        value: 'timecard'
      }]);
      _defineProperty(this, "dataFields", [{
        field: 'type',
        fieldName: 'Documento'
      }, {
        field: 'competence',
        fieldName: 'Competência'
      }, {
        field: 'userName',
        fieldName: 'Usuário'
      }, {
        field: 'sendDate',
        fieldName: 'Data de envio'
      }, {
        field: 'competenceFullySigned',
        fieldName: 'Status'
      }]);
    }
    *loadCompetences() {
      try {
        yield this.getCompetences.perform();
        yield this.getCompetencesToSentSignature.perform();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *getCompetencesToSentSignature() {
      try {
        const competencesToSentSignature = yield this.fetch.request('getCompetencesToSentSignature', {
          data: {
            isSentForSignature: true
          }
        });
        this.competencesToSentSignature = competencesToSentSignature;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *getCompetences() {
      try {
        const competences = yield this.fetch.request('getCompetencesToSentSignature');
        this.competences = competences;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *sendTimeCardToSignature() {
      try {
        if (!this.competenceToSentSignature) {
          return this.swal.warning('Selecione uma competência!');
        }
        this.swal.loading();
        yield this.fetch.request('sendTimeCardToSignature', {
          method: 'POST',
          data: {
            competence: this.competenceToSentSignature
          }
        });
        this.competencesToSentSignature = [];
        this.competenceToSentSignature = null;
        yield this.loadCompetences.perform();
        this.swal.close();
        this.showSentDocumentModal = false;
        this.toast.success('Documentos enviados');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.status) {
        filters.status = this.status;
      }
      if (this.typeDocument) {
        filters.typeDocument = typeof this.typeDocument === 'object' ? this.typeDocument.value : this.ledType;
      }
      if (this.filtersSelected && this.filtersSelected.competence) {
        filters.competence = this.filtersSelected.competence;
      }
      if (this.filtersSelected && this.filtersSelected.users?.length) {
        filters.users = this.filtersSelected.users.map(u => u.id);
      }
      this.filters = filters;
    }
    clearFilters() {
      this.filtersSelected = {};
      this.buildFilters();
    }
    onChangeDocumentType(type) {
      this.typeDocument = type;
      Ember.set(this.filtersSelected, 'typeDocument', type.value);
      this.buildFilters();
    }
    onChangeCompetence(competence) {
      this.competence = competence;
      Ember.set(this.filtersSelected, 'competence', competence.competence);
      this.buildFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "competences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "competence", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "competencesToSentSignature", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "competenceToSentSignature", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "typeDocument", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Cartão Ponto',
        value: 'timecard'
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showSentDocumentModal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCompetencesToSentSignature", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getCompetencesToSentSignature"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendTimeCardToSignature", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sendTimeCardToSignature"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeDocumentType", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeDocumentType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCompetence", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCompetence"), _class.prototype)), _class));
  _exports.default = HumanResourcesSentSignaturesController;
});