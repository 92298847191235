define("portal-talentrh/pods/components/modal-job-function/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MRV52QKO",
    "block": "{\"symbols\":[\"Modal\",\"@value\",\"@show\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@onOpen\",\"@show\",\"@toggle\",\"@onClose\",\"@size\"],[[30,[36,0],[[32,0,[\"init\"]]],null],[32,3],[30,[36,2],[[30,[36,1],[[32,3]],null]],null],[32,0,[\"closeModal\"]],\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[[24,0,\"text-break\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2,[\"title\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"job-function-modal-body\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-align-left text-primary h4 mr-2\"],[12],[13],[2,\"\\n      Descrição:\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[32,2,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"mt-2 mr-1 ml-4\"],[14,5,\"white-space: break-spaces;\"],[12],[1,[32,2,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"font-italic mt-2 mr-1 ml-4\"],[12],[2,\"Nenhuma descrição cadastrada\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"font-weight-bold\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon-user-star-line-modal mr-2\"],[12],[13],[2,\"\\n      Skills:\\n    \"],[13],[2,\"\\n    \"],[8,\"user-skills\",[],[[\"@skills\"],[[32,0,[\"skills\"]]]],null],[2,\"\\n\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/modal-job-function/template.hbs"
    }
  });
  _exports.default = _default;
});