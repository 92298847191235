define("portal-talentrh/helpers/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function stripHtml(params /*, hash*/) {
    let str = params[0];
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '');
  });
  _exports.default = _default;
});