define("portal-talentrh/pods/search/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UkB4gwBM",
    "block": "{\"symbols\":[\"Avatar\",\"@user\",\"@companyConfig\"],\"statements\":[[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-5\"],[16,\"alt\",[32,2,[\"fullName\"]]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"flex-fill ml-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-6 text-truncate\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"font-weight-bold text-truncate mb-0\"],[12],[1,[32,2,[\"fullName\"]]],[13],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"\\n        \"],[1,[32,2,[\"department\",\"name\"]]],[2,\"\\n\\n\"],[6,[37,0],[[32,3,[\"showJobFunction\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          - \"],[1,[32,2,[\"jobFunction\",\"title\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"           - \"],[1,[32,2,[\"jobRole\",\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-4 d-flex align-items-center text-truncate\"],[12],[2,\"\\n      \"],[10,\"small\"],[14,0,\"text-truncate\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,2,[\"email\"]],\"-\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-2 d-flex justify-content-end align-items-center\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-primary z-index-2\"],[15,\"onclick\",[30,[36,2],[[32,0],\"openChat\"],null]],[14,\"title\",\"Abrir conversa\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-comment\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"profile.show\",[32,2,[\"id\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"or\",\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/search/user/template.hbs"
    }
  });
  _exports.default = _default;
});