define("portal-talentrh/helpers/cdn-url", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cdnUrl = cdnUrl;
  _exports.default = void 0;
  function cdnUrl(params /*, hash*/) {
    return _environment.default.cdnUrl + params[0];
  }
  var _default = Ember.Helper.helper(cdnUrl);
  _exports.default = _default;
});