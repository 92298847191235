define("portal-talentrh/pods/components/user-preview-popup-insight/content/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    permission: Ember.inject.service(),
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    voorsChat: Ember.inject.service(),
    // Default values
    isSubordinate: false,
    jobFunction: null,
    jobRole: null,
    externalLine: null,
    // Single line computed
    iCanSendMessageIndividualChat: Ember.computed.or('permission.accessProfile.allowSendMessageIndividualChat', 'permission.admin'),
    heSendMessageIndividualChat: Ember.computed.or('user.accessProfile.allowSendMessageIndividualChat', 'user.accessProfile.admin'),
    isLoading: Ember.computed.or('loadIsSubordinate.isRunning', 'loadUserJobFunctionOrJobRole.isRunning'),
    // Computed
    userPhone: Ember.computed('externalLine', 'loadExternalLine.isLoading', 'user', function () {
      if (this.loadExternalLine.isLoading) {
        return;
      } else {
        let professionalPhone = this.user.get('professionalPhone');
        if (this.externalLine || professionalPhone) {
          return `${professionalPhone || '-'} / ${this.externalLine || '-'}`;
        } else {
          return;
        }
      }
    }),
    jobFunctionTitle: Ember.computed('jobFunction', 'isLoading', 'isSubordinate', 'permission.{companyConfig.showJobFunctionCard,toAccessManagement}', function () {
      const jobFunction = this.jobFunction;
      if (!jobFunction || this.isLoading) return null;
      const {
        toAccessManagement
      } = this.permission.toAccessManagement;
      const {
        showJobFunctionCard
      } = this.permission.companyConfig;
      if ((showJobFunctionCard || toAccessManagement || this.isSubordinate) && jobFunction.title) {
        return jobFunction.title;
      }
      return null;
    }),
    jobRoleTitle: Ember.computed('jobRole', 'isLoading', 'isSubordinate', 'permission.{companyConfig.showJobFunctionCard,toAccessManagement}', function () {
      const jobRole = this.jobRole;
      if (!jobRole || this.isLoading) return null;
      const {
        toAccessManagement
      } = this.permission.toAccessManagement;
      const {
        showJobFunctionCard
      } = this.permission.companyConfig;
      if ((showJobFunctionCard || toAccessManagement || this.isSubordinate) && jobRole.title) {
        return jobRole.title;
      }
      return null;
    }),
    // lifeCycle
    didInsertElement() {
      this._super(...arguments);
      this.loadExternalLine.perform();
      this.loadIsSubordinate.perform();
      this.loadUserJobFunctionOrJobRole.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.loadExternalLine.perform();
      this.loadIsSubordinate.perform();
      this.loadUserJobFunctionOrJobRole.perform();
    },
    // Task
    loadExternalLine: (0, _emberConcurrency.task)(function* () {
      const externalLine = yield this.fetch.request('users/getExternalLine', {
        data: {
          id: this.user.get('id')
        }
      });
      Ember.set(this, 'externalLine', externalLine.users[0].externalLine);
    }),
    loadIsSubordinate: (0, _emberConcurrency.task)(function* () {
      const {
        admin,
        manager
      } = this.permission.accessProfile;
      if (!admin && !manager) {
        const usersIds = yield this.fetch.request('users/userSubordinateAjax', {
          data: {
            onlyIds: 1
          }
        });
        const isSubordinate = usersIds.includes(Number(this.user.id));
        Ember.set(this, 'isSubordinate', isSubordinate);
      }
    }),
    loadUserJobFunctionOrJobRole: (0, _emberConcurrency.task)(function* () {
      if (this.permission.companyConfig.showJobFunction) {
        if (this.user.get('jobFunction.id')) {
          const jobFunctions = yield this.fetch.request('jobFunctions/ajax', {
            data: {
              ids: this.user.get('jobFunction.id')
            }
          });
          Ember.set(this, 'jobFunction', jobFunctions[0]);
        } else {
          Ember.set(this, 'jobFunction', null);
        }
      } else {
        if (this.user.get('jobRole.id')) {
          const jobRoles = yield this.fetch.request('jobRoles/ajax', {
            data: {
              ids: this.user.get('jobRole.id')
            }
          });
          Ember.set(this, 'jobRole', jobRoles[0]);
        } else {
          Ember.set(this, 'jobRole', null);
        }
      }
    }),
    // actions
    actions: {
      openChat() {
        if (!this.user) {
          return;
        }
        this.voorsChat.openNewChat({
          id: this.user.id,
          isGroup: false
        });
      }
    }
  });
  _exports.default = _default;
});