define("portal-talentrh/models/dashboard", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    })
  });
  var _default = _model.default.extend(Validations, {
    title: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    company: (0, _model.belongsTo)('company'),
    dashBoardSlides: (0, _model.hasMany)('dashboard-slide')
  });
  _exports.default = _default;
});