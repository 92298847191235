define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-skill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "89YUHweh",
    "block": "{\"symbols\":[],\"statements\":[[11,\"canvas\"],[24,0,\"mt-2-rem\"],[24,1,\"resultPerSkillChart\"],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"init\"]]],null]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-skill/template.hbs"
    }
  });
  _exports.default = _default;
});