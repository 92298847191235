define("portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/quadrant-modal/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3HF+F1Y3",
    "block": "{\"symbols\":[\"Avatar\",\"@index\",\"@quadrant\",\"@userQuadrant\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"d-flex mb-2 border-bottom \",[30,[36,1],[[30,[36,0],[[32,2],0],null],\"border-top pt-2\"],null]]]],[15,5,[31,[\"border-color: \",[32,3,[\"textColor\"]],\";\"]]],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"avatar-ninebox\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-5 mr-2\"],[16,\"alt\",[30,[36,2],[[32,4,[\"user\",\"fullName\"]]],null]]],[[\"@src\"],[[30,[36,1],[[32,4,[\"user\",\"avatarUrl\"]],[32,4,[\"user\",\"avatarUrl\"]],\"/images/user-sem-imagem.png\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"d-block\"],[12],[1,[30,[36,2],[[32,4,[\"user\",\"firstName\"]],\" \",[32,4,[\"user\",\"lastName\"]]],null]],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"showJobFunction\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"d-block\"],[12],[2,\"Função: \"],[1,[32,0,[\"jobFunction\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"d-block\"],[12],[2,\"Cargo: \"],[1,[32,0,[\"jobRole\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"d-block\"],[12],[2,\"Departamento: \"],[1,[32,0,[\"department\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"d-block\"],[12],[1,[32,0,[\"yAxisDescription\"]]],[2,\": \"],[1,[32,4,[\"yAxisPercentage\"]]],[2,\" %\"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"d-block\"],[12],[1,[32,0,[\"xAxisDescription\"]]],[2,\": \"],[1,[32,4,[\"xAxisPercentage\"]]],[2,\" %\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/quadrant-modal/item/template.hbs"
    }
  });
  _exports.default = _default;
});