define("portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/quadrant-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jZ74vdCA",
    "block": "{\"symbols\":[\"Modal\",\"userQuadrant\",\"index\",\"@quadrant\",\"@nineBoxCycle\",\"@cycles\",\"@show\",\"@close\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@toggle\",\"@modalSize\"],[[32,7],[32,8],\"modal-lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[16,5,[31,[\"background-color: \",[32,4,[\"color\"]],\"; color: \",[32,0,[\"textColor\"]]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h5\"],[12],[1,[32,4,[\"name\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,4,[\"description\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"max-h-vh-5 overflow-auto ui-scrollbar mt-3\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,4,[\"usersQuadrant\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"indicators/company-performance/nine-box/quadrant/quadrant-modal/item\",[],[[\"@quadrant\",\"@nineBoxCycle\",\"@userQuadrant\",\"@cycles\",\"@index\"],[[32,4],[32,5],[32,2],[32,6],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-2\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"far fa-angle-double-up fa-2x cursor-pointer float-right\"],[14,\"title\",\"Ver menos\"],[15,\"onclick\",[32,8]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-performance/nine-box/quadrant/quadrant-modal/template.hbs"
    }
  });
  _exports.default = _default;
});