define("portal-talentrh/pods/portal-manager/indicators/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    accessAVD: Ember.computed.alias('permission.toAccessAVD'),
    showMenuIndicatorTeamProfile: Ember.computed.or('permission.accessProfile.allowIndicatorTeamProfile', 'permission.admin'),
    showMenuIndicatorEngagement: Ember.computed.or('permission.accessProfile.allowIndicatorEngagementMyTeam', 'permission.admin'),
    showMenuIndicatorOrganizationalClimate: Ember.computed.or('permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam', 'permission.admin'),
    showMenuIndicatorPerformance: Ember.computed.or('permission.accessProfile.allowIndicatorPerformanceMyTeam', 'permission.admin'),
    haveLeds: false,
    init() {
      this._super(...arguments);
      this.getLedUsers.perform();
    },
    getLedUsers: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.ajax.request(`users/${this.session.user.id}/hierarchy`);
        if (response.leds && response.leds.length) {
          Ember.set(this, 'haveLeds', true);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    })
  });
  _exports.default = _default;
});