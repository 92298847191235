define("portal-talentrh/models/department", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _attr.default)('boolean'),
    customerProfile: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    successStories: (0, _attr.default)('string'),
    summary: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    comercialManager: (0, _relationships.hasMany)('user', {
      inverse: 'departmentComercialManager'
    }),
    employees: (0, _relationships.hasMany)('user'),
    modules: (0, _relationships.hasMany)('module'),
    technicalManager: (0, _relationships.hasMany)('user', {
      inverse: 'departmentTechnicalManager'
    })
  });
  _exports.default = _default;
});