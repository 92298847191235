define("portal-talentrh/pods/company/decision/show/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['list-group-item'],
    // Multiline computed properties
    decisionVote: Ember.computed('userVote.vote', function () {
      if (!this.userVote.vote) {
        return '';
      }
      if (this.userVote.vote === 'yes') {
        return 'Sim';
      }
      return 'Não';
    })
  });
  _exports.default = _default;
});