define("portal-talentrh/models/good-transfer-historic", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ACTIONS = {
    include: 'Adicionado',
    approve: 'Aprovado',
    disapprove: 'Reprovado'
  };
  var _default = _model.default.extend({
    // Attributes
    action: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    // Associations
    goodTransfer: (0, _relationships.belongsTo)('goodTransfer'),
    user: (0, _relationships.belongsTo)('user'),
    // Computed Properties
    formatedCreatedAt: Ember.computed('createdAt', function () {
      let mDate = (0, _moment.default)(this.createdAt);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    readableAction: Ember.computed('action', function () {
      return ACTIONS[this.action] || 'Sem ação';
    })
  });
  _exports.default = _default;
});