define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/evaluation-status/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionUserId: Ember.computed.alias('session.user.id'),
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('permission.companyConfig.allowConsensusInEmployee180Evaluation'),
    newPerformanceModule: Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    tagName: '',
    evaluationType: null,
    appraiser: Ember.computed('evaluation.appraiserConcluded', function () {
      if (this.evaluation.appraiserConcluded) {
        return this.concludedStatus('Avaliador');
      } else {
        return this.pendingStatus('Avaliador');
      }
    }),
    appraised: Ember.computed('evaluation.{appraisedConcluded,typeEvaluation,type}', function () {
      if (this.evaluation?.typeEvaluation === 90 || this.evaluation.type === 'EVALUATION_90') {
        return;
      } else if (this.evaluation.appraisedConcluded) {
        return this.concludedStatus('Avaliado');
      } else {
        return this.pendingStatus('Avaliado');
      }
    }),
    team: Ember.computed('employeeEvaluationTeams.{length,teamConcluded}', 'evaluation.{type,teamConcluded}', function () {
      if ((!this.employeeEvaluationTeams || this.employeeEvaluationTeams.length == 0) && this.evaluation.type !== "EVALUATION_360") {
        return;
      } else if (this.evaluation.teamConcluded) {
        return this.concludedStatus('Equipe');
      } else {
        return this.pendingStatus('Equipe');
      }
    }),
    consensus: Ember.computed('allowConsensusInEmployee180Evaluation', 'evaluation.{consensusAnswer,consensusConcluded,type}', 'evaluationType', function () {
      if (this.evaluationType !== 2 || this.evaluation.type !== 'EVALUATION_CONSENSUS') {
        return;
      } else if (this.evaluation.consensusConcluded) {
        return this.concludedStatus('Consenso');
      } else {
        return this.pendingStatus('Consenso');
      }
    }),
    // Tasks
    getEvaluationType: (0, _emberConcurrency.task)(function* () {
      if (this.evaluation.type === 'EVALUATION_CONSENSUS') {
        return Ember.set(this, 'evaluationType', 2);
      }
      const appraisedUser = yield this.evaluation.appraisedUser;
      const user = yield this.store.findRecord('user', appraisedUser);
      const evaluationType = user.typeEvaluation;
      if (evaluationType) {
        return Ember.set(this, 'evaluationType', evaluationType);
      }
      const type = this.evaluation.type;
      if (type === 1) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeFirstEvaluation);
      }
      if (type === 2) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeLastEvaluation);
      }
      if (type === 3) {
        return Ember.set(this, 'evaluationType', this.companyConfig.typeFunctionEvaluation);
      }
    }).drop().on('didUpdateAttrs').on('didInsertElement'),
    // Methods
    pendingStatus(role) {
      return {
        title: 'Pendente',
        icon: 'fa fa-clock text-warning',
        role
      };
    },
    concludedStatus(role) {
      return {
        title: 'Concluído',
        icon: 'fa fa-check-circle text-success',
        role
      };
    }
  });
  _exports.default = _default;
});