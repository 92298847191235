define("portal-talentrh/pods/portal-manager/request/completed/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency", "moment"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PortalManagerRequestCompletedController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class PortalManagerRequestCompletedController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "createdAt", _descriptor, this);
      _initializerDefineProperty(this, "filter", _descriptor2, this);
      _initializerDefineProperty(this, "inputFilter", _descriptor3, this);
      _initializerDefineProperty(this, "reload", _descriptor4, this);
      _initializerDefineProperty(this, "showModal", _descriptor5, this);
      _initializerDefineProperty(this, "userSolicitation", _descriptor6, this);
    }
    closeModal() {
      this.userSolicitation = null;
      this.showModal = false;
    }
    showUserSolicitation(solicitation) {
      this.userSolicitation = solicitation;
      this.showModal = true;
      console.log(this.userSolicitation, this.showModal);
    }
    reloadFilters(filters) {
      if (filters) {
        this.filter = Ember.Object.create(filters);
        this.filter.status = ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed'];
        this.filter.term = this.inputFilter;
      }
      this.reload = true;
    }
    reloadList() {
      this.reload = !this.reload;
    }
    clearInput() {
      this.inputFilter = null;
      this.getFilters();
    }
    getFilters(filter) {
      if (!filter) {
        if (!this.filter || this.filter && !this.filter.status) {
          let filter = {
            status: ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed']
          };
          filter.createdAt = (0, _moment.default)((0, _moment.default)().startOf('month').toDate()).format('YYYY-MM-DD HH:mm');
          filter.finalDate = (0, _moment.default)((0, _moment.default)().toDate()).format('YYYY-MM-DD HH:mm');
          this.filter = {
            ...this.filter,
            ...filter
          };
        } else {
          this.filter.term = this.inputFilter;
        }
      } else {
        this.filter = {
          ...this.filter,
          ...filter
        };
      }
      this.filter.status = ['approved', 'disapproved-manager', 'disapproved-hr', 'canceled', 'ignored', 'processed'];
      this.reload = true;
      this.filter.pending = 'n';
    }
    *filterInput() {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ledType: 'd'
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inputFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "reload", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userSolicitation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showUserSolicitation", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showUserSolicitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reloadFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadList", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "reloadList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInput", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterInput", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterInput"), _class.prototype)), _class));
  _exports.default = PortalManagerRequestCompletedController;
});