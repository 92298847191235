define("portal-talentrh/pods/components/dashboard/message-board/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    // Lifecycle hooks
    didInsertElement() {
      this.loadMessageBoard();
    },
    // Custom methods
    loadMessageBoard() {
      this.ajax.request(`/userMessages/active`).then(data => {
        if (this.isDestroyed) {
          return;
        }
        Ember.set(this, 'messageBoard', data);
      });
    },
    // Actions
    actions: {
      refreshDashboard() {
        this.loadMessageBoard();
      }
    }
  });
  _exports.default = _default;
});