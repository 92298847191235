define("portal-talentrh/pods/group/show/attachment/create/controller", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    router: Ember.inject.service(),
    // Default values
    postHaveFiles: true,
    // Single line computed properties
    description: '',
    files: Ember.A(),
    group: Ember.computed.alias('model'),
    lengthMessage: Ember.computed('files.length', function () {
      if (!this.files.length) {
        return 'Nenhum arquivo selecionado';
      }
      let message = this.files.length > 1 ? this.files.length + ' arquivos selecionados' : this.files.length + ' arquivo selecionado';
      return message;
    }),
    showModal: Ember.computed('group.{admins,postOnlyForAdmin}', 'permission.admin', 'session.user.id', function () {
      let admins = this.group.admins.mapBy('id');
      return this.permission.admin || !this.group.postOnlyForAdmin || (admins || []).includes(String(this.session.user.id));
    }),
    // Tasks
    save: (0, _emberConcurrency.task)(function* () {
      const url = `${_environment.default.apiUrl.social}/userGroups/${this.group.id}/groupAttachments`;
      if (!this.files.length) {
        return this.swal.warning('Selecione um arquivo');
      }
      try {
        this.swal.loading('Carregando arquivos');
        yield Ember.RSVP.map(this.files, file => {
          return file.upload(url, {
            data: {
              description: this.description
            },
            fileKey: 'group-attachment',
            headers: {
              Authorization: `Bearer ${this.session.token}`
            },
            method: 'POST'
          });
        });
        this.files.clear();
        this.group.reload();
        this.toast.success('Arquivos enviados');
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      closeModal() {
        this.router.transitionTo('group.show.attachment', this.group.id);
      },
      removeFile(file) {
        this.files.removeObject(file);
      },
      selectFile(file) {
        this.files.addObject(file);
      }
    }
  });
  _exports.default = _default;
});