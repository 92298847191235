define("portal-talentrh/pods/feed/me/receipt/controller", ["exports", "moment", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _moment, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeedMeReceiptController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class FeedMeReceiptController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "repeat", _descriptor5, this);
      _initializerDefineProperty(this, "selected", _descriptor6, this);
      _initializerDefineProperty(this, "branchSelected", _descriptor7, this);
      _initializerDefineProperty(this, "receipt", _descriptor8, this);
      _initializerDefineProperty(this, "branchDisabled", _descriptor9, this);
    }
    get showFilterBranch() {
      return this.model.user && this.model.user.branches.length;
    }
    handleChangeLocal(selected) {
      if (this.onChange) {
        this.onChange(...arguments);
      } else {
        Ember.set(this, 'selected', selected);
        if (this.afterChange) {
          this.afterChange(...arguments);
        }
      }
      if (selected.BRANCH) {
        this.branchSelected = this.model.user.branches.content.currentState.find(branch => {
          return branch.id == selected.BRANCH;
        });
        if (!this.branchSelected) {
          return;
        }
        this.branchSelected = this.branchSelected._record;
        this.branchDisabled = true;
      } else {
        this.branchDisabled = false;
      }
    }
    buildFilters() {
      let printReceipt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let filter = {};
      let date = (0, _moment.default)(this.selected.DATA).format('DD/MM/YYYY');
      let processId = this.selected.IDPROCESSO;
      let emission = (0, _moment.default)().format('DD/MM/YYYY HH:mm');
      filter.repeat = this.repeat ? this.repeat : 'false';
      filter.date = date;
      filter.process = processId;
      filter.emission = emission;
      filter.printReceipt = printReceipt;
      filter.folha = this.selected.FOLHA;
      if (this.showFilterBranch) {
        filter.branch = this.branchSelected.id;
      }
      return filter;
    }
    validateFilters() {
      if (!this.selected) {
        this.swal.warning('Selecione uma folha de pagamento.');
        return false;
      }
      if (this.showFilterBranch) {
        if (!this.branchSelected) {
          this.swal.warning('Selecione uma filial.');
          return false;
        }
      }
      if (!this.selected.DATA || !this.selected.IDPROCESSO) {
        this.swal.warning('Estrutura da folha de pagamento fora de padrão.');
      }
      return true;
    }
    *findReceipt(pdf) {
      let swalLoading = null;
      try {
        if (this.model.user.branches.length <= 1) {
          this.branchSelected = this.model.user.branches.content.currentState[0];
        }
        const validated = this.validateFilters();
        if (!validated) return;
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        const filter = this.buildFilters(pdf);
        let url = `${_environment.default.apiUrl.social}/receipts/report`;
        const search = new URLSearchParams(filter).toString();
        url += `?${search}`;
        if (pdf) {
          swalLoading = this.swal.loading('Gerando o pdf...');
        }
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        if (pdf) {
          const report = yield response.blob();
          const objectURL = URL.createObjectURL(report);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = 'recibo-pagamento.pdf';
          document.body.append(link);
          link.click();
          link.remove();
          swalLoading.close();
        } else {
          this.receipt = yield response.json();
        }
      } catch (e) {
        if (swalLoading) swalLoading.close();
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "repeat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "branchSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "receipt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "branchDisabled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleChangeLocal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "handleChangeLocal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateFilters", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "validateFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findReceipt", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findReceipt"), _class.prototype)), _class));
  _exports.default = FeedMeReceiptController;
});