define("portal-talentrh/models/module", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    avatarUrl: (0, _model.attr)('string'),
    database: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    differential: (0, _model.attr)('string'),
    licenceValue: (0, _model.attr)('string'),
    reports: (0, _model.attr)('string'),
    resources: (0, _model.attr)('string'),
    targetAudience: (0, _model.attr)('string'),
    technology: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    department: (0, _model.belongsTo)('department'),
    // Computed Properties
    avatar: Ember.computed('avatarUrl', function () {
      if (!this.avatarUrl) {
        return '/images/user-sem-imagem.png';
      }
      if (this.avatarUrl.includes('gravatar')) {
        return this.avatarUrl;
      }
      return this.avatarUrl;
    })
  });
  _exports.default = _default;
});