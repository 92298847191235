define("portal-talentrh/pods/components/post-comments/reply-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0QFO3pF4",
    "block": "{\"symbols\":[\"commentReply\",\"Content\",\"@comments\",\"@onlyShow\",\"@viewCommentLikes\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"post-comments/comment\",[],[[\"@comment\",\"@comments\"],[[32,1],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"    \\n    \"],[8,[32,2,[\"body\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"likeBar\"]],[],[[\"@onlyShow\",\"@viewCommentLikes\"],[[32,4],[32,5]]],null],[2,\"\\n    \"],[8,[32,2,[\"moreOptions\"]],[],[[\"@onlyShow\"],[[32,4]]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"commentReplies\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-comments/reply-list/template.hbs"
    }
  });
  _exports.default = _default;
});