define("portal-talentrh/pods/company/programs/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q3fImU+/",
    "block": "{\"symbols\":[\"Card\",\"@companyProgram\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm my-2\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"d-flex align-items-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[14,0,\"font-weight-bold mb-0 text-secondary cursor-pointer\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"transitionToTopics\"]]],null]],[12],[1,[32,2,[\"title\"]]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-auto d-flex align-items-center\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"attachments\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"btn btn-sm btn-outline-primary text-nowrap ml-3\"]],[[\"@route\",\"@model\"],[\"company.programs.attachments\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Visualizar arquivos\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"text-justify\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[12],[1,[30,[36,2],[[32,2,[\"description\"]]],null]],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"badge bg-transparent border border-secondary text-secondary mr-2 cursor-pointer\"],[14,\"title\",\"Tópicos\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"transitionToTopics\"]]],null]],[12],[2,\"\\n      \"],[1,[32,0,[\"topicLabel\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\",\"html\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/programs/item/template.hbs"
    }
  });
  _exports.default = _default;
});