define("portal-talentrh/pods/components/dashboard-action-timer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Default values
    timer: 1000 * 60,
    //Reload em 60 seg,
    _debouncedItem: null,
    // Lifecycle hooks
    didInsertElement() {
      this._debouncedItem = Ember.run.later(this, this.tick, this.timer);
    },
    // Custom methods
    tick() {
      Ember.run.cancel(this._debouncedItem);
      if (this.tickAction) {
        this.tickAction();
      }
    }
  });
  _exports.default = _default;
});