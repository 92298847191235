define("portal-talentrh/pods/group/show/attachment/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    deactivate() {
      Ember.set(this.controller, 'text', '');
    }
  });
  _exports.default = _default;
});