define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-details/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FHqKGWmq",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[11,\"div\"],[24,0,\"d-flex justify-content-around border-bottom p-2\"],[4,[38,2],[[30,[36,1],[[32,0],[32,0,[\"init\"]]],null]],null],[12],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"w-50 ml-2\"],[12],[10,\"p\"],[14,0,\"small\"],[12],[1,[32,1,[\"title\"]]],[2,\" - \"],[1,[32,1,[\"description\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"w-50 ml-2\"],[12],[10,\"p\"],[14,0,\"small\"],[12],[1,[32,1,[\"description\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"appraisedValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"align-center align-content-center display-grid w-15\"],[12],[1,[30,[36,0],[[32,0,[\"appraisedValue\"]]],null]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"appraiserValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"align-center align-content-center display-grid w-15\"],[12],[1,[30,[36,0],[[32,0,[\"appraiserValue\"]]],null]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"teamValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"align-center align-content-center display-grid w-15\"],[12],[1,[30,[36,0],[[32,0,[\"teamValue\"]]],null]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"consensusValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"align-center align-content-center display-grid w-15\"],[12],[1,[30,[36,0],[[32,0,[\"consensusValue\"]]],null]],[2,\"%\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"to-fixed\",\"action\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-details/item/template.hbs"
    }
  });
  _exports.default = _default;
});