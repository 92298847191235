define("portal-talentrh/pods/feed/index/setup-account/led/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    // Computed
    filters: Ember.computed('search', function () {
      if (this.search) {
        return {
          term: this.search,
          exclude: 'self'
        };
      } else {
        return {
          department: this.session.user.department
        };
      }
    }),
    // Tasks
    createLedRequest: (0, _emberConcurrency.task)(function* () {
      try {
        let usersSelected = this.usersSelected || [];
        if (!usersSelected.length) {
          return this.toast.warning('É necessário escolher ao menos um usuário');
        }
        let requestsToCreate = usersSelected.map(u => {
          return this.ajax.post('leadershipRequests', {
            data: {
              leadershipRequest: {
                type: 'leader',
                relatedUser: u.id
              }
            }
          });
        });
        yield Ember.RSVP.all(requestsToCreate);
        this.toast.success('Enviamos uma solicitação para seus liderados.');
        this.send('closeModal');
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      toggleUser(user) {
        let usersSelected = this.usersSelected || [];
        let alreadyAdded = usersSelected.findBy('id', user.id);
        if (alreadyAdded) {
          usersSelected.removeObject(user);
        } else {
          usersSelected.addObject(user);
        }
        Ember.set(this, 'usersSelected', usersSelected);
      },
      removeUserSelected(user) {
        (this.usersSelected || []).removeObject(user);
      },
      closeModal() {
        this.send('close');
      }
    }
  });
  _exports.default = _default;
});