define("portal-talentrh/pods/components/info-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JwhykzPn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"title\",\"content\"],[[30,[36,0],[\"info-block/title\"],null],[30,[36,0],[\"info-block/content\"],null]]]]]],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/info-block/template.hbs"
    }
  });
  _exports.default = _default;
});