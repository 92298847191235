define("portal-talentrh/pods/feed/index/setup-account/user-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4yDDP8/v",
    "block": "{\"symbols\":[\"Card\",\"Avatar\",\"@user\",\"@toggleUser\"],\"statements\":[[8,\"ui-card\",[[16,0,[31,[\"bg-white cursor-pointer \",[30,[36,0],[[32,0,[\"isSelected\"]],\"shadow\"],null]]]],[16,\"onclick\",[30,[36,1],[[32,0],[32,4],[32,3]],null]],[16,\"title\",[30,[36,2],[[32,3,[\"firstName\"]],\" \",[32,3,[\"lastName\"]]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"d-flex p-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,3,[\"fullName\"]]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ml-2 text-truncate\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"mb-1 h6 font-weight-bold text-truncate\"],[12],[2,\"\\n        \"],[1,[32,3,[\"firstName\"]]],[2,\" \"],[1,[32,3,[\"lastName\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"h6\"],[14,0,\"mb-0 text-truncate small\"],[12],[1,[30,[36,3],[[32,3,[\"departmentName\"]],\"-\"],null]],[2,\" / \"],[1,[30,[36,3],[[32,3,[\"jobRoleName\"]],\"-\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isSelected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fas fa-check-circle fa-sm text-primary position-absolute t-2 r-2\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"concat\",\"or\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/index/setup-account/user-item/template.hbs"
    }
  });
  _exports.default = _default;
});