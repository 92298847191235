define("portal-talentrh/pods/components/draw-panel/component", ["exports", "@glimmer/component", "signature_pad"], function (_exports, _component, _signature_pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DrawPanelComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DrawPanelComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "signaturePad", _descriptor, this);
    }
    resizeCanvas(canvas) {
      const {
        onChange
      } = this.args;
      const sketch = document.querySelector('#sketch');
      const sketchStyle = getComputedStyle(sketch);
      canvas.width = parseInt(sketchStyle.getPropertyValue('width'));
      canvas.height = parseInt(sketchStyle.getPropertyValue('height'));
      canvas.style.border = '1px solid #ccc';
      canvas.getContext('2d');
      this.signaturePad = new _signature_pad.default(canvas, {
        backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
      });

      this.signaturePad.addEventListener('endStroke', () => {
        if (this.signaturePad.isEmpty()) {
          return;
        }
        const data = this.signaturePad.toDataURL('image/jpeg');
        if (onChange) {
          onChange(data);
        }
      });
    }
    clearCanvas() {
      this.signaturePad.clear();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "signaturePad", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resizeCanvas", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "resizeCanvas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearCanvas", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearCanvas"), _class.prototype)), _class));
  _exports.default = DrawPanelComponent;
});