define("portal-talentrh/pods/external-link/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    allowCreateExternalLink: Ember.computed.or('permission.accessProfile.allowCreateExternalLink', 'permission.admin'),
    filters: null,
    deleteRecord: (0, _emberConcurrency.task)(function* (record) {
      try {
        let result = yield this.swal.confirm('Tem certeza que deseja remover este registro?', {
          text: 'Todos os dados associados a ele serão excluídos!'
        });
        if (!result.value) {
          return;
        }
        yield this.ajax.delete('userLinks/' + record.id);
        this.getFilters();
        this.swal.success('Removido com sucesso');
      } catch (e) {
        return this.swal.catch(e);
      }
    }),
    filterTerm: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    getFilters() {
      let filters = {};
      if (this.term) {
        filters.term = this.term;
      }
      Ember.set(this, 'filters', filters);
    }
  });
  _exports.default = _default;
});