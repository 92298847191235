define("portal-talentrh/models/meeting-minute", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Ops! O título não foi informado!'
    }),
    start: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a data inicial!'
    }),
    end: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a data final!'
    }), (0, _emberCpValidations.validator)('date', {
      onOrAfter: Ember.computed.reads('model.start'),
      message: 'O horário de início deve ser anterior ao horário final.',
      precision: 'minute'
    })],
    usersAdmin: (0, _emberCpValidations.validator)('length', {
      min: 1,
      message: 'É necessário adicionar um administrador'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    comments: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    end: (0, _model.attr)('timestamp'),
    externalUsers: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),
    generalInformations: (0, _model.attr)('string'),
    meetingDate: (0, _model.attr)('date'),
    start: (0, _model.attr)('timestamp'),
    title: (0, _model.attr)('string'),
    customRoom: (0, _model.attr)('string'),
    meetingAdmins: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),
    // campo virtual
    meetingParticipants: (0, _model.attr)('raw', {
      defaultValue: () => []
    }),
    // campo virtual

    // Associations
    company: (0, _model.belongsTo)('company'),
    createdBy: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    room: (0, _model.belongsTo)('room'),
    meetingAttachments: (0, _model.hasMany)('meeting-attachment'),
    meetingTopics: (0, _model.hasMany)('meeting-topic'),
    usersAdmin: (0, _model.hasMany)('user', {
      inverse: 'meetingAdmin'
    }),
    usersAdminOnMeeting: (0, _model.hasMany)('user-is-admin-on-meeting', {
      inverse: 'meeting'
    }),
    usersParticipant: (0, _model.hasMany)('user', {
      inverse: 'meetingParticipant'
    }),
    usersParticipantOnMeeting: (0, _model.hasMany)('user-is-participant-on-meeting', {
      inverse: 'meeting'
    })
  });
  _exports.default = _default;
});