define("portal-talentrh/pods/feed/me/payroll-loan/form-management-data/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FormManagementDataComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('permission.user'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class FormManagementDataComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "userLogged", _descriptor6, this);
      _initializerDefineProperty(this, "showPdfTermsOfUse", _descriptor7, this);
      _initializerDefineProperty(this, "termsOfUse", _descriptor8, this);
    }
    get hasManyBranches() {
      return this.userLogged.get('branches').length > 1 || this.userLogged.get('branches').length === 0;
    }
    *createAccount() {
      const {
        payrollLoanUser
      } = this.args;
      try {
        const branches = yield this.userLogged.branches;
        const branch = branches.firstObject;
        if (!payrollLoanUser.termsOfUseAccepted) {
          return this.swal.warning('É necessário aceitar os termos de uso!');
        }
        const bodyCreateUserExact = {
          cnpjEmpresa: branch.cnpj,
          cpf: payrollLoanUser.user.get('cpf'),
          nome: payrollLoanUser.user.get('fullName'),
          dataNascimento: (0, _moment.default)(payrollLoanUser.user.get('birthDate')).format('DD/MM/YYYY'),
          email: payrollLoanUser.email,
          senha: `${payrollLoanUser.company.get('id')}@${payrollLoanUser.user.get('cpf')}`,
          confirmarSenha: `${payrollLoanUser.company.get('id')}@${payrollLoanUser.user.get('cpf')}`,
          telefoneCelular: payrollLoanUser.cellphone,
          termosUso: payrollLoanUser.termsOfUseAccepted
        };
        let {
          status,
          tokenAcesso,
          mensagem
        } = yield this.fetch.request('/integracao_consignado/cadastroUsuario', {
          method: 'POST',
          host: _environment.default.exatoUrlBase,
          headers: {
            'Content-type': 'application/json;charset=utf-8',
            token_integracao: _environment.default.exatoTokenIntegration
          },
          data: bodyCreateUserExact
        });
        if ([400, 401].includes(status)) {
          throw mensagem;
        }
        if (status === 200 && tokenAcesso) {
          Ember.set(payrollLoanUser, 'accessToken', tokenAcesso);
          const payrollLoanUserCreated = yield payrollLoanUser.save();
          Ember.set(this.userLogged, 'payrollLoanUser', payrollLoanUserCreated);
          yield this.userLogged.save();
        }
        this.router.transitionTo('feed.me.payroll-loan.complete-account');
        this.toast.success('Conta criada');
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *loadTermsOfUseAndDefaultUser() {
      try {
        const {
          anexo
        } = yield this.fetch.request('/integracao_consignado/buscaTermosUso', {
          method: 'GET',
          host: _environment.default.exatoUrlBase,
          headers: {
            'Content-type': 'application/json;charset=utf-8',
            token_integracao: _environment.default.exatoTokenIntegration
          }
        });
        if (anexo) {
          this.termsOfUse = anexo;
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    closeModal() {
      this.router.transitionTo('home.my-workspace');
    }
    toggleCheckboxTermsOfUser() {
      const {
        payrollLoanUser
      } = this.args;
      Ember.set(payrollLoanUser, 'termsOfUseAccepted', !payrollLoanUser.termsOfUseAccepted);
      this.showPdfTermsOfUse = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userLogged", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showPdfTermsOfUse", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "termsOfUse", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createAccount", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "createAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTermsOfUseAndDefaultUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTermsOfUseAndDefaultUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCheckboxTermsOfUser", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCheckboxTermsOfUser"), _class.prototype)), _class));
  _exports.default = FormManagementDataComponent;
});