define("portal-talentrh/models/branch", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    address: (0, _model.attr)('string'),
    cnpj: (0, _model.attr)('string'),
    complement: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    neighborhood: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    phoneDDD: (0, _model.attr)('string'),
    tradingName: (0, _model.attr)('string'),
    zipcode: (0, _model.attr)('string'),
    externalLine: (0, _model.attr)('number'),
    // Associations
    city: (0, _relationships.belongsTo)('city'),
    benefits: (0, _model.hasMany)('benefit', {
      inverse: 'branches'
    }),
    companyprograms: (0, _model.hasMany)('company-program', {
      inverse: 'branches'
    }),
    policies: (0, _model.hasMany)('policy', {
      inverse: 'branches'
    }),
    // Computed Properties
    companyName: Ember.computed.or('tradingName', 'name')
  });
  _exports.default = _default;
});