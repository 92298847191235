define("portal-talentrh/pods/components/modal-dialogs/create-complaining/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NsntPEGN",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"option\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\"],[true,[30,[36,2],[[32,0],\"resolve\",null],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Reportar Publicação?\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[12],[2,\"Informe o motivo\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-radio\",[[24,0,\"d-block mb-2\"]],[[\"@option\",\"@selected\",\"@onChange\"],[[32,3,[\"value\"]],[32,0,[\"selected\",\"option\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"selected\",\"option\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,3,[\"label\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[8,\"ui-textarea\",[[24,0,\"form-control\"]],[[\"@value\",\"@placeholder\"],[[32,0,[\"selected\",\"message\"]],\"Poderia nos explicar melhor porquê você está reportando esta publicação?\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,2],[[32,0],[32,0,[\"submit\"]],[32,0,[\"selected\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/modal-dialogs/create-complaining/template.hbs"
    }
  });
  _exports.default = _default;
});