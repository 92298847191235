define("portal-talentrh/pods/group/show/attachment/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    fileDownload: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    fetch: Ember.inject.service(),
    // Default values
    filter: '',
    // Single line computed properties
    group: Ember.computed.alias('model'),
    groupFiles: Ember.A([]),
    showAddAttachment: Ember.computed('group.{admins,postOnlyForAdmin}', 'permission.admin', 'session.user.id', function () {
      let admins = this.group.admins.mapBy('id');
      return this.permission.admin || !this.group.postOnlyForAdmin || (admins || []).includes(String(this.session.user.id));
    }),
    // Multiline computed properties
    attachments: Ember.computed('filter', 'groupFiles', function () {
      if (!this.filter) {
        return this.groupFiles;
      }
      let pattern = new RegExp(this.filter, 'i');
      return this.groupFiles.filter(attachment => {
        return (attachment.name || '').match(pattern) || (attachment.description || '').match(pattern);
      });
    }),
    // Tasks
    deleteAttachment: (0, _emberConcurrency.task)(function* (attachment) {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção deste arquivo?');
        if (!value) {
          return;
        }
        this.swal.loading('Removendo arquivo');
        yield this.fetch.request(`groupAttachments/${attachment.id}`, {
          method: 'DELETE'
        });
        Ember.set(this, "groupFiles", []);
        this.loadFiles.perform();
        this.toast.success('Arquivo removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    downloadAttachment: (0, _emberConcurrency.task)(function* (attachmentId) {
      try {
        let file = yield this.ajax.request(`groupAttachments/${attachmentId}/signedLink`);
        this.fileDownload.downloadFile(file);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    loadFiles: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, "groupFiles", []);
        let files = yield this.ajax.request(`groupAttachments/${this.group.id}/findByGroupId`);
        files.forEach(file => {
          file.description = file.description.replace(/(\r\n|\n|\r)/gm, "");
          this.groupFiles.push(file);
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable()
  });
  _exports.default = _default;
});