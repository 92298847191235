define("portal-talentrh/pods/components/user-list-formations/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userFormation: Ember.computed('this.record', function () {
      let formation = 'Estudou';
      if (this.record.courseTitle) {
        formation = formation + ` <strong>${this.record.courseTitle}</strong>`;
      }
      if (this.record.institutionName) {
        formation = formation + ` na instituição <strong>${this.record.institutionName}</strong>.`;
      }
      return Ember.String.htmlSafe(formation);
    }),
    didInsertElement() {
      this._super(...arguments);
      if (!this.record.acquiredknowledge) {
        document.getElementById("nela-3152-formations--" + this.record.id).setAttribute("style", "cursor: auto !important;");
      }
      $('#nela-3152-formations--' + this.record.id).on('show.bs.collapse', {
        id: this.record.id
      }, function (event) {
        $('#nela-3152-formations--' + event.data.id + ' .fas').removeClass('fa-caret-down');
        $('#nela-3152-formations--' + event.data.id + ' .fas').addClass('fa-caret-up');
        $('#nela-3152-experience--' + event.data.id + ' .fas').attr('title', 'Ocultar descrição');
      });
      $('#nela-3152-formations--' + this.record.id).on('hide.bs.collapse', {
        id: this.record.id
      }, function (event) {
        $('#nela-3152-formations--' + event.data.id + ' .fas').removeClass('fa-caret-up');
        $('#nela-3152-formations--' + event.data.id + ' .fas').addClass('fa-caret-down');
        $('#nela-3152-experience--' + event.data.id + ' .fas').attr('title', 'Exibir descrição');
      });
    }
  });
  _exports.default = _default;
});