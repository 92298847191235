define("portal-talentrh/pods/components/quick-tips/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributos
    classNames: 'quick-tips mt-4 position-absolute r-1 z-index-3',
    // Services
    router: Ember.inject.service(),
    // Computed
    videoId: Ember.computed('router.currentRouteName', 'videoIds', function () {
      let id = this.videoIds[this.router.currentRouteName];
      if (!id) {
        // TODO: melhorar a lógica "routeName.*" para valdiar todos os níveis
        // Verifica se existe uma sintaxe: routName.* para essa rota
        let splited = this.router.currentRouteName.split('.');
        id = this.videoIds[splited[0] + '.*'];
        if (!id) {
          Ember.debug(`QuickTips :: Não foi achado um id para a rota: ${this.router.currentRouteName}`);
        }
      }
      return id;
    }),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.videoIds = {
        'absence-board.index': '756947275?h=6f8497038e',
        'anniversary': '756947487?h=19478928fb',
        'company.album.index.index': '756947758?h=55fea72fee',
        'company.benefits.index': '756947901?h=d6968bcc21',
        'company.chart': '756947957?h=56e5621247',
        'company.decision.index.index': '756948173?h=e9b03390fb',
        'company.job-function': '756948226?h=00a264edb5',
        'company.minutes-of-meeting.index.index': '756947817?h=ec6e65c168',
        'company.policy.index': '756948020?h=2e8c7c85e0',
        'company.programs.index': '756948108?h=7bf716d914',
        'company.userlist': '756947662?h=f5bc1f7e08',
        'dashboard.index': '757137211?h=e83d225870',
        'external-link.index': '756948395?h=000e60e8ad',
        'feed.index.index': '756947131?h=108e63dae9',
        'forum.index.index': '756948269?h=af74a61043',
        'feed.me.annual-income': '757136534?h=d6acae3a4f',
        'feed.me.job-contract': '757136488?h=23473d90d4',
        'feed.me.receipt': '757136632?h=3750c5b9b2',
        'feed.me.request.index': '757136753?h=5936f72ba2',
        'feed.me.schedule': '757136411?h=d352b48296',
        'feed.me.time-card': '757136573?h=5ad6e656d8',
        'group.index': '756948307?h=5b4d7e0d95',
        'human-resources.feeling': '757136985?h=9e80c12ee3',
        'human-resources.historic': '757136870?h=7c2c0bae44',
        'human-resources.payment': '757136632?h=3750c5b9b2',
        'human-resources.people-analytics': '757137067?h=89771162f3',
        'human-resources.request.index.index': '757136753?h=5936f72ba2',
        'human-resources.time-card': '757136573?h=5ad6e656d8',
        'human-resources.user-solicitations.index.index': '757136753?h=5936f72ba2',
        'portal-manager.feeling': '757136985?h=9e80c12ee3',
        'portal-manager.historic': '757136870?h=7c2c0bae44',
        'portal-manager.payment': '757136632?h=3750c5b9b2',
        'portal-manager.people-analytics': '757137067?h=89771162f3',
        'portal-manager.team-members.index': '757136685?h=c3b753a908',
        'portal-manager.time-card': '757136573?h=5ad6e656d8',
        'portal-manager.request.index.index': '757136753?h=5936f72ba2',
        'post.ad': '756947584?h=f33e945d0c',
        'vacancy.ad.index': '757137302?h=910ef84230'
      };
    },
    didInsertElement() {
      this._super(...arguments);
      this.fixVideoOnScroll = () => {
        let elementMenu = this.element.querySelector('.quick-tips__float-video');
        let {
          scrollTop
        } = document.documentElement;
        if (!elementMenu) {
          return;
        }
        if (scrollTop > 70) {
          elementMenu.classList.add('quick-tips__float-video--fixed');
        } else {
          elementMenu.classList.remove('quick-tips__float-video--fixed');
        }
      };
    },
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener('scroll', this.fixVideoOnScroll);
    },
    // Method
    hideEffect(e) {
      Ember.run.later(() => {
        e.classList.add('d-none');
      }, 2000);
    },
    // Actions
    actions: {
      toggleVideo() {
        if (this.showVideo) {
          Ember.set(this, 'showVideo', false);
          document.removeEventListener('scroll', this.fixVideoOnScroll);
        } else {
          document.addEventListener('scroll', this.fixVideoOnScroll);
          Ember.set(this, 'showVideo', true);
        }
      }
    }
  });
  _exports.default = _default;
});