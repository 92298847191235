define("portal-talentrh/pods/components/info-block/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rCw67uCI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"h6\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/info-block/title/template.hbs"
    }
  });
  _exports.default = _default;
});