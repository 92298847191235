define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-question/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ResultPerQuestionComponent = (_dec = Ember.computed.alias('args.answers'), _dec2 = Ember.computed.alias('args.employeeEvaluation'), _dec3 = Ember.computed.alias('args.team'), _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class ResultPerQuestionComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "answers", _descriptor, this);
      _initializerDefineProperty(this, "employeeEvaluation", _descriptor2, this);
      _initializerDefineProperty(this, "employeeEvaluationTeams", _descriptor3, this);
      _initializerDefineProperty(this, "chart", _descriptor4, this);
    }
    getLabels() {
      if (!this.answers) return [];
      const labels = [];
      this.answers.forEach(answer => {
        answer.questions.forEach(question => {
          labels.push(question.title.length > 30 ? question.title.substr(0, 30) + '...' : question.title);
        });
      });
      return labels;
    }
    getAnswersValues(answers) {
      const values = [];
      answers.forEach(answer => {
        answer.questions.forEach(question => {
          values.push(question.answer ? Number(question.answer) : 0);
        });
      });
      return values;
    }
    getAppraisedChartData() {
      const {
        appraisedAnswer
      } = this.employeeEvaluation;
      return {
        label: 'Avaliado',
        data: this.getAnswersValues(appraisedAnswer),
        borderColor: '#0263FF',
        fill: false
      };
    }
    getAppraiserChartData() {
      const {
        appraiserAnswer
      } = this.employeeEvaluation;
      return {
        label: 'Avaliador',
        data: this.getAnswersValues(appraiserAnswer),
        borderColor: '#32AA8F',
        fill: false
      };
    }
    getConsensusChartData() {
      const {
        consensusAnswer
      } = this.employeeEvaluation;
      return {
        label: 'Consenso',
        data: this.getAnswersValues(consensusAnswer),
        borderColor: '#8E30FF',
        fill: false
      };
    }
    getTeamChartData() {
      const teamsEvaluations = this.employeeEvaluationTeams;
      const teamData = {
        label: 'Equipe',
        data: [],
        borderColor: '#D85076',
        fill: false
      };
      let data = [];
      teamsEvaluations.forEach(teamEvaluation => {
        const values = this.getAnswersValues(teamEvaluation.appraiserAnswer);
        values.forEach((value, index) => {
          data[index] = data[index] !== undefined ? data[index] + value : value;
        });
      });
      data = data.map(c => c = Number(parseFloat((c / teamsEvaluations.length).toFixed(2))));
      teamData.data = data;
      return teamData;
    }
    getChatData() {
      const chatData = {
        labels: this.getLabels(),
        datasets: []
      };
      const {
        appraisedAnswer,
        appraiserAnswer,
        consensusAnswer
      } = this.employeeEvaluation;
      const employeeEvaluationTeams = this.employeeEvaluationTeams;
      if (appraisedAnswer) {
        const appraisedData = this.getAppraisedChartData();
        chatData.datasets.push(appraisedData);
      }
      if (appraiserAnswer) {
        const appraiserData = this.getAppraiserChartData();
        chatData.datasets.push(appraiserData);
      }
      if (consensusAnswer) {
        const consensusData = this.getConsensusChartData();
        chatData.datasets.push(consensusData);
      }
      if (employeeEvaluationTeams) {
        const teamData = this.getTeamChartData();
        chatData.datasets.push(teamData);
      }
      return chatData;
    }
    initChart(chartData) {
      let chart = this.chart;
      if (chart) {
        chart.data = chartData;
        chart.update();
      } else {
        let ctx = document.getElementById(`resultPerQuestionChart`).getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'radar',
          responsive: false,
          data: chartData,
          options: {
            scale: {
              angleLines: {
                display: false
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 100
              }
            },
            tooltips: {
              callbacks: {
                title: function (tooltipItem) {
                  const index = tooltipItem[0].index;
                  return `Questão ${index + 1}:`;
                },
                label: function (tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
                }
              }
            },
            legend: {
              display: false
            }
          }
        });
      }
      this.chart = chart;
    }
    init() {
      const chartData = this.getChatData();
      this.initChart(chartData);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "answers", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "employeeEvaluation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "employeeEvaluationTeams", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = ResultPerQuestionComponent;
});