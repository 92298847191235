define("portal-talentrh/pods/components/indicators/organizational-climate/feelings-by-type/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeelingsByTypeComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class FeelingsByTypeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isLoading", _descriptor, this);
      _defineProperty(this, "images", {
        happy: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/happy.svg',
        love: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/in-love.svg',
        angry: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/angry.svg',
        tired: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/tired.svg',
        smart: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/smart.svg',
        bored: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/bored.svg',
        eager: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/eager.svg',
        cool: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/cool.svg',
        dispirited: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/dispirited.svg',
        sad: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/sad.svg',
        funny: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/funny.svg',
        excited: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/excited.svg'
      });
      _defineProperty(this, "feelings", {
        Feliz: 'happy',
        Apaixonado: 'love',
        Irritado: 'angry',
        Cansado: 'tired',
        Inteligente: 'smart',
        Entediado: 'bored',
        Ansioso: 'eager',
        Legal: 'cool',
        Desanimado: 'dispirited',
        Triste: 'sad',
        Divertido: 'funny',
        Entusiasmado: 'excited'
      });
    }
    initChart(canvas) {
      let that = this;
      let {
        feelings
      } = this.args.data;
      let data = [];
      let labels = [];
      if (feelings) {
        const sum = feelings.reduce((partialSum, a) => partialSum + a.count, 0);
        feelings.forEach(feeling => {
          data.push(Math.round(feeling.count / sum * 100));
          labels.push(that.feelings[feeling.type]);
        });
      }
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'bar',
        plugins: [{
          afterDraw: _ref => {
            let {
              ctx,
              scales
            } = _ref;
            const xAxis = scales.x;
            const yAxis = scales.y;
            xAxis.ticks.forEach(async (value, index) => {
              const x = xAxis.getPixelForTick(index);
              const image = new Image();
              image.setAttribute('isLoaded', true);
              image.src = that.images[value.label];
              image.onload = function () {
                if (that.args.details) {
                  ctx.drawImage(image, x - 22, yAxis.bottom + 10, 40, 40);
                } else {
                  ctx.drawImage(image, x - 16, yAxis.bottom + 10, 30, 30);
                }
              };
            });
          }
        }],
        data: {
          labels: labels,
          datasets: [{
            fill: false,
            data: data,
            backgroundColor: '#3862bb'
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index'
          },
          layout: {
            padding: {
              bottom: that.args.details ? 50 : 40
            }
          },
          events: [],
          animation: {
            duration: 1,
            onComplete: x => {
              const chart = x.chart;
              var {
                ctx
              } = chart;
              ctx.textAlign = 'center';
              ctx.fillStyle = '#666666';
              ctx.textBaseline = 'bottom';
              ctx.font = 'bold 14px SourceSansPro';
              chart.data.datasets.forEach((dataset, i) => {
                var meta = chart.getDatasetMeta(i);
                meta.data.forEach((bar, index) => {
                  var data = dataset.data[index];
                  ctx.fillText(`${data}%`, bar.x, bar.y - 5);
                });
              });
            }
          },
          scales: {
            y: {
              max: data[0] ? data[0] + 20 : 100,
              beginAtZero: true
            },
            x: {
              display: false
            }
          },
          elements: {
            point: {
              radius: 4
            },
            line: {
              borderWidth: 1
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              //enabled: false,
              callbacks: {
                title: function () {
                  return '';
                },
                label: function (context) {
                  return `${context.label} - ${context.formattedValue}%`;
                }
              }
            }
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = FeelingsByTypeComponent;
});