define("portal-talentrh/pods/components/post-edit-image-upload/component", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "portal-talentrh/config/environment"], function (_exports, _fileField, _uploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fileField.default.extend({
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    filesDidChange: function (files) {
      const uploader = _uploader.default.create({
        // paramNamespace: 'postImage',
        url: `${_environment.default.apiUrl.social}/postImages/`,
        paramName: 'attachment',
        ajaxSettings: {
          headers: {
            'Authorization': `Bearer ${this.session.token}`
          }
        }
      });
      if (!Ember.isEmpty(files)) {
        this.swal.loading('Carregando imagem');
        uploader.upload(files[0], {
          post: this.post.id
        }).then(( /*response*/
        ) => {
          this.post.reload();
          this.toast.success('Imagem adicionada');
        }).catch(e => this.swal.catch(e));
      }
    }
  });
  _exports.default = _default;
});