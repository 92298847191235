define("portal-talentrh/helpers/bucket-url", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function bucketUrl(params /*, hash*/) {
    if (!params[0]) {
      return '/images/user-sem-imagem.png';
    }
    if (params[0] && params[0].includes('gravatar')) {
      return params[0];
    }
    return _environment.default.bucketUrl + encodeURIComponent(params[0]);
  });
  _exports.default = _default;
});