define("portal-talentrh/models/quiz", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    color: (0, _attr.default)('string', {
      defaultValue: 'blue'
    }),
    createdAt: (0, _attr.default)('date'),
    pages: (0, _attr.default)('raw', {
      defaultValue: () => [{
        questions: [{
          title: null,
          type: 'radio',
          required: false,
          order: 0,
          alternatives: [{
            label: null,
            order: 0
          }]
        }]
      }]
    }),
    status: (0, _attr.default)('string', {
      defaultValue: 'R'
    }),
    title: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    // Associations
    company: (0, _relationships.belongsTo)('company')
  });
  _exports.default = _default;
});