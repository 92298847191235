define("portal-talentrh/pods/absence-board/future/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AbsenceBoardFutureRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.getFilters();
    }
    deactivate() {
      this.controller.getFilters();
    }
  }
  _exports.default = AbsenceBoardFutureRoute;
});