define("portal-talentrh/pods/components/user-skills/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let UserSkillsComponent = (_dec = Ember.computed('args.skills'), _dec2 = Ember.computed('args.skills'), (_class = class UserSkillsComponent extends _component.default {
    get softSkills() {
      const softs = this.args.skills?.filter(_ref => {
        let {
          type
        } = _ref;
        return type === 'soft';
      });
      return softs?.map(soft => ({
        ...soft,
        showDescription: false
      }));
    }
    get hardSkills() {
      const hards = this.args.skills?.filter(_ref2 => {
        let {
          type
        } = _ref2;
        return type === 'hard';
      });
      return hards?.map(hard => ({
        ...hard,
        showDescription: false
      }));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "softSkills", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "softSkills"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hardSkills", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "hardSkills"), _class.prototype)), _class));
  var _default = UserSkillsComponent;
  _exports.default = _default;
});