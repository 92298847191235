define("portal-talentrh/pods/view-team-member/performance-management/goals/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTeamMemberPerformanceManagementGoalsRoute extends Ember.Route {
    model() {
      return this.modelFor('view-team-member');
    }
  }
  _exports.default = ViewTeamMemberPerformanceManagementGoalsRoute;
});