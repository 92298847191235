define("portal-talentrh/pods/profile/show/projects/task-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "alIYByQY",
    "block": "{\"symbols\":[\"Skeleton\",\"@color\",\"@taskInfo\",\"@openModal\",\"@tooltip\",\"@title\",\"@loading\"],\"statements\":[[6,[37,1],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-skeleton\",[[24,0,\"flex-fill d-flex justify-content-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-25\"]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"flex-fill text-center text-\",[32,2]]]],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,4],[32,3]],null]],[15,\"title\",[32,5]],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[1,[32,6]],[2,\":\\n      \"],[10,\"span\"],[14,0,\"font-weight-bold h5\"],[12],[1,[32,3,[\"count\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/show/projects/task-filter/template.hbs"
    }
  });
  _exports.default = _default;
});