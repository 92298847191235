define("portal-talentrh/pods/components/goal-achieved-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "45/wZkvR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card-body bg-white py-2\"],[12],[2,\"\\n  \"],[10,\"canvas\"],[14,1,\"chart\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/goal-achieved-chart/template.hbs"
    }
  });
  _exports.default = _default;
});