define("portal-talentrh/pods/components/tree-chart/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "portal-talentrh/config/environment", "d3-org-chart"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, _environment, _d3OrgChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TreeChartComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('args.userId'), _dec12 = Ember.computed.alias('permission.routeHome'), _dec13 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 5
  }), (_class = class TreeChartComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "permission", _descriptor6, this);
      _initializerDefineProperty(this, "fetch", _descriptor7, this);
      _initializerDefineProperty(this, "hierarchy", _descriptor8, this);
      _initializerDefineProperty(this, "chartNotLoaded", _descriptor9, this);
      _initializerDefineProperty(this, "mapUsers", _descriptor10, this);
      _initializerDefineProperty(this, "isNotCompanyChart", _descriptor11, this);
      _initializerDefineProperty(this, "routeHome", _descriptor12, this);
    }
    *loadImages(node, color) {
      const avatar = node.data.avatarUrl ? node.data.avatarUrl : '/images/user-sem-imagem.png';
      const imgSrc = avatar.toString();
      const img = new Image();
      img.classList.add('avatar-8');
      img.classList.add('position-absolute');
      img.style = `border: 5px solid ${color}; border-radius: 50%; z-index: 100; top: -42px; left: 70px;`;
      img.id = node.data.id;
      img.title = node.data.text;
      img.src = imgSrc;
      img.onload = () => {
        const linkElement = document.getElementById(`user-${node.data.id}`);
        if (linkElement) {
          const avatarPlaceholder = document.getElementById(`img-${node.data.id}`);
          if (avatarPlaceholder) {
            linkElement.removeChild(avatarPlaceholder);
          }
          this.mapUsers[node.data.id] = img;
          linkElement.appendChild(img);
        }
      };
      yield (0, _emberConcurrency.timeout)(1000);
    }
    *drawChart() {
      try {
        yield this.loadHierarchy.perform();
        const that = this;
        const chart = yield new _d3OrgChart.OrgChart().container('#tree-chart').svgHeight(window.innerHeight - 280).data(this.hierarchy).nodeHeight(() => 120).nodeWidth(() => 220).childrenMargin(d => {
          if (!this.isNotCompanyChart) {
            return d.depth === 0 ? -100 : 120;
          }
          return 120;
        }).compactMarginBetween(() => 30).compactMarginPair(() => 80).neightbourMargin(() => 30).siblingsMargin(() => 80).rootMargin(50).buttonContent(_ref => {
          let {
            node
          } = _ref;
          const colors = ['#9C27B0', '#008B71', '#36A2EB', '#EB7D18', '#FF6384', '#F4BE37', '#EED656', '#DD8C6A', '#D45ADE', '#74F155', '#9C27B0', '#008B71', '#36A2EB', '#EB7D18', '#FF6384', '#F4BE37', '#EED656', '#DD8C6A', '#D45ADE', '#74F155'];
          let color;
          if (node.depth < colors.length) {
            color = colors[node.depth];
          } else {
            color = '#9C27B0';
          }
          return `
            <div
              style="
                color:${color};
                border-radius:8px;
                padding: 9px 10px 8px 10px;
                margin:auto auto;
                margin-bottom: 0px;
                background-color:#ffffff;
                border: 2px solid ${color}"
            >
              <span style="font-size:14px">
                ${node.children ? `<i class="fas fa-minus"></i>` : `<i class="fas fa-plus"></i>`}
              </span>
            </div>
          `;
        }).nodeContent(node => {
          const colors = ['#9C27B0', '#008B71', '#36A2EB', '#EB7D18', '#FF6384', '#F4BE37', '#EED656', '#DD8C6A', '#D45ADE', '#74F155', '#9C27B0', '#008B71', '#36A2EB', '#EB7D18', '#FF6384', '#F4BE37', '#EED656', '#DD8C6A', '#D45ADE', '#74F155'];
          let color;
          if (node.depth + 1 < colors.length) {
            color = colors[node.depth];
          } else {
            color = '#9C27B0';
          }
          let img = null;
          if (this.mapUsers[node.data.id]) {
            img = this.mapUsers[node.data.id];
          }
          let htmlImg;
          if (img) {
            htmlImg = img.outerHTML;
          } else {
            htmlImg = `
              <div
                id="img-${node.data.id}"
                class="avatar-8 position-absolute d-flex align-items-center justify-content-center"
                style="border: 5px solid ${color}; border-radius: 50%; z-index: 100; top: -42px; left: 70px; background-color: #ffffff;"
              >
                <i class="fa-spinner spinner-border"></i>
              </div>
            `;
            this.loadImages.perform(node, color);
          }
          return `
            <div
              style="width:100%; height: 100%; border-radius: 5px; border-top: 8px solid ${color}; font-family: 'Source Sans Pro', sans-serif;"
              class="cursor-move align-items-center d-flex justify-content-center pb-2 shadow"
            >
              <a id="user-${node.data.id}" href="${_environment.default.appUrl.social}profile/${node.data.id}" target="_blank">
                ${htmlImg}
              </a>
              <div class="d-flex flex-column align-items-center mt-2 overflow-hidden" style="gap: 10px;">
                <p
                  title="${node.data.text}"
                  class="bold text-truncate"
                  style="width: 200px;
                  color: ${color};
                  font-size: 1.1rem;
                  text-align: center;"
                >
                  ${node.data.text}${node.data.text.length >= 22 ? '...' : ''}
                </p>
                <p
                  title="${node.data.role}"
                  class="text-truncate"
                  style="font-size: 1rem; width: 200px; text-align: center;"
                >
                  ${node.data.role}
                </p>
              </div>
            </div>
          `;
        }).linkUpdate(function (d) {
          d3.select(this).attr('stroke', () => '#757575').attr('stroke-width', () => 2);
          if (!that.isNotCompanyChart) {
            if (d.depth === 1) {
              d3.select(this).attr('display', 'none');
            }
          }
        }).nodeUpdate(function (d) {
          if (!that.isNotCompanyChart) {
            if (d.depth === 0) {
              d3.select(this).attr('display', 'none');
              d.height = 0;
              d.width = 0;
            }
          }
        }).render();

        // layout na horizontal
        let compact;
        chart.compact(!!(compact++ % 2)).render();

        // botões expandAll e collapseAll comentados para serem tratados da forma correta posteriormente

        // faz com que no collapseAll não suma todo o chart
        // const allNodes = chart.getChartState().allNodes;
        // const userToKeepExpanded = this.getTopLevelUser(allNodes);

        // const btnCollapse = document.getElementById('tree-chart-button-collapse');
        // btnCollapse.onclick = function () {
        //   chart.collapseAll();

        //   userToKeepExpanded.forEach(function (node) {
        //     chart.setExpanded(`${node}`);
        //   });

        //   chart.render();

        //   chart.fit();
        // };

        // const btnExpand = document.getElementById('tree-chart-button-expand');
        // btnExpand.onclick = function () {
        //   chart.expandAll();

        //   chart.fit();
        // };

        const btnZoomIn = document.getElementById('tree-chart-button-zoom-in');
        btnZoomIn.onclick = function () {
          chart.zoomIn();
        };
        const btnZoomOut = document.getElementById('tree-chart-button-zoom-out');
        btnZoomOut.onclick = function () {
          chart.zoomOut();
        };
        const btnZoomFit = document.getElementById('tree-chart-button-zoom-fit');
        btnZoomFit.onclick = function () {
          chart.fit();
        };
      } catch (error) {
        this.chartNotLoaded = true;
        if (error == 'Error: cycle') {
          yield this.swal.warning(`
          Existem inconsistências na estrutura da sua empresa.
          Verifique o vínculo entre líderes e liderados, observando se os níveis do organograma estão corretos.
        `);
        } else if (error.message.split(' ').includes('missing:')) {
          const missingId = error.message.split(' ')[1];
          const user = yield this.fetch.request(`users/${missingId}/inactive`);
          yield this.swal.warning(`
          Existem inconsistências na estrutura da sua empresa.
          O usuário ${user.firstName} ${user.lastName} possuí liderados e encontra-se inativo.
        `);
        } else {
          yield this.swal.warning(error);
        }
        return this.router.transitionTo(this.routeHome);
      }
    }
    *loadHierarchy() {
      let hierarchy;
      if (this.isNotCompanyChart) {
        hierarchy = yield this.ajax.request(`/users/${this.args.userId}/organizationChart`);
      } else {
        hierarchy = yield this.ajax.request('/companies/organizationChart');
      }
      this.hierarchy = hierarchy;
    }
    getTopLevelUser(arr) {
      if (!this.isNotCompanyChart) {
        const root = arr.filter(el => el.data.parentId === 'fakeRoot').map(el => el.data.id);
        return root;
      }
      return [this.args.userId];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hierarchy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "chartNotLoaded", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "mapUsers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isNotCompanyChart", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "routeHome", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadImages", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "loadImages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drawChart", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "drawChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadHierarchy", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadHierarchy"), _class.prototype)), _class));
  _exports.default = TreeChartComponent;
});