define("portal-talentrh/pods/company/policy/not-accepted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "837vy/Hu",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"],[24,\"data-keyboard\",\"false\"],[4,[38,1],[[30,[36,0],[[32,0,[\"getPdf\"]]],null]],null]],[[\"@show\",\"@size\"],[true,\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[8,[32,1,[\"Header\"]],[[24,0,\"pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"h2\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,2],[[32,0,[\"model\",\"description\"]]],null]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"model\",\"pdfUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"object\"],[15,\"data\",[32,0,[\"URLPdf\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"650\"],[14,4,\"application/pdf\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,0],[[32,0,[\"acceptPolicy\"]]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Aceitar política\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"html\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/policy/not-accepted/template.hbs"
    }
  });
  _exports.default = _default;
});