define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-engagement/ranking-influencers-detail/publication-item/component", ["exports", "@glimmer/component", "portal-talentrh/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const CDN_IMAGES_URL = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images';
  const IMAGES = {
    ad: 'ad.svg',
    announcement: 'announcements.svg',
    event: 'appointments.svg',
    message: 'post.svg',
    news: 'news.svg',
    survey: 'survey.svg'
  };
  const IMAGES_WIDTH = {
    ad: '90px',
    announcement: '68px',
    event: '90px',
    message: '84px',
    news: '78px',
    survey: '90px'
  };
  const POST_TYPES = {
    ad: 'um classificado',
    announcement: 'um comunicado',
    event: 'um evento',
    message: 'um post',
    news: 'uma notícia',
    survey: 'uma enquete'
  };
  let IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInfluencersDetailPublicationItemComponent = (_dec = Ember.computed('args.post.type'), _dec2 = Ember.computed('args.post.type'), _dec3 = Ember.computed('args.post.type'), _dec4 = Ember.computed('args.post.type'), (_class = class IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInfluencersDetailPublicationItemComponent extends _component.default {
    get svg() {
      const {
        post
      } = this.args;
      return `${CDN_IMAGES_URL}/${IMAGES[post.type]}`;
    }
    get description() {
      const {
        post
      } = this.args;
      return `Publicou ${POST_TYPES[post.type]}`;
    }
    get link() {
      const {
        post
      } = this.args;
      return `${_environment.default.appUrl.social}post/${post.id}`;
    }
    get style() {
      const {
        post
      } = this.args;
      return Ember.String.htmlSafe(`
      width: ${IMAGES_WIDTH[post.type]};
      margin-left: 10px;
    `);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "svg", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "svg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "link", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "link"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "style", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "style"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInfluencersDetailPublicationItemComponent;
});