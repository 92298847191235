define("portal-talentrh/pods/company/decision/index/item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item', 'px-0'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Multiline computed properties
    showResults: Ember.computed('decision.votes.[]', 'closed', function () {
      let hasVoted = this.decision.votes.findBy('user.id', String(this.session.user.id));
      return hasVoted || this.closed;
    }),
    isCreator: Ember.computed('session.user.id', 'decision.createdBy.id', function () {
      return Ember.get(this, 'decision.createdBy.id') == String(this.session.user.id);
    }),
    participants: Ember.computed('decision.users.[]', function () {
      const limit = 3;
      if (!this.decision.users || !this.decision.users.length) {
        return Ember.A();
      }
      let length = this.decision.users.length;
      if (length > limit) {
        return this.decision.users.slice(length - limit, length);
      }
      return this.decision.users;
    }),
    // Tasks
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja remover essa Tomada de Decisão?');
        if (!value) {
          return;
        }
        yield this.decision.destroyRecord();
        this.decisions.removeObject(this.decision);
        this.toast.success('Assunto removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});