define("portal-talentrh/pods/company/decision/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return Ember.RSVP.hash({
        closedDecisions: this.store.loadRecords('decision', 'decisions/closed'),
        openDecisions: this.store.loadRecords('decision', 'decisions/open')
      });
    },
    deactivate() {
      this.controller.set('filter', '');
    },
    actions: {
      refreshDecisionIndex() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});