define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-profile/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsHumanResourcesCompanyProfileIndexRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyProfileIndexRoute;
});