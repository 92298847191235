define("portal-talentrh/models/job-function", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    active: (0, _model.attr)('boolean'),
    visible: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    homeTime: (0, _model.attr)('number'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    instructionLevel: (0, _model.belongsTo)('instruction-level', {
      async: true
    }),
    vacancies: (0, _model.hasMany)('vacancy', {
      inverse: 'jobFunctions'
    }),
    skills: (0, _model.hasMany)('skill', {
      inverse: 'jobFunctions'
    }),
    trainings: (0, _model.hasMany)('training', {
      inverse: 'jobFunctions'
    })
  });
  _exports.default = _default;
});