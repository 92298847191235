define("portal-talentrh/pods/company/chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h0zCHUOD",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3 h-100\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"d-flex align-items-center justify-content-between\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 font-weight-bold text-secondary\"],[12],[2,\"Organograma da Empresa\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tree-chart\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/chart/template.hbs"
    }
  });
  _exports.default = _default;
});