define("portal-talentrh/pods/components/widgets/next-events/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    // Services
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    didInsertElement() {
      let session = this.session;
      let currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenNextEventWidget = session.data.lastOpenNextEventWidget;
      if (lastOpenNextEventWidget && lastOpenNextEventWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        this.loadWidget.perform();
      }
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.loadWidget.perform();
    },
    loadWidget: (0, _emberConcurrency.task)(function* () {
      let today = (0, _moment.default)().toDate();
      today.setHours(0, 0, 0, 0);
      return yield this.store.loadRecords('post', '/posts', {
        data: {
          where: JSON.stringify({
            finalDate: today,
            archived: false
          }),
          type: "event",
          sort: "initialDate ASC",
          limit: 5
        },
        host: 'core'
      });
    }).drop(),
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenNextEventWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});