define("portal-talentrh/pods/components/news-feed-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['post-new', 'card', 'shadow-sm', 'mb-2'],
    classNameBindings: ['showNewPost:d-block:d-none'],
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    isAdmin: Ember.computed.alias('permission.admin'),
    didInsertElement() {
      this.element.addEventListener('click', () => {
        this.element.classList.add('focus');
      });
    },
    showNewPost: Ember.computed('accessProfile.{postAd,postAnnouncement,postEvent,postMessage,postNews,postQuiz}', 'companyConfig.{permitAd,permitAnnouncement,permitEvent,permitNews,permitPostMessage,permitSurvey}', 'isAdmin', function () {
      return this.isAdmin || this.companyConfig.permitPostMessage && (this.accessProfile.postMessage || this.isAdmin) || this.companyConfig.permitAnnouncement && (this.accessProfile.postAnnouncement || this.isAdmin) || this.companyConfig.permitEvent && (this.accessProfile.postEvent || this.isAdmin) || this.companyConfig.permitNews && (this.accessProfile.postNews || this.isAdmin) || this.companyConfig.permitAd && (this.accessProfile.postAd || this.isAdmin) || this.companyConfig.permitSurvey && (this.accessProfile.postQuiz || this.isAdmin);
    }),
    prepareTabActive(element) {
      let li = element.firstElementChild;
      if (!li) {
        return;
      }
      let tab = li.firstElementChild;
      if (!tab) {
        return;
      }
      tab.click();
    },
    actions: {
      inputFocus() {
        Ember.run.later(() => document.getElementById('post-new-textarea').focus(), 0);
      }
    }
  });
  _exports.default = _default;
});