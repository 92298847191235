define("portal-talentrh/pods/human-resources/user-solicitations/index/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed.equal('userSolicitation.status', 'pending-hr'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _initializerDefineProperty(this, "permission", _descriptor4, this);
      _initializerDefineProperty(this, "showModal", _descriptor5, this);
      _initializerDefineProperty(this, "showReplyModal", _descriptor6, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor7, this);
      _initializerDefineProperty(this, "pendingHR", _descriptor8, this);
    }
    get title() {
      if (this.pendingHR) {
        return 'Gerenciar solicitação';
      }
      return 'Visualizar solicitação';
    }
    *approve() {
      const {
        solicitation
      } = this.args;
      const type = solicitation.solicitationType;
      const status = solicitation.status;
      const needsManagerApproval = solicitation.needsManagerApproval;
      try {
        if ((type === 4 || type === 7) && (status !== 'pending-hr' || !needsManagerApproval)) {
          this.openReplyModal();
          this.closeModal();
        } else {
          const {
            dismiss
          } = yield this.swal.fire({
            title: 'Aprovar solicitação?',
            text: this.permission.admin ? `
            Seu perfil é administrador, deseja realmente aprovar em definitivo esta solicitação?
            Se deseja encaminhar a gestão de pessoas para aprovação, utilize o menu Meu Time` : '',
            inputAttributes: {
              maxlength: 500
            },
            input: 'textarea',
            confirmButtonText: 'Aprovar',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            inputPlaceholder: 'Informe um motivo (opcional)',
            preConfirm: response => {
              return this.ajax.put(`userSolicitations/${solicitation.id}/hrRespond`, {
                data: {
                  response,
                  approve: 1
                }
              });
            }
          });
          if (dismiss) {
            return;
          }
          this.setDisabled();
          this.closeModal();
          this.toast.success('Solicitação aprovada');
        }
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }
    *reject() {
      const {
        solicitation
      } = this.args;
      try {
        const {
          dismiss
        } = yield this.swal.fire({
          title: 'Reprovar solicitação?',
          text: this.permission.admin ? `
          Seu perfil é administrador, deseja realmente reprovar em definitivo esta solicitação?
          Se deseja encaminhar a gestão de pessoas para aprovação, utilize o menu Meu Time` : '',
          inputAttributes: {
            maxlength: 500
          },
          input: 'textarea',
          confirmButtonText: 'Reprovar',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          inputPlaceholder: 'Informe um motivo',
          preConfirm: response => {
            return this.ajax.put(`userSolicitations/${solicitation.id}/hrRespond`, {
              data: {
                response,
                approve: 0
              }
            });
          }
        });
        if (dismiss) {
          return;
        }
        this.toast.success('Solicitação reprovada');
        this.setDisabled();
        this.closeModal();
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }
    *approveReply(data) {
      const validated = yield this.validateReplyInputs(data);
      if (!validated) return;
      const {
        solicitation
      } = this.args;
      try {
        yield this.ajax.put(`userSolicitations/${solicitation.id}/hrRespond`, {
          data
        });
        this.setDisabled();
        this.toast.success('Solicitação aprovada');
        this.closeReplyModal();
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }
    setDisabled() {
      this.isDisabled = true;
    }
    openModal() {
      this.showModal = true;
    }
    closeModal() {
      this.showModal = false;
    }
    openReplyModal() {
      this.showReplyModal = true;
    }
    closeReplyModal() {
      this.showReplyModal = false;
    }
    validateReplyInputs(data) {
      const {
        solicitation
      } = this.args;
      const type = solicitation.solicitationType;
      if (type === 4) {
        if (!data.extraFields.vacationInitial) {
          this.swal.warning('A data inicial de gozo deve ser informada.');
          return false;
        }
        if (!data.extraFields.vacationDays) {
          this.swal.warning('A quantidade de dias de gozo deve ser informada.');
          return false;
        }
        if (data.extraFields.vacationPaidType) {
          if (!data.extraFields.vacationDaysPaid) {
            this.swal.warning('A quantidade de dias abonados deve ser informada.');
            return false;
          }
        }
      } else if (type === 7) {
        if (data.extraFields.motive != 0 && data.extraFields.motive != 1) {
          this.swal.warning('A causa da rescisão deve ser informada.');
          return false;
        }
        if (!data.extraFields.terminationDate) {
          this.swal.warning('A data da rescisão deve ser informada.');
          return false;
        }
        if (!data.extraFields.noticeDate) {
          this.swal.warning('A data de aviso deve ser informada.');
          return false;
        }
      }
      return true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showReplyModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pendingHR", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "approve", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approveReply", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "approveReply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDisabled", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openReplyModal", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openReplyModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeReplyModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeReplyModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateReplyInputs", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "validateReplyInputs"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});