define("portal-talentrh/models/compensatory-time", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    balance: (0, _model.attr)('number'),
    date: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    totalBalance: (0, _model.attr)('number'),
    initialDate: (0, _model.attr)('string'),
    endDate: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    user: (0, _model.attr)('number'),
    // Associations
    company: (0, _relationships.belongsTo)('company')
  });
  _exports.default = _default;
});