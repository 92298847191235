define("portal-talentrh/pods/components/socket-handler/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    socket: Ember.inject.service(),
    init() {
      this._super(...arguments);
      if (this.get('session.isAuthenticated')) {
        // console.log("Ember autenticado, deve autenticar o socket");
        this.socket.authenticateSocket();
        this.socket.initSocketListeners();
      } /*else {
        console.log('Ember não autenticado, nao vai autenticar o socket');
        }*/
    }
  });
  _exports.default = _default;
});