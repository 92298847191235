define("portal-talentrh/pods/components/post-comments/comment/more-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6F5GFzbw",
    "block": "{\"symbols\":[\"@onlyShow\",\"@isOwner\"],\"statements\":[[6,[37,5],[[30,[36,4],[[32,2],[30,[36,3],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown position-absolute t-2 r-2\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn py-0\"],[14,1,\"post-options\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-ellipsis-h fa-lg\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right shadow-sm\"],[14,\"aria-labelledby\",\"post-options\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn text-left w-100 py-0 px-3 py-1\"],[15,\"onclick\",[30,[36,0],[[32,0],\"enableEditMode\"],null]],[12],[2,\"\\n        Editar\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"button\"],[14,0,\"btn text-left w-100 py-0 px-3 py-1\"],[15,\"onclick\",[30,[36,2],[[35,1]],null]],[12],[2,\"\\n        Excluir\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"deleteComment\",\"perform\",\"not\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-comments/comment/more-options/template.hbs"
    }
  });
  _exports.default = _default;
});