define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-answers/answer-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cAG4qmON",
    "block": "{\"symbols\":[\"page\",\"question\",\"index\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item t-0 py-3 bg-white position-sticky z-index-1\"],[12],[2,\"\\n    \"],[10,\"strong\"],[14,0,\"text-primary\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"questions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"view-team-member/performance-management/employee-evaluations/modal-answers/answer-list/question\",[],[[\"@question\",\"@index\"],[[32,2],[32,3]]],null],[2,\"\\n\"],[6,[37,0],[[32,2,[\"teamAnswers\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"view-team-member/performance-management/employee-evaluations/modal-answers/answer-list/team-answers\",[],[[\"@question\",\"@index\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2,3]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"groupedAnswers\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-answers/answer-list/template.hbs"
    }
  });
  _exports.default = _default;
});