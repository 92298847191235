define("portal-talentrh/pods/company/minutes-of-meeting/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.store.createRecord('meeting-minute');
    },
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  });
  _exports.default = _default;
});