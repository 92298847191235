define("portal-talentrh/pods/components/indicators/company-engagement/rank-influencers/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RankInfluencersComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class RankInfluencersComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chart", _descriptor, this);
    }
    initChart(canvas) {
      const that = this;
      const images = ['https://i.stack.imgur.com/2RAv2.png', 'https://i.stack.imgur.com/Tq5DA.png', 'https://i.stack.imgur.com/3KRtW.png', 'https://i.stack.imgur.com/iLyVi.png', 'https://i.stack.imgur.com/2RAv2.png', 'https://i.stack.imgur.com/Tq5DA.png', 'https://i.stack.imgur.com/3KRtW.png', 'https://i.stack.imgur.com/iLyVi.png', 'https://i.stack.imgur.com/2RAv2.png', 'https://i.stack.imgur.com/Tq5DA.png', 'https://i.stack.imgur.com/3KRtW.png', 'https://i.stack.imgur.com/iLyVi.png'];
      const roundedImage = (ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
      };
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'bar',
        // plugins: [{
        //   afterDraw: ({ ctx, scales }) => {
        //     const xAxis = scales.x;
        //     const yAxis = scales.y;

        //     xAxis.ticks.forEach((value, index) => {
        //       const x = xAxis.getPixelForTick(index);
        //       const image = new Image();
        //       image.setAttribute('isLoaded', true);

        //       image.src = images[index];

        //       image.onload = function() {
        //         ctx.save();
        //         roundedImage(ctx, x - 12, yAxis.bottom - 10, 50, 50, 50);
        //         ctx.createPattern(image, 'no-repeat');
        //         ctx.clip();
        //         ctx.drawImage(image, x - 12, yAxis.bottom - 10, 50, 50);
        //         ctx.restore();
        //       };
        //     });
        //   },
        // }],
        data: {
          datasets: [{
            label: 'Curtidas',
            backgroundColor: '#235BA8',
            data: that.args.rankingInfluencerData?.likes,
            order: 1
          }, {
            label: 'Comentários',
            backgroundColor: '#2D9CDB',
            data: that.args.rankingInfluencerData?.comments,
            order: 1
          }, {
            label: 'Compartilhamentos',
            backgroundColor: '#56CCF2',
            data: that.args.rankingInfluencerData?.shareds,
            order: 1
          }, {
            label: 'Total de engajamento',
            data: that.args.rankingInfluencerData?.total,
            type: 'line',
            order: 1,
            borderColor: '#008B71',
            backgroundColor: ['#008B71'],
            tension: 0.5
          }],
          labels: that.args.rankingInfluencerData?.labels
        },
        plugins: [{
          beforeInit: chart => {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
              fitValue.bind(chart.legend)();
              return this.height += 10;
            };
          }
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 4
            },
            line: {
              borderWidth: 1
            }
          },
          plugins: {
            legend: {
              labels: {
                fontColor: '#666666',
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            },
            tooltip: {
              callbacks: {
                title: function (tooltipItem) {
                  return tooltipItem[0].label.replaceAll(',', ' ');
                }
              }
            },
            title: {
              color: 'red'
            }
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = RankInfluencersComponent;
});