define("portal-talentrh/pods/notification/meeting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Actions
    actions: {
      viewAndSetAsRead() {
        this.click();
        this.router.transitionTo('company.minutes-of-meeting.index.view', Ember.get(this, 'notification.meetingMinute.id'));
      }
    }
  });
  _exports.default = _default;
});