define("portal-talentrh/pods/components/post-edit-images-select/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['row', 'nela-post-image-edit'],
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    maxImages: 8,
    images: Ember.computed.filterBy('post.images', 'summernote', false),
    init() {
      this._super(...arguments);
      if (this.maxQuantity) {
        Ember.set(this, 'maxImages', this.maxQuantity);
      }
    },
    removeImage: (0, _emberConcurrency.task)(function* (image) {
      try {
        let {
          value
        } = yield this.swal.confirm('Remover imagem?', {
          text: 'Esta ação não pode ser desfeita'
        });
        if (!value) {
          return;
        }
        yield image.destroyRecord();
        this.toast.success('Imagem removida');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      click() {
        document.getElementById('post-image-upload').click();
      }
    }
  });
  _exports.default = _default;
});