define("portal-talentrh/models/good-transfer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATUS = {
    'pending-manager': 'Pendente para responsável',
    'pending-controllership': 'Pendente para controladoria',
    'approved': 'Aprovado',
    'disapproved': 'Reprovado'
  };
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _attr.default)('date'),
    date: (0, _attr.default)('date'),
    motive: (0, _attr.default)('string'),
    observations: (0, _attr.default)('string'),
    status: (0, _attr.default)('string', {
      defaultValue: 'pending-manager'
    }),
    telephoneExtension: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    // Associations
    approvedBy: (0, _relationships.belongsTo)('user'),
    originBranch: (0, _relationships.belongsTo)('branch'),
    originCostCenter: (0, _relationships.belongsTo)('department'),
    targetBranch: (0, _relationships.belongsTo)('branch'),
    targetCostCenter: (0, _relationships.belongsTo)('department'),
    user: (0, _relationships.belongsTo)('user'),
    attachments: (0, _relationships.hasMany)('good-transfer-attachment'),
    comments: (0, _relationships.hasMany)('good-transfer-comment'),
    historic: (0, _relationships.hasMany)('good-transfer-historic'),
    items: (0, _relationships.hasMany)('good-transfer-item'),
    // Computed Properties
    formatedCreatedAt: Ember.computed('createdAt', function () {
      let mDate = (0, _moment.default)(this.createdAt);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    formatedDate: Ember.computed('date', function () {
      let mDate = (0, _moment.default)(this.date);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    readableStatus: Ember.computed('status', function () {
      return STATUS[this.status] || 'Sem status';
    })
  });
  _exports.default = _default;
});