define("portal-talentrh/pods/feed/index/setup-account/leader/search/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    filters: null,
    warningRelatedUser: false,
    // Tasks
    createLeaderRequest: (0, _emberConcurrency.task)(function* () {
      try {
        let relatedUser = this.leader;
        if (!relatedUser) {
          return this.toast.warning('Selecione seu líder imediato antes de prosseguir!');
        }
        yield this.ajax.post('leadershipRequests', {
          data: {
            leadershipRequest: {
              type: 'led',
              relatedUser
            }
          }
        });
        this.transitionToRoute('feed.index.setup-account.led');
        this.toast.success('Enviamos uma solicitação para seu líder.');
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop(),
    filterTerm: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }).restartable(),
    // Custom methods
    getFilters() {
      let filters = {
        exclude: 'self'
      };
      if (this.term) {
        filters.term = this.term;
      }
      Ember.set(this, 'filters', filters);
    }
  });
  _exports.default = _default;
});