define("portal-talentrh/models/task", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    conclusionDate: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    initialDate: (0, _attr.default)('date'),
    link: (0, _attr.default)('string'),
    priority: (0, _attr.default)('string'),
    progress: (0, _attr.default)('number'),
    step: (0, _attr.default)('string'),
    storyPoints: (0, _attr.default)('number'),
    time: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    parentTask: (0, _relationships.belongsTo)('task', {
      inverse: null
    }),
    project: (0, _relationships.belongsTo)('project'),
    taskColumn: (0, _relationships.belongsTo)('taskColumn'),
    userCreate: (0, _relationships.belongsTo)('user'),
    taskList: (0, _relationships.belongsTo)('taskList'),
    userResponsible: (0, _relationships.belongsTo)('user'),
    attachments: (0, _relationships.hasMany)('taskAttachment'),
    comments: (0, _relationships.hasMany)('taskComment'),
    follows: (0, _relationships.hasMany)('user'),
    subTasks: (0, _relationships.hasMany)('task', {
      inverse: null
    }),
    taskHistories: (0, _relationships.hasMany)('taskHistory'),
    taskReminders: (0, _relationships.hasMany)('taskReminder')
  });
  _exports.default = _default;
});