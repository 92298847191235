define("portal-talentrh/pods/components/user-action-popup-insight/content/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const INSIGHT_TYPES = {
    birthday: 'Aniversariante do dia',
    admissionAnniversary: 'Aniversariante de empresa',
    accessFrequency: 'Registrar ação',
    feeling: 'Registrar ação',
    feedback: 'Feedback',
    post: 'Registrar ação',
    pdi: 'PDI'
  };
  let ContentComponent = (_dec = Ember.inject.controller('habitus.index'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed.alias('session.showUserPreviewInsight'), _dec15 = Ember.computed.alias('habitus.showInsightActionModal'), _dec16 = Ember.computed.alias('habitus.insightLedActionModal'), _dec17 = Ember.computed.alias('habitus.insightActionModal'), _dec18 = Ember.computed.alias('habitus.multipleUsersActionModal'), _dec19 = Ember.computed.alias('habitus.isDetails'), _dec20 = Ember.computed.or('permission.accessProfile.allowSendMessageIndividualChat', 'permission.admin'), _dec21 = Ember.computed.or('user.accessProfile.allowSendMessageIndividualChat', 'user.accessProfile.admin'), _dec22 = Ember._action, _dec23 = Ember.computed('args.insight.type'), _dec24 = Ember.computed('args.insight.type'), _dec25 = Ember.computed('args.{insightLed.id,user.id,insight.type}'), _dec26 = Ember.computed('args.insight.type'), _dec27 = Ember.computed('args.insight.type'), _dec28 = Ember._action, _dec29 = Ember._action, (_class = class ContentComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "habitusController", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "swal", _descriptor6, this);
      _initializerDefineProperty(this, "habitus", _descriptor7, this);
      _initializerDefineProperty(this, "router", _descriptor8, this);
      _initializerDefineProperty(this, "toast", _descriptor9, this);
      _initializerDefineProperty(this, "isSubordinate", _descriptor10, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor11, this);
      _initializerDefineProperty(this, "jobRole", _descriptor12, this);
      _initializerDefineProperty(this, "showModalAction", _descriptor13, this);
      _initializerDefineProperty(this, "showUserPreviewInsight", _descriptor14, this);
      _initializerDefineProperty(this, "showInsightActionModal", _descriptor15, this);
      _initializerDefineProperty(this, "insightLedActionModal", _descriptor16, this);
      _initializerDefineProperty(this, "insightActionModal", _descriptor17, this);
      _initializerDefineProperty(this, "multipleUsersActionModal", _descriptor18, this);
      _initializerDefineProperty(this, "isDetails", _descriptor19, this);
      _initializerDefineProperty(this, "iCanSendMessageIndividualChat", _descriptor20, this);
      _initializerDefineProperty(this, "heSendMessageIndividualChat", _descriptor21, this);
    }
    start(element) {
      element.addEventListener('mouseover', () => this._onMouseover(), false);
      element.addEventListener('mouseleave', () => this._onMouseleave(), false);
    }
    get actionButton() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      const typeText = INSIGHT_TYPES[type];
      return typeText;
    }
    get detailsRoute() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      return `habitus.details.${this._camelCaseToDash(type)}`;
    }
    get avdUrl() {
      const {
        insightLed,
        user,
        insight
      } = this.args;
      const type = insight.get('type');
      if (type === 'pdi') {
        return `pdi/new/?user=${user.get('id')}&insightLed=${insightLed.get('id')}`;
      }
      if (type === 'feedback') {
        return `feedback/new/?user=${user.get('id')}&insightLed=${insightLed.get('id')}`;
      }
      return '';
    }
    get noDateInfoText() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      if (type === 'pdi') {
        return 'Liderado não possui PDI\'s';
      }
      if (type === 'feedback') {
        return 'Liderado não possui feedbacks';
      }
      return 'Não informado';
    }
    get dateTitle() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      if (type === 'pdi') {
        return 'Data do último PDI';
      }
      if (type === 'feedback') {
        return 'Data do último feedback';
      }
      if (type === 'accessFrequency') {
        return 'Data/hora do último acesso';
      }
      return '';
    }
    _onMouseover() {
      this.showUserPreviewInsight = true;
    }
    _onMouseleave() {
      this.showUserPreviewInsight = false;
    }

    /**
     * camelCaseToDash('accessFrequency') => "access-frequency"
     * camelCaseToDash('waitAMoment') => "wait-a-moment"
     */
    _camelCaseToDash(str) {
      return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }
    openModalRegisterAction() {
      const {
        insight,
        insightLed
      } = this.args;
      this.showInsightActionModal = true;
      this.showUserPreviewInsight = false;
      this.insightLedActionModal = insightLed;
      this.insightActionModal = insight;
      this.multipleUsersActionModal = false;
    }
    *changeLedStatus(status) {
      try {
        const {
          insightLed
        } = this.args;
        insightLed.status = status;
        this.changeStatus.perform();
        if (status === 'noSend') {
          this.toast.success('Mensagem não será enviada');
        } else if (status === 'pending') {
          this.toast.success('Habilitado o envio da mensagem');
          const insightId = insightLed.get('insight').get('id');
          const insight = yield this.store.findRecord('insight', insightId);
          if (insight.get('status') === 'concluded') {
            insight.status = 'pending';
            yield insight.save();
            this.habitusController.reloadRecords();
          }
        }
      } catch (e) {
        console.error(e);
        this.swal.catch(e);
      }
    }
    *changeStatus() {
      const {
        insightLed
      } = this.args;
      try {
        yield insightLed.save();
        this.habitusController.reloadRecords();
      } catch (e) {
        this.swal.catch(e);
      }
    }
    goToDetails() {
      this.isDetails = true;
      const {
        insightLed
      } = this.args;
      this.router.transitionTo(this.detailsRoute, this.args.user.get('id'), {
        queryParams: {
          insightLed: insightLed.get('id')
        }
      });
    }
    changeInsightLedConcluded() {
      const {
        insightLed
      } = this.args;
      insightLed.status = 'concluded';
      insightLed.save();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "habitusController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isSubordinate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "jobRole", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showModalAction", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "showUserPreviewInsight", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showInsightActionModal", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "insightLedActionModal", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "insightActionModal", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "multipleUsersActionModal", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isDetails", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "iCanSendMessageIndividualChat", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "heSendMessageIndividualChat", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionButton", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "actionButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "detailsRoute", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "detailsRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "avdUrl", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "avdUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "noDateInfoText", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "noDateInfoText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateTitle", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "dateTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModalRegisterAction", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "openModalRegisterAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeLedStatus", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeLedStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeStatus", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToDetails", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "goToDetails"), _class.prototype)), _class));
  _exports.default = ContentComponent;
});