define("portal-talentrh/services/socket", ["exports", "portal-talentrh/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP: {
      version
    }
  } = _environment.default;
  var _default = Ember.Service.extend({
    notification: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    groups: Ember.inject.service(),
    habitus: Ember.inject.service(),
    authenticateSocket() {
      io.socket.request({
        method: 'post',
        url: '/socketlogin',
        data: {
          origin: 'nela-web',
          version: version.split('+')[0]
        },
        headers: this.get('session.authorizationBearer')
      }, function (response) {
        Ember.debug(`Socket login: ${response}`);
      });
    },
    initSocketListeners() {
      io.socket.on('notification', notification => {
        if (notification.notifyBrowser) {
          this.notification.showSocketNotification('Voors', notification.message);
        }
      });
      io.socket.on('needsAuthentication', data => {
        Ember.debug(`*** Pediu autenticação *** : ${data.body}`);
        this.authenticateSocket();
      });
      io.socket.on('insightHabitus', async () => {
        this.habitus.reloadFilters = true;
        this.habitus.reloadFiltersSocket = true;
        await (0, _emberConcurrency.timeout)(300);
        this.habitus.reloadFiltersSocket = false;
      });
      io.socket.on('logoutAll', () => {
        Ember.debug('Invalidando a sessão pelo sistema.');
        location.reload(true);
        this.session.invalidate();
      });
    }
  });
  _exports.default = _default;
});