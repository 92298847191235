define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/evolution-chart/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    permission: Ember.inject.service(),
    showJobFunction: Ember.computed.alias('permission.companyConfig.showJobFunction'),
    onlyAppraiserAnswers: Ember.computed.alias('permission.companyConfig.onlyAppraiserAnswers'),
    jobRoleUser: null,
    reviewDateList: [],
    reviewTotalPercentageList: [],
    reviewValue: null,
    typeDescriptions: [],
    chart: null,
    jobRoleTitle: null,
    jobFunctionTitle: null,
    dataSets: [],
    labels: [],
    employeeEvaluationList: [],
    didRender() {
      this._super(...arguments);
      this.buildData.perform();
    },
    buildChart() {
      let listEmployeeAux = null;
      if (this.employeeEvaluationList && this.employeeEvaluationList.length) {
        listEmployeeAux = Object.assign([], this.employeeEvaluationList);
      }
      let listTypeDescriptions = this.typeDescriptions;
      let onlyAppraiserAnswers = this.onlyAppraiserAnswers;
      let showJobFunction = this.showJobFunction;
      let data = {
        labels: this.labels,
        datasets: this.dataSets
      };
      if (this.chart) {
        this.chart.destroy();
      }
      let ctx = document.getElementById('evolutionChart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        responsive: true,
        data: data,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true
            }],
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                callback: function (value) {
                  return value + '%';
                }
              }
            }]
          },
          tooltips: {
            custom: function (tooltip) {
              if (!tooltip) return;
              tooltip.displayColors = false;
            },
            callbacks: {
              title: function () {},
              label: function (tooltipItem) {
                let listEmployee = listEmployeeAux[tooltipItem.datasetIndex];
                let roleOrFunction = 'Cargo: ';
                let valueRoleOrFunction = listEmployee[tooltipItem.index].jobRoleName;
                if (showJobFunction) {
                  roleOrFunction = 'Função: ';
                  valueRoleOrFunction = listEmployee[tooltipItem.index].jobFunctionName;
                }
                if (listEmployee[tooltipItem.index].consensusTotal) {
                  return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota do consenso: ' + listEmployee[tooltipItem.index].consensusTotal.toFixed(2) + '%', 'Nota Final: ' + listEmployee[tooltipItem.index].finalTotal.toFixed(2) + '%'];
                } else if (listEmployee[tooltipItem.index].averageTeam) {
                  return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota da equipe: ' + listEmployee[tooltipItem.index].averageTeam.toFixed(2) + '%', 'Nota Final: ' + listEmployee[tooltipItem.index].finalTotal.toFixed(2) + '%'];
                } else {
                  if (onlyAppraiserAnswers) {
                    return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota Final: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%'];
                  } else {
                    return ['Nome: ' + listEmployee[tooltipItem.index].userName, roleOrFunction + valueRoleOrFunction, listTypeDescriptions[tooltipItem.index], 'Data: ' + moment(listEmployee[tooltipItem.index].createdAt).format('DD/MM/YYYY'), 'Nota avaliador: ' + listEmployee[tooltipItem.index].appraiserTotal.toFixed(2) + '%', 'Nota avaliado: ' + listEmployee[tooltipItem.index].appraisedTotal.toFixed(2) + '%', 'Nota Final: ' + listEmployee[tooltipItem.index].finalTotal.toFixed(2) + '%'];
                  }
                }
              }
            }
          }
        }
      });
    },
    typeReviewFormat(type) {
      let types = {
        1: 'Primeira Avaliação',
        2: 'Avaliação de Efetivação',
        3: 'Avaliação de Função',
        4: 'Avaliação de Skills'
      };
      return types[type];
    },
    buildData: (0, _emberConcurrency.task)(function* () {
      this.reviewDateList = [];
      this.reviewTotalPercentageList = [];
      this.typeDescriptions = [];
      this.employeeEvaluationList = [];
      this.dataSets = [];
      this.labels = [];
      this.reviewValue = null;
      let reviewValueAux = null;
      let reviewDateListAux = [];
      let datasetAux = [];
      let userEmployeeEvaluations = this.listUserEmployee && this.listUserEmployee.users ? this.listUserEmployee.users : null;
      if (userEmployeeEvaluations && userEmployeeEvaluations.length) {
        for (const userEvaluation of userEmployeeEvaluations) {
          if (userEvaluation.employeeEvaluations && userEvaluation.employeeEvaluations.length) {
            let employeeEvaluationList = Object.assign([], userEvaluation.employeeEvaluations).reverse();
            let reviewTotalPercentageList = [];
            let employeeEvaluationListAux = [];
            for (const employeeEvaluation of employeeEvaluationList) {
              this.reviewDateList.push(new Date(employeeEvaluation.createdAt));
              let value = employeeEvaluation.consensusTotal ? employeeEvaluation.consensusTotal : employeeEvaluation.finalTotal;
              if (this.onlyAppraiserAnswers) {
                if (!employeeEvaluation.consensusTotal && (!userEmployeeEvaluations[0].evaluationTeams || !userEmployeeEvaluations[0].evaluationTeams.length)) {
                  value = employeeEvaluation.appraiserTotal;
                  reviewTotalPercentageList.push(value);
                } else {
                  reviewTotalPercentageList.push(value);
                }
              } else {
                reviewTotalPercentageList.push(value);
              }
              if (reviewValueAux < value) {
                reviewValueAux = value.toFixed(2);
              }
              this.typeDescriptions.push(this.typeReviewFormat(employeeEvaluation.type));
              if (userEvaluation.evaluationTeams && userEvaluation.evaluationTeams.length) {
                for (const employeeTeamEvaluation of userEvaluation.evaluationTeams) {
                  if (employeeTeamEvaluation.employeeEvaluation === employeeEvaluation.id) {
                    const employeeEvaluationTeams = userEvaluation.evaluationTeams.filter(evaluation => evaluation.employeeEvaluation === employeeEvaluation.id);
                    const sumOfScores = _lodash.default.sumBy(employeeEvaluationTeams, 'appraiserTotal') / employeeEvaluationTeams.length;
                    employeeEvaluation.averageTeam = sumOfScores;
                  }
                }
              }
              employeeEvaluation.userName = userEvaluation.firstName + " " + userEvaluation.lastName;
              employeeEvaluation.jobRoleName = userEvaluation.jobRole && userEvaluation.jobRole.title ? userEvaluation.jobRole.title : 'Usuário sem Cargo';
              employeeEvaluation.jobFunctionName = userEvaluation.jobFunction && userEvaluation.jobFunction.title ? userEvaluation.jobFunction.title : 'Usuário sem Função';
              employeeEvaluationListAux.push(employeeEvaluation);
              datasetAux.push(value);
              const typeReview = `${this.typeReviewFormat(employeeEvaluation.type)} ${moment(employeeEvaluation.createdAt).format('DD/MM/YYYY')}`;
              let formated = typeReview.split(/(?<!de|da)[\s,]/);
              formated = formated.toString().replaceAll(',', ' ');
              formated = formated.length > 30 ? formated.substr(0, 30) + '...' : formated;
              this.labels.push(formated);
            }
            this.employeeEvaluationList.push(employeeEvaluationListAux);
          }
        }
        this.dataSets.push({
          label: '',
          data: datasetAux,
          borderColor: `rgba(32, 129, 206, 1.0)`,
          backgroundColor: `rgba(32, 129, 206, 0.2)`,
          fill: true,
          lineTension: 0
        });
        this.reviewValue = Math.ceil(reviewValueAux);
        reviewDateListAux = [];
        this.reviewDateList.sort(function (a, b) {
          return a.getTime() - b.getTime();
        });
        this.reviewDateList = reviewDateListAux;
        yield this.buildChart();
      } else {
        yield this.buildChart();
      }
    }).drop()
  });
  _exports.default = _default;
});