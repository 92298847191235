define("portal-talentrh/pods/feed/index/setup-account/user-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isSelected: Ember.computed('usersSelected.length', function () {
      return (this.usersSelected || []).findBy('id', this.user.id);
    })
  });
  _exports.default = _default;
});