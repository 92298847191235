define("portal-talentrh/pods/application/navbar/notification/feedback-request/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "txDEYlUu",
    "block": "{\"symbols\":[\"Avatar\",\"@notification\"],\"statements\":[[10,\"a\"],[15,6,[30,[36,1],[\"avd\",[35,0]],null]],[14,0,\"d-flex py-4\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"mb-1\"]],[[\"@user\"],[[32,0,[\"notification\",\"relatedUser\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,0,[\"notification\",\"relatedUser\",\"fullName\"]]]],[[\"@src\"],[\"./images/voors-logo.png\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n      \"],[1,[32,2,[\"message\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,2,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link\",\"app-url\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/feedback-request/template.hbs"
    }
  });
  _exports.default = _default;
});