define("portal-talentrh/pods/indicators/people-analytics/my-team/company-performance/goals-per-employee/collaborator/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsMyTeamCompanyPerformanceGoalsPerEmployeeCollaboratorRoute extends Ember.Route {
    deactivate() {
      this.controller.filters = {};
      this.controller.filtersSelected = {};
      this.controller.exclusiveGoals = null;
      this.controller.userGoal = null;
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsMyTeamCompanyPerformanceGoalsPerEmployeeCollaboratorRoute;
});