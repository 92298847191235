define("portal-talentrh/pods/home/my-team/time-card-details/item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('args'), _dec6 = Ember.computed('args'), _dec7 = Ember.computed('args'), _dec8 = Ember.computed('args'), (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "showActionModal", _descriptor3, this);
      _initializerDefineProperty(this, "actionDescription", _descriptor4, this);
    }
    get badgeStatus() {
      const {
        record
      } = this.args;
      const {
        status
      } = record;
      const badges = {
        PENDING_LEADER: {
          text: 'Ação pendente',
          class: 'action pending'
        },
        PENDING_HR: {
          text: 'Ação realizada',
          class: 'action completed'
        },
        REPROVED: {
          text: 'Ação reprovada',
          class: 'action failed'
        },
        APPROVED: {
          text: 'Ação aprovada',
          class: 'action completed'
        }
      };
      return badges[status];
    }
    get actionPending() {
      const {
        record
      } = this.args;
      const {
        actions
      } = record;
      const lastAction = actions.at(-1);
      return lastAction?.status === 'REPROVED' || !actions.length;
    }
    get actionsPendingHr() {
      const {
        record
      } = this.args;
      const {
        actions
      } = record;
      const actionsPendingHr = actions.filter(action => action.status === 'PENDING_HR');
      return actionsPendingHr.reverse();
    }
    get actionsHr() {
      const {
        record
      } = this.args;
      const {
        actions
      } = record;
      const actionsHr = actions.filter(action => ['REPROVED', 'APPROVED'].includes(action.status));
      return actionsHr.reverse();
    }
    *saveAction() {
      const {
        record,
        reloadFilters
      } = this.args;
      const {
        id: offSiteBeatId
      } = record;
      if (!this.actionDescription) {
        return this.swal.warning('Ação é obrigatória');
      }
      yield this.fetch.post('offSiteBeats/createActionByLeader', {
        data: {
          offSiteBeatId,
          description: this.actionDescription
        }
      });
      if (reloadFilters) {
        reloadFilters();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showActionModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "actionDescription", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "badgeStatus", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "badgeStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionPending", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "actionPending"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionsPendingHr", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "actionsPendingHr"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "actionsHr", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "actionsHr"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAction", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveAction"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});