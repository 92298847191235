define("portal-talentrh/pods/home/my-team/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2YM4mEH3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-container-management\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"gauges\"],[12],[2,\"\\n    \"],[8,\"home/my-team/pdi-gauge\",[],[[],[]],null],[2,\"\\n    \"],[8,\"home/my-team/feedback-gauge\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"climate\"],[12],[2,\"\\n    \"],[8,\"home/my-team/team-climate-or-time-card\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"goals\"],[12],[2,\"\\n    \"],[8,\"home/my-team/goals\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"solicitation\"],[12],[2,\"\\n    \"],[8,\"home/my-team/solicitations-or-compensatory-time\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"kpis\"],[12],[2,\"\\n    \"],[8,\"home/my-team/kpi\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/home/my-team/index/template.hbs"
    }
  });
  _exports.default = _default;
});