define("portal-talentrh/pods/vacancy/ad/controller", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    showAddVacancy: Ember.computed.or('permission.accessProfile.allowManageVacancies', 'permission.accessProfile.admin', 'permission.admin'),
    actions: {
      click() {
        if (!this.showAddVacancy) {
          return;
        }
        const url = `${_environment.default.appUrl.conta}registry/social/vacancy/?to=toVacancy`;
        window.open(url, '_blank');
      }
    }
  });
  _exports.default = _default;
});