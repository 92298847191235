define("portal-talentrh/pods/company/decision/show/charts/component", ["exports", "ember-concurrency", "chart-js"], function (_exports, _emberConcurrency, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const backgroundColor = ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)'];
  const borderColor = ['rgba(75, 192, 192, 1)', 'rgba(255,99,132,1)'];
  const options = {
    responsive: false,
    maintainAspectRatio: true,
    scales: {
      xAxes: [{
        display: false,
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['row'],
    // Services
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Tasks
    loadCharts: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.ajax.request(`decisions/${this.decision.id}/charts`);
        let pieChart = document.getElementById('decision-pie-chart');
        new _chartJs.default(pieChart.getContext('2d'), {
          options,
          type: 'pie',
          data: {
            labels: ['Sim', 'Não'],
            datasets: [{
              backgroundColor,
              borderColor,
              label: 'Nº de votos',
              data: [response.approve, response.disapprove],
              borderWidth: 1
            }]
          }
        });
        let doughnutChart = document.getElementById('decision-doughnut-chart');
        new _chartJs.default(doughnutChart.getContext('2d'), {
          options,
          type: 'doughnut',
          data: {
            labels: ['Votou', 'Falta Votar'],
            datasets: [{
              backgroundColor,
              borderColor,
              label: 'Nº de votos',
              data: [response.votes, response.pending],
              borderWidth: 1
            }]
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).on('didInsertElement').drop()
  });
  _exports.default = _default;
});