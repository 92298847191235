define("portal-talentrh/pods/forum/topic/index/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    // Computed
    canManage: Ember.computed('permission.admin', 'session.user.{admin,id}', 'topic.user.id', function () {
      return Ember.get(this, 'permission.admin') || Ember.get(this, 'session.user.id') === parseInt(Ember.get(this, 'topic.user.id'));
    }),
    commentHint: Ember.computed('commentsLength', 'topic.comments.length', function () {
      let l = this.commentsLength;
      let labels = {
        0: 'Não há comentários',
        1: '1 comentário'
      };
      return labels[l] || `${l} comentários`;
    }),
    commentsLength: Ember.computed('topic.comments', function () {
      let comments = this.topic.comments;
      comments = comments.filter(c => {
        return c.get('user.active');
      });
      return comments.length;
    })
  });
  _exports.default = _default;
});