define("portal-talentrh/pods/home/my-team/goal-details/collaborator/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeMyTeamGoalDetailsCollaboratorRoute extends Ember.Route {
    deactivate() {
      this.controller.exclusiveGoals = null;
      this.controller.userGoal = null;
    }
  }
  _exports.default = HomeMyTeamGoalDetailsCollaboratorRoute;
});