define("portal-talentrh/pods/company/decision/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    session: Ember.inject.service(),
    // Single line computed properties
    decision: Ember.computed.alias('model'),
    votes: Ember.computed.sort('model.votes', 'votesSorting'),
    votesSorting: Ember.A(['createdAt:desc']),
    // Multiline computed properties
    showResults: Ember.computed('decision.{isActive,votes.[]}', function () {
      let hasVoted = this.decision.votes.findBy('user.id', String(this.session.user.id));
      return hasVoted || !this.decision.isActive;
    })
  });
  _exports.default = _default;
});