define("portal-talentrh/models/broadcast-list", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Insira um nome para a lista de transmissão'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    createdAt: (0, _attr.default)('date'),
    name: (0, _attr.default)('string'),
    // Associations
    user: (0, _relationships.belongsTo)('user'),
    broadcastMessages: (0, _relationships.hasMany)('broadcast-message'),
    users: (0, _relationships.hasMany)('user')
  });
  _exports.default = _default;
});