define("portal-talentrh/pods/portal-manager/request/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PortalManagerRequestCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('user-solicitation', {
        requestedBy: 'manager'
      });
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('portal-manager.request.hr').getFilters();
    }
  }
  _exports.default = PortalManagerRequestCreateRoute;
});