define("portal-talentrh/pods/components/widgets/new-users/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    didInsertElement() {
      const session = this.session;
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenNewUsersWidget = session.data.lastOpenNewUsersWidget;
      if (lastOpenNewUsersWidget && lastOpenNewUsersWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        this.loadWidget.perform();
      }
    },
    loadWidget: (0, _emberConcurrency.task)(function* () {
      let {
        users
      } = yield this.ajax.request('users/recentlyAdmitted');
      return users;
    }),
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenNewUsersWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});