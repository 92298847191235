define("portal-talentrh/pods/application/navbar/notification/mention/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    message: Ember.computed('notification.type', function () {
      if (Ember.get(this, 'notification.type') === 'comment-mention') {
        return 'mencionou você em um comentário.';
      } else {
        return 'mencionou você em uma postagem.';
      }
    })
  });
  _exports.default = _default;
});