define("portal-talentrh/pods/home/my-team/feedback-gauge/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "chart-js", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _chartJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeMyTeamFeedbackGaugeComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed.alias('permission.companyConfig.hasAVDModule'), _dec7 = Ember.computed.alias('permission.companyConfig.featureFlags'), (_class = class HomeMyTeamFeedbackGaugeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "chart", _descriptor3, this);
      _initializerDefineProperty(this, "value", _descriptor4, this);
      _initializerDefineProperty(this, "percent", _descriptor5, this);
      _initializerDefineProperty(this, "hasAVDModule", _descriptor6, this);
      _initializerDefineProperty(this, "featureFlags", _descriptor7, this);
    }
    async getChartData() {
      if (!this.hasAVDModule) {
        return null;
      }
      let withoutFeedback;
      let url = '/api/leader/getWithoutFeedback';
      const {
        newPerformanceModule
      } = this.featureFlags;
      if (newPerformanceModule) {
        url = '/api/graphql/leader/usersWithoutFeedback';
      }
      withoutFeedback = await this.ajax.request(url, {
        host: _environment.default.apiUrl.indicadores,
        data: {
          ledType: 'direct'
        }
      });
      if (newPerformanceModule) {
        return withoutFeedback.data.getUsersWithoutFeedback;
      }
      return withoutFeedback;
    }
    *initChart() {
      let that = this;
      const withoutFeedback = yield this.getChartData();
      this.value = withoutFeedback ? withoutFeedback.value : 0;
      this.percent = withoutFeedback ? withoutFeedback.percent : '100%';

      //gaugeNeedle
      const gaugeNeedle = {
        id: 'gaugeNeedle',
        afterDatasetDraw(chart) {
          const {
            ctx,
            data,
            chartArea: {
              width,
              height
            }
          } = chart;
          ctx.save();
          let needleValue = data.datasets[0].needleValue;
          needleValue = needleValue > 100 ? 100 : needleValue;
          var angle = Math.PI + 1 / 100 * needleValue * Math.PI;
          if (needleValue <= 25) {
            angle -= 0.1;
          } else if (needleValue >= 75) {
            angle += 0.1;
          }
          const cx = width / 2;
          const cy = Math.round(chart._metasets[0].data[0].y) - 10;

          //needle
          ctx.translate(cx, cy);
          ctx.rotate(angle);
          ctx.beginPath();
          ctx.moveTo(0, -1);
          ctx.lineTo(height - 25 - ctx.canvas.offsetTop, 0);
          ctx.lineTo(0, 2);
          ctx.fillStyle = '#444';
          ctx.fill();

          //needle dot
          ctx.translate(-cx, -cy);
          ctx.beginPath();
          ctx.arc(cx, cy, 6, 0, 10);
          ctx.fill();
          ctx.restore();
        }
      };
      let ctx = document.getElementById('chart-feedback').getContext('2d');
      this.chart = new _chartJs.default(ctx, {
        type: 'doughnut',
        data: {
          labels: ['0-25%', '26-50%', '51-75%', '76-100%'],
          datasets: [{
            label: 'Gauge',
            data: [25, 25, 25, 25],
            backgroundColor: ['#FF3932', '#F79009', '#60B527', '#038C8C'],
            needleValue: that.value,
            borderColor: 'white',
            borderWidth: 2,
            cutout: '85%',
            circumference: 180,
            rotation: 270,
            borderRadius: 0
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              yAlign: 'bottom',
              displayColors: false,
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.label;
                }
              }
            }
          }
        },
        plugins: [gaugeNeedle]
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "percent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasAVDModule", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "featureFlags", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initChart", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = HomeMyTeamFeedbackGaugeComponent;
});