define("portal-talentrh/services/groups", ["exports", "portal-talentrh/utils/remove-accents"], function (_exports, _removeAccents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    isListLoaded: false,
    groups: Ember.A([]),
    loadList() {
      if (!this.session.isAuthenticated) {
        return;
      }
      if (this.isListLoaded) {
        this.set('isListLoaded', false);
      }
      return this.ajax.request('/chat/usergroups').then(response => {
        this.set('groups', response.groups);
        return 'A lista de grupos foi carregada.';
      }).catch(() => {
        return 'Não foi possível carregar a lista de grupos';
      }).finally(() => this.set('isListLoaded', true));
    },
    getById(id) {
      return this.groups.findBy('id', parseInt(id));
    },
    filter(filter) {
      return this.groups.filter(item => {
        return item.name && (0, _removeAccents.default)(item.name).match(new RegExp((0, _removeAccents.default)(filter.trim()), 'i'));
      });
    },
    reloadGroup(groupId, group) {
      return this.ajax.request(`/chat/userGroups/${groupId}`).then(response => {
        let foundGroup = this.getById(groupId);
        if (!foundGroup) {
          this.groups.pushObject(response.userGroup);
        }
        if (!group) {
          group = this.getById(groupId);
        }
        if (group) {
          this._updateObject(group, response.userGroup);
        }
      }).catch(function (err) {
        Ember.debug(err);
        this.swal.error('Não foi possível recarregar o grupo');
      });
    },
    _updateObject(current, updated) {
      var key,
        keys = Object.keys(updated);
      var n = keys.length;
      while (n--) {
        key = keys[n];
        Ember.set(current, key, updated[key]);
      }
    }
  });
  _exports.default = _default;
});