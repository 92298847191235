define("portal-talentrh/pods/profile/edit/book/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileEditBookCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('user-book', {
        user: this.modelFor('profile.edit')
      });
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = ProfileEditBookCreateRoute;
});