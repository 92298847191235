define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/result-per-question/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tg2jk+o2",
    "block": "{\"symbols\":[\"@answers\",\"@employeeEvaluation\",\"@employeeEvaluationTeams\"],\"statements\":[[2,\" \"],[8,\"view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-question\",[],[[\"@answers\",\"@employeeEvaluation\",\"@team\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/result-per-question/template.hbs"
    }
  });
  _exports.default = _default;
});