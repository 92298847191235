define("portal-talentrh/models/category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    title: (0, _model.attr)('string'),
    topics: (0, _model.hasMany)('topic'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    user: (0, _model.belongsTo)('user')
  });
  _exports.default = _default;
});