define("portal-talentrh/pods/indicators/people-analytics/human-resources/organizational-climate/feelings-negative-positive-detail/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateFeelingsNegativePositiveDetailController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = class IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateFeelingsNegativePositiveDetailController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['branch', 'department', 'jobRole', 'jobFunction', 'startDate', 'endDate']);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _initializerDefineProperty(this, "prevailingClimateData", _descriptor4, this);
      _initializerDefineProperty(this, "chartFilter", _descriptor5, this);
      _initializerDefineProperty(this, "colors", _descriptor6, this);
      _defineProperty(this, "exportFields", [{
        field: 'fullName',
        fieldName: 'Colaborador'
      }, {
        field: 'tradingName',
        fieldName: 'Filial'
      }, {
        field: 'department',
        fieldName: 'Departamento'
      }, {
        field: 'jobRole',
        fieldName: 'Cargo'
      }, {
        field: 'jobFunction',
        fieldName: 'Função'
      }, {
        field: 'feelingType',
        fieldName: 'Tipo'
      }, {
        field: 'type',
        fieldName: 'Sentimento'
      }, {
        field: 'total',
        fieldName: 'Total'
      }]);
    }
    *loadFeelingsNegativePositiveDetails() {
      try {
        let filters = this.buildFilters();
        const prevailingClimateData = yield this.fetch.request('indicators/getGeneralClimate', {
          data: filters
        });
        this.prevailingClimateData = prevailingClimateData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: OrganizationalClimate :: FeelingsNegativePositiveDetail :: loadFeelingsNegativePositiveDetails`);
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      let filters = {};
      if (this.branch) {
        filters.branch = this.branch.split(',');
      }
      if (this.department) {
        filters.department = this.department.split(',');
      }
      if (this.jobRole) {
        filters.jobRole = this.jobRole.split(',');
      }
      if (this.jobFunction) {
        filters.jobFunction = this.jobFunction.split(',');
      }
      if (this.chartFilter) {
        filters.type = this.chartFilter;
      }
      filters.startDate = this.startDate;
      filters.endDate = this.endDate;
      this.filters = filters;
      return filters;
    }
    onChartClick(label, newColors) {
      this.chartFilter = label;
      this.colors = newColors;
      this.loadFeelingsNegativePositiveDetails.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "prevailingClimateData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chartFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadFeelingsNegativePositiveDetails", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadFeelingsNegativePositiveDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChartClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChartClick"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateFeelingsNegativePositiveDetailController;
});