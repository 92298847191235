define("portal-talentrh/pods/components/upload-chart/component", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader", "portal-talentrh/config/environment"], function (_exports, _fileField, _uploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fileField.default.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    filesDidChange: function (files) {
      let companyId = this.session.company.id;
      const uploader = _uploader.default.create({
        url: _environment.default.apiUrl.social + '/companyConfigurations/uploadAvatar/' + companyId,
        paramName: 'avatar',
        ajaxSettings: {
          headers: {
            'Authorization': 'Bearer ' + this.get('session.token')
          }
        }
      });
      const file = files.item(0);
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        return this.swal.warning('Formato de imagem não permitido, por favor insira uma imagem no formato .jpeg, .jpg ou .png');
      }
      if (!Ember.isEmpty(files)) {
        this.swal.loading('Estamos fazendo o upload do seu organograma...');
        uploader.upload(files[0]).then(response => {
          this.companyConfiguration.reload();
          this.store.pushPayload(response);
          this.swal.success('O organograma foi enviado com sucesso!');
        }).catch(e => {
          this.swal.catch(e);
        });
      }
    }
  });
  _exports.default = _default;
});