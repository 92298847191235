define("portal-talentrh/pods/home/my-team/open-vacations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeMyTeamOpenVacationsRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {
        ledType: 'direct'
      };
    }
  }
  _exports.default = HomeMyTeamOpenVacationsRoute;
});