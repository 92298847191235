define("portal-talentrh/pods/post/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lq2B2sVN",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[8,\"ui-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"post-item\",[],[[\"@tagName\",\"@post\",\"@onDeletePost\",\"@onArchivePost\",\"@isDetail\",\"@onlyShow\"],[\"div\",[34,0],[30,[36,1],[[32,0],\"goToHome\"],null],[30,[36,1],[[32,0],\"goToHome\"],null],true,[32,0,[\"fromAccount\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"post\",\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/post/show/template.hbs"
    }
  });
  _exports.default = _default;
});