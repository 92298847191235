define("portal-talentrh/models/company-configuration", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    chartUrl: (0, _model.attr)('string'),
    communicationApi: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    discountPercentageLunch: (0, _model.attr)('number'),
    featureFlags: (0, _model.attr)('raw'),
    insightFlags: (0, _model.attr)('raw'),
    generalOrSpecific: (0, _model.attr)('number'),
    manageLunchList: (0, _model.attr)('boolean'),
    subscriptionManagement: (0, _model.attr)('boolean'),
    managerSolicitationConfigs: (0, _model.attr)('raw'),
    name: (0, _model.attr)('string'),
    permit2FA: (0, _model.attr)('boolean'),
    permitAd: (0, _model.attr)('boolean'),
    permitAgenda: (0, _model.attr)('boolean'),
    permitAnniversary: (0, _model.attr)('boolean'),
    permitAnnouncement: (0, _model.attr)('boolean'),
    permitAnnualIncoming: (0, _model.attr)('boolean'),
    permitBenefit: (0, _model.attr)('boolean'),
    permitBiblioteca: (0, _model.attr)('boolean'),
    permitCarona: (0, _model.attr)('boolean'),
    permitCartaoPonto: (0, _model.attr)('boolean'),
    permitChat: (0, _model.attr)('boolean'),
    permitChart: (0, _model.attr)('boolean'),
    permitComercialPolitics: (0, _model.attr)('boolean'),
    permitCompanyPhotos: (0, _model.attr)('boolean'),
    permitCompanyPrograms: (0, _model.attr)('boolean'),
    permitComplaint: (0, _model.attr)('boolean'),
    permitContactList: (0, _model.attr)('boolean'),
    permitDashboard: (0, _model.attr)('boolean'),
    permitDecisionMaking: (0, _model.attr)('boolean'),
    permitDocument: (0, _model.attr)('boolean'),
    permitEvent: (0, _model.attr)('boolean'),
    permitImports: (0, _model.attr)('boolean'),
    permitExternalLink: (0, _model.attr)('boolean'),
    permitForum: (0, _model.attr)('boolean'),
    permitGroups: (0, _model.attr)('boolean'),
    permitMeetingMinute: (0, _model.attr)('boolean'),
    permitMessageRh: (0, _model.attr)('boolean'),
    permitMural: (0, _model.attr)('boolean'),
    permitNews: (0, _model.attr)('boolean'),
    permitNotify: (0, _model.attr)('boolean'),
    permitPolicy: (0, _model.attr)('boolean'),
    permitPostMessage: (0, _model.attr)('boolean'),
    permitProjectsAndTasks: (0, _model.attr)('boolean'),
    permitReceipt: (0, _model.attr)('boolean'),
    permitRend: (0, _model.attr)('boolean'),
    permitScale: (0, _model.attr)('boolean'),
    permitSuggestionBox: (0, _model.attr)('boolean'),
    permitSurvey: (0, _model.attr)('boolean'),
    permitStrongPass: (0, _model.attr)('boolean'),
    showCorporateEmail: (0, _model.attr)('boolean'),
    permitTimeCard: (0, _model.attr)('boolean'),
    permitTraining: (0, _model.attr)('boolean'),
    permitUserExpense: (0, _model.attr)('boolean'),
    permitUserFeeling: (0, _model.attr)('boolean'),
    permitUserPhotos: (0, _model.attr)('boolean'),
    postAnniversary: (0, _model.attr)('boolean'),
    postAnniversaryCompany: (0, _model.attr)('boolean'),
    permitCreateVacancyAnnouncement: (0, _model.attr)('boolean'),
    postImageUrl: (0, _model.attr)('string'),
    requireImageProfile: (0, _model.attr)('boolean'),
    showAdsOnFeed: (0, _model.attr)('boolean'),
    showBirthdayCompanyMonth: (0, _model.attr)('boolean'),
    showBirthdayMessage: (0, _model.attr)('boolean'),
    showBirthdayMonth: (0, _model.attr)('boolean'),
    showBranchName: (0, _model.attr)('boolean'),
    showFeelingOnProfile: (0, _model.attr)('boolean'),
    showJobFunction: (0, _model.attr)('boolean'),
    showJobFunctionCard: (0, _model.attr)('boolean'),
    showMyGoalsCard: (0, _model.attr)('boolean'),
    showNeutralEvent: (0, _model.attr)('boolean'),
    showReasonWeek: (0, _model.attr)('boolean'),
    showUserLinksHome: (0, _model.attr)('boolean'),
    subscription: (0, _model.attr)('raw'),
    thumbLogo: (0, _model.attr)('string'),
    timeLimitToOrderLunch: (0, _model.attr)('string'),
    totalCostLunch: (0, _model.attr)('number'),
    userApproval: (0, _model.attr)('boolean'),
    userSolicitationConfigs: (0, _model.attr)('raw'),
    usesCompensatoryTime: (0, _model.attr)('boolean'),
    allowConsensusInEmployee180Evaluation: (0, _model.attr)('boolean'),
    onlyAppraiserAnswers: (0, _model.attr)('boolean'),
    typeFirstEvaluation: (0, _model.attr)('number'),
    typeLastEvaluation: (0, _model.attr)('number'),
    typeFunctionEvaluation: (0, _model.attr)('number'),
    allowNelaChat: (0, _model.attr)('boolean'),
    allowHabitusIa: (0, _model.attr)('boolean'),
    allowProfessionalProgression: (0, _model.attr)('boolean'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    // Computed Properties
    permitPortalCompany: Ember.computed.or('permitCompanyPhotos', 'permitMeetingMinute', 'permitBenefit', 'permitChart', 'permitPolicy', 'permitCompanyPrograms', 'permitDecisionMaking', 'permitContactList'),
    accountIsActive: Ember.computed('subscription.active', function () {
      return this.get('subscription.active') || false;
    }),
    hasAVDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('AVD');
    }),
    hasPesquisaModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PESQUISA');
    }),
    hasPortalColabModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_COLAB');
    }),
    hasPortalGestorModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PORTAL_GESTOR');
    }),
    hasProjectManagerModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('PROJECT-MANAGER');
    }),
    hasSocialModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('NELA');
    }),
    hasTrainingModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TRAINING');
    }),
    hasTEDModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('TED');
    }),
    hasAgendaModule: Ember.computed('subscription.availableModules', function () {
      return this.get('subscription.availableModules').includes('AGENDA');
    }),
    logo: Ember.computed('postImageUrl', function () {
      if (!this.postImageUrl) {
        return '/images/voors-logo.png';
      }
      return this.postImageUrl;
    }),
    showPortalColab: Ember.computed('hasPortalColabModule', 'permitAnnualIncoming', 'permitMessageRh', 'permitReceipt', 'permitTimeCard', 'permitUserExpense', 'usesCompensatoryTime', function () {
      return (this.permitReceipt || this.permitMessageRh || this.permitAnnualIncoming || this.permitUserExpense || this.permitTimeCard || this.usesCompensatoryTime) && this.hasPortalColabModule;
    })
  });
  _exports.default = _default;
});