define("portal-talentrh/pods/notification/share/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Multi line computed properties
    relatedUsersLength: Ember.computed('notification.{relatedUsers,relatedsUsers}', function () {
      const relatedUsers = Ember.get(this, 'notification.relatedsUsers');
      return relatedUsers ? relatedUsers.length : 0;
    }),
    //  `${firstObject.name} curtiu sua publicação:`
    firstUserId: Ember.computed('notification.{relatedUsers,relatedsUsers}', function () {
      const relatedUsers = Ember.get(this, 'notification.relatedsUsers');
      return Ember.get(relatedUsers, 'firstObject');
    }),
    // `${lastUser.name} e ${firstUser.name} curtiram sua publicação:`;
    lastUserId: Ember.computed('notification.{relatedUsers,relatedsUsers}', function () {
      const relatedUsers = Ember.get(this, 'notification.relatedsUsers');
      const lastUser = Ember.get(relatedUsers, 'lastObject');
      return lastUser;
    }),
    // `${lastUser.name}, ${penultimateUser.name} e mais ${length-2} curtiram sua publicação:`;
    penultimateUserId: Ember.computed('notification.{relatedUsers,relatedsUsers}', function () {
      const relatedUsers = Ember.get(this, 'notification.relatedsUsers');
      if (relatedUsers.length < 2) {
        return null;
      }
      const lastUser = relatedUsers.objectAt(length - 2);
      return lastUser ? lastUser : null;
    }),
    // Actions
    actions: {
      viewAndSetAsRead() {
        this.click();
        this.router.transitionTo('post.show', Ember.get(this, 'notification.post.id'));
      }
    }
  });
  _exports.default = _default;
});