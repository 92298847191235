define("portal-talentrh/pods/post/attachment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params) {
      this.ajax.request(`/postAttachment/${params.id}/signedLink`).then(response => {
        return response;
      });
    }
  });
  _exports.default = _default;
});