define("portal-talentrh/pods/feed/index/setup-account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    welcome: Ember.inject.service(),
    router: Ember.inject.service(),
    actions: {
      close() {
        // Quando a pessoa fechar o primeiro modal, ativa a flag para que apareça apenas o segundo modal de lider (scroll)
        let leaderPreference = this.permission.userPreference;
        if (leaderPreference.get('user')) {
          leaderPreference.set('user.hideLeaderFirstModal', true);
        } else {
          leaderPreference.set('user', {
            hideLeaderFirstModal: true
          });
        }

        // Quando a pessoa fechar o modal, marca a flag para que ele não seja aberto novamente.
        let userPreference = this.permission.userPreference;
        if (userPreference.get('social')) {
          userPreference.set('social.dismissLeadershipConfig', true);
        } else {
          userPreference.set('social', {
            dismissLeadershipConfig: true
          });
        }
        userPreference.save();
        this.router.transitionTo('feed');
        this.welcome.steps();
      }
    }
  });
  _exports.default = _default;
});