define("portal-talentrh/pods/feed/index/widgets/leader-request/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    session: Ember.inject.service(),
    findLeadershipRequests: (0, _emberConcurrency.task)(function* () {
      try {
        let leadershipRequests = yield this.store.query('leadership-request', {
          relatedUser: this.session.user.id,
          status: 'pending'
        });
        Ember.set(this, 'leadershipRequests', leadershipRequests.toArray());
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didInsertElement'),
    approve: (0, _emberConcurrency.task)(function* (request) {
      try {
        let result = yield this.swal.confirm(`Aceitar a solicitação de ${request.get('user.fullName')}?`);
        if (!result.value) {
          return;
        }
        yield this.ajax.post(`leadershipRequests/${request.id}/manage`, {
          data: {
            approved: true
          }
        });
        this.leadershipRequests.removeObject(request);
        this.toast.success('A solicitação foi aprovada com sucesso!');
      } catch (e) {
        Ember.debug(e);
      }
    }),
    disapprove: (0, _emberConcurrency.task)(function* (request) {
      try {
        let result = yield this.swal.confirm(`Recusar a solicitação de ${request.get('user.fullName')}?`);
        if (!result.value) {
          return;
        }
        yield this.ajax.post(`leadershipRequests/${request.id}/manage`);
        this.leadershipRequests.removeObject(request);
        this.toast.success('A solicitação foi reprovada!');
      } catch (e) {
        Ember.debug(e);
      }
    })
  });
  _exports.default = _default;
});