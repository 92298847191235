define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/quadrant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/cxPUgTG",
    "block": "{\"symbols\":[\"quadrantUser\",\"@quadrant\",\"@nineBoxCycle\",\"@cycles\"],\"statements\":[[10,\"div\"],[14,0,\"box position-relative\"],[15,5,[31,[\"background-color: \",[32,2,[\"color\"]],\";\"]]],[12],[2,\"\\n  \"],[10,\"p\"],[15,5,[31,[\"color: \",[32,0,[\"textColor\"]],\";background-color: \",[32,2,[\"color\"]],\";\"]]],[14,0,\"font-weight-bold m-2 quadrant-title\"],[15,\"title\",[32,2,[\"description\"]]],[12],[2,\"\\n    \"],[1,[32,2,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"user-list ml-2 mt-3 text-center\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"usersQuadrant\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"view-team-member/performance-management/employee-evaluations/nine-box/quadrant/user-avatar\",[],[[\"@quadrantUser\",\"@quadrant\",\"@nineBoxCycle\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"usersQuadrant\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-expand-alt cursor-pointer h5 show-more-box mr-2 float-right\"],[14,\"title\",\"Ver mais\"],[15,5,[31,[\"color: \",[32,0,[\"textColor\"]]]]],[15,\"onclick\",[30,[36,1],[[30,[36,0],[[32,0,[\"openModal\"]]],null],true],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[8,\"view-team-member/performance-management/employee-evaluations/nine-box/quadrant/quadrant-modal\",[],[[\"@show\",\"@close\",\"@quadrant\",\"@nineBoxCycle\",\"@cycles\"],[[32,0,[\"openModal\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"openModal\"]]],null],false],null],[32,2],[32,3],[32,4]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/quadrant/template.hbs"
    }
  });
  _exports.default = _default;
});