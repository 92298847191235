define("portal-talentrh/pods/profile/show/timeline/controller", ["exports", "ember-concurrency-decorators", "moment", "portal-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfileShowTimelineController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('model'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ProfileShowTimelineController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "user", _descriptor3, this);
      _initializerDefineProperty(this, "posts", _descriptor4, this);
      _initializerDefineProperty(this, "type", _descriptor5, this);
      _initializerDefineProperty(this, "branches", _descriptor6, this);
      _initializerDefineProperty(this, "reloadFilters", _descriptor7, this);
      _initializerDefineProperty(this, "archivedFilter", _descriptor8, this);
      _initializerDefineProperty(this, "isLoadingMore", _descriptor9, this);
      _initializerDefineProperty(this, "haveMorePosts", _descriptor10, this);
      _initializerDefineProperty(this, "postTagFilter", _descriptor11, this);
    }
    onDeletePost(post) {
      this.posts.removeObject(post);
    }
    removeFromTimeline(post) {
      if (post.archived) {
        this.send('reloadWidgetNextEvent');
      }
      this.posts.removeObject(post);
    }
    reloadWidgetNextEvent() {
      Ember.set(this, 'reloadNextEvent', (0, _moment.default)());
    }
    *loadPosts(type, tag, branches) {
      try {
        this.type = type;
        const where = {
          archived: false,
          complained: false,
          user: this.user.id,
          limit: 5,
          sort: 'priority Desc, createdAt DESC'
        };
        if (tag) {
          where.postTag = tag;
          this.postTagFilter = tag;
        } else if (type !== 'all' && type !== 'last' && type !== 'archived') {
          where.type = type;
        } else if (branches && branches.length) {
          where.branches = branches.map(branch => branch.id);
          this.branches = where.branches;
        } else {
          this.branches = null;
        }
        if (!tag) {
          this.postTagFilter = null;
        }
        if (!branches) {
          this.branches = null;
        }
        if (type === 'last') {
          where.sort = 'createdAt DESC';
        }
        this.archivedFilter = type === 'archived' ? 1 : 0;
        where.archived = this.archivedFilter;
        const posts = yield this.fetch.request('/feed/posts', {
          host: _environment.default.apiUrl.core,
          data: where
        });
        this.posts = posts;
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "posts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "branches", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reloadFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "archivedFilter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isLoadingMore", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "haveMorePosts", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "postTagFilter", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDeletePost", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onDeletePost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFromTimeline", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "removeFromTimeline"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadWidgetNextEvent", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "reloadWidgetNextEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPosts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadPosts"), _class.prototype)), _class));
  _exports.default = ProfileShowTimelineController;
});