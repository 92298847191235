define("portal-talentrh/helpers/date-chat", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateChat = dateChat;
  _exports.default = void 0;
  function dateChat(params /*, hash*/) {
    if (!params[0]) {
      return;
    }
    const date = (0, _moment.default)(params[0]);
    if ((0, _moment.default)(date).isAfter((0, _moment.default)().startOf('day'))) {
      return (0, _moment.default)(date).format('HH:mm');
    } else if ((0, _moment.default)(date).isAfter((0, _moment.default)().startOf('month'))) {
      return (0, _moment.default)(date).format('DD/MM');
    } else {
      return (0, _moment.default)(date).format('DD/MM/YYYY');
    }
  }
  var _default = Ember.Helper.helper(dateChat);
  _exports.default = _default;
});