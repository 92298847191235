define("portal-talentrh/models/user-solicitation-attachment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    link: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    path: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    userSolicitation: (0, _model.belongsTo)('user-solicitation'),
    ext: Ember.computed('name', function () {
      if (!this.name) {
        return '';
      }
      return this.name.split('.').pop();
    })
  });
  _exports.default = _default;
});