define("portal-talentrh/pods/components/user-solicitations-approve-modal/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UserSolicitationsApproveModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.solicitation.solicitationType'), _dec3 = Ember.computed.alias('args.solicitation'), _dec4 = Ember.computed.equal('type', 1), _dec5 = Ember.computed.equal('type', 2), _dec6 = Ember.computed.equal('type', 3), _dec7 = Ember.computed.equal('type', 4), _dec8 = Ember.computed.equal('type', 5), _dec9 = Ember.computed.equal('type', 7), _dec10 = Ember.computed.equal('type', 14), _dec11 = Ember.computed.equal('type', 11), _dec12 = Ember.computed.equal('type', 15), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember.computed('solicitation.updatedAt'), _dec19 = Ember.computed('solicitation'), _dec20 = Ember.computed('solicitation.status'), (_class = class UserSolicitationsApproveModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "type", _descriptor2, this);
      _initializerDefineProperty(this, "solicitation", _descriptor3, this);
      _initializerDefineProperty(this, "isChangingSalary", _descriptor4, this);
      _initializerDefineProperty(this, "isChangingFunction", _descriptor5, this);
      _initializerDefineProperty(this, "isChangingHorary", _descriptor6, this);
      _initializerDefineProperty(this, "isVacancyPeriod", _descriptor7, this);
      _initializerDefineProperty(this, "isWarning", _descriptor8, this);
      _initializerDefineProperty(this, "isTermination", _descriptor9, this);
      _initializerDefineProperty(this, "isJustification", _descriptor10, this);
      _initializerDefineProperty(this, "isRegistrationData", _descriptor11, this);
      _initializerDefineProperty(this, "isCompensatoryTime", _descriptor12, this);
    }
    approve() {
      this.args.approve();
    }
    reject() {
      this.args.reject();
    }
    closeModal() {
      this.args.closeModal();
    }
    openReplyModal() {
      this.args.openReplyModal();
    }
    setDisabled() {
      this.args.setDisabled();
    }
    get solicitationUpdatedAt() {
      return moment(this.solicitation.updatedAt).format('DD/MM/YYYY - HH:mm');
    }
    get userAttendant() {
      return this.solicitation.userAttendant ? this.solicitation.userAttendant : this.solicitation.attendant;
    }
    get solicitationStatus() {
      if (this.solicitation.status === 'disapproved-manager') {
        return 'Reprovado pelo Líder em ';
      }
      if (this.solicitation.status === 'disapproved-hr') {
        return 'Reprovado pela Gestão de Pessoas em ';
      }
      if (this.solicitation.status === 'approved') {
        return 'Aprovado em ';
      }
    }
    get readOnly() {
      const {
        solicitation
      } = this.args;
      return this.args.isReadOnly || !['pending-manager', 'pending-hr', 'opened'].includes(solicitation.status) || !this.permission.hr && !this.permission.admin;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "solicitation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isChangingSalary", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isChangingFunction", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isChangingHorary", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isVacancyPeriod", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isTermination", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isJustification", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isRegistrationData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isCompensatoryTime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "approve", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openReplyModal", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "openReplyModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDisabled", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "solicitationUpdatedAt", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "solicitationUpdatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userAttendant", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "userAttendant"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "solicitationStatus", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "solicitationStatus"), _class.prototype)), _class));
  _exports.default = UserSolicitationsApproveModalComponent;
});