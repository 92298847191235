define("portal-talentrh/pods/view-team-member/projects/project-detail/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ProjectItemComponent = (_dec = Ember._action, (_class = class ProjectItemComponent extends _component.default {
    get projectName() {
      let {
        project
      } = this.args;
      let projectName = project.name;
      if (project.alias) {
        projectName += ` (${project.alias})`;
      }
      return projectName;
    }
    setProjectTasks(status) {
      this.args.setFilterTasks(status);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setProjectTasks", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setProjectTasks"), _class.prototype)), _class));
  _exports.default = ProjectItemComponent;
});