define("portal-talentrh/models/skill", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    company: (0, _model.belongsTo)('company'),
    description: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    jobFunctions: (0, _model.hasMany)('job-function', {
      inverse: 'skills'
    }),
    typeSkill: Ember.computed('type', function () {
      const types = {
        soft: 'Soft Skill',
        hard: 'Hard Skill'
      };
      return types[this.type];
    })
  });
  _exports.default = _default;
});