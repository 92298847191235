define("portal-talentrh/pods/components/viasoft-connect-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['vs-connect'],
    session: Ember.inject.service(),
    didInsertElement() {
      // this.tick();

      let [bgImage] = document.getElementsByClassName('vs-connect__background');
      if (bgImage) {
        bgImage.onload = () => {
          Ember.set(this, 'backgroundLoaded', true);
        };
      }
    }

    // tick () {
    //   if (this.isDestroyed) { return; }

    //   const eventDay = moment([2019, 8, 9, 8, 0, 0]);
    //   const now = moment();
    //   const days = eventDay.diff(now, 'days');
    //   const daysInHours = days * 24;
    //   const hours = eventDay.diff(now, 'hours') - daysInHours;
    //   const daysInMinutes = (daysInHours * 60) + (hours * 60);
    //   const minutes = eventDay.diff(now, 'minutes') - daysInMinutes;
    //   const seconds = eventDay.diff(now, 'seconds') - (daysInMinutes * 60) - (minutes * 60);

    //   if(get(this, 'daysAndHoursToConnect.seconds') < 0) {
    //     $('.vs-connect').addClass('d-none');
    //   }

    //   this.set('daysAndHoursToConnect', { days, hours, minutes, seconds });
    //   setTimeout(() => this.tick(), 100);
    // }
  });
  _exports.default = _default;
});