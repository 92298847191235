define("portal-talentrh/pods/company/policy/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R+heno99",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[4,[38,1],[[30,[36,0],[[32,0,[\"getPdf\"]]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"d-flex align-items-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-fill font-weight-bold text-secondary mb-0\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"max-h-vh-7\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[32,0,[\"model\",\"description\"]]],null]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"model\",\"pdfUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"object\"],[14,0,\"max-h-vh-6\"],[15,\"data\",[32,0,[\"pdf\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"650\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"html\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/policy/details/template.hbs"
    }
  });
  _exports.default = _default;
});