define("portal-talentrh/helpers/minutes-to-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.minutesToTime = minutesToTime;
  function minutesToTime(params /*, hash*/) {
    if (!params[0]) {
      return params;
    }
    let minutes = params[0];
    if (minutes) {
      minutes = Number(minutes);
      if (minutes < 0) {
        minutes = minutes * -1;
      }
      const h = Math.floor(minutes / 60).toString().padStart(2, 0);
      const m = Math.floor(minutes % 60).toString().padStart(2, 0);
      return `${h}h ${m}m`;
    }
    return `00h 00m`;
  }
  var _default = Ember.Helper.helper(minutesToTime);
  _exports.default = _default;
});