define("portal-talentrh/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    // Base Case Attributes
    active: true,
    address() {
      return _faker.default.address.streetAddress();
    },
    birthDate() {
      return _faker.default.date.past().toLocaleString();
    },
    cpf() {
      let baseNumber = _faker.default.datatype.number({
        min: 100000000,
        max: 999999999
      });
      // Transforma em array de digitos
      let validCpf = Array.from(String(baseNumber), Number);

      // Calcula primeiro digito verificador
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += validCpf[i] * (10 - i);
      }
      let firstVerificationDigit = sum % 11 < 2 ? 0 : 11 - sum % 11;
      validCpf[9] = firstVerificationDigit;

      // Calcula o segundo dígito verificador
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += validCpf[i] * (11 - i);
      }
      let secondVerificationDigit = sum % 11 < 2 ? 0 : 11 - sum % 11;
      validCpf[10] = secondVerificationDigit;
      validCpf = validCpf.reduce((accumulator, currentValue) => `${accumulator}${currentValue}`);
      validCpf = Number(validCpf);
      return validCpf;
    },
    personalEmail() {
      return _faker.default.internet.exampleEmail();
    },
    gender() {
      let genders = ['U', 'M', 'F'];
      return genders[Math.floor(Math.random() * 3)];
    },
    neighborhood() {
      let neighborhoods = ['Centro', 'Bela Vista', 'São José', 'Santo Antônio', 'São Francisco', 'Vila Nova', 'Boa Vista', 'Industrial', 'São Cristóvão', 'Planalto'];
      return neighborhoods[Math.floor(Math.random() * 10)];
    },
    number() {
      return _faker.default.datatype.number(4);
    },
    zipcode() {
      return _faker.default.address.zipCode();
    },
    // Base Case Attributes
    accessProfile: (0, _emberCliMirage.association)(),
    // appraisedBy: association(),
    birthCity: (0, _emberCliMirage.association)(),
    city: (0, _emberCliMirage.association)(),
    instructionLevel: (0, _emberCliMirage.association)(),
    afterCreate(user, server) {
      server.create('user-preference', {
        user,
        account: {
          'hideEmergencyContact': false,
          'hideAddress': false,
          'hideBirthDate': false,
          'hideProfessionalCellphone': true,
          'hideContact': true
        },
        social: {
          'hideWelcomeVideo': true,
          'hideLeaderFirstModal': true
        }
      });
    },
    // Traits
    admin: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        const accessProfile = server.schema.accessProfiles.findOrCreateBy({
          admin: true
        });
        user.update('accessProfile', accessProfile);
      }
    }),
    with3SimplePosts: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        server.createList('post', 3, {
          user
        });
      }
    }),
    realistic: (0, _emberCliMirage.trait)({
      acceptCompanyPolicies: true,
      admin: false,
      isLeader: false,
      admissionDate() {
        return _faker.default.date.past().toLocaleString();
      },
      approved: true,
      avatarUrl() {
        return _faker.default.image.avatar();
      },
      away: false,
      brigade: false,
      createdByNela: true,
      cellphone() {
        return _faker.default.phone.phoneNumber();
      },
      chatStatus: 0,
      // Deprecated
      cipeiro: false,
      complement() {
        return _faker.default.address.secondaryAddress();
      },
      confirmPassword: 'aô, disgraçactions',
      // Deprecated é pouco. Já não existe nem no banco

      email() {
        return _faker.default.internet.exampleEmail();
      },
      emergencyContactName() {
        _faker.default.name.findName();
      },
      emergencyContactPhone() {
        return _faker.default.phone.phoneNumber();
      },
      externalLine() {
        return _faker.default.datatype.number(6);
      },
      facebookUrl: 'https://www.facebook.com',
      // favoriteAuthor,
      // favoriteDishes,
      // favoriteSport,
      // favoriteTeam,
      firstName() {
        let gender = this.gender;
        if (gender === 'M') {
          return _faker.default.name.firstName('male');
        } else if (gender === 'F') {
          return _faker.default.name.firstName('female');
        } else {
          return _faker.default.name.firstName();
        }
      },
      googleOAuth: false,
      // googleplusUrl,
      groupsAdmin: false,
      hasNoLeader: false,
      hobbies() {
        let hobbyOptions = ['Pintura', 'Teatro', 'Academia', 'Leitura', 'Tocar violão', 'Pescaria', 'Praticar arte marcial', 'Jogar cartas', 'Viajar', 'Cozinhar', 'Escutar música', 'Desenhar', 'Fotografia', 'Meditação', 'Yoga', 'Corrida', 'Andar de bike', 'Jogos eletrônicos'];
        let hobbies = '';
        let n = Math.floor(3 * Math.random());
        while (n > 0) {
          let index = Math.floor(hobbyOptions.length * Math.random());
          hobbies += hobbyOptions.splice(index, 1) + ', ';
          n--;
        }
        hobbies += hobbyOptions.splice(Math.floor(hobbyOptions.length * Math.random()), 1);
        return hobbies;
      },
      instagramUrl: 'https://www.instagram.com',
      language: 'pt-br',
      lastName() {
        return _faker.default.name.lastName();
      },
      linkedinUrl: 'https://www.linkedin.com',
      // newPassword, // Super Deprecated
      // otherSport,
      outlookOAuth: false,
      password: '$2a$04$Lmw7DorZWjr1cJOP9XVbU.zp3X1Zhwb6got92L.EX69eGX0a5Gk3i',
      phone() {
        return _faker.default.phone.phoneNumber();
      },
      practicesSports: false,
      preferences: '{"receiveTaskAlertEmails":true}',
      // professionalAddress,
      professionalCellphone() {
        return _faker.default.phone.phoneNumber();
      },
      professionalEmail() {
        return _faker.default.internet.exampleEmail();
      },
      // professionalCompany,
      // professionalComplement,
      // professionalNeighborhood,
      // professionalNumber,
      professionalPhone() {
        return _faker.default.phone.phoneNumber();
      },
      // professionalZipcode,
      // registration,
      reservistNumber: '0',
      // skype,
      superUser: false,
      twitterUrl: 'https://twitter.com',
      typeEvaluation() {
        return Math.floor(Math.random() * Math.floor(3));
      },
      username(i) {
        return i;
      },
      voterRegistrationCard: '0'
    }),
    branches: (0, _emberCliMirage.trait)({
      afterCreate(user, server) {
        server.createList('branch', 3);
      }
    })
  });
  _exports.default = _default;
});