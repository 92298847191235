define("portal-talentrh/pods/habitus/details/feeling/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HabitusDetailsFeelingController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model.user'), _dec5 = Ember.computed.alias('model.pdis'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('isSubordinate', 'jobFunction', 'permission.accessProfile.admin'), _dec12 = Ember.computed('isSubordinate', 'jobRole', 'permission.accessProfile.admin'), _dec13 = Ember.computed('this.permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam', 'this.permission.admin', 'this.isHistory'), (_class = class HabitusDetailsFeelingController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['insightLed']);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "pdis", _descriptor5, this);
      _initializerDefineProperty(this, "jobFunction", _descriptor6, this);
      _initializerDefineProperty(this, "jobRole", _descriptor7, this);
      _initializerDefineProperty(this, "isSubordinate", _descriptor8, this);
      _initializerDefineProperty(this, "chart", _descriptor9, this);
      _initializerDefineProperty(this, "isHistory", _descriptor10, this);
    }
    *loadUserJobFunctionOrJobRole() {
      try {
        const {
          admin,
          manager
        } = this.permission.accessProfile;
        if (!admin && !manager) {
          const usersIds = yield this.fetch.request('users/userSubordinateAjax', {
            data: {
              onlyIds: 1
            }
          });
          if (usersIds.lenght) {
            const isSubordinate = usersIds.includes(Number(this.user.id));
            this.isSubordinate = isSubordinate;
          }
        }
        if (this.permission.companyConfig.showJobFunction) {
          if (this.user.get('jobFunction.id')) {
            const jobFunctions = yield this.fetch.request('jobFunctions/ajax', {
              data: {
                ids: this.user.get('jobFunction.id')
              }
            });
            this.jobFunction = jobFunctions[0];
          } else {
            this.jobFunction = null;
          }
        } else {
          if (this.user.get('jobRole.id')) {
            const jobRoles = yield this.fetch.request('jobRoles/ajax', {
              data: {
                ids: this.user.get('jobRole.id')
              }
            });
            this.jobRole = jobRoles[0];
          } else {
            this.jobRole = null;
          }
        }
      } catch (e) {
        this.toast.error('Não foi possível carregar as informações do usuário.');
      }
    }
    get jobFunctionTitle() {
      const jobFunction = this.jobFunction;
      if (!jobFunction) return null;
      const {
        admin,
        manager
      } = this.permission.accessProfile;
      if ((admin || manager || jobFunction.visible || this.isSubordinate) && jobFunction.title) {
        return jobFunction.title;
      }
      return null;
    }
    get jobRoleTitle() {
      const jobRole = this.jobRole;
      if (!jobRole) return null;
      const {
        admin,
        manager
      } = this.permission.accessProfile;
      if ((admin || manager || jobRole.visible || this.isSubordinate) && jobRole.title) {
        return jobRole.title;
      }
      return null;
    }
    get permitShowAllLeds() {
      return !this.isHistory && (this.permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam || this.permission.admin);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pdis", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "jobFunction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "jobRole", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSubordinate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isHistory", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadUserJobFunctionOrJobRole", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadUserJobFunctionOrJobRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobFunctionTitle", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctionTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jobRoleTitle", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "jobRoleTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "permitShowAllLeds", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "permitShowAllLeds"), _class.prototype)), _class));
  _exports.default = HabitusDetailsFeelingController;
});