define("portal-talentrh/pods/feed/me/schedule/skeleton/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4Usp0u/E",
    "block": "{\"symbols\":[\"Skeleton\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[],[[\"@count\"],[5]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"li\"],[14,0,\"list-group-item d-flex align-items-center py-2 border-0\"],[12],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-25\"]],[[],[]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"ml-3 pl-3 flex-fill border-left\"],[12],[2,\"\\n        \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n        \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-50\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/schedule/skeleton/template.hbs"
    }
  });
  _exports.default = _default;
});