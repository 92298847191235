define("portal-talentrh/pods/components/skills/list/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ListComponent = (_dec = Ember._action, (_class = class ListComponent extends _component.default {
    get skills() {
      const {
        hardSkills,
        softSkills,
        isHardSkills
      } = this.args;
      return isHardSkills ? hardSkills : softSkills;
    }
    onChangeSkillSelected(skill, index) {
      const {
        hardSkills,
        softSkills
      } = this.args;
      hardSkills?.forEach(skillFounded => {
        if (skillFounded.name !== skill.name) {
          Ember.set(skillFounded, 'show', false);
        }
      });
      softSkills?.forEach(skillFounded => {
        if (skillFounded.name !== skill.name) {
          Ember.set(skillFounded, 'show', false);
        }
      });
      Ember.set(this.skills[index], 'show', !this.skills[index]?.show);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onChangeSkillSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSkillSelected"), _class.prototype)), _class));
  _exports.default = ListComponent;
});