define("portal-talentrh/pods/indicators/people-analytics/my-team/company-performance/nine-box-details/controller", ["exports", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsMyTeamCompanyPerformanceNineBoxDetailsController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class IndicatorsPeopleAnalyticsMyTeamCompanyPerformanceNineBoxDetailsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['id', 'usersIds', 'branch', 'department', 'jobRole', 'jobFunction']);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "nineBoxCycle", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
    }
    *initNineBox() {
      try {
        let nineBoxCyclesSelect = yield this.fetch.request(`nineBoxCycles/ajax`, {
          host: _environment.default.apiUrl.avd,
          data: {
            type: 'indicators'
          }
        });
        if (nineBoxCyclesSelect.length) {
          this.loadNineBox.perform();
        }
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: MyTeam :: CompanyPerformance :: NineBoxDetails :: initNineBox`);
      }
    }
    *loadNineBox() {
      try {
        const filters = this.buildFilters();
        filters.limit = 1;
        filters.loadUsersQuadrants = 1;
        let nineBoxCycles = yield this.fetch.request(`nineBoxCycles`, {
          host: _environment.default.apiUrl.avd,
          data: filters
        });
        let nineBox = nineBoxCycles.nineBoxes;
        nineBox.columns = nineBoxCycles.nineBoxColumns;
        nineBox.lines = nineBoxCycles.nineBoxLines;
        nineBox.quadrants = nineBoxCycles.nineBoxQuadrants;
        nineBox.cycles = nineBoxCycles.nineBoxCycles;
        nineBox.users = nineBoxCycles.users;
        nineBox.jobFunctions = nineBoxCycles.jobFunctions;
        nineBox.jobRoles = nineBoxCycles.jobRoles;
        nineBox.departments = nineBoxCycles.departments;
        for (let quadrant of nineBox.quadrants) {
          let arrUsersQuadrant = [];
          for (let userQuadrant of quadrant.usersQuadrant) {
            let objUserQuadrant = nineBoxCycles.nineBoxUserQuadrants.find(u => u.id.toString() === userQuadrant.toString());
            objUserQuadrant.user = nineBoxCycles.users.find(u => u.id === objUserQuadrant.user);
            arrUsersQuadrant.push(objUserQuadrant);
          }
          quadrant.usersQuadrant = arrUsersQuadrant;
        }
        this.nineBoxCycle = nineBox;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: MyTeam :: CompanyPerformance :: NineBoxDetails :: loadNineBox`);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.branch) {
        filters.branch = this.branch;
      }
      if (this.department) {
        filters.department = this.department;
      }
      if (this.jobRole) {
        filters.jobRole = this.jobRole;
      }
      if (this.jobFunction) {
        filters.jobFunction = this.jobFunction;
      }
      if (this.id) {
        filters.id = this.id;
      }
      this.filters = filters;
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "nineBoxCycle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initNineBox", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initNineBox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNineBox", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadNineBox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsMyTeamCompanyPerformanceNineBoxDetailsController;
});