define("portal-talentrh/pods/indicators/people-analytics/human-resources/organizational-climate/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateIndexRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {};
      this.controller.prevailingClimateData = Ember.A();
      this.controller.feelingsNegativeVsPositiveData = Ember.A();
      this.controller.absencesIndicatorData = Ember.A();
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateIndexRoute;
});