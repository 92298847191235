define("portal-talentrh/helpers/is-feeling-negative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function isFeelingNegative(params /*, hash*/) {
    if (!params[0]) {
      return false;
    }
    if (params[0] && params[0].includes('gravatar')) {
      return params[0];
    }
    return ['Irritado', 'Cansado', 'Entediado', 'Ansioso', 'Triste', 'Desanimado'].includes(params[0]);
  });
  _exports.default = _default;
});