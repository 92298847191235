define("portal-talentrh/pods/group/index/controller", ["exports", "portal-talentrh/utils/remove-accents"], function (_exports, _removeAccents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    groupsService: Ember.inject.service('groups'),
    swal: Ember.inject.service(),
    // Default values
    filter: '',
    // Single line computed properties
    isGroupAdmin: Ember.computed.or('model.user.accessProfile.admin', 'model.user.groupsAdmin'),
    // Multiline computed properties
    groups: Ember.computed('filter', 'groupsService.isListLoaded', 'isGroupAdmin', 'model.userGroups.@each.totalParticipants', 'showAll', function () {
      if (this.showAll && this.isGroupAdmin) {
        let userGroups = Ember.get(this, 'model.userGroups');
        return userGroups.filter(item => {
          return item.name && (0, _removeAccents.default)(item.name).match(new RegExp((0, _removeAccents.default)(this.filter.trim()), 'i'));
        });
      } else {
        return this.groupsService.filter(this.filter);
      }
    }),
    // Actions
    actions: {
      delete(group) {
        let showAll = this.showAll;
        let isGroupAdmin = this.isGroupAdmin;
        this.swal.confirm('Tem certeza que deseja excluir?').then(result => {
          if (!result.value) {
            return;
          }
          this.store.findRecord('user-group', group.id).then(data => {
            data.destroyRecord().then(() => {
              if (showAll && isGroupAdmin) {
                let userGroups = Ember.get(this, 'model.userGroups').filter(g => g.id !== group.id);
                Ember.set(this, 'model.userGroups', userGroups);
              }
              this.groupsService.loadList();
              this.swal.success('Removido com sucesso!');
            }).catch(this.swal.catch);
          });
        });
      },
      editGroup(group) {
        this.transitionToRoute('group.edit', group.id);
      },
      toggleShowAll(value) {
        Ember.set(this, 'showAll', value);
      }
    }
  });
  _exports.default = _default;
});