define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/arrow/right-arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q/1YSqnA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-center mt-3\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"the-arrow right\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"shaft\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"mt-1\"],[12],[2,\"\\n    \"],[1,[32,0,[\"axisDescription\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/arrow/right-arrow/template.hbs"
    }
  });
  _exports.default = _default;
});