define("portal-talentrh/helpers/chat-message-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chatMessageHelper = chatMessageHelper;
  _exports.default = void 0;
  const escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  function isURL() {
    let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const pattern = /^(?:http(s)?:\/\/)?[\w-]+(?:\.[\w-]+)+[\w\-._~:/?#[\]@!$&'()*+=]+$/;
    return pattern.test(str);
  }
  function addhttp() {
    let $url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    try {
      if (!/^(f|ht)tps?:\/\//i.test($url)) {
        $url = "http://" + $url;
      }
    } catch (error) {
      Ember.debug(`[helper chat-message-helper] Não foi possível processar ${$url}`);
    }
    return $url;
  }
  function defineLinks() {
    let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    const words = text.split(' ');
    for (const word in words) {
      if (words.hasOwnProperty(word)) {
        const element = words[word];
        if (isURL(element)) {
          let url = addhttp(element);
          text = text.replace(element, `<a href='${url}' target='_blank'>${element}</a>`);
        }
      }
    }
    return text;
  }
  function chatMessageHelper(_ref /*, hash*/) {
    let [text] = _ref;
    text = escapeExpression(text);
    text = defineLinks(text);
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    return Ember.String.htmlSafe(text);
  }
  var _default = Ember.Helper.helper(chatMessageHelper);
  _exports.default = _default;
});