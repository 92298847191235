define("portal-talentrh/pods/components/profile-feeling/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    src: '',
    title: '',
    femaleFeelings: {
      Feliz: 'feliz',
      Apaixonado: 'apaixonada',
      Irritado: 'irritada',
      Cansado: 'cansada',
      Inteligente: 'inteligente',
      Entediado: 'entediada',
      Ansioso: 'ansiosa',
      Legal: 'legal',
      Triste: 'triste',
      Desanimado: 'desanimada',
      Divertido: 'divertida',
      Entusiasmado: 'entusiasmada'
    },
    maleFeelings: {
      Feliz: 'feliz',
      Apaixonado: 'apaixonado',
      Irritado: 'irritado',
      Cansado: 'cansado',
      Inteligente: 'inteligente',
      Entediado: 'Entediado',
      Ansioso: 'ansioso',
      Legal: 'legal',
      Triste: 'triste',
      Desanimado: 'desanimado',
      Divertido: 'divertido',
      Entusiasmado: 'entusiasmado'
    },
    init() {
      this._super(...arguments);
      this.feelings = {
        Feliz: 'happy.svg',
        Apaixonado: 'in-love.svg',
        Irritado: 'angry.svg',
        Cansado: 'tired.svg',
        Inteligente: 'smart.svg',
        Entediado: 'bored.svg',
        Ansioso: 'eager.svg',
        Legal: 'cool.svg',
        Triste: 'sad.svg',
        Desanimado: 'dispirited.svg',
        Divertido: 'funny.svg',
        Entusiasmado: 'excited.svg'
      };
    },
    onInsertElement: (0, _emberConcurrency.task)(function* () {
      let user = this.user;
      let response = yield this.ajax.request(`/users/${Ember.get(user, 'id')}/feelingtoday`);
      if (response.answered) {
        let feelings = this.feelings;
        let type = user.gender === 'F' ? this.femaleFeelings[response.type] : this.maleFeelings[response.type];
        let src = _environment.default.cdnUrl + `icons/feeling/${Ember.get(feelings, response.type)}`;
        let title = `${Ember.get(user, 'firstName')} está se sentindo ${type.toLowerCase()} hoje.`;
        Ember.set(this, 'src', src);
        Ember.set(this, 'title', title);
      }
    }).on('didInsertElement')
  });
  _exports.default = _default;
});