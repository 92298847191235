define("portal-talentrh/pods/group/index/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    classNames: ['col-lg-3', 'col-6', 'text-center'],
    permission: Ember.inject.service(),
    allowSendMessageGroupChat: Ember.computed.or('permission.accessProfile.allowSendMessageGroupChat', 'permission.admin'),
    // Multiline computed properties
    canManageGroup: Ember.computed('user.id', 'group.userAdminIds', function () {
      const isAdmin = (this.group.userAdminIds || []).includes(parseInt(this.user.id));
      return this.user.get('accessProfile.admin') || isAdmin;
    }),
    isOnGroup: Ember.computed('user.id', function () {
      let usersOnGroup = this.group.userIds;
      let id = parseInt(this.user.id);
      return usersOnGroup.includes(id);
    }),
    // Actions
    actions: {
      delete() {
        if (this.delete && this.canManageGroup) {
          this.delete(this.group);
        }
      },
      editGroup() {
        if (this.editGroup && this.canManageGroup) {
          this.editGroup(this.group);
        }
      },
      openChat() {
        if (this.openChat) {
          this.openChat(this.group);
        }
      }
    }
  });
  _exports.default = _default;
});