define("portal-talentrh/models/feeling", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _model.attr)('date'),
    type: (0, _model.attr)('string'),
    motive: (0, _model.attr)('string'),
    factor: (0, _model.attr)('string'),
    userGender: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    user: (0, _model.belongsTo)('user'),
    leader: (0, _model.belongsTo)('user'),
    actions: (0, _model.hasMany)('feeling-action'),
    typeTextByGender: Ember.computed('type', function () {
      const textFemale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonada',
        Irritado: 'Irritada',
        Cansado: 'Cansada',
        Inteligente: 'Inteligente',
        Entediado: 'Entediada',
        Ansioso: 'Ansiosa',
        Legal: 'Legal',
        Desanimado: 'Desanimada',
        Triste: 'Triste',
        Divertido: 'Divertida',
        Entusiasmado: 'Entusiasmada'
      };
      const textMale = {
        Feliz: 'Feliz',
        Apaixonado: 'Apaixonado',
        Irritado: 'Irritado',
        Cansado: 'Cansado',
        Inteligente: 'Inteligente',
        Entediado: 'Entediado',
        Ansioso: 'Ansioso',
        Legal: 'Legal',
        Desanimado: 'Desanimado',
        Triste: 'Triste',
        Divertido: 'Divertido',
        Entusiasmado: 'Entusiasmado'
      };
      return this.user.get('gender') === 'F' ? textFemale[this.type] : textMale[this.type];
    }),
    emojiSource: Ember.computed('type', function () {
      const options = {
        Feliz: 'happy.svg',
        Apaixonado: 'in-love.svg',
        Irritado: 'angry.svg',
        Cansado: 'tired.svg',
        Inteligente: 'smart.svg',
        Entediado: 'bored.svg',
        Ansioso: 'eager.svg',
        Legal: 'cool.svg',
        Triste: 'sad.svg',
        Desanimado: 'dispirited.svg',
        Divertido: 'funny.svg',
        Entusiasmado: 'excited.svg'
      };
      return options[this.type];
    }),
    isNegative: Ember.computed('type', function () {
      return ['Irritado', 'Cansado', 'Entediado', 'Ansioso', 'Triste', 'Desanimado'].includes(this.type);
    })
  });
  _exports.default = _default;
});