define("portal-talentrh/pods/application/navbar/notification/like/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WswNW+GO",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"@notification\"],\"statements\":[[8,\"link-to\",[[24,0,\"d-flex py-4\"]],[[\"@route\",\"@model\"],[\"post.show\",[32,3,[\"post\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"user-attribute\",[],[[\"@userId\"],[[32,3,[\"relatedsUsers\",\"lastObject\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-avatar\",[[24,0,\"mb-1\"]],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,3,[\"relatedsUsers\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"fullName\"]]],[2,\" curtiu sua publicação:\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,3,[\"relatedsUsers\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"fullName\"]]],[2,\" e mais \"],[1,[30,[36,0],[[32,3,[\"relatedsUsers\",\"length\"]],1],null]],[2,\" curtiram sua publicação:\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[10,\"i\"],[12],[2,\"\\n          \\\"\"],[1,[30,[36,4],[[30,[36,2],[[30,[36,1],[[32,3,[\"post\",\"type\"]],\"message\"],null],[32,3,[\"post\",\"body\"]],[32,3,[\"post\",\"title\"]]],null]],[[\"limit\"],[18]]]],[2,\"\\\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n        \"],[1,[30,[36,5],[[32,3,[\"updatedAt\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sub\",\"eq\",\"if\",\"gt\",\"truncate-text\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/like/template.hbs"
    }
  });
  _exports.default = _default;
});