define("portal-talentrh/models/city", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    ddd: (0, _model.attr)('string'),
    ibge: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    official: (0, _model.attr)('string'),
    uf: (0, _model.attr)('string'),
    zipcode: (0, _model.attr)('string'),
    // Associations
    state: (0, _model.belongsTo)('state'),
    companies: (0, _model.hasMany)('company')
  });
  _exports.default = _default;
});