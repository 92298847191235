define("portal-talentrh/pods/components/skeleton/birthday/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jA3qMZch",
    "block": "{\"symbols\":[\"Skeleton\"],\"statements\":[[8,\"ui-skeleton\",[[24,0,\"py-5\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Avatar\"]],[[24,0,\"avatar-7 mb-3 position-relative centralize-x\"]],[[],[]],null],[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-75 position-relative centralize-x\"]],[[],[]],null],[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-50 position-relative centralize-x\"]],[[],[]],null],[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-25 position-relative centralize-x mt-3\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/skeleton/birthday/template.hbs"
    }
  });
  _exports.default = _default;
});