define("portal-talentrh/pods/application/welcome-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LWj12U6l",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"showWelcomeVideo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"welcome-message position-fixed t-0 l-0\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-link welcome-button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"closeWelcomeVideo\"]]],null]],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fas fa-times h4\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"iframe\"],[14,\"src\",\"https://player.vimeo.com/video/756946924?h=b1eebaadbe?controls=0\"],[14,0,\"position-absolute z-index-1 t-5 r-9\"],[14,\"frameborder\",\"0\"],[14,\"width\",\"500px\"],[14,\"height\",\"500px\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"welcome-message__backdrop\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/welcome-message/template.hbs"
    }
  });
  _exports.default = _default;
});