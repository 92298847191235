define("portal-talentrh/pods/components/widgets/latest-announcements-home/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    loadWidget: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          posts
        } = yield this.ajax.request('widgets/announcements', {
          host: _environment.default.apiUrl.core
        });
        this.set('announcements', posts);
      } catch (e) {
        Ember.debug(e);
      }
    }).drop().on('didInsertElement')
  });
  _exports.default = _default;
});