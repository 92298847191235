define("portal-talentrh/pods/search/post/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Cxr/tK2Q",
    "block": "{\"symbols\":[\"@post\"],\"statements\":[[1,[32,0,[\"icon\"]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"flex-fill text-truncate ml-3\"],[12],[2,\"\\n  \"],[10,\"h6\"],[14,0,\"text-truncate font-weight-bold mb-0\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"title\"]],[30,[36,0],[[32,1,[\"body\"]]],null]],null]],[[\"limit\"],[80]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"small\"],[12],[2,\"Publicado \"],[1,[30,[36,3],[[32,1,[\"createdAt\"]]],null]],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"stretched-link\"]],[[\"@route\",\"@model\"],[\"post.show\",[32,1,[\"id\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html\",\"or\",\"truncate-text\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/search/post/template.hbs"
    }
  });
  _exports.default = _default;
});