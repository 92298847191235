define("portal-talentrh/pods/components/request-reply/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let RequestReplyComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('args.solicitation.solicitationType'), _dec5 = Ember.computed('args.solicitation.{motive,vacationPaidType}', 'terminationOptions'), _dec6 = Ember._action, _dec7 = Ember.computed('args.solicitation.vacationPaidType', 'dash.route', 'dashboardOptions', 'vacationOptions'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class RequestReplyComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "toast", _descriptor3, this);
      _defineProperty(this, "terminationOptions", [{
        label: 'Dispensa',
        value: '0'
      }, {
        label: 'Pedido de demissão',
        value: '1'
      }]);
      _defineProperty(this, "vacationOptions", [{
        label: 'Não abonar',
        value: null
      }, {
        label: 'Abonar no início',
        value: 'begin'
      }, {
        label: 'Abonar no fim',
        value: 'end'
      }]);
      _initializerDefineProperty(this, "type", _descriptor4, this);
    }
    get terminationSelected() {
      return this.terminationOptions.find(option => {
        return option.value === this.args.solicitation.motive;
      });
    }
    set terminationSelected(selected) {
      Ember.set(this.args, 'solicitation.motive', selected.value);
      Ember.set(this.args, 'solicitation.motiveLabel', selected.motiveLabel);
    }
    onSelectTerminationMotive(selected) {
      Ember.set(this.args, 'solicitation.motive', selected.value);
      Ember.set(this.args, 'solicitation.motiveLabel', selected.motiveLabel);
    }
    get vacationSelected() {
      return this.vacationOptions.find(option => {
        return option.value === this.args.solicitation.vacationPaidType;
      });
    }
    set vacationSelected(selected) {
      Ember.set(this.args, 'solicitation.vacationPaidType', selected.value);
    }
    onSelectVacationType(selected) {
      Ember.set(this.args, 'solicitation.vacationPaidType', selected.value);
    }
    setTerminationDate(_ref) {
      let {
        moment
      } = _ref;
      const formattedDate = moment.format('YYYY-MM-DD HH:mm');
      Ember.set(this.args.solicitation, 'terminationDate', formattedDate);
    }
    setNoticeDate(_ref2) {
      let {
        moment
      } = _ref2;
      const formattedDate = moment.format('YYYY-MM-DD HH:mm');
      Ember.set(this.args.solicitation, 'noticeDate', formattedDate);
    }
    setVacationInitial(_ref3) {
      let {
        moment
      } = _ref3;
      const formattedDate = moment.format('YYYY-MM-DD');
      Ember.set(this.args.solicitation, 'vacationInitial', formattedDate);
    }
    approve(data) {
      const {
        approve,
        solicitation
      } = this.args;
      const {
        solicitationType: type
      } = solicitation;
      let formattedData;
      if (type == 4) {
        const formattedVacationInitial = data.vacationInitial ? (0, _moment.default)(data.vacationInitial).format('YYYY.MM.DD') : null;
        formattedData = {
          extraFields: {
            vacationInitial: formattedVacationInitial,
            vacationDays: Number(data.vacationDays),
            vacationDaysPaid: data.vacationDaysPaid ?? data.vacationDaysPaid,
            vacationPaidType: this.vacationSelected ? this.vacationSelected.value : null
          },
          approve: 1,
          message: data.message
        };
      } else if (type == 7) {
        const formattedTerminationDate = data.terminationDate ? (0, _moment.default)(data.terminationDate).format('YYYY.MM.DD HH:mm:ss') : null;
        const formattedNoticeDate = data.noticeDate ? (0, _moment.default)(data.noticeDate).format('YYYY.MM.DD HH:mm:ss') : null;
        formattedData = {
          extraFields: {
            motive: data.motive,
            terminationDate: formattedTerminationDate,
            noticeDate: formattedNoticeDate
          },
          approve: 1,
          message: data.message
        };
      }
      approve(formattedData);
    }
    closeModal() {
      this.args.closeModal();
    }
    setDisabled() {
      this.args.setDisabled();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "terminationSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "terminationSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectTerminationMotive", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectTerminationMotive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "vacationSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "vacationSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectVacationType", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectVacationType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTerminationDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setTerminationDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNoticeDate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setNoticeDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setVacationInitial", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setVacationInitial"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDisabled", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setDisabled"), _class.prototype)), _class));
  _exports.default = RequestReplyComponent;
});