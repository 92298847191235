define("portal-talentrh/pods/profile/show/team/leader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uu/YzHj4",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"option\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"]],[[\"@show\",\"@size\",\"@onClose\"],[true,\"lg\",[30,[36,0],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Definir líder\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Líder imediato\"],[13],[2,\"\\n      \"],[8,\"nela-select\",[],[[\"@placeholder\",\"@selected\",\"@modelName\",\"@endpoint\",\"@query\",\"@disabled\"],[\"Selecione o líder\",[32,0,[\"led\",\"appraisedBy\"]],\"user\",\"/users/ajax\",[32,0,[\"query\"]],[32,0,[\"led\",\"hasNoLeader\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,3,[\"fullName\"]]],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[8,\"ui-checkbox\",[[24,0,\"mt-4\"]],[[\"@checked\",\"@onChange\"],[[32,0,[\"led\",\"hasNoLeader\"]],[30,[36,0],[[32,0,[\"toggleHasNoLeader\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"Usuário sem líder\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,1],[[32,0,[\"save\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/show/team/leader/template.hbs"
    }
  });
  _exports.default = _default;
});