define("portal-talentrh/models/employee-evaluation", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    appraisedConcluded: (0, _model.attr)('boolean'),
    appraiserConcluded: (0, _model.attr)('boolean'),
    teamConcluded: (0, _model.attr)('boolean'),
    consensusConcluded: (0, _model.attr)('boolean'),
    concluded: (0, _model.attr)('boolean'),
    skill: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    type: (0, _model.attr)('number'),
    appraisedUser: (0, _model.belongsTo)('user'),
    appraiserUser: (0, _model.belongsTo)('user'),
    limitDate: Ember.computed('createdAt', function () {
      return (0, _moment.default)(this.createdAt).endOf('month');
    })
  });
  _exports.default = _default;
});