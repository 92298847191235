define("portal-talentrh/pods/view-team-member/projects/concluded/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberProjectsConcludedController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ViewTeamMemberProjectsConcludedController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "toast", _descriptor2, this);
      _initializerDefineProperty(this, "openTaskModal", _descriptor3, this);
      _initializerDefineProperty(this, "projects", _descriptor4, this);
      _initializerDefineProperty(this, "selectedProject", _descriptor5, this);
      _initializerDefineProperty(this, "userId", _descriptor6, this);
      _initializerDefineProperty(this, "filteredTasks", _descriptor7, this);
      _initializerDefineProperty(this, "taskStatus", _descriptor8, this);
      _initializerDefineProperty(this, "filters", _descriptor9, this);
    }
    *loadProjects(userId) {
      if (userId) {
        try {
          let projects = yield this.fetch.request(`users/${userId}/projects?concluded=true`);
          this.projects = projects.data;
          this.userId = userId;
          this.setSelectedProject(projects.data[0]);
          this.setFilterTasks('all');
        } catch (e) {
          Ember.debug(`Não foi possível carregar os projetos no perfil do usuário.`);
        }
      }
    }
    setSelectedProject(project) {
      this.selectedProject = project;
      this.filteredTasks = project.tasks.all;
      this.taskStatus = 'all';
    }
    setFilterTasks(status) {
      let tasks = {
        'all': this.selectedProject.tasks.all,
        'noDeadline': this.selectedProject.tasks.noDeadline,
        'pending': this.selectedProject.tasks.pending,
        'late': this.selectedProject.tasks.late,
        'concluded': this.selectedProject.tasks.concluded
      };
      this.taskStatus = status;
      this.filteredTasks = tasks[status];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "openTaskModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "projects", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedProject", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filteredTasks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "taskStatus", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        concluded: true
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadProjects", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedProject", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedProject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFilterTasks", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setFilterTasks"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberProjectsConcludedController;
});