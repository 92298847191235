define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-answers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SLPuu1A1",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"@show\",\"@onCloseModal\",\"@evaluation\",\"@employeeEvaluationTeams\",\"@employeeEvaluationTeamsUsers\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@size\",\"@toggle\"],[[32,3],\"lg\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Respostas da Avaliação\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"p-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"view-team-member/performance-management/employee-evaluations/modal-answers/answer-list\",[],[[\"@evaluation\",\"@class\",\"@employeeEvaluationTeams\",\"@employeeEvaluationTeamsUsers\"],[[32,5],[34,0],[32,6],[32,7]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"    \\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"appraiserListClass\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-answers/template.hbs"
    }
  });
  _exports.default = _default;
});