define("portal-talentrh/pods/components/user-list-experiences/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-group list-group-flush']
  });
  _exports.default = _default;
});