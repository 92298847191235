define("portal-talentrh/pods/components/toolbar-lunch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WDnJuo/f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,5],[[30,[36,4],[[30,[36,3],[[35,2],0],null],[30,[36,1],[[35,0]],null]],null],\"d-none\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui-block mb-3 lunch-block\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-block-title\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"title\"],[12],[2,\"Gostaria de solicitar a refeição para hoje?\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"ui-block-content p-4\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"title\"],[12],[2,\"Restam \"],[1,[34,6]],[2,\" para realizar o pedido.\"],[13],[2,\"\\n      \"],[10,\"p\"],[12],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-block m-0 text-white\"],[15,\"onclick\",[30,[36,8],[[35,7]],null]],[12],[2,\"\\n            Não, Obrigado\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n          \"],[10,\"button\"],[14,0,\"btn btn-block btn-fill m-0 text-white\"],[15,\"onclick\",[30,[36,8],[[35,9]],null]],[12],[2,\"\\n            Sim, Por Favor\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showOrderLunch\",\"not\",\"seconds\",\"lt\",\"or\",\"if\",\"diff\",\"cancel\",\"perform\",\"confirm\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/toolbar-lunch/template.hbs"
    }
  });
  _exports.default = _default;
});