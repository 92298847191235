define("portal-talentrh/pods/components/preloader-animation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ejEHl1WA",
    "block": "{\"symbols\":[\"@isLogin\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"loading-voors mx-auto \",[30,[36,0],[[32,1],\"loading-centered\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"v-voors\"],[14,\"width\",\"56\"],[14,\"height\",\"56\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors/icons/v-voors.svg\"],[14,\"alt\",\"V do voors\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"spinner-voors mx-auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"img\"],[14,0,\"r-voors\"],[14,\"width\",\"56\"],[14,\"height\",\"56\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors/icons/r-voors.svg\"],[14,\"alt\",\"R do voors\"],[12],[13],[2,\"\\n  \"],[10,\"img\"],[14,0,\"s-voors\"],[14,\"width\",\"56\"],[14,\"height\",\"56\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors/icons/s-voors.svg\"],[14,\"alt\",\"S do voors\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/preloader-animation/template.hbs"
    }
  });
  _exports.default = _default;
});