define("portal-talentrh/pods/components/feed/post-comments/comment/reply-field/component", ["exports", "ember-concurrency", "moment-timezone"], function (_exports, _emberConcurrency, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['comment-form', 'sub-comments-form'],
    classNameBindings: 'showReplyField:d-flex:d-none',
    showReplyField: false,
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    replyComment: (0, _emberConcurrency.task)(function* () {
      const comment = this.newComment;
      const mentions = this.commentMentions;
      if (comment.length < 1) {
        return;
      }
      let body = {
        data: {
          comment: {
            text: comment,
            isResponse: true,
            post: Ember.get(this, 'post.id'),
            responseTo: Ember.get(this, 'comment.id'),
            mentions
          }
        }
      };
      try {
        let created = yield this.ajax.post('comments', body);
        this.store.pushPayload(created);
        let emberObj = this.store.peekRecord('comment', created.comment.id);
        if (!emberObj) {
          return;
        }
        const {
          comment
        } = created;
        const timezone = _momentTimezone.default.tz.guess();
        let responseCommentDate = _momentTimezone.default.tz(comment.createdAt, timezone).format("YYYY-MM-DD HH:mm ZZ");
        created.comment.createdAt = responseCommentDate;
        Ember.set(this, 'newComment', '');
        Ember.set(this, 'commentMentions', null);
        Ember.set(this, 'showReplies', true);
        this.replies.clear();
        this.replies.addObject(created.comment);
      } catch (e) {
        console.log(e);
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      autoGrow() {
        const element = document.getElementById(`input-reply${this.comment.id}`);
        element.style.height = element.scrollHeight + 'px';
      }
    }
  });
  _exports.default = _default;
});