define("portal-talentrh/pods/portal-manager/team-members/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PortalManagerPaymentController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed('branchesSelected.[]'), _dec15 = Ember.computed('departmentsSelected.[]'), _dec16 = Ember.computed('branchesSelected.[]', 'departmentsSelected.[]', 'jobFunctionsSelected.[]', 'jobRolesSelected.[]'), _dec17 = Ember.computed('branchesSelected.[]', 'departmentsSelected.[]', 'jobFunctionsSelected.[]', 'jobRolesSelected.[]'), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class PortalManagerPaymentController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "advancedFilter", _descriptor5, this);
      _initializerDefineProperty(this, "filterTag", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "branchesSelected", _descriptor8, this);
      _initializerDefineProperty(this, "departmentsSelected", _descriptor9, this);
      _initializerDefineProperty(this, "jobFunctionsSelected", _descriptor10, this);
      _initializerDefineProperty(this, "jobRolesSelected", _descriptor11, this);
      _initializerDefineProperty(this, "usersSelected", _descriptor12, this);
      _initializerDefineProperty(this, "haveLeds", _descriptor13, this);
    }
    get selectedBranchesIds() {
      if (this.branchesSelected && this.branchesSelected.length) {
        return this.branchesSelected.map(b => b.id);
      }
      return null;
    }
    get selectedDepartmentIds() {
      if (this.departmentsSelected && this.departmentsSelected.length) {
        return this.departmentsSelected.map(b => b.id);
      }
      return null;
    }
    get disableBranchFilter() {
      const hasJobRoleOrFunction = this.jobFunctionsSelected.length > 0 || this.jobRolesSelected.length > 0;
      return !this.branchesSelected.length > 0 && (this.departmentsSelected.length > 0 || hasJobRoleOrFunction);
    }
    get disableDepartmentFilter() {
      const hasJobRoleOrFunction = this.jobFunctionsSelected.length > 0 || this.jobRolesSelected.length > 0;
      return !this.branchesSelected.length > 0 && !this.departmentsSelected.length > 0 && hasJobRoleOrFunction;
    }
    toggleAdvancedFilter() {
      this.advancedFilter = !this.advancedFilter;
      this.getFilters();
    }
    getFilters() {
      const filters = {};
      if (this.branchesSelected && this.branchesSelected.length) {
        filters.branches = this.branchesSelected.map(b => b.id);
      }
      if (this.departmentsSelected && this.departmentsSelected.length) {
        filters.departments = this.departmentsSelected.map(d => d.id);
      }
      if (this.jobFunctionsSelected && this.jobFunctionsSelected.length) {
        filters.jobFunctions = this.jobFunctionsSelected.map(jf => jf.id);
      }
      if (this.jobRolesSelected && this.jobRolesSelected.length) {
        filters.jobRoles = this.jobRolesSelected.map(jr => jr.id);
      }
      if (this.usersSelected && this.usersSelected.length) {
        filters.users = this.usersSelected.map(b => b.id);
      }
      if (this.filters.ledType) {
        filters.ledType = this.filters.ledType;
      }
      if (this.filters.teamManagement) {
        filters.teamManagement = this.filters.teamManagement;
      }
      if (this.filterTag) {
        filters.tagSituation = this.filterTag;
        if (this.filterTag === 'absent') {
          filters.tagSituation = ['compensatoryTime', 'absent'];
        }
      }
      this.filters = filters;
    }
    onChangeTagFilter(classCss, _ref) {
      let {
        target
      } = _ref;
      const parentNode = target.parentNode;
      this.filterTag = classCss;
      parentNode.querySelectorAll('span').forEach((span, index) => {
        if (index > 0) {
          span.classList.remove(span.classList[1]);
        }
      });
      this.getFilters();
      target.classList.add(`span-filter__${classCss}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "advancedFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterTag", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        ledType: 'd',
        teamManagement: true
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "branchesSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "departmentsSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionsSelected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "jobRolesSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "usersSelected", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "haveLeds", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedBranchesIds", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBranchesIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedDepartmentIds", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "selectedDepartmentIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableBranchFilter", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "disableBranchFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableDepartmentFilter", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "disableDepartmentFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAdvancedFilter", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdvancedFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeTagFilter", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTagFilter"), _class.prototype)), _class));
  _exports.default = PortalManagerPaymentController;
});