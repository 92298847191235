define("portal-talentrh/models/benefit", ["exports", "@ember-data/model", "portal-talentrh/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const COLORS = {
    agreement: '#e83e8c',
    education: '#6f42c1',
    food: '#ffc107',
    gym: '#495057',
    health: '#dc3545',
    support: '#28a745',
    transport: '#fd7e14',
    recreation: '#00134d',
    clothing: '#d0d1d6',
    beauty: '#f8c29e'
  };
  const ICONS = {
    agreement: 'fa-hands-helping',
    education: 'fa-graduation-cap',
    food: 'fa-utensils-alt',
    gym: 'fa-dumbbell',
    health: 'fa-medkit',
    support: 'fa-hand-holding-usd',
    transport: 'fa-bus',
    recreation: 'fa-bowling-pins',
    clothing: 'fa-tshirt',
    beauty: 'fa-spa'
  };
  const LABELS = {
    agreement: 'Convênio',
    education: 'Educação',
    food: 'Alimentação',
    gym: 'Academia',
    health: 'Saúde',
    support: 'Auxílio',
    transport: 'Transporte',
    recreation: 'Lazer',
    clothing: 'Vestuário',
    beauty: 'Beleza'
  };
  let BenefitModel = (_dec = (0, _model.attr)('boolean'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('date'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.belongsTo)('company'), _dec8 = (0, _model.hasMany)('branch', {
    inverse: 'benefits'
  }), (_class = class BenefitModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "active", _descriptor, this);
      _initializerDefineProperty(this, "category", _descriptor2, this);
      _initializerDefineProperty(this, "createdAt", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
      _initializerDefineProperty(this, "logoUrl", _descriptor5, this);
      _initializerDefineProperty(this, "title", _descriptor6, this);
      _initializerDefineProperty(this, "company", _descriptor7, this);
      _initializerDefineProperty(this, "branches", _descriptor8, this);
    }
    // Computed Properties
    get categoryLabel() {
      return LABELS[this.category] || 'Outros';
    }
    get color() {
      return COLORS[this.category] || '#007bff';
    }
    get icon() {
      return ICONS[this.category] || 'fa-ellipsis-h';
    }
    get logo() {
      if (!this.logoUrl) {
        return '/images/benefit.png';
      }
      return `${_environment.default.apiUrl.social}${this.logoUrl}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "logoUrl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "branches", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BenefitModel;
});