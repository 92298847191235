define("portal-talentrh/pods/components/post-like/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['d-flex', 'align-items-center', 'flex-fill', 'py-2'],
    fetch: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    permission: Ember.inject.service(),
    store: Ember.inject.service(),
    openModalLikes: false,
    hasTwoLikes: Ember.computed.equal('post.usersLiked.length', 2),
    hasMoreThanTwoLikes: Ember.computed.gt('post.usersLiked.length', 2),
    /** Verifica se o usuário da sessão curtiu o post. **/
    isLiked: Ember.computed('post.usersLiked.[]', 'session.user.id', function () {
      let sessionUserId = this.session.user.id;
      let usersLiked = this.post.usersLiked;
      if (!sessionUserId || !usersLiked) {
        return;
      }
      return usersLiked.includes(sessionUserId);
    }),
    /** Contém os X últimos usuários que curtiram o post. **/
    lastLikes: Ember.computed('post.usersLiked.[]', 'session.user.id', function () {
      const limit = 4;
      let usersLiked = this.post.usersLiked;
      if (!usersLiked) {
        return [];
      }
      let length = this.post.usersLiked.length;
      let me = this.session.user.id;
      let includesMe = usersLiked.includes(me);
      if (includesMe) {
        usersLiked.splice(usersLiked.indexOf(me), 1);
        usersLiked.push(me);
      }
      if (length > limit) {
        return usersLiked.slice(length - limit, length);
      } else {
        return usersLiked;
      }
    }),
    likeCount: Ember.computed('post.usersLiked.[]', function () {
      if (!this.post) {
        return '';
      }
      let likes = this.post.usersLiked.length;
      return likes > 1 ? `${likes} curtidas` : '1 curtida';
    }),
    /** Contém a quantidade de usuários que curtiram o post exceto 1, pois esse será exibido o nome.
      ex: João e outras 20 pessoas curtiram isso... **/
    totalExceptOne: Ember.computed('post.usersLiked.length', function () {
      return this.post.usersLiked.length - 1;
    }),
    like: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.fetch.post(`posts/${this.post.id}/like`);
        yield this.store.loadRecords('post', '/posts', {
          data: {
            id: this.post.id
          },
          host: 'core'
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});