define("portal-talentrh/pods/components/indicators/company-engagement/total-interactions/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TotalInteractionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed.alias('args.totalInteractionData'), _dec3 = Ember.computed.alias('totalInteractionData.totalInteractions'), _dec4 = Ember.computed.alias('totalInteractionData.totalPosts'), _dec5 = Ember.computed.alias('totalInteractionData.totalLikes'), _dec6 = Ember.computed.alias('totalInteractionData.totalComments'), _dec7 = Ember.computed.alias('totalInteractionData.totalShared'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class TotalInteractionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "utils", _descriptor, this);
      _initializerDefineProperty(this, "totalInteractionData", _descriptor2, this);
      _initializerDefineProperty(this, "totalInteractions", _descriptor3, this);
      _initializerDefineProperty(this, "totalPosts", _descriptor4, this);
      _initializerDefineProperty(this, "totalLikes", _descriptor5, this);
      _initializerDefineProperty(this, "totalComments", _descriptor6, this);
      _initializerDefineProperty(this, "totalShared", _descriptor7, this);
    }
    initChartTotal(canvas) {
      const that = this;
      const existData = this.totalPosts?.data[0] + this.totalLikes?.data[0] + this.totalComments?.data[0] + this.totalShared?.data[0];
      const data = existData ? that.totalInteractions?.data : [0, 100];
      const bgAndBorderColor = existData ? ['#FFBF00', '#6C6CD9', '#E786D7', '#36A2EB'] : ['#FFBF00', '#ccc'];
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data,
            backgroundColor: bgAndBorderColor,
            borderColor: bgAndBorderColor,
            borderWidth: 5
          }]
        },
        plugins: [{
          afterDraw: chart => {
            if (that.totalInteractions?.data.length) {
              that.utils.addTextInsideChart(chart);
            }
          },
          beforeInit: chart => {
            const fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
              fitValue.bind(chart.legend)();
              return this.height += 10;
            };
          }
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: 40,
          elements: {
            center: {
              text: this.totalInteractions ? this.totalInteractions.percentage : '',
              color: '#666666',
              minFontSize: 14,
              maxFontSize: 14
            }
          },
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      });
    }
    initChartTotalPost(canvas) {
      const that = this;
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: that.totalPosts?.data,
            backgroundColor: ['#FFBF00', '#ccc'],
            borderColor: ['#FFBF00', '#ccc'],
            borderWidth: 5
          }]
        },
        plugins: [{
          afterDraw: chart => {
            if (that.totalPosts?.data) {
              that.utils.addTextInsideChart(chart);
            }
          }
        }],
        options: {
          plugins: {
            tooltip: {
              enabled: false
            }
          },
          elements: {
            center: {
              text: that.totalPosts?.data ? that.totalPosts.percentage : '',
              color: '#666666',
              minFontSize: 14,
              maxFontSize: 14
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          cutout: 40
        }
      });
    }
    initChartTotalLikes(canvas) {
      const that = this;
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: this.totalLikes?.data,
            backgroundColor: ['#6C6CD9', '#ccc'],
            borderColor: ['#6C6CD9', '#ccc'],
            borderWidth: 5
          }]
        },
        plugins: [{
          afterDraw: chart => {
            if (this.totalLikes) {
              that.utils.addTextInsideChart(chart);
            }
          }
        }],
        options: {
          elements: {
            center: {
              text: that.totalLikes ? that.totalLikes.percentage : '',
              color: '#666666',
              minFontSize: 14,
              maxFontSize: 14
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          cutout: 40,
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      });
    }
    initChartTotalComments(canvas) {
      const that = this;
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: that.totalComments?.data,
            backgroundColor: ['#E786D7', '#ccc'],
            borderColor: ['#E786D7', '#ccc'],
            borderWidth: 5
          }]
        },
        plugins: [{
          afterDraw: chart => {
            if (that.totalComments) {
              that.utils.addTextInsideChart(chart);
            }
          }
        }],
        options: {
          elements: {
            center: {
              text: that.totalComments ? that.totalComments.percentage : '',
              color: '#666666',
              minFontSize: 14,
              maxFontSize: 14
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          cutout: 40,
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      });
    }
    initChartTotalShared(canvas) {
      const that = this;
      new _chartJs.default(canvas.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            data: that.totalShared?.data,
            backgroundColor: ['#36A2EB', '#ccc'],
            borderColor: ['#36A2EB', '#ccc'],
            borderWidth: 5
          }]
        },
        plugins: [{
          afterDraw: chart => {
            if (that.totalShared) {
              that.utils.addTextInsideChart(chart);
            }
          }
        }],
        options: {
          elements: {
            center: {
              text: that.totalShared ? that.totalShared.percentage : '',
              color: '#666666',
              minFontSize: 14,
              maxFontSize: 14
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          cutout: 40,
          plugins: {
            tooltip: {
              enabled: false
            }
          }
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "totalInteractionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "totalInteractions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "totalPosts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "totalLikes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "totalComments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "totalShared", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initChartTotal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initChartTotal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChartTotalPost", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initChartTotalPost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChartTotalLikes", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "initChartTotalLikes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChartTotalComments", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "initChartTotalComments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChartTotalShared", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "initChartTotalShared"), _class.prototype)), _class));
  _exports.default = TotalInteractionsComponent;
});