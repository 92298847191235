define("portal-talentrh/pods/company/album/index/new-album/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VaGpHDOU",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"@model\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\"],[true,[30,[36,0],[[32,0],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Novo Álbum\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Nome\"],[13],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-control\"],[24,\"autocomplete\",\"off\"]],[[\"@value\",\"@placeholder\"],[[32,3,[\"description\"]],\"Nome do álbum\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,1],[[32,0,[\"save\"]]],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/album/index/new-album/template.hbs"
    }
  });
  _exports.default = _default;
});