define("portal-talentrh/pods/components/post-new-survey/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'form',
    ajax: Ember.inject.service(),
    contactService: Ember.inject.service(),
    groupsService: Ember.inject.service('groups'),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    type: 'radio',
    showResult: false,
    postHaveImages: false,
    groupSelected: null,
    options: Ember.A(),
    postImages: Ember.A(),
    groupsOptions: Ember.computed('groupsService.groups', function () {
      const groups = [{
        id: 0,
        name: 'Todos'
      }];
      return [...groups, ...this.groupsService.groups];
    }),
    didInsertElement() {
      this.set('groupSelected', {
        id: 0,
        name: 'Todos'
      });
      if (this.options.length) {
        this.set('options', Ember.A());
      }
    },
    actions: {
      onGroupSelect(group) {
        this.set('groupSelected', group);
        this.set('groupId', group.id);
      },
      add() {
        this.options.pushObject({
          value: null
        });
      },
      remove(option) {
        this.options.removeObject(option);
      },
      type(type) {
        (0, _jquery.default)(`.btn-group-sm.type button`).toggleClass('active');
        this.set('type', type);
      },
      postAd() {
        var userId = this.session.user.id;
        var adTitle = this.adTitle;
        let groupId = this.groupId > 0 ? this.groupId : false;
        if (this.permission.accessProfile.publishOnlyInGroup && !groupId && !this.permission.admin) {
          return this.swal.warning('Publicação permitida somente em grupos, selecione o mesmo.');
        }
        if (!adTitle) {
          (0, _jquery.default)("#titleSurvey").focus();
          return this.swal.warning('Por favor, informe o título da enquete');
        }
        if (!this.get('options.length')) {
          return this.swal.warning('Por favor, informe ao menos uma alternativa');
        }
        let isEmpty = false;
        this.options.forEach(option => {
          if (!option.value) {
            isEmpty = true;
          }
        });
        if (isEmpty) {
          return this.swal.warning('Por favor, informe uma descrição para cada alternativa');
        }
        const endDay = (0, _jquery.default)("#validate-survey-day").val();
        if (!endDay) {
          return this.swal.warning('Por favor, informe uma data de duração da enquete');
        }
        const endHour = (0, _jquery.default)("#validate-survey-hour").val();
        if (!endHour) {
          return this.swal.warning('Por favor, informe uma hora de duração da enquete');
        }
        let body = {
          type: this.type,
          showResult: this.showResult,
          alternatives: this.options,
          endDay: endDay,
          endHour: endHour
        };
        var post = {
          type: 'survey',
          title: adTitle,
          body: JSON.stringify(body),
          user: userId
        };
        if (this.userGroup) {
          post.userGroup = this.get('userGroup.id');
        }
        if (this.groupId > 0) {
          post.userGroup = this.groupId;
        }
        this.swal.loading('Criando enquete');
        this.ajax.post('posts', {
          data: {
            post
          }
        }).then(response => {
          this.set('adTitle', '');
          this.set('options', Ember.A());
          this.set('type', 'radio');
          this.set('showResult', false);
          this.set('groupId', undefined);
          (0, _jquery.default)("#selec2-group").val('0');
          (0, _jquery.default)("#validate-survey-day").val('0');
          (0, _jquery.default)("#validate-survey-hour").val('1');
          var postImages = this.postImages;
          if (postImages.length) {
            this.swal.loading('Estamos carregando a sua imagem');
          }
          Ember.RSVP.map(postImages, image => {
            var form = new FormData();
            form.append('post', response.post.id);
            form.append('attachment', image.form);
            return this.ajax.request('postimages', {
              contentType: false,
              processData: false,
              type: 'POST',
              data: form
            });
          }).then(() => {
            postImages.clear();
            this.set('postHaveImages', false);
            this.swal.success('Enquete criada com sucesso');
            document.getElementById('post-message-tab').click();
            if (this.afterCreate) {
              this.afterCreate(response.post);
            }
          }).catch(err => {
            this.swal.fire('Opa! Não foi possível carregar uma das suas imagens.', err.errors[0].title, 'error');
          });
        }).catch(e => {
          this.swal.catch(e);
        });
      },
      postHaveImages() {
        if (this.postHaveImages) {
          this.set('postHaveImages', false);
        } else {
          this.set('postHaveImages', true);
        }
      }
    }
  });
  _exports.default = _default;
});