define("portal-talentrh/helpers/moment-custom-diff", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentCustomDiff = momentCustomDiff;
  function momentCustomDiff(params /*, hash*/) {
    const date = params[0];
    if ((0, _moment.default)().diff(date, 'day') < 7) {
      return (0, _moment.default)(date).from();
    }
    return (0, _moment.default)(date).format('DD/MM/YYYY');
  }
  var _default = Ember.Helper.helper(momentCustomDiff);
  _exports.default = _default;
});