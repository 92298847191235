define("portal-talentrh/models/pdi-evidence", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    description: (0, _model.attr)('string'),
    path: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    // BelongsTo
    createdBy: (0, _relationships.belongsTo)('user'),
    pdiAction: (0, _relationships.belongsTo)('pdi-action'),
    // hasMany
    pdiEvidenceComments: (0, _model.hasMany)('pdi-evidence-comment', {
      inverse: null
    })
  });
  _exports.default = _default;
});