define("portal-talentrh/pods/company/programs/viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "J/709Qbc",
    "block": "{\"symbols\":[\"Modal\",\"@model\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@size\",\"@onClose\"],[true,\"lg\",[30,[36,0],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-link text-body\"]],[[\"@route\",\"@model\"],[\"company.programs.attachments\",[32,2,[\"companyProgram\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fal fa-arrow-left fa-lg\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[1,[32,2,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"object\"],[15,\"data\",[32,2,[\"link\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"500\"],[15,\"title\",[32,2,[\"name\"]]],[14,4,\"application/pdf\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/programs/viewer/template.hbs"
    }
  });
  _exports.default = _default;
});