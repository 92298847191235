define("portal-talentrh/pods/components/indicator-loader/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorLoaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.alias('permission.admin'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class IndicatorLoaderComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "indicatorToSetFilter", _descriptor5, this);
      _initializerDefineProperty(this, "openIndicatorFilters", _descriptor6, this);
      _initializerDefineProperty(this, "indicatorsToLoad", _descriptor7, this);
      _initializerDefineProperty(this, "generalFilters", _descriptor8, this);
      _initializerDefineProperty(this, "modalTypeRH", _descriptor9, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor10, this);
    }
    // Carrega filtros do localStorage e iniciar o carregamento dos indicadores
    loadFilterDate() {
      let storageFilter = this.getStorageFilters() || {};
      let generalFilters = this.args.generalFilters;
      if ((storageFilter.startDate || !generalFilters.filters.startDate) && (storageFilter.endDate || !generalFilters.filters.endDate)) {
        let departments = storageFilter.department || [];
        let jobFunction = storageFilter.jobFunction || [];
        let jobRole = storageFilter.jobRole || [];
        let branch = storageFilter.branch || [];
        let appraisedBy = generalFilters.filters.appraisedBy ? 1 : 0;
        let filterByLedUsers = storageFilter.filterByLedUsers || [];
        const filterData = {
          startDate: storageFilter.startDate,
          endDate: storageFilter.endDate,
          department: departments,
          jobFunction: jobFunction,
          jobRole: jobRole,
          branch: branch,
          appraisedBy: appraisedBy,
          filterByLedUsers: filterByLedUsers
        };
        Ember.set(this.args.generalFilters, 'filterData', filterData);
        this.indicatorsToLoad.clear();
        this.loadIndicators();
      } else {
        this.openModalFilter(this.args.generalFilters);
      }
    }

    // Retorna os filtros do localStorage ou FALSE caso ainda não exista
    getStorageFilters() {
      let storageData = localStorage.getItem('indicatorFilters');
      try {
        return JSON.parse(storageData);
      } catch (e) {
        return false;
      }
    }

    // Método responsável por carregar todos os indicadores ou algum específico caso seja passado por parâmetro.
    loadIndicators(specificIndicator) {
      let indicatorsToLoad = [...this.indicatorsToLoad];
      let indicatorDefinitions = this.args.indicatorDefinitions || [];
      let generalFilters = this.args.generalFilters;
      indicatorDefinitions.forEach(indicatorDefinition => {
        if (specificIndicator && specificIndicator.name !== indicatorDefinition.name) {
          return;
        }
        if (indicatorDefinition.allowedForAdmin && !this.isAdmin) {
          return;
        }
        if (!specificIndicator) {
          if (indicatorDefinition.filterData && indicatorDefinition.filterData.ledType) {
            indicatorDefinition.filterData.ledType = generalFilters.filterData.ledType;
          }
        }
        let alreadyAdded = indicatorsToLoad.findBy('name', indicatorDefinition.name);
        if (!alreadyAdded) {
          indicatorsToLoad.pushObject({
            loaded: false,
            data: null,
            name: indicatorDefinition.name,
            template: indicatorDefinition.template,
            classNames: indicatorDefinition.classNames,
            filters: indicatorDefinition.filters,
            componentName: indicatorDefinition.componentName,
            filterData: indicatorDefinition.filterData,
            hideBtnFilter: indicatorDefinition.hideBtnFilter,
            host: indicatorDefinition.host
          });
        } else {
          Ember.set(alreadyAdded, 'loaded', false);
          Ember.set(alreadyAdded, 'data', null);
        }
      });
      const indicators = [];
      indicatorsToLoad.forEach(indicator => {
        if (specificIndicator && specificIndicator.name !== indicator.name) {
          return;
        }
        indicators.push({
          ...indicator
        });
      });
      this.indicatorsLoader.perform(indicators, specificIndicator);
    }
    buildIndicatorFilter(indicator) {
      let filtersName = ['startDate', 'endDate', 'branch', 'department', 'jobRole', 'jobFunction', 'appraisedBy', 'filterByLedUsers'];
      let generalFilters = this.args.generalFilters;
      if (!indicator.filters) {
        if (indicator.filterData.filterByLedUsers) {
          indicator.filters = {
            filterByLedUsers: 'd'
          };
        } else {
          return {};
        }
      }
      let filter = {};
      filtersName.forEach(f => {
        if (!indicator.filters[f]) {
          return;
        }
        if (f === 'branch' || f === 'department' || f === 'jobRole' || f === 'jobFunction') {
          let filterArray = indicator.filterData[f] || generalFilters.filterData[f] || [];
          if (filterArray.length) {
            filter[f] = filterArray.mapBy('id');
          }
        } else if (f === 'appraisedBy') {
          let value = indicator.filterData[f] || generalFilters.filterData[f];
          filter[f] = value ? 1 : 0;
        } else if (f === 'filterByLedUsers') {
          let value = indicator.filterData.ledType || generalFilters.filterData.ledType;
          filter[f] = value ? value : 'd';
        } else {
          let date = indicator.filterData[f] || generalFilters.filterData[f];
          if (date) {
            filter[f] = `${(0, _moment.default)(date).format("YYYY-MM-DD")} ${f === 'startDate' ? '00:00:00' : '23:59:59'}`;
          }
        }
      });
      return filter;
    }
    *indicatorsLoader(indicators, specificIndicator) {
      if (!specificIndicator) {
        this.indicatorsToLoad = [...indicators];
      }
      const indicatorsLoad = [];
      let host = this.args.host || 'conta';
      for (let i = 0; i < indicators.length; i++) {
        const indicator = {
          ...indicators[i]
        };
        host = indicator.host ? indicator.host : host;
        try {
          const query = this.buildIndicatorFilter(indicator);
          if (this.args.onlyLed) {
            query.onlyLed = true;
          }
          indicator.loaded = false;
          let indicatorData = yield this.fetch.request(`indicators/${indicator.name}`, {
            host,
            data: query
          });
          indicator.loaded = true;
          indicator.data = indicatorData;
        } catch (e) {
          Ember.debug(`Falha ao carrega o indicador ${indicator.name}`);
        }
        indicatorsLoad.push(indicator);
      }
      if (!specificIndicator) {
        this.indicatorsToLoad = [...indicatorsLoad];
      } else {
        // Caso for filtro específico, atualiza somente o filtro selecionado
        const indicatorsArray = [...this.indicatorsToLoad];
        const index = this.indicatorsToLoad.findIndex(item => specificIndicator.name === item.name);
        indicatorsArray[index] = indicatorsLoad[0];
        this.indicatorsToLoad = indicatorsArray;
      }
    }
    load(specificIndicator) {
      this.openIndicatorFilters = false;
      if (specificIndicator.isGeneralFilters) {
        this.loadIndicators();
      } else {
        this.loadIndicators(specificIndicator);
      }
    }
    openModalFilter(indicator) {
      this.indicatorToSetFilter = {
        ...indicator
      };
      this.openIndicatorFilters = true;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "indicatorToSetFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openIndicatorFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "indicatorsToLoad", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "generalFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modalTypeRH", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.modalType === 'rh' ? true : false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadFilterDate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadFilterDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "indicatorsLoader", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "indicatorsLoader"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModalFilter", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openModalFilter"), _class.prototype)), _class));
  _exports.default = IndicatorLoaderComponent;
});