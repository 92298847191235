define("portal-talentrh/pods/components/indicator-viewer/team-profile/salary-general-info/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    initChart(indicator) {
      let {
        salaryByFunction
      } = indicator.data;
      let backgroundColor = salaryByFunction.labels.map(() => {
        return this.randomColor(0.6);
      });
      let haveData = {
        labels: salaryByFunction.labels,
        datasets: [{
          data: salaryByFunction.data,
          backgroundColor
        }]
      };
      let noData = {
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#eee']
        }]
      };
      let data = salaryByFunction.labels.length ? haveData : noData;
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart-jobfunction-average').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'polarArea',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            legendCallback: function (chart) {
              // Cria uma lengenda customizada usando html
              let html = [];
              chart.data.labels.forEach((obj, index) => {
                let color = chart.data.datasets[0].backgroundColor[index];
                let labelName = chart.data.labels[index];
                let legendHtml = `
                <li class="py-1 text-truncate" title="${labelName}">
                  <span class="pr-1 mr-2 rounded" style="background: ${color}"></span> ${labelName}
                </li>
              `;
                html.push(legendHtml);
              });
              return html.join('');
            }
          }
        });
      }
      this.set('chart', chart);
    },
    initChart2(indicator) {
      let {
        salaryByDepartment
      } = indicator.data;
      let backgroundColor = salaryByDepartment.labels.map(() => {
        return this.randomColor(0.6);
      });
      let haveData = {
        labels: salaryByDepartment.labels,
        datasets: [{
          data: salaryByDepartment.data,
          backgroundColor
        }]
      };
      let noData = {
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#eee']
        }]
      };
      let data = salaryByDepartment.labels.length ? haveData : noData;
      let chart = this.chart2;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart-department-average').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'polarArea',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'right'
            },
            legendCallback: function (chart) {
              // Cria uma lengenda customizada usando html
              let html = [];
              chart.data.labels.forEach((obj, index) => {
                let color = chart.data.datasets[0].backgroundColor[index];
                let labelName = chart.data.labels[index];
                let legendHtml = `
                <li class="py-1 text-truncate" title="${labelName}">
                  <span class="pr-1 mr-2 rounded" style="background: ${color}"></span> ${labelName}
                </li>
              `;
                html.push(legendHtml);
              });
              return html.join('');
            }
          }
        });
      }
      this.set('chart2', chart);
    },
    randomColor() {
      let A = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.8;
      const R = Math.floor(Math.random() * 125);
      const G = Math.floor(Math.random() * 125);
      const B = Math.floor(Math.random() * 125);
      return `rgba(${R}, ${G}, ${B}, ${A})`;
    },
    actions: {
      generate() {
        let indicator = this.indicator;
        if (indicator && indicator.data) {
          this.initChart(indicator);
          this.initChart2(indicator);
        }
      }
    }
  });
  _exports.default = _default;
});