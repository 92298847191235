define("portal-talentrh/pods/components/skeleton/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1bZVPW8r",
    "block": "{\"symbols\":[\"Skeleton\",\"@ulClass\",\"@liClass\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[[16,0,[31,[\"list-group \",[32,2]]]]],[[\"@tagName\",\"@count\"],[\"ul\",3]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"li\"],[15,0,[31,[\"list-group-item \",[32,3]]]],[12],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[],[[],[]],null],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-3 w-75\"]],[[],[]],null],[2,\"\\n      \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-3 w-25\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/skeleton/list/template.hbs"
    }
  });
  _exports.default = _default;
});