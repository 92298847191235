define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-question/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Pg6xDo3c",
    "block": "{\"symbols\":[\"@answers\"],\"statements\":[[11,\"canvas\"],[24,0,\"mt-2-rem\"],[24,1,\"resultPerQuestionChart\"],[4,[38,1],[[30,[36,0],[[32,0,[\"init\"]]],null],[32,1]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"init\"]]],null],[32,1]],null],[12],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"did-update\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-question/template.hbs"
    }
  });
  _exports.default = _default;
});