define("portal-talentrh/pods/group/show/index/controller", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),
    fetch: Ember.inject.service(),
    // Default values
    isCreatingPost: false,
    type: 'all',
    branches: null,
    isLoadingMore: false,
    haveMorePosts: true,
    postTagFilter: null,
    // Single Line Computed Properties
    group: Ember.computed.alias('model.group'),
    posts: Ember.computed.alias('model.posts'),
    showNewsFeedForm: Ember.computed('group.{admins,postOnlyForAdmin}', 'permission.admin', 'session.user.id', function () {
      let admins = this.group.admins.mapBy('id');
      return this.permission.admin || !this.group.postOnlyForAdmin || (admins || []).includes(String(this.session.user.id));
    }),
    // Tasks
    loadPosts: (0, _emberConcurrency.task)(function* (type, tag, branches) {
      Ember.setProperties(this, {
        type,
        loading: true
      });
      let query = {
        archived: type === 'archived' ? 1 : 0,
        limit: 5,
        sort: 'priority Desc, createdAt DESC',
        userGroup: this.group.id
      };
      if (tag) {
        query.postTag = tag;
        Ember.set(this, 'postTagFilter', tag);
      } else if (type !== 'all' && type !== 'last' && type !== 'archived') {
        query.type = type;
      } else if (branches && branches.length) {
        query.branches = branches.map(branch => branch.id);
        Ember.set(this, 'branches', query.branches);
      }
      if (!tag) {
        this.set('postTagFilter', null);
      }
      if (!branches) {
        this.set('branches', null);
      }
      if (type === 'last') {
        query.sort = 'createdAt DESC';
      } else if (type === 'all' && !this.permission.companyConfig.showAdsOnFeed) {
        query.excludeType = ['ad'];
      }
      try {
        const newPosts = yield this.fetch.request('/feed/posts', {
          host: _environment.default.apiUrl.core,
          data: query
        });
        Ember.set(this, 'model.posts', newPosts);
        Ember.set(this, 'loading', false);
      } catch (e) {
        Ember.debug(e);
      }
    }).restartable(),
    // Actions
    actions: {
      afterCreatePoll(post) {
        this.store.pushPayload({
          post
        });
        post = this.store.peekRecord('post', post.id);
        this.posts.insertAt(0, post);
      },
      onTagChange(tag) {
        if (!tag) {
          return;
        }
        Ember.set(this, 'type', null);
        this.loadPosts.perform(null, tag);
        let postTagFilter = this.store.peekRecord('post-tag', tag);
        Ember.set(this, 'postTagFilter', postTagFilter);
      },
      onDeletePost(post) {
        this.posts.removeObject(post);
      }
    }
  });
  _exports.default = _default;
});