define("portal-talentrh/pods/company/decision/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    permission: Ember.inject.service('permission'),
    session: Ember.inject.service('session'),
    // Default values
    filter: '',
    openModal: null,
    // Single line computed properties
    allowCreateDecisionMaking: Ember.computed.alias('permission.accessProfile.allowCreateDecisionMaking'),
    // Multiline computed properties
    closedDecisions: Ember.computed('model.closedDecisions', 'filter', function () {
      if (!this.filter) {
        return this.model.closedDecisions;
      }
      let pattern = new RegExp(this.filter, 'i');
      return this.model.closedDecisions.filter(decision => {
        return (decision.title || '').match(pattern) || (decision.description || '').match(pattern);
      });
    }),
    openDecisions: Ember.computed('model.openDecisions', 'filter', function () {
      if (!this.filter) {
        return this.model.openDecisions;
      }
      let pattern = new RegExp(this.filter, 'i');
      return this.model.openDecisions.filter(decision => {
        return (decision.title || '').match(pattern) || (decision.description || '').match(pattern);
      });
    })
  });
  _exports.default = _default;
});