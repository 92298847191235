define("portal-talentrh/intro/feed/index/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    steps: [{
      element: '[data-step="feed-index-1"]',
      intro: `
        <h6 class="font-weight-bold text-primary">Olá, bem-vindo ao Nela Social!</h6>
        <div>O seu feed será apresentado em uma estrutura, pensada e desenvolvida, para proporcionar as melhores experiências no uso da ferramenta. Continue o tutorial para conhecer as funcionalidades!</div>
      `
    }, {
      element: '[data-step="feed-index-2"]',
      position: 'right',
      intro: `
        <h6 class="font-weight-bold text-primary">Grupos</h6>
        <div>Exibimos os seus grupos no feed, para que você possa acessá-los rapidamente.</div>
      `,
      disable(user) {
        return user.admin;
      }
    }, {
      element: '[data-step="feed-index-3"]',
      position: 'right',
      intro: `
        <h6 class="font-weight-bold text-primary">Menu</h6>
        <div>Aqui você terá acesso a todas as funcionalidades do Nela Social.</div>
      `,
      disable(user) {
        return user.admin;
      }
    }, {
      element: '[data-step="feed-index-4"]',
      position: 'right',
      intro: `
        <h6 class="font-weight-bold text-primary">Configurações</h6>
        <h6>Clicando aqui você será direcionado para o Assistente de Configurações Nela.</h6>
        <h6>
          Se você é novo por aqui, fique tranquilo!
          Vamos ajudá-lo nos primeiros passos para que possa aproveitar ao máximo a plataforma Nela.
        </h6>
        <h6 class="mb-0">
          Sempre que precisar fazer qualquer alteração, este menu te levará para as configurações.
        </h6>
      `
    }, {
      element: '[data-step="feed-index-5"]',
      position: 'bottom',
      intro: `
        <h6 class="font-weight-bold text-primary">Meu Nela</h6>
        <div>Reservamos um espaço especial, no Nela Social, para que você possa chamar de seu. Aqui você poderá ver sua agenda, seus links compartilhados, histórico de registro de humor e atividades em aberto.</div>
      `,
      disable(user) {
        return user.admin;
      }
    }, {
      element: '[data-step="feed-index-tips"]',
      position: 'bottom',
      intro: `
        <h6 class="font-weight-bold text-primary">Dicas rápidas</h6>
        <div>Ao clicar neste ícone, será exibido um vídeo com dicas rápidas e interessantes sobre as funcionalidades.</div>
      `
    }, {
      element: '[data-step="feed-index-welcome"]',
      position: 'bottom',
      intro: `
        <h6 class="font-weight-bold text-primary">Vídeo de boas-vindas</h6>
        <div>Assista nosso vídeo de boas-vindas e fique sabendo um pouco mais sobre o Nela. Após clicar no botão de fechar, o vídeo não será mais exibido em seu feed.</div>
      `
    }, {
      element: '[data-step="feed-btn"]',
      position: 'left',
      intro: `
        <h6 class="font-weight-bold text-primary">Acesso ao tutorial</h6>
        <div>Surgiu alguma dúvida? Você pode acessar o tutorial novamente clicando aqui.</div>
      `
    }]
  };
  _exports.default = _default;
});