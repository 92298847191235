define("portal-talentrh/pods/notification/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    controllerApplication: Ember.inject.controller('application'),
    reloadCount() {
      this.controllerApplication.setCountNotification();
    },
    // Actions
    actions: {
      reloadFilter() {
        Ember.set(this, 'filtersPrevious', {});
        Ember.set(this, 'filtersLast', {});
      },
      filter(type) {
        let filters = type ? {
          type
        } : {};
        Ember.set(this, 'filtersPrevious', filters);
        Ember.set(this, 'filtersLast', filters);
      },
      readAllNotifications() {
        io.socket.post('/notifications/read/all', {
          action: 'read'
        }, () => {
          this.reloadCount();
          this.send('reloadFilter');
        });
        //
      }
    }
  });
  _exports.default = _default;
});