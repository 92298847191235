define("portal-talentrh/helpers/remove-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.removeExtension = removeExtension;
  function removeExtension(params) {
    let str = params[0] || '';
    str = str.split('.');
    str.pop();
    return str;
  }
  var _default = Ember.Helper.helper(removeExtension);
  _exports.default = _default;
});