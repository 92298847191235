define("portal-talentrh/models/users-invited-to-appointment", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    acceptedAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    status: (0, _attr.default)('string'),
    // Associations
    appointment: (0, _relationships.belongsTo)('appointment'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});