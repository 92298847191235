define("portal-talentrh/pods/human-resources/chat-history/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['list-group-item'],
    // Multiline computed properties
    receivers: Ember.computed('message.receivers', function () {
      let str = 'destinatário';
      if (this.message.receivers > 1) {
        str = 'destinatários';
      }
      return `${this.message.receivers} ${str}`;
    })
  });
  _exports.default = _default;
});