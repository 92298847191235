define("portal-talentrh/pods/dashboard/layout/album/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FrBJ4MYg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dashboard-scene dashboard-scene-1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row board-height-large\"],[12],[2,\"\\n        \"],[8,\"dashboard/recent-users\",[],[[\"@class\"],[\"col-md-12 board-height-short\"]],null],[2,\"\\n        \"],[8,\"dashboard/announcements-block\",[],[[\"@class\"],[\"col-md-12 board-block-bot board-height-short\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"dashboard-scene dashboard-scene-2 invisible\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row board-height-large\"],[12],[2,\"\\n        \"],[8,\"dashboard/births-block\",[],[[\"@class\",\"@configuration\"],[\"col-md-12 board-height-short\",[34,0]]],null],[2,\"\\n        \"],[8,\"dashboard/admission-births\",[],[[\"@class\"],[\"col-md-12 board-block-bot board-height-short\"]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n    \"],[8,\"dashboard/album-block\",[],[[\"@class\"],[\"col-md-12 board-blocks-2\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"companyConfiguration\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/dashboard/layout/album/template.hbs"
    }
  });
  _exports.default = _default;
});