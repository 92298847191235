define("portal-talentrh/models/meeting-topic", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe um Título'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    conclusions: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    order: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    meetingMinute: (0, _model.belongsTo)('meeting-minute'),
    presenter: (0, _model.belongsTo)('user'),
    actionitems: (0, _model.hasMany)('action-item-meeting', {
      async: true
    })
  });
  _exports.default = _default;
});