define("portal-talentrh/pods/components/modal-dialogs/create-complaining/component", ["exports", "ember-modal-service/components/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modal.default.extend({
    toast: Ember.inject.service(),
    options: Ember.A([{
      label: 'Conteúdo é phishing e spam',
      value: 1
    }, {
      label: 'Conteúdo inadequado ou impróprio',
      value: 2
    }, {
      label: 'Conteúdo confidencial de terceiros',
      value: 3
    }, {
      label: 'Conteúdo que viola as diretrizes da empresa',
      value: 4
    }, {
      label: 'Conteúdo que viola os direitos autorais',
      value: 5
    }, {
      label: 'Outros',
      value: 6
    }]),
    submit: Ember.computed('selected.{message,option}', function () {
      if (this.selected.message && this.selected.option) {
        return 'resolve';
      }
      return 'emptySelection';
    }),
    init() {
      this._super(...arguments);
      this.selected = {
        message: '',
        option: null
      };
      Ember.set(this, 'selected.message', '');
      Ember.set(this, 'selected.option', null);
    },
    actions: {
      emptySelection(selected) {
        if (!(selected.option || selected.message)) {
          this.toast.warning('Você precisa escolher uma das opções e fornecer uma descrição para o seu reporte');
        } else if (!selected.option) {
          this.toast.warning('Você precisa escolher uma das opções para o seu reporte');
        } else {
          this.toast.warning('Você precisa fornecer uma descrição para o seu reporte');
        }
      }
    }
  });
  _exports.default = _default;
});