define("portal-talentrh/pods/habitus/details/feeling/negative-feelings-for-employee/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NegativeFeelingsForEmployeeComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class NegativeFeelingsForEmployeeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "openFilterModal", _descriptor, this);
      _initializerDefineProperty(this, "led", _descriptor2, this);
      _initializerDefineProperty(this, "badFeelings", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "showDateOnFilters", _descriptor5, this);
    }
    loadInsights(feelings, storageFilters) {
      if (storageFilters) {
        const filters = {
          ...this.filters,
          startDate: storageFilters.startDate,
          endDate: storageFilters.endDate,
          selectedFactor: storageFilters.factor
        };
        this.filters = filters;
        this.showDateOnFilters = true;
      }
      this.badFeelings = [];
      this.loadBadFeelings(feelings);
      this.openFilterModal = false;
    }
    start() {
      this.loadBadFeelings(this.args.feelings);
    }
    loadBadFeelings(feelings) {
      if (feelings && feelings.length) {
        this.led = feelings.firstObject.user;
        let badFeelings = [];
        feelings.forEach(feeling => {
          if (feeling.type === 'Cansado' || feeling.type === 'Triste' || feeling.type === 'Desanimado' || feeling.type === 'Irritado' || feeling.type === 'Ansioso' || feeling.type === 'Entediado') {
            badFeelings.push(feeling);
          }
        });
        this.badFeelings = badFeelings;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "led", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "badFeelings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        date: true,
        startDate: null,
        endDate: null,
        factor: true,
        selectedFactor: null
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showDateOnFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "start", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadBadFeelings", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "loadBadFeelings"), _class.prototype)), _class));
  _exports.default = NegativeFeelingsForEmployeeComponent;
});