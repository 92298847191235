define("portal-talentrh/pods/company/programs/modal-attachments/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ModalAttachmentsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed.sort('args.model.attachments', 'attachmentSorting'), _dec8 = Ember.computed('desc'), _dec9 = Ember.computed('sorting', 'desc'), _dec10 = Ember._action, (_class = class ModalAttachmentsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "fileDownload", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "desc", _descriptor5, this);
      _initializerDefineProperty(this, "sorting", _descriptor6, this);
      _initializerDefineProperty(this, "attachmentsSorted", _descriptor7, this);
      _defineProperty(this, "options", Ember.A([{
        value: 'name',
        label: 'Nome'
      }, {
        value: 'type',
        label: 'Tipo'
      }, {
        value: 'createdAt',
        label: 'Data'
      }]));
    }
    get sortIcon() {
      return this.desc ? 'fal fa-sort-amount-down' : 'fal fa-sort-amount-up';
    }
    get attachmentSorting() {
      let sorting = this.sorting;
      let desc = this.desc ? ':desc' : '';
      return [`${sorting.value}${desc}`];
    }
    *downloadAttachment(attachment) {
      try {
        const {
          isCompanyProgram
        } = this.args;
        const file = yield this.ajax.request(`S3/signedGetUrlDownload`, {
          data: {
            model: isCompanyProgram ? 'CompanyProgramAttachment' : 'CompanyProgramTopicAttachment',
            modelId: attachment.id
          }
        });
        this.fileDownload.downloadFile(file, attachment.name);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    closeModal() {
      const {
        model,
        isCompanyProgram
      } = this.args;
      const {
        companyProgram
      } = model;
      if (isCompanyProgram) {
        this.router.transitionTo('company.programs');
      } else {
        this.router.transitionTo('company.programs.topics', companyProgram.get('id'));
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fileDownload", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "desc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sorting", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        value: 'name',
        label: 'Nome'
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "attachmentsSorted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortIcon", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sortIcon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachmentSorting", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "attachmentSorting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadAttachment", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "downloadAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = ModalAttachmentsComponent;
});