define("portal-talentrh/pods/components/version/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I5jQU+2y",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,1],[[32,0,[\"load\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"version\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"card text-center card-version mb-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-header version-header\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"card-title\"],[12],[2,\"\\n          \"],[10,\"b\"],[12],[2,\"O Voors está com novidades!\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"card-text\"],[12],[2,\"\\n          \"],[10,\"b\"],[12],[2,\"\\n            \"],[10,\"u\"],[14,0,\"font-weight-bold cursor-pointer\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"click\"]]],null]],[12],[2,\"Clique aqui\"],[13],[2,\" e confira.\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/version/template.hbs"
    }
  });
  _exports.default = _default;
});