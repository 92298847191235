define("portal-talentrh/pods/application/navbar/notification/feedback-request/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    link: Ember.computed('notification', function () {
      let modelId = this.notification.modelId;
      return `feedback-request/${modelId}`;
    })
  });
  _exports.default = _default;
});