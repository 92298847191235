define("portal-talentrh/pods/components/insight-action-buttons/feeling/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let InsightActionButtonsFeelingComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.controller('habitus.index'), _dec6 = Ember._tracked, _dec7 = Ember.computed.alias('session.showUserPreviewInsight'), _dec8 = Ember.computed.alias('habitus.showInsightActionModal'), _dec9 = Ember.computed.alias('habitus.insightLedActionModal'), _dec10 = Ember.computed.alias('habitus.insightActionModal'), _dec11 = Ember.computed.alias('habitus.multipleUsersActionModal'), _dec12 = Ember.computed.alias('habitus.isDetails'), _dec13 = Ember._action, _dec14 = Ember._action, (_class = class InsightActionButtonsFeelingComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "habitus", _descriptor3, this);
      _initializerDefineProperty(this, "fetch", _descriptor4, this);
      _initializerDefineProperty(this, "habitusController", _descriptor5, this);
      _initializerDefineProperty(this, "openModalWarning", _descriptor6, this);
      _initializerDefineProperty(this, "showUserPreviewInsight", _descriptor7, this);
      _initializerDefineProperty(this, "showInsightActionModal", _descriptor8, this);
      _initializerDefineProperty(this, "insightLedActionModal", _descriptor9, this);
      _initializerDefineProperty(this, "insightActionModal", _descriptor10, this);
      _initializerDefineProperty(this, "multipleUsersActionModal", _descriptor11, this);
      _initializerDefineProperty(this, "isDetails", _descriptor12, this);
    }
    *checkForPreviousPendingInsightsSameType() {
      try {
        const {
          insight,
          insightLed
        } = this.args;
        const data = {
          type: insight.type,
          insightLedId: insightLed.id
        };
        const {
          hasPreviousPendingInsights,
          insightLeds
        } = yield this.fetch.request('insightLedAction/findPreviousPendingInsightsSameType', {
          data
        });
        if (!hasPreviousPendingInsights) {
          return this.openModalRegisterAction();
        }
        this.habitusController.insightLedsToConclude = insightLeds;
        this.openModalWarning = true;
      } catch (e) {
        this.swal.catch(e);
      }
    }
    openModalRegisterAction() {
      const {
        insight,
        insightLed
      } = this.args;
      this.openModalWarning = false;
      this.showInsightActionModal = true;
      this.showUserPreviewInsight = false;
      this.insightLedActionModal = insightLed;
      this.insightActionModal = insight;
      this.multipleUsersActionModal = false;
    }
    goToDetails() {
      this.isDetails = true;
      const {
        insightLed
      } = this.args;
      this.router.transitionTo('habitus.details.feeling', this.args.user.get('id'), {
        queryParams: {
          insightLed: insightLed.get('id')
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "habitusController", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openModalWarning", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showUserPreviewInsight", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showInsightActionModal", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "insightLedActionModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "insightActionModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "multipleUsersActionModal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isDetails", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkForPreviousPendingInsightsSameType", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "checkForPreviousPendingInsightsSameType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModalRegisterAction", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openModalRegisterAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToDetails", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "goToDetails"), _class.prototype)), _class));
  _exports.default = InsightActionButtonsFeelingComponent;
});