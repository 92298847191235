define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/evaluation-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u2wodPIw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"appraiser\",\"appraised\",\"team\",\"consensus\"],[[32,0,[\"appraiser\"]],[32,0,[\"appraised\"]],[32,0,[\"team\"]],[32,0,[\"consensus\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/evaluation-status/template.hbs"
    }
  });
  _exports.default = _default;
});