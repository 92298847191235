define("portal-talentrh/pods/components/team-hierarchy/user-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "h0kzfijL",
    "block": "{\"symbols\":[\"user\",\"Avatar\",\"Avatar\",\"@userId\",\"@notLinkTo\"],\"statements\":[[8,\"user-attribute\",[],[[\"@userId\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"user-preview\",[],[[\"@user\",\"@position\"],[[32,1],\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui-avatar\",[[16,0,[31,[\"d-block text-center m-2 \",[32,0,[\"userClass\"]]]]]],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3,[\"Img\"]],[[16,0,[31,[\"border-social border border-2 absence-badge-profile \",[32,0,[\"avatarClass\"]],\" \",[34,0]]]],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[31,[\"d-block text-center m-2 \",[32,0,[\"userClass\"]]]]],[16,\"title\",[32,1,[\"fullName\"]]]],[[\"@route\",\"@model\"],[\"profile.show\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui-avatar\",[],[[\"@user\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"Img\"]],[[16,0,[31,[\"border-social border border-2 absence-badge-profile \",[32,0,[\"avatarClass\"]],\" \",[34,0]]]],[16,\"alt\",[32,1,[\"fullName\"]]]],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"grayscaleClass\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/team-hierarchy/user-item/template.hbs"
    }
  });
  _exports.default = _default;
});