define("portal-talentrh/pods/feed/index/controller", ["exports", "ember-concurrency", "moment", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Default values
    selectedTag: null,
    favorite: false,
    isBirthdayMessageClosed: localStorage.getItem('isBirthdayMessageClosed') === 'true',
    isCreatingPost: false,
    showBasicEditor: true,
    showPriority: true,
    type: 'last',
    branches: null,
    archivedFilter: false,
    postTagFilter: null,
    isLoadingMore: false,
    haveMorePosts: true,
    // Single Line Computed Properties
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    isAdmin: Ember.computed.alias('permission.admin'),
    integration: Ember.computed.or('permission.companyConfig.communicationApi', 'permission.companyConfig.permitImports'),
    showMenuChatHistory: Ember.computed.or('permission.accessProfile.allowChatHistoryManagement', 'permission.admin'),
    showMenuIndicator: Ember.computed.or('accessProfile.allowIndicatorEngagementAllUsers', 'accessProfile.allowIndicatorOrganizationalClimateAllUsers', 'accessProfile.allowIndicatorPerformanceAllUsers', 'accessProfile.allowIndicatorCompanyProfile', 'isAdmin'),
    posts: Ember.computed.alias('model'),
    toAccessMyTeam: Ember.computed.alias('permission.toAccessMyTeam'),
    toAccessManagement: Ember.computed.or('permission.toAccessManagement', 'permission.accessProfile.allowIndicatorCompanyProfile', 'permission.accessProfile.allowIndicatorPerformanceAllUsers', 'permission.accessProfile.allowIndicatorOrganizationalClimateAllUsers', 'permission.accessProfile.allowIndicatorEngagementAllUsers'),
    showConfig: Ember.computed.or('permission.admin', 'permission.hr'),
    showDashboard: Ember.computed('permission.accessProfile.{allowManagementDashboardCustom,allowViewDashboardCustom}', 'permission.{admin,companyConfig.permitDashboard}', function () {
      return this.permission.companyConfig.permitDashboard && (this.permission.admin || this.permission.accessProfile.allowViewDashboardCustom || this.permission.accessProfile.allowManagementDashboardCustom);
    }),
    customRouteMyTeam: Ember.computed('accessProfile.{allowCompensatoryTimeMyTeam,allowConsultMyTeam,allowFeelingsTodayMyTeam,allowHistoricalDataMyTeam,allowIndicatorEngagementMyTeam,allowIndicatorOrganizationalClimateMyTeam,allowIndicatorPerformanceMyTeam,allowIndicatorTeamProfile,allowReceiptMyTeam,allowTimeCardMyTeam}', 'companyConfig.{communicationApi,permitImports,permitMessageRh,permitUserFeeling}', 'integration', 'isAdmin', function () {
      // Membros do Time
      if (this.isAdmin || this.accessProfile.allowConsultMyTeam) {
        return 'portal-manager.team-members';

        // Solicitações
      } else if (this.isAdmin || this.companyConfig.permitMessageRh) {
        return 'portal-manager.request.index';

        // Dados Históricos
      } else if (this.integration && this.accessProfile.allowHistoricalDataMyTeam || this.integration && this.isAdmin) {
        return 'portal-manager.historic';

        // Recibos de Pagamento
      } else if (this.integration && this.accessProfile.allowReceiptMyTeam || this.integration && this.isAdmin) {
        return this.companyConfig.permitImports ? 'portal-manager.import-receipt' : 'portal-manager.payment';

        // Cartão Ponto
      } else if (this.integration && this.accessProfile.allowTimeCardMyTeam || this.integration && this.isAdmin) {
        return this.companyConfig.permitImports ? 'portal-manager.import-time-card' : 'portal-manager.time-card';

        // Bancos de Horas
      } else if (this.companyConfig.communicationApi && this.accessProfile.allowCompensatoryTimeMyTeam || this.companyConfig.communicationApi && this.isAdmin) {
        return 'portal-manager.compensatory-time';

        // Sentimentos do Dia
      } else if (this.accessProfile.allowFeelingsTodayMyTeam && this.companyConfig.permitUserFeeling || this.isAdmin && this.companyConfig.permitUserFeeling) {
        return 'portal-manager.feeling';

        // Indicadores
      } else if (this.accessProfile.allowIndicatorTeamProfile || this.accessProfile.allowIndicatorPerformanceMyTeam || this.accessProfile.allowIndicatorOrganizationalClimateMyTeam || this.accessProfile.allowIndicatorEngagementMyTeam || this.isAdmin) {
        return 'portal-manager.people-analytics';
      } else {
        return 'errors.forbidden';
      }
    }),
    customRouteHumanResources: Ember.computed('accessProfile.{allowCompensatoryTimeManagement,allowFeelingsTodayManagement,allowHistoricalDataManagement,allowReceiptManagement,allowTimeCardManagement}', 'companyConfig.{communicationApi,permitImports,permitMessageRh,permitUserFeeling}', 'integration', 'isAdmin', 'permission.hr', 'showMenuChatHistory', 'showMenuIndicator', function () {
      // Solicitações
      if (this.companyConfig.permitMessageRh && (this.permission.hr || this.isAdmin)) {
        return 'human-resources.user-solicitations.index';

        // Dados Históricos
      } else if (this.integration && this.accessProfile.allowHistoricalDataManagement || this.integration && this.isAdmin) {
        return 'human-resources.historic';

        // Recibos de Pagamento
      } else if (this.integration && this.accessProfile.allowReceiptManagement || this.integration && this.isAdmin) {
        return this.companyConfig.permitImports ? 'human-resources.import-receipt' : 'human-resources.payment';

        // Bancos de Horas
      } else if (this.companyConfig.communicationApi && this.accessProfile.allowCompensatoryTimeManagement || this.companyConfig.communicationApi && this.isAdmin) {
        return 'portal-manager.compensatory-time';

        // Cartão Ponto
      } else if (this.integration && this.accessProfile.allowTimeCardManagement || this.integration && this.isAdmin) {
        return this.companyConfig.permitImports ? 'human-resources.import-time-card' : 'human-resources.time-card';

        // Sentimentos do Dia
      } else if (this.accessProfile.allowFeelingsTodayManagement && this.companyConfig.permitUserFeeling || this.isAdmin && this.companyConfig.permitUserFeeling) {
        return 'human-resources.feeling';

        // Histórico de Conversa
      } else if (this.showMenuChatHistory) {
        return 'human-resources.chat-history';

        // Indicadores
      } else if (this.showMenuIndicator) {
        return 'human-resources.people-analytics';
      } else {
        return 'errors.forbidden';
      }
    }),
    showNewsFeedForm: Ember.computed.or('permission.admin', 'accessProfile.postMessage', 'accessProfile.postAnnouncement', 'accessProfile.postEvent', 'accessProfile.postNews', 'accessProfile.postAd', 'accessProfile.postQuiz'),
    doReloadPosts: (0, _emberConcurrency.task)(function* (query, type) {
      this.set('loading', true);
      if (type === 'last') {
        query = Ember.assign(query, {
          limit: 5
        });
      } else {
        query = Ember.assign(query, {
          limit: 5,
          sort: 'priority DESC, createdAt DESC'
        });
      }
      query.archived = type === 'archived' ? 1 : 0;
      const newPosts = yield this.fetch.request('/feed/posts', {
        host: _environment.default.apiUrl.core,
        data: query
      });
      Ember.set(this, 'model', newPosts);
      Ember.set(this, 'loading', false);
    }),
    init() {
      this._super(...arguments);
    },
    // Actions
    actions: {
      loadPost(type, tag, branches) {
        Ember.setProperties(this, {
          favorite: false,
          type: type,
          archivedFilter: type === 'archived' ? 1 : 0
        });
        let query = {
          complained: false
        };
        if (tag) {
          query.postTag = tag;
          Ember.set(this, 'postTagFilter', tag);
        } else if (type !== 'all' && type !== 'last' && type !== 'archived') {
          query.type = type;
        } else if (branches && branches.length) {
          query.branches = branches.map(branch => branch.id);
          Ember.set(this, 'branches', query.branches);
        }
        if (!tag) {
          this.set('postTagFilter', null);
        }
        if (!branches) {
          this.set('branches', null);
        }
        if (type === 'last') {
          query.excludeType = ['ad'];
          query.sort = 'createdAt DESC';
        } else if (type === 'all' && !this.permission.companyConfig.showAdsOnFeed) {
          query.excludeType = ['ad'];
        }
        query.archived = type === 'archived' ? 1 : 0;
        this.doReloadPosts.perform(query, type);
      },
      onTagChange(tag) {
        if (!tag) {
          return;
        }
        const tagId = Ember.get(tag, 'id');
        Ember.set(this, 'type', null);
        this.send('loadPost', null, tagId);
        Ember.set(this, 'selectedTag', tag);
        Ember.set(this, 'postTagFilter', tag);
      },
      removeFromTimeline(post) {
        if (post.archived) {
          this.send('reloadWidgetNextEvent');
        }
        this.posts.removeObject(post);
      },
      afterCreatePoll(post) {
        this.store.pushPayload({
          post
        });
        post = this.store.peekRecord('post', post.id);
        this.posts.insertAt(0, post);
      },
      reloadWidgetNextEvent() {
        Ember.set(this, 'reloadNextEvent', (0, _moment.default)());
      },
      reloadPinPost() {
        this.doReloadPosts.perform({});
      }
    }
  });
  _exports.default = _default;
});