define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oqjSgkAT",
    "block": "{\"symbols\":[\"lines\",\"line\",\"quadrant\",\"@nineBoxCycle\",\"@cycles\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex justify-content-center nine-box mt-2\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n    \"],[8,\"view-team-member/performance-management/employee-evaluations/nine-box/arrow\",[],[[\"@upArrow\",\"@cycles\"],[true,[32,5]]],null],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"quadrantsByLine\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"d-flex\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[8,\"view-team-member/performance-management/employee-evaluations/nine-box/quadrant\",[],[[\"@quadrant\",\"@nineBoxCycle\",\"@cycles\"],[[32,3],[32,4],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"      \"],[8,\"view-team-member/performance-management/employee-evaluations/nine-box/arrow\",[],[[\"@upArrow\",\"@cycles\"],[false,[32,5]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"let\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/nine-box/template.hbs"
    }
  });
  _exports.default = _default;
});