define("portal-talentrh/pods/group/show/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    model() {
      let data = {
        archived: false,
        complained: false,
        limit: 5,
        priority: 0,
        sort: 'updatedAt DESC',
        userGroup: this.modelFor('group.show').id
      };
      if (!this.permission.companyConfig.showAdsOnFeed) {
        data.excludeType = ['ad'];
      }
      return Ember.RSVP.hash({
        group: this.modelFor('group.show'),
        posts: this.store.loadRecords('post', '/posts', {
          data,
          host: 'core'
        }).then(posts => posts.toArray())
      });
    }
  });
  _exports.default = _default;
});