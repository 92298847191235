define("portal-talentrh/pods/view-team-member/schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wCSQ5oek",
    "block": "{\"symbols\":[],\"statements\":[[8,\"feed/me/index/schedule\",[],[[\"@userLed\",\"@title\"],[[32,0,[\"model\"]],\"Agenda\"]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/schedule/template.hbs"
    }
  });
  _exports.default = _default;
});