define("portal-talentrh/pods/feed/index/my-groups/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Oculta este bloco quando estiver no celular
    classNames: ['d-none', 'd-sm-block'],
    classNameBindings: 'this.permission.companyConfig.permitGroups:d-block:d-none',
    // Servives
    groups: Ember.inject.service(),
    permission: Ember.inject.service(),
    // Computed
    myGroups: Ember.computed('groups.groups', 'showAll', function () {
      let groups = this.groups.groups || [];
      return this.showAll ? groups : groups.slice(0, 5);
    }),
    showButton: Ember.computed('groups.groups', function () {
      return (this.groups.groups || []).length > 5;
    })
  });
  _exports.default = _default;
});