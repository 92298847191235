define("portal-talentrh/pods/portal-manager/request/answered/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PortalManagerRequestAnsweredRoute extends Ember.Route {
    setupController(controller, model) {
      super.setupController(controller, model);
      let filters = this.controllerFor('portal-manager.request').get('filters');
      if (!filters.createdAt) {
        controller.getFilters();
      } else {
        controller.getFilters(filters);
      }
    }
    deactivate() {
      this.controller.inputFilter = null;
      this.controller.createdAt = null;
      this.controller.showModal = false;
    }
  }
  _exports.default = PortalManagerRequestAnsweredRoute;
});