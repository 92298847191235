define("portal-talentrh/pods/components/post-pin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qwmiFf0s",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"@openModal\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\"],[[32,3],[30,[36,0],[[32,0],\"closeModal\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Fixar post no topo\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Informe até que data deseja manter essa publicação fixada no topo\"],[13],[2,\"\\n      \"],[8,\"nela-datepicker\",[[24,\"autocomplete\",\"off\"]],[[\"@placeholder\",\"@selected\",\"@onSelect\"],[\"Selecionar data\",[32,0,[\"priorityUntil\"]],[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"priorityUntil\"]]],null]],[[\"value\"],[\"date\"]]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[[24,0,\"btn btn-outline-primary\"],[16,\"onclick\",[30,[36,0],[[32,0],\"closeModal\"],null]]],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,0],[[32,0],\"save\"],null]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"mut\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-pin/template.hbs"
    }
  });
  _exports.default = _default;
});