define("portal-talentrh/pods/portal-manager/request/create/controller", ["exports", "portal-talentrh/config/environment", "ember-concurrency", "moment"], function (_exports, _environment, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    router: Ember.inject.service(),
    file: null,
    solicitationOptionsSearchTerm: null,
    notificationInitial: null,
    notificationEnd: null,
    // Single line computed properties
    managerSolicitations: Ember.computed.alias('permission.companyConfig.managerSolicitationConfigs'),
    changingFunction: Ember.computed.equal('userSolicitation.solicitationType', 2),
    changingHorary: Ember.computed.equal('userSolicitation.solicitationType', 3),
    changingSalary: Ember.computed.equal('userSolicitation.solicitationType', 1),
    compensatoryTime: Ember.computed.equal('userSolicitation.solicitationType', 15),
    pointJustification: Ember.computed.equal('userSolicitation.solicitationType', 14),
    salary: Ember.computed.alias('loadSalary.lastSuccessful.value'),
    termination: Ember.computed.equal('userSolicitation.solicitationType', 7),
    userSolicitation: Ember.computed.alias('model'),
    vacancyPeriod: Ember.computed.equal('userSolicitation.solicitationType', 4),
    warning: Ember.computed.equal('userSolicitation.solicitationType', 5),
    motiveMessageIsNull: Ember.computed.equal('userSolicitation.notificationReason', undefined),
    descriptionMessageIsNull: Ember.computed.equal('userSolicitation.message', undefined),
    // Multiline computed properties
    compensatoryTypes: Ember.A([{
      label: 'Crédito',
      value: 'credit'
    }, {
      label: 'Débito',
      value: 'debit'
    }]),
    compensatoryTypeSelected: Ember.computed('compensatoryTypes', 'userSolicitation.compensatoryTimeType', function () {
      return this.compensatoryTypes.findBy('value', this.userSolicitation.compensatoryTimeType);
    }),
    solicitationTypes: Ember.A([{
      label: 'Advertência/Suspensão',
      value: 5,
      notUse: 'notUseWarning'
    }, {
      label: 'Alteração de função',
      value: 2,
      notUse: 'notUseChangeFunction'
    }, {
      label: 'Alteração de horário',
      value: 3,
      notUse: 'notUseChangeHorary'
    }, {
      label: 'Alteração de salário',
      value: 1,
      notUse: 'notUseChangeSalary'
    }, {
      label: 'Rescisão de colaborador',
      value: 7,
      notUse: 'notUseTerminationEmployed'
    }, {
      label: 'Justificativa de ponto',
      value: 14,
      notUse: 'notUseAbsenceJustification'
    }, {
      label: 'Período de férias',
      value: 4,
      notUse: 'notUseVacancyPeriod'
    }, {
      label: 'Benefícios',
      value: 9,
      notUse: 'notUseBenefitsRequest'
    }, {
      label: 'Banco de horas',
      value: 15,
      notUse: 'notUseTimeBankRequest'
    }, {
      label: 'Outros itens',
      value: 8,
      notUse: 'notUseOtherSolicitations'
    }]),
    solicitations: Ember.computed('managerSolicitations', 'solicitationTypes', 'solicitationOptionsSearchTerm', function () {
      if (!this.managerSolicitations) {
        return this.solicitationTypes;
      }
      let solicitations = this.solicitationTypes.filter(s => !Ember.get(this.managerSolicitations, s.notUse)).filter(solicitation => {
        if (!this.solicitationOptionsSearchTerm) {
          return true;
        }
        return solicitation.label.toLowerCase().includes(this.solicitationOptionsSearchTerm.toLowerCase());
      });
      let notFound = {
        label: 'Nenhum resultado encontrado'
      };
      if (!solicitations.length) {
        solicitations.pushObject(notFound);
        return solicitations;
      }
      return solicitations;
    }),
    terminationOptions: Ember.A([{
      label: 'Dispensa',
      value: 0
    }, {
      label: 'Pedido de demissão',
      value: 1
    }]),
    terminationSelected: Ember.computed('terminationOptions', 'userSolicitation.motive', function () {
      return this.terminationOptions.findBy('value', this.userSolicitation.motive);
    }),
    typeSelected: Ember.computed('solicitationTypes', 'userSolicitation.solicitationType', function () {
      return this.solicitationTypes.findBy('value', this.userSolicitation.solicitationType);
    }),
    vacancyPaidOptions: Ember.A([{
      label: 'Não Abonar',
      value: ''
    }, {
      label: 'Abonar no início',
      value: 'begin'
    }, {
      label: 'Abonar no fim',
      value: 'end'
    }]),
    vacationPaidType: Ember.computed('userSolicitation.vacationPaidType', 'vacancyPaidOptions', function () {
      return this.vacancyPaidOptions.findBy('value', this.userSolicitation.vacationPaidType || '');
    }),
    warningOptions: Ember.A([{
      label: 'Advertência',
      value: 'warning'
    }, {
      label: 'Suspensão',
      value: 'suspension'
    }, {
      label: 'Notificação (Aviso)',
      value: 'notification'
    }]),
    warningSelected: Ember.computed('userSolicitation.notificationType', 'warningOptions', function () {
      return this.warningOptions.findBy('value', this.userSolicitation.notificationType);
    }),
    disableIfFunction: Ember.computed('userSolicitation.solicitationType', function () {
      return this.userSolicitation.solicitationType !== 2;
    }),
    disableIfHorary: Ember.computed('userSolicitation.solicitationType', function () {
      return this.userSolicitation.solicitationType !== 3;
    }),
    disableIfSalary: Ember.computed('userSolicitation.solicitationType', function () {
      return this.userSolicitation.solicitationType !== 1;
    }),
    disableIfSuspension: Ember.computed('userSolicitation.{notificationType,solicitationType}', function () {
      const suspensionType = this.userSolicitation.solicitationType === 5 && this.userSolicitation.notificationType === 'suspension';
      return !suspensionType;
    }),
    disableIfVacation: Ember.computed('model.{id,requestedBy,solicitationType}', 'userSolicitation.{id,requestedBy,solicitationType}', function () {
      if (this.userSolicitation.solicitationType !== 4) {
        return true;
      } else if (this.userSolicitation.requestedBy === 'collaborator') {
        return Boolean(!this.userSolicitation.id);
      }
      return false;
    }),
    // Tasks
    loadSalary: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, 'userSolicitation.branch', null);
        let user = Ember.get(this, 'userSolicitation.user.id');
        let {
          userSalary
        } = yield this.ajax.request(`users/${user}/usersalary`);
        return userSalary || 0;
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      const url = `${_environment.default.apiUrl.social}/userSolicitationAttachments`;
      let now = (0, _moment.default)().startOf('day');
      if (this.userSolicitation.validations.isInvalid) {
        return this.swal.validation(this.userSolicitation.validations);
      }
      if (!this.disableIfFunction) {
        if (!this.userSolicitation.functionChangeDate) {
          return this.swal.warning('Selecione a data de alteração');
        }
        const isBefore = (0, _moment.default)(this.userSolicitation.functionChangeDate).isBefore(now);
        if (isBefore) {
          return this.swal.warning('A data de alteração não pode ser anterior a hoje');
        }
      }
      if (this.userSolicitation.compensatoryTimeType) {
        const isEndDateBeforeStartDate = (0, _moment.default)(this.userSolicitation.compensatoryTimeEnd).isBefore(this.userSolicitation.compensatoryTimeStart);
        if (isEndDateBeforeStartDate) {
          return this.swal.warning('A data inicial precisa ser igual ou anterior a data de término');
        }
      }
      if (!this.disableIfHorary) {
        if (!this.userSolicitation.horaryChangeDate) {
          return this.swal.warning('Selecione a data de alteração');
        }
        const isBefore = (0, _moment.default)(this.userSolicitation.horaryChangeDate).isBefore(now);
        if (isBefore) {
          return this.swal.warning('A data de alteração não pode ser anterior a hoje');
        }
      }
      if (!this.disableIfSalary) {
        if (!this.userSolicitation.readjustDate) {
          return this.swal.warning('Selecione a data de alteração');
        }
        const isBefore = (0, _moment.default)(this.userSolicitation.readjustDate).isBefore(now);
        if (isBefore) {
          return this.swal.warning('A data de alteração não pode ser anterior a hoje');
        }
      }
      if (!this.disableIfVacation) {
        if (!this.userSolicitation.vacationInitial) {
          return this.swal.warning('Selecione a data inicial');
        }
        const isBefore = (0, _moment.default)(this.userSolicitation.vacationInitial).isBefore(now);
        if (isBefore) {
          return this.swal.warning('A data inicial não pode ser anterior a hoje');
        }
      }
      if (!this.disableIfSuspension) {
        if (!this.userSolicitation.notificationInitial) {
          return this.swal.warning('Selecione a data inicial');
        }
        if ((0, _moment.default)(this.userSolicitation.notificationInitial).isBefore(Date.now())) {
          return this.swal.warning('A data inicial não pode ser anterior a hoje');
        }
        if (!this.userSolicitation.notificationEnd) {
          return this.swal.warning('Selecione a data final');
        }
        if ((0, _moment.default)(this.userSolicitation.notificationEnd).isBefore(this.userSolicitation.notificationInitial)) {
          return this.swal.warning('A data final não pode ser anterior a data inicial');
        }
      }
      try {
        let userSolicitation = this.userSolicitation.toJSON({
          includeId: true
        });
        let response = yield this.ajax.post('userSolicitations/manager', {
          contentType: 'application/json; charset=utf-8',
          data: {
            userSolicitation
          }
        });
        let attachment = this.file;
        if (attachment) {
          if (!['jpeg', 'png', 'jpg', 'pdf', 'tiff'].includes(attachment.extension)) {
            this.swal.warning('O arquivo deve ser uma imagem ou um PDF.');
            return;
          }
          this.swal.loading('Carregando arquivo');
          yield attachment.upload(url, {
            data: {
              solicitation: response.userSolicitation.id
            },
            fileKey: 'attachment',
            headers: {
              Authorization: `Bearer ${this.session.token}`
            },
            method: 'POST'
          });
          this.swal.close();
        }
        Ember.set(this, 'file', null);
        this.store.pushPayload(response);
        this.toast.success('Solicitação criada');
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    // Actions
    actions: {
      closeModal() {
        Ember.set(this, 'file', null);
        Ember.set(this, 'solicitationOptionsSearchTerm', null);
        this.router.transitionTo('portal-manager.request.hr');
      },
      compensatoryTypeSelect(selected) {
        Ember.set(this, 'userSolicitation.compensatoryTimeType', selected.value);
      },
      removeAttachment() {
        Ember.set(this, 'file', null);
      },
      selectFile(file) {
        Ember.set(this, 'file', file);
      },
      selectType(selected) {
        if (this.userSolicitation?.solicitationType == 4) {
          Ember.setProperties(this.userSolicitation, {
            vacationInitial: null,
            vacationDays: null,
            vacationDaysPaid: null,
            vacationPaydTipe: null
          });
        }
        if (this.userSolicitation?.solicitationType == 7) {
          Ember.setProperties(this.userSolicitation, {
            terminationDate: null,
            noticeDate: null
          });
        }
        if (this.userSolicitation?.solicitationType == 5) {
          Ember.setProperties(this.userSolicitation, {
            notificationEnd: null,
            notificationInitial: null,
            notificationType: null,
            notificationReason: null
          });
        }
        Ember.setProperties(this.userSolicitation, {
          solicitationType: selected.value,
          subject: selected.label
        });
      },
      searchSolicitationOptions(term) {
        Ember.set(this, 'solicitationOptionsSearchTerm', term);
      },
      setNotificationInitial(_ref) {
        let {
          moment
        } = _ref;
        Ember.set(this.userSolicitation, 'notificationInitial', moment.format('YYYY-MM-DD'));
      },
      setNotificationEnd(_ref2) {
        let {
          moment
        } = _ref2;
        Ember.set(this.userSolicitation, 'notificationEnd', moment.format('YYYY-MM-DD'));
      }
    }
  });
  _exports.default = _default;
  Ember.TextField.reopen({
    attributeBindings: ['data-inputmask-mask', 'data-inputmask-placeholder', 'data-inputmask-groupSeparator', 'data-inputmask-alias', 'data-inputmask-prefix', 'data-inputmask-digits', 'data-inputmask-radixPoint', 'data-inputmask-digitsOptional', 'data-inputmask-autoGroup']
  });
});