define("portal-talentrh/models/topic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    title: (0, _model.attr)('string'),
    // Associations
    category: (0, _model.belongsTo)('category'),
    company: (0, _model.belongsTo)('company'),
    user: (0, _model.belongsTo)('user'),
    comments: (0, _model.hasMany)('comment-forum')
  });
  _exports.default = _default;
});