define("portal-talentrh/pods/components/dashboard/admission-births/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    // Lifecycle hooks
    didInsertElement() {
      const showBirthdayCompanyMonth = this.permission.companyConfig.showBirthdayCompanyMonth;
      this.fetch.request(`/users/admissiontoday?byMonth=${showBirthdayCompanyMonth}`).then(admission => {
        if (this.isDestroyed) {
          return;
        }
        Ember.set(this, 'admissionBirths', admission);
      });
    }
  });
  _exports.default = _default;
});