define("portal-talentrh/pods/human-resources/user-solicitations/historic/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GP+VQCZN",
    "block": "{\"symbols\":[\"@solicitation\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"align-middle\"],[15,\"title\",[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[14,\"width\",\"150\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"requesterFullName\"]],[32,1,[\"userFullName\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[14,\"width\",\"150\"],[12],[2,\"\\n    \"],[1,[32,1,[\"userFullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[12],[2,\"\\n    \"],[1,[32,1,[\"subject\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-truncate max-w-200\"],[15,\"title\",[32,1,[\"response\"]]],[12],[2,\"\\n    \"],[1,[32,1,[\"statusLabel\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"td\"],[14,0,\"text-right align-middle\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-primary\"],[24,\"title\",\"Visualizar\"],[4,[38,2],[[32,0],[32,0,[\"showUserSolicitation\"]],[32,1]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"far fa-eye\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,0,[\"showModal\"]],[32,0,[\"userSolicitation\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"user-solicitations-approve-modal\",[],[[\"@show\",\"@solicitation\",\"@closeModal\"],[true,[32,1],[32,0,[\"onCloseModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"or\",\"action\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/human-resources/user-solicitations/historic/item/template.hbs"
    }
  });
  _exports.default = _default;
});