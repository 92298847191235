define("portal-talentrh/models/pdi-evidence-comment", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    createdBy: (0, _relationships.belongsTo)('user'),
    company: (0, _relationships.belongsTo)('company'),
    pdiEvidence: (0, _relationships.belongsTo)('pdi-evidence')
  });
  _exports.default = _default;
});