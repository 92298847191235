define("portal-talentrh/pods/components/indicator-viewer/team-profile/hires-per-year/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    initChart(indicator) {
      let data = {
        labels: indicator.data.chartData.labels,
        datasets: [{
          label: 'Contratações',
          backgroundColor: 'rgba(81, 150, 145, 0.8)',
          data: indicator.data.chartData.data
        }]
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('hires-per-year').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'line',
          responsive: true,
          maintainAspectRatio: false,
          data: data
        });
      }
      this.set('chart', chart);
    },
    actions: {
      generate() {
        let indicator = this.indicator;
        if (indicator && indicator.data) {
          this.initChart(indicator);
        }
      }
    }
  });
  _exports.default = _default;
});