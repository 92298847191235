define("portal-talentrh/pods/company/decision/show/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kiiOi6Fv",
    "block": "{\"symbols\":[\"Avatar\",\"@userVote\"],\"statements\":[[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-4 text-truncate\"],[12],[2,\"\\n    \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0 mr-2\"]],[[\"@user\"],[[32,2,[\"user\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,2,[\"user\",\"fullName\"]]]],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[1,[32,2,[\"user\",\"fullName\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-3 text-center\"],[12],[2,\"\\n    \"],[1,[32,0,[\"decisionVote\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n    \"],[1,[32,2,[\"justification\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/decision/show/item/template.hbs"
    }
  });
  _exports.default = _default;
});