define("portal-talentrh/pods/components/widgets/item/slide/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let WidgetItemSlideComponent = (_dec = Ember.computed.alias('args.itemsPerPage'), _dec2 = Ember.computed.alias('args.idElement'), _dec3 = Ember.computed.alias('args.hasMore'), _dec4 = Ember.computed.alias('args.hasPaging'), _dec5 = Ember.computed.alias('args.task'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('idElement'), (_class = class WidgetItemSlideComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "itemsPerPage", _descriptor, this);
      _initializerDefineProperty(this, "idElement", _descriptor2, this);
      _initializerDefineProperty(this, "hasMore", _descriptor3, this);
      _initializerDefineProperty(this, "hasPaging", _descriptor4, this);
      _initializerDefineProperty(this, "task", _descriptor5, this);
      _initializerDefineProperty(this, "currentPage", _descriptor6, this);
      _initializerDefineProperty(this, "isGoBack", _descriptor7, this);
    }
    get slickClass() {
      return `slick-element ${this.idElement} px-4 py-4 mx-3`;
    }
    slideToLastItem() {
      $(`.${this.idElement}`).slick('slickGoTo', this.itemsPerPage - 1, true);
    }
    *loadMore() {
      if (!this.hasMore) {
        return;
      }
      const newCurrentPage = this.currentPage + 1;
      this.currentPage = newCurrentPage;
      yield this.task.perform(newCurrentPage, this.itemsPerPage);
    }
    *loadLess() {
      if (this.isGoBack || this.currentPage === 0) {
        this.isGoBack = false;
        return;
      }
      const newCurrentPage = this.currentPage === 0 ? 0 : this.currentPage - 1;
      this.currentPage = newCurrentPage;
      yield this.task.perform(newCurrentPage, this.itemsPerPage);
      yield (0, _emberConcurrency.timeout)(100);
      this.isGoBack = true;
      this.slideToLastItem();
    }
    *onBeforeChange(slick, currentSlide, nextSlide) {
      if (!this.hasPaging) {
        return;
      }
      if (nextSlide === 0 && currentSlide === this.itemsPerPage - 1) {
        yield this.loadMore.perform();
      } else if (nextSlide === this.itemsPerPage - 1 && currentSlide === 0) {
        yield this.loadLess.perform(currentSlide, nextSlide);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "itemsPerPage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "idElement", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasMore", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasPaging", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "task", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isGoBack", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "slickClass", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "slickClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadLess", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadLess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBeforeChange", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onBeforeChange"), _class.prototype)), _class));
  _exports.default = WidgetItemSlideComponent;
});