define("portal-talentrh/pods/profile/show/team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OXuerbIo",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary btn-sm position-absolute r-2 shadow t-2\"],[24,\"title\",\"Ver organograma da empresa\"]],[[\"@route\"],[\"company.chart\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-sitemap\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"team-hierarchy\",[],[[\"@profileUserId\",\"@updateAt\"],[[32,0,[\"model\",\"id\"]],[32,0,[\"now\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/show/team/template.hbs"
    }
  });
  _exports.default = _default;
});