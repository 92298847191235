define("portal-talentrh/pods/components/input-summernote/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      var that = this;
      var element = '#' + this.elementId + '-summernote';
      (0, _jquery.default)(element).summernote({
        dialogsInBody: true,
        height: 150,
        disableDragAndDrop: true,
        toolbar: [
        // [groupName, [list of button]]
        // ['insert', ['picture']],
        ['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', ['link', 'video']]],
        callbacks: {
          onChange: function (contents /*, $editable*/) {
            that.set('body', contents);
          }
        }
      });
    }
  });
  _exports.default = _default;
});