define("portal-talentrh/pods/components/user/books-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uh9+N2WC",
    "block": "{\"symbols\":[\"record\",\"@records\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"list-group-item border my-1\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-book fa-lg text-primary\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"border-left pl-2 ml-3\"],[12],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"mb-1\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"book\",\"title\"]],[32,1,[\"otherTitle\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"small\"],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"Autor:\"],[13],[2,\" \"],[1,[30,[36,0],[[32,1,[\"book\",\"author\"]],\"Não informado\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-book mb-1\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0 text-center\"],[12],[2,\"Ainda não há informações\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user/books-list/template.hbs"
    }
  });
  _exports.default = _default;
});