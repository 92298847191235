define("portal-talentrh/pods/indicators/people-analytics/human-resources/company-engagement/ranking-interactions-detail/controller", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInteractionsDetailController = (_dec = Ember._tracked, (_class = class IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInteractionsDetailController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['branches', 'departments', 'jobRoles', 'jobFunctions', 'initialDate', 'endDate', 'interaction']);
      _defineProperty(this, "types", {
        likes: 'Curtidas',
        comments: 'Comentários',
        posts: 'Publicações'
      });
      _initializerDefineProperty(this, "filters", _descriptor, this);
    }
    get exportFields() {
      return [{
        field: 'ranking',
        fieldName: 'Ranking'
      }, {
        field: 'fullName',
        fieldName: 'Colaborador'
      }, {
        field: 'tradingName',
        fieldName: 'Filial'
      }, {
        field: 'total',
        fieldName: this.types[this.interaction]
      }];
    }
    get getType() {
      return this.types[this.interaction];
    }
    *buildFilters() {
      const filters = {};
      if (this.branches) {
        filters.branches = this.branches.split(',');
      }
      if (this.departments) {
        filters.departments = this.departments.split(',');
      }
      if (this.jobRoles) {
        filters.jobRoles = this.jobRoles.split(',');
      }
      if (this.jobFunctions) {
        filters.jobFunctions = this.jobFunctions.split(',');
      }
      if (this.initialDate) {
        filters.initialDate = this.initialDate;
      }
      if (this.endDate) {
        filters.endDate = this.endDate;
      }
      filters.interaction = this.interaction;
      this.filters = filters;
      yield filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesCompanyEngagementRankingInteractionsDetailController;
});