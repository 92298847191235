define("portal-talentrh/pods/components/skeleton/post/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XWJaw9mW",
    "block": "{\"symbols\":[\"Skeleton\",\"@count\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[],[[\"@count\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"card shadow-sm mb-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card-header bg-white d-flex align-items-center\"],[12],[2,\"\\n        \"],[8,[32,1,[\"Avatar\"]],[[24,0,\"avatar-5\"]],[[],[]],null],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"ml-3 w-100\"],[12],[2,\"\\n          \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-3 w-50\"]],[[],[]],null],[2,\"\\n          \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-3 w-25\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"card-body bg-white\"],[12],[2,\"\\n        \"],[8,[32,1,[\"Text\"]],[],[[\"@lines\"],[5]],null],[2,\"\\n        \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-75\"]],[[],[]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/skeleton/post/template.hbs"
    }
  });
  _exports.default = _default;
});