define("portal-talentrh/pods/components/dashboard/album-block/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Lifecycle hooks
    didInsertElement() {
      this.loadAlbums();
    },
    // Custom methods
    loadAlbums() {
      this.store.query('company-album', {
        where: JSON.stringify({
          showOnDashboard: 1
        }),
        sort: 'updatedAt ASC'
      }).then(data => {
        if (this.isDestroyed) {
          return;
        }
        Ember.set(this, 'albums', data);
      });
    },
    // Actions
    actions: {
      refreshDashboard() {
        this.loadAlbums();
      }
    }
  });
  _exports.default = _default;
});