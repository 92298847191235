define("portal-talentrh/pods/components/image-cropper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aEYJCzIm",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"img\"],[24,\"onerror\",\"this.style.display='none'\"],[24,\"alt\",\"Atualizar avatar\"],[17,1],[4,[38,0],[[32,0,[\"initCropper\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/image-cropper/template.hbs"
    }
  });
  _exports.default = _default;
});