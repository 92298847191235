define("portal-talentrh/pods/application/navbar/notification/project-created/component", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    linkTo: Ember.computed('notification', function () {
      return `${_environment.default.appUrl['projetos']}project/${Ember.get(this.notification, 'project')}`;
    }),
    actions: {
      redirect() {
        window.open(this.linkTo, '_blank');
      }
    }
  });
  _exports.default = _default;
});