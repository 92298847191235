define("portal-talentrh/pods/post/attachment/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    fileDownload: Ember.inject.service(),
    actions: {
      close() {
        window.history.back();
      }
    }
  });
  _exports.default = _default;
});