define("portal-talentrh/pods/errors/inactive-account/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    actions: {
      logout() {
        this.session.invalidate();
      }
    }
  });
  _exports.default = _default;
});