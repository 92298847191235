define("portal-talentrh/pods/habitus/insight-item/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const INSIGHT_TYPES = {
    birthday: 'Aniversariante do dia',
    admissionAnniversary: 'Aniversariante de empresa',
    accessFrequency: 'Frequência de acesso',
    feeling: 'Sentimento dos liderados',
    feedback: 'Feedback',
    post: 'Publicações',
    pdi: 'PDI'
  };
  const LED_MAX_ITENS_PER_ROW = 5;
  let InsightItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember.computed.alias('session.showUserPreviewInsight'), _dec9 = Ember.computed.alias('habitus.showInsightActionModal'), _dec10 = Ember.computed.alias('habitus.insightLedActionModal'), _dec11 = Ember.computed.alias('habitus.insightActionModal'), _dec12 = Ember.computed.alias('habitus.multipleUsersActionModal'), _dec13 = Ember.computed.alias('habitus.pendingInsights'), _dec14 = Ember.inject.controller('habitus.index'), _dec15 = Ember.computed('args.insight.createdAt'), _dec16 = Ember.computed('args.insight.createdAt'), _dec17 = Ember.computed('args.insight.type'), _dec18 = Ember.computed('args.insight.type', 'led.length'), _dec19 = Ember.computed('args.insight'), _dec20 = Ember.computed('args', 'habitus.habitusPreferences', 'led.length', 'permission.user.firstName', 'session.user.fullName'), _dec21 = Ember.computed('led.length', 'args.insight'), _dec22 = Ember.computed('args.insight.led.[isFulfilled]', 'habitusController.{insightLedsToConclude,hasInsightsToUpdate}'), _dec23 = Ember.computed('args.insight.led', 'indexLed', 'sortedLeds'), _dec24 = Ember._action, _dec25 = Ember._action, (_class = class InsightItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "habitus", _descriptor5, this);
      _initializerDefineProperty(this, "fetch", _descriptor6, this);
      _initializerDefineProperty(this, "indexLed", _descriptor7, this);
      _initializerDefineProperty(this, "showUserPreviewInsight", _descriptor8, this);
      _initializerDefineProperty(this, "showInsightActionModal", _descriptor9, this);
      _initializerDefineProperty(this, "insightLedActionModal", _descriptor10, this);
      _initializerDefineProperty(this, "insightActionModal", _descriptor11, this);
      _initializerDefineProperty(this, "multipleUsersActionModal", _descriptor12, this);
      _initializerDefineProperty(this, "pendingInsights", _descriptor13, this);
      _initializerDefineProperty(this, "habitusController", _descriptor14, this);
    }
    get isNew() {
      const {
        insight
      } = this.args;
      const createdAt = insight.get('createdAt');
      const today = (0, _moment.default)();
      return (0, _moment.default)(createdAt).isSame(today, 'day');
    }
    get pendingDays() {
      const {
        insight
      } = this.args;
      const createdAt = insight.get('createdAt');
      const today = (0, _moment.default)();
      const days = today.diff((0, _moment.default)(createdAt), 'days') + 1;
      return `${days} ${days > 1 ? 'dias' : 'dia'}`;
    }
    get insightTitle() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      const typeText = INSIGHT_TYPES[type];
      return typeText;
    }
    get sendMessage() {
      const {
        insight
      } = this.args;
      const type = insight.get('type');
      let sendMessage = false;
      if ((type === 'birthday' || type === 'admissionAnniversary') && this.led.length > 1) {
        sendMessage = true;
      }
      return sendMessage;
    }
    get insightInfoToConcluded() {
      const {
        insight
      } = this.args;
      const insightInfo = {
        birthday: '*Para que o insight seja concluído envie uma mensagem de parabéns.',
        admissionAnniversary: '*Para que o insight seja concluído envie uma mensagem de parabéns.',
        pdi: '*Para que o insight seja concluído registre um PDI para seu liderado no gestão de desempenho.',
        feedback: '*Para que o insight seja concluído registre um feedback para seu liderado no gestão de desempenho.',
        feeling: '*Para que o insight seja concluído registre uma ação, relatando o que foi conversado com o seu liderado.',
        post: '*Para que o insight seja concluído realize uma publicação ou compartilhe um assunto no feed.',
        accessFrequency: '*Para que o insight seja concluído registre uma ação, relatando o que foi conversado com o seu liderado.'
      };
      const type = insight.get('type');
      return insightInfo[type];
    }
    get insightDescription() {
      const {
        insight,
        habitusPhrasesBank,
        habitusPhraseUserIndexes
      } = this.args;
      const {
        daysFeedbackWarning
      } = this.habitus.habitusPreferences;
      const daysFeedbackWarningAux = insight.daysFeedbackWarning;
      const feelingFactor = insight.get('feelingFactor');
      const type = insight.get('type');
      const phraseUserIndex = habitusPhraseUserIndexes.find(phraseIndex => phraseIndex.type === type);
      let phrases = habitusPhrasesBank.filter(hpb => hpb.type === type);
      if (!phrases.length || !phraseUserIndex) {
        return '';
      }
      let description;
      if (this.led.length === 1) {
        let phrasesSingular;
        if (type === 'feeling' && ['internal', 'external'].includes(feelingFactor)) {
          if (feelingFactor === 'internal') {
            phrasesSingular = phrases.filter(phrase => phrase.singular && phrase.feelingFactor === 'internal');
          } else {
            phrasesSingular = phrases.filter(phrase => phrase.singular && phrase.feelingFactor === 'external');
          }
        } else {
          phrasesSingular = phrases.filter(phrase => phrase.singular);
        }
        description = phrasesSingular[this._randomIntFromInterval(0, phrasesSingular.length - 1)].phrase;
      } else {
        if (type !== 'post') {
          phrases = phrases.filter(hpb => !hpb.singular);
        }
        if (type === 'feeling' && ['internal', 'external'].includes(feelingFactor)) {
          if (feelingFactor === 'internal') {
            phrases = phrases.filter(phrase => phrase.feelingFactor === 'internal');
          } else {
            phrases = phrases.filter(phrase => phrase.feelingFactor === 'external');
          }
        }
        const index = phraseUserIndex.index > 0 && phraseUserIndex.index - 1 < phrases.length ? phraseUserIndex.index - 1 : 0;
        description = phrases[index].phrase;
      }
      description = description.replace('{userName}', this.permission.user.firstName);
      if (type === 'feedback') {
        description = description.replace('{daysFeedbackWarning}', daysFeedbackWarningAux ? daysFeedbackWarningAux : daysFeedbackWarning);
      } else if (type === 'accessFrequency') {
        const accessFrequencyDate = insight.led.firstObject.get('accessFrequencyDate');
        const accessFrequencyDateStr = (0, _moment.default)(accessFrequencyDate).format("DD/MM/YYYY");
        description = description.replace('{accessFrequencyDate}', accessFrequencyDateStr);
      } else if (type === 'feeling') {
        const feelingDate = insight.led.firstObject.get('feelingDate');
        const feelingDateStr = (0, _moment.default)(feelingDate).format("DD/MM/YYYY");
        description = description.replace('{feelingDate}', feelingDateStr);
      }
      return description;
    }
    get showMoreIsNeeded() {
      const {
        insight
      } = this.args;
      const ledArr = [...insight.led.toArray()];
      return this.led.length < ledArr.length;
    }
    get sortedLeds() {
      const {
        insight
      } = this.args;
      if (this.habitusController.hasInsightsToUpdate) {
        const insightLedsToConclude = this.habitusController.insightLedsToConclude;
        insight.led.forEach(insightLed => {
          if (insightLedsToConclude.includes(Number(insightLed.id))) {
            insightLed.status = 'concluded';
            insightLed.save();
          }
        });
      }
      const ledArr = [...insight.led.toArray()];
      return ledArr.sort((a, b) => {
        if (!a.led.get('fullName')) return -1;
        return a.led.get('fullName').localeCompare(b.led.get('fullName'));
      });
    }
    get led() {
      const ledArr = this.sortedLeds;
      if (!this.indexLed === LED_MAX_ITENS_PER_ROW) {
        return ledArr.slice(0, LED_MAX_ITENS_PER_ROW);
      }
      return ledArr.slice(0, this.indexLed);
    }
    openModalRegisterAction() {
      const {
        insight
      } = this.args;
      this.showInsightActionModal = true;
      this.showUserPreviewInsight = false;
      this.insightLedActionModal = [];
      insight.led.forEach(element => {
        if (element.get('status') === 'pending') {
          this.insightLedActionModal.push(element);
        }
      });
      if (this.insightLedActionModal.length > 1) {
        this.multipleUsersActionModal = true;
      } else {
        this.multipleUsersActionModal = false;
        this.insightLedActionModal = this.insightLedActionModal[0];
      }
      this.insightActionModal = insight;
    }
    showMore() {
      this.indexLed += LED_MAX_ITENS_PER_ROW;
    }
    _randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "habitus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "indexLed", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return LED_MAX_ITENS_PER_ROW;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showUserPreviewInsight", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showInsightActionModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "insightLedActionModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "insightActionModal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "multipleUsersActionModal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "pendingInsights", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "habitusController", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "isNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pendingDays", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "pendingDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insightTitle", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "insightTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendMessage", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "sendMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insightInfoToConcluded", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "insightInfoToConcluded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insightDescription", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "insightDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showMoreIsNeeded", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "showMoreIsNeeded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedLeds", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "sortedLeds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "led", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "led"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModalRegisterAction", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "openModalRegisterAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showMore", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "showMore"), _class.prototype)), _class));
  _exports.default = InsightItemComponent;
});