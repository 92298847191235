define("portal-talentrh/pods/components/social-page/menu/list/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SocialPageMenuListItemComponent extends _component.default {
    get showIf() {
      let {
        showIf
      } = this.args;
      if (showIf === undefined) {
        return true;
      }
      return showIf;
    }
  }
  _exports.default = SocialPageMenuListItemComponent;
});