define("portal-talentrh/pods/view-team-member/performance-management/feedback/controller", ["exports", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberPerformanceManagementFeedbackController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.alias('model'), _dec10 = Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'), _dec11 = Ember.computed('userLed.id', 'newPerformanceModule'), _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ViewTeamMemberPerformanceManagementFeedbackController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "statusFilter", _descriptor5, this);
      _initializerDefineProperty(this, "countFeedbackPositive", _descriptor6, this);
      _initializerDefineProperty(this, "countFeedbackCorrective", _descriptor7, this);
      _initializerDefineProperty(this, "totalFeedback", _descriptor8, this);
      _initializerDefineProperty(this, "userLed", _descriptor9, this);
      _initializerDefineProperty(this, "newPerformanceModule", _descriptor10, this);
    }
    get urlCreateFeedback() {
      if (this.newPerformanceModule) {
        return `${_environment.default.appUrl.performance}feedbacks/my-team/create`;
      }
      return `${_environment.default.appUrl.avd}feedback/new/?user=${this.userLed.id}`;
    }
    *countFeedback() {
      try {
        const {
          feedbackCorrective,
          feedbackPositive,
          totalFeedback
        } = yield this.fetch.request(`/feedbacks/${this.userLed.id}/countFeedback`);
        this.countFeedbackPositive = feedbackPositive ?? 0;
        this.countFeedbackCorrective = feedbackCorrective ?? 0;
        this.totalFeedback = totalFeedback ?? 0;
        const filter = {
          type: ['0', '1'],
          user: this.userLed.id
        };
        this.filters = filter;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    onChangeFilterStatus(status) {
      this.statusFilter = status;
      const filters = this.filters;
      if (status === 'corrective') {
        filters.type = '0';
      } else if (status === 'positive') {
        filters.type = '1';
      } else {
        filters.type = ['0', '1'];
      }
      this.filters = filters;
    }
    resetFilters() {
      this.statusFilter = 'all';
      this.filters = {
        type: ['0', '1'],
        user: this.userLed.id
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        type: ['0', '1'],
        user: this.userLed.id
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "statusFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "countFeedbackPositive", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "countFeedbackCorrective", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "totalFeedback", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userLed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newPerformanceModule", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "urlCreateFeedback", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "urlCreateFeedback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countFeedback", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "countFeedback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeFilterStatus", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeFilterStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetFilters", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "resetFilters"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberPerformanceManagementFeedbackController;
});