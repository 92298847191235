define("portal-talentrh/pods/portal-manager/indicators/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    router: Ember.inject.service(),
    permission: Ember.inject.service(),
    allowIndicatorOrganizationalClimateAllUsers: Ember.computed.alias('permission.accessProfile.allowIndicatorOrganizationalClimateAllUsers'),
    allowIndicatorOrganizationalClimateMyTeam: Ember.computed.alias('permission.accessProfile.allowIndicatorOrganizationalClimateMyTeam'),
    allowIndicatorPerformanceAllUsers: Ember.computed.alias('permission.accessProfile.allowIndicatorPerformanceAllUsers'),
    allowIndicatorEngagementAllUsers: Ember.computed.alias('permission.accessProfile.allowIndicatorEngagementAllUsers'),
    allowIndicatorPerformanceMyTeam: Ember.computed.alias('permission.accessProfile.allowIndicatorPerformanceMyTeam'),
    allowIndicatorEngagementMyTeam: Ember.computed.alias('permission.accessProfile.allowIndicatorEngagementMyTeam'),
    allowIndicatorTeamProfile: Ember.computed.alias('permission.accessProfile.allowIndicatorTeamProfile'),
    allowConsultMyTeam: Ember.computed.alias('permission.accessProfile.allowConsultMyTeam'),
    admin: Ember.computed.alias('permission.accessProfile.admin'),
    isLeader: Ember.computed.alias('permission.user.isLeader'),
    beforeModel() {
      const allowed = this.allowConsultMyTeam || this.allowIndicatorTeamProfile || this.allowIndicatorEngagementMyTeam || this.allowIndicatorPerformanceMyTeam || this.allowIndicatorEngagementAllUsers || this.allowIndicatorPerformanceAllUsers || this.allowIndicatorOrganizationalClimateMyTeam || this.allowIndicatorOrganizationalClimateAllUsers || this.admin;
      if (!allowed) {
        this.router.transitionTo('errors.forbidden');
      }
    }
  });
  _exports.default = _default;
});