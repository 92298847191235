define("portal-talentrh/pods/components/time-card-report/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TimeCardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed.alias('permission.companyConfig.showJobFunction'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('args.fromMyself', 'args.timeCard.days', 'timeCard'), _dec7 = Ember.computed('args.timeCard.days', 'args.timeCard.totals', 'session.company.id'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class TimeCardComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor3, this);
      _initializerDefineProperty(this, "showOccurrencesModal", _descriptor4, this);
      _initializerDefineProperty(this, "occurrences", _descriptor5, this);
    }
    tableFixHead(e) {
      const el = e.target,
        sT = el.scrollTop;
      el.querySelectorAll('thead th').forEach(th => th.style.transform = `translateY(${sT}px)`);
    }
    get showLegends() {
      const days = this.args.timeCard.days;
      const fromMyself = this.args.fromMyself;
      let show = false;
      if (!fromMyself) {
        for (const day of days) {
          if (show) {
            break;
          }
          for (const order of day.order) {
            if (typeof order === 'object') {
              show = true;
              break;
            }
          }
        }
      }
      return show;
    }
    get aditionalColumns() {
      const arr = [];
      const days = this.args.timeCard.days;
      const totals = this.args.timeCard.totals;
      const company = this.session.company.id;
      days.forEach(day => {
        const dailyTotals = day.dailyTotal;
        if (!dailyTotals.length) {
          return arr;
        }
        if (company === 461) {
          dailyTotals.forEach(daily => {
            if (!arr.includes(daily.title)) {
              arr.push(daily.title);
            }
          });
        } else {
          totals.forEach(total => {
            if (!arr.includes(total.title)) {
              arr.push(total.title);
            }
          });
        }
      });
      return arr;
    }
    didInsert() {
      document.querySelectorAll('.tableFixHead').forEach(el => el.addEventListener('scroll', this.tableFixHead));
    }
    downloadPdf() {
      const {
        user,
        downloadPdf
      } = this.args;
      if (downloadPdf) {
        downloadPdf(user);
      }
    }
    showOccurrences(day) {
      this.showOccurrencesModal = true;
      this.setOccurrences(day);
    }
    setOccurrences(day) {
      const {
        occurrences,
        order
      } = day;
      const adaptedOccurrences = occurrences.map(occurrence => {
        let occurrenceOrder;
        if (occurrence.order) {
          occurrenceOrder = order[occurrence.order];
          if (typeof occurrenceOrder === 'object') {
            occurrenceOrder = occurrenceOrder.time;
          }
        }
        return {
          date: occurrenceOrder,
          description: occurrence.occurrence
        };
      });
      this.occurrences = adaptedOccurrences;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showOccurrencesModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "occurrences", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showLegends", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showLegends"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "aditionalColumns", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "aditionalColumns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadPdf", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "downloadPdf"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showOccurrences", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "showOccurrences"), _class.prototype)), _class));
  var _default = TimeCardComponent;
  _exports.default = _default;
});