define("portal-talentrh/pods/profile/edit/professional-interest/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileEditProfessionalInterestEditRoute extends Ember.Route {
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = ProfileEditProfessionalInterestEditRoute;
});