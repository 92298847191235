define("portal-talentrh/utils/remove-accents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * Remove todos os acentos de um texto
   */
  function _default(txt) {
    txt = txt || '';
    return txt.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }
});