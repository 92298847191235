define("portal-talentrh/utils/swal-error", ["exports", "sweet-alert2"], function (_exports, _sweetAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(response) {
    if (response.errors && response.errors.length) {
      var messages = '';
      response.errors.forEach(function (error) {
        if (error.detail) {
          messages += error.detail + '\n';
        } else {
          messages += error.title + '\n';
        }
      });
      _sweetAlert.default.fire('Ops!', messages, 'error');
    } else {
      _sweetAlert.default.fire('Ops!', response.payload || 'Erro desconhecido', 'error');
    }
  }
});