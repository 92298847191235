define("portal-talentrh/pods/habitus/details/feeling/amount-of-feelings-by-type/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AmountOfFeelingsByTypeComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class AmountOfFeelingsByTypeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "chart", _descriptor2, this);
      _initializerDefineProperty(this, "labels", _descriptor3, this);
      _initializerDefineProperty(this, "data", _descriptor4, this);
      _initializerDefineProperty(this, "openFilterModal", _descriptor5, this);
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      _initializerDefineProperty(this, "showDateOnFilters", _descriptor7, this);
    }
    loadInsights(feelings, storageFilters) {
      if (storageFilters) {
        const filters = {
          ...this.filters,
          startDate: storageFilters.startDate,
          endDate: storageFilters.endDate
        };
        this.filters = filters;
        this.showDateOnFilters = true;
      }
      this.loadDataChart(feelings);
      this.feelings = feelings;
      this.openFilterModal = false;
    }
    init() {
      const {
        feelings
      } = this.args;
      if (feelings && feelings.length) {
        this.feelings = feelings;
        this.led = feelings.firstObject.user;
        this.loadDataChart(feelings);
      }
    }
    loadDataChart(feelings) {
      this.labels = [];
      this.data = [];
      let dataChart = this.dataChart(feelings);
      if (dataChart && dataChart.length) {
        dataChart.forEach(data => {
          const userLed = this.store.peekRecord('user', data.user);
          const textFemale = {
            Feliz: 'Feliz',
            Apaixonado: 'Apaixonada',
            Irritado: 'Irritada',
            Cansado: 'Cansada',
            Inteligente: 'Inteligente',
            Entediado: 'Entediada',
            Ansioso: 'Ansiosa',
            Legal: 'Legal',
            Desanimado: 'Desanimada',
            Triste: 'Triste',
            Divertido: 'Divertida',
            Entusiasmado: 'Entusiasmada'
          };
          const textMale = {
            Feliz: 'Feliz',
            Apaixonado: 'Apaixonado',
            Irritado: 'Irritado',
            Cansado: 'Cansado',
            Inteligente: 'Inteligente',
            Entediado: 'Entediado',
            Ansioso: 'Ansioso',
            Legal: 'Legal',
            Desanimado: 'Desanimado',
            Triste: 'Triste',
            Divertido: 'Divertido',
            Entusiasmado: 'Entusiasmado'
          };
          data.label = userLed.gender === 'F' ? textFemale[data.label] : textMale[data.label];
          this.labels.push(data.label);
          this.data.push(data.data);
        });
      } else {
        this.chart = null;
        this.initChart();
      }
      if (this.labels.length && this.data.length) {
        this.initChart();
      }
    }
    dataChart(feelings) {
      let data = [];
      feelings.forEach(feeling => {
        if (data && data.length) {
          let i = data.findIndex(x => x.label === feeling.type);
          if (i >= 0) {
            data[i].data = data[i].data + 1;
          } else {
            data.push({
              label: feeling.type,
              data: 1,
              user: feeling.user
            });
          }
        } else {
          data.push({
            label: feeling.type,
            data: 1,
            user: feeling.user
          });
        }
      });
      data = data.sort((a, b) => b.data - a.data);
      return data;
    }
    initChart() {
      let backgroundColor = [];
      this.feelings.forEach(() => {
        backgroundColor.push('rgba(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + 0.5 + ')');
      });
      let data = {
        labels: this.labels,
        min: 0,
        datasets: [{
          backgroundColor: backgroundColor,
          data: this.data
        }]
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('amount-of-feelings-by-type').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          maintainAspectRatio: false,
          data: data,
          options: {
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                }
              }
            },
            scales: {
              yAxes: [{
                display: true,
                stacked: true,
                ticks: {
                  stepSize: 1,
                  min: 0,
                  // minimum value
                  max: this.feelings.length // maximum value
                }
              }]
            }
          }
        });
      }

      this.chart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "labels", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        date: true
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showDateOnFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "init", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = AmountOfFeelingsByTypeComponent;
});