define("portal-talentrh/pods/feed/me/schedule/calendar/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let CalendarComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('appointments.[]'), (_class = class CalendarComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "appointments", _descriptor4, this);
      _initializerDefineProperty(this, "center", _descriptor5, this);
    }
    get collection() {
      return this.appointments;
    }
    *loadAppointments(date) {
      try {
        let dates = yield this.fetch.request('appointments/existingDates', {
          host: _environment.default.apiUrl.agenda,
          data: {
            startDate: date.startOf('month').format('YYYY-MM-DD'),
            endDate: date.endOf('month').format('YYYY-MM-DD HH:mm:ss')
          }
        });
        dates = dates.map(o => (0, _moment.default)(o));
        this.appointments.addObjects(dates);
      } catch (e) {
        Ember.debug(`<Feed::Me::Schedule::Calendar> :: loadAppointments :: ${e}`);
      }
    }
    *loadGoogleAppointments(date) {
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        if (!user.googleOAuth) {
          return;
        }
        let dates = yield this.fetch.request('appointments/google', {
          host: _environment.default.apiUrl.agenda,
          data: {
            start: date.startOf('month').toISOString(),
            end: date.endOf('month').toISOString()
          }
        });
        dates = dates.map(o => (0, _moment.default)(o.start));
        this.appointments.addObjects(dates);
      } catch (e) {
        Ember.debug(`<Feed::Me::Schedule::Calendar> :: loadGoogleAppointments :: ${e}`);
      }
    }
    *loadOutlookAppointments(date) {
      try {
        let user = this.store.peekRecord('user', this.session.user.id);
        if (!user) {
          user = yield this.store.findRecord('user', this.session.user.id);
        }
        if (!user.outlookOAuth) {
          return;
        }
        let {
          appointments
        } = yield this.fetch.request('appointments/outlook', {
          host: _environment.default.apiUrl.agenda,
          data: {
            start: date.startOf('month').toISOString(),
            end: date.endOf('month').toISOString()
          }
        });
        appointments = appointments.map(o => (0, _moment.default)(o.start));
        this.appointments.addObjects(appointments);
      } catch (e) {
        Ember.debug(`<Feed::Me::Schedule::Calendar> :: loadOutlookAppointments :: ${e}`);
      }
    }
    selectCalendar(selected, calendar, e) {
      let {
        onChangeMonth
      } = this.args;
      let date = (0, _moment.default)(e.target.dataset.date);
      onChangeMonth(date);
    }
    loadCalendar() {
      let date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _moment.default)();
      let el = arguments.length > 1 ? arguments[1] : undefined;
      date = Array.isArray(el) && !el.length ? (0, _moment.default)() : date;
      this.loadAppointments.perform(date);
      this.loadGoogleAppointments.perform(date);
      this.loadOutlookAppointments.perform(date);
    }
    centerChange(_ref) {
      let {
        date
      } = _ref;
      this.center = date;
      this.loadCalendar((0, _moment.default)(date));
    }
    selectToday() {
      let {
        onChangeMonth
      } = this.args;
      onChangeMonth((0, _moment.default)());
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appointments", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "center", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "collection", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "collection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAppointments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadGoogleAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadGoogleAppointments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadOutlookAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadOutlookAppointments"), _class.prototype)), _class));
  _exports.default = CalendarComponent;
});