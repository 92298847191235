define("portal-talentrh/pods/home/my-workspace/skills-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7QwbQA6s",
    "block": "{\"symbols\":[\"Modal\",\"@showModal\",\"@closeModal\",\"@changeTotalSkills\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@onClose\",\"@size\"],[[32,2],[30,[36,0],[[32,3]],null],\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"titleModal\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"max-h-vh-8 overflow-auto ui-scrollbar\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"skills\",[],[[\"@changeTotalSkills\",\"@changeTitleModal\"],[[32,4],[32,0,[\"changeTitleModal\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/home/my-workspace/skills-modal/template.hbs"
    }
  });
  _exports.default = _default;
});