define("portal-talentrh/pods/company/programs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { action } from '@ember/object';

  class CompanyProgramsRoute extends Ember.Route {
    deactivate() {
      this.controller.filters = {};
    }

    // @action
    // willTransition() {
    //   this.controller.showCompanyPrograms = true;
    // }
  }
  _exports.default = CompanyProgramsRoute;
});