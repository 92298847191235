define("portal-talentrh/pods/search/user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-group-item', 'd-flex', 'align-items-center', 'py-3'],
    voorsChat: Ember.inject.service(),
    actions: {
      openChat() {
        this.voorsChat.openNewChat({
          id: this.user.id,
          isGroup: false
        });
      }
    }
  });
  _exports.default = _default;
});