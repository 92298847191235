define("portal-talentrh/pods/application/navbar/notification/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['navbar__dropdown'],
    ajax: Ember.inject.service(),
    notification: Ember.inject.service(),
    store: Ember.inject.service(),
    unreadCount: 0,
    notifications: Ember.A(),
    init() {
      this._super(...arguments);
      this.type = {
        'reminer': 'Lembrete'
      };
      this.loadNotifications.perform();
    },
    loadNotifications: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, 'loadedNotifications', true);
        let notifications = yield this.store.query('notification', {
          sort: 'createdAt DESC',
          limit: 3
        });
        Ember.set(this, 'notifications', notifications);
      } catch (e) {
        Ember.debug(e);
      }
    }),
    actions: {
      readAllNotifications() {
        io.socket.post('/notifications/read/all', {
          action: 'read'
        }, () => {
          Ember.set(this, 'unreadCount', 0);
          this.loadNotifications.perform();
        });
      }
    }
  });
  _exports.default = _default;
});