define("portal-talentrh/helpers/sub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sub = sub;
  function sub(params /*, hash*/) {
    return params[0] - params[1];
  }
  var _default = Ember.Helper.helper(sub);
  _exports.default = _default;
});