define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/controller", ["exports", "ember-concurrency-decorators", "portal-talentrh/config/environment", "lodash"], function (_exports, _emberConcurrencyDecorators, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberPerformanceManagementEmployeeEvaluationsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'), _dec25 = Ember.computed('newPerformanceModule', 'employeeEvaluation.id'), _dec26 = Ember._action, (_class = class ViewTeamMemberPerformanceManagementEmployeeEvaluationsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "evaluations", _descriptor3, this);
      _initializerDefineProperty(this, "evaluationsSkills", _descriptor4, this);
      _initializerDefineProperty(this, "evaluationSelected", _descriptor5, this);
      _initializerDefineProperty(this, "employeeEvaluation", _descriptor6, this);
      _initializerDefineProperty(this, "answers", _descriptor7, this);
      _initializerDefineProperty(this, "employeeEvaluationTeams", _descriptor8, this);
      _initializerDefineProperty(this, "employeeEvaluationTeamsUsers", _descriptor9, this);
      _initializerDefineProperty(this, "evaluationsPending", _descriptor10, this);
      _initializerDefineProperty(this, "evaluationPending", _descriptor11, this);
      _initializerDefineProperty(this, "listUserEmployee", _descriptor12, this);
      _initializerDefineProperty(this, "nineBoxCycle", _descriptor13, this);
      _initializerDefineProperty(this, "evolutionChartFilter", _descriptor14, this);
      _initializerDefineProperty(this, "comparisonChart", _descriptor15, this);
      _initializerDefineProperty(this, "generalFilters", _descriptor16, this);
      _initializerDefineProperty(this, "employeeByUsersFilters", _descriptor17, this);
      _initializerDefineProperty(this, "employeeByUsersSkillsFilters", _descriptor18, this);
      _initializerDefineProperty(this, "nineBoxCyclesFilters", _descriptor19, this);
      _initializerDefineProperty(this, "openModalAnswers", _descriptor20, this);
      _initializerDefineProperty(this, "openModalFeedback", _descriptor21, this);
      _initializerDefineProperty(this, "openModalDetails", _descriptor22, this);
      _initializerDefineProperty(this, "feedback", _descriptor23, this);
      _initializerDefineProperty(this, "newPerformanceModule", _descriptor24, this);
    }
    get urlEvaluationNewPerformanceModule() {
      return `${_environment.default.appUrl.performance}evaluations/my-team/${this.employeeEvaluation.id}`;
    }
    *loadFromGraphQl(userId) {
      const {
        employeeEvaluations: allEvaluationsFromUser
      } = yield this.fetch.request(`api/graphQl/teamMembersEvaluation`, {
        host: _environment.default.apiUrl.indicadores,
        data: {
          canceled: false,
          appraisedUser: userId
        }
      });
      if (!allEvaluationsFromUser.length) {
        return;
      }
      this.evaluations = allEvaluationsFromUser;
      this.evaluationSelected = allEvaluationsFromUser[0];
      this.employeeEvaluation = allEvaluationsFromUser[0];
      this.feedback = allEvaluationsFromUser?.feedback;
    }
    getAllEmployeeEvaluationsFilters(userId) {
      this.generalFilters.appraisedUser = userId;
      this.generalFilters.sort = 'createdAt DESC';
      this.generalFilters.canceled = 0;
    }
    getAllEmployeeEvaluationsSkillsFilters() {
      this.employeeByUsersSkillsFilters.appraisedUser = this.evaluationSelected.appraisedUser;
      this.employeeByUsersSkillsFilters.sort = 'createdAt DESC';
      this.employeeByUsersSkillsFilters.canceled = 0;
      this.employeeByUsersSkillsFilters.concluded = 1;
      this.employeeByUsersSkillsFilters.skill = 1;
      this.employeeByUsersSkillsFilters.createdAt = {
        '<': this.evaluationSelected.createdAt
      };
    }
    getEmployeeByUsersFilters() {
      this.employeeByUsersFilters.createdAt = this.evaluationSelected.createdAt;
      this.employeeByUsersFilters.user = [this.evaluationSelected.appraisedUser];
      this.employeeByUsersFilters.concluded = 1;
      this.employeeByUsersFilters.canceled = 0;
      this.employeeByUsersFilters.sort = 'createdAt DESC';
    }
    getNineBoxCyclesFilters() {
      this.nineBoxCyclesFilters.limit = 1;
      this.nineBoxCyclesFilters.loadUsersQuadrants = 1;
      this.nineBoxCyclesFilters.usersIds = [this.evaluationSelected.appraisedUser];
      this.nineBoxCyclesFilters.dataRef = this.evaluationSelected.createdAt;
    }
    getTypeDescription(n) {
      let types = {
        1: 'Primeira Avaliação',
        2: 'Avaliação de Efetivação',
        3: 'Avaliação de Função',
        4: 'Avaliação de Skills'
      };
      if (n.type === 4) {
        return `${types[n.originType]} - Skills`;
      }
      return types[n.type];
    }
    get evaluationConcluded() {
      if (this.newPerformanceModule) {
        return this.evaluationSelected.concluded;
      }
      return this.evaluationSelected.appraisedConcluded && this.evaluationSelected.appraiserConcluded && this.evaluationSelected.teamConcluded && this.evaluationSelected.consensusConcluded;
    }
    *loadAllEmployeeEvaluations(userId) {
      try {
        this.getAllEmployeeEvaluationsFilters(userId);
        let that = this;
        let response = yield this.fetch.request(`employeeEvaluations`, {
          host: _environment.default.apiUrl.avd,
          data: this.generalFilters
        });
        let responseEvaluationsPending = yield this.fetch.request(`home/evaluationsPending`, {
          host: _environment.default.apiUrl.avd
        });
        _lodash.default.map(response.employeeEvaluations, function square(n) {
          n.typeDescription = that.getTypeDescription(n);
        });
        this.evaluationsPending = responseEvaluationsPending;
        this.evaluations = response.employeeEvaluations;
        this.evaluationsSkills = null;
        this.evaluationSelected = null;
        this.employeeEvaluation = null;
        this.employeeEvaluationTeams = null;
        this.employeeEvaluationTeamsUsers = null;
        this.listUserEmployee = null;
        this.nineBoxCycle = null;
        this.feedback = null;
        this.answers = null;
        if (response.employeeEvaluations.firstObject) {
          this.evaluationSelected = response.employeeEvaluations.firstObject;
          this.evaluationPending = responseEvaluationsPending.find(o => o.id == response.employeeEvaluations.firstObject.id);
          yield this.loadEmployeeEvaluation.perform();
        }
      } catch (e) {
        Ember.debug(`EmployeeEvaluations Controller :: loadAllEmployeeEvaluations :: ${e}`);
      }
    }
    *loadEmployeeEvaluation() {
      try {
        let that = this;
        this.getEmployeeByUsersFilters();
        this.getAllEmployeeEvaluationsSkillsFilters();
        this.evaluationsSkills = null;
        this.employeeEvaluation = null;
        this.employeeEvaluationTeams = null;
        this.employeeEvaluationTeamsUsers = null;
        this.listUserEmployee = null;
        this.nineBoxCycle = null;
        this.answers = null;
        this.evolutionChartFilter = 2;
        this.feedback = null;
        let response = yield this.fetch.request(`employeeEvaluations/${this.evaluationSelected.id}`, {
          host: _environment.default.apiUrl.avd
        });
        let employeeEvaluationsSkills = yield this.fetch.request(`employeeEvaluations`, {
          host: _environment.default.apiUrl.avd,
          data: this.employeeByUsersSkillsFilters
        });
        let listUserEmployee = yield this.fetch.request('/employeeEvaluations/findEmployeeByUsers', {
          host: _environment.default.apiUrl.avd,
          data: this.employeeByUsersFilters
        });
        _lodash.default.map(employeeEvaluationsSkills.employeeEvaluations, function square(n) {
          n.typeDescription = that.getTypeDescription(n);
        });
        this.employeeEvaluation = response.employeeEvaluation;
        this.evaluationsSkills = employeeEvaluationsSkills.employeeEvaluations;
        this.listUserEmployee = listUserEmployee;
        if (response.employeeEvaluation.evaluationTeams) {
          yield this.loadEmployeeEvaluationTeams.perform();
        }
        if (this.evaluationSelected.feedback) {
          yield this.loadFeedback.perform();
        }
        yield this.loadNineBoxCycles.perform();
        this.answers = response.employeeEvaluation.appraisedAnswer ? response.employeeEvaluation.appraisedAnswer : response.employeeEvaluation.appraiserAnswer;
      } catch (e) {
        Ember.debug(`EmployeeEvaluations Controller :: loadEmployeeEvaluation :: ${e}`);
      }
    }
    *loadEmployeeEvaluationTeams() {
      try {
        this.employeeEvaluationTeams = null;
        this.employeeEvaluationTeamsUsers = null;
        let response = yield this.fetch.request(`employeeEvaluationTeams`, {
          host: _environment.default.apiUrl.avd,
          data: {
            employeeEvaluation: this.evaluationSelected.id
          }
        });
        if (response.employeeEvaluationTeams) {
          const sumOfScores = _lodash.default.sumBy(response.employeeEvaluationTeams, 'appraiserTotal') / response.employeeEvaluationTeams.length;
          Ember.set(this.employeeEvaluation, 'averageTeam', sumOfScores);
          this.employeeEvaluationTeams = response.employeeEvaluationTeams;
          this.employeeEvaluationTeamsUsers = response.users;
        }
      } catch (e) {
        Ember.debug(`EmployeeEvaluations Controller :: loadEmployeeEvaluationTeams :: ${e}`);
      }
    }
    *loadNineBoxCycles() {
      try {
        this.getNineBoxCyclesFilters();
        let nineBoxCycles = yield this.fetch.request(`nineBoxCycles`, {
          host: _environment.default.apiUrl.avd,
          data: this.nineBoxCyclesFilters
        });
        let nineBox = nineBoxCycles.nineBoxes;
        nineBox.columns = nineBoxCycles.nineBoxColumns;
        nineBox.lines = nineBoxCycles.nineBoxLines;
        nineBox.quadrants = nineBoxCycles.nineBoxQuadrants;
        nineBox.cycles = nineBoxCycles.nineBoxCycles.firstObject;
        nineBox.users = nineBoxCycles.users.firstObject;
        nineBox.jobFunctions = nineBoxCycles.jobFunctions;
        nineBox.jobRoles = nineBoxCycles.jobRoles;
        nineBox.departments = nineBoxCycles.departments;
        for (let quadrant of nineBox.quadrants) {
          let arrUsersQuadrant = [];
          for (let userQuadrant of quadrant.usersQuadrant) {
            let objUserQuadrant = nineBoxCycles.nineBoxUserQuadrants.find(u => u.id.toString() === userQuadrant.toString());
            objUserQuadrant.user = nineBoxCycles.users.find(u => u.id === objUserQuadrant.user);
            arrUsersQuadrant.push(objUserQuadrant);
          }
          quadrant.usersQuadrant = arrUsersQuadrant;
        }
        this.nineBoxCycle = nineBox;
      } catch (e) {
        Ember.debug(`EmployeeEvaluations Controller :: loadEmployeeEvaluations :: ${e}`);
      }
    }
    *loadFeedback() {
      try {
        let response = yield this.fetch.request(`/feedbacks/${this.evaluationSelected.feedback}`, {
          host: _environment.default.apiUrl.avd
        });
        this.feedback = response.feedbacks;
      } catch (e) {
        Ember.debug(`EmployeeEvaluations Controller :: loadFeedback :: ${e}`);
      }
    }
    async onEvaluationSelected(evaluation) {
      if (this.newPerformanceModule) {
        this.employeeEvaluation = evaluation;
        this.evaluationSelected = evaluation;
        return;
      }
      this.evaluationSelected = evaluation;
      this.evaluationPending = this.evaluationsPending.find(o => o.id == evaluation.id);
      await this.loadEmployeeEvaluation.perform();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "evaluations", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "evaluationsSkills", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "evaluationSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "employeeEvaluation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "answers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "employeeEvaluationTeams", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "employeeEvaluationTeamsUsers", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "evaluationsPending", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "evaluationPending", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "listUserEmployee", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "nineBoxCycle", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "evolutionChartFilter", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 2;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "comparisonChart", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "generalFilters", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "employeeByUsersFilters", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "employeeByUsersSkillsFilters", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "nineBoxCyclesFilters", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "openModalAnswers", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "openModalFeedback", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "openModalDetails", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "feedback", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "newPerformanceModule", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "urlEvaluationNewPerformanceModule", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "urlEvaluationNewPerformanceModule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadFromGraphQl", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadFromGraphQl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAllEmployeeEvaluations", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAllEmployeeEvaluations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEmployeeEvaluation", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmployeeEvaluation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEmployeeEvaluationTeams", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmployeeEvaluationTeams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadNineBoxCycles", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadNineBoxCycles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadFeedback", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadFeedback"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEvaluationSelected", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "onEvaluationSelected"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberPerformanceManagementEmployeeEvaluationsController;
});