define("portal-talentrh/pods/components/insight-action-buttons/feeling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZxPbcCUM",
    "block": "{\"symbols\":[\"@insightLed\",\"@insight\"],\"statements\":[[10,\"div\"],[14,0,\"col-md-6 p-0 mx-2\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,\"title\",\"Ver detalhes\"],[14,0,\"btn btn-sm btn-outline-success btn-action\"],[15,\"onclick\",[30,[36,0],[[32,0],\"goToDetails\"],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"far fa-bars\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"col-md-6 p-0 mx-2\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,\"title\",\"Registrar ação\"],[15,0,[31,[\"btn btn-sm btn-outline-primary btn-action \",[30,[36,2],[[30,[36,1],[[32,1,[\"status\"]],\"concluded\"],null],\"button-action-disabled\"],null]]]],[15,\"disabled\",[30,[36,2],[[30,[36,1],[[32,1,[\"status\"]],\"concluded\"],null],true],null]],[15,\"onclick\",[30,[36,3],[[32,0,[\"checkForPreviousPendingInsightsSameType\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"far fa-plus\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"insight-action-buttons/feeling/warning-modal\",[],[[\"@openModal\",\"@onSubmit\",\"@leader\"],[[32,0,[\"openModalWarning\"]],[30,[36,4],[[32,0,[\"openModalRegisterAction\"]]],null],[32,2,[\"leader\",\"firstName\"]]]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"if\",\"perform\",\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/insight-action-buttons/feeling/template.hbs"
    }
  });
  _exports.default = _default;
});