define("portal-talentrh/pods/home/human-resources/goal-details/collaborator/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeHumanResourcesGoalDetailsCollaboratorRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {};
      this.controller.exclusiveGoals = null;
      this.controller.userGoal = null;
    }
  }
  _exports.default = HomeHumanResourcesGoalDetailsCollaboratorRoute;
});