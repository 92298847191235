define("portal-talentrh/pods/absence-board/absence-calendar/component", ["exports", "ember-concurrency", "jquery"], function (_exports, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    screen: Ember.inject.service(),
    store: Ember.inject.service(),
    disableCancel: false,
    disableTerminate: false,
    showModal: false,
    absenceSelected: null,
    didInsertElement() {
      this._super(...arguments);
      this._initCalendar();
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)('#calendar').fullCalendar('destroy');
    },
    displayEvent: (0, _emberConcurrency.task)(function* (event) {
      yield Ember.get(this, 'store').findRecord('user-message', event.id).then(message => {
        Ember.set(this, 'absenceSelected', message);
        Ember.set(this, 'showModal', true);
      });
    }),
    _initCalendar: function () {
      if (!_jquery.default.isFunction(_jquery.default.fn.fullCalendar)) {
        return;
      }
      (0, _jquery.default)('#calendar').fullCalendar({
        allDaySlot: false,
        buttonText: {
          today: 'Hoje',
          month: 'Mês',
          week: 'Semana',
          day: 'Dia'
        },
        droppable: false,
        editable: true,
        eventTextColor: 'white',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'month,agendaWeek,agendaDay'
        },
        height: 700,
        locale: 'pt-br',
        navLinks: true,
        slotLabelFormat: 'HH:mm',
        timezone: 'local',
        timeFormat: 'HH:mm',
        events: (start, end, timezone, callback) => {
          let data = {
            start: start.toISOString(),
            end: end.toISOString()
          };
          this.ajax.request(`/userMessages/calendar`, {
            data
          }).then(response => {
            callback(response);
          });
        },
        eventClick: event => {
          this.displayEvent.perform(event);
        }
      });
    },
    _refetchEvents() {
      (0, _jquery.default)('#calendar').fullCalendar('refetchEvents');
    }
  });
  _exports.default = _default;
});