define("portal-talentrh/models/dashboard-slide-item", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar um título'
    }),
    type: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'É necessário informar o tipo'
    }), (0, _emberCpValidations.validator)('inclusion', {
      in: ['birthday', 'album', 'admissionAnniversary', 'absence', 'ad', 'announcement', 'event', 'vacancy', 'recent_users', 'image', 'video', 'link'],
      message: 'É necessário informar um tipo de unidade de medida entre os valores aceitos'
    })]
  });
  let DashboardSlideItemModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.belongsTo)('dashboard-slide'), _dec11 = (0, _model.belongsTo)('dashboard-slide-item-absence'), _dec12 = (0, _model.belongsTo)('dashboard-slide-item-admission'), _dec13 = (0, _model.belongsTo)('dashboard-slide-item-ad'), _dec14 = (0, _model.belongsTo)('dashboard-slide-item-album'), _dec15 = (0, _model.belongsTo)('dashboard-slide-item-announcement'), _dec16 = (0, _model.belongsTo)('dashboard-slide-item-birthday'), _dec17 = (0, _model.belongsTo)('dashboard-slide-item-event'), _dec18 = (0, _model.belongsTo)('dashboard-slide-item-image'), _dec19 = (0, _model.belongsTo)('dashboard-slide-item-link'), _dec20 = (0, _model.belongsTo)('dashboard-slide-item-recent-user'), _dec21 = (0, _model.belongsTo)('dashboard-slide-item-vacancy'), _dec22 = (0, _model.belongsTo)('dashboard-slide-item-video'), _dec23 = Ember.computed('refreshTimeInSeconds'), (_class = class DashboardSlideItemModel extends _model.default.extend(Validations) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "color", _descriptor2, this);
      _initializerDefineProperty(this, "type", _descriptor3, this);
      _initializerDefineProperty(this, "x", _descriptor4, this);
      _initializerDefineProperty(this, "y", _descriptor5, this);
      _initializerDefineProperty(this, "width", _descriptor6, this);
      _initializerDefineProperty(this, "height", _descriptor7, this);
      _initializerDefineProperty(this, "minWidth", _descriptor8, this);
      _initializerDefineProperty(this, "selected", _descriptor9, this);
      _initializerDefineProperty(this, "dashboardSlide", _descriptor10, this);
      _initializerDefineProperty(this, "dashboardSlideItemAbsence", _descriptor11, this);
      _initializerDefineProperty(this, "dashboardSlideItemAdmission", _descriptor12, this);
      _initializerDefineProperty(this, "dashboardSlideItemAds", _descriptor13, this);
      _initializerDefineProperty(this, "dashboardSlideItemAlbum", _descriptor14, this);
      _initializerDefineProperty(this, "dashboardSlideItemAnnouncement", _descriptor15, this);
      _initializerDefineProperty(this, "dashboardSlideItemBirthday", _descriptor16, this);
      _initializerDefineProperty(this, "dashboardSlideItemEvent", _descriptor17, this);
      _initializerDefineProperty(this, "dashboardSlideItemImage", _descriptor18, this);
      _initializerDefineProperty(this, "dashboardSlideItemLink", _descriptor19, this);
      _initializerDefineProperty(this, "dashboardSlideItemRecentUser", _descriptor20, this);
      _initializerDefineProperty(this, "dashboardSlideItemVacancy", _descriptor21, this);
      _initializerDefineProperty(this, "dashboardSlideItemVideo", _descriptor22, this);
    }
    get refreshTimeInMilliseconds() {
      const randomRefreshtime = Math.floor(Math.random() * (120 - 60 + 1)) + 60;
      return randomRefreshtime * 1000;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "color", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "x", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "y", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "minWidth", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlide", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemAbsence", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemAdmission", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemAds", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemAlbum", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemAnnouncement", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemBirthday", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemEvent", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemImage", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemLink", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemRecentUser", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemVacancy", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "dashboardSlideItemVideo", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshTimeInMilliseconds", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "refreshTimeInMilliseconds"), _class.prototype)), _class));
  _exports.default = DashboardSlideItemModel;
});