define("portal-talentrh/pods/components/dragdrop-position-control/component", ["exports", "sortablejs"], function (_exports, _sortablejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Lifecycle hooks
    didInsertElement() {
      let sortable = _sortablejs.default.create(this.element, {
        onUpdate: evt => {
          if (this.onUpdate) {
            this.onUpdate(sortable.toArray(), evt);
          }
        }
      });
    }
  });
  _exports.default = _default;
});