define("portal-talentrh/helpers/show-mentions", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.showMentions = showMentions;
  function showMentions(params, hash) {
    let text = hash.text || '';
    let mentions = hash.mentions || [];
    let diff = 0;
    if (!mentions) {
      return text;
    }
    if (typeof mentions === 'string') {
      mentions = JSON.parse(mentions);
    }
    mentions = mentions.sort(m => Number(m.start));
    mentions.forEach(mention => {
      let start = Number(mention.start) + diff;
      let end = Number(mention.end) + diff;
      let name = text.substring(start, end);
      if (name !== mention.userName) {
        return;
      }
      let a = `<a href="${_environment.default.appUrl.social}profile/${mention.userId}" class="font-weight-bold">%name</a>`;
      diff += a.length - 5;
      a = a.replace('%name', name);
      text = text.substring(0, start) + a + text.substring(end, text.length);
    });
    if (hash.breakLines) {
      text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    }
    return Ember.String.htmlSafe(text);
  }
  var _default = Ember.Helper.helper(showMentions);
  _exports.default = _default;
});