define("portal-talentrh/models/feedback", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    approvalStatus: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    date: (0, _model.attr)('date'),
    local: (0, _model.attr)('string'),
    activityDescription: (0, _model.attr)('string'),
    objective: (0, _model.attr)('string'),
    startTime: (0, _model.attr)('string'),
    endTime: (0, _model.attr)('string'),
    draft: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('number'),
    type: (0, _model.attr)('number'),
    improvePoints: (0, _model.attr)('string'),
    keepPoints: (0, _model.attr)('string'),
    finalFeedback: (0, _model.attr)('string'),
    suggestionOrImprovement: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    leaderUser: (0, _model.belongsTo)('user'),
    user: (0, _model.belongsTo)('user'),
    pdi: (0, _model.belongsTo)('pdi'),
    pdiAction: (0, _model.belongsTo)('pdiAction'),
    employeeEvaluation: (0, _model.belongsTo)('employee-evaluation'),
    typeBadge: Ember.computed('type', function () {
      let type = this.type;
      let badges = {
        0: {
          text: 'Corretivo',
          color: 'danger'
        },
        1: {
          text: 'Positivo',
          color: 'success'
        }
      };
      return badges[type] || {};
    }),
    approvalStatusBadge: Ember.computed('approvalStatus', function () {
      let type = this.approvalStatus;
      let badges = {
        'pending': {
          text: 'Pendente',
          color: 'warning'
        },
        'approved': {
          text: 'Aprovado',
          color: 'success'
        },
        'disapproved': {
          text: 'Reprovado',
          color: 'danger'
        }
      };
      return badges[type] || {};
    })
  });
  _exports.default = _default;
});