define("portal-talentrh/pods/anniversary/controller", ["exports", "moment", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _moment, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AnniversaryController = (_dec = Ember.computed.alias('model.typeBirthday'), _dec2 = Ember.computed.alias('model.filterDefault'), _dec3 = Ember.computed.alias('model.birthdaysAppointments'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed('monthSelected.number'), _dec14 = Ember.computed('monthSelected.number'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class AnniversaryController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "typeBirthday", _descriptor, this);
      _initializerDefineProperty(this, "filterDefault", _descriptor2, this);
      _initializerDefineProperty(this, "birthdaysAppointments", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "isCompanyBirthdate", _descriptor5, this);
      _initializerDefineProperty(this, "nameFilter", _descriptor6, this);
      _initializerDefineProperty(this, "today", _descriptor7, this);
      _initializerDefineProperty(this, "thisWeek", _descriptor8, this);
      _initializerDefineProperty(this, "month", _descriptor9, this);
      _initializerDefineProperty(this, "monthSelected", _descriptor10, this);
      _initializerDefineProperty(this, "dateSelected", _descriptor11, this);
      _initializerDefineProperty(this, "optionsBirthdatesSelected", _descriptor12, this);
      _defineProperty(this, "optionsPeriod", Ember.A([{
        groupName: 'Rápido',
        options: [{
          number: 0,
          name: 'Hoje'
        }, {
          number: 13,
          name: 'Esta semana'
        }, {
          number: 14,
          name: 'Próxima semana'
        }]
      }, {
        groupName: 'Mês',
        options: [{
          number: 1,
          name: 'Janeiro'
        }, {
          number: 2,
          name: 'Fevereiro'
        }, {
          number: 3,
          name: 'Março'
        }, {
          number: 4,
          name: 'Abril'
        }, {
          number: 5,
          name: 'Maio'
        }, {
          number: 6,
          name: 'Junho'
        }, {
          number: 7,
          name: 'Julho'
        }, {
          number: 8,
          name: 'Agosto'
        }, {
          number: 9,
          name: 'Setembro'
        }, {
          number: 10,
          name: 'Outubro'
        }, {
          number: 11,
          name: 'Novembro'
        }, {
          number: 12,
          name: 'Dezembro'
        }]
      }]));
      _defineProperty(this, "optionsBirthdates", Ember.A([{
        number: 0,
        name: 'Colaboradores'
      }, {
        number: 1,
        name: 'Tempo de empresa'
      }]));
    }
    get isValidMonth() {
      let currentMonth = this.optionsPeriod[1].options.findBy('number', (0, _moment.default)().month() + 1);
      return [0, 13, 14].includes(this.monthSelected.number) ? currentMonth : this.monthSelected;
    }
    get disableDateFilter() {
      return [0, 13, 14].includes(this.monthSelected.number);
    }
    get startEndDate() {
      const monthNumber = this.monthSelected.number;
      return {
        start: (0, _moment.default)(monthNumber, 'MM').startOf('month'),
        end: (0, _moment.default)(monthNumber, 'MM').endOf('month')
      };
    }
    *filterTerm() {
      yield (0, _emberConcurrency.timeout)(300);
      this.getFilters();
    }
    getFilters() {
      let filter = {
        month: (0, _moment.default)().month() + 1,
        typeBirthday: this.typeBirthday
      };
      let optionsBirthdatesSelected = this.optionsBirthdates[0];
      if (this.typeBirthday === 'company') {
        optionsBirthdatesSelected = this.optionsBirthdates[1];
        this.isCompanyBirthdate = true;
      }
      this.optionsBirthdatesSelected = optionsBirthdatesSelected;
      if (!this.thisWeek && !this.month && this.filterDefault === 'showBirthdayDay') {
        this.monthSelected = this.optionsPeriod[0].options[0];
        filter.today = (0, _moment.default)().format('D');
      }
      if (this.nameFilter) {
        filter.term = this.nameFilter;
      }
      if (this.month) {
        filter.month = this.month.number;
      }
      if (this.thisWeek) {
        filter.initialDayWeek = this.thisWeek.initialDate;
        filter.endDayWeek = this.thisWeek.endDate;
      }
      if (this.today) {
        filter.today = this.today;
      }
      if (this.filterDefault === 'showBirthdayMonth' && !this.month) {
        this.monthSelected = this.optionsPeriod[1].options.findBy('number', (0, _moment.default)().month() + 1);
      }
      if (this.dateSelected) {
        filter.date = (0, _moment.default)(this.dateSelected).format('YYYY-MM-DD');
      }
      this.filters = filter;
    }
    changeMonth(month) {
      let monthSelected = month;
      this.thisWeek = null;
      this.today = null;
      this.nameFilter = null;
      if ([0, 13, 14].includes(month.number)) {
        this.dateSelected = null;
        month = this.optionsPeriod[1].options.findBy('number', (0, _moment.default)().month() + 1);
        if (monthSelected.number === 0) {
          this.today = (0, _moment.default)().format('D');
        }
        if (monthSelected.number === 13) {
          this.thisWeek = {
            initialDate: (0, _moment.default)().startOf('week').format('MM-DD'),
            endDate: (0, _moment.default)().endOf('week').format('MM-DD')
          };
        }
        if (monthSelected.number === 14) {
          let nextWeekInit = (0, _moment.default)().startOf('week').add(1, 'weeks').format('MM-DD');
          let nextWeekEnd = (0, _moment.default)().endOf('week').add(1, 'weeks').format('MM-DD');
          this.thisWeek = {
            initialDate: nextWeekInit,
            endDate: nextWeekEnd
          };
        }
      }
      this.month = month;
      this.monthSelected = monthSelected;
      this.getFilters();
    }
    changeDate(value) {
      const {
        date
      } = value;
      this.dateSelected = date;
      this.getFilters();
    }
    changeBirthdates(value) {
      this.optionsBirthdatesSelected = value;
      this.isCompanyBirthdate = value.number === 1 ? true : false;
      this.typeBirthday = value.number === 1 ? 'company' : 'personal';
      this.getFilters();
    }
    addAppointment(appointment) {
      this.birthdaysAppointments.addObject(appointment);
    }
    removeAppointment(appointment) {
      this.birthdaysAppointments.removeObject(appointment);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "typeBirthday", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filterDefault", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "birthdaysAppointments", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isCompanyBirthdate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nameFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "thisWeek", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "month", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "monthSelected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        number: 0,
        name: 'Hoje'
      };
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dateSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "optionsBirthdatesSelected", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disableDateFilter", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "disableDateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startEndDate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "startEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterTerm", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "filterTerm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeMonth", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "changeMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeDate", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "changeDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeBirthdates", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "changeBirthdates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addAppointment", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "addAppointment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAppointment", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "removeAppointment"), _class.prototype)), _class));
  _exports.default = AnniversaryController;
});