define("portal-talentrh/pods/company/minutes-of-meeting/index/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyMinutesOfMeetingIndexViewRoute extends Ember.Route {
    deactivate() {
      Ember.set(this.controllerFor('company.minutes-of-meeting.index'), 'tab', this.controller.userIsParticipant ? 'invited' : 'admin');
      Ember.set(this.controller, 'userIsParticipant', false);
    }
  }
  _exports.default = CompanyMinutesOfMeetingIndexViewRoute;
});