define("portal-talentrh/pods/components/indicators/organizational-climate/absences/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K/xX/gE8",
    "block": "{\"symbols\":[\"absence\",\"@data\"],\"statements\":[[10,\"div\"],[14,0,\"absences-chart\"],[12],[2,\"\\n  \"],[8,\"indicators/organizational-climate/absences-chart\",[],[[\"@data\"],[[32,2]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mt-2 ui-scrollbar absences-progress\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row p-2\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"details\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-md-6 align-left\"],[12],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-6 align-right\"],[12],[2,\"\\n          \"],[1,[32,1,[\"percentage\"]]],[2,\"%\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-12 mt-1 mb-3\"],[12],[2,\"\\n          \"],[8,\"progressbar-indicators\",[],[[\"@percentage\",\"@backgroundColor\",\"@mini\"],[[31,[[32,1,[\"percentage\"]],\"%\"]],[32,1,[\"color\"]],true]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/organizational-climate/absences/template.hbs"
    }
  });
  _exports.default = _default;
});