define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/evolution-chart-skill/component", ["exports", "@glimmer/component", "chart-js", "moment"], function (_exports, _component, _chartJs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EvolutionChartSkillComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class EvolutionChartSkillComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chart", _descriptor, this);
      _initializerDefineProperty(this, "firstEvaluationSelected", _descriptor2, this);
      _initializerDefineProperty(this, "secondEvaluationSelected", _descriptor3, this);
    }
    initChart() {
      let that = this;
      let datasets = [];
      let labels = [];
      let firstDataSet = {
        label: '',
        backgroundColor: "#008B71",
        borderColor: "#008B71",
        data: [],
        fill: false,
        maxBarThickness: 30
      };
      let secondDataSet = {
        label: '',
        backgroundColor: "#D85076",
        borderColor: "#D85076",
        data: [],
        fill: false,
        maxBarThickness: 30
      };
      if (this.firstEvaluationSelected && this.firstEvaluationSelected.consensusAnswer) {
        firstDataSet.label = `${this.firstEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.firstEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
        this.firstEvaluationSelected.consensusAnswer.map(answer => {
          answer.questions.map(question => {
            if (!labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            firstDataSet.data.push(parseFloat(question.answer));
          });
        });
      } else if (this.firstEvaluationSelected && this.firstEvaluationSelected.appraiserAnswer && this.firstEvaluationSelected.appraisedAnswer) {
        firstDataSet.label = `${this.firstEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.firstEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
        this.firstEvaluationSelected.appraiserAnswer.map(answer => {
          answer.questions.map(question => {
            let avg = that.firstEvaluationSelected.appraisedAnswer.find(o => o.id === answer.id);
            avg = avg.questions.find(o => o.id === question.id);
            avg = parseFloat(avg.answer) > 0 ? parseFloat(avg.answer) / 2 : 0;
            if (!labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            firstDataSet.data.push(parseFloat(avg));
          });
        });
      } else if (this.firstEvaluationSelected && this.firstEvaluationSelected.appraiserAnswer) {
        firstDataSet.label = `${this.firstEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.firstEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
        this.firstEvaluationSelected.appraiserAnswer.map(answer => {
          answer.questions.map(question => {
            if (!labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            firstDataSet.data.push(parseFloat(question.answer));
          });
        });
      }
      if (this.secondEvaluationSelected && this.secondEvaluationSelected.consensusAnswer) {
        this.secondEvaluationSelected.consensusAnswer.map(answer => {
          secondDataSet.label = `${this.secondEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.secondEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
          answer.questions.map(question => {
            if (labels.length == 0 && !labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            secondDataSet.data.push(parseFloat(question.answer));
          });
        });
      } else if (this.secondEvaluationSelected && this.secondEvaluationSelected.appraiserAnswer && this.secondEvaluationSelected.appraisedAnswer) {
        secondDataSet.label = `${this.secondEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.secondEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
        this.secondEvaluationSelected.appraiserAnswer.map(answer => {
          answer.questions.map(question => {
            let avg = that.secondEvaluationSelected.appraisedAnswer.find(o => o.id === answer.id);
            avg = avg.questions.find(o => o.id === question.id);
            avg = parseFloat(avg.answer) > 0 ? parseFloat(avg.answer) / 2 : 0;
            if (labels.length == 0 && !labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            secondDataSet.data.push(parseFloat(avg));
          });
        });
      } else if (this.secondEvaluationSelected && this.secondEvaluationSelected.appraiserAnswer) {
        secondDataSet.label = `${this.secondEvaluationSelected.typeDescription} | ${(0, _moment.default)(this.secondEvaluationSelected.createdAt).format("DD/MM/YYYY")}`;
        this.secondEvaluationSelected.appraiserAnswer.map(answer => {
          answer.questions.map(question => {
            if (!labels.find(o => o === question.title)) {
              labels.push(question.title);
            }
            secondDataSet.data.push(parseFloat(question.answer));
          });
        });
      }
      datasets.push(firstDataSet);
      datasets.push(secondDataSet);
      let arrLabels = [];
      labels.map(label => {
        let labelFormated = label.split(/(?<! de| da| aos| ou| em| às| dos| das| na| no| é| à)[\s,]/);
        labelFormated = labelFormated.toString().replaceAll(',', ' ');
        labelFormated = labelFormated.length > 30 ? labelFormated.substr(0, 30) + '...' : labelFormated;
        arrLabels.push(labelFormated);
      });
      var data = {
        labels: arrLabels,
        datasets: datasets
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('evolutionChartSkill').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          data: data,
          options: {
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  return ' ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
                },
                title: function (tooltipItem) {
                  return tooltipItem[0].xLabel.join(' ');
                }
              }
            },
            barValueSpacing: 20,
            scales: {
              yAxes: [{
                ticks: {
                  min: 0,
                  max: 100,
                  callback: function (value) {
                    return value + '%';
                  }
                }
              }]
            }
          }
        });
      }
      this.chart = chart;
    }
    init() {
      let {
        options
      } = this.args;
      this.firstEvaluationSelected = options[0];
      this.secondEvaluationSelected = options[1];
      this.initChart();
    }
    onFirstEvaluationSelected(evaluation) {
      this.firstEvaluationSelected = evaluation;
      this.initChart();
    }
    onSecondEvaluationSelected(evaluation) {
      this.secondEvaluationSelected = evaluation;
      this.initChart();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firstEvaluationSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "secondEvaluationSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFirstEvaluationSelected", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onFirstEvaluationSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSecondEvaluationSelected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSecondEvaluationSelected"), _class.prototype)), _class));
  _exports.default = EvolutionChartSkillComponent;
});