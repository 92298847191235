define("portal-talentrh/pods/components/social-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['social-page', 'ui-page'],
    actions: {
      enableMenu() {
        Ember.set(this, 'menuEnabled', true);
      }
    }
  });
  _exports.default = _default;
});