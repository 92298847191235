define("portal-talentrh/helpers/project-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.projectStatusBadge = projectStatusBadge;
  function projectStatusBadge(params /*, hash*/) {
    switch (params[0]) {
      case 'pending':
        return 'A vencer';
      case 'late':
        return 'Atrasada';
      case 'concluded':
        return 'Concluída';
      case 'noDeadline':
        return 'Sem prazo';
    }
  }
  var _default = Ember.Helper.helper(projectStatusBadge);
  _exports.default = _default;
});