define("portal-talentrh/pods/components/indicators/company-profile/distribution-by-role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dN4a+r67",
    "block": "{\"symbols\":[\"record\",\"@chartFilter\",\"@hover\",\"@distributionByRole\"],\"statements\":[[10,\"div\"],[14,0,\"row p-2\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-4 pr-0 pl-0\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"vertical-chart-text-truncate\"],[15,\"title\",[32,1,[\"label\"]]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,1,[31,[\"progressbar_\",[32,1,[\"jobRole\"]]]]],[14,0,\"col-8\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"onChartClick\"]],[32,1,[\"jobRole\"]]],null]],[12],[2,\"\\n      \"],[8,\"progressbar-indicators\",[],[[\"@title\",\"@percentage\",\"@total\",\"@backgroundColor\",\"@chartFilter\",\"@chartId\",\"@hover\"],[[32,1,[\"label\"]],[32,1,[\"percentage\"]],[32,1,[\"total\"]],\"#6200ee\",[32,2],[32,1,[\"jobRole\"]],[32,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicators/company-profile/distribution-by-role/template.hbs"
    }
  });
  _exports.default = _default;
});