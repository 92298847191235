define("portal-talentrh/pods/application/navbar/search/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['search-bar'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    searchResults: Ember.computed.alias('search.lastSuccessful.value'),
    search: (0, _emberConcurrency.task)(function* (dropdown) {
      yield (0, _emberConcurrency.timeout)(500);
      try {
        let {
          searchResults
        } = yield this.ajax.request('search', {
          data: {
            term: this.query
          }
        });
        dropdown.actions.open();
        return searchResults;
      } catch (e) {
        Ember.debug(e);
      }
    }).restartable(),
    actions: {
      closeSearch(dropdown) {
        Ember.set(this, 'query', null);
        dropdown.actions.close();
      },
      showSearch() {
        this.router.transitionTo('search', {
          queryParams: {
            query: this.query
          }
        });
      }
    }
  });
  _exports.default = _default;
});