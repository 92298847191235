define("portal-talentrh/pods/components/meeting-attachments-itens/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N/PM44ww",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"card shadow-sm\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-body d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[\"pr-2 fa-lg fa \",[30,[36,2],[[35,0,[\"type\"]]],null]]]],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex-fill ml-2 pl-2 text-truncate border-left\"],[12],[2,\"\\n      \"],[10,\"h6\"],[14,0,\"mb-0 text-truncate\"],[15,\"title\",[34,0,[\"name\"]]],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n      \"],[10,\"small\"],[15,\"title\",[34,0,[\"user\",\"fullName\"]]],[12],[1,[35,0,[\"user\",\"fullName\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"text-nowrap\"],[12],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-primary ml-1\"],[14,\"title\",\"Baixar\"],[15,\"onclick\",[30,[36,4],[[35,3]],null]],[14,4,\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-download\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-danger\"],[15,\"onclick\",[30,[36,1],[[32,0],\"delete\",[35,0]],null]],[14,\"title\",\"Remover\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"file-type\",\"download\",\"perform\",\"edit\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/meeting-attachments-itens/template.hbs"
    }
  });
  _exports.default = _default;
});