define("portal-talentrh/pods/components/profile-feeling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dLOG/KHV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"feeling-profile\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"src\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[31,[[32,0,[\"src\"]]]]],[14,0,\"profile-feeling avatar-2 avatar-feeling-borderless d-block\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"feeling-profile-popup\"],[12],[2,\"\\n                \"],[10,\"p\"],[14,0,\"font-weight-bold text-secondary\"],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n                \"],[10,\"img\"],[15,\"src\",[31,[[32,0,[\"src\"]]]]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/profile-feeling/template.hbs"
    }
  });
  _exports.default = _default;
});