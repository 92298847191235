define("portal-talentrh/pods/feed/index/setup-account/skeleton-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nLJ+pZEx",
    "block": "{\"symbols\":[\"Skeleton\",\"@count\"],\"statements\":[[8,\"ui-skeleton\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Render\"]],[[24,0,\"row\"]],[[\"@count\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"card mb-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-body d-flex\"],[12],[2,\"\\n          \"],[8,[32,1,[\"Avatar\"]],[[24,0,\"avatar-4\"]],[[],[]],null],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"ml-3 flex-fill\"],[12],[2,\"\\n            \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-0 w-75\"]],[[],[]],null],[2,\"\\n            \"],[8,[32,1,[\"Text\"]],[[24,0,\"mb-0 w-50\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/index/setup-account/skeleton-item/template.hbs"
    }
  });
  _exports.default = _default;
});