define("portal-talentrh/pods/absence-board/future/controller", ["exports", "moment", "portal-talentrh/utils/formatDate"], function (_exports, _moment, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AbsenceBoardFutureController = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class AbsenceBoardFutureController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", ['from']);
      _initializerDefineProperty(this, "filters", _descriptor, this);
    }
    getFilters(term, dates) {
      const filters = {
        tab: 'future'
      };
      const dateNow = this.formatDateTimeZone(new Date());
      const startDateToFilter = dates?.startDateToFilter ? (0, _moment.default)(dates.startDateToFilter).format('YYYY-MM-DD 00:00:00') : null;
      const endDateToFilter = dates?.endDateToFilter ? (0, _moment.default)(dates.endDateToFilter).format('YYYY-MM-DD 23:59:59') : null;
      if (startDateToFilter || endDateToFilter) {
        filters.startDateToFilter = startDateToFilter;
        filters.endDateToFilter = endDateToFilter;
        filters.startDate = startDateToFilter ? startDateToFilter : null;
        filters.endDate = endDateToFilter ? endDateToFilter : null;
      } else {
        filters.startDate = (0, _moment.default)(dateNow, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
      }
      if (term) {
        filters.term = term;
      }
      this.filters = filters;
    }
    formatDateTimeZone(dateToFilter) {
      const timezone = new Date().getTimezoneOffset() / 60;
      let date = (0, _moment.default)(dateToFilter);
      if (timezone != 3) {
        date = date.add(timezone + 1, 'h');
        date = date.subtract(timezone, 'h');
      }
      return (0, _formatDate.default)(date);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getFilters", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatDateTimeZone", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "formatDateTimeZone"), _class.prototype)), _class));
  _exports.default = AbsenceBoardFutureController;
});