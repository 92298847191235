define("portal-talentrh/pods/company/userlist/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(_, transition) {
      let {
        hasNewEmployees
      } = transition.to.queryParams;
      return Ember.RSVP.hash({
        hasNewEmployees
      });
    },
    afterModel(model) {
      this.controllerFor('company.userlist').updateFilter.perform(model);
    },
    deactivate() {
      this.controller.resetFilters();
    }
  });
  _exports.default = _default;
});