define("portal-talentrh/pods/components/widgets/birthday-employees/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    hasMore: false,
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    fetch: Ember.inject.service(),
    // Single line computed properties
    configuration: Ember.computed.alias('permission.companyConfig'),
    // Life cycle
    didInsertElement() {
      const session = this.session;
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenBirthdayWidget = session.data.lastOpenBirthdayWidget;
      if (lastOpenBirthdayWidget && lastOpenBirthdayWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        this.loadWidget.perform();
      }
    },
    loadWidget: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      let itemsPerPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
      return function* () {
        let showBirthdayMonth = _this.configuration.showBirthdayMonth ? 'month' : 'today';
        let queryParams = `?variant=${showBirthdayMonth}&limit=${itemsPerPage}`;
        if (page > 1) {
          queryParams += `&skip=${(page - 1) * itemsPerPage}`;
        }
        const {
          users,
          hasMore
        } = yield _this.fetch.request(`/widgets/birthday${queryParams}`);
        Ember.set(_this, 'hasMore', hasMore);
        return Ember.A(users);
      }();
    }).drop(),
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenBirthdayWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});