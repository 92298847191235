define("portal-talentrh/pods/components/indicator-viewer/team-profile/jobfunctions-by-type/component", ["exports", "chart-js"], function (_exports, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    initChart(indicator) {
      let {
        chartData
      } = indicator.data;
      let haveData = {
        labels: chartData.labels,
        datasets: [{
          data: chartData.data,
          backgroundColor: ['#5389c5', '#35b386', '#465773', '#e86a69', '#008B71', '#7d5ac2', '#d2c93f', '#6475FD']
        }]
      };
      let noData = {
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#eee']
        }]
      };
      let data = chartData.labels.length ? haveData : noData;
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('chart-jobfunction-by-type').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'doughnut',
          data: data,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'right'
            },
            legendCallback: function (chart) {
              // Cria uma lengenda customizada usando html
              let html = [];
              chart.data.labels.forEach((obj, index) => {
                let color = chart.data.datasets[0].backgroundColor[index];
                let labelName = chart.data.labels[index];
                let legendHtml = `
                <li class="py-1 text-truncate" title="${labelName}">
                  <span class="pr-1 mr-2 rounded" style="background: ${color}"></span> ${labelName}
                </li>
              `;
                html.push(legendHtml);
              });
              return html.join('');
            }
          }
        });
      }
      document.getElementById('chart-jobfunction-by-type-legend').innerHTML = chart.generateLegend();
      this.set('chart', chart);
    },
    actions: {
      generate() {
        let indicator = this.indicator;
        if (indicator && indicator.data) {
          this.initChart(indicator);
        }
      }
    }
  });
  _exports.default = _default;
});