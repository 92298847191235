define("portal-talentrh/models/decision", ["exports", "@ember-data/model", "ember-cp-validations", "moment"], function (_exports, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O título deve ser preenchido'
    }),
    initialDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A data inicial deve ser preenchida'
    }), (0, _emberCpValidations.validator)('date', {
      precision: 'day',
      onOrAfter: Ember.computed('model.{initialDate,isNew}', function () {
        return this.model.isNew && (0, _moment.default)().startOf('day');
      }),
      message: 'A data inicial deve ser igual ou posterior à data de hoje'
    })],
    conclusionDate: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A data final deve ser preenchida'
    }), (0, _emberCpValidations.validator)('date', {
      message: 'A data final deve ser após a inicial',
      precision: 'day',
      onOrAfter: Ember.computed.reads('model.initialDate')
    })]
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    conclusionDate: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    dataChart: (0, _model.attr)('raw'),
    description: (0, _model.attr)('string'),
    initialDate: (0, _model.attr)('date'),
    requiresJustify: (0, _model.attr)('boolean'),
    receiveNotificationByReply: (0, _model.attr)('boolean'),
    title: (0, _model.attr)('string'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    createdBy: (0, _model.belongsTo)('user'),
    users: (0, _model.hasMany)('user', {
      inverse: 'decisions'
    }),
    votes: (0, _model.hasMany)('decision-user-vote', {
      inverse: 'decision'
    }),
    // Computed Properties
    isActive: Ember.computed('conclusionDate', 'initialDate', function () {
      return (0, _moment.default)().isBetween(this.initialDate, this.conclusionDate);
    }),
    today: Ember.computed(function () {
      return (0, _moment.default)().startOf('day');
    })
  });
  _exports.default = _default;
});