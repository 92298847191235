define("portal-talentrh/pods/components/widgets/vacancy/component", ["exports", "moment", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _moment, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    manageOnlyBranch: Ember.computed.alias('permission.accessProfile.manageOnlyBranch'),
    // Taks
    loadWidget: (0, _emberConcurrency.task)(function* () {
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenAdsWidget = this.session.data.lastOpenAdsWidget;
      if (lastOpenAdsWidget && lastOpenAdsWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        let {
          vacancy
        } = yield this.ajax.request('vacancy/find', {
          host: _environment.default.apiUrl.conta,
          data: {
            manageOnlyBranch: this.manageOnlyBranch,
            sort: 'applicationdeadline ASC',
            where: JSON.stringify({
              applicationdeadline: {
                '>=': currentDate
              }
            })
          }
        });
        return vacancy;
      }
    }).on('didInsertElement'),
    // Actions
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenAdsWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});