define("portal-talentrh/pods/company/decision/index/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyDecisionIndexCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('decision');
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
    }
  }
  _exports.default = CompanyDecisionIndexCreateRoute;
});