define("portal-talentrh/pods/components/user-preview-insight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xxDf39s+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"user-preview-insight\"],[4,[38,0],[[32,0,[\"insertElement\"]]],null],[4,[38,1],[[32,0,[\"destroyElement\"]]],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/user-preview-insight/template.hbs"
    }
  });
  _exports.default = _default;
});