define("portal-talentrh/pods/habitus/details/access-frequency/publications/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const POST_TYPES = {
    all: 'Todas',
    ad: 'Classificados',
    announcement: 'Comunicados',
    event: 'Eventos',
    message: 'Post',
    news: 'Notícias',
    survey: 'Enquetes'
  };
  let PublicationsComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('filter', 'posts'), _dec9 = Ember.computed('filter'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class PublicationsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "posts", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
      _initializerDefineProperty(this, "filtersModal", _descriptor4, this);
      _initializerDefineProperty(this, "filter", _descriptor5, this);
      _initializerDefineProperty(this, "openFilterModal", _descriptor6, this);
      _initializerDefineProperty(this, "showDateOnFilters", _descriptor7, this);
    }
    get filteredPosts() {
      if (this.filter === 'all') return this.posts;
      return this.posts.filter(p => p.get('type') === this.filter);
    }
    get postType() {
      if (this.filter === 'all') {
        return 'Publicações'.toLowerCase();
      }
      return POST_TYPES[this.filter].toLowerCase();
    }
    *postsTasks(startDate, endDate) {
      try {
        if (startDate && endDate) {
          startDate = `${(0, _moment.default)(startDate).format('YYYY-MM-DD')} 00:00:00`;
          endDate = `${(0, _moment.default)(endDate).format('YYYY-MM-DD')} 23:59:59`;
        } else {
          let {
            generalFilters
          } = this.args;
          startDate = `${(0, _moment.default)(generalFilters.startDate).format('YYYY-MM-DD')} 00:00:00`;
          endDate = `${(0, _moment.default)(generalFilters.endDate).format('YYYY-MM-DD')} 23:59:59`;
        }
        const posts = yield this.store.loadRecords('post', 'indicators/interactionsPerPost', {
          data: {
            startDate,
            endDate
          },
          host: 'conta'
        });
        const {
          user
        } = this.args;
        this.posts = posts.filter(p => p.get('user.id') === user.id);
        this._buildFilters();
      } catch (e) {
        Ember.debug(e);
      }
    }
    selectFilter(filter) {
      const prevFilter = this.filters.find(f => f.type === this.filter);
      this.filter = filter.type;
      const filterCardkpi = document.getElementById(`filterCardkpi${filter.type}`);
      filterCardkpi.style.backgroundColor = filter.color;
      if (prevFilter.type !== filter.type) {
        const filterCardkpiPrev = document.getElementById(`filterCardkpi${prevFilter.type}`);
        filterCardkpiPrev.style.backgroundColor = null;
      }
    }
    loadInsights(storageFilters) {
      if (storageFilters) {
        const filters = {
          ...this.filtersModal,
          startDate: storageFilters.startDate,
          endDate: storageFilters.endDate
        };
        this.filtersModal = filters;
        this.showDateOnFilters = true;
      }
      this.posts = [];
      this.postsTasks.perform(storageFilters.startDate, storageFilters.endDate);
      this.openFilterModal = false;
    }
    _buildFilters() {
      const messageList = this.posts.filter(p => p.get('type') === 'message');
      const announcementList = this.posts.filter(p => p.get('type') === 'announcement');
      const eventList = this.posts.filter(p => p.get('type') === 'event');
      const newsList = this.posts.filter(p => p.get('type') === 'news');
      const adList = this.posts.filter(p => p.get('type') === 'ad');
      const surveyList = this.posts.filter(p => p.get('type') === 'survey');
      this.filters = [{
        type: 'all',
        label: POST_TYPES['all'],
        total: this.posts.length,
        color: '#35B386 '
      }, {
        type: 'message',
        label: POST_TYPES['message'],
        total: messageList.length,
        color: '#008B71 '
      }, {
        type: 'announcement',
        label: POST_TYPES['announcement'],
        total: announcementList.length,
        color: '#3751FF'
      }, {
        type: 'event',
        label: POST_TYPES['event'],
        total: eventList.length,
        color: '#FFCC00'
      }, {
        type: 'news',
        label: POST_TYPES['news'],
        total: newsList.length,
        color: '#E86A69'
      }, {
        type: 'ad',
        label: POST_TYPES['ad'],
        total: adList.length,
        color: '#FEB267'
      }, {
        type: 'survey',
        label: POST_TYPES['survey'],
        total: surveyList.length,
        color: '#465773'
      }];
      // eslint-disable-next-line ember/no-global-jquery
      $(document).ready(function () {
        const filterCardkpi = document.getElementById('filterCardkpiall');
        filterCardkpi.style.backgroundColor = '#35B386';
        filterCardkpi.click();
      });
    }
    setBorderColorKpi(element, _ref) {
      let [color] = _ref;
      element.style.border = `2px solid ${color}`;
      element.style.color = color;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "posts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filtersModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        date: true,
        startDate: null,
        endDate: null
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openFilterModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showDateOnFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filteredPosts", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "filteredPosts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postType", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "postType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postsTasks", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "postsTasks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectFilter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadInsights", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadInsights"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setBorderColorKpi", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setBorderColorKpi"), _class.prototype)), _class));
  _exports.default = PublicationsComponent;
});