define("portal-talentrh/pods/components/input-summernote-post/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    _valueCache: null,
    _advancedEditorCache: null,
    mediaType: Ember.computed('type', function () {
      let type = this.type;
      if (type === 'event') {
        return ['link', 'video'];
      }
      return ['link', 'video', 'picture'];
    }),
    init() {
      this._super(...arguments);
      this.images = [];
    },
    didInsertElement() {
      let mediaType = this.mediaType;
      let $summernote = (0, _jquery.default)(this.element);
      let that = this;
      $summernote.summernote({
        height: 350,
        disableDragAndDrop: true,
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['height', ['height']], ['insert', mediaType]],
        lang: 'pt-BR',
        callbacks: {
          onPaste: function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
            e.preventDefault();
            document.execCommand('insertText', false, bufferText);
          },
          onChange(contents) {
            let base64Regex = /data:image\/([a-zA-Z]*);base64,([^"]*)/g;
            if (base64Regex.test(contents) && that.post && that.post.id) {
              Ember.get(that, 'swal').loading('Estamos processando sua imagem...');
              let summernoteImages = Ember.get(that, 'summernoteImages');
              let mimeTypeRegex = /data:([a-zA-Z/]*)/;
              let mimeType = contents.match(mimeTypeRegex)[1];
              let extension = mimeType.split('/')[1];
              let filename = `$${Math.random().toString(36).substring(3)}.${extension}`;
              let base64Data = contents.match(base64Regex)[0];
              let file = that._base64ToFile(base64Data, filename);
              that._uploadFiles([file]).then(response => {
                response.forEach(responseItem => {
                  contents = contents.replace(base64Data, responseItem.postImage.url);
                  summernoteImages.pushObject(responseItem.postImage.id);
                  Ember.get(that, 'swal').close();
                });
                $summernote.summernote('code', contents);
              });
            } else {
              Ember.set(that, 'value', contents);
            }
          },
          onImageUpload(files) {
            let summernoteImages = Ember.get(that, 'summernoteImages');
            let filesArray = [];
            Ember.get(that, 'swal').loading('Estamos processando sua imagem...');
            return Promise.all(Array.from(files).map(file => {
              return that._toBase64(file);
            })).then(imagesBase64 => {
              imagesBase64.forEach((file, index) => {
                summernoteImages.pushObject(file);
                filesArray.push(file);
                that.images.pushObject({
                  url: file.base64
                });
                $summernote.summernote('insertImage', file.base64, index);
              });
              Ember.get(that, 'swal').close();
            });
          }
        }
      });
      $summernote.summernote('code', this.value);
      let panels = document.querySelectorAll('.note-editor.note-frame.panel');
      if (!panels) {
        return;
      }
      for (let editor of panels) {
        let headers = editor.querySelectorAll('.modal-header');
        if (!headers) {
          return;
        }
        for (let header of headers) {
          header.classList.add('flex-row-reverse', 'align-items-center');
        }
      }
    },
    didUpdateAttrs() {
      if (!Ember.isEmpty(this.advancedEditor) && this.advancedEditor !== this._advancedEditorCache) {
        this._advancedEditorCache = this.advancedEditor;
        (0, _jquery.default)(this.element).summernote('code', this.value);
      }
      if (!this.value) {
        (0, _jquery.default)(this.element).summernote('code', this.value);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)(this.element).summernote('destroy');
    },
    _uploadFiles(files) {
      return Ember.RSVP.map(files, file => {
        var form = new FormData();
        form.append('summernote', true);
        form.append('attachment', file);
        return this.ajax.request('postimages', {
          contentType: false,
          processData: false,
          type: 'POST',
          data: form
        });
      });
    },
    _base64ToFile(dataurl, filename) {
      let arr = dataurl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    _toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          file.base64 = reader.result;
          resolve(file);
        };
        reader.onerror = error => reject(error);
      });
    }
  });
  _exports.default = _default;
});