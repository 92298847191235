define("portal-talentrh/pods/application/navbar/notification/feedback/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    link: Ember.computed('notification.{modelId,linkParams.feedback}', function () {
      let modelId = Ember.get(this, 'notification.linkParams.feedback') || Ember.get(this, 'notification.modelId');
      return `feedback/${modelId}`;
    })
  });
  _exports.default = _default;
});