define("portal-talentrh/pods/view-team-member/notes/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GpxO8vfS",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"@title\",\"@note\"],\"statements\":[[8,\"ui-modal\",[[24,\"data-backdrop\",\"static\"],[24,\"data-keyboard\",\"false\"]],[[\"@show\",\"@onClose\",\"@size\"],[true,[30,[36,0],[[32,0,[\"closeModal\"]]],null],\"lg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"Descrição\"],[13],[2,\"\\n      \"],[8,\"textarea\",[[24,0,\"form-control ui-scrollbar\"]],[[\"@placeholder\",\"@value\",\"@maxlength\"],[\"Descrição\",[32,4,[\"description\"]],5000]],null],[2,\"      \"],[10,\"p\"],[14,0,\"text-right\"],[12],[1,[30,[36,1],[[32,4,[\"description\",\"length\"]],[32,4,[\"description\",\"length\"]],\"0\"],null]],[2,\"/5000\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"button\"],[14,0,\"btn btn-outline-primary\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"closeModal\"]]],null]],[12],[2,\"Cancelar\"],[13],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,0],[[32,0,[\"save\"]]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Salvar\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/notes/form/template.hbs"
    }
  });
  _exports.default = _default;
});