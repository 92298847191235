define("portal-talentrh/pods/dashboard/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MupuUtL4",
    "block": "{\"symbols\":[\"Card\",\"option\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"font-weight-bold\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Mural\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"align-items-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Programação\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group d-flex flex-fill\"],[12],[2,\"\\n      \"],[8,\"nela-select\",[[24,0,\"w-100\"]],[[\"@selected\",\"@modelName\",\"@endpoint\",\"@allowClear\",\"@query\",\"@onChange\"],[[32,0,[\"dashboard\"]],\"dashboard\",\"/dashboards\",true,[30,[36,0],null,[[\"active\"],[true]]],[30,[36,1],[[32,0,[\"onDashboardSelected\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"title\"]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-sm btn-primary ml-2\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"onShowDashboardClick\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n        Visualizar\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/dashboard/index/template.hbs"
    }
  });
  _exports.default = _default;
});