define("portal-talentrh/pods/home/my-team/feedback-details/controller", ["exports", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeMyTeamFeedbackDetailsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'), _dec8 = Ember.computed('newPerformanceModule'), _dec9 = Ember.computed('newPerformanceModule'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class HomeMyTeamFeedbackDetailsController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "filters", _descriptor4, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor5, this);
      _initializerDefineProperty(this, "ledType", _descriptor6, this);
      _initializerDefineProperty(this, "newPerformanceModule", _descriptor7, this);
      _defineProperty(this, "ledOptions", [{
        label: 'Todos',
        value: 'all'
      }, {
        label: 'Diretos',
        value: 'direct'
      }, {
        label: 'Indiretos',
        value: 'indirect'
      }]);
      _defineProperty(this, "exportFields", [{
        field: 'fullName',
        fieldName: 'Colaborador'
      }, {
        field: 'tradingName',
        fieldName: 'Filial'
      }, {
        field: 'department',
        fieldName: 'Departamento'
      }, {
        field: 'jobRole',
        fieldName: 'Cargo'
      }, {
        field: 'jobFunction',
        fieldName: 'Função'
      }, {
        field: 'lastFeedbackDate',
        fieldName: 'Último Feedback'
      }]);
    }
    get endpoint() {
      if (this.newPerformanceModule) {
        return '/api/graphql/leader/usersWithoutFeedback/details';
      }
      return '/api/leader/getWithoutFeedback/details';
    }
    get newFeedbackUrl() {
      if (this.newPerformanceModule) {
        return `${_environment.default.appUrl.performance}feedbacks/my-team/create?appraised=`;
      }
      return `${_environment.default.appUrl.avd}feedback/new?user=`;
    }
    *initIndicator() {
      try {
        yield this.buildFilters();
      } catch (e) {
        console.log(e);
        Ember.debug(`Home :: MyTeam :: FeedBackDetails :: initIndicator`);
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.ledType) {
        filters.ledType = typeof this.ledType === 'object' ? this.ledType.value : this.ledType;
        filters.ledTypeChar = filters.ledType.charAt(0);
      }
      if (this.filtersSelected && this.filtersSelected.departments) {
        filters.departments = this.filtersSelected.departments.map(department => department.id);
      }
      if (this.filtersSelected && this.filtersSelected.jobRoles) {
        filters.jobRoles = this.filtersSelected.jobRoles.map(jobRole => jobRole.id);
      }
      if (this.filtersSelected && this.filtersSelected.jobFunctions) {
        filters.jobFunctions = this.filtersSelected.jobFunctions.map(jobFunction => jobFunction.id);
      }
      this.filters = filters;
      return filters;
    }
    onChangeLedType(type) {
      this.ledType = type;
      Ember.set(this.filtersSelected, 'ledType', type.value);
      this.buildFilters();
    }
    clearFilters() {
      this.filtersSelected = {};
      this.ledType = {
        label: 'Diretos',
        value: 'direct'
      };
      this.buildFilters();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ledType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Diretos',
        value: 'direct'
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "newPerformanceModule", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "endpoint", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "endpoint"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newFeedbackUrl", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "newFeedbackUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initIndicator", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initIndicator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeLedType", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeLedType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = HomeMyTeamFeedbackDetailsController;
});