define("portal-talentrh/pods/dashboard/show/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DashboardShowController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.bool('model.dashBoardSlides'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed.alias('permission.companyConfig.permitCompanyPhotos'), _dec14 = Ember.computed.alias('permission.companyConfig.permitMural'), _dec15 = Ember.computed.alias('model.dashBoardSlides'), _dec16 = Ember.computed('slidesAll'), _dec17 = Ember.computed('albumEnabled', 'muralEnabled'), _dec18 = Ember.computed('activeIndex', 'activeSlider', 'slides'), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class DashboardShowController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "swal", _descriptor5, this);
      _initializerDefineProperty(this, "customDashboard", _descriptor6, this);
      _initializerDefineProperty(this, "openChangeDashboard", _descriptor7, this);
      _initializerDefineProperty(this, "dashboard", _descriptor8, this);
      _initializerDefineProperty(this, "activeIndex", _descriptor9, this);
      _initializerDefineProperty(this, "activeSlider", _descriptor10, this);
      _initializerDefineProperty(this, "slideInterval", _descriptor11, this);
      _initializerDefineProperty(this, "sceneInterval", _descriptor12, this);
      _initializerDefineProperty(this, "albumEnabled", _descriptor13, this);
      _initializerDefineProperty(this, "muralEnabled", _descriptor14, this);
      _initializerDefineProperty(this, "slidesAll", _descriptor15, this);
    }
    // Multi line computed
    get slides() {
      const currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      return this.slidesAll.filter(s => s.hasExpireDate === false || s.expireDateStart <= currentDate && s.expireDateEnd >= currentDate);
    }
    get layoutName() {
      if (this.muralEnabled && this.albumEnabled) {
        // Mural e Album Habilitados
        return 'dashboard/layout/complete';
      } else if (this.muralEnabled && !this.albumEnabled) {
        // Mural Habilitado
        return 'dashboard/layout/mural';
      } else if (!this.muralEnabled && this.albumEnabled) {
        // Album Habilitado
        return 'dashboard/layout/album';
      } else {
        // Mural e Album Desabilitados
        return 'dashboard/layout/basic';
      }
    }
    get refreshTimeInSeconds() {
      const activeSlider = this.slides.toArray().get(this.activeIndex);
      return activeSlider ? activeSlider.get('refreshTimeInSeconds') : null;
    }

    // Tasks
    *onShowDashboardClick() {
      try {
        if (!this.dashboard) {
          return this.swal.warning('É necessário informar uma programação');
        }
        this.activeIndex = 0;
        const dashboard = yield this.store.findRecord('dashboard', this.dashboard.id);
        Ember.set(this, 'model', dashboard);
        this._setActiveSlider();
        this.openChangeDashboard = false;
      } catch (e) {
        return this.swal.catch(e);
      }
    }

    // Custom methods
    _controlScene() {
      function changeScene(scene, scenesTotal) {
        return scene === scenesTotal ? 1 : scene + 1;
      }
      if (this.customDashboard) {
        this._createIntervalDashboardCustom();
      } else {
        const scenesTotal = 2;
        let currentScene = 1;
        let sceneInterval = setInterval(() => {
          currentScene = changeScene(currentScene, scenesTotal);
          Array.from(this.element.getElementsByClassName('dashboard-scene')).forEach(element => {
            element.classList.remove('invisible');
            element.classList.remove('board-block-show');
            element.classList.add('board-block-hide');
          });
          Ember.run.later(() => {
            const element = this.element.querySelector(`.dashboard-scene-${currentScene}`);
            if (element) {
              element.classList.add('board-block-show');
              element.classList.remove('board-block-hide');
            }
          }, 500);
        }, 15000);
        this.sceneInterval = sceneInterval;
      }
    }
    _clearIntervalDashboardCustom() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
      }
    }
    _createIntervalDashboardCustom() {
      if (!this.refreshTimeInSeconds) {
        return;
      }
      const slideInterval = setInterval(() => {
        this._nextSlide();
      }, this.refreshTimeInSeconds * 1000);
      this.slideInterval = slideInterval;
    }
    async _nextSlide() {
      const totalSlides = this.slides.length - 1;
      this.activeIndex += 1;

      // Chega no final dos slides volta pro começo
      if (totalSlides < this.activeIndex) {
        this.activeIndex = 0;
      }
      this.activeSlider = await this.slides.toArray().get(this.activeIndex);
      this._clearIntervalDashboardCustom();
      this._createIntervalDashboardCustom();
    }
    _configureScene() {
      this._controlScene();
      this.fullscreen.on('fullscreenChange', active => {
        const content = document.querySelector('.ui-application__content');
        const navbar = document.querySelector('.ui-navbar');
        const gridContainer = document.querySelector('.grid-container');
        if (active) {
          document.body.style.overflow = 'hidden';
          const sectionGridContainer = gridContainer.querySelector('section');
          sectionGridContainer.setAttribute('style', 'min-width: 100%');
          content.classList.add('mx-0');
          navbar.classList.add('l-0');
        } else {
          document.body.style.overflow = 'auto';
          content.classList.remove('mx-0');
          navbar.classList.remove('l-0');
        }
      });
    }
    _setActiveSlider() {
      try {
        if (this.slides) {
          this.activeSlider = this.slides.firstObject;
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }

    // Actions
    onElementDestroy() {
      if (this.sceneInterval) {
        clearInterval(this.sceneInterval);
      }
      this._clearIntervalDashboardCustom();
    }
    onDashboardSelected(dashboard) {
      this.dashboard = dashboard;
    }
    toggleFullscreen() {
      this.fullscreen.toggle();
    }
    initComponent() {
      try {
        this._configureScene();
        this.dashboard = this.model;
        this._setActiveSlider();
      } catch (e) {
        return this.swal.error('Ocorreu um erro ao buscar as informações do mural');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customDashboard", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "openChangeDashboard", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dashboard", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeIndex", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "activeSlider", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "slideInterval", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "sceneInterval", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "albumEnabled", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "muralEnabled", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "slidesAll", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "slides", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "slides"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "layoutName", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "layoutName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshTimeInSeconds", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "refreshTimeInSeconds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onShowDashboardClick", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onShowDashboardClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onElementDestroy", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onElementDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDashboardSelected", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onDashboardSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleFullscreen", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFullscreen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initComponent", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "initComponent"), _class.prototype)), _class));
  _exports.default = DashboardShowController;
});