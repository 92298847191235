define("portal-talentrh/pods/company/minutes-of-meeting/edit/new-topic/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CompanyMinutesOfMeetingEditNewTopicRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        topic: this.store.createRecord('meeting-topic'),
        meeting: this.modelFor('company/minutes-of-meeting/edit')
      });
    }
    deactivate() {
      this.controller.topic.rollbackAttributes();
      this.controller.meeting.rollbackAttributes();
    }
  }
  _exports.default = CompanyMinutesOfMeetingEditNewTopicRoute;
});