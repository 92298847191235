define("portal-talentrh/pods/view-team-member/professional-profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberProfessionalProfileController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('model.user'), _dec5 = Ember.computed.alias('model.preference'), _dec6 = Ember.computed.alias('permission.accessProfile'), _dec7 = Ember.computed.alias('permission.companyConfig.showJobFunction'), _dec8 = Ember.computed.or('user.{facebookUrl,linkedinUrl,instagramUrl,twitterUrl,googleplusUrl,skype}'), _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('user.branches.length'), _dec12 = Ember.computed('user.branches.length', 'user.company.name'), _dec13 = Ember.computed('accessProfile', 'permission.accessProfile.{admin,allowHistoricalDataMyTeam,allowRegistrationDataManagement}', 'user.id', 'userSubordinatesIds'), (_class = class ViewTeamMemberProfessionalProfileController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "user", _descriptor4, this);
      _initializerDefineProperty(this, "preference", _descriptor5, this);
      _initializerDefineProperty(this, "accessProfile", _descriptor6, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor7, this);
      _initializerDefineProperty(this, "showSocialNetworks", _descriptor8, this);
      _initializerDefineProperty(this, "userSubordinatesIds", _descriptor9, this);
      _initializerDefineProperty(this, "showSalary", _descriptor10, this);
    }
    get showRegistration() {
      let registration = this.user.registration;
      return registration && registration !== '0';
    }
    get branchName() {
      let name = "";
      if (this.user.branches?.length) {
        this.user.branches.forEach((branch, index) => {
          name += (index != 0 ? " / " : "") + (branch.get('tradingName') ? branch.get('tradingName') : branch.get('name'));
        });
      } else {
        name = "Não informado";
      }
      return name;
    }
    get companyName() {
      let name = "";
      if (this.user.branches?.length) {
        this.user.branches.forEach((branch, index) => {
          name += (index != 0 ? " / " : "") + branch.get('name');
        });
      } else {
        name = this.user.company.get('name');
      }
      return name;
    }
    get birthDayFormat() {
      let {
        admin,
        allowRegistrationDataManagement,
        allowHistoricalDataMyTeam
      } = this.accessProfile;
      if (admin || allowRegistrationDataManagement) {
        return 'DD/MM/YYYY';
      }
      if (allowHistoricalDataMyTeam && this.userSubordinatesIds) {
        const isSubordinate = this.userSubordinatesIds.includes(Number(this.user.id));
        if (isSubordinate) return 'DD/MM/YYYY';
      }
      return 'DD/MM';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "preference", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accessProfile", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSocialNetworks", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "userSubordinatesIds", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showSalary", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "branchName", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "branchName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "companyName", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "companyName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "birthDayFormat", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "birthDayFormat"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberProfessionalProfileController;
});