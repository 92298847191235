define("portal-talentrh/pods/company/album/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    closePhotoPopUp() {
      const closePopButtonsElements = document.getElementsByClassName('lb-close');
      if (closePopButtonsElements.length <= 0) {
        return;
      }
      closePopButtonsElements[0].click();
    },
    beforeModel(transition) {
      this.closePhotoPopUp();
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.store.query('company-album', {
        company: this.session.company.id,
        sort: 'createdAt desc'
      });
    },
    actions: {
      refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});