define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/indicator-viewer/result-per-skill/component", ["exports", "@glimmer/component", "chart-js", "lodash"], function (_exports, _component, _chartJs, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ResultPerQuestionComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class ResultPerQuestionComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chart", _descriptor, this);
    }
    init() {
      let {
        appraised,
        appraiser,
        consensus,
        team
      } = this.args;
      let labels = [];
      let data = [];
      if (appraised) {
        let appraisedData = {
          label: 'Avaliado',
          data: [],
          borderColor: '#0263FF',
          fill: false
        };
        _lodash.default.map(appraised, function (a) {
          _lodash.default.map(a.questions, function (q) {
            if (!labels.find(a => a === q.title)) {
              labels.push(q.title);
            }
            appraisedData.data.push(Number(q.answer));
          });
        });
        data.push(appraisedData);
      }
      if (appraiser) {
        let appraiserData = {
          label: 'Avaliador',
          data: [],
          borderColor: '#32AA8F',
          fill: false
        };
        _lodash.default.map(appraiser, function (a) {
          _lodash.default.map(a.questions, function (q) {
            if (!labels.find(a => a === q.title)) {
              labels.push(q.title);
            }
            appraiserData.data.push(Number(q.answer));
          });
        });
        data.push(appraiserData);
      }
      if (consensus) {
        let consensusData = {
          label: 'Consenso',
          data: [],
          borderColor: '#8E30FF',
          fill: false
        };
        _lodash.default.map(consensus, function (a) {
          _lodash.default.map(a.questions, function (q) {
            if (!labels.find(a => a === q.title)) {
              labels.push(q.title);
            }
            consensusData.data.push(Number(q.answer));
          });
        });
        data.push(consensusData);
      }
      if (team && team.length > 0) {
        let teamData = {
          label: 'Equipe',
          data: [],
          borderColor: '#D85076',
          fill: false
        };
        _lodash.default.map(team, function (a) {
          _lodash.default.map(a.appraiserAnswer, function (n) {
            _lodash.default.map(n.questions, function (q) {
              if (!labels.find(a => a === q.title)) {
                labels.push(q.title);
              }
              if (!teamData.data[q.id]) {
                teamData.data.push(q.answer ? parseFloat(q.answer) : 0);
              } else {
                teamData.data[q.id] += q.answer ? parseFloat(q.answer) : 0;
              }
            });
          });
        });
        teamData.data = teamData.data.map(c => c = Number(parseFloat((c / team.length).toFixed(2))));
        data.push(teamData);
      }
      let arrLabels = [];
      labels.map(label => {
        arrLabels.push(label.length > 30 ? label.substr(0, 30) + '...' : label);
      });
      let indicator = {
        labels: arrLabels,
        datasets: data
      };
      if (indicator) {
        this.initChart(indicator);
      }
    }
    initChart(indicator) {
      let chart = this.chart;
      if (chart) {
        chart.data = indicator;
        chart.update();
      } else {
        let ctx = document.getElementById(`resultPerSkillChart`).getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'radar',
          responsive: false,
          data: indicator,
          options: {
            scale: {
              angleLines: {
                display: false
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 100
                //stepSize: 15
              }
            },

            tooltips: {
              callbacks: {
                title: function () {
                  return '';
                },
                label: function (tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
                }
              }
            },
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                fontSize: 14,
                fontFamily: "SourceSansPro",
                padding: 20
              }
            }
          }
        });
      }
      this.chart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = ResultPerQuestionComponent;
});