define("portal-talentrh/pods/feed/me/index/schedule/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ScheduleComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed.sort('appointments', 'appointmentSorting'), (_class = class ScheduleComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "appointmentSorting", _descriptor5, this);
      _initializerDefineProperty(this, "appointments", _descriptor6, this);
      _initializerDefineProperty(this, "tabs", _descriptor7, this);
      _initializerDefineProperty(this, "sortedAppointments", _descriptor8, this);
    }
    *loadAppointments(date) {
      try {
        const {
          userLed
        } = this.args;
        const userToLoadAppointments = userLed ? userLed.id : this.session.user.id;
        const appointments = yield this.store.loadRecords('appointment', `users/${userToLoadAppointments}/appointments`, {
          host: _environment.default.apiUrl.agenda,
          data: {
            start: {
              '>=': date.startOf('day').format('YYYY-MM-DD HH:mm:ss')
            },
            end: {
              '<=': date.endOf('day').format('YYYY-MM-DD HH:mm:ss')
            }
          }
        });
        this.appointments.addObjects(appointments.toArray());
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *loadGoogleAppointments(date) {
      try {
        const {
          userLed
        } = this.args;
        const userToLoadAppointments = userLed ? userLed.id : this.session.user.id;
        let user = this.store.peekRecord('user', userToLoadAppointments);
        if (!user) {
          user = yield this.store.findRecord('user', userToLoadAppointments);
        }
        if (!user.googleOAuth) {
          return;
        }
        let appointments = yield this.fetch.request('appointments/google', {
          host: _environment.default.apiUrl.agenda,
          data: {
            user: userToLoadAppointments,
            start: date.startOf('day').toISOString(),
            end: date.endOf('day').toISOString()
          }
        });
        if (!appointments) {
          return;
        }
        appointments = appointments.filter(appointment => {
          let exists = this.appointments.some(a => a.googleCalendarId === appointment.googleCalendarId);
          if (exists) {
            return;
          }
          appointment.start = (0, _moment.default)(appointment.start).toDate();
          return appointment;
        });
        this.appointments.addObjects(appointments);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *loadOutlookAppointments(date) {
      try {
        const {
          userLed
        } = this.args;
        const userToLoadAppointments = userLed ? userLed.id : this.session.user.id;
        let user = this.store.peekRecord('user', userToLoadAppointments);
        if (!user) {
          user = yield this.store.findRecord('user', userToLoadAppointments);
        }
        if (!user.outlookOAuth) {
          return;
        }
        let {
          appointments
        } = yield this.fetch.request('appointments/outlook', {
          host: _environment.default.apiUrl.agenda,
          data: {
            user: userToLoadAppointments,
            start: date.startOf('day').toISOString(),
            end: date.endOf('day').toISOString()
          }
        });
        if (!appointments) {
          return;
        }
        appointments = appointments.filter(appointment => {
          let exists = this.appointments.some(a => a.outlookCalendarId === appointment.outlookCalendarId);
          if (exists) {
            return;
          }
          appointment.start = (0, _moment.default)(appointment.start).toDate();
          return appointment;
        });
        this.appointments.addObjects(appointments);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    buildTabs(startOf, el) {
      if (!this.session.isAuthenticated) {
        return;
      }
      startOf = Array.isArray(el) && !el.length ? null : startOf;
      let today = (0, _moment.default)();
      let tomorrow = today.clone().add(1, 'd');
      let date = startOf || today.clone();
      let tabs = [];
      for (var i = 0; i < 4; i++) {
        let isToday = date.isSame(today, 'd');
        let isTomorrow = date.isSame(tomorrow, 'd');
        let text = date.format('ddd, DD/MM');
        if (isToday) {
          text = 'Hoje';
        } else if (isTomorrow) {
          text = 'Amanhã';
        }
        tabs.push({
          text,
          date: date.clone()
        });
        date.add(1, 'day');
      }
      this.tabs = tabs;
      this.changeTab(tabs[0]);
    }
    loadCalendar(date) {
      this.loadAppointments.perform(date);
      this.loadGoogleAppointments.perform(date);
      this.loadOutlookAppointments.perform(date);
    }
    changeTab(tab) {
      this.appointments.clear();
      this.loadCalendar(tab.date);
      this.tabs.setEach('active', false);
      Ember.set(tab, 'active', true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appointmentSorting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A(['start']);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "appointments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tabs", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "sortedAppointments", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAppointments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadGoogleAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadGoogleAppointments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadOutlookAppointments", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadOutlookAppointments"), _class.prototype)), _class));
  _exports.default = ScheduleComponent;
});