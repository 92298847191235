define("portal-talentrh/pods/view-team-member/projects/project-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+Ny18KKG",
    "block": "{\"symbols\":[\"Card\",\"@project\",\"@selectedProject\"],\"statements\":[[8,\"ui-card\",[[16,1,[31,[\"card_\",[32,2,[\"id\"]]]]],[16,0,[30,[36,1],[[30,[36,0],[[32,2,[\"id\"]],[32,3,[\"id\"]]],null],\"selected-project-card\",\"project-card\"],null]],[16,5,[32,0,[\"getStyle\"]]],[16,\"onclick\",[30,[36,2],[[32,0,[\"setSelectedProject\"]],[32,2]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"flex-wrap\"],[16,\"title\",[32,0,[\"projectNameComplete\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"img\"],[14,0,\"logo-project\"],[15,\"src\",[32,0,[\"getLogo\"]]],[15,5,[32,0,[\"getBackgroundColor\"]]],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex-fill h-50 mt-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"font-weight-bold text-secondary line-height-normal\"],[12],[2,\"\\n        \"],[1,[32,0,[\"projectName\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"small text-truncate line-height-normal mt-2\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2,[\"tasks\",\"total\"]],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"        Nenhuma tarefa\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2,[\"tasks\",\"total\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2,[\"tasks\",\"total\"]]],[2,\" tarefa\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,3],[[32,2,[\"tasks\",\"total\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2,[\"tasks\",\"total\"]]],[2,\" tarefas\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"gt\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/projects/project-item/template.hbs"
    }
  });
  _exports.default = _default;
});