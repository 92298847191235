define("portal-talentrh/pods/components/ui-info-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    showProperty: false,
    titleHint: Ember.computed('label', 'showProperty', 'title', function () {
      return this.showProperty ? `Ocultar ${this.label}` : `Exibir ${this.label}`;
    })
  });
  _exports.default = _default;
});