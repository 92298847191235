define("portal-talentrh/pods/feed/me/index/role-function/user-skills/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l2Fia/fn",
    "block": "{\"symbols\":[\"skill\",\"Card\",\"@skills\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-card\",[[24,0,\"bg-info d-inline-block m-1\"],[24,\"title\",\"Visualizar descrição da skill\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Body\"]],[[24,0,\"px-2 pb-1 pt-0 text-white cursor-pointer\"],[16,\"onclick\",[30,[36,1],[[30,[36,0],[[32,1,[\"showModal\"]],true],null]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[8,\"modal-skill\",[],[[\"@show\",\"@value\"],[[32,1,[\"showModal\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Nenhuma skill atribuída\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/role-function/user-skills/template.hbs"
    }
  });
  _exports.default = _default;
});