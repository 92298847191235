define("portal-talentrh/pods/feed/me/user-expense/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hTkSFsba",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"font-weight-bold text-secondary\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Gastos do Colaborador\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[10,\"ul\"],[14,\"role\",\"tablist\"],[14,1,\"nav-tab\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"feed.me.user-expense.extract\"]],[[\"default\"],[{\"statements\":[[2,\"        Extrato de Gastos\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"li\"],[14,0,\"nav-item bg-transparent\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",\"feed.me.user-expense.payroll-discount\"]],[[\"default\"],[{\"statements\":[[2,\"        Desconto em Folha\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/user-expense/template.hbs"
    }
  });
  _exports.default = _default;
});