define("portal-talentrh/pods/indicators/people-analytics/my-team/organizational-climate/prevailing-climate-detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsPeopleAnalyticsMyTeamOrganizationalClimatePrevailingClimateDetailRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {};
      this.controller.prevailingClimateData = Ember.A();
      this.controller.typeSelected = null;
    }
  }
  _exports.default = IndicatorsPeopleAnalyticsMyTeamOrganizationalClimatePrevailingClimateDetailRoute;
});