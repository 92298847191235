define("portal-talentrh/pods/components/home-quiz/component", ["exports", "portal-talentrh/config/environment", "ember-concurrency", "moment"], function (_exports, _environment, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['quizAnswers.length:mb-3:d-none'],
    ajax: Ember.inject.service(),
    url: `${_environment.default.appUrl.pesquisa}responder/`,
    loadQuizzes: (0, _emberConcurrency.task)(function* () {
      try {
        const quizAnswers = yield this.ajax.request('quizAnswers/pending');
        const today = (0, _moment.default)();
        const deadlineForResponses = quizAnswers.map(quizAnswer => {
          const createdAt = (0, _moment.default)(quizAnswer.createdAt);
          const daysToAnswer = quizAnswer.daysToAnswer;
          const deadlineDate = createdAt.add(daysToAnswer, 'days');
          const isWithinDeadline = !today.isAfter(deadlineDate);
          return {
            ...quizAnswer,
            deadlineDate: isWithinDeadline ? deadlineDate.format('DD/MM/YYYY') : null
          };
        });
        deadlineForResponses.map(e => {
          if (!e.daysToAnswer && !e.validity && e.surveyPeriodic) {
            return Ember.set(this, 'deadlineForResponse', 'Prazo não definido.');
          }
          if (!e.daysToAnswer && !e.surveyPeriodic) {
            return;
          }
          if (e.deadlineDate) {
            return Ember.set(this, 'deadlineForResponse', `Prazo para responder: ${e.deadlineDate}`);
          }
        });
        Ember.set(this, 'quizAnswers', quizAnswers);
      } catch (e) {
        Ember.debug(e);
      }
    }).on('didInsertElement')
  });
  _exports.default = _default;
});