define("portal-talentrh/pods/view-team-member/performance-management/goals/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QtnHLtCW",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"tab-content p-3\"],[14,1,\"tabGoals\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"tab-pane active\"],[12],[2,\"\\n    \"],[8,\"feed/me/index/goals\",[],[[\"@user\",\"@roundedOff\"],[[32,1],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/performance-management/goals/template.hbs"
    }
  });
  _exports.default = _default;
});