define("portal-talentrh/pods/notification/user-absence/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H5R6ec9+",
    "block": "{\"symbols\":[\"Avatar\",\"@click\",\"@notification\"],\"statements\":[[10,\"div\"],[14,0,\"align-items-center d-flex dad_open_chat h-vh-2 justify-content-between m-auto row w-100\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-7 d-flex align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex cursor-pointer\"],[15,\"onclick\",[30,[36,1],[[32,0],\"viewAndSetAsRead\"],null]],[12],[2,\"\\n      \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,3,[\"relatedUser\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-6\"],[16,\"alt\",[32,3,[\"relatedUser\",\"fullName\"]]]],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n\\n       \"],[10,\"div\"],[14,0,\"px-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n          \"],[10,\"strong\"],[12],[1,[32,3,[\"relatedUser\",\"fullName\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[1,[32,3,[\"message\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-3 d-flex flex-column align-items-end justify-content-between\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"mt-5\"],[12],[1,[30,[36,2],[[32,3,[\"createdAt\"]]],null]],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[14,0,\"navbar__dropdown__item__unread navbar__dropdown__item__unread\"],[14,\"title\",\"Marcar como lida\"],[15,\"onclick\",[30,[36,0],[[32,2]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"action\",\"moment-from-now\",\"isRead\",\"unless\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/notification/user-absence/template.hbs"
    }
  });
  _exports.default = _default;
});