define("portal-talentrh/pods/components/social-page/menu/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "08Kn06vR",
    "block": "{\"symbols\":[\"@link\",\"@title\",\"@icon\",\"@svg\",\"@route\",\"&attrs\"],\"statements\":[[6,[37,0],[[32,0,[\"showIf\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"li\"],[24,0,\"list-group-item bg-transparent border-0 py-2 my-1 px-0 text-truncate\"],[17,6],[12],[2,\"\\n\"],[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[16,\"data-test-item-list\",[32,2]],[16,\"title\",[32,2]]],[[\"@route\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[14,0,\"text-primary position-absolute\"],[15,\"src\",[32,4]],[14,\"width\",\"21\"],[14,\"height\",\"21\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3],\" text-primary position-absolute\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"h6\"],[14,0,\"mb-0 ml-3 pl-3 text-truncate\"],[12],[2,\"\\n          \"],[1,[32,2]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"a\"],[15,6,[32,1]],[15,\"title\",[32,2]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fa \",[32,3],\" text-primary position-absolute\"]]],[12],[13],[2,\"\\n        \"],[10,\"h6\"],[14,0,\"mb-0 ml-3 pl-3 text-truncate\"],[12],[2,\"\\n          \"],[1,[32,2]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/social-page/menu/list/item/template.hbs"
    }
  });
  _exports.default = _default;
});