define("portal-talentrh/pods/components/post-comments/comment/more-options/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    store: Ember.inject.service(),
    deleteComment: (0, _emberConcurrency.task)(function* () {
      const commentId = this.commentId;
      try {
        let {
          value
        } = yield this.swal.confirm('Tem certeza que deseja remover este comentário?');
        if (!value) {
          return;
        }
        let comment = this.store.peekRecord('comment', commentId);
        if (!comment) {
          return;
        }
        yield comment.destroyRecord();
        this.toast.success('Comentário removido');
      } catch (e) {
        this.toast.error('Estamos tendo problemas para remover seu comentário no momento, tente novamente mais tarde');
      }
    }).drop(),
    actions: {
      enableEditMode() {
        Ember.set(this, 'editMode', true);
      }
    }
  });
  _exports.default = _default;
});