define("portal-talentrh/pods/feed/me/index/goals/goal-modal/item/component", ["exports", "moment", "portal-talentrh/utils/formatDate"], function (_exports, _moment, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    permission: Ember.inject.service(),
    tagName: 'tr',
    unit: null,
    openInfo: false,
    jsonParsed: false,
    value: Ember.computed.alias('goalAchieved.value'),
    lastUpdate: Ember.computed('goalAchieved.projectDetails.length', 'goalAchieved.taskDetails.length', 'jsonParsed', function () {
      if (this.jsonParsed) {
        if (this.goalAchieved.taskDetails && this.goalAchieved.taskDetails.length) {
          return `Atualizado em ${this.goalAchieved.taskDetails[0].lastUpdate}`;
        } else if (this.goalAchieved.projectDetails && this.goalAchieved.projectDetails.length) {
          return `Atualizado em ${this.goalAchieved.projectDetails[0].lastUpdate}`;
        }
      }
      return null;
    }),
    showButton: Ember.computed.or('goalAchieved.projectDetails', 'goalAchieved.taskDetails'),
    formattedValue: Ember.computed('goalAchieved', 'goalAchieved.unit', 'unit', 'value', function () {
      if (this.goalAchieved && this.goalAchieved.unit) {
        Ember.set(this, 'unit', this.goalAchieved.unit);
      }
      if (this.unit === 'percentage') {
        return Intl.NumberFormat('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(this.value);
      }
      if (this.unit === 'monetary') {
        return Intl.NumberFormat('pt-br', {
          currency: 'BRL',
          style: 'currency',
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(this.value);
      }
      if (this.unit === 'qualitative') {
        return this.value === 'S' ? 'Atingiu' : 'Não atingiu';
      }
      if (this.unit === 'hours') {
        let str = '';
        let duration = _moment.default.duration(this.value, 'minutes');
        let hours = parseInt(duration.as('hours'));
        if (hours) {
          str += `${hours}h `;
        }
        if (duration.get('minutes')) {
          str += `${duration.get('minutes')}min`;
        }
        if (!str) {
          return 'Não informado';
        }
        return str;
      }
      return this.value;
    }),
    actions: {
      formatData() {
        if (this.goalAchieved && !this.goalAchieved.jsonParsed) {
          if (this.goalAchieved.projectDetails) {
            Ember.set(this, 'goalAchieved.projectDetails', JSON.parse(this.goalAchieved.projectDetails));
          }
          if (this.goalAchieved.taskDetails) {
            Ember.set(this, 'goalAchieved.taskDetails', JSON.parse(this.goalAchieved.taskDetails));
          }
          if (this.goalAchieved.achieveDate) {
            const date = (0, _formatDate.default)(this.goalAchieved.achieveDate);
            Ember.set(this, 'goalAchieved.achieveDate', date);
          }
          Ember.set(this, 'jsonParsed', true);
          Ember.set(this, 'goalAchieved.jsonParsed', true);
        }
      },
      showInfo() {
        Ember.set(this, 'openInfo', !this.openInfo);
      }
    }
  });
  _exports.default = _default;
});