define("portal-talentrh/pods/feed/me/index/goals/explanation/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ExplanationComponent = (_dec = Ember._tracked, _dec2 = Ember.computed('step', 'steps'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ExplanationComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "step", _descriptor, this);
      _defineProperty(this, "steps", [{
        body: `
      <div>
        <h6 class="font-weight-bold text-primary">Como calculamos o percentual das suas metas</h6>
        <div>O percentual da sua meta é calculado com base nas seguintes informações:</div>
        <p>
          <ul>
            <li>valor da meta;</li>
            <li>valor atingido na última atualização; </li>
            <li style="text-align: left;">indicador do resultado esperado, que são: Maior ou igual, Menor ou igual, Maior que, Menor que, Entre dois valores e Qualitativas.</li>
          </ul>
        </p>
      </div>
      `
      }, {
        body: `
      <div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Maior ou igual</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve ser Maior ou Igual ao valor esperado.</p>
        <p>
          <div>O percentual da sua meta é calculado da seguinte forma:</div>
          <div><b>(</b>Valor atingido <b>/</b> Meta<b>) *100</b></div>
        </p>
        <p>
          <div><b>Exemplo:</b></div>
          <div>Valor esperado (Meta): 100</div>
          <div>Valor atingido pelo colaborador: 80</div>
        </p>
        <div>A partir destas informações, se o resultado esperado for Maior ou igual, o cálculo do percentual será obtido aplicando a seguinte fórmula:</div>
        <p><img style="max-width:100%; width: auto;" src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/Group_1.png"></p>
      </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Menor ou igual</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve ser Menor ou Igual ao valor esperado.</p>
        <p>
          <div>O percentual da sua meta é calculado da seguinte forma:</div>
          <div><b>(</b>Meta <b>/</b> Valor atingido<b>) *100</b></div>
        </p>
        <p>
          <div><b>Exemplo:</b></div>
          <div>Valor esperado (Meta): 100</div>
          <div>Valor atingido pelo colaborador: 80</div>
        </p>
        <div>A partir destas informações, se o resultado esperado for Menor ou igual, o cálculo do percentual será obtido aplicando a seguinte fórmula:</div>
        <p><img style="max-width:100%; width: auto;" src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/Group_3.svg"></p>
        <p style="line-height:normal;"><small><b>* O percentual final sempre será 100%, mesmo que tenha conseguido um valor atingido superior ao valor esperado.</b></small></p>
        </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Maior que</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve ser Maior que ao valor esperado.</p>
        <p>
          <div>O percentual da sua meta é calculado da seguinte forma:</div>
          <div><b>(</b>Valor atingido <b>/</b> Meta<b>) *100</b></div>
        </p>
        <p>
          <div><b>Exemplo:</b></div>
          <div>Valor esperado (Meta): 100</div>
          <div>Valor atingido pelo colaborador: 80</div>
        </p>
        <p>A partir destas informações, se o resultado esperado for Maior ou igual, o cálculo do percentual será obtido aplicando a seguinte fórmula:</p>
        <p><img style="max-width:100%; width: auto;" src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/Group_1.png"></p>
        <p style="line-height:normal;"><small><b>* Quando o valor atingido for exatamente o valor da meta, o percentual será de 99% pois a meta para ser considerada atingida, deve ser maior que o valor esperado.</b></small></p>
      </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Menor que</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve ser Menor que o valor esperado.</p>
        <p>
          <div>O percentual da sua meta é calculado da seguinte forma:</div>
          <div><b>(</b>Meta <b>/</b> Valor atingido<b>) *100</b></div>
        </p>
        <p>
          <div><b>Exemplo:</b></div>
          <div>Valor esperado (Meta): 100</div>
          <div>Valor atingido pelo colaborador: 80</div>
        </p>
        <p>A partir destas informações, se o resultado esperado for Maior ou igual, o cálculo do percentual será obtido aplicando a seguinte fórmula:</p>
        <p><img style="max-width:100%; width: auto;" src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/Group_3.svg"></p>
        <p style="line-height:normal;"><small><b>* Quando o valor atingido for exatamente o valor da meta, o percentual será de 99% pois a meta para ser considerada atingida, deve ser menor que o valor esperado.</b></small></p>
      </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Entre dois valores</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve estar entre os dois valores informados.</p>
        <p>Para essa meta não existe percentual, o atingimento dela acontece quando o valor atingido fica entre os dois valores esperados.</p>
        <p>No lugar do percentual é mostrado na barra:</p>
        <p>
          <b>
            <ul style="line-height: 1.0;">
              <li style="line-height: 1.0;">Meta atingida </li>
              <li style="line-height: 1.0;">Meta não atingida</li>
            </ul
          </b>
        </p>
      </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Cálculo das metas Qualitativas</h6>
        <p>Para sua meta ser considerada atingida, seu resultado deve indicar atingiu.</p>
        <p>Para essa meta não existe percentual, o atingimento dela acontece quando é indicado como atingiu no valor esperado.</p>
        <p>No lugar do percentual é mostrado na barra:</p>
        <p>
          <b>
            <ul style="line-height: 1.0;">
              <li style="line-height: 1.0;">Meta atingida </li>
              <li style="line-height: 1.0;">Meta não atingida</li>
            </ul
          </b>
        </p>
      </div>`
      }, {
        body: `<div>
        <h6 class="font-weight-bold text-primary">Acesso ao tutorial</h6>
        <p>Surgiu alguma dúvida? Você pode acessar o tutorial novamente no ícone de informações  <i class="far fa-info-circle text-primary" title="Informações"></i>.

        </p>
      </div>`
      }]);
    }
    get stepSelected() {
      return this.steps[this.step].body;
    }
    nextStep() {
      const {
        closeModalExplanation
      } = this.args;
      this.step = this.step + 1;
      if (this.step > 7) {
        this.step = 0;
        closeModalExplanation();
      }
    }
    previousStep() {
      this.step = this.step - 1;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "step", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "stepSelected", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "stepSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextStep", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "nextStep"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previousStep", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "previousStep"), _class.prototype)), _class));
  _exports.default = ExplanationComponent;
});