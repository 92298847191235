define("portal-talentrh/pods/forum/index/category-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    lastTopic: null,
    didInsertElement() {
      this._super(...arguments);
      const topics = this.store.peekAll('topic').sortBy('updatedAt', 'DESC');
      topics.forEach(topic => {
        if (Number(Ember.get(topic, 'category.id')) === Number(this.category.id)) {
          Ember.set(this, 'lastTopic', topic);
        }
      });
    },
    // Computed
    canManage: Ember.computed('category.user.id', 'permission.admin', 'session.user.id', function () {
      return Ember.get(this, 'permission.admin') || Ember.get(this, 'session.user.id') === parseInt(Ember.get(this, 'category.user.id'));
    }),
    topicLabel: Ember.computed('category.topics.length', function () {
      let l = this.category.topics.length;
      let labels = {
        0: 'Não há tópicos',
        1: '1 tópico'
      };
      return labels[l] || `${l} tópicos`;
    })
  });
  _exports.default = _default;
});