define("portal-talentrh/pods/feed/me/payroll-loan/edit-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6FlWj+9M",
    "block": "{\"symbols\":[],\"statements\":[[8,\"feed/me/payroll-loan/form-management-data\",[],[[\"@payrollLoanUser\",\"@editMode\"],[[32,0,[\"model\"]],true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/payroll-loan/edit-account/template.hbs"
    }
  });
  _exports.default = _default;
});