define("portal-talentrh/pods/human-resources/import-receipt/controller", ["exports", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HumanResourcesImportReceiptController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed('filtersSelected.branches.@each.id'), _dec13 = Ember.computed('filtersSelected.jobRoles.@each.id'), _dec14 = Ember._action, (_class = class HumanResourcesImportReceiptController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "permission", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "advancedFilter", _descriptor4, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor5, this);
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      _initializerDefineProperty(this, "filesWithUserNameFilter", _descriptor7, this);
      _initializerDefineProperty(this, "led", _descriptor8, this);
      _initializerDefineProperty(this, "receipts", _descriptor9, this);
      _initializerDefineProperty(this, "receiptSelected", _descriptor10, this);
      _initializerDefineProperty(this, "files", _descriptor11, this);
    }
    // Multi Line computed
    get selectedBranchesIds() {
      if (this.filtersSelected && this.filtersSelected.branches && this.filtersSelected.branches.length) {
        return this.filtersSelected.branches.map(b => b.id);
      }
      return null;
    }
    get selectedJobRoleIds() {
      if (this.filtersSelected && this.filtersSelected.jobRoles && this.filtersSelected.jobRoles.length) {
        return this.filtersSelected.jobRoles.map(b => b.id);
      }
      return null;
    }
    *loadReceipts() {
      try {
        this.receipts = null;
        this.receiptSelected = null;
        const receipts = yield this.store.loadRecords('import-receipt', '/import-receipt/people-management', {
          data: this.filters
        });
        this.receipts = receipts;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *buildFiltersAndFindReceipts() {
      const filters = {};
      if (this.led && this.led.length) {
        filters.users = this.led.map(u => u.id);
      }
      if (this.filtersSelected?.branches && this.filtersSelected.branches.length) {
        filters.branches = this.filtersSelected.branches.map(b => b.id);
      }
      if (this.filtersSelected?.jobRoles && this.filtersSelected.jobRoles.length) {
        filters.jobRoles = this.filtersSelected.jobRoles.map(jr => jr.id);
      }
      if (this.filtersSelected?.jobFunctions && this.filtersSelected.jobFunctions.length) {
        filters.jobFunctions = this.filtersSelected.jobFunctions.map(jf => jf.id);
      }
      if (this.filtersSelected?.departments && this.filtersSelected.departments.length) {
        filters.departments = this.filtersSelected.departments.map(d => d.id);
      }
      this.filters = filters;
      yield this.loadReceipts.perform();
    }
    *changeUserNameFilter() {
      yield (0, _emberConcurrency.timeout)(300);
      let files = this.files;
      if (this.userNameFilter) {
        files = files.filter(file => `${file.user.get('fullName')}`.toLowerCase().includes(this.userNameFilter.toLowerCase()));
      }
      this.filesWithUserNameFilter = files;
    }
    toggleAdvancedFilter() {
      this.receipts = null;
      this.receiptSelected = null;
      this.led = null;
      this.toggleProperty('advancedFilter');
    }
    *onChangeCompetence(competence) {
      try {
        this.files = [];
        this.receiptSelected = competence;
        yield this.getFiles.perform();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *getFiles() {
      try {
        const files = yield this.store.loadRecords('import-receipt-file', `/import-receipt/${this.receiptSelected.id}/files/people-management`, {
          data: this.filters
        });
        this.files = files.length ? files : [];
        yield this.changeUserNameFilter.perform();
      } catch (e) {
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "advancedFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filesWithUserNameFilter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "led", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "receipts", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "receiptSelected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedBranchesIds", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectedBranchesIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedJobRoleIds", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "selectedJobRoleIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadReceipts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadReceipts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFiltersAndFindReceipts", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "buildFiltersAndFindReceipts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeUserNameFilter", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "changeUserNameFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAdvancedFilter", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdvancedFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCompetence", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCompetence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFiles", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getFiles"), _class.prototype)), _class));
  _exports.default = HumanResourcesImportReceiptController;
});