define("portal-talentrh/pods/home/human-resources/late-task/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H0mO9uFv",
    "block": "{\"symbols\":[\"@latetask\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-180\"],[12],[2,\"\\n    \"],[1,[32,1,[\"task\",\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-180\"],[12],[2,\"\\n    \"],[1,[32,1,[\"project\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-180\"],[12],[2,\"\\n    \"],[1,[32,1,[\"userResponsibleName\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-180\"],[12],[2,\"\\n    \"],[1,[32,1,[\"initialDate\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-180\"],[12],[2,\"\\n    \"],[1,[32,1,[\"conclusionDate\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"text-truncate min-width-90\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[30,[36,1],[\"projetos\",[30,[36,0],[\"project/\",[32,1,[\"project\",\"id\"]],\"/task/\",[32,1,[\"task\",\"id\"]]],null]],null]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"btn btn-sm ml-auto\"],[14,\"title\",\"Ver tarefa\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fas fa-external-link text-primary\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"app-url\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/home/human-resources/late-task/item/template.hbs"
    }
  });
  _exports.default = _default;
});