define("portal-talentrh/pods/components/scroll-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'tab-scroll',
    actions: {
      toRight() {
        document.getElementById('nav-tab').scrollLeft += this.element.querySelector('.nav-item').offsetWidth;
      },
      toLeft() {
        document.getElementById('nav-tab').scrollLeft -= this.element.querySelector('.nav-item').offsetWidth;
      }
    }
  });
  _exports.default = _default;
});