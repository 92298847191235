define("portal-talentrh/pods/components/info-block/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'ui-block-content border-bottom pt-0'
  });
  _exports.default = _default;
});