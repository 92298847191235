define("portal-talentrh/pods/components/post-filter-dropdown/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kOLZIv1k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-truncate\"],[15,\"title\",[34,0]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[[34,2],\" fa-sm mr-2 text-\",[34,1]]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\" \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"color\",\"icon\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-filter-dropdown/item/template.hbs"
    }
  });
  _exports.default = _default;
});