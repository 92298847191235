define("portal-talentrh/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  async function _default(server) {
    const company = server.create('company', {
      id: 12
    });
    server.create('company-configuration', {
      company
    });
    server.createList('branch', 3);
    const lider = server.create('user', {
      id: 434,
      appraisedBy: server.create('user', {
        company
      }),
      company
    });
    server.create('user', {
      firstName: 'Colaborador',
      lastName: 'Liderado',
      id: 435,
      appraisedBy: lider,
      company
    });
  }
});