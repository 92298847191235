define("portal-talentrh/utils/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripHTML;
  /*
   *  Uso: import stripHTML from 'portal-talentrh/utils/stripHTML';
   */
  function stripHTML() {
    let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let breakLines = arguments.length > 1 ? arguments[1] : undefined;
    if (breakLines) {
      str = str.replace(/<\/p>/g, '<br>');
      let i = str.indexOf('<br>');
      if (i > 0) {
        str = str.slice(0, i);
      }
    }
    return str.replace(/<.*?>/g, '');
  }
});