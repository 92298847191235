define("portal-talentrh/models/post", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    archived: (0, _model.attr)('boolean'),
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    complained: (0, _model.attr)('boolean'),
    ended: (0, _model.attr)('boolean'),
    finalDate: (0, _model.attr)('date'),
    initialDate: (0, _model.attr)('date'),
    link: (0, _model.attr)('string'),
    mentions: (0, _model.attr)('raw'),
    nelaContent: (0, _model.attr)('boolean', {
      defaultsTo: false
    }),
    postStatus: (0, _model.attr)('string'),
    postTemplateId: (0, _model.attr)('number'),
    priority: (0, _model.attr)('boolean'),
    priorityUntil: (0, _model.attr)('date'),
    showComments: (0, _model.attr)('boolean'),
    sold: (0, _model.attr)('boolean'),
    title: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    //TIPO DO POST: mensagem, comunicado, noticia ou anũncio classificado
    updatedAt: (0, _model.attr)('date'),
    usersLiked: (0, _model.attr)('raw'),
    value: (0, _model.attr)('number'),
    isSharedPost: (0, _model.attr)('boolean'),
    sharedPost: (0, _model.attr)('number'),
    // Post compartilhado
    sharedPosts: (0, _model.attr)('number'),
    totalSharings: (0, _model.attr)('number'),
    totalComments: (0, _model.attr)('number'),
    totalLikes: (0, _model.attr)('number'),
    totalInteractions: (0, _model.attr)('number'),
    // Associations
    company: (0, _model.belongsTo)('company'),
    postTag: (0, _model.belongsTo)('post-tag'),
    user: (0, _model.belongsTo)('user'),
    // USUARIO QUE POSTOU
    userGroup: (0, _model.belongsTo)('userGroup'),
    attachments: (0, _model.hasMany)('post-attachment'),
    comments: (0, _model.hasMany)('comment', {
      async: true
    }),
    confirmedPresences: (0, _model.hasMany)('user', {
      inverse: 'confirmedEvents'
    }),
    images: (0, _model.hasMany)('post-image'),
    videos: (0, _model.hasMany)('post-video'),
    usersFavorited: (0, _model.hasMany)('user', {
      inverse: 'favoritedPosts'
    }),
    // usersLiked: hasMany('user'),
    usersRelated: (0, _model.hasMany)('user'),
    branches: (0, _model.hasMany)('branch', {
      inverse: null
    }),
    // Computed Properties
    endDate: Ember.computed('finalDate', function () {
      let mDate = (0, _moment.default)(this.finalDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    endTime: Ember.computed('finalDate', function () {
      let mDate = (0, _moment.default)(this.finalDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    }),
    startDate: Ember.computed('initialDate', function () {
      let mDate = (0, _moment.default)(this.initialDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    startTime: Ember.computed('initialDate', function () {
      let mDate = (0, _moment.default)(this.initialDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    })
  });
  _exports.default = _default;
});