define("portal-talentrh/pods/components/indicator-viewer/performance/expired-or-canceled-by-appraiser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iMDrNAh9",
    "block": "{\"symbols\":[\"Card\",\"@indicator\"],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,4],[[30,[36,3],[[32,1,[\"Header\"]],\"expected `Card.Header` to be a contextual component but found a string. Did you mean `(component Card.Header)`? ('portal-talentrh/pods/components/indicator-viewer/performance/expired-or-canceled-by-appraiser/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"Finalizadas e canceladas por avaliador responsável\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"Body\"]],\"expected `Card.Body` to be a contextual component but found a string. Did you mean `(component Card.Body)`? ('portal-talentrh/pods/components/indicator-viewer/performance/expired-or-canceled-by-appraiser/template.hbs' @ L4:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"canvas\"],[24,1,\"expiredOrCanceledByAppraiserChart\"],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"init\"]]],null]],null],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"text-center mt-5 mb-5 h3 \",[30,[36,2],[[32,2,[\"loaded\"]],\"d-none\"],null]]]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"if\",\"-assert-implicit-component-helper-argument\",\"component\",\"ui-card\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/performance/expired-or-canceled-by-appraiser/template.hbs"
    }
  });
  _exports.default = _default;
});