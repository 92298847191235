define("portal-talentrh/models/user-group", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'O grupo deve ter um nome'
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    active: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    archived: (0, _model.attr)('boolean'),
    chatOnlyForAdmin: (0, _model.attr)('boolean'),
    postOnlyForAdmin: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    imageUrl: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    public: (0, _model.attr)('boolean'),
    requireChatMessageConfirmation: (0, _model.attr)('boolean'),
    isChat: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    // Associations
    company: (0, _model.belongsTo)('company'),
    files: (0, _model.hasMany)('group-attachment', {
      inverse: 'userGroup'
    }),
    posts: (0, _model.hasMany)('post'),
    user: (0, _model.belongsTo)('user'),
    users: (0, _model.hasMany)('users'),
    admins: (0, _model.hasMany)('users', {
      inverse: 'userGroupsAdmin'
    }),
    // Computed Properties
    avatar: Ember.computed('imageUrl', function () {
      if (!this.imageUrl) {
        return '/ui-nela/images/group-default.png';
      }
      return this.imageUrl;
    })
  });
  _exports.default = _default;
});