define("portal-talentrh/pods/application/route", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.computed.alias('permission.routeHome'), _dec12 = Ember.computed.alias('router.currentURL'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ApplicationRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "absentUsers", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
      _initializerDefineProperty(this, "groups", _descriptor3, this);
      _initializerDefineProperty(this, "moment", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "router", _descriptor6, this);
      _initializerDefineProperty(this, "session", _descriptor7, this);
      _initializerDefineProperty(this, "swal", _descriptor8, this);
      _initializerDefineProperty(this, "voorsChat", _descriptor9, this);
      _initializerDefineProperty(this, "userCookie", _descriptor10, this);
      _initializerDefineProperty(this, "routeHome", _descriptor11, this);
      _initializerDefineProperty(this, "currentUrl", _descriptor12, this);
    }
    loadChatScript() {
      return new Promise(resolve => {
        try {
          let url = _environment.default.chatWidgetUrl + `?v=${new Date().getTime()}`;
          var s = document.createElement('script');
          s.setAttribute('src', url);
          s.setAttribute('id', 'chat-script');
          s.onload = resolve;
          s.onerror = resolve;
          document.head.appendChild(s);
        } catch (error) {
          resolve();
        }
      });
    }
    beforeModel() {
      this.moment.setLocale('pt-br');
      return this.loadChatScript();
    }
    model() {
      if (this.controller) {
        this.controller.willChangeRouteShortcut(this.router.currentRouteName);
      }
      this.groups.loadList();
      this.absentUsers.load.perform();
      return this.permission.load.perform(false);
    }
    async afterModel(_ref) {
      let {
        companyConfig
      } = _ref;
      if (!this.session.isAuthenticated) {
        return;
      }
      const companyName = this.session.company.name || 'Indefinida';
      const jamRelay = document.querySelector('jam-relay');
      if (jamRelay) {
        jamRelay.remove();
      }

      // Remove o backup da session (login do superUser) caso seja do mesmo usuário logado
      if (this.session?.user.id === this.session.data?.keybkp?.tokenData?.user.id) {
        this.session.set('data.keybkp', undefined);
      }
      await this.userCookie.handle();
      if (_environment.default.environment !== 'test') {
        gtag('config', 'UA-136622007-1', {
          user_id: this.session.user.id
        });
        gtag('event', 'set_company', {
          event_label: companyName
        });
      }
      window.companyName = companyName;
      if (companyConfig && !companyConfig.accountIsActive) {
        this.router.transitionTo('errors.inactiveAccount');
      }
      if (this.permission.isLoadedFromLogin || window.location.pathname == '/') {
        this.router.transitionTo(this.routeHome);
      }
    }
    loading(transition /*, originRoute*/) {
      let controller = this.controllerFor('application');
      Ember.set(controller, 'currentlyLoading', true);
      transition.promise.finally(function () {
        Ember.set(controller, 'currentlyLoading', false);
      });
    }
    error(adapterError /*, transition*/) {
      let status = 0;
      if (adapterError && adapterError.errors && adapterError.errors[0]) {
        status = adapterError.errors[0].status || 0;
      }
      Ember.debug(`ApplicationRoute :: error :: ${adapterError}`);
      switch (parseInt(status)) {
        case 400:
          if (adapterError.errors[0] && adapterError.errors[0].detail) {
            this.swal.fire('Ops!', adapterError.errors[0].detail, 'error');
          } else {
            this.swal.error('Aconteceu um erro inesperado por favor tente novamente mais tarde!');
          }
          break;
        case 403:
          this.swal.catch(adapterError);
          this.router.transitionTo('errors.forbidden');
          break;
        case 404:
          this.router.transitionTo('errors.not-found');
          break;
        case 500:
          this.swal.error('Aconteceu um erro inesperado por favor tente novamente mais tarde! (500)');
          this.router.transitionTo('errors.not-found');
          break;
        case 0:
          this.router.transitionTo('errors.unexpected');
          break;
      }
    }
    reloadRoute() {
      this.refresh();
    }
    willTransition(_ref2) {
      let {
        targetName
      } = _ref2;
      this.controller.willChangeRouteShortcut(targetName);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "absentUsers", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "groups", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "moment", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "voorsChat", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userCookie", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "routeHome", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentUrl", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadRoute", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "reloadRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});