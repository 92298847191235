define("portal-talentrh/pods/components/input-summernote-forum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ghyt92tg",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/input-summernote-forum/template.hbs"
    }
  });
  _exports.default = _default;
});