define("portal-talentrh/pods/components/post-edit-images-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4yJYfJlH",
    "block": "{\"symbols\":[\"image\",\"@post\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"images\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 my-2\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"nela-post-image-edit__body\"],[12],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[32,1,[\"url\"]]],[14,0,\"nela-post-image-edit__body__image\"],[15,\"alt\",[32,0,[\"post\",\"title\"]]],[12],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"nela-post-image-edit__remove\"],[14,\"title\",\"Clique para remover\"],[15,\"onclick\",[30,[36,2],[[35,1],[32,1]],null]],[14,\"role\",\"button\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-times fa-2x\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,0,[\"images\",\"length\"]],[32,0,[\"maxImages\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-lg-3 col-md-4 my-2\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"nela-post-image-edit__input\"],[15,\"onclick\",[30,[36,0],[[32,0],\"click\"],null]],[14,\"title\",\"Adicionar imagens\"],[14,\"role\",\"button\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Adicionar imagem\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,7],null,[[\"id\",\"class\",\"post\"],[\"post-image-upload\",\"d-none\",[32,2]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"removeImage\",\"perform\",\"-track-array\",\"each\",\"lt\",\"if\",\"post-edit-image-upload\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-edit-images-select/template.hbs"
    }
  });
  _exports.default = _default;
});