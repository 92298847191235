define("portal-talentrh/pods/feed/me/index/welcome/skeleton/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "F5JpX4UX",
    "block": "{\"symbols\":[\"Skeleton\"],\"statements\":[[8,\"ui-skeleton\",[[24,0,\"card-body d-flex py-4 align-items-center\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Avatar\"]],[[24,0,\"avatar-3\"]],[[],[]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-4 w-50\"],[12],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-55\"]],[[\"@size\"],[\"lg\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ml-auto w-25\"],[12],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[[24,0,\"w-50 float-right\"]],[[\"@size\"],[\"sm\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/welcome/skeleton/template.hbs"
    }
  });
  _exports.default = _default;
});