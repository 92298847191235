define("portal-talentrh/pods/components/widgets/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GP3pU8kK",
    "block": "{\"symbols\":[\"@closeWidget\",\"@hasMore\",\"@itemsPerPage\",\"@hasPaging\",\"@idElement\",\"&default\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3 group container-fluid width-widget\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-link text-dark position-absolute r-1 t-1 opacity-0 group-hover:opacity-10\"],[14,\"title\",\"Ocultar painel até o próximo login\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,1]],null]],[14,4,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,6,[[30,[36,3],null,[[\"Header\",\"Slide\"],[[30,[36,2],[\"ui-card/header\"],null],[30,[36,2],[\"widgets/item/slide\"],[[\"idElement\",\"hasPaging\",\"itemsPerPage\",\"hasMore\"],[[32,5],[32,4],[32,3],[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/widgets/item/template.hbs"
    }
  });
  _exports.default = _default;
});