define("portal-talentrh/pods/components/indicator-viewer/climate/amount-of-feelings-by-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "P3M6d94A",
    "block": "{\"symbols\":[\"Card\",\"@indicator\"],\"statements\":[[6,[37,6],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,5],[[30,[36,4],[[32,1,[\"Header\"]],\"expected `Card.Header` to be a contextual component but found a string. Did you mean `(component Card.Header)`? ('portal-talentrh/pods/components/indicator-viewer/climate/amount-of-feelings-by-type/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"Sentimentos por Tipo\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"Body\"]],\"expected `Card.Body` to be a contextual component but found a string. Did you mean `(component Card.Body)`? ('portal-talentrh/pods/components/indicator-viewer/climate/amount-of-feelings-by-type/template.hbs' @ L4:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"canvas\"],[24,1,\"amount-of-feelings-by-type\"],[4,[38,2],[[30,[36,1],[[32,0],[35,0]],null]],null],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"text-center mt-5 mb-5 h3 \",[30,[36,3],[[32,2,[\"loaded\"]],\"d-none\"],null]]]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"init\",\"action\",\"did-insert\",\"if\",\"-assert-implicit-component-helper-argument\",\"component\",\"ui-card\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/climate/amount-of-feelings-by-type/template.hbs"
    }
  });
  _exports.default = _default;
});