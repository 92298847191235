define("portal-talentrh/pods/habitus/details/feedback/feedback-item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const MAX_OBJECTIVE_LENGTH = 100;
  const FEEDBACK_INFO = {
    0: {
      text: 'Corretivo',
      icon: 'far fa-thumbs-down fa-2x text-primary'
    },
    1: {
      text: 'Positivo',
      icon: 'far fa-thumbs-up fa-2x text-primary'
    },
    2: {
      text: 'Tarefa',
      icon: 'far fa-tasks fa-2x text-primary'
    },
    3: {
      text: 'Outros',
      icon: 'far fa-comment-minus fa-2x text-primary'
    },
    4: {
      text: 'Geral',
      icon: 'far fa-comment-smile fa-2x text-primary'
    }
  };
  let FeedbackItemComponent = (_dec = Ember.computed('args.feedback.objective.length'), _dec2 = Ember.computed('args.feedback.type'), (_class = class FeedbackItemComponent extends _component.default {
    get showMoreIsNeeded() {
      return this.args.feedback.objective.length > MAX_OBJECTIVE_LENGTH;
    }
    get feedbackInfo() {
      const type = this.args.feedback.type;
      return FEEDBACK_INFO[type];
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "showMoreIsNeeded", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showMoreIsNeeded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "feedbackInfo", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "feedbackInfo"), _class.prototype)), _class));
  _exports.default = FeedbackItemComponent;
});