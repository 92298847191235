define("portal-talentrh/pods/human-resources/chat-history/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    fetch: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Default values
    isListLoaded: true,
    endDate: null,
    initialDate: null,
    // Single line computed properties
    disableGroup: Ember.computed.equal('type', 'direct'),
    messages: Ember.A(),
    // Multiline computed properties
    typeOptions: Ember.A([{
      label: 'Todas',
      value: ''
    }, {
      label: 'Direta',
      value: 'direct'
    }, {
      label: 'Em grupo',
      value: 'group'
    }]),
    typeSelected: Ember.computed('type', function () {
      return this.typeOptions.findBy('value', this.type || '');
    }),
    print: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.setProperties(this, {
          showResults: false,
          printing: true
        });
        let data = this.filters;
        data.print = 1;
        const report = yield this.fetch.file('chat/administration/search', {
          data,
          limit: 50
        });
        if (/Android/i.test(navigator.userAgent)) {
          this.fileDownload.downloadFile(report, 'cartão-ponto.pdf');
        } else {
          Ember.set(this, 'pdf', report);
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      filter() {
        if (!this.from && !this.to) {
          return this.swal.warning('Informe o remetente ou o destinatário.');
        }
        Ember.set(this, 'showResults', false);
        let filters = {
          print: null
        };
        if (this.initialDate) {
          filters.initialDate = (0, _moment.default)(this.initialDate).format('YYYY-MM-DD HH:mm');
        }
        if (this.endDate) {
          filters.endDate = (0, _moment.default)(this.endDate).endOf('day').format('YYYY-MM-DD HH:mm');
        }
        if (this.type) {
          filters.type = this.type;
        }
        if (this.group) {
          filters.group = this.group.id;
        }
        if (this.from) {
          filters.from = this.from.id;
        }
        if (this.to) {
          filters.to = this.to.id;
        }
        if (this.content) {
          filters.content = this.content;
        }
        Ember.setProperties(this, {
          filters,
          showResults: true,
          pdf: null,
          printing: false
        });
      },
      resetFilters() {
        Ember.setProperties(this, {
          content: null,
          endDate: null,
          filters: {},
          from: null,
          group: null,
          initialDate: null,
          to: null,
          type: null,
          pdf: null,
          printing: false
        });
      }
    }
  });
  _exports.default = _default;
});