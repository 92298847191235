define("portal-talentrh/pods/components/block-indicator-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gi36DS+7",
    "block": "{\"symbols\":[\"@value\",\"&attrs\",\"@isLoaded\",\"@name\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card block-indicator-info\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"friend-item rounded-lg overflow-hidden\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"friend-header-thumb\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"menu-block-cover\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"friend-item-content position-relative text-center px-4 pb-4\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"author-thumb\"],[12],[2,\"\\n          \"],[10,\"p\"],[14,0,\"indicator-number\"],[12],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[8,\"number-incrementer\",[],[[\"@targetValue\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"indicator-name\"],[12],[1,[32,4]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/block-indicator-info/template.hbs"
    }
  });
  _exports.default = _default;
});