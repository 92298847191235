define("portal-talentrh/pods/profile/show/team/leds/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ProfileShowTeamLedsRoute = (_dec = Ember._action, (_class = class ProfileShowTeamLedsRoute extends Ember.Route {
    model(params, transition) {
      let {
        userId
      } = transition.to.queryParams;
      if (!userId || !transition.to.queryParams) {
        this.transitionTo('profile.show.team');
      }
      return this.store.peekRecord('user', userId);
    }
    refreshInfo() {
      this.controllerFor('profile.show.team').refreshInfoHierarchy();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refreshInfo", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "refreshInfo"), _class.prototype)), _class));
  _exports.default = ProfileShowTeamLedsRoute;
});