define("portal-talentrh/pods/components/feed/post-comments/comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z5DxKI8U",
    "block": "{\"symbols\":[\"@post\",\"@comment\",\"@onlyShow\",\"&default\"],\"statements\":[[18,4,[[30,[36,6],null,[[\"moreOptions\",\"body\",\"likeBar\",\"replyField\",\"commentReplies\"],[[30,[36,4],[\"feed/post-comments/comment/more-options\"],[[\"isOwner\",\"editMode\",\"commentId\",\"post\"],[[32,0,[\"isOwner\"]],[35,5],[32,2,[\"id\"]],[32,1]]]],[30,[36,4],[\"feed/post-comments/comment/body\"],[[\"comment\",\"editMode\",\"post\"],[[32,2],[35,5],[32,1]]]],[30,[36,4],[\"feed/post-comments/comment/like-bar\"],[[\"comment\",\"showReplies\",\"showReplyField\",\"onlyShow\",\"editMode\",\"replies\"],[[32,2],[35,3],[35,2],[32,3],[35,5],[35,1]]]],[30,[36,4],[\"feed/post-comments/comment/reply-field\"],[[\"comment\",\"showReplies\",\"showReplyField\",\"post\",\"replies\"],[[32,2],[35,3],[35,2],[32,1],[35,1]]]],[35,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"commentReplies\",\"replies\",\"showReplyField\",\"showReplies\",\"component\",\"editMode\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/feed/post-comments/comment/template.hbs"
    }
  });
  _exports.default = _default;
});