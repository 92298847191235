define("portal-talentrh/pods/application/navbar/notification/training-invitation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qJoEiA6u",
    "block": "{\"symbols\":[\"Avatar\",\"@notification\",\"@setAsRead\"],\"statements\":[[10,\"a\"],[15,6,[30,[36,1],[\"ted\",[30,[36,0],[\"schedule/\",[32,2,[\"modelId\"]]],null]],null]],[14,\"target\",\"_blank\"],[14,0,\"d-flex py-4\"],[15,\"onclick\",[30,[36,2],[[32,3]],null]],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0\"]],[[\"@user\"],[[32,2,[\"relatedUser\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"],[16,\"alt\",[32,2,[\"relatedUser\",\"fullName\"]]]],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex-fill ml-2\"],[12],[2,\"\\n    \"],[10,\"h6\"],[14,0,\"mb-0\"],[12],[1,[30,[36,3],[[32,2,[\"message\"]]],null]],[13],[2,\"\\n\\n    \"],[10,\"small\"],[15,\"title\",[31,[[30,[36,4],[[32,2,[\"createdAt\"]],\"DD/MM/YYYY HH:mm\"],null]]]],[12],[2,\"\\n      \"],[1,[30,[36,5],[[32,2,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"app-url\",\"perform\",\"html\",\"moment-format\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/training-invitation/template.hbs"
    }
  });
  _exports.default = _default;
});