define("portal-talentrh/pods/components/autosize-textarea/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.TextArea.extend({
    didInsertElement() {
      this.resize();
      if (this.hidden) {
        (0, _jquery.default)().hide();
      }
    },
    didUpdate() {
      this.resize();
    },
    keyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        this.send();
        return false;
      } else if (event.key === 'Enter' && event.shiftKey) {
        Ember.debug('Enter, não envia a mensagem ainda');
      }
    },
    resize() {
      Ember.set(this, 'element.style.height', '5px');
      const scrollHeight = Ember.get(this, 'element.scrollHeight');
      Ember.set(this, 'element.style.height', `${scrollHeight}px`);
    }
  });
  _exports.default = _default;
});