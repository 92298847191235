define("portal-talentrh/pods/components/mobile-banner/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['mobile-banner'],
    classNameBindings: ['showBanner:d-block:d-none'],
    didInsertElement() {
      let now = (0, _moment.default)();
      // let bannerCloseDate = window.localStorage.getItem('bannerCloseDate');
      let bannerCloseDate = false;
      if (!bannerCloseDate || (0, _moment.default)(bannerCloseDate).isBefore(now)) {
        if (/Android/i.test(navigator.userAgent)) {
          Ember.set(this, 'storeLink', 'android-app://br.com.talentrh.nelaApp');
          Ember.set(this, 'showBanner', true);
        }
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          Ember.set(this, 'storeLink', 'https://itunes.apple.com/br/app/nela-rede-social-corporativa/id1445427737?mt=8');
          Ember.set(this, 'showBanner', true);
        }
      }
    },
    actions: {
      closeBanner() {
        // Ao fechar o banner, o mesmo só abrirá 1 dias depois.
        let bannerCloseDate = (0, _moment.default)().add('days', 1);
        window.localStorage.setItem('bannerCloseDate', bannerCloseDate.toDate());
        Ember.set(this, 'showBanner', false);
      }
    }
  });
  _exports.default = _default;
});