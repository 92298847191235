define("portal-talentrh/pods/components/skills/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const MAX_SIZE_DISPLAYED = 300;
  let SkillsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('permission.user'), _dec12 = Ember.computed.alias('permission.companyConfig'), _dec13 = Ember.computed.alias('companyConfig.showJobFunction'), _dec14 = Ember.computed('readMore'), _dec15 = Ember.computed('jobFunctionDescription', 'jobRoleDescription', 'showJobFunction'), _dec16 = Ember.computed('description.length', 'readMore', 'jobFunctionDescription', 'jobRoleDescription'), _dec17 = Ember.computed('description.length'), (_class = class SkillsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "userDataLoader", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "hardSkills", _descriptor5, this);
      _initializerDefineProperty(this, "softSkills", _descriptor6, this);
      _initializerDefineProperty(this, "readMore", _descriptor7, this);
      _initializerDefineProperty(this, "user", _descriptor8, this);
      _initializerDefineProperty(this, "jobFunctionDescription", _descriptor9, this);
      _initializerDefineProperty(this, "jobRoleDescription", _descriptor10, this);
      _initializerDefineProperty(this, "userLogged", _descriptor11, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor12, this);
      _initializerDefineProperty(this, "showJobFunction", _descriptor13, this);
    }
    *loadUser() {
      const {
        userId,
        changeTitleModal
      } = this.args;
      this.user = userId ? yield this.userDataLoader.get(userId) : this.userLogged;
      if (this.showJobFunction) {
        const jobFunction = yield this.user.jobFunction;
        this.jobFunctionDescription = jobFunction?.description ? jobFunction.description : 'Nenhuma descrição cadastrada';
        changeTitleModal(jobFunction ? jobFunction.title : 'Nenhuma função informada');
      } else {
        const jobRole = yield this.user.jobRole;
        this.jobRoleDescription = jobRole?.description ? jobRole.description : 'Nenhuma descrição cadastrada';
        changeTitleModal(jobRole ? jobRole.title : 'Nenhum cargo informado');
      }
    }
    *loadSkills() {
      try {
        yield this.loadUser.perform();
        const {
          changeTotalSkills
        } = this.args;
        const {
          hardSkills,
          softSkills
        } = yield this.fetch.request('users/findSkills', {
          data: {
            showJobFunction: this.companyConfig.showJobFunction ? true : false,
            userId: this.user.id
          }
        });
        this.hardSkills = hardSkills;
        this.softSkills = softSkills;
        if (changeTotalSkills) {
          changeTotalSkills(this.hardSkills.length + this.softSkills.length);
        }
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    get btnName() {
      return this.readMore ? 'Ver mais' : 'Ver menos';
    }
    get description() {
      if (this.showJobFunction) {
        return this.jobFunctionDescription;
      } else {
        return this.jobRoleDescription;
      }
    }
    get descriptionDisplayed() {
      if (this.readMore && this.description?.length > MAX_SIZE_DISPLAYED) {
        return `${this.description.slice(0, MAX_SIZE_DISPLAYED)}...`;
      } else {
        return this.description;
      }
    }
    get showReadMore() {
      return this.description?.length > MAX_SIZE_DISPLAYED;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userDataLoader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hardSkills", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "softSkills", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "readMore", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionDescription", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "jobRoleDescription", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "userLogged", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showJobFunction", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadSkills", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadSkills"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "btnName", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "btnName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "descriptionDisplayed", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "descriptionDisplayed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showReadMore", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "showReadMore"), _class.prototype)), _class));
  _exports.default = SkillsComponent;
});