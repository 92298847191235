define("portal-talentrh/models/payroll-loan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PayrollLoanModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)('date'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('number'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('number'), _dec16 = (0, _model.attr)('number'), _dec17 = (0, _model.belongsTo)('payroll-loan-user'), _dec18 = (0, _model.hasMany)('payroll-loan-history'), _dec19 = Ember.computed('status'), _dec20 = Ember.computed('status'), (_class = class PayrollLoanModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "attachmentCpf", _descriptor, this);
      _initializerDefineProperty(this, "attachmentRgFront", _descriptor2, this);
      _initializerDefineProperty(this, "attachmentRgVerse", _descriptor3, this);
      _initializerDefineProperty(this, "attachmentReceipt", _descriptor4, this);
      _initializerDefineProperty(this, "attachmentSignature", _descriptor5, this);
      _initializerDefineProperty(this, "attachmentProofOfResidence", _descriptor6, this);
      _initializerDefineProperty(this, "codeCcb", _descriptor7, this);
      _initializerDefineProperty(this, "createdAt", _descriptor8, this);
      _initializerDefineProperty(this, "firstDueDate", _descriptor9, this);
      _initializerDefineProperty(this, "installmentValue", _descriptor10, this);
      _initializerDefineProperty(this, "status", _descriptor11, this);
      _initializerDefineProperty(this, "numberOfInstallments", _descriptor12, this);
      _initializerDefineProperty(this, "existingDiscountTotal", _descriptor13, this);
      _initializerDefineProperty(this, "termsCcb", _descriptor14, this);
      _initializerDefineProperty(this, "totalLoanAmount", _descriptor15, this);
      _initializerDefineProperty(this, "totalLoanAmountWithInterest", _descriptor16, this);
      _initializerDefineProperty(this, "payrollLoanUser", _descriptor17, this);
      _initializerDefineProperty(this, "historic", _descriptor18, this);
    }
    get statusLabel() {
      const statusOptions = {
        PENDENTE: 'Pendente',
        APROVADA: 'Aprovada',
        REPROVADA: 'Reprovada'
      };
      return statusOptions[this.status];
    }
    get statusBadge() {
      const statusBadgeOptions = {
        PENDENTE: 'badge-warning',
        APROVADA: 'badge-success',
        REPROVADA: 'badge-danger'
      };
      return statusBadgeOptions[this.status];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "attachmentCpf", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attachmentRgFront", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "attachmentRgVerse", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "attachmentReceipt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "attachmentSignature", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "attachmentProofOfResidence", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "codeCcb", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "firstDueDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "installmentValue", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "numberOfInstallments", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "existingDiscountTotal", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "termsCcb", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "totalLoanAmount", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "totalLoanAmountWithInterest", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "payrollLoanUser", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "historic", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statusLabel", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "statusLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "statusBadge", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "statusBadge"), _class.prototype)), _class));
  _exports.default = PayrollLoanModel;
});