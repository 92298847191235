define("portal-talentrh/helpers/to-fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function toFixed(params) {
    let [value, digits] = params;
    if (value === 0) return "0,00";
    if (!value) {
      return;
    }
    return Math.min(value, 100).toFixed(digits || 2).replace('.', ',');
  });
  _exports.default = _default;
});