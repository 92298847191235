define("portal-talentrh/pods/group/show/members/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GroupShowMembersRoute extends Ember.Route {
    model() {
      return this.modelFor('group.show');
    }
  }
  _exports.default = GroupShowMembersRoute;
});