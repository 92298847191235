define("portal-talentrh/pods/profile/edit/signature/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProfileEditSignatureRoute extends Ember.Route {
    model() {
      return this.modelFor('profile.edit');
    }
  }
  _exports.default = ProfileEditSignatureRoute;
});