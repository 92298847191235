define("portal-talentrh/pods/indicators/people-analytics/human-resources/organizational-climate/index/controller", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateIndexController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed.alias('permission.accessProfile.manageOnlyBranch'), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateIndexController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "startDate", _descriptor4, this);
      _initializerDefineProperty(this, "endDate", _descriptor5, this);
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      _initializerDefineProperty(this, "filtersSelected", _descriptor7, this);
      _initializerDefineProperty(this, "prevailingClimateData", _descriptor8, this);
      _initializerDefineProperty(this, "feelingsNegativeVsPositiveData", _descriptor9, this);
      _initializerDefineProperty(this, "absencesIndicatorData", _descriptor10, this);
      _initializerDefineProperty(this, "manageOnlyBranch", _descriptor11, this);
    }
    loadIndicators() {
      try {
        const filters = this.buildFilters();
        if (filters.invalidPeriod) {
          return;
        }
        this.loadPrevailingClimate.perform(filters);
        this.loadAbsencesIndicator.perform(filters);
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: OrganizationalClimate :: Index :: loadIndicators`);
        return this.swal.catch(e);
      }
    }
    *loadPrevailingClimate(filters) {
      try {
        const prevailingClimateData = yield this.fetch.request('indicators/getGeneralClimate', {
          data: filters
        });
        this.prevailingClimateData = prevailingClimateData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: OrganizationalClimate :: Index :: loadPrevailingClimate`);
        return this.swal.catch(e);
      }
    }
    *loadAbsencesIndicator(filters) {
      try {
        const absencesIndicatorData = yield this.fetch.request('indicators/getUserAbsences', {
          data: filters
        });
        this.absencesIndicatorData = absencesIndicatorData;
      } catch (e) {
        Ember.debug(`Indicators :: PeopleAnalytics :: HumanResources :: OrganizationalClimate :: Index :: loadAbsencesIndicator`);
        return this.swal.catch(e);
      }
    }
    buildFilters() {
      const filters = {};
      if (this.startDate && this.endDate) {
        const diffDias = (0, _moment.default)(this.endDate).diff(this.startDate, 'days');
        diffDias + 1;
        if (diffDias > 365) {
          this.swal.warning('Não é possível buscar um período maior que um ano.');
          filters.invalidPeriod = true;
          return filters;
        }
        filters.startDate = this.startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        filters.endDate = this.endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if ((0, _moment.default)(this.startDate).isAfter(this.endDate)) {
          this.swal.warning('A data inicial não pode ser posterior a data final');
          filters.invalidPeriod = true;
          return filters;
        }
      }
      if (this.filtersSelected && this.filtersSelected.branches) {
        filters.branch = this.filtersSelected.branches.map(branch => branch.id);
      }
      if (this.filtersSelected && this.filtersSelected.departments) {
        filters.department = this.filtersSelected.departments.map(department => department.id);
      }
      if (this.filtersSelected && this.filtersSelected.jobRoles) {
        filters.jobRole = this.filtersSelected.jobRoles.map(jobRole => jobRole.id);
      }
      if (this.filtersSelected && this.filtersSelected.jobFunctions) {
        filters.jobFunction = this.filtersSelected.jobFunctions.map(jobFunction => jobFunction.id);
      }
      this.filters = filters;
      return filters;
    }
    clearFilters() {
      this.filtersSelected = {};
      this.startDate = (0, _moment.default)().subtract(1, 'months').startOf('month');
      this.endDate = (0, _moment.default)().subtract(1, 'months').endOf('month');
      this.loadIndicators();
    }
    onSelectStartDate(_ref) {
      let {
        moment
      } = _ref;
      this.startDate = moment;
      this.loadIndicators();
    }
    onSelectEndDate(_ref2) {
      let {
        moment
      } = _ref2;
      this.endDate = moment;
      this.loadIndicators();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().subtract(1, 'months').startOf('month');
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().subtract(1, 'months').endOf('month');
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filtersSelected", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "prevailingClimateData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "feelingsNegativeVsPositiveData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "absencesIndicatorData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "manageOnlyBranch", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadIndicators", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadIndicators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPrevailingClimate", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadPrevailingClimate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadAbsencesIndicator", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadAbsencesIndicator"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectStartDate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectEndDate", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectEndDate"), _class.prototype)), _class));
  _exports.default = IndicatorsPeopleAnalyticsHumanResourcesOrganizationalClimateIndexController;
});