define("portal-talentrh/pods/components/mobile-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tLxMAJZc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/images/mobile-icon.svg\"],[14,0,\"mobile-banner__logo mr-2\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"],[10,\"a\"],[15,6,[34,0]],[12],[2,\"Acesse sua conta pelo nosso aplicativo\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"mobile-banner__close btn btn-link text-white\"],[4,[38,1],[[32,0],\"closeBanner\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-times\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"storeLink\",\"action\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/mobile-banner/template.hbs"
    }
  });
  _exports.default = _default;
});