define("portal-talentrh/pods/group/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.store.findRecord('user', this.session.user.id).then(user => {
        const admin = user.get('accessProfile.admin');
        let hashObj = {
          user
        };
        if (admin) {
          hashObj.userGroups = this.ajax.request('companies/userGroups');
        }
        return Ember.RSVP.hash(hashObj);
      });
    },
    deactivate() {
      this.controller.set('filter', '');
    }
  });
  _exports.default = _default;
});