define("portal-talentrh/pods/home/human-resources/goal-details/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeHumanResourcesGoalDetailsIndexRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
      this.controller.filters = {};
    }
  }
  _exports.default = HomeHumanResourcesGoalDetailsIndexRoute;
});