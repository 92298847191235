define("portal-talentrh/pods/habitus/details/feeling/employee-feelings/component", ["exports", "@glimmer/component", "moment", "chart-js"], function (_exports, _component, _moment, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EmployeeFeelingsComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.equal('filter', 'monthly'), _dec10 = Ember.computed.equal('filter', 'yearly'), _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class EmployeeFeelingsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "filter", _descriptor, this);
      _initializerDefineProperty(this, "years", _descriptor2, this);
      _initializerDefineProperty(this, "months", _descriptor3, this);
      _initializerDefineProperty(this, "dataLineChart", _descriptor4, this);
      _initializerDefineProperty(this, "yearSelected", _descriptor5, this);
      _initializerDefineProperty(this, "monthSelected", _descriptor6, this);
      _initializerDefineProperty(this, "dataAux", _descriptor7, this);
      _initializerDefineProperty(this, "loadedCalendar", _descriptor8, this);
      _initializerDefineProperty(this, "monthly", _descriptor9, this);
      _initializerDefineProperty(this, "yearly", _descriptor10, this);
      _defineProperty(this, "backgroundColor", ['rgba(34, 158, 132,1)', 'rgba(247, 252, 0, 1)', 'rgba(125, 90, 194, 1)', 'rgba(250, 118, 5,1)', 'rgba(77, 206, 32,1)', 'rgba(240, 65, 85,1)', 'rgba(83, 137, 197,1)', 'rgba(50, 0, 250,1)']);
      _initializerDefineProperty(this, "data", _descriptor11, this);
    }
    start() {
      const feelings = JSON.parse(JSON.stringify(this.args.feelings));
      this.loadYearsAndMonths();
      if (feelings && feelings.length) {
        let yearNow = new Date().getFullYear();
        let monthNow = new Date().getMonth() + 1;
        feelings.reverse();
        this.yearSelected = {
          value: yearNow,
          label: yearNow + ''
        };
        this.monthSelected = {
          value: monthNow,
          label: monthNow + '/' + yearNow
        };
        this.dataAux = JSON.parse(JSON.stringify(this.data));
        this.dataFeelings(this.monthSelected, this.filteredFeelingsCalendar);
      }
    }
    get filteredFeelings() {
      const {
        feelings
      } = this.args;
      return feelings.filter(fe => {
        const yearBase = this.yearSelected.value;
        const yearFeeling = new Date(fe.createdAt).getFullYear();
        if (yearFeeling === yearBase) {
          return fe;
        }
      });
    }
    reloadChart(yearSelected) {
      this.yearSelected = yearSelected;
      this.dataChart(this.filteredFeelings);
      this.initChart();
    }
    reloadCalendar(monthSelected) {
      this.monthSelected = monthSelected;
      this.dataFeelings(this.monthSelected, this.filteredFeelingsCalendar);
    }
    loadYearsAndMonths() {
      const {
        user
      } = this.args;
      const admissionDate = new Date(user.admissionDate);
      this.years = [];
      this.months = [];
      this.yearsFeelinsgs = [];
      const now = new Date();
      const nowYear = now.getUTCFullYear();
      const admissionDateYear = admissionDate.getUTCFullYear();
      const admissionDateMonth = admissionDate.getUTCMonth();
      const countYears = nowYear - admissionDateYear + 1;
      this.years = Array(countYears).fill('').map((v, idx) => ({
        value: nowYear - idx,
        label: nowYear - idx + ''
      }));
      this.yearsFeelinsgs = Array(countYears).fill('').map((v, idx) => ({
        value: admissionDateYear + idx,
        label: admissionDateYear + idx + ''
      }));
      this.yearsFeelinsgs.reverse();
      this.yearsFeelinsgs.forEach(year => {
        let index = 12;
        Array(12).fill('').map(() => {
          if (year.value === admissionDateYear && index === admissionDateMonth || year.value === nowYear && index > now.getUTCMonth() + 1) {
            index--;
          } else {
            this.months.push({
              value: index,
              label: index + '/' + year.value
            });
            index--;
          }
        });
      });
    }
    weekOfMonth(day) {
      const startOfMonth = (0, _moment.default)(day).startOf('month');
      const endOfMonth = (0, _moment.default)(day).endOf('month');
      let currentMomentDate = (0, _moment.default)(startOfMonth);
      const weeks = [];
      while (currentMomentDate.isBefore(endOfMonth)) {
        weeks.push(currentMomentDate.week());
        currentMomentDate.add(1, 'weeks').startOf('week');
      }
      return weeks.indexOf(day.week());
    }
    dataFeelings(monthSelected, feelings) {
      let monthYear = monthSelected.label.split('/');
      let month = Number(monthYear[0]);
      let year = Number(monthYear[1]);
      const firstDay = new Date(year, month - 1, 1);
      const lastDay = new Date(year, month, 0);
      let endDate = (0, _moment.default)(lastDay);
      const weekOfLastDay = this.weekOfMonth(endDate);
      this.data = JSON.parse(JSON.stringify(this.dataAux));
      if (feelings && feelings.length) {
        for (let i = 0; i < firstDay.getDay(); i++) {
          this.data[0][i].weekend = false;
        }
        if (weekOfLastDay === 5) {
          for (let i = 0; i < 7; i++) {
            if (i > lastDay.getDay()) {
              this.data[5][i].weekend = false;
            }
          }
        } else if (weekOfLastDay === 4) {
          for (let i = 0; i < 7; i++) {
            if (i > lastDay.getDay()) {
              this.data[4][i].weekend = false;
            }
          }
          for (let i = 0; i < 7; i++) {
            this.data[5][i].weekend = false;
          }
        }
        feelings.forEach(feeling => {
          let date = new Date(feeling.createdAt);
          let momentDay = (0, _moment.default)(feeling.createdAt);
          const weekOfMonth = this.weekOfMonth(momentDay);
          let dayofweek = date.getDay();
          this.data[weekOfMonth][dayofweek].type = feeling.type;
          this.data[weekOfMonth][dayofweek].active = true;
          this.data[weekOfMonth][dayofweek].user = feeling.user;
        });
      } else {
        this.data = JSON.parse(JSON.stringify(this.dataAux));
        for (let i = 0; i < firstDay.getDay(); i++) {
          this.data[0][i].weekend = false;
        }
        if (weekOfLastDay === 5) {
          for (let i = 0; i < 7; i++) {
            if (i > lastDay.getDay()) {
              this.data[5][i].weekend = false;
            }
          }
        } else if (weekOfLastDay === 4) {
          for (let i = 0; i < 7; i++) {
            if (i > lastDay.getDay()) {
              this.data[4][i].weekend = false;
            }
          }
          for (let i = 0; i < 7; i++) {
            this.data[5][i].weekend = false;
          }
        }
      }
      const startDate = (0, _moment.default)(firstDay);
      for (startDate; startDate.isSameOrBefore(endDate); startDate.add(1, 'days')) {
        const dayStr = startDate.format('ddd').toUpperCase();
        const dayOfWeek = startDate.day();
        const dayOfMonth = startDate.date();
        const month = startDate.month() + 1;
        const week = this.weekOfMonth(startDate);
        this.data[week][dayOfWeek].strDay = `${dayStr} - ${dayOfMonth}/${month}`;
      }
    }
    filterFeelings() {
      if (this.yearly) {
        this.reloadChart(this.yearSelected);
      } else if (this.monthly) {
        if (!this.loadedCalendar) {
          this.dataFeelings(this.monthSelected, this.filteredFeelingsCalendar);
        }
        this.chart.destroy();
        this.chart = null;
        this.loadedCalendar = true;
      }
    }
    get filteredFeelingsCalendar() {
      const {
        feelings
      } = this.args;
      return feelings.filter(fe => {
        let monthYear = this.monthSelected.label.split('/');
        let monthBase = Number(monthYear[0]);
        let yearBase = Number(monthYear[1]);
        const yearFeeling = new Date(fe.createdAt).getFullYear();
        const monthFeeling = new Date(fe.createdAt).getMonth() + 1;
        if (yearFeeling === yearBase && monthFeeling === monthBase) {
          return fe;
        }
      });
    }
    dataChart(feelings) {
      const monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
      let dataValues = [];
      let dataSets = [];
      feelings.forEach(feeling => {
        if (dataValues && dataValues.length) {
          let i = dataValues.findIndex(x => x.type === feeling.type && x.month === monthNames[new Date(feeling.createdAt).getMonth()]);
          if (i >= 0) {
            dataValues[i].count = dataValues[i].count + 1;
          } else {
            dataValues.push({
              type: feeling.type,
              count: 1,
              month: monthNames[new Date(feeling.createdAt).getMonth()]
            });
          }
        } else {
          dataValues.push({
            type: feeling.type,
            count: 1,
            month: monthNames[new Date(feeling.createdAt).getMonth()]
          });
        }
      });
      if (dataValues && dataValues.length) {
        let indexColor = 0;
        dataValues.forEach(data => {
          if (dataSets && dataSets.length) {
            let i = dataSets.findIndex(x => x.feelingType === data.type);
            if (i >= 0) {
              dataSets[i].data.push({
                x: data.month,
                y: data.count
              });
            } else {
              dataSets.push({
                data: [{
                  x: data.month,
                  y: data.count
                }],
                borderColor: this.backgroundColor[indexColor],
                fill: false,
                tension: 0.1,
                feelingType: data.type
              });
              indexColor++;
            }
          } else {
            dataSets.push({
              data: [{
                x: data.month,
                y: data.count
              }],
              borderColor: this.backgroundColor[indexColor],
              fill: false,
              tension: 0.1,
              feelingType: data.type
            });
            indexColor++;
          }
        });
      }
      this.dataLineChart = dataSets;
      return dataSets;
    }
    initChart() {
      let data = {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: this.dataLineChart
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('feelings-by-collaborator').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'line',
          data: data,
          options: {
            responsive: true,
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  stepSize: 5,
                  min: 0,
                  // minimum value
                  max: 31 // maximum value
                }
              }]
            },

            tooltips: {
              callbacks: {
                title: function (tooltipItem) {
                  return tooltipItem.firstObject.label;
                },
                afterLabel: function (tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].feelingType;
                }
              },
              enabled: false,
              yAlign: 'bottom',
              custom: function (tooltip) {
                const tooltipEl = $('#chartjs-tooltip');
                if (!tooltip) {
                  tooltipEl.css({
                    opacity: 0
                  });
                  return;
                }
                if (!tooltip.body) {
                  tooltipEl.css({
                    opacity: 0
                  });
                  return;
                }
                tooltipEl.removeClass('above below');
                tooltipEl.addClass(tooltip.yAlign);
                const feeling = tooltip.body[0].after.firstObject;
                const dataPoints = tooltip.dataPoints.firstObject;
                let imgNames = [{
                  type: 'Feliz',
                  img: 'happy.png'
                }, {
                  type: 'Apaixonado',
                  img: 'inlove.png'
                }, {
                  type: 'Irritado',
                  img: 'angry.png'
                }, {
                  type: 'Cansado',
                  img: 'tired.png'
                }, {
                  type: 'Inteligente',
                  img: 'nerd.png'
                }, {
                  type: 'Entediado',
                  img: 'sleeping.png'
                }, {
                  type: 'Ansioso',
                  img: 'shocked.png'
                }, {
                  type: 'Legal',
                  img: 'cool.png'
                }, {
                  type: 'Triste',
                  img: 'sad.png'
                }, {
                  type: 'Desanimado',
                  img: 'sad.png'
                }, {
                  type: 'Irritado',
                  img: 'angry.png'
                }];
                let found = imgNames.findBy('type', feeling) || {
                  type: 'default',
                  img: 'default.png'
                };
                const src = `https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/feelings-icons/${found.img}`;

                // split out the label and value and make your own tooltip here
                const innerHtml =
                // `<div class="text-center font-weight-bold">${(dataPoints.label)}</div>` +
                `<div class="text-center font-weight-bold">${feeling}</div>` + '<div class="text-center">' + `<img src="${src}" title="${feeling}" class="p-1 avatar-4 ">` + '</div>' + `<div class="text-center font-weight-bold">Total: ${dataPoints.value}</div>`;
                tooltipEl.html(innerHtml);
                tooltipEl.css({
                  opacity: 1,
                  left: tooltip.x + 30 + 'px',
                  top: tooltip.y + 84 + 'px'
                });
              }
            }
          }
        });
      }
      this.chart = chart;
    }
    toggleData(value) {
      if (!this.chart.data.datasets[value].hidden) {
        document.getElementById('button-' + value).title = 'Visualizar';
        document.getElementById(value).style['background-color'] = '#fff';
        document.getElementById(value).style['border'] = '2px solid ' + this.chart.data.datasets[value].borderColor;
      } else {
        document.getElementById('button-' + value).title = 'Ocultar';
        document.getElementById(value).style['background-color'] = this.chart.data.datasets[value].borderColor;
      }
      this.chart.data.datasets[value].hidden = !this.chart.data.datasets[value].hidden;
      this.chart.update();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'monthly';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "years", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "months", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataLineChart", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "yearSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "monthSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dataAux", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loadedCalendar", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "monthly", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "yearly", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [[{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }], [{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }], [{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }], [{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }], [{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }], [{
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }, {
        type: null,
        active: false,
        weekend: true,
        strDay: null
      }]];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "start", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "start"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadChart", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "reloadChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadCalendar", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "reloadCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadYearsAndMonths", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "loadYearsAndMonths"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterFeelings", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "filterFeelings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dataChart", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "dataChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleData", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggleData"), _class.prototype)), _class));
  _exports.default = EmployeeFeelingsComponent;
});