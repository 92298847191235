define("portal-talentrh/pods/errors/unexpected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nn1dOSit",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Fimages%2FintegrationFailed.svg\"],[14,\"width\",\"50%\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"mb-5\"],[12],[2,\"Ops, ocorreu uma falha na integração. Por favor, tente novamente mais tarde\"],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[[32,0,[\"routeHome\"]]]],[[\"default\"],[{\"statements\":[[2,\"Tentar novamente\"]],\"parameters\":[]}]]],[2,\" \\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/errors/unexpected/template.hbs"
    }
  });
  _exports.default = _default;
});