define("portal-talentrh/models/rating-note-pdi-action", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    orderRatingNoteItem: (0, _model.attr)('number'),
    pdiActionNote: (0, _model.attr)('number'),
    status: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    pdiAction: (0, _model.belongsTo)('pdi-action'),
    ratingNote: (0, _model.belongsTo)('rating-note')
  });
  _exports.default = _default;
});