define("portal-talentrh/helpers/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    compute(params /*, hash*/) {
      if (!params.length) {
        return '';
      }
      Ember.debug('O componente {{user-avatar}} foi depreciado');
      return '/images/user-sem-imagem.png';
    }
  });
  _exports.default = _default;
});