define("portal-talentrh/pods/components/widgets/item/slide/empty/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['text-center', 'py-5']
  });
  _exports.default = _default;
});