define("portal-talentrh/models/shared-post", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    // Associations
    post: (0, _relationships.belongsTo)('post'),
    // Post criado (isSharedPost = true)
    sharedPost: (0, _relationships.belongsTo)('post'),
    // Post original, que foi compartilhado
    sharedPostUser: (0, _relationships.belongsTo)('user'),
    //  Usuario do post original, que foi compartilhado
    userWhoShared: (0, _relationships.belongsTo)('user') // Usuario do post criado (isSharedPost = true)
  });
  _exports.default = _default;
});