define("portal-talentrh/pods/view-team-member/receipt/controller", ["exports", "moment", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _moment, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ViewTeamMemberReceiptController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed.alias('model'), _dec15 = Ember.computed.alias('permission.companyConfig.permitImports'), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class ViewTeamMemberReceiptController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "swal", _descriptor4, this);
      _initializerDefineProperty(this, "repeat", _descriptor5, this);
      _initializerDefineProperty(this, "competenceSelected", _descriptor6, this);
      _initializerDefineProperty(this, "receipts", _descriptor7, this);
      _initializerDefineProperty(this, "receiptSelected", _descriptor8, this);
      _initializerDefineProperty(this, "competenceImportSelected", _descriptor9, this);
      _initializerDefineProperty(this, "receiptImports", _descriptor10, this);
      _initializerDefineProperty(this, "notFoundCompetence", _descriptor11, this);
      _initializerDefineProperty(this, "showReceiptImported", _descriptor12, this);
      _initializerDefineProperty(this, "files", _descriptor13, this);
      _initializerDefineProperty(this, "userLed", _descriptor14, this);
      _initializerDefineProperty(this, "permitImports", _descriptor15, this);
    }
    *findCompetences() {
      try {
        if (this.permitImports) {
          return;
        }
        const {
          folhas
        } = yield this.fetch.request(`receipts/list?user=${this.userLed.cpf}`);
        if (!folhas.length) {
          this.notFoundCompetence = 'Não foi encontrada nenhuma competência para o colaborador.';
        }
        this.receipts = folhas;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *loadReceiptImports() {
      try {
        if (!this.permitImports) {
          return;
        }
        this.receiptImports = null;
        this.receiptImportSelected = null;
        const receiptImports = yield this.store.loadRecords('import-receipt', '/import-receipt/my-team', {
          data: {
            users: [this.userLed.id]
          }
        });
        if (!receiptImports.length) {
          this.notFoundCompetence = 'Não foi encontrada nenhuma competência para o colaborador.';
        }
        this.receiptImports = receiptImports;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *getFiles() {
      try {
        const files = yield this.store.loadRecords('import-receipt-file', `/import-receipt/${this.competenceImportSelected.id}/files/my-team`, {
          data: {
            users: [this.userLed.id]
          }
        });
        this.files = files.length ? files : [];
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *viewReceiptImport() {
      try {
        if (!this.competenceImportSelected) {
          return this.swal.warning('Informe uma competência');
        }
        yield this.getFiles.perform();
        this.showReceiptImported = true;
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    *printReceiptUser(pdf) {
      try {
        if (!this.competenceSelected) {
          return this.swal.warning('Informe uma competência');
        }
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        const filter = this._buildFilters(pdf);
        let url = `${_environment.default.apiUrl.social}/receipts/report`;
        const search = new URLSearchParams(filter).toString();
        url += `?${search}`;
        if (pdf) {
          this.swal.loading('Gerando o pdf...');
        }
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        if (pdf) {
          const report = yield response.blob();
          const objectURL = URL.createObjectURL(report);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = 'recibo-pagamento.pdf';
          document.body.append(link);
          link.click();
          link.remove();
          this.swal.close();
        } else {
          const receipt = yield response.json();
          this.receiptSelected = receipt;
          this.receiptSelected.userReport = this.userLed.id;
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }
    _buildFilters() {
      let printReceipt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const filter = {};
      const date = (0, _moment.default)(this.competenceSelected.DATA).format('DD/MM/YYYY');
      const processId = this.competenceSelected.IDPROCESSO;
      const emission = (0, _moment.default)().format('DD/MM/YYYY HH:mm');
      filter.repeat = this.repeat ? this.repeat : 'false';
      filter.date = date;
      filter.process = processId;
      filter.emission = emission;
      filter.printReceipt = printReceipt;
      filter.user = this.userLed.id;
      filter.folha = this.competenceSelected.FOLHA;
      if (this.competenceSelected.BRANCH) {
        filter.branch = this.competenceSelected.BRANCH;
      }
      return filter;
    }
    onCompetenceSelected(competence) {
      this.competenceSelected = null;
      this.competenceSelected = competence;
    }
    onChangeCompetenceImported(competence) {
      this.competenceImportSelected = null;
      this.showReceiptImported = false;
      this.competenceImportSelected = competence;
    }
    previewUserReceipt() {
      this.printReceiptUser.perform(false);
    }
    printUserReceipt() {
      this.printReceiptUser.perform(true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "repeat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "competenceSelected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "receipts", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "receiptSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "competenceImportSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "receiptImports", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "notFoundCompetence", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showReceiptImported", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "userLed", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "permitImports", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "findCompetences", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findCompetences"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadReceiptImports", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadReceiptImports"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getFiles", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "getFiles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewReceiptImport", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "viewReceiptImport"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printReceiptUser", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "printReceiptUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCompetenceSelected", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onCompetenceSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCompetenceImported", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCompetenceImported"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previewUserReceipt", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "previewUserReceipt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printUserReceipt", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "printUserReceipt"), _class.prototype)), _class));
  _exports.default = ViewTeamMemberReceiptController;
});