define("portal-talentrh/pods/components/indicator-viewer/team-profile/hires-per-year/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EfA/OY16",
    "block": "{\"symbols\":[\"Card\",\"@indicator\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm\"],[4,[38,1],[[30,[36,0],[[32,0],\"generate\"],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Contratação Anual\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"canvas\"],[14,1,\"hires-per-year\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"text-center mt-5 mb-5 h3 \",[30,[36,2],[[32,2,[\"loaded\"]],\"d-none\"],null]]]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-spinner rotate-spinner\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-viewer/team-profile/hires-per-year/template.hbs"
    }
  });
  _exports.default = _default;
});