define("portal-talentrh/pods/components/user/books-list/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-group list-group-flush'],
    ajax: Ember.inject.service()
  });
  _exports.default = _default;
});