define("portal-talentrh/pods/components/preview-link/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _component, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PreviewLinkComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class PreviewLinkComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "preview", _descriptor4, this);
      _initializerDefineProperty(this, "loading", _descriptor5, this);
    }
    get url() {
      return this.args.url;
    }
    *init() {
      this.error = false;
      this.loading = false;
      this.preview = null;
      if (!this.checkInputParameters()) {
        return;
      }
      try {
        this.loading = true;
        let link = this.args.url;
        const {
          data
        } = yield this.ajax.request(`/posts/preview`, {
          data: {
            link
          }
        });
        if (data) {
          this.preview = data;
          this.error = false;
        } else {
          this.error = true;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.swal.catch(e);
      }
    }
    checkInputParameters() {
      let {
        url
      } = this.args;
      if (!url) {
        return false;
      }
      const links = url.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
      return links && links.length;
    }
    onLinkClick(url, e) {
      e.preventDefault();
      window.open(url, '_blank');
    }
    *onImageError(preview, article) {
      let type = article ? article.class : null;
      try {
        if (!article) {
          const {
            post
          } = this.args;
          if (!post) {
            return;
          }
          let articleRelationship = yield this.store.query('article-post-shared', {
            post
          });
          articleRelationship = articleRelationship.toArray()[0];
          type = articleRelationship.get('article.class');
        }
        Ember.set(preview, 'image', `${_environment.default.cdnUrl}images/news/${type}.jpg`);
      } catch (e) {
        Ember.debug(`PreviewLink :: onImageError ${e}`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "preview", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLinkClick", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onLinkClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onImageError", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onImageError"), _class.prototype)), _class));
  _exports.default = PreviewLinkComponent;
});