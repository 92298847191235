define("portal-talentrh/pods/habitus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IU4h/mda",
    "block": "{\"symbols\":[\"Page\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex justify-content-center\"],[12],[2,\"\\n  \"],[8,\"ui-page\",[[24,0,\"w-100\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Section\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"IAScreen\"]]],null]],[15,0,[31,[\"btn d-inline-block shadow insights-news-buttons insights-news-buttons-right \",[30,[36,2],[[30,[36,1],[[32,0,[\"isActiveButton\"]],\"ia\"],null],\"insights-news-buttons-focus\"],null]]]],[14,4,\"button\"],[12],[2,\"\\n          \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"strong\"],[14,0,\"h5 font-weight-bold\"],[12],[2,\" Assistentes \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"newsScreen\"]]],null]],[15,0,[31,[\"btn d-inline-block shadow insights-news-buttons \",[30,[36,2],[[32,0,[\"aiAssistant\"]],\"insights-news-buttons-left\"],null],\"  \",[30,[36,2],[[30,[36,1],[[32,0,[\"isActiveButton\"]],\"news\"],null],\"insights-news-buttons-focus\"],null]]]],[14,4,\"button\"],[12],[2,\"\\n          \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"strong\"],[14,0,\"h5 font-weight-bold\"],[12],[2,\"Notícias\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"eq\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/template.hbs"
    }
  });
  _exports.default = _default;
});