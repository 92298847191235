define("portal-talentrh/pods/errors/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "n/7GJ4Go",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-lg-12 text-center\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[10,\"i\"],[14,0,\"fa fa-meh text-primary fa-2x\"],[12],[13],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"mb-5\"],[12],[2,\"Página não encontrada\"],[13],[2,\"\\n\\n  \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[[32,0,[\"routeHome\"]]]],[[\"default\"],[{\"statements\":[[2,\"Ir para página inicial\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/errors/not-found/template.hbs"
    }
  });
  _exports.default = _default;
});