define("portal-talentrh/pods/home/my-team/team-climate/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HomeMyTeamTeamClimateComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed.alias('permission.companyConfig.permitUserFeeling'), _dec6 = Ember.computed.alias('permission.accessProfile.allowFeelingsTodayMyTeam'), _dec7 = Ember.computed.alias('permission.admin'), _dec8 = Ember.computed('allowFeelingsTodayMyTeam', 'feelings.length', 'isAdmin', 'permitUserFeeling'), (_class = class HomeMyTeamTeamClimateComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "feelings", _descriptor3, this);
      _initializerDefineProperty(this, "total", _descriptor4, this);
      _initializerDefineProperty(this, "permitUserFeeling", _descriptor5, this);
      _initializerDefineProperty(this, "allowFeelingsTodayMyTeam", _descriptor6, this);
      _initializerDefineProperty(this, "isAdmin", _descriptor7, this);
      _defineProperty(this, "images", {
        Triste: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/sad.svg',
        Irritado: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/angry.svg',
        Cansado: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/tired.svg',
        Desanimado: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/dispirited.svg',
        Ansioso: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/eager.svg',
        Entediado: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/bored.svg'
      });
      _defineProperty(this, "feelingsDisabled", [{
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/sad-disabled.svg'
      }, {
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/angry-disabled.svg'
      }, {
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/tired-disabled.svg'
      }, {
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/dispirited-disabled.svg'
      }, {
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/eager-disabled.svg'
      }, {
        img: 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/feeling/bored-disabled.svg'
      }]);
    }
    get feelingsPermission() {
      return this.feelings.length && this.permitUserFeeling && (this.allowFeelingsTodayMyTeam || this.isAdmin);
    }
    *initChart() {
      let teamClimate = yield this.fetch.request('/api/leader/getTeamClimate', {
        host: 'indicadores',
        data: {
          ledType: 'direct'
        }
      });
      this.feelings = teamClimate.feelings;
      this.total = teamClimate.total;
      this.feelings.forEach(feeling => {
        feeling.img = this.images[feeling.type];
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "feelings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permitUserFeeling", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "allowFeelingsTodayMyTeam", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "feelingsPermission", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "feelingsPermission"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChart", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = HomeMyTeamTeamClimateComponent;
});