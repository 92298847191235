define("portal-talentrh/pods/forum/topic/show/comment-item/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    // Services
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Computed
    canManage: Ember.computed('comment.user.id', 'permission.admin', 'session.user.{admin,id}', function () {
      return Ember.get(this, 'permission.admin') || Ember.get(this, 'session.user.id') === parseInt(Ember.get(this, 'comment.user.id'));
    }),
    // Tasks
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção do comentário?');
        if (!value) {
          return;
        }
        yield this.comment.destroyRecord();
        this.toast.success('O comentário foi removido');
        this.comments.removeObject(this.comment);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});