define("portal-talentrh/pods/components/dashboard/births-block/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    loadBirthDayUsers: (0, _emberConcurrency.task)(function* () {
      let variant = this.get('permission.companyConfig.showBirthdayMonth') ? 'month' : 'today';
      const {
        users
      } = yield this.ajax.request(`/widgets/birthday?variant=${variant}`);
      Ember.set(this, 'births', users);
    }).on('didInsertElement')
  });
  _exports.default = _default;
});