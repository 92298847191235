define("portal-talentrh/models/post-complaining", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    message: (0, _attr.default)('string'),
    motive: (0, _attr.default)('number'),
    status: (0, _attr.default)('string', {
      defaultValue: 'pending'
    }),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    post: (0, _relationships.belongsTo)('post'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});