define("portal-talentrh/pods/habitus/details/feedback/amount-of-feedbacks-by-type/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const FEEDBACK_INFO = {
    0: {
      text: 'Corretivo'
    },
    1: {
      text: 'Positivo'
    },
    2: {
      text: 'Tarefa'
    },
    3: {
      text: 'Outros'
    },
    4: {
      text: 'Geral'
    }
  };
  let AmountOfFeedbacksByTypeComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class AmountOfFeedbacksByTypeComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chart", _descriptor, this);
      _initializerDefineProperty(this, "labels", _descriptor2, this);
      _initializerDefineProperty(this, "data", _descriptor3, this);
    }
    init() {
      this.labels = [];
      this.data = [];
      let dataChart = this.dataChart;
      if (dataChart && dataChart.length) {
        dataChart.forEach(data => {
          this.labels.push(data.label);
          this.data.push(data.data);
        });
      }
      if (this.labels.length && this.data.length) {
        this.initChart();
      }
    }
    get dataChart() {
      const {
        feedbacks
      } = this.args;
      let data = [];
      data.push({
        label: 'Geral',
        data: 0
      });
      data.push({
        label: 'Corretivo',
        data: 0
      });
      data.push({
        label: 'Positivo',
        data: 0
      });
      data.push({
        label: 'Tarefa',
        data: 0
      });
      data.push({
        label: 'Outros',
        data: 0
      });
      feedbacks.forEach(feedback => {
        const type = FEEDBACK_INFO[feedback.type].text;
        if (data && data.length) {
          let i = data.findIndex(x => x.label === type);
          if (i >= 0) {
            data[i].data = data[i].data + 1;
          } else {
            data.push({
              label: type,
              data: 1
            });
          }
        } else {
          data.push({
            label: type,
            data: 1
          });
        }
      });
      data = data.sort((a, b) => b.data - a.data);
      return data;
    }
    initChart() {
      const {
        feedbacks
      } = this.args;
      const backgroundColor = ['rgba(2, 99, 255, 0.2)', 'rgba(255, 48, 35, 0.2)', 'rgba(27, 199, 138, 0.5)', 'rgba(151, 151, 151, 0.5)', 'rgba(251, 232, 213, 1)'];
      let data = {
        labels: this.labels,
        min: 0,
        datasets: [{
          backgroundColor: backgroundColor,
          data: this.data
        }]
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('amount-of-feedbacks-by-type').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          maintainAspectRatio: false,
          data: data,
          options: {
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.yLabel;
                }
              }
            },
            scales: {
              yAxes: [{
                display: true,
                stacked: true,
                ticks: {
                  stepSize: 1,
                  min: 0,
                  // minimum value
                  max: feedbacks.length // maximum value
                }
              }]
            }
          }
        });
      }

      this.chart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "labels", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = AmountOfFeedbacksByTypeComponent;
});