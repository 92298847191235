define("portal-talentrh/pods/group/show/attachment/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model() {
      return this.modelFor('group.show');
    },
    deactivate() {
      this.controller.files.clear();
      Ember.set(this.controller, 'description', '');
    }
  });
  _exports.default = _default;
});