define("portal-talentrh/pods/dashboard/layout/basic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Zl6SJXvH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row board-height-large\"],[12],[2,\"\\n      \"],[8,\"dashboard/recent-users\",[],[[\"@class\"],[\"col-md-6 board-height-short\"]],null],[2,\"\\n      \"],[8,\"dashboard/announcements-block\",[],[[\"@class\"],[\"col-md-6 board-height-short\"]],null],[2,\"\\n      \"],[8,\"dashboard/births-block\",[],[[\"@class\",\"@configuration\"],[\"col-md-6 board-block-bot board-height-short\",[34,0]]],null],[2,\"\\n      \"],[8,\"dashboard/admission-births\",[],[[\"@class\"],[\"col-md-6 board-block-bot board-height-short\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"companyConfiguration\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/dashboard/layout/basic/template.hbs"
    }
  });
  _exports.default = _default;
});