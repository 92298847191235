define("portal-talentrh/pods/components/insight-filter/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let InsightFilterComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class InsightFilterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "fetch", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "generalFilterType", _descriptor4, this);
      _initializerDefineProperty(this, "feelingFactor", _descriptor5, this);
      _initializerDefineProperty(this, "filterData", _descriptor6, this);
    }
    updateFilter() {
      this.filterData = {};
      if (this.args.updateFilters) {
        this.args.updateFilters(this.generalFilterType);
      }
    }
    *generateIndicators() {
      let storageFilters = {};
      if (!this.filterData.modalStartDate || !this.filterData.modalEndDate) {
        this.swal.warning('Data inicial e final devem ser informadas');
        return;
      } else if ((0, _moment.default)(this.filterData.modalStartDate).isAfter(this.filterData.modalEndDate)) {
        this.swal.warning('A data final não pode ser anterior a data inicial');
        return;
      } else {
        storageFilters = {
          startDate: `${(0, _moment.default)(this.filterData.modalStartDate).format('YYYY-MM-DD')} 00:00:00`,
          endDate: `${(0, _moment.default)(this.filterData.modalEndDate).format('YYYY-MM-DD')} 23:59:59`
        };
        if (this.filterData.branch) {
          storageFilters.branches = this.filterData.branch.map(o => {
            return o.id;
          });
        }
        if (this.filterData.department) {
          storageFilters.departments = this.filterData.department.map(o => {
            return o.id;
          });
        }
        if (this.filterData.jobRole) {
          storageFilters.jobRoles = this.filterData.jobRole.map(o => {
            return o.id;
          });
        }
        if (this.filterData.factor) {
          storageFilters.factor = this.filterData.factor;
        }
        if (this.filterData.jobFunctions) {
          storageFilters.jobFunction = this.filterData.jobFunction.map(o => {
            return o.id;
          });
        }
      }
      if (storageFilters) {
        if (this.args.insightType === 'general') {
          if (this.generalFilterType === 'm') {
            this.args.loadInsights(storageFilters);
          } else if (this.generalFilterType === 'e') {
            storageFilters.type = 'e';
            this.args.loadInsights(storageFilters);
          }
        } else if (this.args.insightType === 'feeling') {
          let data = {
            start: storageFilters.startDate,
            end: storageFilters.endDate,
            factor: storageFilters.factor
          };
          let feelings = yield this.fetch.request(`feelings/${this.args.led}/findFeelingsByLed`, {
            data
          });
          this.args.loadInsights(feelings, storageFilters);
        }
      }
      this.generalFilterType = 'm';
    }
    isHabitusTeamPage() {
      return this.router.currentRouteName === 'habitus.team-insights';
    }
    onClose() {
      this.filterData = {};
    }
    updateGeneralFiltarType() {
      this.generalFilterType = this.isHabitusTeamPage() ? 'e' : 'm';
      this.updateFilter();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "generalFilterType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'm';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "feelingFactor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'internal';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateIndicators", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "generateIndicators"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateGeneralFiltarType", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateGeneralFiltarType"), _class.prototype)), _class));
  _exports.default = InsightFilterComponent;
});