define("portal-talentrh/models/task-list", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    conclusionDate: (0, _attr.default)('date'),
    description: (0, _attr.default)('string'),
    initialDate: (0, _attr.default)('date'),
    open: (0, _attr.default)('boolean'),
    title: (0, _attr.default)('string'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    project: (0, _relationships.belongsTo)('project'),
    tasks: (0, _relationships.hasMany)('task'),
    // Computed Properties
    endDate: Ember.computed('conclusionDate', function () {
      let mDate = (0, _moment.default)(this.conclusionDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    endTime: Ember.computed('conclusionDate', function () {
      let mDate = (0, _moment.default)(this.conclusionDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    }),
    startDate: Ember.computed('initialDate', function () {
      let mDate = (0, _moment.default)(this.initialDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('DD/MM/YYYY');
    }),
    startTime: Ember.computed('initialDate', function () {
      let mDate = (0, _moment.default)(this.initialDate);
      if (!mDate.isValid()) {
        return '';
      }
      return mDate.format('HH:mm');
    })
  });
  _exports.default = _default;
});