define("portal-talentrh/pods/feed/me/index/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "loEje7m1",
    "block": "{\"symbols\":[\"Card\"],\"statements\":[[8,\"ui-card\",[[24,0,\"mb-3 shadow-sm\"],[4,[38,2],[[30,[36,1],[[32,0,[\"loadComponentInfo\"]]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[16,0,[31,[\"py-4 \",[30,[36,3],[[32,0,[\"loadComponentInfo\",\"isRunning\"]]],null]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n      \"],[10,\"h5\"],[14,0,\"mb-0 text-secondary\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-birthday-cake fa-lg mr-3\"],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n          \"],[10,\"strong\"],[12],[2,\"Feliz aniversário \"],[1,[32,0,[\"user\",\"firstName\"]]],[2,\"!\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"i\"],[15,0,[31,[\"fa \",[34,0,[\"icon\"]],\" fa-lg mr-3\"]]],[14,\"aria-hidden\",\"\"],[12],[13],[2,\"\\n          \"],[10,\"strong\"],[12],[1,[35,0,[\"text\"]]],[2,\" \"],[1,[32,0,[\"user\",\"firstName\"]]],[2,\"!\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[10,\"span\"],[14,0,\"ml-auto text-muted\"],[12],[1,[30,[36,7],[[30,[36,6],null,null],\"dddd, DD [de] MMMM\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"loadComponentInfo\",\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"feed/me/index/welcome/skeleton\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"period\",\"perform\",\"did-insert\",\"hide-if\",\"isBirthday\",\"if\",\"now\",\"moment-format\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/feed/me/index/welcome/template.hbs"
    }
  });
  _exports.default = _default;
});