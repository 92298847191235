define("portal-talentrh/pods/company/minutes-of-meeting/index/item/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'tr',
    classNames: ['event-item'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    permission: Ember.inject.service(),
    toast: Ember.inject.service(),
    isApproved: false,
    isAdminOnMeeting: Ember.computed('meeting.meetingAdmins', 'session.user.id', 'tab', function () {
      return this.meeting.meetingAdmins.includes(this.session.user.id);
    }),
    showApproveButton: Ember.computed('isAdminOnMeeting', 'meeting.meetingParticipants', 'session.user.id', 'tab', 'tasb', function () {
      return this.tab === "invited" || this.isAdminOnMeeting || this.meeting.meetingParticipants.includes(this.session.user.id);
    }),
    approved: Ember.computed('isAdminOnMeeting', 'isApproved', 'meeting.{usersAdminOnMeeting,usersParticipantOnMeeting}', 'session.user.id', function () {
      const isAdmin = this.isAdminOnMeeting;
      const user = this.session.user.id;
      const usersParticipating = isAdmin ? this.meeting.usersAdminOnMeeting : this.meeting.usersParticipantOnMeeting;
      const getUserParticipation = usersParticipating.findBy('user', user);
      return getUserParticipation && getUserParticipation.approved || this.isApproved;
    }),
    meetingDateStart: Ember.computed('isAdminOnMeeting', 'meeting.meetingParticipants', function () {
      const timestamp = this.meeting.start;
      return (0, _moment.default)(new Date(timestamp * 1000)).format('DD/MM/YYYY HH:mm');
    }),
    // Tasks
    approve: (0, _emberConcurrency.task)(function* () {
      let isAdmin = this.isAdminOnMeeting;
      let meeting = this.meeting;
      let result = yield this.swal.confirm("Tem certeza que deseja aprovar esta Ata de Reunião?");
      if (!result.value) {
        return;
      }
      let url = `${isAdmin ? 'userIsAdminOnMeeting' : 'userIsParticipantOnMeeting'}/approve/${meeting.id}`;
      let response = yield this.ajax.put(url);
      this.store.pushPayload(response);
      Ember.set(this, 'isApproved', true);
      this.swal.success('Aprovado com sucesso!');
    }).drop(),
    confirmEdit: (0, _emberConcurrency.task)(function* () {
      const usersApproved = yield this.ajax.request(`/meetingMinutes/${this.meeting.id}/usersApproved`);
      if (usersApproved && usersApproved.length) {
        const result = yield this.swal.confirm('Após salvar as alterações os participantes deverão aprovar novamente.  Deseja continuar?');
        if (!result.value) {
          return;
        }
      }
      return this.router.transitionTo('company.minutes-of-meeting.edit', this.meeting.id);
    }),
    delete: (0, _emberConcurrency.task)(function* () {
      let result = yield this.swal.confirm('Tem certeza que deseja excluir esta Ata de Reunião?');
      if (!result.value) {
        return;
      }
      yield this.ajax.delete(`/meetingMinutes/delete/${this.meeting.id}`);
      this.remove();
      this.toast.success('Ata de reunião removida com sucesso');
    }).drop()
  });
  _exports.default = _default;
});