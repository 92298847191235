define("portal-talentrh/pods/view-team-member/notes/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTeamMemberNotesCreateRoute extends Ember.Route {
    model() {
      return this.store.createRecord('note', {
        user: this.modelFor('view-team-member')
      });
    }
    deactivate() {
      this.controller.model.rollbackAttributes();
      this.controllerFor('view-team-member.notes').buildFilters();
    }
  }
  _exports.default = ViewTeamMemberNotesCreateRoute;
});