define("portal-talentrh/pods/notification/list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['list-group-item', 'text-body', 'd-flex', 'align-items-center', 'py-3']
  });
  _exports.default = _default;
});