define("portal-talentrh/pods/components/social-page/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WxKLw4kj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Title\",\"Breadcrumbs\"],[[30,[36,0],[\"ui-page/header/title\"],null],[30,[36,0],[\"ui-page/header/breadcrumbs\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/social-page/header/template.hbs"
    }
  });
  _exports.default = _default;
});