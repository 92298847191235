define("portal-talentrh/pods/feed/index/setup-account/leader/suggestion/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    swal: Ember.inject.service(),
    // Computed
    users: Ember.computed.alias('model.possibleLeaders'),
    // Tasks
    createLeaderRequest: (0, _emberConcurrency.task)(function* () {
      try {
        let relatedUser = this.leader;
        if (!relatedUser) {
          return this.toast.warning('É necessário escolher um usuário para continuar');
        }
        yield this.ajax.post('leadershipRequests', {
          data: {
            leadershipRequest: {
              type: 'led',
              relatedUser
            }
          }
        });
        this.transitionToRoute('feed.index.setup-account.led');
        this.toast.success('Enviamos uma solicitação para seu líder.');
      } catch (e) {
        return this.swal.catch(e);
      }
    }).drop()
  });
  _exports.default = _default;
});