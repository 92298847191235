define("portal-talentrh/pods/company/professional-progression/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hrAveLeZ",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"d-flex w-100 align-items-center justify-content-center\"],[24,1,\"professional-progression-crud-container\"],[24,5,\"height: calc(100% - 75px);\"],[4,[38,1],[[30,[36,0],[[32,0,[\"handleData\"]]],null]],null],[4,[38,2],[[30,[36,0],[[32,0,[\"handleData\"]]],null],[32,0,[\"board\",\"id\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"data\",\"professionalProgressions\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"professional-progression-board\",[],[[\"@data\"],[[32,0,[\"data\",\"professionalProgressions\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text-center font-italic\"],[12],[2,\"Nenhum dado encontrado\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"did-update\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/company/professional-progression/details/template.hbs"
    }
  });
  _exports.default = _default;
});