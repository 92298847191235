define("portal-talentrh/pods/application/navbar/notification/backup-solicitation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gX4HRzEL",
    "block": "{\"symbols\":[\"@notification\"],\"statements\":[[10,\"li\"],[14,0,\"d-flex py-4 cursor-pointer\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"redirect\"]]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1,[\"message\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,1,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"html\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/backup-solicitation/template.hbs"
    }
  });
  _exports.default = _default;
});