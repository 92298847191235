define("portal-talentrh/pods/view-team-member/projects/concluded/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTeamMemberProjectsConcludedRoute extends Ember.Route {
    setupController(controller, model) {
      controller.filterSelected = null;
      controller.loadProjects.perform(model.id);
    }
  }
  _exports.default = ViewTeamMemberProjectsConcludedRoute;
});