define("portal-talentrh/pods/view-team-member/notes/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sLSpW80a",
    "block": "{\"symbols\":[\"@annotation\",\"@userIsMember\"],\"statements\":[[10,\"tr\"],[14,0,\"hover-actions w-100\"],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"align-middle\"],[12],[1,[30,[36,1],[[32,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"align-middle text-justify\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"position-relative align-bottom actions\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row text-xl-right\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-md-12 action-buttons\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"btn btn-link m-lg-n1\"],[24,\"title\",\"Editar anotação\"]],[[\"@route\",\"@model\"],[\"view-team-member.notes.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-edit\"],[12],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\\n          \"],[10,\"button\"],[14,0,\"btn btn-link m-lg-n1\"],[14,\"title\",\"Excluir anotação\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"removeNote\"]],[32,1]],null]],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12 user-info-table\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"m-1\"],[14,\"title\",\"Líder que registrou a anotação\"],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"far fa-user text-info mr-2\"],[12],[13],[2,\"\\n          \"],[1,[32,1,[\"userRegistration\",\"firstName\"]]],[2,\" \"],[1,[32,1,[\"userRegistration\",\"lastName\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"perform\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/view-team-member/notes/item/template.hbs"
    }
  });
  _exports.default = _default;
});