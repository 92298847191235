define("portal-talentrh/pods/components/number-incrementer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SMCUEGfd",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,1],[[30,[36,0],[[32,0,[\"incrementerTask\"]]],null]],null],[12],[2,\"\\n  \"],[1,[32,0,[\"incrementer\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/number-incrementer/template.hbs"
    }
  });
  _exports.default = _default;
});