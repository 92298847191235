define("portal-talentrh/models/quiz-answer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    answers: (0, _attr.default)('raw', {
      defaultValue: () => [{
        questions: [{
          answer: null,
          title: null,
          type: 'radio',
          required: false,
          order: 0,
          alternatives: [{
            label: null,
            order: 0
          }]
        }]
      }]
    }),
    createdAt: (0, _attr.default)('date'),
    timeCompletion: (0, _attr.default)('number'),
    //Em segundos
    updatedAt: (0, _attr.default)('date'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    quiz: (0, _relationships.belongsTo)('quiz'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});