define("portal-talentrh/pods/components/progressbar-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CxhsUNiy",
    "block": "{\"symbols\":[\"@total\",\"@mini\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"progressbar-mini\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progressbar-mini-indicator\"],[15,5,[32,0,[\"getStyle\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"progressbar\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progressbar-indicator\"],[15,5,[32,0,[\"getStyle\"]]],[15,\"title\",[32,0,[\"getTitle\"]]],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"small ml-2\"],[15,\"title\",[32,0,[\"getTitle\"]]],[12],[1,[32,1]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/progressbar-indicators/template.hbs"
    }
  });
  _exports.default = _default;
});