define("portal-talentrh/pods/human-resources/user-solicitations/filter/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "moment"], function (_exports, _component, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HumanResourcesUserSolicitationsFilterComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed.alias('permission.companyConfig'), _dec7 = Ember.computed.alias('companyConfig.userSolicitationConfigs'), _dec8 = Ember.computed.alias('companyConfig.managerSolicitationConfigs'), _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, (_class = class HumanResourcesUserSolicitationsFilterComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor6, this);
      _initializerDefineProperty(this, "userSolicitationConfigs", _descriptor7, this);
      _initializerDefineProperty(this, "managerSolicitationConfigs", _descriptor8, this);
      _initializerDefineProperty(this, "createdAt", _descriptor9, this);
      _initializerDefineProperty(this, "filter", _descriptor10, this);
      _initializerDefineProperty(this, "inputFilter", _descriptor11, this);
      _initializerDefineProperty(this, "reload", _descriptor12, this);
      _initializerDefineProperty(this, "initialDate", _descriptor13, this);
      _initializerDefineProperty(this, "finalDate", _descriptor14, this);
      _initializerDefineProperty(this, "departmentsSelected", _descriptor15, this);
      _initializerDefineProperty(this, "branchesSelected", _descriptor16, this);
      _initializerDefineProperty(this, "jobRolesSelected", _descriptor17, this);
      _initializerDefineProperty(this, "jobFunctionsSelected", _descriptor18, this);
      _initializerDefineProperty(this, "selectedType", _descriptor19, this);
      _initializerDefineProperty(this, "status", _descriptor20, this);
      _defineProperty(this, "solicitationTypes", [{
        label: 'Todas',
        value: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        utilize: ['notUseWarning', 'notUseChangeFunction', 'notUseChangeHorary', 'notUseChangeSalary', 'useIgnoreManagerApprovalToJustification', 'notUseAbsenceJustification', 'useIgnoreManagerApprovalToThirteen', 'useIgnoreManagerApprovalToAdvance', 'useIgnoreManagerApprovalToRegistrationData', 'useIgnoreManagerApprovalToCompensatoryTime', 'notUseTimeBankRequest', 'useIgnoreManagerApprovalToBenefits', 'notUseBenefitsRequest', 'useIgnoreManagerApprovalToUniform', 'useIgnoreManagerApprovalToOthers', 'notUseOtherSolicitations', 'useIgnoreManagerApprovalToTermination', 'notUseTerminationEmployed']
      }, {
        label: 'Advertência/Suspensão',
        value: 5,
        utilize: 'notUseWarning'
      }, {
        label: 'Alteração de função',
        value: 2,
        utilize: 'notUseChangeFunction'
      }, {
        label: 'Alteração de horário',
        value: 3,
        utilize: 'notUseChangeHorary'
      }, {
        label: 'Alteração de salário',
        value: 1,
        utilize: 'notUseChangeSalary'
      }, {
        label: 'Justificativa de ponto',
        value: 14,
        utilize: ['useIgnoreManagerApprovalToJustification', 'notUseAbsenceJustification']
      }, {
        label: 'Adiantamento de 13º',
        value: 10,
        utilize: 'useIgnoreManagerApprovalToThirteen'
      }, {
        label: 'Adiantamento salarial',
        value: 13,
        utilize: 'useIgnoreManagerApprovalToAdvance'
      }, {
        label: 'Alteração de dados cadastrais',
        value: 11,
        utilize: 'useIgnoreManagerApprovalToRegistrationData'
      }, {
        label: 'Banco de horas',
        value: 15,
        utilize: ['useIgnoreManagerApprovalToCompensatoryTime', 'notUseTimeBankRequest']
      }, {
        label: 'Benefícios',
        value: 9,
        utilize: ['useIgnoreManagerApprovalToBenefits', 'notUseBenefitsRequest']
      }, {
        label: 'Férias',
        value: 4,
        utilize: ['useIgnoreManagerApprovalToVacation', 'notUseVacancyPeriod']
      }, {
        label: 'Uniforme',
        value: 12,
        utilize: 'useIgnoreManagerApprovalToUniform'
      }, {
        label: 'Outros itens',
        value: 8,
        utilize: ['useIgnoreManagerApprovalToOthers', 'notUseOtherSolicitations']
      }, {
        label: 'Pedido de demissão',
        value: 7,
        utilize: ['useIgnoreManagerApprovalToTermination', 'notUseTerminationEmployed']
      }]);
      _defineProperty(this, "statusOptions", [{
        label: 'Todas',
        value: ['pending-hr', 'pending-manager']
      }, {
        label: 'Aguardando resposta do Gestão de Pessoas',
        value: 'pending-hr'
      }, {
        label: 'Aguardando resposta do líder',
        value: 'pending-manager'
      }]);
    }
    get solicitations() {
      if (this.managerSolicitationConfigs && this.solicitationTypes && this.userSolicitationConfigs) {
        this.solicitationTypes.forEach(solicitation => {
          if (Ember.get(this, `managerSolicitationConfigs.${solicitation.utilize}`) !== undefined) {
            solicitation.use = Ember.get(this, `managerSolicitationConfigs.${solicitation.utilize}`);
          }
          if (Ember.get(this, `userSolicitationConfigs.${solicitation.utilize}`) !== undefined) {
            if (solicitation.use) {
              solicitation.use = Ember.get(this, `userSolicitationConfigs.${solicitation.utilize}`);
            }
          }
        });
        this.solicitationTypes = this.solicitationTypes.filter(solicitation => {
          return !solicitation.use;
        });
      }
      return this.solicitationTypes;
    }
    *findAndShowRequests() {
      const {
        onChangeFilters
      } = this.args;
      try {
        this.filters = this.getFilters();
        this.filters.route = this.router.currentRouteName;
        yield onChangeFilters(this.filters);
      } catch (e) {
        console.log(e);
      }
    }
    getFilters() {
      let filters = {};
      filters.createdAt = (0, _moment.default)(this.initialDate).format('YYYY-MM-DD HH:mm');
      filters.finalDate = (0, _moment.default)(this.finalDate).format('YYYY-MM-DD HH:mm');
      if (this.departmentsSelected.length) {
        filters.user = filters.user || {};
        filters.user.department = this.departmentsSelected.mapBy('id');
      }
      if (this.branchesSelected.length) {
        filters.branch = this.branchesSelected.mapBy('id');
      }
      if (this.jobFunctionsSelected.length) {
        filters.user = filters.user || {};
        filters.user.jobFunction = this.jobFunctionsSelected.mapBy('id');
      }
      if (this.selectedType) {
        filters.solicitationType = this.selectedType.value;
      }
      if (this.status) {
        filters.status = this.status.value;
      }
      if (this.router.currentRouteName === 'human-resources.user-solicitations.index.index') {
        filters.pending = 's';
      }
      filters.limit = 10;
      filters.skip = 0;
      filters.sort = 'createdAt DESC';
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userSolicitationConfigs", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "managerSolicitationConfigs", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "inputFilter", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "reload", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().startOf('month').toDate();
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "finalDate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().toDate();
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "departmentsSelected", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "branchesSelected", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "jobRolesSelected", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionsSelected", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Todas',
        value: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15]
      };
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Todas',
        value: ['pending-hr', 'pending-manager']
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "findAndShowRequests", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findAndShowRequests"), _class.prototype)), _class));
  _exports.default = HumanResourcesUserSolicitationsFilterComponent;
});