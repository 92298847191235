define("portal-talentrh/helpers/group-attr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    groups: Ember.inject.service(),
    compute(params /*, hash*/) {
      if (params.length < 2) {
        Ember.debug('O componente {{group-attr}} foi utilizado de maneira incorreta: params < 2');
        return '';
      }
      let groupId = params[0];
      let attr = params[1];
      let group = this.groups.getById(groupId);
      return group && group[attr] || 'Grupo indisponível';
    }
  });
  _exports.default = _default;
});