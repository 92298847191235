define("portal-talentrh/mirage/factories/branch", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberCliMirage.Factory.extend({
    company() {
      return _faker.default.datatype.number();
    },
    address() {
      return _faker.default.address.streetAddress();
    },
    name() {
      let id = _faker.default.datatype.number();
      return `Estabelecimento ${id}`;
    },
    tradingName() {
      let id = _faker.default.datatype.number();
      return `Estabelecimento ${id}`;
      ;
    }
  });
  _exports.default = _default;
});