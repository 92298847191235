define("portal-talentrh/pods/post/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    post: Ember.computed.alias('model'),
    fromAccount: false,
    actions: {
      goToHome() {
        this.transitionToRoute('feed');
      }
    }
  });
  _exports.default = _default;
});