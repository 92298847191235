define("portal-talentrh/pods/components/post-pin/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    swal: Ember.inject.service(),
    priorityUntil: '',
    actions: {
      closeModal() {
        Ember.set(this, 'priorityUntil', '');
        Ember.set(this, 'openModal', false);
      },
      save() {
        if (this.priorityUntil.length < 1) {
          return this.swal.warning('A data deve ser preenchida');
        }
        if (moment(this.priorityUntil).isBefore(moment().startOf('day'))) {
          return this.swal.warning('Não é possível definir datas anteriores à data atual.');
        }
        if (this.onSave) {
          this.onSave(this.priorityUntil);
        }
      }
    }
  });
  _exports.default = _default;
});