define("portal-talentrh/mixins/modal-route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    activate() {
      this._super();
      Ember.run.next(() => {
        (0, _jquery.default)('.' + this.modalName).modal('show');
      });
    },
    actions: {
      closeModal() {
        (0, _jquery.default)('.' + this.modalName).modal('hide');
        window.history.back();
      }
    }
  });
  _exports.default = _default;
});