define("portal-talentrh/pods/company/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model(params, transition) {
      const {
        queryParams: {
          notAcceptedPolicies
        },
        name
      } = transition.to;
      return {
        notAcceptedPolicies,
        routeName: name
      };
    },
    afterModel(_ref) {
      let {
        notAcceptedPolicies,
        routeName
      } = _ref;
      const {
        companyConfig
      } = this.permission;
      const routes = [{
        name: 'company.userlist',
        permission: companyConfig?.permitContactList
      }, {
        name: 'company.album',
        permission: companyConfig?.permitCompanyPhotos
      }, {
        name: 'company.minutes-of-meeting',
        permission: companyConfig?.permitMeetingMinute
      }, {
        name: 'company.benefits',
        permission: companyConfig?.permitBenefit
      }, {
        name: 'company.chart',
        permission: companyConfig?.permitChart
      }, {
        name: 'company.policy',
        permission: companyConfig?.permitPolicy
      }, {
        name: 'company.programs',
        permission: companyConfig?.permitCompanyPrograms
      }, {
        name: 'company.decision',
        permission: companyConfig?.permitDecisionMaking
      }, {
        name: 'company.job-function',
        permission: true
      }, {
        name: 'company.professional-progression',
        permission: true
      }];
      const foundRoutePermitted = routes.reduce((routePermitted, _ref2) => {
        let {
          name,
          permission
        } = _ref2;
        if (routeName.includes(name) && permission) routePermitted = {
          name: routeName
        };
        return routePermitted;
      }, null);
      let route = foundRoutePermitted ? foundRoutePermitted : routes.find(r => r.permission);
      if (notAcceptedPolicies && companyConfig?.permitPolicy) {
        route = {
          name: 'company.policy'
        };
      }
      this.transitionTo(route?.name);
    }
  });
  _exports.default = _default;
});