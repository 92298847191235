define("portal-talentrh/pods/portal-manager/request/filter/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PortalManagerRequestFilterController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed.alias('permission.companyConfig'), _dec5 = Ember.computed.alias('companyConfig.userSolicitationConfigs'), _dec6 = Ember.computed.alias('companyConfig.managerSolicitationConfigs'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember.computed('permission.led.length'), _dec17 = Ember._action, (_class = class PortalManagerRequestFilterController extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "permission", _descriptor3, this);
      _initializerDefineProperty(this, "companyConfig", _descriptor4, this);
      _initializerDefineProperty(this, "userSolicitationConfigs", _descriptor5, this);
      _initializerDefineProperty(this, "managerSolicitationConfigs", _descriptor6, this);
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      _initializerDefineProperty(this, "ledType", _descriptor8, this);
      _initializerDefineProperty(this, "initialDate", _descriptor9, this);
      _initializerDefineProperty(this, "finalDate", _descriptor10, this);
      _initializerDefineProperty(this, "departmentsSelected", _descriptor11, this);
      _initializerDefineProperty(this, "branchesSelected", _descriptor12, this);
      _initializerDefineProperty(this, "jobRolesSelected", _descriptor13, this);
      _initializerDefineProperty(this, "jobFunctionsSelected", _descriptor14, this);
      _initializerDefineProperty(this, "selectedType", _descriptor15, this);
      _defineProperty(this, "solicitationTypes", [{
        label: 'Todas',
        value: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        utilize: ['notUseWarning', 'notUseChangeFunction', 'notUseChangeHorary', 'notUseChangeSalary', 'useIgnoreManagerApprovalToJustification', 'notUseAbsenceJustification', 'useIgnoreManagerApprovalToThirteen', 'useIgnoreManagerApprovalToAdvance', 'useIgnoreManagerApprovalToRegistrationData', 'useIgnoreManagerApprovalToCompensatoryTime', 'notUseTimeBankRequest', 'useIgnoreManagerApprovalToBenefits', 'notUseBenefitsRequest', 'useIgnoreManagerApprovalToUniform', 'useIgnoreManagerApprovalToOthers', 'notUseOtherSolicitations', 'useIgnoreManagerApprovalToTermination', 'notUseTerminationEmployed']
      }, {
        label: 'Advertência/Suspensão',
        value: 5,
        utilize: 'notUseWarning'
      }, {
        label: 'Alteração de função',
        value: 2,
        utilize: 'notUseChangeFunction'
      }, {
        label: 'Alteração de horário',
        value: 3,
        utilize: 'notUseChangeHorary'
      }, {
        label: 'Alteração de salário',
        value: 1,
        utilize: 'notUseChangeSalary'
      }, {
        label: 'Justificativa de ponto',
        value: 14,
        utilize: ['useIgnoreManagerApprovalToJustification', 'notUseAbsenceJustification']
      }, {
        label: 'Adiantamento de 13º',
        value: 10,
        utilize: 'useIgnoreManagerApprovalToThirteen'
      }, {
        label: 'Adiantamento salarial',
        value: 13,
        utilize: 'useIgnoreManagerApprovalToAdvance'
      }, {
        label: 'Alteração de dados cadastrais',
        value: 11,
        utilize: 'useIgnoreManagerApprovalToRegistrationData'
      }, {
        label: 'Banco de horas',
        value: 15,
        utilize: ['useIgnoreManagerApprovalToCompensatoryTime', 'notUseTimeBankRequest']
      }, {
        label: 'Benefícios',
        value: 9,
        utilize: ['useIgnoreManagerApprovalToBenefits', 'notUseBenefitsRequest']
      }, {
        label: 'Férias',
        value: 4,
        utilize: ['useIgnoreManagerApprovalToVacation', 'notUseVacancyPeriod']
      }, {
        label: 'Uniforme',
        value: 12,
        utilize: 'useIgnoreManagerApprovalToUniform'
      }, {
        label: 'Outros itens',
        value: 8,
        utilize: ['useIgnoreManagerApprovalToOthers', 'notUseOtherSolicitations']
      }, {
        label: 'Pedido de demissão',
        value: 7,
        utilize: ['useIgnoreManagerApprovalToTermination', 'notUseTerminationEmployed']
      }]);
    }
    get showFilterLed() {
      return this.permission.led && this.permission.led.length;
    }
    findAndShowRequests() {
      const {
        onChangeFilters
      } = this.args;
      try {
        this.filters = this.getFilters();
        this.filters.route = this.router.currentRouteName;
        onChangeFilters(this.filters);
      } catch (e) {
        return this.swal.catch(e);
      }
    }
    get solicitations() {
      if (this.managerSolicitationConfigs && this.solicitationTypes && this.userSolicitationConfigs) {
        this.solicitationTypes.forEach(solicitation => {
          if (Ember.get(this, `managerSolicitationConfigs.${solicitation.utilize}`) !== undefined) {
            solicitation.use = Ember.get(this, `managerSolicitationConfigs.${solicitation.utilize}`);
          }
          if (Ember.get(this, `userSolicitationConfigs.${solicitation.utilize}`) !== undefined) {
            if (solicitation.use) {
              solicitation.use = Ember.get(this, `userSolicitationConfigs.${solicitation.utilize}`);
            }
          }
        });
        this.solicitationTypes = this.solicitationTypes.filter(solicitation => {
          return !solicitation.use;
        });
      }
      return this.solicitationTypes;
    }
    getFilters() {
      const filters = {};
      filters.createdAt = (0, _moment.default)(this.initialDate).format('YYYY-MM-DD HH:mm');
      filters.finalDate = (0, _moment.default)(this.finalDate).format('YYYY-MM-DD HH:mm');
      if (this.departmentsSelected.length) {
        filters.user = filters.user || {};
        filters.user.department = this.departmentsSelected.mapBy('id');
      }
      if (this.branchesSelected.length) {
        filters.branch = this.branchesSelected.mapBy('id');
      }
      if (this.jobFunctionsSelected.length) {
        filters.user = filters.user || {};
        filters.user.jobFunction = this.jobFunctionsSelected.mapBy('id');
      }
      if (this.selectedType) {
        filters.solicitationType = this.selectedType.value;
      }
      if (this.status) {
        filters.status = this.status.value;
      }
      if (this.ledType) {
        filters.ledType = this.ledType;
      }
      if (this.router.currentRouteName !== 'portal-manager.request.completed.index') {
        filters.pending = 's';
      }
      filters.skip = 0;
      filters.sort = 'createdAt DESC';
      return filters;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "companyConfig", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userSolicitationConfigs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "managerSolicitationConfigs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ledType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'd';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().startOf('month').toDate();
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "finalDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().toDate();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "departmentsSelected", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "branchesSelected", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "jobRolesSelected", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionsSelected", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "selectedType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        label: 'Todas',
        value: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15]
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showFilterLed", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "showFilterLed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findAndShowRequests", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "findAndShowRequests"), _class.prototype)), _class));
  _exports.default = PortalManagerRequestFilterController;
});