define("portal-talentrh/pods/components/feed/post-comments/comment/edit-field/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['comment__edit-form', 'form', 'w-100'],
    toast: Ember.inject.service(),
    ajax: Ember.inject.service(),
    editComment: (0, _emberConcurrency.task)(function* () {
      try {
        let comment = this.comment;
        yield this.ajax.put(`/comments/${comment.id}`, {
          data: {
            comment
          }
        });
        const comments = this.post.comments;
        const index = comments.findIndex(c => c.id === comment.id);
        if (index >= 0) {
          comments[index] = comment;
        }
        Ember.set(this.post, 'comments', [...comments]);
        this.toast.success('Comentário editado');
        Ember.set(this, 'editMode', false);
      } catch (e) {
        console.log(e);
        this.toast.error('No momento estamos tendo problema para editar seu comentário, tente novamente mais tarde.');
      }
    }).drop(),
    actions: {
      cancelCommentEdit() {
        Ember.set(this, 'editMode', false);
      },
      autoGrow() {
        const element = document.getElementById(`input-reply${this.comment.id}`);
        element.style.height = element.scrollHeight + 'px';
      }
    }
  });
  _exports.default = _default;
});