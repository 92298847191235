define("portal-talentrh/pods/confirm-account-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C7JXUVYb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-12 text-center mt-5\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/email-error.png\"],[14,\"width\",\"10%\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-2\"],[12],[2,\"Desculpe, não foi possível validar seu e-mail.\"],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Voltar para o início\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"col-12 text-center mt-5\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/email-success.png\"],[14,\"width\",\"10%\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-2\"],[12],[2,\"Seu endereço de e-mail foi confirmado.\"],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Voltar para o início\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/confirm-account-email/template.hbs"
    }
  });
  _exports.default = _default;
});