define("portal-talentrh/pods/absence-board/calendar/controller", ["exports", "ember-concurrency-decorators", "@fullcalendar/core", "@fullcalendar/daygrid", "@fullcalendar/core/locales/pt-br"], function (_exports, _emberConcurrencyDecorators, _core, _daygrid, _ptBr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let AbsenceBoardCalendarController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class AbsenceBoardCalendarController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "filters", _descriptor3, this);
    }
    renderCalendar(element) {
      let calendar = new _core.Calendar(element, {
        locale: _ptBr.default,
        plugins: [_daygrid.default],
        height: 'parent',
        eventDisplay: 'block',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        events: (info, successCallback, failureCallback) => {
          return this.loadUserMessages.perform(info, successCallback, failureCallback);
        },
        eventClick: info => {
          // Esse find é realizado para evitar o loading automático que ocorre no application
          this.store.findRecord('user-message', info.event.id).then(userMessage => {
            this.transitionToRoute('absence-board.calendar.show', userMessage);
          });
        }
      });
      calendar.render();
      this.calendar = calendar;
    }
    *loadUserMessages(info, successCallback, failureCallback) {
      try {
        let data = {
          start: info.startStr,
          end: info.endStr
        };
        data.branches = (this.filters.branches || []).mapBy('id');
        data.departments = (this.filters.departments || []).mapBy('id');
        data.jobRoles = (this.filters.jobRoles || []).mapBy('id');
        data.users = (this.filters.users || []).mapBy('id');
        data.ledByMe = this.filters.ledByMe || null;
        let response = yield this.fetch.request(`userMessages/calendar`, {
          data
        });
        return successCallback(response);
      } catch (e) {
        Ember.debug(`UserMessage controller :: loadUserMessages :: ${e}`);
        return failureCallback(e);
      }
    }
    refetchEvents() {
      this.calendar.refetchEvents();
    }
    clearFilter() {
      this.filters = {
        departments: [],
        jobRoles: [],
        users: [],
        ledByMe: false
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "renderCalendar", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "renderCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadUserMessages", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadUserMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refetchEvents", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refetchEvents"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype)), _class));
  _exports.default = AbsenceBoardCalendarController;
});