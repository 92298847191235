define("portal-talentrh/models/user-link", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Informe o nome do link.'
    }),
    link: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Informe o link.'
    }), (0, _emberCpValidations.validator)('format', {
      type: 'url',
      message: 'Formato inválido, utilize o http:// antes do link. Exemplo: https://www.voors.com.br/'
    })]
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    color: (0, _model.attr)('string'),
    imageUrl: (0, _model.attr)('string'),
    link: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    public: (0, _model.attr)('boolean'),
    signedUrl: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user'),
    // Associations
    company: (0, _relationships.belongsTo)('company'),
    createdBy: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});