define("portal-talentrh/mirage/config", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    this.urlPrefix = 'http://localhost:1337'; // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '/api';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */

    // this.passthrough();

    this.get('/accessProfiles/me', function (schema) {
      return schema.accessProfiles.first();
    });
    this.get('/branches/ajax', function () {
      return [{
        "id": '1',
        "name": "estabelecimento 1",
        "tradingName": "estabelecimento 1"
      }, {
        "id": '2',
        "name": "estabelecimento 2",
        "tradingName": "estabelecimento 2"
      }];
    });
    this.get('/chat/usergroups', function () {
      return [];
    });
    this.resource('city');
    this.get('/companyConfigurations/:companyId', function (schema, request) {
      const companyConfiguration = schema.companyConfigurations.findBy({
        companyId: request.params.companyId
      });
      return {
        companyConfiguration
      };
    });
    this.get('/departments/departmentsICanApproveSolicitation', function () {
      return [17, 18];
    });
    this.get('/instructionLevels/:id');
    this.get('/leadershipRequests', function () {
      return {
        'leadership-requests': []
      };
    });
    this.get('/notifications', function () {
      return {
        notifications: []
      };
    });
    this.get('/notifications/unread', function () {
      return [];
    });
    this.get('/pdis', function () {
      return {
        pdis: []
      };
    });
    this.get('/policies/notAcceptedPolicies', function () {
      return [];
    });
    this.get('/postTags', function () {
      return [];
    });
    this.get('/quizAnswers/pending', function () {
      return [];
    });
    this.get('/readjustReasons/ajax', () => {
      return [{
        "company": 12,
        "description": "REAJUSTE SALARIAL COLETIVO ANU",
        "remoteId": "1",
        "id": 1,
        "createdAt": "2021-06-11T20:12:04.000Z",
        "updatedAt": "2021-06-11T20:18:54.000Z"
      }];
    });
    this.get('/showIntroToPage', function () {
      return {
        alreadyAccessed: {}
      };
    });
    this.get('/userPreferences/:userId/accountPreferences', function (schema, request) {
      let userId = request.params.userId;
      return schema.userPreferences.findBy({
        userId
      });
    });
    this.get('/users/:id');
    this.get('/users/:id/branches', function (schema, request) {
      return [{
        "id": '1',
        "name": "estabelecimento 1",
        "tradingName": "estabelecimento 1"
      }, {
        "id": '2',
        "name": "estabelecimento 2",
        "tradingName": "estabelecimento 2"
      }];
    });
    this.get('/users/:id/feelingtoday', function () {
      return {
        answered: true
      };
    });
    this.get('/users/:id/possibleLeaders', function () {
      return [];
    });
    this.get('/users/:id/projectTaskInfo', function () {
      return;
    });
    this.get('/users/:id/userLinks', function () {
      return [];
    });
    this.get('/users/admissiontoday', function () {
      return [];
    });

    // TODO: criar mirage model e retornar do schema
    this.get('/users/me/userAccount', function (schema) {
      const user = schema.users.first();
      return {
        id: user.id,
        validatedEmail: user.personalEmail
      };
    });
    this.get('/users/openActivities', function () {
      return {};
    });
    this.get('/users/pendingapproval', function () {
      return [];
    });
    this.get('/users/recentlyAdmitted', function () {
      return [];
    });
    this.get('/users/userLinks', function () {
      return {
        userLinks: []
      };
    });
    this.get('/userSkills/findByUser', function () {
      return {
        hards: [],
        softs: []
      };
    });
    this.post('/userSolicitationAttachments', function () {
      return {};
    });
    this.resource('user-solicitations', {
      path: 'userSolicitations/manager'
    });
    this.resource('user-solicitations', {
      path: 'userSolicitations'
    });
    this.get('/versions/latest', function () {
      return [];
    });
    this.get('/widgets/birthday', function () {
      return [];
    });
    this.get('/users/userSubordinateAjax', function (schema) {
      return schema.users.where({
        appraisedById: 434
      });
    });

    // API do conta
    this.get('http://localhost:1341/wizardStatuses/me', function () {
      return {
        wizardStatus: {
          done: true
        }
      };
    });
    this.post('http://localhost:1341/auth/account', function () {
      return {
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQWNjb3VudCI6eyJpZCI6MjcyLCJjcGYiOiIwODMzNjI5Mzk1NyJ9LCJpYXQiOjE2MzM5NTg2MzUsImV4cCI6MTYzMzk2MDQzNX0.l-hZjsqoQMmCD3Poln4JM9JM33kfG9cXlEG9AQsL0sU",
        "users": [{
          "id": 434,
          "companyId": 12,
          "companyName": "JUJUTSU ACADEMY"
        }, {
          "id": 4052,
          "companyId": 145,
          "companyName": "Noelle Silva"
        }],
        "userAccount": {
          "email": null,
          "cellphone": "46991301997",
          "validatedEmail": false,
          "validatedCellphone": true
        }
      };
    });
    this.get('http://localhost:1341/passwordHistories/hasChangedPassword', function () {
      return {};
    });
    this.post('http://localhost:1341/login', function () {
      return {
        "company": {
          "id": 12,
          "name": "JUJUTSU ACADEMY"
        },
        "user": {
          "active": true,
          "admin": true,
          "agreedToUseTerms": true,
          "avatarUrl": "profile-images/ae9b5300-c4c0-46a6-88fe-d265a8cf974d.jpg",
          "away": false,
          "status": "active",
          "superUser": true,
          "company": 12,
          "fullName": "Gojou Soturo",
          "googleOAuth": true,
          "id": 434
        },
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo0MzQsImNvbXBhbnkiOjEyfSwiaWF0IjoxNjMzOTU4NjcyLCJleHAiOjE2MzQwNDUwNzJ9.-n6cy4UR8JG8rzLLcu2ZCjqjFre3Ik7jEp_ZngPtYzg",
        "userAccountToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQWNjb3VudCI6eyJpZCI6MjcyLCJjcGYiOiIwODMzNjI5Mzk1NyJ9LCJpYXQiOjE2MzM5NTg2MzUsImV4cCI6MTYzMzk2MDQzNX0.l-hZjsqoQMmCD3Poln4JM9JM33kfG9cXlEG9AQsL0sU"
      };
    });

    // API do Core
    this.get('http://localhost:3001/posts', function (schema) {
      return schema.posts.all();
    });
    this.passthrough();
    console.log(_environment.default);
  }
});