define("portal-talentrh/pods/home/human-resources/feedback-details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeHumanResourcesFeedbackDetailsRoute extends Ember.Route {
    deactivate() {
      this.controller.filtersSelected = {};
    }
  }
  _exports.default = HomeHumanResourcesFeedbackDetailsRoute;
});