define("portal-talentrh/pods/components/professional-progression-board/component", ["exports", "ember-concurrency-decorators", "moment"], function (_exports, _emberConcurrencyDecorators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProfessionalProgressionBoardComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed.filterBy('jobFunctionDetails.skills', 'type', 'hard'), _dec10 = Ember.computed.filterBy('jobFunctionDetails.skills', 'type', 'soft'), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = (0, _emberConcurrencyDecorators.dropTask)(), (_class = class ProfessionalProgressionBoardComponent extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fetch", _descriptor, this);
      _initializerDefineProperty(this, "swal", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "utils", _descriptor5, this);
      _initializerDefineProperty(this, "hierarchy", _descriptor6, this);
      _initializerDefineProperty(this, "jobFunctionDetails", _descriptor7, this);
      _initializerDefineProperty(this, "trainings", _descriptor8, this);
      _initializerDefineProperty(this, "hardSkills", _descriptor9, this);
      _initializerDefineProperty(this, "softSkills", _descriptor10, this);
      _defineProperty(this, "colors", ['#3500D5', '#9C27B0', '#36A2EB', '#FF6384', '#F4BE37', '#4BC0C0', '#FF784D', '#CC0088', '#54548C', '#0263FF', '#FC9C20', '#E82626', '#465773', '#FF4069']);
    }
    getColor(index) {
      return this.colors[index % this.colors.length];
    }
    prepareData(data) {
      const node = {
        id: this.utils.generateUUID(),
        jobFunctionId: data.jobFunctionId,
        name: data.jobFunctionTitle,
        children: data.nextLevels?.map(nextLevel => this.prepareData(nextLevel)),
        level: data.level,
        isUserFunction: data.isUserFunction,
        sectorName: data.sectorName,
        boardTitle: data.boardTitle,
        date: data.date ? (0, _moment.default)(data.date).format('DD/MM/YYYY') : undefined
      };
      return node;
    }
    setHierarchy(data) {
      // first level is always the sector unless it's feed/me
      const hierarchy = data.map(item => {
        if (item.sectorId) {
          return {
            id: item.sectorId,
            name: item.sectorTitle,
            children: item.jobFunctions.map(jobFunction => this.prepareData(jobFunction))
          };
        }
        return this.prepareData(item);
      });
      this.hierarchy = hierarchy;
    }
    getFlattenedData(dataHierarchy) {
      const descendants = d3.hierarchy(dataHierarchy).descendants();
      descendants.forEach((d, i) => {
        d.id = d.id || 'id' + i;
      });
      return descendants.map(d => [d.parent?.data?.id, d.data]).map(_ref => {
        let [parentId, data] = _ref;
        const copy = {
          ...data
        };
        delete copy.children;
        return {
          ...copy,
          ...{
            parentId
          }
        };
      });
    }
    drawChart() {
      this.setHierarchy(this.data);
      const that = this;
      const fakeRoot = {
        id: 'fake-root',
        name: 'fake-root',
        children: this.hierarchy
      };
      const flattenedData = this.getFlattenedData(fakeRoot);
      const chart = new d3.OrgChart().container('#professional-progression-board-container');
      const crudWrapper = document.getElementById('professional-progression-crud-container');

      //layout
      chart.layout('left').compact(false).initialExpandLevel(this.initialLevel ?? 1).setActiveNodeCentered(false).svgHeight(crudWrapper.offsetHeight).svgWidth(crudWrapper.offsetWidth).rootMargin(-160);

      // node
      chart.nodeWidth(() => 150).nodeHeight(() => 150);
      chart.nodeContent(_ref2 => {
        let {
          data
        } = _ref2;
        const index = data?.level ?? 0;
        const youAreHere = `<i class="blink fa fa-map-marker-alt text-primary fa-2x mt-1" aria-hidden="true" title="Você está aqui"></i>`;
        const levelBadge = `
        <div class="p-1 position-absolute r-2 t-2 rounded" style="background-color: gold;">
          <span>Nível ${data.level}</span>
        </div>
      `;
        const name = data.name + (data.sectorName ? ' - ' + data.sectorName : '');
        return `
          <div
            class="d-flex flex-column w-100 h-100 position-relative justify-content-center align-items-center"
            style="box-shadow: 12px 12px 0px 0px ${this.getColor(index)}; border: 2px solid ${this.getColor(index)}; border-radius: 15%;"
          >
              ${data?.level ? levelBadge : ''}
              <p
                title="${data.name}"
                class="text-center overflow-hidden ${data?.isUserFunction && 'mt-4'}"
                style="font: 16px SourceSansPro; max-width: 120px; max-height: 100px;"
              >
                ${name}
              </p>
              ${data?.isUserFunction ? youAreHere : ''}
              ${data?.date ?? ''}
          </div>
        `;
      });
      chart.nodeUpdate(function (d) {
        if (d.data.parentId == null) {
          d3.select(this).remove();
        }
        if (d.data.id !== 'fake-root' && d.data.jobFunctionId) {
          d3.select(this).on('click', function () {
            that.getJobFunctionDetails.perform(d.data.jobFunctionId);
          });
        }
      });

      // link
      chart.linkUpdate(function (d) {
        const index = d.parent.data?.level ?? 0;
        if (d.parent && d.parent.data.parentId == null) {
          d3.select(this).remove();
        }
        d3.select(this).attr('stroke', () => that.getColor(index)).attr('stroke-width', () => 2);
      });

      // button
      chart.buttonContent(function (d) {
        const index = d.node.data?.level ?? 0;
        return `
            <div
              style="
                border-radius: 8px;
                border: 1px solid ${that.getColor(index)};
                padding: 9px 10px 8px 10px;
                margin: auto auto;
                background-color: white;
            >
              <span style="font-size: 14px">
               ${d.node.children ? `<i class="fas fa-minus"></i>` : `<i class="fas fa-plus"></i>`}
              </span>
            </div>
      `;
      });

      // action buttons
      const btnZoomIn = document.getElementById('professional-progression-button-zoom-in');
      btnZoomIn.onclick = function () {
        chart.zoomIn();
      };
      const btnZoomOut = document.getElementById('professional-progression-button-zoom-out');
      btnZoomOut.onclick = function () {
        chart.zoomOut();
      };
      const btnZoomFit = document.getElementById('professional-progression-button-zoom-fit');
      btnZoomFit.onclick = function () {
        const centeringNodes = chart.getChartState().allNodes.filter(node => node.data.id !== 'fake-root');
        chart.fit({
          node: centeringNodes
        });
      };

      // render
      try {
        chart.data(flattenedData).render();
      } catch (e) {
        if (e.message.includes('ambiguous')) {
          this.swal.error('Há algo errado com a estrutura da progressão. Por favor, verifique por dados ambiguos.');
          return;
        }
        this.swal.error(e);
      }
    }
    clearChart() {
      const professionalProgressionDiv = document.querySelector('#professional-progression-board-container');
      if (!professionalProgressionDiv || !professionalProgressionDiv.firstElementChild) {
        return;
      }
      professionalProgressionDiv.firstElementChild.remove();
    }
    redrawChart() {
      this.clearChart();
      this.drawChart();
    }
    closeModal() {
      this.jobFunctionDetails = null;
    }
    onChangeSelectedTraining(training, index) {
      this.trainings.forEach(trainingFounded => {
        if (trainingFounded.id !== training.id) {
          Ember.set(trainingFounded, 'show', false);
        }
      });
      Ember.set(this.trainings[index], 'show', !this.trainings[index]?.show);
    }
    *getJobFunctionDetails(jobFunctionId) {
      try {
        const jobFunctionDetails = (yield this.fetch.request(`jobFunctions/${jobFunctionId}`, {
          data: {
            populateRelations: true
          }
        }))?.jobFunction;
        this.jobFunctionDetails = jobFunctionDetails;
        this.trainings = jobFunctionDetails.populatedTrainings;
      } catch (e) {
        this.swal.error(e);
      }
    }
    get homeTime() {
      if (!this.jobFunctionDetails.homeTime) {
        return 'Não informado';
      }
      return this.jobFunctionDetails.homeTime > 1 ? `${this.jobFunctionDetails.homeTime} anos` : `${this.jobFunctionDetails.homeTime} ano`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hierarchy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "jobFunctionDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "trainings", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hardSkills", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "softSkills", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "drawChart", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "drawChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearChart", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "clearChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redrawChart", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "redrawChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSelectedTraining", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSelectedTraining"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getJobFunctionDetails", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "getJobFunctionDetails"), _class.prototype)), _class));
  _exports.default = ProfessionalProgressionBoardComponent;
});