define("portal-talentrh/pods/components/social-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/mxS+lWM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,2],null,[[\"Header\",\"Menu\",\"Section\"],[[30,[36,0],[\"social-page/header\"],null],[30,[36,0],[\"social-page/menu\"],[[\"didRender\"],[[30,[36,1],[[32,0],\"enableMenu\"],null]]]],[30,[36,0],[\"social-page/section\"],[[\"menuEnabled\"],[[32,0,[\"menuEnabled\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/social-page/template.hbs"
    }
  });
  _exports.default = _default;
});