define("portal-talentrh/pods/components/post-share-news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1QFwSknS",
    "block": "{\"symbols\":[\"Modal\",\"Footer\",\"Avatar\",\"@openModal\",\"@article\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\",\"@size\",\"@toggle\",\"@onClose\"],[[32,4],\"lg\",[30,[36,1],[[30,[36,0],[[32,4]],null]],null],[30,[36,1],[[32,0,[\"closeModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Compartilhar Notícia\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"py-3\"],[12],[2,\"\\n      \"],[8,\"ui-avatar\",[[24,0,\"flex-shrink-0 mr-3\"]],[[\"@user\"],[[32,0,[\"session\",\"user\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"Img\"]],[[24,0,\"avatar-5\"],[16,\"alt\",[32,0,[\"session\",\"user\",\"fullName\"]]]],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[3]}]]],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Contribua com a publicação...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"ui-textarea\",[[24,1,\"input-body\"],[24,0,\"form-control border\"],[24,\"autocomplete\",\"off\"]],[[\"@value\",\"@placeholder\"],[[32,0,[\"body\"]],\"O que você está pensando?\"]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"py-3\"],[12],[2,\"\\n      \"],[8,\"preview-link\",[],[[\"@url\",\"@isPreview\",\"@article\"],[[32,5,[\"link\"]],true,[32,5]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Close\"]],[],[[],[]],null],[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[16,\"onclick\",[30,[36,2],[[32,0,[\"sharePost\"]]],null]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Compartilhar\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-share-news/template.hbs"
    }
  });
  _exports.default = _default;
});