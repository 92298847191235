define("portal-talentrh/pods/components/indicators/organizational-climate/feelings-negative-positive/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FeelingsNegativePositiveComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class FeelingsNegativePositiveComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "utils", _descriptor, this);
      _initializerDefineProperty(this, "positivos", _descriptor2, this);
      _initializerDefineProperty(this, "negativos", _descriptor3, this);
      _initializerDefineProperty(this, "label", _descriptor4, this);
      _initializerDefineProperty(this, "colors", _descriptor5, this);
    }
    loadChart(canvas) {
      let that = this;
      let {
        pieChart
      } = this.args.data;
      if (!pieChart) return null;
      if (pieChart.labels) {
        const sum = pieChart.data.reduce((partialSum, a) => partialSum + a, 0);
        this.positivos = Math.round(pieChart.data[0] / sum * 100);
        this.negativos = Math.round(pieChart.data[1] / sum * 100);
        pieChart.labels[0] += ` (${pieChart.data[0]}) - ${this.positivos}%`;
        pieChart.labels[1] += ` (${pieChart.data[1]}) - ${this.negativos}%`;
        new _chartJs.default(canvas.getContext('2d'), {
          type: 'doughnut',
          data: {
            labels: pieChart.labels,
            datasets: [{
              backgroundColor: that.args.colors ? that.args.colors : that.colors,
              borderColor: ['#FFFFFF', '#FFFFFF'],
              label: 'Sentimentos',
              data: pieChart.data,
              borderWidth: 2
            }]
          },
          plugins: [{
            afterDraw: function (chart) {
              that.utils.addTextInsideChart(chart);
            }
          }],
          options: {
            elements: {
              center: {
                text: `Sentimento predominante ${this.positivos > this.negativos ? 'positivo' : 'negativo'}`,
                valueText: this.positivos > this.negativos ? `${this.positivos}%` : `${this.negativos}%`,
                color: '#666666',
                fontStyle: 'SourceSansPro',
                sidePadding: that.args.sidePadding ? that.args.sidePadding : 30,
                minFontSize: that.args.minFontSize ? that.args.minFontSize : 14,
                lineHeight: that.args.lineHeight ? that.args.lineHeight : 20
              }
            },
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            rotation: 180,
            plugins: {
              legend: {
                display: false,
                labels: {
                  fontColor: '#666666',
                  usePointStyle: true,
                  pointStyle: 'rectRounded'
                }
              },
              tooltip: {
                yAlign: 'bottom',
                callbacks: {
                  label: function (context) {
                    return context.label;
                  }
                }
              }
            },
            onClick: function (e) {
              that.utils.onChartClick(this, that, e);
            },
            onHover: function (e) {
              if (that.args.hover) {
                that.utils.onChartHover(this, e);
              }
            }
          }
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "utils", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "positivos", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "negativos", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ['#3DBD90', '#E86A69'];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadChart", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadChart"), _class.prototype)), _class));
  _exports.default = FeelingsNegativePositiveComponent;
});