define("portal-talentrh/pods/components/post-comments/comment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7FR9GEV2",
    "block": "{\"symbols\":[\"@comments\",\"@comment\",\"@onlyShow\",\"&default\"],\"statements\":[[18,4,[[30,[36,5],null,[[\"moreOptions\",\"body\",\"likeBar\",\"replyField\",\"commentReplies\"],[[30,[36,3],[\"post-comments/comment/more-options\"],[[\"isOwner\",\"editMode\",\"commentId\"],[[32,0,[\"isOwner\"]],[35,4],[32,2,[\"id\"]]]]],[30,[36,3],[\"post-comments/comment/body\"],[[\"comment\",\"editMode\",\"comments\"],[[32,2],[35,4],[32,1]]]],[30,[36,3],[\"post-comments/comment/like-bar\"],[[\"comment\",\"showReplies\",\"showReplyField\",\"onlyShow\",\"editMode\"],[[32,2],[35,2],[35,1],[32,3],[35,4]]]],[30,[36,3],[\"post-comments/comment/reply-field\"],[[\"comment\",\"showReplies\",\"showReplyField\",\"comments\"],[[32,2],[35,2],[35,1],[32,1]]]],[35,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"commentReplies\",\"showReplyField\",\"showReplies\",\"component\",\"editMode\",\"hash\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-comments/comment/template.hbs"
    }
  });
  _exports.default = _default;
});