define("portal-talentrh/pods/profile/edit/index/privacy-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xRoriEjb",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"dropdown\"],[17,1],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-sm btn-light border dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,4,\"button\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"attr\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[12],[13],[2,\"\\n      Ocultado no perfil\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-globe\"],[12],[13],[2,\"\\n      Exibido no perfil\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[14,\"aria-labelledby\",\"privacySelect\"],[12],[2,\"\\n    \"],[10,\"button\"],[14,0,\"dropdown-item\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"changePrivacy\"]],false],null]],[14,4,\"button\"],[12],[2,\"Mostrar no perfil\"],[13],[2,\"\\n    \"],[10,\"button\"],[14,0,\"dropdown-item\"],[15,\"onclick\",[30,[36,1],[[32,0,[\"changePrivacy\"]],true],null]],[14,4,\"button\"],[12],[2,\"Ocultar do perfil\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/edit/index/privacy-select/template.hbs"
    }
  });
  _exports.default = _default;
});