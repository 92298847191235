define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/evaluation-pending/component", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionUserId: Ember.computed.alias('session.user.id'),
    allowConsensusInEmployee180Evaluation: Ember.computed.alias('permission.companyConfig.allowConsensusInEmployee180Evaluation'),
    newPerformanceModule: Ember.computed.alias('permission.companyConfig.featureFlags.newPerformanceModule'),
    canAnswerConsensus: Ember.computed('sessionUserId', 'allowConsensusInEmployee180Evaluation', 'employeeEvaluationTeams.length', 'evaluationSelected.{appraiserUser,consensusAnswer}', 'evaluation.{appraisedConcluded,appraiserConcluded,consensusConcluded}', function () {
      if (this.employeeEvaluationTeams?.length > 0) {
        return;
      } else {
        const appraiserId = this.evaluationSelected.appraiserUser;
        const consensusAnswer = this.evaluationSelected.consensusAnswer;
        const appraisedConcluded = this.evaluation.appraisedConcluded;
        const appraiserConcluded = this.evaluation.appraiserConcluded;
        const consensusConcluded = this.evaluation.consensusConcluded;
        return appraisedConcluded && appraiserConcluded && !consensusConcluded && consensusAnswer && this.allowConsensusInEmployee180Evaluation && appraiserId === this.sessionUserId;
      }
    }),
    canRate: Ember.computed('evaluation.{appraisedConcluded,appraiserConcluded,id,isAvailable,appraisedUser.id,appraiserUser.id}', 'evaluationSelected.periodExpired', 'sessionUserId', function () {
      const appraiserConcluded = this.evaluation.appraiserConcluded;
      const periodExpired = this.evaluationSelected.periodExpired;
      return !periodExpired && !appraiserConcluded;
    }),
    urlAnswer: Ember.computed('evaluationSelected.id', 'newPerformanceModule', function () {
      if (this.newPerformanceModule) {
        return _environment.default.appUrl['performance'] + `evaluations/${this.evaluationSelected.id}/answer`;
      }
      return _environment.default.appUrl['avd'] + `evaluation/answer/${this.evaluationSelected.id}`;
    }),
    urlAnswerConsensus: Ember.computed('evaluationSelected.id', 'newPerformanceModule', function () {
      if (this.newPerformanceModule) {
        return _environment.default.appUrl['performance'] + `evaluations/${this.evaluationSelected.id}/answer`;
      }
      return _environment.default.appUrl['avd'] + `evaluation/answer-consensus/${this.evaluationSelected.id}`;
    })
  });
  _exports.default = _default;
});