define("portal-talentrh/pods/components/post-attachment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xocemriJ",
    "block": "{\"symbols\":[\"@showDelete\"],\"statements\":[[10,\"div\"],[14,0,\"card-body d-flex align-items-center p-3\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,[36,3],[[35,2,[\"type\"]]],null]],[14,0,\"nela-post-attachment__icon mr-2\"],[15,\"alt\",[34,2,[\"name\"]]],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text-truncate w-100\"],[15,\"title\",[34,2,[\"name\"]]],[12],[2,\"\\n    \"],[1,[35,2,[\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-primary\"],[15,\"onclick\",[30,[36,1],[[35,4]],null]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-download\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-sm btn-outline-danger ml-1\"],[15,\"onclick\",[30,[36,1],[[35,0]],null]],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"delete\",\"perform\",\"attachment\",\"task-attachment\",\"download\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/post-attachment/template.hbs"
    }
  });
  _exports.default = _default;
});