define("portal-talentrh/pods/components/indicators/company-performance/nine-box/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IndicatorsCompanyPerformanceNineBoxComponent extends _component.default {
    /**
     * Monta a matrix do ninebox desse forma
     * ⌜-----------------------------------------⌝
     * | quadrante 3 | quadrante 6 | quadrante 9 |
     * | quadrante 2 | quadrante 5 | quadrante 8 |
     * | quadrante 1 | quadrante 4 | quadrante 7 |
     * ⌞-----------------------------------------⌟
     */
    get quadrantsByLine() {
      let {
        lines,
        columns,
        quadrants
      } = this.args;
      const linesQuadrants = [];
      const columnsSize = columns.length;
      lines.forEach((_, index) => {
        const startIndex = columnsSize - index;
        const lineQuadrant = [];
        for (let column = 0; column < columnsSize; column++) {
          const quadrantIndex = startIndex + columnsSize * column - 1;
          lineQuadrant.push(quadrants.objectAt(quadrantIndex));
        }
        linesQuadrants.push(lineQuadrant);
      });
      return linesQuadrants;
    }
  }
  _exports.default = IndicatorsCompanyPerformanceNineBoxComponent;
});