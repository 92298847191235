define("portal-talentrh/pods/components/insight-action-buttons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vv1s2ex3",
    "block": "{\"symbols\":[\"@user\",\"@insight\",\"@insightLed\"],\"statements\":[[10,\"div\"],[14,0,\"insight-action-buttons justify-content-center\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[30,[36,0],[[32,2,[\"type\"]],\"birthday\"],null],[30,[36,0],[[32,2,[\"type\"]],\"admissionAnniversary\"],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"insight-action-buttons/birthday\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"type\"]],\"accessFrequency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"insight-action-buttons/access-frequency\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"type\"]],\"feeling\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"insight-action-buttons/feeling\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"type\"]],\"feedback\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"insight-action-buttons/feedback\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2,[\"type\"]],\"pdi\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"insight-action-buttons/pdi\",[],[[\"@user\",\"@insight\",\"@insightLed\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"or\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/insight-action-buttons/template.hbs"
    }
  });
  _exports.default = _default;
});