define("portal-talentrh/models/state", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    name: (0, _attr.default)('string'),
    // Associations
    cities: (0, _relationships.hasMany)('city'),
    country: (0, _relationships.belongsTo)('country')
  });
  _exports.default = _default;
});