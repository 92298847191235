define("portal-talentrh/pods/components/widgets/item/slide/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3znT+gm6",
    "block": "{\"symbols\":[\"record\",\"index\",\"&default\",\"@task\"],\"statements\":[[6,[37,7],[[32,4,[\"isRunning\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,[[30,[36,1],null,[[\"Loading\"],[[30,[36,0],[\"widgets/item/slide/loading\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[32,4,[\"last\",\"value\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],null,[[\"class\",\"autoplay\",\"arrows\",\"slidesToScroll\",\"slidesToShow\",\"autoplaySpeed\",\"dots\",\"lazyLoad\",\"beforeChange\"],[[32,0,[\"slickClass\"]],true,true,1,1,8000,false,\"ondemand\",[30,[36,5],[[32,0,[\"onBeforeChange\"]]],null]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4,[\"last\",\"value\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,3,[[30,[36,1],null,[[\"Item\",\"First\"],[[30,[36,0],[\"widgets/item/slide/item\"],[[\"record\"],[[32,1]]]],[30,[36,2],[[32,2],0],null]]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,3,[[30,[36,1],null,[[\"Empty\"],[[30,[36,0],[\"widgets/item/slide/empty\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"eq\",\"-track-array\",\"each\",\"perform\",\"slick-slider\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/widgets/item/slide/template.hbs"
    }
  });
  _exports.default = _default;
});