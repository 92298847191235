define("portal-talentrh/helpers/is-different-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function isDifferentDay(_ref /*, hash*/) {
    let [date1, date2] = _ref;
    return !(0, _moment.default)(date1).isSame(date2, 'day');
  });
  _exports.default = _default;
});