define("portal-talentrh/pods/components/indicator-block/img-feeling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oA8c1kb7",
    "block": "{\"symbols\":[\"@class\",\"&attrs\"],\"statements\":[[11,\"img\"],[16,\"src\",[32,0,[\"src\"]]],[16,\"title\",[32,0,[\"title\"]]],[16,0,[32,1]],[17,2],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/indicator-block/img-feeling/template.hbs"
    }
  });
  _exports.default = _default;
});