define("portal-talentrh/pods/components/infinite-post-scroll/component", ["exports", "jquery", "lodash", "portal-talentrh/config/environment", "ember-concurrency"], function (_exports, _jquery, _lodash, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LIMIT = 5;
  var _default = Ember.Component.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    fetch: Ember.inject.service(),
    didInsertElement: function () {
      const configuration = this.permission.companyConfig;
      let user = this.store.peekRecord('user', this.session.user.id);
      let profileUser = this.profileUser;
      (0, _jquery.default)(window).scroll(() => {
        let border = (0, _jquery.default)(document).height() - (0, _jquery.default)(window).height() - ((0, _jquery.default)(document).height() - (0, _jquery.default)(window).height()) * 0.02;
        if ((0, _jquery.default)(window).scrollTop() > border && !this.isLoadingMore && this.haveMorePosts) {
          const loadedPostsId = this.posts.mapBy('id');
          let where = {
            archived: this.type === 'archived' ? 1 : 0,
            complained: false,
            exclude: loadedPostsId,
            limit: 5,
            sort: 'priority Desc, createdAt DESC'
          };
          if (this.postTagFilter) {
            where.postTag = this.postTagFilter;
          } else if (this.type && this.type !== 'all' && this.type !== 'last' && this.type !== 'archived') {
            where.type = this.type;
          } else {
            if (!configuration.showAdsOnFeed && !this.profile) {
              where.excludeType = ['ad', 'vacancy'];
            } else {
              where.excludeType = ['vacancy'];
            }
          }
          if (this.type === 'ad' && this.validAd) {
            where.sold = this.sold;
          }
          if (this.profile) {
            where.user = profileUser.id;
          }
          if (this.userGroup) {
            where.userGroup = this.userGroup.id;
          }
          if (this.favorite) {
            let favorites = user.favoritedPosts.mapBy('id');
            where.id = _lodash.default.xor(loadedPostsId, favorites);
          }
          if (this.branches) {
            where.branches = this.branches;
          }
          Ember.set(this, 'isLoadingMore', true);
          this.loadPosts.perform(where);

          // this.store
          //   .loadRecords('post', '/posts', {
          //     data: where,
          //     host: 'core',
          //   })
          //   .then((records) => {
          //     records.map((record) => {
          //       this.posts.pushObject(record);
          //     });

          //     this.set('isLoadingMore', false);
          //     if (records.length < LIMIT) {
          //       this.set('haveMorePosts', false);
          //     }
          //   })
          //   .catch((e) => {
          //     this.set('error', e);
          //   });
        }
      });
    },

    willDestroyElement() {
      (0, _jquery.default)(window).unbind('scroll');
    },
    loadPosts: (0, _emberConcurrency.task)(function* (query) {
      try {
        let newPosts = yield this.fetch.request('/feed/posts', {
          host: _environment.default.apiUrl.core,
          data: query
        });
        newPosts = newPosts || [];
        newPosts.forEach(post => {
          this.posts.pushObject(post);
        });
        if (newPosts.length < LIMIT) {
          this.set('haveMorePosts', false);
        }
      } finally {
        this.set('isLoadingMore', false);
      }
    })
  });
  _exports.default = _default;
});