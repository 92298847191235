define("portal-talentrh/pods/components/notification-permission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "j9yNYcLU",
    "block": "{\"symbols\":[\"Modal\",\"Footer\"],\"statements\":[[8,\"ui-modal\",[],[[\"@show\"],[[32,0,[\"needsPermission\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Title\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Notificações\"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Ative as notificações do seu navegador para receber notificações de conversas pelo chat!\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"Submit\"]],[[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"requestPermission\"]]],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Vamos lá!\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"perform\",\"on\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/notification-permission/template.hbs"
    }
  });
  _exports.default = _default;
});