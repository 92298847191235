define("portal-talentrh/pods/components/viasoft-connect-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bttfERE/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"user\",\"company\"]],12],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/images/vc-action.png\"],[14,0,\"vs-connect__background\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"vs-connect__date-content\"],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ui-block\",\"session\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/viasoft-connect-widget/template.hbs"
    }
  });
  _exports.default = _default;
});