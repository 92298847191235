define("portal-talentrh/services/utils", ["exports", "portal-talentrh/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    generateUUID() {
      let d = new Date().getTime();
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
      }
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
    },
    generateS3Name(filename) {
      let uuid = this.generateUUID();
      let extension = filename.split('.').pop();
      return `${uuid}.${extension}`;
    },
    addTextInsideChart(chart) {
      //http://jsfiddle.net/danieljoeblack/feq1tpgm/6/
      if (chart.config.options.elements.center && chart.data.datasets.firstObject.data.length) {
        let ctx = chart.ctx;
        let centerConfig = chart.config.options.elements.center;
        let fontStyle = centerConfig.fontStyle || 'Arial';
        let txt = centerConfig.text;
        let valueText = centerConfig.valueText;
        let color = centerConfig.color || '#000';
        let maxFontSize = centerConfig.maxFontSize || 75;
        let sidePadding = centerConfig.sidePadding || 20;
        let sidePaddingCalculated = sidePadding / 100 * (chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2);
        ctx.font = '30px ' + fontStyle;
        let stringWidth = ctx.measureText(txt).width;
        let elementWidth = chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2 - sidePaddingCalculated;
        let widthRatio = elementWidth / stringWidth;
        let newFontSize = Math.floor(30 * widthRatio);
        let elementHeight = chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2;
        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
        let minFontSize = centerConfig.minFontSize;
        let lineHeight = centerConfig.lineHeight || 25;
        let wrapText = false;
        if (minFontSize === undefined) {
          minFontSize = 20;
        }
        if (minFontSize && fontSizeToUse < minFontSize) {
          fontSizeToUse = minFontSize;
          wrapText = true;
        }
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font = fontSizeToUse + 'px ' + fontStyle;
        ctx.fillStyle = color;
        if (!wrapText) {
          ctx.fillText(txt, centerX, centerY);
          return;
        }
        let words = txt.split(' ');
        let line = '';
        let lines = [];
        for (let n = 0; n < words.length; n++) {
          let testLine = line + words[n] + ' ';
          let metrics = ctx.measureText(testLine);
          let testWidth = metrics.width;
          if (testWidth > elementWidth && n > 0) {
            lines.push(line);
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }
        let linesLength = valueText ? lines.length + 1 : lines.length;
        centerY -= linesLength / 2 * lineHeight;
        let endLine;
        for (let n = 0; n < lines.length; n++) {
          ctx.fillText(lines[n], centerX, centerY);
          centerY += lineHeight;
          endLine = centerY;
        }
        ctx.fillText(line, centerX, centerY);
        if (valueText) {
          ctx.font = fontSizeToUse + 5 + 'px ' + fontStyle;
          ctx.fillText(valueText, centerX, endLine + lineHeight + 5);
        }
      }
    },
    onChartClick(chart, component, e) {
      const points = chart.getElementsAtEventForMode(e, 'nearest', {
        intersect: true
      }, true);
      if (points.length) {
        const firstPoint = points[0];
        const color = component.colors[firstPoint.index];
        const id = chart.data.ids ? chart.data.ids[firstPoint.index] : undefined;
        component.label = chart.data.labels[firstPoint.index];
        component.label = component.args.chartFilter != component.label ? component.label : null;
        let newColors = component.label ? component.colors.map(n => {
          if (n != color) {
            return n = '#CFCFCF';
          } else {
            return n;
          }
        }) : null;
        if (component.args.onChartClick) {
          component.args.onChartClick(component.label, newColors, newColors ? id : undefined);
        }
      }
    },
    onChartHover(chart, e) {
      const point = chart.getElementsAtEventForMode(e, 'nearest', {
        intersect: true
      }, true);
      if (point.length) {
        e.native.target.style.cursor = 'pointer';
      } else {
        e.native.target.style.cursor = 'default';
      }
    },
    getAuthTokenChat() {
      console.log(_environment.default.environment);
      const cookieKey = _environment.default.environment === 'production' ? 'authTokenp=' : 'authTokend=';
      console.log(cookieKey);
      console.log(document.cookie);
      const cookieValue = document.cookie.split('; ').find(row => row.startsWith(cookieKey))?.split('=')[1];
      if (!cookieValue) return null;
      const cookieDecoded = JSON.parse(decodeURIComponent(cookieValue));
      console.log(cookieDecoded.token);
      return cookieDecoded.token;
    }
  });
  _exports.default = _default;
});