define("portal-talentrh/pods/components/post-tag-select/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    // Default values
    postTags: null,
    // Tasks
    loadTags: (0, _emberConcurrency.task)(function* () {
      try {
        const postTags = yield this.store.query('post-tag', {
          active: 1
        });
        Ember.set(this, 'postTags', postTags);
      } catch (error) {
        Ember.set(this, 'error', 'Erro ao carregar as etiquetas');
      }
    }).on('didInsertElement'),
    // Actions
    actions: {
      setPostTag() {
        if (this.post) {
          Ember.set(this, 'post.postTag', this.selectedTag);
        }
        const onTagChange = this.onTagChange;
        if (onTagChange) {
          onTagChange(this.selectedTag);
        }
        Ember.set(this, 'openModal', false);
      },
      rollbackAttr() {
        if (!this.post.postTag) {
          Ember.set(this, 'selectedTag', null);
        }
        Ember.set(this, 'openModal', false);
      },
      closeModal() {
        Ember.set(this, 'openModal', false);
      },
      check() {
        if (!this.post.postTag) {
          Ember.set(this, 'selectedTag', null);
        }
      }
    }
  });
  _exports.default = _default;
});