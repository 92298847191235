define("portal-talentrh/pods/components/job-function-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JoNUM/lF",
    "block": "{\"symbols\":[\"Card\",\"@value\"],\"statements\":[[8,\"ui-card\",[[24,0,\"text-center card-job-function\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"pb-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"icon-user-star-line-card\"],[12],[13],[2,\"\\n    \"],[10,\"p\"],[15,\"title\",[32,2,[\"title\"]]],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,0,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"font-italic\"],[12],[2,\"Nenhuma descrição cadastrada\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Footer\"]],[[24,0,\"border-top-0 pt-0\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[10,\"button\"],[14,0,\"btn btn-outline-primary\"],[14,\"title\",\"Visualizar skills\"],[15,\"onclick\",[30,[36,2],[[30,[36,1],[[32,0,[\"showModal\"]],true],null]],null]],[12],[2,\"\\n      Visualizar\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[8,\"modal-job-function\",[],[[\"@show\",\"@value\"],[[32,0,[\"showModal\"]],[32,2]]],null]],\"hasEval\":false,\"upvars\":[\"if\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/job-function-card/template.hbs"
    }
  });
  _exports.default = _default;
});