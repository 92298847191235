define("portal-talentrh/pods/application/navbar/notification/share/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services

    fetch: Ember.inject.service(),
    // Tasks
    getSharedPost: (0, _emberConcurrency.task)(function* () {
      try {
        const id = Ember.get(this, 'notification.post.id');
        const {
          post
        } = yield this.fetch.request(`/sharedPosts/post/${id}`, {
          host: 'core'
        });
        Ember.set(this, 'sharedPost', post);
      } catch (e) {
        console.error(e);
      }
    }).on('didInsertElement').drop()
  });
  _exports.default = _default;
});