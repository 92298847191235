define("portal-talentrh/pods/home/my-workspace/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeMyWorkspaceRoute extends Ember.Route {
    deactivate() {
      this.controller.balanceVacationOpen = false;
      this.controller.tasksOpen = false;
    }
  }
  _exports.default = HomeMyWorkspaceRoute;
});