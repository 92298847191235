define("portal-talentrh/pods/components/team-hierarchy/user-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    userClass: Ember.computed('hierarchy.leds.length', 'profileUserId', 'userId', function () {
      let classNames = [];
      if (!this.hierarchy) {
        return;
      }
      if (this.profileUserId === this.userId && this.hierarchy.leds.length) {
        classNames.push('team-hierarchy__item');
      } else if (this.profileUserId === this.userId && !this.hierarchy.leds.length) {
        classNames.push('team-hierarchy__item__no-leds');
      }
      return classNames.join(' ');
    }),
    avatarClass: Ember.computed('hierarchy.leds', 'profileUserId', 'userId', function () {
      let classNames = ['avatar-5'];
      if (this.profileUserId === this.userId && this.hierarchy && this.hierarchy.leds) {
        classNames.push('avatar-6');
      }
      return classNames.join(' ');
    }),
    grayscaleClass: Ember.computed('profileUserId', 'userId', 'hierarchy', function () {
      return this.profileUserId === this.userId ? '' : 'user-avatar-grayscale';
    })
  });
  _exports.default = _default;
});