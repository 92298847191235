define("portal-talentrh/helpers/project-status-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.projectStatusBadge = projectStatusBadge;
  function projectStatusBadge(params /*, hash*/) {
    switch (params[0]) {
      case 'pending':
        return 'bg-warning';
      case 'late':
        return 'bg-danger';
      case 'concluded':
        return 'bg-success';
      case 'noDeadline':
        return 'bg-secondary';
    }
  }
  var _default = Ember.Helper.helper(projectStatusBadge);
  _exports.default = _default;
});