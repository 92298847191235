define("portal-talentrh/pods/view-team-member/notes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTeamMemberNotesRoute extends Ember.Route {
    model() {
      return this.modelFor('view-team-member');
    }
    setupController(controller, model) {
      controller.set('user', model);
      controller.reloadFilters(model);
    }
    deactivate() {
      this.controller.set('initialDate', null);
      this.controller.set('endDate', null);
    }
  }
  _exports.default = ViewTeamMemberNotesRoute;
});