define("portal-talentrh/pods/components/indicator-viewer/performance/function-evaluation-chart/component", ["exports", "@glimmer/component", "chart-js"], function (_exports, _component, _chartJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let FunctionEvaluationChartComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class FunctionEvaluationChartComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chart", _descriptor, this);
    }
    init() {
      let indicator = this.args.indicator;
      if (indicator && indicator.data) {
        this.initChart(indicator);
      }
    }
    initChart(indicator) {
      let data = {
        labels: indicator.data.chartData.labels,
        datasets: [{
          label: 'Média',
          backgroundColor: 'rgba(60, 168, 251, 0.75)',
          data: indicator.data.chartData.data
        }]
      };
      let chart = this.chart;
      if (chart) {
        chart.data = data;
        chart.update();
      } else {
        let ctx = document.getElementById('functionEvaluationCharts').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'bar',
          responsive: true,
          maintainAspectRatio: false,
          data: data,
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  stepSize: 5
                }
              }]
            }
          }
        });
      }
      this.chart = chart;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = FunctionEvaluationChartComponent;
});