define("portal-talentrh/pods/post/show/route", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    fetch: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    fromAccount: false,
    beforeModel(transition) {
      this.fromAccount = transition.to.queryParams.from || false;
      this.session.requireAuthentication(transition, 'login');
    },
    model(transition) {
      return this.modelTask.perform(transition.post_id);
    },
    modelTask: (0, _emberConcurrency.task)(function* (postId) {
      try {
        let query = null;
        if (this.fromAccount) {
          query = {
            onlyShow: true
          };
        }
        return yield this.store.loadRecord('post', `/posts/${postId}`, {
          data: query,
          host: 'core'
        });
      } catch (e) {
        this.swal.catch(e);
        if (e.errors && e.errors.length) {
          let error = e.errors[0];
          if (error.status == 404) {
            this.transitionTo('feed');
          }
        }
      }
    }),
    setupController(controller, model) {
      this._super(controller, model);
      this.controller.set('fromAccount', false);
      if (this.fromAccount) {
        this.controller.set('fromAccount', true);
      }
    },
    actions: {
      onDeletePost() {
        this.transitionTo('feed');
      },
      onArchivePost() {
        this.transitionTo('feed');
      },
      refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});