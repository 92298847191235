define("portal-talentrh/pods/company/minutes-of-meeting/form/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    shouldCreateEvent: false,
    useLocationText: false,
    // Single line computed properties
    rooms: Ember.A(),
    topicsOrderedSorting: Ember.A(['order']),
    topicsOrdered: Ember.computed.sort('meeting.meetingTopics', 'topicsOrderedSorting'),
    users: Ember.A(),
    // Tasks
    upload: (0, _emberConcurrency.task)(function* (file) {
      const url = `${_environment.default.apiUrl.social}/meetingMinutes/${this.meeting.id}/meetingAttachment`;
      try {
        this.swal.loading('Carregando arquivos');
        let response = yield file.upload(url, {
          fileKey: 'meeting-minute-attachment',
          headers: {
            Authorization: `Bearer ${this.session.token}`
          },
          method: 'POST'
        });
        this.store.pushPayload(response.body);
        this.toast.success('Arquivo enviado');
      } catch (e) {
        let status = e.status;
        if (e.errors && e.errors.length) {
          let error = e.errors[0];
          status = error.status;
        }
        if (status == 413) {
          return this.swal.warning('Ops. Tempo de upload excedido. Por favor realize o upload de um arquivo menor');
        }
        return this.swal.catch(e);
      }
    }).maxConcurrency(5).drop(),
    createEvent: (0, _emberConcurrency.task)(function* () {
      let invitedUsers = Ember.A();
      this.meeting.usersAdmin.forEach(user => {
        invitedUsers.pushObject(user);
      });
      this.meeting.usersParticipant.forEach(user => {
        invitedUsers.pushObject(user);
      });
      let appointment = this.store.createRecord('appointment', {
        invitedUsers,
        createdBy: this.meeting.createdBy,
        end: this.meeting.end,
        room: this.meeting.room,
        start: this.meeting.start,
        title: this.meeting.title,
        user: this.meeting.createdBy
      });
      try {
        yield appointment.save();
      } catch (e) {
        Ember.debug(`<Company::MinutesOfMeeting::Form> :: createEvent :: ${e}`);
      }
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* (topic) {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão do tópico?', {
          text: topic.title
        });
        if (!value) {
          return;
        }
        yield topic.destroyRecord();
        this.toast.success('Tópico removido');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    save: (0, _emberConcurrency.task)(function* () {
      if (this.meeting.validations.isInvalid) {
        return this.swal.validation(this.meeting.validations);
      }
      const participants = yield this.meeting.usersParticipant.map(u => u.id);
      const admins = yield this.meeting.usersAdmin.map(u => u.id);
      const userBothAdminAndParticipant = participants.some(participant => admins.includes(participant));
      if (userBothAdminAndParticipant) {
        return this.swal.warning('Os administradores já são considerados como participantes da ata.');
      }
      let message = this.meeting.isNew ? 'Ata de reunião cadastrada' : 'Ata de reunião atualizada';
      try {
        if (this.meeting.isNew) {
          let user = yield this.store.peekRecord('user', this.session.user.id);
          this.meeting.usersAdmin.pushObject(user);
          Ember.set(this, 'meeting.createdBy', user);
        }
        yield this.meeting.save();
        if (this.meeting.isNew && this.shouldCreateEvent) {
          yield this.createEvent.perform();
        }
        this.toast.success(message);
        this.router.transitionTo('company.minutes-of-meeting.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    updatePositions: (0, _emberConcurrency.task)(function* (list) {
      try {
        let topics = yield this.ajax.post('meetingTopics/sortPositions', {
          data: {
            list
          }
        });
        this.store.pushPayload(topics);
      } catch (e) {
        Ember.debug(`<Company::MinutesOfMeeting::Form> :: updatePositions :: ${e}`);
        this.toast.error('Não foi possível alterar a posição');
      }
    }).drop(),
    // Actions
    actions: {
      addParticipant(select, e) {
        let externalUsers = this.meeting.externalUsers || [];
        if (e.keyCode === 13 && !select.highlighted && select.searchText) {
          externalUsers.addObject(select.searchText);
        }
        Ember.set(this, 'meeting.externalUsers', externalUsers);
      },
      addRoom(select, e) {
        let room = this.meeting.room || [];
        if (e.keyCode === 13 && !select.highlighted && select.searchText) {
          room.addObject(select.searchText);
        }
        Ember.set(this, 'meeting.room', room);
      },
      focusOut(options) {
        if (options.searchText) {
          this.meeting.externalUsers.addObject(options.searchText);
        }
      },
      toggleLocation() {
        Ember.set(this, 'meeting.room', null);
        Ember.set(this, 'meeting.customRoom', null);
        Ember.set(this, 'useLocationText', !this.useLocationText);
      }
    }
  });
  _exports.default = _default;
});