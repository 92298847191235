define("portal-talentrh/pods/components/loading-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eHgR+m7Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ocrloader\"],[12],[2,\"\\n  \"],[10,\"em\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[10,\"i\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/components/loading-report/template.hbs"
    }
  });
  _exports.default = _default;
});