define("portal-talentrh/pods/search/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    swal: Ember.inject.service(),
    router: Ember.inject.service(),
    permission: Ember.inject.service(),
    queryParams: ['query'],
    posts: Ember.computed.alias('search.lastSuccessful.value.posts'),
    users: Ember.computed.alias('search.lastSuccessful.value.users'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    search: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(300);
      try {
        if (!this.query) {
          return;
        }
        return yield this.ajax.request('search/searchDetails', {
          data: {
            term: this.query
          }
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).restartable()
  });
  _exports.default = _default;
});