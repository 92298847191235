define("portal-talentrh/utils/timeWithLocalTimeZone", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeWithLocalTimeZone;
  function timeWithLocalTimeZone(startDate, endDate) {
    let timezone = new Date().getTimezoneOffset() / 60;
    let startDateLocalTimeZone = (0, _moment.default)(startDate).hours(timezone)._d;
    let endDateLocalTimeZone = (0, _moment.default)(endDate).hours(timezone)._d;
    return {
      startDateLocalTimeZone,
      endDateLocalTimeZone
    };
  }
});