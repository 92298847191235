define("portal-talentrh/helpers/format-currency-brl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function formatCurrencyBrl(params /*, hash*/) {
    if (!params[0]) {
      return '';
    }
    return params[0].toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  });
  _exports.default = _default;
});