define("portal-talentrh/pods/absence-board/form/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    fetch: Ember.inject.service(),
    permission: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    makeAppointment: false,
    startDate: null,
    endDate: null,
    didInsertElement() {
      const startDate = this.absence.isNew ? null : (0, _moment.default)(this.absence.startDate);
      const endDate = this.absence.isNew || !this.absence.endDate ? null : (0, _moment.default)(this.absence.endDate);
      Ember.set(this, 'startDate', startDate);
      Ember.set(this, 'endDate', endDate);
      Ember.set(this, 'absence.notifyManager', true);
    },
    showFinish: Ember.computed('absence.{active,isNew}', function () {
      return !this.absence.isNew && this.absence.active;
    }),
    disableInputEmployee: Ember.computed('absence.{isNew,user}', function () {
      return !this.absence.isNew && this.absence.user;
    }),
    save: (0, _emberConcurrency.task)(function* () {
      const message = this.absence.isNew ? 'Ausência cadastrada' : 'Ausência atualizada';
      try {
        if (!this.startDate) {
          return this.swal.warning('A data de início deve ser informada');
        }
        if (!this.endDate) {
          return this.swal.warning('A data de término deve ser informada');
        }
        const startDate = _moment.default.utc(this.startDate);
        const endDate = _moment.default.utc(this.endDate);
        Ember.set(this.absence, 'startDate', startDate);
        Ember.set(this.absence, 'endDate', endDate);
        if (this.absence.validations.isInvalid) {
          return this.swal.validation(this.absence.validations);
        }
        if ((0, _moment.default)(this.startDate).isAfter((0, _moment.default)(this.endDate))) {
          return this.swal.warning('A data término não pode ser inferior a data de início');
        }
        const absence = yield this.absence.save();
        if (this.makeAppointment) {
          yield this.ajax.post('appointments', {
            data: {
              appointment: {
                absence: true,
                company: this.session.company.id,
                createdBy: Ember.get(absence, 'user.id') || this.session.user.id,
                description: absence.description,
                end: absence.endDate,
                start: absence.startDate,
                title: absence.title,
                user: Ember.get(absence, 'user.id') || this.session.user.id,
                userMessage: absence.id
              }
            }
          });
        }
        this.toast.success(message);
        this.send('closeModal');
      } catch (e) {
        Ember.debug(e);
        this.swal.catch(e);
      }
    }).drop(),
    finish: (0, _emberConcurrency.task)(function* () {
      if (this.absence.validations.isInvalid) {
        return this.swal.validation(this.absence.validations);
      }
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar finalização da ausência?', {
          text: 'Esta ação não pode ser desfeita'
        });
        if (!value) {
          return;
        }
        Ember.set(this, 'absence.active', false);
        yield this.absence.save();
        this.toast.success('Ausência finalizada');
        this.send('closeModal');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    actions: {
      closeModal() {
        let backRoute = this.router.currentRoute.queryParams.backRoute || 'absence-board.index';
        this.router.transitionTo(backRoute);
      },
      setReason(reason) {
        Ember.set(this, 'absence.reason', reason);
        Ember.set(this, 'makeAppointment', reason.createAppointment);
      }
    }
  });
  _exports.default = _default;
});