define("portal-talentrh/pods/anniversary/item/component", ["exports", "@glimmer/component", "moment", "ember-concurrency-decorators"], function (_exports, _component, _moment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.or('permission.accessProfile.allowSendMessageIndividualChat', 'permission.accessProfile.admin'), _dec8 = Ember.computed('permission.{companyConfig.showJobFunctionCard,toAccessManagement}'), _dec9 = Ember.computed('args.user.account'), _dec10 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "swal", _descriptor3, this);
      _initializerDefineProperty(this, "toast", _descriptor4, this);
      _initializerDefineProperty(this, "permission", _descriptor5, this);
      _initializerDefineProperty(this, "voorsChat", _descriptor6, this);
      _initializerDefineProperty(this, "allowSendMessageIndividualChat", _descriptor7, this);
    }
    get permitAddCalendar() {
      let {
        birthdaysAppointments,
        user
      } = this.args;
      let appointments = birthdaysAppointments.map(ap => parseInt(ap.birthdayPerson.get('id')));
      return !appointments.includes(user.id) && this.session.user.id !== user.id;
    }
    get showJobTitle() {
      if (this.permission.companyConfig.showJobFunctionCard || this.permission.toAccessManagement) {
        return true;
      } else {
        return false;
      }
    }
    get showHideBirthdayTag() {
      let account = this.args.user.account;
      if (!account) return false;
      account = JSON.parse(account);
      return account.hideBirthDate;
    }
    get nameMonth() {
      let {
        user,
        isCompanyBirthdate
      } = this.args;
      let date = user.birthDate;
      if (isCompanyBirthdate) {
        date = user.admissionDate;
      }
      return _moment.default.utc(date).format('DD [de] MMMM');
    }
    *addCalendar() {
      try {
        let {
          user,
          addAppointment
        } = this.args;
        let date = (0, _moment.default)(user.birthDate).format('DD/MM');
        let birthDateIni = (0, _moment.default)(date, 'DD/MM').hours(6);
        let birthDateEnd = (0, _moment.default)(date, 'DD/MM').hours(23);
        let birthdayPerson = yield this.store.loadRecord('user', `/users/${user.id}`);
        let userLogged = yield this.store.peekRecord('user', this.session.user.id);
        let company = yield this.store.peekRecord('company', this.session.user.company);
        if (!birthdayPerson) {
          return this.toast.error('Usuário não encontrado.');
        }
        let appointment = this.store.createRecord('appointment', {
          title: `Aniversário: ${user.name}`,
          user: userLogged,
          createdBy: userLogged,
          birthdayPerson,
          company,
          start: birthDateIni.toDate(),
          end: birthDateEnd.toDate()
        });
        yield appointment.save();
        addAppointment(appointment);
        this.toast.success(`O aniversário de ${user.name} foi cadastrado na sua agenda`);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    *removeCalendar() {
      try {
        let {
          birthdaysAppointments,
          removeAppointment,
          user
        } = this.args;
        let birthdaysAppointment = birthdaysAppointments.findBy('birthdayPerson.id', String(user.id));
        if (!birthdaysAppointment) {
          return this.toast.error('Compromisso não encontrado.');
        }
        let appointment = yield this.store.findRecord('appointment', birthdaysAppointment.id);
        yield appointment.destroyRecord();
        removeAppointment(birthdaysAppointment);
        this.toast.success(`O aniversário de ${user.name} foi removido da sua agenda`);
      } catch (e) {
        this.swal.catch(e);
      }
    }
    openChat() {
      let {
        user
      } = this.args;
      this.voorsChat.openNewChat({
        id: user.id,
        isGroup: false
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "voorsChat", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allowSendMessageIndividualChat", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showJobTitle", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showJobTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showHideBirthdayTag", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "showHideBirthdayTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addCalendar", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "addCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeCalendar", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openChat", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "openChat"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});