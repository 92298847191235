define("portal-talentrh/pods/application/navbar/notification/badge/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    ajax: Ember.inject.service(),
    didInsertElement() {
      this._super(...arguments);
      this.loadNotificationsCount.perform();
    },
    loadNotificationsCount: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          unreadCount
        } = yield this.ajax.request('notifications/unread');
        Ember.set(this, 'unreadCount', unreadCount);
      } catch (e) {
        Ember.debug(e);
      }
    })
  });
  _exports.default = _default;
});