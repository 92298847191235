define("portal-talentrh/pods/errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9EY7ILm4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"align-items-center container d-flex h-100 justify-content-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"section-body contain-lg\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row integration-failed-img\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/errors/template.hbs"
    }
  });
  _exports.default = _default;
});