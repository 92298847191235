define("portal-talentrh/pods/habitus/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XBjh/Ts6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"body\"],[14,0,\"habitus-ia-body\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ia-container\"],[12],[2,\"\\n    \"],[8,\"habitus/index/chat-ia-contacts\",[],[[\"@contacts\",\"@onClickContact\"],[[32,0,[\"chatIAContacts\"]],[32,0,[\"onClickChatContact\"]]]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"selectedContact\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"habitus/index/chat-ia-message\",[],[[\"@selectedContact\",\"@messages\",\"@sendMessages\"],[[32,0,[\"selectedContact\"]],[32,0,[\"messages\"]],[32,0,[\"sendMessages\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"svg-text-container\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"loadingMessages\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"loading-indicator\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"spinner\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"svg-container\"],[12],[2,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/images/no-selected-contact.svg\"],[14,0,\"mobile-banner__logo mr-2\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-container\"],[12],[2,\"\\n          \"],[10,\"span\"],[12],[2,\"Como posso ajudar você hoje?\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/habitus/index/template.hbs"
    }
  });
  _exports.default = _default;
});