define("portal-talentrh/pods/habitus/team-insights/insights-by-period/component", ["exports", "@glimmer/component", "chart-js", "moment"], function (_exports, _component, _chartJs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let InsightsByPeriodComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class InsightsByPeriodComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "initDay", _descriptor, this);
      _initializerDefineProperty(this, "initMonth", _descriptor2, this);
      _initializerDefineProperty(this, "endDay", _descriptor3, this);
      _initializerDefineProperty(this, "endMonth", _descriptor4, this);
      _initializerDefineProperty(this, "groupBy", _descriptor5, this);
      _initializerDefineProperty(this, "groupByOptions", _descriptor6, this);
    }
    generate() {
      this.initChart();
    }
    get pending() {
      const {
        findIndicatorsByLeadersPerDay
      } = this.args;
      return findIndicatorsByLeadersPerDay.pendingValues;
    }
    get concluded() {
      const {
        findIndicatorsByLeadersPerDay
      } = this.args;
      return findIndicatorsByLeadersPerDay.concludedValues;
    }
    get total() {
      const {
        findIndicatorsByLeadersPerDay
      } = this.args;
      return findIndicatorsByLeadersPerDay.totalValues;
    }
    changeGroupBy(groupBy) {
      this.groupBy = groupBy;
      this.initChart();
    }
    initChart() {
      this.groupBy = !this.groupBy ? this.suggestedGroupBy : this.groupBy;
      let groupBy = this.groupBy ? this.groupBy : this.suggestedGroupBy;
      let chart = this.interactionsTimelineChart;
      let data = {
        datasets: [{
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          borderColor: '#3dbd90',
          data: this.concluded,
          label: 'Concluídos',
          order: 1,
          pointBackgroundColor: '#3dbd90',
          pointHitRadius: 10,
          pointHoverRadius: 5,
          pointRadius: 1
        }, {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          borderColor: '#6090c5',
          data: this.total,
          label: 'Geral',
          order: 2,
          pointBackgroundColor: '#6090c5',
          pointHitRadius: 10,
          pointHoverRadius: 5,
          pointRadius: 1
        }, {
          backgroundColor: 'rgba(0, 0, 0, 0.0)',
          borderColor: '#d2c93f',
          data: this.pending,
          label: 'Pendentes',
          order: 0,
          pointBackgroundColor: '#d2c93f',
          pointHitRadius: 10,
          pointHoverRadius: 5,
          pointRadius: 1
        }]
      };
      let options = {
        legend: {
          reverse: true
        },
        scales: {
          xAxes: [{
            ticks: {
              maxTicksLimit: 30
            },
            time: {
              displayFormats: {
                day: 'D MMM',
                week: 'DD/MM/YYYY',
                quarter: '[T]Q YYYY'
              },
              minUnit: 'day',
              unit: groupBy.value,
              tooltipFormat: groupBy.tooltip
            },
            type: 'time'
          }]
        },
        tooltips: {
          mode: 'index',
          position: 'nearest',
          intersect: false
        }
      };
      if (chart) {
        chart.data = data;
        chart.options = options;
        chart.update();
      } else {
        let ctx = document.getElementById('interactionsTimelineChart').getContext('2d');
        chart = new _chartJs.default(ctx, {
          type: 'line',
          responsive: true,
          maintainAspectRatio: false,
          data: data,
          options: options
        });
      }
      this.interactionsTimelineChart = chart;
    }
    get suggestedGroupBy() {
      const {
        startDate,
        endDate
      } = this.args;
      if (!startDate || !endDate) {
        return '';
      }
      let startDateM = (0, _moment.default)(startDate);
      let endDateM = (0, _moment.default)(endDate);
      let diffDays = endDateM.diff(startDateM, 'days');
      if (diffDays > 900) {
        return this.groupByOptions[3];
      }
      if (diffDays > 210) {
        return this.groupByOptions[2];
      }
      if (diffDays > 30) {
        return this.groupByOptions[1];
      }
      return this.groupByOptions[0];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "initDay", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initMonth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "endDay", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endMonth", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "groupBy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.suggestedGroupBy;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "groupByOptions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        label: 'dia',
        tooltip: 'DD/MM/YYYY',
        value: 'day'
      }, {
        label: 'semana',
        tooltip: '[Semana do dia] DD [de] MMMM',
        value: 'week'
      }, {
        label: 'mês',
        tooltip: 'MMM YYYY',
        value: 'month'
      }, {
        label: 'trimestre',
        tooltip: 'Q[º trimestre de] YYYY',
        value: 'quarter'
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "generate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeGroupBy", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "changeGroupBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype)), _class));
  _exports.default = InsightsByPeriodComponent;
});