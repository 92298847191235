define("portal-talentrh/pods/notification/item/component", ["exports", "ember-concurrency", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    tagName: 'li',
    classNames: ['list-group-item p-0 bg-transparent'],
    // Services
    ajax: Ember.inject.service(),
    voorsChat: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    type: Ember.computed.alias('notification.type'),
    allowSendMessageIndividualChat: Ember.computed.alias('permission.accessProfile.allowSendMessageIndividualChat'),
    // Single line comptued properties
    isFeedbackRequest: Ember.computed.equal('notification.type', 'feedback-request'),
    isComplainingNotification: Ember.computed.equal('notification.type', 'complaining'),
    isMeeting: Ember.computed.equal('notification.type', 'meetingMinute'),
    // Multiline computed properties
    avatar: Ember.computed('notification.{relatedUser.avatar,type,user.avatar}', function () {
      if (this.notification.type === 'created-user-solicitation') {
        return Ember.get(this, 'notification.user.avatar');
      }
      return Ember.get(this, 'notification.relatedUser.avatar');
    }),
    isNotificationPdi: Ember.computed('notification.type', function () {
      return this.notification.type === 'pdi' || this.notification.type === 'pdi-action' || this.notification.type === 'pdi-action-expired' || this.notification.type === 'pdi-action-expire-in' || this.notification.type === 'evidence';
    }),
    isNotificationTraining: Ember.computed.equal('notification.type', 'training'),
    isNotificationPde: Ember.computed('notification.type', function () {
      return this.notification.type === 'pde' || this.notification.type === 'pde-action' || this.notification.type === 'pde-evidence';
    }),
    isNotificationProject: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'project' === type;
    }),
    isNotificationMention: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return type === 'comment-mention' || type === 'post-mention';
    }),
    isNotificationFeedback: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return type === 'feedback' || type === 'followup' || type === 'feedback-approval';
    }),
    isNotificationUserAbsence: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'user-message' === type;
    }),
    isNotificationSharedPost: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'shared-post' === type;
    }),
    isBackupSolicitation: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'lgpd-backup-company-solicitation-available' === type;
    }),
    isNotificationNewTask: Ember.computed('notification.type', function () {
      let type = this.notification.type;
      return 'newTask' === type;
    }),
    notItsMe: Ember.computed('notification.relatedUser.id', 'session.user.id', function () {
      let relatedUser = Ember.get(this, 'notification.relatedUser.id');
      if (!relatedUser) {
        relatedUser = 0;
      }
      return parseInt(relatedUser) !== this.session.user.id && relatedUser != 0;
    }),
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      this.typeAvatars = {
        'created-user-solicitation'(notification) {
          return Ember.get(notification, 'user.avatar');
        },
        forum(notification) {
          return Ember.get(notification, 'relatedUser.avatar');
        },
        decision(notification) {
          return Ember.get(notification, 'relatedUser.avatar');
        }
      };
      this.typeMessages = {
        'created-user-solicitation'(notification) {
          return `${Ember.get(notification, 'user.firstName')} ${Ember.get(notification, 'message')}`;
        },
        forum(notification) {
          return `${Ember.get(notification, 'relatedUser.firstName')} comentou no seu tópico`;
        },
        decision(notification) {
          return notification.message;
        }
      };
    },
    // Tasks
    setAsRead: (0, _emberConcurrency.task)(function* () {
      if (this.notification.read) {
        return;
      }
      try {
        let {
          unreadCount
        } = yield this.ajax.post(`/notifications/${this.notification.id}/read`);
        Ember.set(this, 'notification.read', true);
        Ember.set(this, 'unreadCount', unreadCount);
        this.reloadFilter();
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    loadCarApproval: (0, _emberConcurrency.task)(function* () {
      let url;
      try {
        const carReservation = yield this.store.findRecord('car-reservation', this.notification.modelId);
        const car = yield Ember.get(carReservation, 'car');
        const needsApproval = car.get('needsManagerApproval');
        if (needsApproval) {
          url = `${_environment.default.appUrl['agenda']}car-reservation/requests/manager/${this.notification.modelId}/show`;
        } else {
          url = `${_environment.default.appUrl['agenda']}car-reservation/requests/admin/${this.notification.modelId}/show`;
        }
        return window.open(url);
      } catch (e) {
        Ember.debug(e);
      }
    }).drop(),
    // Actions
    actions: {
      openChat(user) {
        this.voorsChat.openNewChat({
          id: user.id,
          isGroup: false
        });
      },
      redirect() {
        let url;
        let notification = this.notification;
        let type = this.type;
        let router = this.router;
        const newPerformanceModule = this.permission.companyConfig.featureFlags['newPerformanceModule'];
        if (type === 'task') {
          url = `${_environment.default.appUrl['projetos']}project/${Ember.get(notification, 'project')}/task/${Ember.get(notification, 'modelId')}`;
        } else if (type === 'carReservation') {
          url = `${_environment.default.appUrl['agenda']}car-reservation/calendar/${Ember.get(notification, 'modelId')}/show`;
        } else if (type === 'invite') {
          url = `${_environment.default.appUrl['agenda']}invite`;
        } else if (type === 'appointment') {
          url = `${_environment.default.appUrl['agenda']}my-calendar/${Ember.get(notification, 'modelId')}/show-appointment`;
        } else if (type === 'employee-evaluation') {
          if (Ember.get(notification, 'employeeEvaluation')) {
            if (newPerformanceModule) {
              url = `${_environment.default.appUrl['performance']}evaluations/${notification.employeeEvaluation}/answer`;
            } else {
              url = `${_environment.default.appUrl['avd']}evaluation/answer/${notification.employeeEvaluation}`;
            }
          } else {
            if (newPerformanceModule) {
              url = `${_environment.default.appUrl['performance']}evaluations`;
            } else {
              url = `${_environment.default.appUrl['avd']}`;
            }
          }
        }
        this.setAsRead.perform();
        switch (type) {
          case 'created-user-solicitation':
            return router.transitionTo('human-resources.user-solicitations');
          case 'answered-user-solicitation':
            return router.transitionTo('feed.me.request');
          case 'forum':
            return router.transitionTo('forum.view-topic', [Ember.get(this, 'notification.topic.id')]);
          case 'meetingMinute':
            return router.transitionTo('company.minutes-of-meeting.index.view', [Ember.get(this, 'notification.meetingMinute.id')]);
          case 'decision':
            return router.transitionTo('company.decision.index');
          case 'announcement':
            return router.transitionTo('post.show', notification.get('post.id'));
          case 'task':
          case 'carResponsible':
          case 'carReservation':
          case 'employee-evaluation':
            return window.open(url, '_blank');
          case 'invite':
          case 'appointment':
            return window.open(url, '_blank');
          case 'complaining':
            return;
          case 'avatar-image':
            return router.transitionTo('profile.edit');
          default:
            return;
        }
      }
    }
  });
  _exports.default = _default;
});