define("portal-talentrh/pods/post/edit/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const POST_TYPES = {
    ad: 'um classificado',
    announcement: 'um comunicado',
    event: 'um evento',
    message: 'uma mensagem',
    news: 'uma notícia',
    survey: 'uma enquete'
  };
  var _default = Ember.Controller.extend({
    // Services
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    postImageUpload: Ember.inject.service(),
    postVideoUpload: Ember.inject.service(),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    // Default values
    openPostTagModal: false,
    // Single line computed properties
    post: Ember.computed.alias('model'),
    advancedEditorImages: Ember.A(),
    advancedEditorVideos: Ember.A(),
    // Multiline computed properties
    postType: Ember.computed('post.type', function () {
      return POST_TYPES[this.post.type];
    }),
    priorityUntilDate: Ember.computed('post.priorityUntil', {
      get() {
        let priorityUntilDate = this.post.priorityUntil ? (0, _moment.default)(this.post.priorityUntil).add(this.post.priorityUntil.getTimezoneOffset(), 'm').toDate() : '';
        return priorityUntilDate;
      },
      set(key, value) {
        Ember.set(this, 'post.priorityUntil', value);
        return value;
      }
    }),
    selectedPostTag: Ember.computed('post.postTag.id', function () {
      let id = Ember.get(this, 'post.postTag.id');
      if (!id) {
        return {
          title: 'Etiqueta'
        };
      }
      return this.store.peekRecord('post-tag', id);
    }),
    showDelete: Ember.computed('post.user.id', 'session.user', function () {
      let user = this.session.user;
      return Ember.get(this, 'post.user.id') === String(user.id) || user.admin;
    }),
    showTitle: Ember.computed('post.type', function () {
      return this.post.type !== 'message' && this.post.type !== 'news';
    }),
    showBranches: Ember.computed('accessProfile.{allowPublishPerBranch,admin}', 'companyConfig.featureFlags.{ENABLE,postPerBranch}', function () {
      return (this.accessProfile.allowPublishPerBranch || this.accessProfile.admin) && this.companyConfig.featureFlags.ENABLE && this.companyConfig.featureFlags.postPerBranch;
    }),
    endPointBranches: Ember.computed('accessProfile.admin', 'session.user.id', function () {
      return this.accessProfile.admin ? '/branches/ajax' : `/users/${this.session.user.id}/branches`;
    }),
    // Tasks
    delete: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar exclusão da publicação?');
        if (!value) {
          return;
        }
        yield this.post.destroyRecord();
        this.toast.success('Publicação removida');
        this.transitionToRoute('feed.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    validateIfPostIsntPriority: (0, _emberConcurrency.task)(function* () {
      if (!this.post.priority) {
        yield this.post.priorityUntil = null;
      }
    }).drop(),
    uploadFile: (0, _emberConcurrency.task)(function* () {
      let s3Signer = '/s3/signedUploadUrl';
      let model = 'postAttachment';
      let parentModel = 'post';
      let file = document.getElementById('select-attachment').files[0];
      let query = {
        model,
        parentModel,
        parentModelId: this.post.id,
        filename: file.name,
        type: file.type
      };
      try {
        let response = yield this.ajax.request(s3Signer, {
          data: query
        });
        yield fetch(response.url, {
          method: 'PUT',
          body: file
        });
        this.store.pushPayload({
          postAttachments: response.postAttachments
        });
        this.toast.success('Anexo adicionado');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      const priorityDateForValidation = _moment.default.utc(this.post.priorityUntil).format('YYYY-MM-DD');
      const now = (0, _moment.default)().startOf('day').format('YYYY-MM-DD');
      if (this.post.type === 'news') {
        if (!this.post.link) {
          return this.swal.warning('Informe o link da notícia');
        } else {
          const links = this.post.link.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gim);
          if (!links) {
            return this.swal.warning('Formato inválido, utilize o http:// antes do link. Exemplo: http://nela.com.br');
          } else {
            try {
              //Só para pegar um retorno, se o link é válido ou não, no caso da edição do post.
              yield fetch(links, {
                mode: 'no-cors'
              });
            } catch (e) {
              return this.swal.warning('Informe o link da notícia');
            }
          }
        }
      }
      if (!this.post.body && !this.post.isSharedPost) {
        return this.swal.warning('Preencha o corpo da publicação');
      }
      const {
        isSharedPost
      } = this.post;
      if (this.post.type === 'announcement' && (!this.post.title || this.post.title.trim() === '') && !isSharedPost) {
        document.getElementById('title').focus();
        return this.swal.warning('O comunicado deve ter um título');
      }
      if (this.post.type === 'event' && (!this.post.title || this.post.title.trim() === '') && !isSharedPost) {
        document.getElementById('title').focus();
        return this.swal.warning('O evento deve ter um título');
      }
      if (this.post.type === 'ad' && (!this.post.title || this.post.title.trim() === '') && !isSharedPost) {
        document.getElementById('title').focus();
        return this.swal.warning('O classificado deve ter um título');
      }
      if (this.post.type === 'survey') {
        if ((!this.post.title || this.post.title.trim() === '') && !isSharedPost) {
          document.getElementById('title').focus();
          return this.swal.warning('A enquete deve ter um título');
        }
        if (!this.post.body.alternatives.length) {
          return this.swal.warning('A enquete deve ter pelo menos uma alternativa');
        }
        let isEmpty = false;
        this.post.body.alternatives.forEach(option => {
          if (!option.value) {
            isEmpty = true;
          }
        });
        if (isEmpty) {
          return this.swal.warning('Informe uma descrição para cada alternativa');
        }

        // Remove referencia cíclica "users" das alternativas
        for (let i of this.post.body.alternatives) {
          delete i.users;
        }
        const body = JSON.stringify(this.post.body);
        Ember.set(this.post, 'body', body);
      }
      if (this.post.priority && !this.post.priorityUntil) {
        return this.swal.warning('A data deve ser preenchida');
      }
      if ((0, _moment.default)(priorityDateForValidation).isBefore(now)) {
        return this.swal.warning('Não é possível definir datas anteriores à data atual.');
      }
      try {
        yield this.postImageUpload.save.perform(this.post, this.advancedEditorImages);
        yield this.postVideoUpload.save.perform(this.post, this.advancedEditorVideos);
        yield this.post.save();
        this.toast.success('Publicação atualizada');
        this.transitionToRoute('feed.index');
      } catch (e) {
        this.swal.catch(e);
      }
    }),
    // Actions
    actions: {
      openSelectAttachment() {
        document.getElementById('select-attachment').click();
      },
      branchesSelected(branches) {
        this.post.get('branches').then(() => {
          Ember.set(this, 'post.branches', branches);
        });
      },
      removePostTag() {
        Ember.set(this, 'post.postTag', null);
      }
    }
  });
  _exports.default = _default;
});