define("portal-talentrh/pods/profile/edit/experience/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4RoSHrpi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"profile/edit/experience/form\",[],[[\"@experience\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/profile/edit/experience/create/template.hbs"
    }
  });
  _exports.default = _default;
});