define("portal-talentrh/pods/application/navbar/notification/complaining/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Tb2Ag9zH",
    "block": "{\"symbols\":[\"Avatar\",\"@notification\"],\"statements\":[[10,\"a\"],[15,6,[30,[36,0],[\"conta\",\"manage-complained-post\"],null]],[14,0,\"d-flex py-4\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n  \"],[8,\"ui-avatar\",[[24,0,\"mb-1\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Img\"]],[[24,0,\"avatar-4\"]],[[\"@src\"],[\"./images/voors-logo.png\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text\"],[12],[2,\"\\n      \"],[1,[32,2,[\"message\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"navbar__dropdown__item__body__text--small\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,2,[\"createdAt\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"app-url\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/application/navbar/notification/complaining/template.hbs"
    }
  });
  _exports.default = _default;
});