define("portal-talentrh/pods/company/album/view-album/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    album: Ember.computed.alias('model'),
    admin: Ember.computed.alias('permission.admin'),
    actions: {
      deleteAlbum() {
        this.swal.confirm('Tem certeza que deseja remover este album?').then(result => {
          if (result.value) {
            this.album.destroyRecord().then(() => {
              this.transitionToRoute('company.album');
              this.swal.success('Álbum removido');
            }).catch(() => {
              this.swal.error('Não foi possível remover este album');
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});