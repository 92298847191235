define("portal-talentrh/pods/group/show/attachment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model() {
      return this.modelFor('group.show');
    },
    deactivate() {
      this.controller.set('filter', '');
    },
    actions: {
      willTransition() {
        this.controller.loadFiles.perform();
      }
    }
  });
  _exports.default = _default;
});