define("portal-talentrh/models/good-transfer-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    goodAquisitionDate: (0, _attr.default)('date'),
    goodBranch: (0, _attr.default)('string'),
    goodCostCenter: (0, _attr.default)('string'),
    goodDescription: (0, _attr.default)('string'),
    goodIdentification: (0, _attr.default)('string'),
    goodResidualValue: (0, _attr.default)('string'),
    // Associations
    good: (0, _relationships.belongsTo)('good'),
    goodTransfer: (0, _relationships.belongsTo)('goodTransfer'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});