define("portal-talentrh/pods/components/view-survey/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    permission: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    answered: false,
    saving: false,
    selects: Ember.A(),
    surveyAnswered: Ember.A(),
    isCompleted: Ember.computed('answered', 'post.{ended,body.endTime}', function () {
      let answered = this.answered;
      let endTime = Ember.get(this, 'post.body.endTime');
      let ended = Ember.get(this, 'post.ended');
      return answered || endTime <= 0 || ended;
    }),
    showExportBtn: Ember.computed('permission.admin', 'post.user.id', 'session.user.{admin,id}', function () {
      let userId = String(this.session.user.id);
      let isAdmin = this.permission.admin;
      let postUserId = Ember.get(this, 'post.user.id');
      return userId === postUserId || isAdmin;
    }),
    canSeeResults: Ember.computed('isCompleted', 'permission.admin', 'post.body.showResult', 'post.user.id', 'session.user.{admin,id}', function () {
      let userId = String(this.session.user.id);
      let isAdmin = this.permission.admin;
      let isCompleted = this.isCompleted;
      let postUserId = Ember.get(this, 'post.user.id');
      let showResult = Ember.get(this, 'post.body.showResult');
      return userId === postUserId || isAdmin || showResult && isCompleted;
    }),
    didInsertElement() {
      this.initElement();
    },
    initElement() {
      if (this.get('post.body') && this.get('post.type') === 'survey') {
        let body = this.get('post.body');
        if (typeof body !== 'object') {
          try {
            body = JSON.parse(body);
          } catch (err) {
            body = {
              alternatives: []
            };
          }
        }
        this.set('post.body', body);
        const createdAt = this.get('post.createdAt');
        const endDay = this.get('post.body.endDay');
        const endHour = this.get('post.body.endHour');
        this.set('post.body.endTime', (0, _moment.default)(createdAt).add(endDay, 'd').add(endHour, 'h').toDate());
        this.store.query('survey', {
          post: this.get('post.id')
        }).then(surveys => {
          let data = surveys.filter(survey => Ember.get(survey, 'user.id') === String(this.session.user.id));
          let answer = [];
          if (data.get('length')) {
            let first = data.get('firstObject');
            if (first) {
              this.set('answered', true);
              answer = first.get('answer');
              if (typeof answer !== 'object') {
                answer = JSON.parse(answer);
              }
            }
          }
          let alternatives = this.get('post.body.alternatives');
          if (typeof alternatives === 'string') {
            alternatives = JSON.parse(alternatives);
          }
          alternatives.forEach(alternative => {
            Ember.set(alternative, 'votes', 0);
            Ember.set(alternative, 'percent', 0);
            if (answer.includes(alternative.value)) {
              Ember.set(alternative, 'checked', true);
            }
          });
          this.set('surveyAnswered', alternatives);
          if (this.get('post.body.showResult') || String(this.session.user.id) === this.get('post.user.id') || this.permission.admin) {
            let length = 0;
            let totals = new Array(length);
            let imgs = [];
            surveys.forEach(survey => {
              let answer = survey.get('answer');
              if (typeof answer !== 'object') {
                survey.set('answer', JSON.parse(answer));
              }
              survey.get('answer').forEach(item => {
                if (totals[item]) {
                  totals[item]++;
                } else {
                  totals[item] = 1;
                }
                if (!imgs[item]) {
                  imgs[item] = [];
                }
                imgs[item].push(survey.get('user'));
                length++;
              });
            });
            this.surveyAnswered.forEach(item => {
              let percent = Math.round(totals[item.value] * 100 / length);
              if (percent > 0) {
                Ember.set(item, 'users', imgs[item.value]);
                Ember.set(item, 'votes', totals[item.value]);
                Ember.set(item, 'percent', percent);
              } else {
                Ember.set(item, 'votes', 0);
                Ember.set(item, 'percent', 0);
              }
            });
          }
        });
      }
    },
    saveResponse: (0, _emberConcurrency.task)(function* (survey) {
      try {
        yield this.ajax.post('/surveys', {
          data: {
            survey
          }
        });
        this.initElement();
        Ember.set(this, 'alreadyAnswered', true);
      } catch (e) {
        this.swal.catch(e);
      } finally {
        this.set('saving', false);
      }
    }).drop(),
    actions: {
      select(alternative) {
        if (this.get('post.body.type') === 'radio') {
          this.set('selects', []);
        }
        push(this.selects, alternative);
      },
      save() {
        this.set('saving', true);
        if (this.alreadyAnswered) {
          return;
        }
        if (!this.get('selects.length')) {
          return this.swal.warning('Antes de enviar, informe sua resposta');
        }
        let survey = {
          user: this.session.user.id,
          post: this.post.id,
          answer: JSON.stringify(this.selects)
        };
        this.saveResponse.perform(survey);
      },
      csv(titleQuiz) {
        const table = this.surveyAnswered;
        if (!table.length) {
          return this.swal.warning('Nenhum valor encontrado');
        }
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += `Respondente;${titleQuiz};`;
        csvContent += '\n';
        table.forEach(result => {
          const resposta = result.value;
          if (result.users) {
            result.users.forEach(user => {
              csvContent += user.get('fullName') + ';';
              csvContent += resposta + ';';
              csvContent += '\n';
            });
          }
        });
        function ajustaEncodeURI(str) {
          return encodeURI(str).replace(/[#]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
          });
        }
        const encodedUri = ajustaEncodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `${titleQuiz}.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      }
    }
  });
  _exports.default = _default;
  function push(array, value) {
    const index = array.indexOf(value);
    if (index === -1) {
      array.push(value);
    } else {
      array.splice(index, 1);
    }
  }
});