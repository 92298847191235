define("portal-talentrh/pods/components/widgets/admission-anniversaries/component", ["exports", "ember-concurrency", "moment", "portal-talentrh/config/environment"], function (_exports, _emberConcurrency, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['closeWidgetToday:d-none'],
    // Services
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    permission: Ember.inject.service(),
    hasMore: false,
    // Life cycle
    didInsertElement() {
      let currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      let lastOpenAdmissionWidget = this.session.data.lastOpenAdmissionWidget;
      if (lastOpenAdmissionWidget && lastOpenAdmissionWidget === currentDate) {
        Ember.set(this, 'closeWidgetToday', true);
      } else {
        this.loadWidget.perform();
      }
    },
    loadWidget: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      let itemsPerPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
      return function* () {
        const showBirthdayCompanyMonth = _this.permission.companyConfig.showBirthdayCompanyMonth;
        let queryParams = `?byMonth=${showBirthdayCompanyMonth}&limit=${itemsPerPage}`;
        if (page > 1) {
          queryParams += `&skip=${(page - 1) * itemsPerPage}`;
        }
        const {
          users,
          hasMore
        } = yield _this.ajax.request(`/users/admissiontoday${queryParams}`);
        if (!users) {
          return;
        }
        Ember.set(_this, 'hasMore', hasMore);
        users.forEach(user => {
          if (!user.avatarUrl) {
            user.avatarUrl = '/images/user-sem-imagem.png';
          }
        });
        return users;
      }();
    }).drop(),
    actions: {
      closeWidget() {
        this.session.set('data.lastOpenAdmissionWidget', (0, _moment.default)().format('YYYY-MM-DD'));
        Ember.set(this, 'closeWidgetToday', true);
      }
    }
  });
  _exports.default = _default;
});