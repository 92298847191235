define("portal-talentrh/models/notification", ["exports", "@ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    // Attributes
    createdAt: (0, _model.attr)('date'),
    employeeEvaluation: (0, _model.attr)('number'),
    linkParams: (0, _model.attr)('raw'),
    message: (0, _model.attr)('string'),
    modelId: (0, _model.attr)('string'),
    notifyBrowser: (0, _model.attr)('boolean'),
    project: (0, _model.attr)('number'),
    read: (0, _model.attr)('boolean'),
    relatedsUsers: (0, _model.attr)('raw'),
    type: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    // Associations
    decision: (0, _relationships.belongsTo)('decision'),
    meetingMinute: (0, _relationships.belongsTo)('meeting-minute'),
    post: (0, _relationships.belongsTo)('post'),
    relatedUser: (0, _relationships.belongsTo)('user'),
    topic: (0, _relationships.belongsTo)('topic'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});