define("portal-talentrh/services/notification", ["exports", "portal-talentrh/config/environment", "ember-concurrency-decorators"], function (_exports, _environment, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NotificationService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class NotificationService extends Ember.Service {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "groups", _descriptor, this);
      _initializerDefineProperty(this, "userDataLoader", _descriptor2, this);
      _initializerDefineProperty(this, "isSilenced", _descriptor3, this);
      _defineProperty(this, "openNotifications", Ember.A());
    }
    get status() {
      return Notification.permission;
    }
    requestPermission() {
      return Notification.requestPermission();
    }
    showNotification(message) {
      if (this.isSilenced) {
        return;
      }
      this._showNotificationTask.perform(message);
    }
    _clearLastNotification(notification, type) {
      let lastNotification;
      if (type === 'direct') {
        lastNotification = this.openNotifications.findBy('data.user', notification.data.user);
      }
      if (type === 'group') {
        lastNotification = this.openNotifications.findBy('data.group', notification.data.group);
      }
      if (lastNotification) {
        lastNotification.close();
      }
      this.openNotifications.pushObject(notification);
    }
    _isAtChatMessagesroute() {
      const currentPath = Ember.getOwner(this).lookup('controller:application').currentPath;
      return currentPath.includes('chat');
    }
    showSocketNotification(title, message) {
      if (this.isSilenced) {
        return;
      }
      return new Notification(title, {
        body: message,
        icon: _environment.default.cdnUrl + 'voors/icons/icon-notification-72x72.png',
        closeOnClick: true
      });
    }
    *_getNotification(message) {
      let notificationTitle;
      let notificationOptions;
      if (message.type === 'direct') {
        let user = yield this.userDataLoader.get(message.with);
        notificationTitle = user.fullName;
        notificationOptions = {
          data: {
            user: message.with
          },
          body: message.latestMessage,
          icon: user.get('avatar')
        };
      }
      if (!notificationTitle) {
        return null;
      }
      let notification = new Notification(notificationTitle, notificationOptions);
      notification.onclose = () => {
        this.openNotifications.removeObject(notification);
      };
      return notification;
    }
    *_showNotificationTask(message) {
      if (this.status === 'denied') {
        return;
      }
      if (this.status === 'default') {
        let result = yield this.requestPermission();
        if (result !== 'granted') {
          return;
        }
      }
      let notification = yield this._getNotification.perform(message);
      this._clearLastNotification(notification, message.type);
      return;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "groups", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userDataLoader", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSilenced", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_getNotification", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_getNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_showNotificationTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_showNotificationTask"), _class.prototype)), _class));
  _exports.default = NotificationService;
});