define("portal-talentrh/pods/habitus/details/access-frequency/amount-of-interactions/component", ["exports", "@glimmer/component", "chart-js", "moment"], function (_exports, _component, _chartJs, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  _moment.default.locale('pt-br');
  const CHART_COLORS = {
    likes: 'rgba(55, 81, 255, 1)',
    comments: 'rgba(34, 158, 132, 1)',
    sharedPosts: 'rgba(254, 178, 103, 1)'
  };
  let AmountOfInteractionsComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed.equal('filter', 'monthly'), _dec14 = Ember.computed.equal('filter', 'yearly'), _dec15 = Ember.computed('args.startDate'), _dec16 = Ember.computed('filteredLikes.length'), _dec17 = Ember.computed('filteredComments.length'), _dec18 = Ember.computed('filteredSharedPosts.length'), _dec19 = Ember.computed('totalLikes', 'totalComments', 'totalSharedPosts'), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class AmountOfInteractionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "filter", _descriptor, this);
      _initializerDefineProperty(this, "years", _descriptor2, this);
      _initializerDefineProperty(this, "months", _descriptor3, this);
      _initializerDefineProperty(this, "selectedYear", _descriptor4, this);
      _initializerDefineProperty(this, "selectedMonth", _descriptor5, this);
      _initializerDefineProperty(this, "chart", _descriptor6, this);
      _initializerDefineProperty(this, "filteredLikes", _descriptor7, this);
      _initializerDefineProperty(this, "filteredComments", _descriptor8, this);
      _initializerDefineProperty(this, "filteredSharedPosts", _descriptor9, this);
      _initializerDefineProperty(this, "likes", _descriptor10, this);
      _initializerDefineProperty(this, "comments", _descriptor11, this);
      _initializerDefineProperty(this, "sharedPosts", _descriptor12, this);
      _defineProperty(this, "charLabelsYearly", ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']);
      _initializerDefineProperty(this, "monthly", _descriptor13, this);
      _initializerDefineProperty(this, "yearly", _descriptor14, this);
    }
    get chartLabelsMonthly() {
      const days = (0, _moment.default)(this.args.startDate).daysInMonth();
      return Array.from({
        length: days
      }, (_, i) => i + 1);
    }
    get totalLikes() {
      return this.filteredLikes.length;
    }
    get totalComments() {
      return this.filteredComments.length;
    }
    get totalSharedPosts() {
      return this.filteredSharedPosts.length;
    }
    get totalInteractions() {
      return this.totalLikes + this.totalComments + this.totalSharedPosts;
    }
    generate() {
      const {
        generalFilters
      } = this.args;
      this._loadYearsAndMonths();
      if (generalFilters) {
        let yearNow = new Date(generalFilters.startDate).getFullYear();
        let monthNow = new Date(generalFilters.startDate).getMonth() + 1;
        this.selectedYear = {
          value: yearNow,
          label: yearNow + ''
        };
        this.selectedMonth = {
          value: monthNow,
          label: monthNow + '/' + yearNow
        };
        this._buildChartDataMonthly(this.selectedMonth);
      }
    }
    initChart() {
      let chart = this.chart;
      const data = {
        labels: this.monthly ? this.chartLabelsMonthly : this.charLabelsYearly,
        datasets: [{
          label: 'Curtidas',
          data: this.likes,
          borderColor: CHART_COLORS['likes'],
          pointBorderColor: CHART_COLORS['likes'],
          pointBackgroundColor: CHART_COLORS['likes'],
          pointHoverBackgroundColor: CHART_COLORS['likes'],
          pointHoverBorderColor: CHART_COLORS['likes'],
          borderWidth: 2,
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false
        }, {
          label: 'Comentários',
          data: this.comments,
          borderColor: CHART_COLORS['comments'],
          pointBorderColor: CHART_COLORS['comments'],
          pointBackgroundColor: CHART_COLORS['comments'],
          pointHoverBackgroundColor: CHART_COLORS['comments'],
          pointHoverBorderColor: CHART_COLORS['comments'],
          borderWidth: 2,
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false
        }, {
          label: 'Compartilhamentos',
          data: this.sharedPosts,
          borderColor: CHART_COLORS['sharedPosts'],
          pointBorderColor: CHART_COLORS['sharedPosts'],
          pointBackgroundColor: CHART_COLORS['sharedPosts'],
          pointHoverBackgroundColor: CHART_COLORS['sharedPosts'],
          pointHoverBorderColor: CHART_COLORS['sharedPosts'],
          borderWidth: 2,
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false
        }]
      };
      const options = {
        responsive: true,
        legend: {
          reverse: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: true,
            position: 'right',
            ticks: {
              suggestedMin: 0,
              suggestedMax: 50,
              stepSize: 10
            },
            gridLines: {
              display: true
            }
          }]
        },
        tooltips: {
          callbacks: {
            title: function () {
              return '';
            }
          }
        }
      };
      if (chart) {
        chart.data = data;
        chart.options = options;
        chart.update();
      } else {
        let ctx = document.getElementById('amount-of-interactions-chart').getContext('2d');
        const gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
        gradientFill.addColorStop(0, "rgba(55, 81, 255, 0)");
        gradientFill.addColorStop(1, "rgba(55, 81, 255, 0.1)");
        data.datasets[0].backgroundColor = gradientFill;
        chart = new _chartJs.default(ctx, {
          type: 'line',
          responsive: true,
          maintainAspectRatio: false,
          data: data,
          options: options
        });
      }
      this.chart = chart;
    }
    filterInteractions() {
      if (this.yearly) {
        this.reloadChart(this.selectedYear);
      } else if (this.monthly) {
        this.reloadChart(this.selectedMonth);
      }
    }
    reloadChart(selected) {
      if (this.monthly) {
        this._buildChartDataMonthly(selected);
      } else {
        this._buildChartDataYearly(selected);
      }
    }
    _buildChartDataMonthly(selected) {
      const {
        comments,
        likes,
        sharedPosts
      } = this.args;
      this.selectedMonth = selected;
      let monthYear = this.selectedMonth.label.split('/');
      let month = Number(monthYear[0]);
      let year = Number(monthYear[1]);
      const startDate = (0, _moment.default)(new Date(year, month - 1, 1));
      const endDate = (0, _moment.default)(new Date(year, month, 0));
      this.filteredComments = comments.filter(comment => {
        const date = (0, _moment.default)(comment.get('createdAt'));
        return date.isBetween(startDate, endDate, null, '[]');
      });
      this.filteredLikes = likes.filter(like => {
        const date = (0, _moment.default)(like.createdAt);
        return date.isBetween(startDate, endDate, null, '[]');
      });
      this.filteredSharedPosts = sharedPosts.filter(sharedPost => {
        const date = (0, _moment.default)(sharedPost.get('createdAt'));
        return date.isBetween(startDate, endDate, null, '[]');
      });
      let commentsData = [];
      this.filteredComments.forEach(comment => {
        const date = (0, _moment.default)(comment.get('createdAt'));
        const day = date.day() + 1;
        const data = commentsData.find(d => d.x === day);
        if (data) {
          data.y += 1;
        } else {
          commentsData.push({
            x: day,
            y: 1
          });
        }
      });
      this.comments = commentsData.sort((a, b) => a.x - b.x);
      let likesData = [];
      this.filteredLikes.forEach(like => {
        const date = (0, _moment.default)(like.createdAt);
        const day = date.day() + 1;
        const data = likesData.find(d => d.x === day);
        if (data) {
          data.y += 1;
        } else {
          likesData.push({
            x: day,
            y: 1
          });
        }
      });
      this.likes = likesData.sort((a, b) => a.x - b.x);
      let sharedPostsData = [];
      this.filteredSharedPosts.forEach(sharedPost => {
        const date = (0, _moment.default)(sharedPost.get('createdAt'));
        const day = date.day() + 1;
        const data = sharedPostsData.find(d => d.x === day);
        if (data) {
          data.y += 1;
        } else {
          sharedPostsData.push({
            x: day,
            y: 1
          });
        }
      });
      this.sharedPosts = sharedPostsData.sort((a, b) => a.x - b.x);
      this.initChart();
    }
    _buildChartDataYearly(selected) {
      const {
        comments,
        likes,
        sharedPosts
      } = this.args;
      const _capitalize = _ref => {
        let [firstLetter, ...restOfWord] = _ref;
        return firstLetter.toUpperCase() + restOfWord.join("");
      };
      this.selectedYear = selected;
      let year = this.selectedYear.value;
      const startDate = (0, _moment.default)(new Date(year, 0, 1));
      const endDate = (0, _moment.default)(new Date(year, 12, 0));
      this.filteredComments = comments.filter(comment => {
        const date = (0, _moment.default)(comment.get('createdAt'));
        return date.isBetween(startDate, endDate, null, '[]');
      });
      this.filteredLikes = likes.filter(like => {
        const date = (0, _moment.default)(like.createdAt);
        return date.isBetween(startDate, endDate, null, '[]');
      });
      this.filteredSharedPosts = sharedPosts.filter(sharedPost => {
        const date = (0, _moment.default)(sharedPost.get('createdAt'));
        return date.isBetween(startDate, endDate, null, '[]');
      });
      let commentsData = [];
      this.filteredComments.forEach(comment => {
        const date = (0, _moment.default)(comment.createdAt);
        const monthLabel = _capitalize(date.format("MMM"));
        const data = commentsData.find(d => d.x === monthLabel);
        if (data) {
          data.y += 1;
        } else {
          commentsData.push({
            x: monthLabel,
            y: 1
          });
        }
      });
      this.comments = commentsData.sort((a, b) => a.x - b.x);
      let likesData = [];
      this.filteredLikes.forEach(like => {
        const date = (0, _moment.default)(like.createdAt);
        const monthLabel = _capitalize(date.format("MMM"));
        const data = likesData.find(d => d.x === monthLabel);
        if (data) {
          data.y += 1;
        } else {
          likesData.push({
            x: monthLabel,
            y: 1
          });
        }
      });
      this.likes = likesData.sort((a, b) => a.x - b.x);
      let sharedPostsData = [];
      this.filteredSharedPosts.forEach(sharedPost => {
        const date = (0, _moment.default)(sharedPost.createdAt);
        const monthLabel = _capitalize(date.format("MMM"));
        const data = sharedPostsData.find(d => d.x === monthLabel);
        if (data) {
          data.y += 1;
        } else {
          sharedPostsData.push({
            x: monthLabel,
            y: 1
          });
        }
      });
      this.sharedPosts = sharedPostsData.sort((a, b) => a.x - b.x);
      this.initChart();
    }
    _loadYearsAndMonths() {
      const {
        accessLogs
      } = this.args;
      const firstObject = accessLogs.firstObject;
      const lastObject = accessLogs.lastObject;
      this.years = [];
      this.months = [];
      this.yearsAccessLogs = [];
      const now = new Date().getUTCFullYear();
      this.years = Array(now - 1999).fill('').map((v, idx) => ({
        value: now - idx,
        label: now - idx + ''
      }));
      this.yearsAccessLogs = Array(new Date(firstObject.createdAt).getFullYear() + 1 - new Date(lastObject.createdAt).getFullYear()).fill('').map((v, idx) => ({
        value: new Date(lastObject.createdAt).getFullYear() + idx,
        label: new Date(lastObject.createdAt).getFullYear() + idx + ''
      }));
      this.yearsAccessLogs.reverse();
      this.yearsAccessLogs.forEach(year => {
        Array(12).fill('').map((v, idx) => this.months.push({
          value: idx + 1,
          label: idx + 1 + '/' + year.value
        }));
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'monthly';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "years", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "months", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedYear", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedMonth", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chart", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filteredLikes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filteredComments", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filteredSharedPosts", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "likes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "comments", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "sharedPosts", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "monthly", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "yearly", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chartLabelsMonthly", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "chartLabelsMonthly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalLikes", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "totalLikes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalComments", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "totalComments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalSharedPosts", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "totalSharedPosts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalInteractions", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "totalInteractions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generate", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "generate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initChart", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "initChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterInteractions", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "filterInteractions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadChart", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "reloadChart"), _class.prototype)), _class));
  _exports.default = AmountOfInteractionsComponent;
});