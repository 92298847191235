define("portal-talentrh/models/user-solicitation", ["exports", "@ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COMPENSATORY_TYPE = {
    credit: 'Crédito',
    debit: 'Débito'
  };
  const MOTIVE_OPTIONS = {
    0: 'Dispensa',
    1: 'Pedido de demissão'
  };
  const NOTIFICATION_TYPE = {
    warning: 'Advertência',
    suspension: 'Suspensão',
    notification: 'Notificação (Aviso)'
  };
  const STATUS_OPTIONS = {
    approved: 'Aprovada',
    canceled: 'Cancelada',
    'disapproved-hr': 'Reprovada pelo Gestão de Pessoas',
    'disapproved-manager': 'Reprovada pelo(s) líder(es)',
    ignored: 'Ignorada no software de folha de pagamento',
    opened: 'Aberta',
    'pending-hr': 'Aguardando resposta do Gestão de Pessoas',
    'pending-manager': 'Aguardando resposta do líder',
    processed: 'Processada'
  };

  /* Condições para desabilitar validações */
  const disableIfCollaborator = Ember.computed.equal('model.requestedBy', 'collaborator');
  const disableIfAbono = Ember.computed('model.{vacationPaidType,solicitationType}', function () {
    return !this.model.vacationPaidType || this.model.solicitationType !== 4;
  });
  const disableIfCompensatoryTime = Ember.computed('model.{requestedBy,solicitationType}', function () {
    return this.model.solicitationType !== 15 || this.model.requestedBy === 'collaborator';
  });
  const disableIfFunction = Ember.computed('model.solicitationType', function () {
    return this.model.solicitationType !== 2;
  });
  const disableIfHorary = Ember.computed('model.solicitationType', function () {
    return this.model.solicitationType !== 3;
  });
  const disableIfPlanOrOthers = Ember.computed('model.solicitationType', function () {
    return this.model.solicitationType !== 6 && this.model.solicitationType !== 8;
  });
  const disableIfSalary = Ember.computed('model.solicitationType', function () {
    return this.model.solicitationType !== 1;
  });
  const disableIfTermination = Ember.computed('model.{id,requestedBy,solicitationType}', function () {
    if (this.model.solicitationType !== 7) {
      return true;
    } else if (this.model.requestedBy === 'collaborator') {
      return Boolean(!this.model.id);
    }
    return false;
  });
  const disableIfVacation = Ember.computed('model.{id,requestedBy,solicitationType}', function () {
    if (this.model.solicitationType !== 4) {
      return true;
    } else if (this.model.requestedBy === 'collaborator') {
      return Boolean(!this.model.id);
    }
    return false;
  });
  const disableIfWarning = Ember.computed('model.solicitationType', function () {
    return this.model.solicitationType !== 5;
  });
  const disableIfSuspension = Ember.computed('model.{solicitationType,notificationType}', function () {
    return this.model.solicitationType !== 5 || this.model.notificationType !== 'suspension';
  });
  const notificationInitial = Ember.computed.reads('model.notificationInitial');
  /* Condições para desabilitar validações */

  const Validations = (0, _emberCpValidations.buildValidations)({
    solicitationType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione um tipo de solicitação'
    }),
    user: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o colaborador',
      disabled: disableIfCollaborator
    }),
    /* Ajuste de salário */
    readjustReason: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o motivo do reajuste',
      disabled: disableIfSalary
    }),
    readjustValue: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Preencha o valor do salário',
      disabled: disableIfSalary
    }), (0, _emberCpValidations.validator)('number', {
      gt: 0,
      message: 'O valor do salário deve ser superior a zero',
      disabled: disableIfSalary
    })],
    /* Ajuste de salário */

    /* Banco de horas */
    compensatoryTimeStart: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data do início',
      disabled: disableIfCompensatoryTime
    }),
    compensatoryTimeEnd: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe a data do fim',
      disabled: disableIfCompensatoryTime
    }),
    /* Banco de horas */

    jobFunction: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a nova função',
      disabled: disableIfFunction
    }),
    // branch: validator('presence', {
    //   presence: true,
    //   message: 'Selecione a nova filial/empresa',
    //   disabled: disableIfFunction
    // }),

    department: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o novo departamento',
      disabled: disableIfFunction
    }),
    appraisedBy: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o novo coordenador',
      disabled: disableIfFunction
    }),
    /* Mudança de função */

    // /* Mudança de horário */
    horary: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o horário',
      disabled: disableIfHorary
    }),
    /* Mudança de horário */

    /* Férias */
    vacationDays: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a quantidade de dias',
      disabled: disableIfVacation
    }),
    vacationDaysPaid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe o número de dias abonados',
      disabled: disableIfAbono
    }),
    /* Férias */

    /* Mudança de Advertência */
    notificationType: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione o tipo de notificação',
      disabled: disableIfWarning
    }),
    notificationReason: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Informe o motivo',
      disabled: disableIfWarning
    }),
    /* Mudança de Advertência */

    /* Rescisão de colaborador */
    motive: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a causa da rescisão',
      disabled: disableIfTermination
    }),
    terminationDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a data da rescisão',
      disabled: disableIfTermination
    }),
    noticeDate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione a data do aviso',
      disabled: disableIfTermination
    }),
    /* Rescisão de colaborador */

    message: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Preencha o campo descrição',
      disabled: disableIfPlanOrOthers
    })
  });
  var _default = _model.default.extend(Validations, {
    // Attributes
    compensatoryTimeStart: (0, _model.attr)('date'),
    compensatoryTimeEnd: (0, _model.attr)('date'),
    compensatoryTimeType: (0, _model.attr)('string', {
      defaultValue: 'credit'
    }),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    functionChangeDate: (0, _model.attr)('date'),
    horaryChangeDate: (0, _model.attr)('date'),
    horaryManual: (0, _model.attr)('string'),
    managerResponse: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    motive: (0, _model.attr)('number'),
    needsManagerApproval: (0, _model.attr)('boolean'),
    noticeDate: (0, _model.attr)('date'),
    notificationEnd: (0, _model.attr)('date'),
    notificationInitial: (0, _model.attr)('date'),
    notificationReason: (0, _model.attr)('string'),
    notificationType: (0, _model.attr)('string'),
    readjustDate: (0, _model.attr)('date'),
    readjustValue: (0, _model.attr)('number'),
    requestedBy: (0, _model.attr)('string'),
    response: (0, _model.attr)('string'),
    solicitationType: (0, _model.attr)('number'),
    status: (0, _model.attr)('string', {
      defaultValue: 'pending-manager'
    }),
    subject: (0, _model.attr)('string'),
    statusLabel: (0, _model.attr)('string'),
    terminationDate: (0, _model.attr)('date'),
    userFullName: (0, _model.attr)('string'),
    vacationDays: (0, _model.attr)('number'),
    vacationDaysPaid: (0, _model.attr)('number'),
    vacationInitial: (0, _model.attr)('date'),
    vacationPaidType: (0, _model.attr)('string'),
    // Associations
    appraisedBy: (0, _relationships.belongsTo)('user'),
    approverManager: (0, _relationships.belongsTo)('user'),
    attendant: (0, _relationships.belongsTo)('user'),
    branch: (0, _relationships.belongsTo)('branch'),
    company: (0, _relationships.belongsTo)('company'),
    department: (0, _relationships.belongsTo)('department'),
    horary: (0, _relationships.belongsTo)('horary'),
    jobFunction: (0, _relationships.belongsTo)('job-function'),
    readjustReason: (0, _relationships.belongsTo)('readjust-reason'),
    requester: (0, _relationships.belongsTo)('user'),
    user: (0, _relationships.belongsTo)('user'),
    attachments: (0, _model.hasMany)('user-solicitation-attachments', {
      inverse: 'userSolicitation'
    }),
    // Computed
    motiveLabel: Ember.computed('motive', function () {
      return MOTIVE_OPTIONS[this.motive];
    }),
    notificationLabel: Ember.computed('notificationType', function () {
      if (!this.notificationType) {
        return '';
      }
      return NOTIFICATION_TYPE[this.notificationType];
    }),
    readableCompensatoryTimeType: Ember.computed('compensatoryTimeType', 'status', function () {
      return COMPENSATORY_TYPE[this.compensatoryTimeType] || 'Não informado';
    })
  });
  _exports.default = _default;
});