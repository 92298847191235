define("portal-talentrh/pods/post/attachment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b9V469+A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"attachment-viewer\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"attachment-viewer-header js-close-viewer\"],[12],[2,\"\\n    \"],[11,\"i\"],[24,0,\"fa fa-times fa-lg attachment-viewer-header-close-icon\"],[24,\"aria-hidden\",\"true\"],[4,[38,0],[[32,0],\"close\"],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"attachment-viewer-underlay js-close-viewer\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"attachment-viewer-frames js-frames\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"attachment-viewer-frame js-close-viewer attachment-viewer-frame-center attachment-viewer-frame-loaded\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"attachment-viewer-frame-preview-wrapper post\"],[12],[2,\"\\n\"],[2,\"        \"],[1,[30,[36,2],null,[[\"src\"],[[35,1,[\"url\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"js-pdf\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/post/attachment/template.hbs"
    }
  });
  _exports.default = _default;
});