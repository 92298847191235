define("portal-talentrh/pods/view-team-member/performance-management/employee-evaluations/modal-details/item/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ItemComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class ItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appraisedValue", _descriptor, this);
      _initializerDefineProperty(this, "appraiserValue", _descriptor2, this);
      _initializerDefineProperty(this, "consensusValue", _descriptor3, this);
      _initializerDefineProperty(this, "teamValue", _descriptor4, this);
      _initializerDefineProperty(this, "totalValue", _descriptor5, this);
    }
    init() {
      let {
        answer,
        question,
        appraised,
        appraiser,
        consensus,
        team
      } = this.args;
      if (appraised) {
        appraised = _lodash.default.find(appraised, function (o) {
          return o.id === answer.id;
        }) || undefined;
        appraised = _lodash.default.find(appraised.questions, function (o) {
          return o.id === question.id;
        }) || undefined;
        this.appraisedValue = appraised?.answer ? appraised.answer : 0;
      }
      if (appraiser) {
        appraiser = _lodash.default.find(appraiser, function (o) {
          return o.id === answer.id;
        }) || undefined;
        appraiser = _lodash.default.find(appraiser.questions, function (o) {
          return o.id === question.id;
        }) || undefined;
        this.appraiserValue = appraiser?.answer ? appraiser?.answer : 0;
      }
      if (consensus) {
        consensus = _lodash.default.find(consensus, function (o) {
          return o.id === answer.id;
        }) || undefined;
        consensus = _lodash.default.find(consensus.questions, function (o) {
          return o.id === question.id;
        }) || undefined;
        this.consensusValue = consensus?.answer ? consensus?.answer : 0;
      }
      if (team.length) {
        let appraiserAnswer,
          appraiserAnswerQuestion,
          totalTeam = 0;
        _lodash.default.map(team, function square(n) {
          appraiserAnswer = _lodash.default.find(n.appraiserAnswer, function (o) {
            return o.id === answer.id;
          }) || undefined;
          appraiserAnswerQuestion = _lodash.default.find(appraiserAnswer.questions, function (o) {
            return o.id === question.id;
          }) || undefined;
          totalTeam += !isNaN(appraiserAnswerQuestion.answer) ? parseFloat(appraiserAnswerQuestion.answer) : 0;
        });
        this.teamValue = totalTeam ? (totalTeam / team.length).toFixed(2) : 0;
      }
      this.totalValue = question?.answer ? question?.answer : 0;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appraisedValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appraiserValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "consensusValue", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "teamValue", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "totalValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "init", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "init"), _class.prototype)), _class));
  _exports.default = ItemComponent;
});